import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import NotifItem from './NotifItem'
import { getNotifs, notifReset, setLastNotif } from 'features/notifs/notifSlice'
import Loader from "loaders/Loader";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

export default function NotifFeed() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { 
        notifs,
        isNotifLoading,
        isNotifSuccess,
        lastNotifViewedIndex
    } = useSelector((state) => state.notifs)
    const { user } = useSelector((state) => state.auth)

    useEffect(() => { 
        if(!isNotifLoading && isNotifSuccess)
        dispatch(getNotifs({
            churchId: 'profileChurch._id'
        })) 
    }, [])
    
    return (
        <NotifFeedContainer>
        {

            isNotifLoading ?
            (
                <Loader />
            )
            : notifs && isNotifSuccess && notifs.length > 0 ?
            (
                notifs.map((notif, index) => ( 
                    <NotifItem 
                        key={notif._id}
                        index={index}
                        data={notif}
                        isLatest={index === 0}
                        />
                ))
            )
            :
            (
                <div className='noRequestsWrap'>
                    <div> <NotificationsNoneIcon className="noRequestsIcon" /></div>
                    <div className='noRequests'> No notifications </div>
                </div>
            )
        }
        </NotifFeedContainer>
  )
}

const NotifFeedContainer = styled.div`

    border-top: 1px solid #b3b3b39d; 
    width: 100%;
    @media (max-width: 768px) {
        width: 95%;
    }
`