import { useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { postReset } from '../../features/posts/postSlice'
import PostFeed from '../../components/MyPostFeed'
import UserProfileTop from '../../components/userProfile/UserProfileTop'
import ChurchExtras from '../../components/userProfile/ChurchExtras'
import UserExtras from '../../components/userProfile/UserExtras'
import styled from 'styled-components'
import ChoosePostPage from '../../components/postComponents/ChoosePostPage'


function Saved() {

    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)

//cleanup 
    useEffect(() => {
        return () => {
            dispatch(postReset())
        }
    },[])

  return (
    <>
        {user.isOrganization || user.isChurch ? (
            <ProfileRow>
                <ProfileLeft>
                    <ChurchExtras/>
                </ProfileLeft>  

                <UserProfileMain>
                    <UserProfileTop />
                    <PostFeed name={'user'} />
                 </UserProfileMain>
            </ProfileRow>
        )
        :
        (
            <ProfileRow>
                <ProfileLeft>
                    <UserExtras />
                </ProfileLeft>  



                <UserProfileMain>

                    <UPMTop>
                        <ChoosePostPage />
                    </UPMTop>

                    
                    <PostFeed name={'churchUser'} page={'saved'}/>

                </UserProfileMain>
            </ProfileRow>
        )}
    </>
)
}

export default Saved

const UserProfileMain = styled.div`
    margin-left: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;

`

const ProfileRow = styled.div`
    display: flex;
    width: 1300px;
    flex-direction: row;
    justify-content: flex-start; 
    margin: 0 auto;
    @media (max-width: 868px) {
      width: 100%;
    }
`
const UPMTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const ProfileLeft = styled.div`
    display: flex;
    flex-direction: column;
`