import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { setViewCreatePostModal, postCreateReset } from 'features/posts/postSlice'
import { useDispatch, useSelector } from 'react-redux' 
import FormTop from 'components/createPostModal/FormTop';
import CreatePostFormData from './CreatePostFormData'; 
import ModalCloseButton from 'components/buttons/ModalCloseButton';
import { language } from 'app/language';
import PostAddIcon from '@mui/icons-material/PostAdd';

export default function CreatePostModal() {

    const dispatch = useDispatch()
    const { isPostCreatedSuccess } = useSelector((state) => state.posts)
    const { user } = useSelector((state) => state.auth)

    useEffect(() => {
        if(isPostCreatedSuccess){
            dispatch(setViewCreatePostModal(false))
            dispatch(postCreateReset())            
        }
    }, [isPostCreatedSuccess])

    return ( 
        <CPModalContainer>
            <ModalCloseButton 
                handler={() => dispatch(setViewCreatePostModal(false))}
                size='35px'
                top='10px'
                right='10px'
                />

            <CPHeading>
                <PostAddIcon style={{fontSize:'25px', marginRight: '10px'}} />
                {language.createPost[user.preferredLanguage]}
            </CPHeading>
            <StyledWrapper> 
                <FormTop />
                <CreatePostFormData />
            </StyledWrapper>
        </CPModalContainer> 
    )
}

const StyledWrapper = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
`

const CPModalContainer = styled.div`
    background-color: white;
    width: 650px; 
    /* height: 600px; */
    height: fit-content;
    max-height: 90vh;
    padding-bottom: 20px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: scroll;

    @media (max-width: 768px) {
        width: 98%;
        border-radius: 2px; 
    }


`

const CPHeading = styled.div`
    font-size: 14px;
    color: var(--lightThemeFontColor); 
    padding-left: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`