import React from 'react' 
import CloseIcon from '@mui/icons-material/Close'; 
import EditDescriptionForm from './EditDescriptionForm'; 
import { ModalContainerMain, CloseBtn, ModalWrapAbout } from 'components/modal/modal.styled';
import { aboutChurchStore } from 'app/aboutChurchStore';

export default function EditAboutModal() { 

    const setViewEditDescriptionModal = aboutChurchStore(state => state.setViewEditDescriptionModal) 

    const handleClick = () => {
        setViewEditDescriptionModal(false)
    }

    return (
        <ModalContainerMain>
            <ModalWrapAbout>
                <CloseBtn onClick={handleClick}><CloseIcon /></CloseBtn>
                <EditDescriptionForm />
            </ModalWrapAbout>
        </ModalContainerMain>
    )
}

