import React from 'react'

function Spinner() {
  return (
    <div>

        


    </div>
  )
}

export default Spinner