
import { SettingsMainContainer, TopHeaderSettings } from './settings.styled'
import { useSelector } from 'react-redux'
import Loader from "loaders/Loader";
import ReqSentItem from './requests/ReqSentItem' 
import { RiGroupLine } from 'react-icons/ri';
import { settingsLanguage } from './settingsLanguage'

function RequestsSent() {
    const { requestsSent, isRequestGetSuccess, isRequestGetLoading, isRequestGetError, requestGetmessage } = useSelector((state) => state.requests)
    const { user } = useSelector((state) => state.auth)

    return (
        <SettingsMainContainer>
            <TopHeaderSettings>
                    {
                        settingsLanguage.requestsSent[user.preferredLanguage]
                    }
            </TopHeaderSettings> 
            { isRequestGetLoading ? 
                (
                    <Loader />
                )
                : isRequestGetSuccess && !isRequestGetLoading && requestsSent.length > 0 ?
                ( 
                  requestsSent.map( ele => (
                        <ReqSentItem 
                            key={ele._id} 
                            churchId={ele.receiverId._id} 
                            reqId={ele._id}
                            fullName={ele.receiverId.fullName}
                            username={ele.receiverId.username}
                            image={ele.receiverId.profileImg.imagePath2} 
                        />
                    )) 
                )
                :
                (
                    <div className='noRequestsWrap'>
                        <div> <RiGroupLine className="noRequestsIcon" /></div>
                        <div className='noRequests'>
                            {
                                settingsLanguage.noRequests[user.preferredLanguage]
                            }
                        </div>
                    </div> 
                )}
        </SettingsMainContainer>
    )
}

export default RequestsSent 
