import React, { useEffect } from 'react'
import styled from 'styled-components'
import VideoForm from './VideoForm'
import { useSelector, useDispatch } from 'react-redux'
import { uploadVideo, setViewUploadVideoModal } from 'features/videos/videoSlice'
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import SpinnerSmall from 'components/SpinnerSmall'

export default function UploadVideoModal() {
    
    const dispatch = useDispatch()
    const { isVideoUploading, selectedVideo, isVideoUploadSuccess, uploadVideoType, thumbnailPreview, videoUrl, videoTitle } = useSelector((state) => state.video)

    const handleUpload = () => {
        if(uploadVideoType === 'computer'){
            // here we are uploading a video and an image to the database
            const formData = new FormData(); 

            formData.append("file", selectedVideo)
            formData.append("videoTitle", videoTitle)
            formData.append("videoSource", uploadVideoType)  

            dispatch(uploadVideo(formData))

        }else if(uploadVideoType === 'youtube' || uploadVideoType === 'url'){

            // here we are not uploading any files, simply adding URLs to the database
            dispatch(uploadVideo({
                videoTitle: videoTitle, 
                videoSource: uploadVideoType,
                imageUrl: thumbnailPreview,
                videoUrl: videoUrl
            }))
        }
    }

    useEffect(() => {
        if(isVideoUploadSuccess){
            dispatch(setViewUploadVideoModal(false))
        }
    }, [isVideoUploadSuccess])

    return (
        <UploadVideoModalContainer>
            <ModalCloseButton
                handler={() => dispatch(setViewUploadVideoModal(false))
                }
                size='30px'
                top='10px'
                right='10px'
                />

            <StyledHeading>Video Upload</StyledHeading>
            <VideoForm
                type={uploadVideoType}
                />
            <StyledSubmitButton
                disabled={isVideoUploading}
                onClick={handleUpload}
                >
                {
                    isVideoUploading ? <SpinnerSmall /> : 'Upload'
                }
            </StyledSubmitButton>
        </UploadVideoModalContainer>
    )
}

const UploadVideoModalContainer = styled.div`
    position: relative;
    background-color: white;
    border-radius: 10px;
    width: 500px;
    height: fit-content;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;

    @media (max-width: 768px) {
        width: 100%; 
        border-radius: 0px;
        padding: 10px;
    }

`

const StyledHeading = styled.h1`

    font-size: 17px;
    font-weight: 300;
    color: var(--lightThemeFontColor);
    text-align: center;
    margin-top: 5px;
    margin-bottom: 20px;
`

const StyledSubmitButton = styled.button`
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 100px;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: var(--lightThemeFontColor);
    color: white;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    &:hover{
        background-color: #878787;
    }
`