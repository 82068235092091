import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import singlePostCommentService from './singlePostCommentService'

const initialState = {
  sPComments: [],
  isSPCommentError: false,
  isSPCommentSuccess: false,
  isSPCommentLoading: false,

  isSPNewCommentLoading: false,
  isSPNewCommentSuccess: false,

  sPCommentMessage: '',

  totalComments: 0,
  limit: 0,
  totalPages: 0,
  page: 0,
  pagingCounter: 0,
  hasPrevPage: false,
  hasNextPage: false,
  prevPage: null,
  nextPage:null,

  replies: [],
  latestReply: {},
  isCreateReplyLoading: false,
  isCreateReplySuccess: false,
  isCreateReplyError: false,

  commentIdsOfRepliesLoaded: [],
  commentRepliesLoading: false,
  commentIdReplies: null,
  commentRepliesSuccess: false,
  commentIndexLoading: {
    commentId: null,
    index: null,
    loading: false,
    status: null,
  },

  isCommentDeleteLoading: false,
  isCommentDeleteSuccess: false,
  isCommentDeleteError: false,


}

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

export const getComments = createAsyncThunk(
  'spcomments/getComments',
  async (postData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await singlePostCommentService.getComments(postData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  })

export const setComment = createAsyncThunk(
  'spcomments/setComment',
  async (commentData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await singlePostCommentService.setComment(commentData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  })


export const deleteComment = createAsyncThunk(
  'spcomments/deleteComment',
  async (postData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await singlePostCommentService.deleteComment(postData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  })

  export const setCommentReply = createAsyncThunk(
    'spcomments/setCommentReply',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await singlePostCommentService.setCommentReply(data, token)
      } catch (error) {
        const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    })

  export const deleteCommentReply = createAsyncThunk(
    'spcomments/deleteCommentReply',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await singlePostCommentService.deleteCommentReply(data, token)
      } catch (error) {
        const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    })
      
  export const getCommentReplies = createAsyncThunk(
    'spcomments/getCommentReplies',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await singlePostCommentService.getCommentReplies(data, token)
      } catch (error) {
        const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    })


export const singlePostCommentSlice = createSlice({
  name: 'sPComments',
  initialState,
  reducers: {
    singlePostCommentReset: (state) => initialState,
    setLoadingCommentIdReplies: (state, action) => {

      let newObj = {
        commentId: action.payload.commentId,
        index: action.payload.index,
        loading: action.payload.loading,
        status: action.payload.status,
      }

      state.commentIndexLoading = newObj

    },
    resetLatestReply: (state, action) => {
      state.latestReply = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getComments.pending, (state) => {
        state.isSPCommentLoading = true
      })
      .addCase(getComments.fulfilled, (state, action) => {
        state.isSPCommentLoading = false
        state.isSPCommentSuccess = true
        state.sPComments.push(...action.payload.docs)
        state.totalComments = action.payload.totalDocs
        state.limit = action.payload.limit
        state.totalPages = action.payload.totalPages
        state.page = action.payload.page
        state.pagingCounter = action.payload.pagingCounter
        state.hasPrevPage = action.payload.hasPrevPage
        state.hasNextPage = action.payload.hasNextPage
        state.prevPage = action.payload.prevPage
        state.nextPage = action.payload.nextPage
      })
      .addCase(getComments.rejected, (state, action) => {
        state.isSPCommentLoading = false
        state.isSPCommentError = true
        state.sPCommentMessage = action.payload
      })
      //================================================================
      .addCase(setComment.pending, (state) => {
        state.isSPNewCommentLoading = true
        state.isSPCommentError = false
        state.isSPNewCommentSuccess = false
      })
      .addCase(setComment.fulfilled, (state, action) => {
        state.isSPCommentError = false
        state.isSPNewCommentLoading = false
        state.isSPNewCommentSuccess = true
        state.sPComments.unshift(action.payload)
        state.totalComments += 1
      })
      .addCase(setComment.rejected, (state, action) => {
        state.isSPCommentLoading = false
        state.isSPCommentError = true
        state.sPCommentMessage = action.payload
      })
      //================================================================  

      .addCase(deleteComment.pending, (state) => {
        state.isCommentDeleteLoading = true
        state.isCommentDeleteSuccess = false
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        state.isCommentDeleteLoading = false
        state.isCommentDeleteSuccess = true
        state.sPComments = state.sPComments.filter(
          (ele) => ele._id !== action.payload.id
        )
      })
      .addCase(deleteComment.rejected, (state, action) => {
        state.isCommentDeleteLoading = false
        state.isCommentDeleteError = true
        // state.sPCommentMessage = action.payload
      })
      //================================================================      
      .addCase(setCommentReply.pending, (state) => {
        state.isCreateReplyLoading = true
      })
      .addCase(setCommentReply.fulfilled, (state, action) => {
        state.isCreateReplyLoading = false
        state.isCreateReplySuccess = true

        // if we do this line of code below, when we then click "view replies" on the comment, we get an error
        // because this last reply is completely loaded, but the other replies are not loaded yet

        // check if the replies for this comment are loaded, if they are add the whole reply, if not add only the id

        if(state.commentIdsOfRepliesLoaded.includes(action.payload.updatedComment._id)) {
          state.sPComments[action.payload.commentIndex].replies.unshift(action.payload.newReply)
        } else {
          state.sPComments[action.payload.commentIndex].replies.unshift(action.payload.newReply._id)
        }

        state.latestReply = {
          commentId: action.payload.updatedComment._id,
          reply: action.payload.newReply,
        }
      })
      .addCase(setCommentReply.rejected, (state, action) => {
        state.isCreateReplyLoading = false
        state.isCreateReplyError = true
        state.sPCommentMessage = action.payload
      })
      //================================================================
      .addCase(getCommentReplies.pending, (state) => {
        state.commentRepliesLoading = true
      })
      .addCase(getCommentReplies.fulfilled, (state, action) => {

        state.sPComments[action.payload.index].repliesLoaded = action.payload.commentId
        state.commentIdsOfRepliesLoaded.push(action.payload.commentId)

        state.commentIdReplies = action.payload.commentId
        state.commentRepliesLoading = false
        state.commentRepliesSuccess = true

        let newObj = {
          commentId: action.payload.commentId,
          index: action.payload.index,
          loading: false,
          status: "success",
        }


        state.commentIndexLoading = newObj
        
        state.sPComments[action.payload.index].replies = action.payload.replies

      })
      .addCase(getCommentReplies.rejected, (state, action) => {
        state.commentRepliesLoading = false
        state.commentRepliesSuccess = false
        state.sPCommentMessage = action.payload
      })

  },
})
export const { 
  singlePostCommentReset,
  setLoadingCommentIdReplies,
  resetLatestReply,
 } = singlePostCommentSlice.actions
export default singlePostCommentSlice.reducer