import React, {useState} from 'react'
import styled from 'styled-components'
import { imgUrl } from 'features/constants'
import useImageLoader from 'hooks/useImageLoader'
import ContextMenuContainer from 'components/contextMenu/ContextMenuContainer'
import useContextMenu from 'hooks/useContextMenu'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

export default function ListingItem({item}) {
    // OPEN LISTING IN RIGHT SIDE OF SCREEN
    //NOT A MODAL, NOT A SINGLE PAGE, JUST A RIGHT SIDE OF SCREEN VIEW
    // THE USER CAN OPTIONALLY CLICK ON THE LISTING TO OPEN IT IN A NEW PAGE
    
    const [imageSource, { blur }] = useImageLoader(`${imgUrl}/marketplace/${item.thumbnail.imagePath2}`, `${imgUrl}/marketplace/${item.images[0].imagePath2}`)
    const { clicked, setClicked, points, setPoints } = useContextMenu();
    const ref = React.useRef(null);


    return (
        <ListingItemContainer
            ref={ref}
            onContextMenu={(e) => {         
                e.preventDefault(); // prevent the default behaviour when right clicked
                setClicked(true);
                setPoints({
                    x: e.clientX - ref.current.getBoundingClientRect().left,
                    y: e.clientY - ref.current.getBoundingClientRect().top,    
                })
            }}
            >
            <ListingImage>
                <img 
                    src={imageSource} 
                    alt="listing"  
                    style={{
                        filter: blur ? 'blur(2px)' : 'none',
                        transition: blur ? 'none' : 'all 0.9s ',
                    }}
                    />
            </ListingImage>    

            <ListingDetails>
                <h2>{item.title}</h2>
                <h3>${item.price}</h3>
                <h4>{item.locationAddress}</h4>
            </ListingDetails>

            {clicked && (
                <ContextMenuContainer
                    top={points.y} 
                    left={points.x}
                    >
                    <section>
                        <div><OpenInNewIcon /> <span>Open in New Tab</span> </div> 
                        <div><MenuOpenIcon /> <span>Open to Right</span> </div> 
                        <div><BookmarkBorderIcon /> <span>Save Listing</span> </div> 
                    </section>
                </ContextMenuContainer>
            )}
        </ListingItemContainer>
    )
}

const ListingItemContainer = styled.div`
    margin: 10px;
    height: 200px;
    min-width: 150px;
    width: 200px; 
    max-width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    margin-top:20px;
    flex-grow: 1;
    position: relative;
`

const ListingImage = styled.div`
        border-radius: 10px;
        height: 80%;
        width: 100%;
        background-color: #bebebe;

        /* on hover zoom image */

        &:hover
        {
            img{
                transform: scale(1.05);
                transition: all 0.2s ease-in-out;                
            }

        }

        img {
            border-radius: 10px;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

`

const ListingDetails = styled.div`
    height: 20%;
    margin-top: 5px;
    width: 100%; 
    flex-direction: column;
    align-items:flex-start;
    justify-content: flex-start;
    text-align: left;

    h2{

        padding: 2px; 
        font-size: 15px;
        color: var(--lightThemeFontColor);
        font-weight: 400;
    }
    h3{
        padding: 2px;
        font-size: 14px;
        color: var(--lightThemeFontColor);
        font-weight: 400;
    }
    h4{
        padding: 2px;
        font-size: 12px;
        color: var(--lightThemeFontColor);
        font-weight: 400;
    }
`

