import React from 'react'
import { ModalWrap } from 'components/modal/modal.styled'
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import { useDispatch, useSelector } from 'react-redux'
import { setViewFeedbackItemModal } from 'features/profileChurch/profileChurchSlice'
import styled from 'styled-components'
import { imgUrl } from 'features/constants'
import TimeAgo from 'timeago-react';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';

export default function ViewFeedbackItemModal() {

    const dispatch = useDispatch()
    const { feedbackItemData } = useSelector((state) => state.profileChurch)

    const handleClose = () => {
        dispatch(setViewFeedbackItemModal({
            view: false, 
            data: {}
        }))
    }


    if(Object.keys(feedbackItemData).length === 0) return 'loading...'


    return (
        <ModalWrap
            width='700px'
            height='600px'
            >
            <ModalCloseButton 
                top='15px'
                right='15px'
                size='35px'
                handler={handleClose}
                />

            <FeedbackItemModalMain>

                <FTop>
                    <FTitle>
                        {
                            feedbackItemData.isAnonymous ?
                            (
                                <NoAccountsIcon 
                                    style={{
                                        width: '40px',
                                        height: '40px',
                                        borderRadius: '50%',
                                        marginRight: '10px'
                                    }}
                                    />
                            )
                            :
                            (
                                <img 
                                    src={`${imgUrl}/profile/${feedbackItemData.creator.profileImg.imagePath2}`}
                                    alt={feedbackItemData.creator.fullName} 
                                    />
                            )
                        }


                        {feedbackItemData.creator.fullName}
                    </FTitle>
                    <FDate>
                        <TimeAgo datetime={feedbackItemData.createdAt} locale='en_US' />    
                    </FDate>
                </FTop>

                <FContent>

                    {
                        // detect line breaks and split into paragraphs
                        feedbackItemData.content.split('\n').map((item, i) => {
                            return <section key={i}>
                                {item}
                                <br />
                            </section>
                        })
                    }

                </FContent>


            </FeedbackItemModalMain> 

        </ModalWrap>
    )
}

const FeedbackItemModalMain = styled.div`
    margin-top: 30px;
    padding: 25px;
    display: flex;  
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

`

const FTop = styled.div`
    height: 60px; 
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; 

`

const FTitle = styled.h1`
    
    font-size: 17px;
    font-weight: 400;
    color: var(--lightThemeFontColor);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;


    img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
    }

    `       

const FDate = styled.h1`
    font-size: 14px;
    font-weight: 400;
    color: var(--lightThemeFontColor);
`   

const FContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: var(--lightThemeFontColor);
    line-height: 1.3; 
    height: 420px;
    overflow-y: scroll;
    padding-right: 10px;

`