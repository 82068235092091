import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const API_URL = '/api/memberships/getMemberOf'
const API_URL2 = '/api/memberships/getMembers'
const API_URL3 = '/api/memberships/removeMember'
const API_URL4 = '/api/memberships/removeMemberOf'
const API_URL5 = '/api/memberships/searchMembers'
const API_URL6 = '/api/memberships/searchChurch'

//for sending the token
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }


const getMemberOf = async (token) => {
  const response = await axios.get(URL+API_URL, config(token))
  return response.data
}

const getMembers = async (data, token) => {
  const response = await axios.post(URL+API_URL2, data, config(token))
  return response.data
}

const removeMember = async (userId, token) => {
  const response = await axios.post(URL+API_URL3, userId, config(token))
  return response.data
}

const removeMemberOf = async (churchId, token) => {
  const response = await axios.post(URL+API_URL4, churchId, config(token))
  return response.data
}

const searchMembers = async (data, token) => {
  const response = await axios.post(URL+API_URL5, data, config(token))
  return response.data
}
const searchChurch = async (data, token) => {
  const response = await axios.post(URL+API_URL6, data, config(token))
  return response.data
}

const makeMemberAnAdmin = async (data, token) => {
  const response = await axios.post(URL+'/api/memberships/makeMemberAnAdmin', data, config(token))
  return response.data
}

const makeMemberAModerator = async (data, token) => {
  const response = await axios.post(URL+'/api/memberships/makeMemberAModerator', data, config(token))
  return response.data
}

const removeAModerator = async (data, token) => {
    const  response = await axios.post(URL+'/api/memberships/removeAModerator', data, config(token))
    return response.data
}

const removeAnAdmin = async (data, token) => {
    const  response = await axios.post(URL+'/api/memberships/removeAnAdmin', data, config(token))
    return response.data  
}

const searchFellowMembers = async (data, token) => {
  const response = await axios.post(URL+'/api/memberships/searchFellowMembers', data, config(token))
  return response.data
}

const registerUserFromInvite = async (data) => {
  const response = await axios.post(URL+'/api/memberships/registerUserFromInvite', data, {withCredentials: true})
  return response.data
}

const getRegInvData = async (data) => {
  const response = await axios.post(URL+'/api/memberships/getRegInvData', data, {withCredentials: true})
  return response.data
}



const membershipsService = { 
  getMemberOf, getMembers, removeMember, removeMemberOf, 
  searchMembers, searchChurch, makeMemberAnAdmin, 
  makeMemberAModerator, removeAModerator, removeAnAdmin,
  searchFellowMembers, registerUserFromInvite, getRegInvData
}
export default membershipsService
