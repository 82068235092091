import TextFieldInput from "./TextFieldInput"
import AddConnectBank from "./AddConnectBank"

export const requirementsData = {

    "person": {
        label: "Personal Information Details",
    },
    
    "individual": {
        label: "Individual Details",
    },

    "company": {
        label: "Company Details",
    },

    "owner": {
        label: "Owner Details",
    },

    "representative": {
        label: "Representative Details",
    },

    "business_profile.mcc": {
        label: "Business Type",
    },
    "business_profile.url": {
        label: "Business Website",
    },
    "external_account": {
        label: <AddConnectBank />,
    },
    "settings.payments.statement_descriptor": {
        label: "Statement Descriptor",
    },

    "tos_acceptance.date": {
        label: "Terms of Service Acceptance",
    },

}
