import React from 'react'
import styled from 'styled-components'


export default function DropDownContainer(props) {
    return (
        <DropDownContainerMain
            top={props.top}
            right={props.right}
            >
            {props.children}
        </DropDownContainerMain>
    )
}

const DropDownContainerMain = styled.div` 
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: white; 
    width: auto;  
    z-index: 12;
    padding: 5px;
    box-shadow: 0 0 4px #ccc;
    /* these below are important to make div dropdown inside post */
    border-radius: 10px 0px 10px 10px;
    color: var(--lightThemeFontColor);
    top: ${props => props.top ? props.top : '10px'};
    right: ${props => props.right ? props.right : '15px'};
`