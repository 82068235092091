import React, {useState, useEffect } from 'react'
import styled from 'styled-components'   
import { useSelector, useDispatch } from 'react-redux'
import { setActiveImageCarousel } from 'features/givingCampaigns/givingCampaignSlice'
import { GiveForm, StyledInput, InputIconWrap } from '../../giving.styled'
import { CiImageOn } from "react-icons/ci";
import AddExtraImage from './AddExtraImage'
import { IoIosArrowForward, IoIosArrowBack  } from "react-icons/io";

export default function Step2Images() {

    const dispatch = useDispatch()
    const { activeImageCarousel, images } = useSelector((state) => state.givingCampaign)

    const [imageHover, setImageHover] = useState(false)

    const navigateImages = (direction) => {
        dispatch(setActiveImageCarousel(direction))
    }

    return (
        <GiveForm
            style={{flexDirection: 'row'}}
            >
            <Aside>
                <AddImage
                    onMouseEnter={() => setImageHover(true)}
                    onMouseLeave={() => setImageHover(false)}
                    align={images.length > 0 ? 'space-between' : 'center'}
                    hideBorder={images.length > 0}
                    >

                    <ImageViewButton
                        visible={images.length > 1 && imageHover}
                        side={'left'}
                        onClick={() => navigateImages('left')}
                        >
                        <IoIosArrowBack 
                            size={30} 
                            />
                    </ImageViewButton>


                    {
                        images.length > 0 ? 
                        (
                            <img 
                                src={images[activeImageCarousel].src}
                                alt={images[activeImageCarousel].alt}
                                style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                /> 
                        )
                        :
                        (
                            <CiImageOn size={100} /> 
                        )
                    }

                    
                    <ImageViewButton
                        side={'right'}
                        visible={images.length > 1 && imageHover}
                        onClick={() => navigateImages('right')}
                        >
                        <IoIosArrowForward 
                            size={30} 
                            />
                    </ImageViewButton>

                
                </AddImage>
            </Aside>

            <Aside>
                <ImagesContainer>
                    <div>Add up to 5 images</div>  
                    <ExtraImagesButtons> 
                        {
                            images.map((num, index) => (
                                <AddExtraImage 
                                    key={index} 
                                    number={index}
                                    button={false}
                                    />
                            ))
                        }
                        {
                            images.length < 5 && (
                                <AddExtraImage 
                                    number={images.length}
                                    button={true}
                                    />
                            )
                        }                        
                    </ExtraImagesButtons> 
                </ImagesContainer>
            </Aside>
        </GiveForm> 
    )
}



const ExtraImagesButtons = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    flex-wrap: wrap;

`
const ImageViewButton = styled.div` 
    display: flex;
    position: absolute;
    right: ${props => props.side === 'right' ? '-10px' : 'auto'};
    left: ${props => props.side === 'left' ? '-10px' : 'auto'};
    z-index: 9;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ffffff9c;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`


const AddImage = styled.div`
    position: relative;
    width: 100%; 
    background-color: aliceblue;
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.align};
    align-items: center; 
    aspect-ratio: 4/3;
    border-radius: 10px;
    color: var(--lightThemeFontColor);
    border: 3px dashed transparent;
    transition: all 0.6s ease-in-out;
    cursor: pointer;
    z-index: 1;

    &:hover{
        border: ${props => props.hideBorder ? '3px dashed transparent' : '3px dashed #ccc'};
 
    }

    img{
        z-index: 1;
        border-radius: 10px;
    }

`


const ImagesContainer = styled.div`
    width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px  20px 20px 20px;
    font-size: 13px;
`

const Aside = styled.div`
    width: 50%;  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    color: var(--lightThemeFontColor);
    transition: all 0.6s ease-in-out;
    cursor: pointer;
`