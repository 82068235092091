import { useEffect, useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import styled from 'styled-components'
import TuneIcon from '@mui/icons-material/Tune';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import useToggleDropdown from "hooks/useToggleDropdown";
import { setIsAnonymous, setPostType } from "features/createPost/selectPostTypeSlice";
import { chooseImage } from "features/settings/profileImageSlice";
import placeholder from "../../images/directoryImages/default/addImage.png"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { toast } from "react-toastify";
import { setPost, setErrorField } from "features/posts/postSlice";
import Resizer from "react-image-file-resizer"; 
import SpinnerSmall from "components/SpinnerSmall";
import { FileUploadButton, ButttonWrap } from "components/files/files.styled";
import { language } from "app/language";


export default function CreatePostFormData() {

    const dispatch = useDispatch()
    const [createPostText, setCreatePostText] = useState('')
    const { postingTo } = useSelector((state) => state.postingTo)
    const { user } = useSelector((state) => state.auth)
    const { postType, isAnonymous } = useSelector((state) => state.postType )
    const { createPostPostingTo, posts, isLoading, isPostCreatedLoading, isError, isSuccess, message, errorField } = useSelector((state) => state.posts)
    const ref1 = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref1)

    useEffect(() => {}, [posts, isLoading, isError, isSuccess, message, postingTo])

    const handleChooseType = () => { 
        if(errorField === 'postType'){ dispatch(setErrorField('')) }
        setIsDropped.toggle(ref1) 
    }


    const handlePostTextChange = (e) => {
        if(errorField === 'postText'){dispatch(setErrorField(''))}
        setCreatePostText(e.target.value)
    }

    const handleAnonymous = () => {
        dispatch(setIsAnonymous(!isAnonymous))
    }

    const handlePostTypeClick = (value) => { dispatch(setPostType(value)) }

    const [{alt, src, edited, fileSrc}, setImg] = useState({
        src: placeholder,
        alt: 'Upload an Image',
        edited: false,
    });

    const handleImg = async (e) => {
        if(e.target.files[0]) {
            dispatch(chooseImage(e.target.files[0]))
            setImg({
                src: URL.createObjectURL(e.target.files[0]),
                alt: e.target.files[0].name,
                fileSrc: e.target.files[0],
                edited: true
            });    
        }
    }

    const removeImagePreview = () => {
        dispatch(chooseImage(null))
        setImg({
            src: placeholder,
            fileSrc: '',
            alt: 'Upload an Image',
            edited: false
        });    
    }

    const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

    const handleSubmit = async () => {
        const formData = new FormData();
        if(!createPostPostingTo){ toast.error('Select where to post!');return ( dispatch(setErrorField('postingTo')))}
        if(!postType){ toast.error('Select a post type!');return ( dispatch(setErrorField('postType')))}
        if(createPostText.length < 3 ){
            toast.error('Write something meaningful...');
            return ( dispatch(setErrorField('postText')))
        }
        if(createPostPostingTo._id){
            // const postingToId = postingTo.id ?? postingTo._id //this is a bug. When clicking createPost from 
            // console.log(postingTo._id + '2')
            formData.append("postedToId", createPostPostingTo._id);
            if(fileSrc){

                formData.append("file", fileSrc);
            }
            formData.append("isAnonymous", isAnonymous);
            formData.append("postType", postType);
            formData.append("postText", createPostText);
            dispatch(setPost(formData))
        }else{
            return toast.error('Error occurred d')
        }
    }

  return (
    // if isPostCreatedLoading , disable clickable events on modal

    <CPFDCont>
      <CreatePostformText  >
        <TA  
            isError={errorField === 'postText'}
            value={createPostText} 
            onChange={handlePostTextChange} 
            placeholder={language.startWriting[user.preferredLanguage]}  
            />
      </CreatePostformText>

        { edited && (
            <CPImage>
                <img style={{ width: '95%', height: 'auto'}} src={src} alt={alt} />
                <RemoveImgBtn  onClick={removeImagePreview} > 
                    <DeleteOutlineIcon /> 
                </RemoveImgBtn>
            </CPImage>
        )}

        <AddToPost>
            {isDropped && (
                <SelectType>
                    <STEle onClick={() => handlePostTypeClick('Prayer Request')}>
                        {language["Prayer Request"][user.preferredLanguage]}
                    </STEle>
                    <STEle onClick={() => handlePostTypeClick('Testimony')}>
                        {language["Testimony"][user.preferredLanguage]}
                    </STEle>
                    <STEle onClick={() => handlePostTypeClick('Praise')}>
                        {language["Praise"][user.preferredLanguage]}
                    </STEle>
                </SelectType>
            )}
    

            <ATPEle
                ref={ref1}
                onClick={handleChooseType}
                isError={errorField === 'postType'}
                > 
                <TuneIcon />  
                <BtnText>
                    {language.postType[user.preferredLanguage]}
                </BtnText> 
            </ATPEle>

            <ATPEleLabel> 
                <AddPhotoAlternateIcon  /> 
                <BtnText> 
                    { edited ? 
                        (
                            language.replace[user.preferredLanguage]
                        )
                        :
                        (
                            language.add[user.preferredLanguage]
                        )
                    } 
                    {' ' + language.image[user.preferredLanguage]}
                </BtnText> 
            </ATPEleLabel>

            <input hidden  id="imageUpload"  type="file" accept=".png, .jpg, .jpeg" name="file" onChange={handleImg}/>

            <ATPEle onClick={handleAnonymous}> 
                <VisibilityOffIcon style={{color: isAnonymous && ('var(--themeColor)')}} /> 
                <BtnText>
                    { language.anonymous[user.preferredLanguage] }
                </BtnText> 
            </ATPEle>

        </AddToPost>


        <ButttonWrap
            padding='16px 30px'
            >
                <FileUploadButton
                    onClick={handleSubmit}
                    disabled={isPostCreatedLoading}
                    width='100%' 
                    >
                    {
                        isPostCreatedLoading ? 
                        (   
                            <SpinnerSmall />
                        )
                        :
                        (
                            <span>
                                {language.submitPost[user.preferredLanguage]}
                            </span>
                        )
                    }
                    
                </FileUploadButton>

        </ButttonWrap>
        
      
    </CPFDCont>
  )
}

const BtnText = styled.span`
    margin-left: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 13px; 
    @media (max-width: 768px) {
        font-size: 11px;
    }
`

const CPFDCont = styled.div`
    margin-top: 10px;
    width: 100%;
    height: 100%;
    border-top: 1px solid rgb(200,200,200);
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

`
const CPImage = styled.div`
    height: 300px;
    overflow-y: scroll;
    position: relative;
`
const CreatePostformText = styled.div`
    
`

const TA = styled.textarea`
    resize: none;
    width: 100%;
    min-height: 150px;
    border: none;
    outline: none;
    padding: 25px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: rgb(128, 128, 128); 
    border:  ${props => props.isError ? ('1px solid red'):('none') };

    /* styhle the placeholder */
    &::placeholder { 
        font-size: 14px; 
    }

`

const AddToPost = styled.div`
    margin-top: 15px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 10px; 
    @media (max-width: 768px) {
        padding: 5px;
    }
`

const ATPEle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    margin-left: 20px;
    cursor: pointer;
    color: var(--lightThemeFontColor);
    border-radius: 10px;
    border:  ${props => props.isError ? ('1px solid red'):('none') };
    &:hover{
        background-color: #e7e7e7;
        color: black;
    }

    @media (max-width: 768px) {
        padding: 5px;
        flex-direction: column;
    }

`
const ATPEleLabel = styled.label.attrs(() => ({
    htmlFor: 'imageUpload'
  }))`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    margin-left: 20px;
    color: var(--lightThemeFontColor);
    cursor: pointer;
    &:hover{
        background-color: #e7e7e7;
        border-radius: 10px;
        color: black;
    }
    @media (max-width: 768px) {
        padding: 5px;
        flex-direction: column;
    }
`

const SelectType = styled.div`
    position: absolute;
    height: fit-content;
    width: fit-content;
    border: 1px solid #e0e0e0;
    margin-top: -120px;
    margin-left: 20px;
    background-color: white;
    z-index: 5;
    border-radius: 10px;
    padding: 5px;
`
const STEle = styled.div`
    padding: 10px;
    cursor: pointer;
    color: var(--lightThemeFontColor);
    &:hover{
        background-color: #e0e0e0;
        border-radius: 5px;
    }
`
const RemoveImgBtn = styled.div`
    position: absolute;
    top: 5px;
    left: 15px;
    z-index: 10;
    padding: 10px;
    background-color: red;
    cursor: pointer;
    opacity: 0.5;
    color: white;
`