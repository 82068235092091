import { ModalContainerMain } from 'components/modal/modal.styled' 
import React from 'react'
import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close';
import AddPersonForm from './AddPersonForm';
import { aboutChurchStore } from 'app/aboutChurchStore';

export default function AddPersonModal() {

    const setViewAddPersonModal = aboutChurchStore(state => state.setViewAddPersonModal)

    return (
        <ModalContainerMain>
            <ModalWrapAbout> 
                <CloseBtn onClick={() => setViewAddPersonModal(false)}><CloseIcon /></CloseBtn>
                <AddPersonForm />
            </ModalWrapAbout>
        </ModalContainerMain>
    )
}

const ModalWrapAbout = styled.div`
    width: 600px;
    min-height: 700px;
    height: fit-content;
    max-height: 90vh;
    overflow-y: scroll;
    position: relative;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
    }
`
const CloseBtn = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
        background-color: #dadada;
        border-radius: 50%; 
    }
`