 
export const eventLanguage = {

    noEvents: {
        en: 'No events',
        es: 'No hay eventos',
    },

    details: {
        en: 'Details',
        es: 'Detalles',
    },
    discussion: {
        en: 'Discussion',
        es: 'Discusión',
    },
    attending: {
        en: ' attending',
        es: ' asistiendo',
    },
    interested: {
        en: ' interested',
        es: ' interesados',
    },
    addComment: {
        en: 'Add a comment',
        es: 'Añadir un comentario',
    },
    openInGoogle: {
        en: 'Open in Google Maps',
        es: 'Abrir en Google Maps',
    },
    loginToViewDiscussion: {
        en: 'login to view discussion',
        es: 'iniciar sesión para ver la discusión',
    },
    noCommentsYet: {
        en: 'No comments yet',
        es: 'Aún no hay comentarios',
    },

}