import React, { useEffect } from 'react'
import styled from 'styled-components'
import { NoItemsContainer } from 'components/main.styled'
import { useSelector, useDispatch } from 'react-redux'
import { LiaMoneyCheckSolid } from "react-icons/lia";
import { getGivingCampaigns } from 'features/givingCampaigns/givingCampaignSlice'
import CampaignItem from './CampaignItem';

export default function CampaignFeed() {

    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { campaigns, getGivingCampaignsLoading, getGivingCampaignsSuccess } = useSelector(state => state.givingCampaign)
    const { profileChurch } = useSelector(state => state.profileChurch)

    useEffect(() => {

        if(campaigns.length === 0){
            dispatch(getGivingCampaigns({ churchId: profileChurch._id }))
        }
    }, [])


    return (
        <CampaignFeedContainer>

            {
                getGivingCampaignsSuccess ? 
                (
                    campaigns.length > 0 ? 
                    (
                        campaigns.map(campaign => (
                            <CampaignItem 
                                key={campaign._id} 
                                campaign={campaign} 
                                />
                        ))
                    ) 
                    : 
                    (
                        <NoItemsContainer>
                            <LiaMoneyCheckSolid />
                            No campaigns found
                        </NoItemsContainer>
                    )
                ) 
                : 
                (
                    <NoItemsContainer>
                        Loading campaigns...
                    </NoItemsContainer>
                )
            }

        </CampaignFeedContainer>
    )
}

const CampaignFeedContainer = styled.div` 
    margin-top: 20px;
    display: flex;
    width: 100%;
    /* flow the elements in a row */
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; 

    @media (max-width: 768px){
        
        /* make just one item appear per line */ 
        justify-content: center;
        align-items: center;

    }


`