import React from 'react'
import styled from 'styled-components'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


export default function ChurchVerifySucess() {

  return (
    <ChurchVerifyCont>
        <CVText>Verification request submitted!</CVText>
        <CheckCircleOutlineIcon 
                style={{
                    fontSize: '50px',
                    marginBottom: '30px',
                    color: 'green'
                }} />

        <CVText>Please allow 1 - 2 business days for review.</CVText>
    </ChurchVerifyCont>
  )
}

const ChurchVerifyCont = styled.div`
        height: 100vh;
        width: 50vw;
        display: flex;
        flex-direction: column;
        width: fit-content;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: lighter;
        color: #4d4d4d;
        align-items: center;
`
const CVText = styled.div`
    width: 60%;
    
`