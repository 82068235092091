import React, {useEffect} from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getTransactions, setView } from 'features/giving/givingSlice'
import { ViewContainer, TransactionsTableContainer } from './giving.styled'
import TransactionItem from './TransactionItem'
 

export default function Transactions() {
    
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { 
        transactions,
        getTransactionsLoading,
        getTransactionsSuccess,
        getTransactionsError
    } = useSelector((state) => state.giving)

    useEffect(() => {
        // get transactions
        dispatch(getTransactions({
            churchId: profileChurch._id,
        }))
    }, [])
    



    return (
        <ViewContainer>
            <TransactionsTableContainer>
                <tbody>
                <tr>
                    <th>Date</th>
                    <th>Amount</th> 
                    <th>Description</th>
                    <th>Receipt</th>
                    <th>Status</th>
                </tr>

                {transactions.map((ele) => 
                (
                    <TransactionItem 
                        key={ele.id} 
                        data={ele} 
                        />
                ) 
                )}          
                </tbody>
            </TransactionsTableContainer> 

        </ViewContainer>
    )
}

