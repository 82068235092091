import React, { useEffect } from 'react'
import styled from 'styled-components'; 
import { setViewConfirmModal } from 'features/modal/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import useGetWindowSize from 'hooks/useGetWindowSize'; 
import SpinnerSmall from 'components/SpinnerSmall';

export default function ConfirmModal({ 
    text, 
    handler, 
    hasCloseHandler, 
    closeHandler, 
    doSomethingAfterClose,
    confirmBtnBackgroundHover, 
    extraInfo, 
    conditionToCloseModal,
    resetAfterClose,
    loadingState
}) {
 
    const [width, height] = useGetWindowSize() 
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(setViewConfirmModal(false))
    }

    useEffect(() => {
        if(conditionToCloseModal){
            if(doSomethingAfterClose){
                resetAfterClose()
            }
            
            dispatch(setViewConfirmModal(false))
        }
    }, [conditionToCloseModal, doSomethingAfterClose, dispatch, resetAfterClose])
    

    return ( 
        <ConfirmModalBody top={(height/2)-150}>
            <ConfirmModalText>
                <MainText>{text}</MainText> 
                {extraInfo && <ExtraInfoText>{extraInfo}</ExtraInfoText>} 
            </ConfirmModalText>
            <ConfirmButtonsWrap>
                <ConfirmButton
                    loading={loadingState}
                    hoverBackgroundColor={confirmBtnBackgroundHover}
                    hoverTextColor={'white'}
                    onClick={handler}
                    >
                    {loadingState ? <SpinnerSmall /> : 'Confirm'}
                </ConfirmButton>
                <ConfirmButton 
                    hoverBackgroundColor={'grey'}
                    hoverTextColor={'white'}
                    onClick={ handleClose }
                    >
                    Cancel
                </ConfirmButton>
            </ConfirmButtonsWrap> 
        </ConfirmModalBody> 
    )
}

const ConfirmModalBody = styled.div`
    width: 450px;
    top: ${props => props.top}px;
    min-height: 150px;
    max-height: fit-content;
    background: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px; 
    animation: animate 0.3s;
    padding: 20px;
    color: rgb(100,100,100);
    margin: 0 auto;
    vertical-align: middle;
    @media (max-width: 768px) {
        width: 99%;
    }
`

const ConfirmButtonsWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    text-align: center;
`
const ConfirmModalText = styled.div`
    width: 100%;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 16px;
    font-family: 'Arial', sans-serif;
    color: var(--fontColorGrey);
`

const ConfirmButton = styled.div`
    pointer-events: ${props => props.loading ? 'none' : 'auto'};
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    margin-bottom: 5px;
    padding: 10px 10px;
    width: 23%;
    border-radius: 5px;
    background-color: ${props => props.loading ? 'red' : 'white'};
    color: var(--lightThemeFontColor);
    font-size: 14px;
    border: 1px solid #c5c5c5;
    cursor: pointer;


    &:hover{
        background-color: ${props => props.hoverBackgroundColor ?? 'none'};
        color: ${props => props.hoverTextColor ?? 'none'};
        border: 1px solid ${props => props.hoverBackgroundColor ?? 'none'};
    }
`
const MainText = styled.div`
    font-size: 16px;
    margin-bottom: 10px;
 `

 const ExtraInfoText = styled.div`
    font-size: 12px;
    color: #a5a5a5; 
`