import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updatePassword } from '../../features/profile/profileSlice'

import { toast } from 'react-toastify'
import { TopHeaderSettings, CenterSettingsContainer } from './settings.styled';
import SpinnerSmall from '../SpinnerSmall'

function UpdatePassword(props) {
  const dispatch = useDispatch()
  const { isUpdateLoading } = useSelector((state) => state.profile)
  
  const I_STATE = {currentPassword: '', newPassword: '', newPassword2: ''}
  const [inputValue, setInputValue] = useState(I_STATE)
  const { currentPassword, newPassword, newPassword2 } = inputValue


  const handleChange = (e) => {
    setInputValue((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))

    console.log(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if ( (currentPassword.length || newPassword || newPassword2 ) < 8   ){ return toast.error('Check input...') }
    if(newPassword === newPassword2){
      setInputValue(I_STATE)
      return dispatch(updatePassword(inputValue))
    }else if( newPassword !== newPassword2){
      return toast.error('New Passwords Must Match')
    }
  }

  
  return (

      <CenterSettingsContainer>
        <TopHeaderSettings> Update Password </TopHeaderSettings>

        <div className='passwordWrap'>
          <div  className = "settingsUpdateForm"  >
            <input  
              value={currentPassword} 
              autocomplete='false' 
              type='password' 
              name='currentPassword' 
              placeholder="Current Password"
              onChange={handleChange} 
              />
          </div>
          <div className = "settingsUpdateForm"  >
            <input 
              value={newPassword} 
              autocomplete='off' 
              type='password' 
              name='newPassword' 
              placeholder="New Password" 
              onChange={handleChange} 
            />
          </div>
          <div className = "settingsUpdateForm">
            <input 
              value={newPassword2}
              autocomplete='off'
              type='password'
              name='newPassword2'
              placeholder="Re-enter New Password" 
              onChange={handleChange} 
              />
          </div>
        
        { isUpdateLoading ? 
                    (
                      <div  className='passwordSubmitButton'>
                          <SpinnerSmall />
                      </div>
                    )
                    :
                    (
                      <button 
                        className='passwordSubmitButton' 
                        onClick={handleSubmit}
                        
                        > 
                        Submit
                      </button>
                    )
                  }
        </div>
      </CenterSettingsContainer>
  )
}

export default UpdatePassword