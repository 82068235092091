import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl
//for sending the token
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }
const API_URL = '/api/settings/'

const verifyPassword = async (data, token) => {
  const response = await axios.post(URL+API_URL+'verifyPassword', data, config(token))
  return response.data
}

const resetEmail = async (data, token) => {
  const response = await axios.post(URL+API_URL+'resetEmail', data, config(token))
  return response.data
}

const updateUsername = async (data, token) => {
  const response = await axios.post(URL+API_URL+'updateUsername', data, config(token))
  return response.data
}

const updateFullName = async (data, token) => {
  const response = await axios.post(URL+API_URL+'updateFullName', data, config(token))
  return response.data
}

const settingService = {
    verifyPassword, resetEmail, updateUsername, updateFullName
}

export default settingService