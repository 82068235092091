import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import cvService from './cvService'

const errorMessage = (e) => {
    return (e.response && e.response.data && e.response.data.message) || e.message || e.toString()
  }

const initialState = {

    isVerifyError: false,
    isVerifySubmitSuccess: false,
    isVerifySubmitLoading: false,
    isVerifySubmitError: false,
    errorMessage: '',
    churchLocationState: ''
}

export const submitVerify = createAsyncThunk(
    'cv/submitVerify',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await cvService.submitVerify(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  
  export const cvSlice = createSlice({
    name: 'churchVerification',
    initialState,
    reducers: {
        resetCvSlice: (state) => initialState,
        setChurchState: (state, action) => {
          state.churchLocationState = action.payload
        }
    },
    extraReducers: (builder) => {
            builder
              .addCase(submitVerify.pending, (state) => {
                state.isVerifySubmitLoading = true
              })
              .addCase(submitVerify.fulfilled, (state, action) => {
                state.isVerifySubmitLoading = false
                state.isVerifySubmitSuccess = true
              })
              .addCase(submitVerify.rejected, (state, action) => {
                state.isVerifySubmitError = true
                state.isVerifySubmitLoading = false
                state.isVerifySubmitSuccess = false
                state.errorMessage = action.payload
              })
              //------------------
      }
    })

    
export const { resetCvSlice, setChurchState } = cvSlice.actions
export default cvSlice.reducer