import React from 'react'
import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close';

export default function ModalCloseButton({top = '20px', right='20px', handler, size = '50px', backgroundColor = 'white', hoverBackgroundColor = '#d7d7d7'}) {
    return (
        <ModalCloseButtonWrap
            backgroundColor={backgroundColor}
            hoverBackgroundColor={hoverBackgroundColor}
            top={top}
            right={right}
            onClick={handler}
            size={size}
            >
            <CloseIcon onClick={handler} />
        </ModalCloseButtonWrap>
    )
}

const ModalCloseButtonWrap = styled.div`
    top: ${props => props.top};
    right: ${props => props.right};
    position: absolute;
    width: ${props => props.size};
    height: ${props => props.size};
    border-radius: 50%;
    background-color: ${props => props.backgroundColor};
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--lightThemeFontColor);
    cursor: pointer;
    transition: 0.5s;
    &:hover{
        background-color: ${props => props.hoverBackgroundColor};
    }
`