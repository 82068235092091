import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const URL_GET_SUBSCRIPTION = '/api/billing/getSubscription'
const URL_CREATE_SUBSCRIPTION = '/api/billing/createSubscription'
const URL_GET_PAYMETHODS = '/api/billing/getPaymentMethods'
const URL_GET_SETPAYINTENT= '/api/billing/setPaymentIntent'
const URL_GET_ATTACH = '/api/billing/attachPaymentMethod'
const URL_GET_USER = '/api/billing/getUserData'
const URL_UPDATE_CARD = '/api/billing/updateCard'
const URL_GETTRANS = '/api/billing/getTransactions'

const config = (token) => { return { 
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}`, }, 
  } }
  

const createSubscription = async (data, token) => {
    const response = await axios.post(URL+URL_CREATE_SUBSCRIPTION, data, config(token))
    return response.data
}

const getSubscription = async (data, token) => {
    const response = await axios.post(URL+URL_GET_SUBSCRIPTION, data, config(token))
    return response.data
}

const getPaymentMethods = async (data, token) => {
    const response = await axios.post(URL+URL_GET_PAYMETHODS, data, config(token))
    return response.data
}

const setPaymentIntent = async (data, token) => {
    const response = await axios.post(URL+URL_GET_SETPAYINTENT, data, config(token))
    return response.data
}

const attachPaymentMethod = async (data, token) => {
    const response = await axios.post(URL+URL_GET_ATTACH, data, config(token))
    return response.data
}

const getUserData = async (data, token) => {
    const response = await axios.post(URL+URL_GET_USER, data, config(token))
    return response.data
}

const updateCard = async (data, token) => {
    const response = await axios.post(URL+URL_UPDATE_CARD, data, config(token))
    return response.data
}

const getTransactions = async (data, token) => {
    const response = await axios.post(URL+URL_GETTRANS, data, config(token))
    return response.data
}

const billingService = {
    createSubscription, 
    getSubscription, 
    getPaymentMethods, 
    setPaymentIntent,
    attachPaymentMethod,
    getUserData,
    updateCard,
    getTransactions
}

export default billingService