import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LanguageIcon from '@mui/icons-material/Language'; 
import { setAddPersonLinks, setAddPersonAddLinkButton } from 'features/profileChurch/profileChurchSlice';

export default function SocialMediaDropdown() {
    const dispatch = useDispatch()

    const { 
        addPersonAddLinkButton,
        currentAddPersonLink
     } = useSelector(state => state.profileChurch)

     const [ currentValue, setCurrentValue ] = useState('')
    const [ inputType, setInputType ] = useState('') 

    const handleSelection = (value) => {
        dispatch(setAddPersonAddLinkButton({
            status: true,
            linkName: value
        }))
    }

    const handleLinkInput = (value) => {
        setCurrentValue(value)
    }

    const handleSubmitLink = (value) => {

        setInputType('')
        dispatch(setAddPersonLinks({
            name: currentAddPersonLink,
            value: currentValue
        }))
    }

    const handleCancel = (value) => {
        setInputType('')
        setCurrentValue('')
        dispatch(setAddPersonLinks({
            name: currentAddPersonLink,
            value: ''
        }))
    }
    
    return (
        <SocialMediaMenu> 
            {
                (!addPersonAddLinkButton || currentAddPersonLink === 'facebook') && (
                    <LinkIconWrap onClick={() => handleSelection('facebook')} >
                        <IconWrapChild>
                        <FacebookIcon/>
                        </IconWrapChild>
                        {
                            currentAddPersonLink === 'facebook' && (
                                <input type="text" placeholder="Enter Facebook Link" onChange={(e) => handleLinkInput(e.target.value)} />
                            )
                        }
                    </LinkIconWrap>
                )   
            }
            {
                (!addPersonAddLinkButton || currentAddPersonLink === 'twitter') && ( 
                    <LinkIconWrap onClick={() => handleSelection('twitter')}  >
                        <IconWrapChild>
                        <TwitterIcon/>
                        </IconWrapChild>
                        {
                            currentAddPersonLink === 'twitter' && (
                                <input 
                                    type="text" 
                                    placeholder="Enter Twitter Link" 
                                    onChange={(e) => handleLinkInput(e.target.value)} />
                            )
                        }
                    </LinkIconWrap>
                )
            }
            {
                (!addPersonAddLinkButton || currentAddPersonLink === 'instagram') && (
                    <LinkIconWrap onClick={() => handleSelection('instagram')} >
                        <IconWrapChild>
                        <InstagramIcon/>
                        </IconWrapChild>
                        {
                            currentAddPersonLink === 'instagram' && (
                                <input type="text" placeholder="Enter Instagram Link"  onChange={(e) => handleLinkInput(e.target.value)}/>
                            )
                        }
                    </LinkIconWrap>
                )
            } 
            {
                (!addPersonAddLinkButton || currentAddPersonLink === 'linkedin') && (
                    <LinkIconWrap onClick={() => handleSelection('linkedin')} >
                        <IconWrapChild>
                        <LinkedInIcon/>
                        </IconWrapChild>
                        {
                            currentAddPersonLink === 'linkedin' && (
                                <input type="text" placeholder="Enter LinkedIn Link" onChange={(e) => handleLinkInput(e.target.value)} />
                            )
                        }
                    </LinkIconWrap>
                )
            }
            {
                (!addPersonAddLinkButton || currentAddPersonLink === 'youtube') && (

                    <LinkIconWrap onClick={() => handleSelection('youtube')}>
                        <IconWrapChild>
                            <YouTubeIcon/>
                        </IconWrapChild>
                        {
                            currentAddPersonLink === 'youtube' && (
                                <input type="text" placeholder="Enter YouTube Link" onChange={(e) => handleLinkInput(e.target.value)} />
                            )
                        }
                    </LinkIconWrap>
                )
            }
            {
                (!addPersonAddLinkButton || currentAddPersonLink === 'website') && (
                    <LinkIconWrap onClick={() => handleSelection('website')} selected={inputType === 'website'} > 

                        <IconWrapChild>
                            <LanguageIcon/>
                        </IconWrapChild>
                            
                        {
                            currentAddPersonLink === 'website' && (
                                
                                    <input 
                                        onChange={(e) => handleLinkInput(e.target.value)}
                                        type="text" 
                                        placeholder="Enter Website Link"  
                                        selected={inputType === 'website'}
                                        />                              
                                
                            )
                        }
                    </LinkIconWrap>
                )
            } 
                {
                    currentAddPersonLink !== '' && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <button 
                                style={{padding: '5px'}}
                                onClick={() => handleSubmitLink()}
                                >
                                Add
                            </button>
                            <div 
                                style={{
                                    width: 'fit-content',
                                    fontSize: '10px',
                                    color: 'grey',
                                    marginLeft: '10px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => handleCancel()}
                                >
                                cancel
                            </div>
                        </div>   
                    )
                }
 

        </SocialMediaMenu>

    )
}

const SocialMediaMenu = styled.div` 
    transition: all .5s ;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    top: 10px;
    margin-left: 20px;
    left: 30px;
    width: 100%;
    height: fit-content;
    color: black;
    font-size: ${props => props.show ? '10px' : '0px'};
    border-radius: 5px;
    /* border: 1px solid grey; */
    z-index: 9;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ${props => props.show ? '5px' : '0px'};

`

const LinkIconWrap = styled.div`
    margin: 0px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    height: 30px;
    width: 100%;
    cursor: pointer;
    input{
        margin-left: 10px;
    }
`
// jhkjkjhkjhlkjhkjh

const IconWrapChild = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    height: 30px;
    width: 30px;
    
    border-radius: 5px;
    background-color: grey;
    color: white;
    &:hover{
        background-color: #595959;
    }
`
