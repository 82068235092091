import styled from 'styled-components';

export const FormWrap = styled.div `
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #37bdc2;

    @media (max-width: 768px) {
        width: 95%;
    }

`

export const FormElement = styled.div `
    display: flex;
    width: 80%;
    flex-direction: column;
    background-color: #5ae6eb;
    padding: 0px 15px 15px 10px;
    margin-bottom: 15px;
    border:  ${props => props.isError ? ('1px solid red'):('none') };
    border-right:  ${props => props.isError ? ('4px solid red'):('none') };

    & input{
        border: none;
        background: transparent;
        outline: none;
        font-size: 17px;
        padding: 5px 0px ;
    }

    @media (max-width: 768px) {
    width: 95%;
  }

`

export const FormHeading = styled.div `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 12px;
    color: rgb(70,70,70);

    padding: 5px 0px ;
`



export const SubmitButton = styled.div`
    pointer-events: ${props => props.isDisabled ? ('none'):('auto') };
    margin-top: 15px;
    padding: 20px 40px;
    color: white;
    background-color: green;
    cursor: pointer;
    border-radius: 5px;
    &:hover{
      background-color: #03ad03;
    }

`

