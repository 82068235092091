import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux' 
import { tokenCheck, passwordUpdate, passwordResetState } from '../features/passwordReset/passResetSlice';
import { toast } from 'react-toastify'
import styled from 'styled-components';
import LockResetIcon from '@mui/icons-material/LockReset';
import Loader from 'loaders/Loader';

function ResetPassword() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { token, email, userType } = useParams() // dont need to destructure since I am passing the value to dispatch function
    const {
        isTokenCheckSuccess ,
        isTokenCheckError,
        isTokenCheckLoading,
        isSubmitLoading,
        isSubmitSuccess,
        submitmessage,
        isSubmitError,
        passwordResetSuccess
    } = useSelector((state) => state.isPassResetSuccess)



    const [formData, setFormData] = useState({
        password: '',
        password2: '', 
    })

    const { password, password2 } = formData

    useEffect(()=> {
        if (isSubmitError) {
            toast.error(submitmessage)
            }

        if(isSubmitSuccess){
            passwordResetState()
            navigate('/login')
            toast.success('Successfully updated password. Login to continue')
        }    


        dispatch(tokenCheck({
            token: token,
            email: email
        }))

    }, [dispatch, navigate, isSubmitError, email, submitmessage, token, isSubmitSuccess ] )

    if (isTokenCheckLoading) {
        return <Loader />
      }
    
    const onSubmit = () => {

        console.log("submitting")
        if(password2 !== password){
            return toast.error("Passwords do not match")
        }else{
            const passwordData = {
                email: email,
                password: password2,
                userType: userType,
                token: token
              }
              dispatch(passwordUpdate(passwordData))
              console.log("success")
        }

    }

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }))
    }


  return (
    <FPContainer> 
        

        {isSubmitError ? (<h3>Try again</h3>):
        (
        <ResetPForm>
            <StyledH2>
                <LockResetIcon />
                <span>Reset Password</span>
            </StyledH2>

            <FormGroup>
                <input
                    type='password'
                    className='form-control'
                    autoComplete="new-password"
                    id='password'
                    name='password'
                    value={password}
                    placeholder='Enter your new password'
                    onChange={onChange}
                    />
            </FormGroup>
            <FormGroup>
                <input
                    type='password'
                    className='form-control'
                    id='password2'
                    name='password2'
                    value={password2}
                    placeholder='Re-Enter your new password'
                    onChange={onChange}
                    />
            </FormGroup>

            <SubmitButtonP
                onClick={onSubmit}
                disabled={isSubmitLoading}
                > 

                Submit
              
            </SubmitButtonP>


        {isSubmitLoading ? (<Loader />):(null)  }
        {passwordResetSuccess ? (<p>{submitmessage}</p>):(null) } 

      </ResetPForm>
      )}

    </FPContainer>
  )
}

const FPContainer = styled.div`
    margin-top: 30px;
`

const FormGroup = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    input{
        padding: 20px;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0px 0px 10px #ccc;
        border: none;
        outline: none;
    }
`

const ResetPForm = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    margin: 0 auto;
    border-radius: 10px;
    padding: 30px;
    background-color: var(--lightThemeColor); 
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    color: var(--lightThemeFontColor);
    font-family: 'Roboto', sans-serif;
    font-weight: 300;

    form{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end; 
    }
`


const SubmitButtonP = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px #ccc;
    height: 40px;
    width: 100px;
    border-radius: 10px;
    color: white;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-top: 10px;
    background-color: var(--themeColor);
    
    pointer-events: ${props => props.disabled ? 'none':'all'};

`

const StyledH2 = styled.h2`
    margin-bottom: 30px;
    text-align: center; 
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: var(--lightThemeFontColor);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    svg{
        margin-right: 10px;
        font-size: 30px;
    }

`   

export default ResetPassword