import React, { useEffect } from 'react' 
import { useDispatch, useSelector } from 'react-redux'
import { addBank, setViewAddCardModal, setViewAddPaymentMethodModal } from 'features/giving/givingSlice' 
import styled from 'styled-components'  
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import SpinnerSmall from 'components/SpinnerSmall'

export default function AddBank() {

    const dispatch = useDispatch() 
    const {
        giveToChurchLoading, 
        clientSecret,
        addPaymentMethodLoading,
        addPaymentMethodError,
        addPaymentMethodSuccess
        
    } = useSelector((state) => state.giving)
    const { profileChurch } = useSelector((state) => state.profileChurch)

    const handleSubmit = async (e) => {
        try{
            e.preventDefault() 
            dispatch(addBank({
                churchId: profileChurch._id
            })) 
        }catch(e){
            console.log(e)
        }
    }


    useEffect(() => {
        if(addPaymentMethodSuccess){
            dispatch(setViewAddPaymentMethodModal(''))
        }
    }, [addPaymentMethodSuccess])

    return (
        <>
            {/* 
                nothing here
            */}
        </>
    )
}
