import {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../Spinner'
import {  } from '../../features/auth/authSlice'
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import { login, reset, loginOrganization } from '../../features/auth/authSlice'
import HaveAnAccount from './HaveAnAccount'
import ForgotPasswordLink from './ForgotPasswordLink'
import { setForgotPasswordUserType, setViewForgotPassword, setViewEmailConfirmModal } from '../../features/auth/authSlice'
import { FormWrap, FormElement, FormHeading, SubmitButton } from './login.styled'
import SpinnerSmall from 'components/SpinnerSmall'
import useKeypress from 'hooks/useKeypress'



function UserLoginForm() {


    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [errorField, setErrorField] = useState('')
    const [email, setEmail] = useState('')
    const [emailValid, setEmailValid] = useState()
    const [isEmailEdited, setIsEmailEdited] = useState(false)
    const { user, isLoginLoading, isError, isLoginSuccess, message } = useSelector( (state) => state.auth )
    const [password, setPassword] = useState('')
    const [passwordValid, setPasswordValid] = useState()
    const [isRedirect, setIsRedirect] = useState(false)
    const [redirect, setRedirect] = useState('')
    const formRef = useRef(null)

    const handleEmail = (e) => {
        let emailType = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setEmail(e.target.value)
        if ( emailType.test(e.target.value) ) {setEmailValid(true)}
        else {setEmailValid(false)}
        setIsEmailEdited(true)
      }


    useEffect(() => { 
 
        // console.log('Navigator: ', navigator.userAgentData)


        let val = sessionStorage.getItem('loginRedirect')

        if(val){
            setIsRedirect(true)
            setRedirect(val)
        } 
    }, [])


    const handleSubmit = () => { 
        const userData = { email, password }
        dispatch(login(userData))
    }
      
    const handlePassword = (e) => {
        setPassword(e.target.value)
        if(password.length > 5){ setPasswordValid(true) }
    }

      useEffect(() => {
        if (!user) {  navigate('/login') }
        if (isError) { toast.error(message) }

        if ( !isLoginLoading && (isLoginSuccess || user) && !(user.isChurch || user.isOrganization) ) {
            console.log('redirecting to home')
            navigate(`/home`)
        }
        else if( !isLoginLoading && (isLoginSuccess || user) && user.isChurch){
            console.log('redirecting to church profile')
            navigate('/home') 
        }

    // dispatch(reset()) 
    }, [ isLoginLoading, user, isError, isLoginSuccess, message, navigate, dispatch])

    const handleKeyPressSubmit = () => {


        if(document.activeElement === formRef.current){
            handleSubmit()
        }
    }
    
    useKeypress('Enter', handleKeyPressSubmit)

  return (
    <FormWrap
         
        >
        <FormElement isError={errorField === 'email' ? ('red'):(null) }>
            <FormHeading>Email</FormHeading>
                <input 
                    type={'text'} 
                    id='email'
                    name='email'
                    value={email}
                    placeholder=''
                    onChange={handleEmail}
                    autoComplete='off'
                /> 
        </FormElement>
        <FormElement isError={errorField === 'password' ? ('red'):(null) }>
            <FormHeading>Password</FormHeading>
                <input 
                    ref={formRef}
                    type={'password'} 
                    id='password'
                    name='password'
                    value={password}
                    placeholder=''
                    onChange={handlePassword}
                    autoComplete='off'
                /> 
        </FormElement>

        <ForgotPasswordLink onClick={() => {
            dispatch(setForgotPasswordUserType('user'))
            dispatch(setViewForgotPassword())
        }} />
        
        <SubmitButton 
            disabled={ !(emailValid && passwordValid) || isLoginLoading }
            onClick={handleSubmit}
            >
            {isLoginLoading ? <SpinnerSmall /> : 'Log In'}
        </SubmitButton>

        <HaveAnAccount />

    </FormWrap>
  )
}

export default UserLoginForm
