import React, { useState } from 'react'
import { useEffect } from 'react'
import styled from 'styled-components'
import TimeAgo from 'timeago-react'; 
import { imgUrl } from 'features/constants';
import { useNavigate } from 'react-router-dom';
import { closeSearchChurchModal } from 'features/memberships/membershipSlice';
import { useDispatch } from 'react-redux';

export default function ChurchSearchResultsItem(props) {
    const [show, setShow] = useState(false)
    const [length, setLength] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const handleGoTo = () => {
        dispatch(closeSearchChurchModal())
        
        navigate(`/${props.data.username}`)
    }

    useEffect(() => {
        try{
            let memberLength = Object.keys(props.data.members).length
            if(memberLength === 0){ setLength(' 0 ') }
            else if(memberLength > 0){ setLength(memberLength) }
        }catch(e){ setLength(null) }

    },[props.data.members])

    let newDate = new Date(props.data.createdAt)

    let profileImageToShow;
    try{ 
      profileImageToShow =  `${imgUrl}/profile/${props.data.profileImg.imagePath2}`}
    catch(e){  profileImageToShow =  `${imgUrl}/profile/${props.data.profileImg.imagePath2}`}

    
    
  return (
    <ResultsContainer 
        onMouseEnter={() => setShow(true)} 
        onMouseLeave={() => setShow(false)}
        >
        <SRCMain>
            <SRCImage> <img  src={profileImageToShow} alt={'church'} /> </SRCImage>
            <SRCData>
                <SRCName> {props.data.fullName} </SRCName>
                <SRCDataItems>
                    { length && ( <span>Members: { length }</span> )}
                    
                    <span>Joined: <TimeAgo datetime={newDate}/></span>

                </SRCDataItems>
            </SRCData>
            

        </SRCMain>
        { show && (
                    <GoToChurch onClick={handleGoTo}> 
                        View
                    </GoToChurch>
            )
        }
        

    </ResultsContainer>
  )
}

const ResultsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    padding: 10px;
    cursor: pointer;
   
    &:hover{
        background-color: #dfdfdf;
        border-radius: 10px;
    }

`
const SRCMain = styled.div`
    display: flex;
    flex-direction: row;
    font-size: smaller;
`

const SRCImage = styled.div`
    width: 30px;
    height: 30px;
    background-color: grey;
    border-radius: 50%;
    margin-right: 8px;
    img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        
    }
`

const SRCName = styled.div`
    display: flex;
    align-self: flex-start;
    justify-self: flex-start;
`
const SRCData = styled.div`
    display: flex;
    flex-direction: column;

    color: #333333;
`
const SRCDataItems = styled.div`
    display: flex;
    flex-direction: row;
    font-size: smaller;
`
const GoToChurch = styled.div`
    height: 80%;
    padding: 5px 20px;
    background-color: green;
    color: white;
    font-size: smaller;
    border-radius: 5px;
    &:hover{
        background-color: #00aa00;
    }
`