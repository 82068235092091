import React, { useEffect  } from 'react'
import { FormWrap, GiveForm, LeftSide, BottomWrap, PaymentProcessContainer } from '../giving.styled'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { cancelProcess } from 'features/giving/givingSlice' 
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; 
import { useNavigate, useLocation } from 'react-router-dom'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RestartAltIcon from '@mui/icons-material/RestartAlt';


export default function PaymentSuccess() {

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { 
        amount,
        description, 
        giveToChurchSuccess
    } = useSelector((state) => state.giving)

    const status =  new URLSearchParams(location.search).get('status') ? new URLSearchParams(location.search).get('status') : 'none'

    useEffect(() => { 
        return () => {
            dispatch(cancelProcess())
        } 
    }, [])

    useEffect(() => {
        if(status  === 'success' && !giveToChurchSuccess){
            navigate('?section=giving&v=give&status=initialize')
            dispatch(cancelProcess())
        }
    }, [status, navigate, giveToChurchSuccess])


    const handleNavigate = () => {
        navigate('?section=giving&v=give&status=initialize')
    }

    return (
        <PaymentProcessContainer>
            <FormWrap>
                <LeftSide>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',  width: '100%', }} >
                        <CFM>confirmed</CFM>
                        You have sent:
                    </div>
                    <h3>
                        <AttachMoneyIcon style={{fontSize: '40px' }} />
                        <span> { amount } </span>
                    </h3>
                    <div style={{   marginTop: '15px'  }} >  to {profileChurch.fullName} </div>
                    <CfmDesc> {description} </CfmDesc>
                </LeftSide>
                <GiveForm>
                    <SuccessWrap>
                        <CheckCircleIcon style={{fontSize: '100px', color: '#3c9d3c'}} />
                        <div style={{  fontSize: '15px',  color: '#777777', marginTop: '10px'}} >
                            Transaction processed successfully
                        </div>
                    </SuccessWrap>
                    <BottomWrap style={{ justifyContent: 'center' }}>
                        <CancelBtn onClick={handleNavigate} >
                            <RestartAltIcon style={{fontSize: '20px', marginRight: '5px'}} />
                            Start a new transaction
                        </CancelBtn>
                    </BottomWrap>
                </GiveForm>
            </FormWrap>
        </PaymentProcessContainer>
    )
}
 
const SuccessWrap = styled.div` 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
`

const CFM = styled.div`
    font-size: 12px;
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
    background-color: #3c9d3c; 
    margin-right: 10px;
`

const CfmDesc = styled.div`
    margin-top: 25px;
    font-size: 14px;
    width: 100%;
    height: fit-content;
    color: var(--lightThemeFontColor);
    background-color: #e1e1e1;
    padding: 10px;
    max-height: 200px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 0px;
    } 

`

const CancelBtn = styled.div` 
    color: #a8a8a8;
    font-weight: 500; 
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;
    border-radius: 5px; 
    border: 1px solid #ccc;
    &:hover{
        color: #515151;
        border: 1px solid #515151;
    }
`