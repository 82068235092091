import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { sendFeedback } from 'features/feedback/feedbackSlice'

export default function FeedbackForm(props) {

    const dispatch = useDispatch()
    const [details, setDetails] = useState('')
    const [subject, setSubject] = useState('')


    const handleSubmit = () => {
        dispatch(sendFeedback({
            subject: subject,
            details: details,
            feedbackType: props.type
        }))
    }
    

  return (
    <FFContainer>
        <FFTitle>{props.title} </FFTitle>
        <FFDescription>Please be concise with a description and details for the reason for your submission. </FFDescription>

        <FFSubject value={subject} onChange={(e) => setSubject(e.target.value)} placeholder='Subject: '></FFSubject>
        <FFText value={details} onChange={(e) => setDetails(e.target.value)}> </FFText>

        <FFSubmitButton onClick={handleSubmit} >Submit</FFSubmitButton>

    </FFContainer>

 

  )
}

const FFContainer = styled.div`
    color: #777777cc;
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 50px;
    @media (max-width: 668px) {
        width: 90vw;
        padding-bottom: 30px;
    }
`
const FFTitle = styled.h2`
    margin-top: 10px;

`

const FFDescription = styled.div`
    margin-bottom: 10px;
    text-align: left;
`
const FFSubject = styled.input`
    font-family: Arial, Helvetica, sans-serif;
    font-size: smaller;
    padding: 20px;
    resize: none;
    width: 50%;
    outline: none;
    border: 1px solid green;
    border-radius: 10px;
    margin-bottom: 10px;
    color: #202020; 
    @media (max-width: 668px) {
        border-radius: 5px;

    }
`

const FFText = styled.textarea`
    font-family: Arial, Helvetica, sans-serif;
    color: #202020; 
    padding: 20px;
    resize: none;
    width: 100%;
    height: 150px;
    outline: none;
    border: 1px solid green;
    border-radius: 10px;
    @media (max-width: 668px) {
        border-radius: 5px;
        height: 100px;
    }
`

const FFSubmitButton = styled.div`
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 20px;
    border: 1px solid grey;
    transition: .4s;
    &:hover{
        background-color: green;
        color: white
    }
`