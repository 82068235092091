import React from 'react'
import { convertTime, formatPrice } from 'components/settings/billing/stripeUtilityFuncs'
import { useSelector } from 'react-redux'


export default function PayoutsItem(props) {

    let date = convertTime(props.data.created)
    let price = formatPrice(props.data.amount)
    let arrivalDate = convertTime(props.data.arrival_date)

    const { destInfo, getPayoutsSuccess } = useSelector(state => state.giving)
 

    return (
        <tr>
            <td>{`${date.month}  ${date.day}  ${date.year}`}</td>
            <td>{price}</td>
            {
                getPayoutsSuccess && destInfo[props.data.destination] ?
                (
                    <td> {destInfo[props.data.destination].name} - {destInfo[props.data.destination].last4}   </td>

                )
                :
                (
                    <td> ... </td>
                )
            } 
            <td> {`${arrivalDate.month}  ${arrivalDate.day}  ${arrivalDate.year}`}  </td>
            <td>  {props.data.status}  </td>
        </tr>
    )
}
