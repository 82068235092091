import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const URL_CREATE = '/api/announcements/create'
const URL_GET = '/api/announcements/get'
const URL_DELETE = '/api/announcements/delete'


//for sending the token
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }


const createAnnouncement = async (data, token) => {
    const response = await axios.post(URL+URL_CREATE, data, config(token))
    return response.data
}

const getAnnouncements = async (data, token) => {
  const response = await axios.post(URL+URL_GET, data, config(token))
  return response.data
}

const deleteAnnouncement = async (data, token) => {
  const response = await axios.post(URL+URL_DELETE, data, config(token))
  return response.data
}

const announcementService = {
    createAnnouncement, deleteAnnouncement, getAnnouncements
}

export default announcementService