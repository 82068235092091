import React from 'react'
import styled from 'styled-components'
import { ICONS } from './icons'
import { useDispatch } from 'react-redux'
import { setUploadVideoType, setViewUploadVideoModal } from 'features/videos/videoSlice'
import { Tooltip } from '@mui/material'

const videoTypes = [
    {
        type: 'youtube',
        name: 'Youtube',     
        tooltip: 'Upload a YouTube video', 
    },
    {
        type: 'computer',
        name: 'My Computer', 
        tooltip: 'Upload from your computer',
    },
    {
        type: 'url',
        name: 'Video from URL',
        tooltip: 'From a URL on the web',
    }

]

export default function VideoUploadSelect() {

    const dispatch = useDispatch()

    const handleClick = (type) => {
        dispatch(setUploadVideoType(type))
        dispatch(setViewUploadVideoModal(true))
    }

    return (
        <VideoUploadSelectContainer>
            {
                videoTypes.map((videoType, index) => (
                    
                    <Tooltip title={videoType.tooltip} placement='top' >
                        <ItemType
                            key={index}
                            onClick={() => handleClick(videoType.type)}
                            >
                            {ICONS[videoType.type]}
                        </ItemType>
                    </Tooltip>
                ))
            }
        </VideoUploadSelectContainer>
    )
}

const VideoUploadSelectContainer = styled.div`
    width: 100%;
    height: fit-content; 
    display: flex;
    flex-direction: row;
    align-items: center; 
    margin-top: 10px;
`

const ItemType = styled.div`
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    color: var(--lightThemeFontColor);
    box-shadow: 0px 0px 10px #ccc;

    &:hover{
        cursor: pointer;
        background-color: #f5f5f5;
    }
`