import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { aboutChurchStore } from 'app/aboutChurchStore'
import { editChurchDescription } from 'features/profileChurch/profileChurchSlice'
import Loader from 'loaders/Loader'
import { toast } from 'react-toastify'



export default function EditDescriptionForm() {

  const dispatch = useDispatch()
  const { profileChurch, editingChurchDescriptionLoading, editingChurchDescriptionSuccess } = useSelector((state) => state.profileChurch)
  const setViewEditDescriptionModal = aboutChurchStore(state => state.setViewEditDescriptionModal)  
  const [ description, setDescription ] = useState('')

  const handleSubmit = () => { 

    if(description.length > 2000){
      return toast.error('Description must be less than 2000 characters')
    }

    dispatch(editChurchDescription({ 
      churchId: profileChurch._id,
      description: description 
    }))
  }

  const handleChange = (e) => {
    setDescription(e.target.value)
  }

  useEffect(() => {
    if(editingChurchDescriptionSuccess){
      setViewEditDescriptionModal(false)
    }
  }, [editingChurchDescriptionSuccess])

  useEffect(() => {
    setDescription(profileChurch.organization.description)
  }, [])

  return (
    <EditDescriptionFormContainer>
      <h2>Edit Description</h2>
      <ContentEditableDiv value={description} onChange={handleChange} > 
        {profileChurch.organization.description}
      </ContentEditableDiv>

      
        {/* <Editor
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onContentStateChange={setContentState}
        /> */}

        {/* <textarea
          disabled
          value={JSON.stringify(contentState, null, 4)}
        /> */}
 
      {
        description && (
          <span>
            Char length: 
            <CharCount exceeded={description.length > 2000}>
              {description.length}
            </CharCount> / 2000
          </span>          
        )
      }

 
      <SubmitButton 
        disabled={editingChurchDescriptionLoading}
        onClick={handleSubmit}
        >
          <span>Update Description</span>
          {
            editingChurchDescriptionLoading && <Loader color={'bgColorWhite'} />
          }
      </SubmitButton>
    </EditDescriptionFormContainer>
  )
}


const EditDescriptionFormContainer = styled.div`
  width: 100%;
  height: 100%; 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
  position: relative;
  h2 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    color: var(--textColor);
  }
`

const ContentEditableDiv = styled.textarea`
  width: 100%;
  height: 70%;
  margin: 20px;
  border: 1px solid grey;
  border-radius: 5px;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  padding: 10px;
  color: #696969;
  font-size: 17px;
  font-weight: 500;
  text-align: left;
`

const SubmitButton = styled.div`
  position: absolute;
  bottom: 10px;
  width: 95%;
  height: 40px;
  background-color: var(--btnSubmit);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  &:hover {
    opacity: 0.8;
  }
`

const CharCount = styled.span`
  color: ${props => props.exceeded ? 'red' : 'grey'};
`