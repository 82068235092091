import React, { useEffect, useRef, useState } from 'react'
import { FormWrap, LeftSide, GiveForm, BottomWrap, SubmitButton, 
    StyledInput, InputIconWrap
 } from '../giving.styled'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getMemberPaymentMethods, setSelectedPaymentMethod, giveToChurch, giveToChurchWithNewPaymentMethod, setView, resetGiveToChurch, createPaymentGivingIntent } from 'features/giving/givingSlice'
import { InputWrap } from 'components/forms/forms.styled'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import SpinnerSmall from 'components/SpinnerSmall'
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import { toast } from 'react-toastify'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';  
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import { baseUrl } from 'features/constants'
import { givingLanguage } from '../givingLanguage'

const URL = baseUrl


export default function Initialize() {

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { 
        createPaymentGivingIntentLoading,
        createPaymentGivingIntentSuccess, 
        paymentCards, 
        paymentBanks,
        clientSecret,
        amount,
        description
    } = useSelector((state) => state.giving)

    const status =  new URLSearchParams(location.search).get('status') ? new URLSearchParams(location.search).get('status') : 'none'
    
    const [currentAmount, setCurrentAmount] = useState(amount)
    const [currentDescription, setCurrentDescription] = useState(description)

    const [ errorField, setErrorField ] = useState({
        amount: false,
        description: false
    })

    const [intentLoading, setIntentLoading] = useState(false)

    const handleOnSelectOpen = () => { 
        if(paymentCards.length === 0 && paymentBanks.length === 0){
            dispatch(getMemberPaymentMethods({
                churchId: profileChurch._id, 
            }))
        }
    }

    const handleDescriptionChange = (e) => {

        if(errorField['description']) setErrorField({...errorField, description: false})

        setCurrentDescription(e.target.value)
    }

    const handleSelectionPayment = (event) => {
        dispatch(setSelectedPaymentMethod(event.target.value))   
    }

    const handleAmountChange = (e) => {

        if(errorField['amount']) setErrorField({...errorField, amount: false})

        // do not allow spaces
        if(e.target.value.includes(' ')){
            return
        }

        // only allow numbers
        if(isNaN(e.target.value)){
            return
        }
        // format with commas
        let formattedAmount = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        // should not be more that 10 digits
        if(formattedAmount.length > 10){
            return
        }
 
        formattedAmount = formattedAmount.replace(/\,/g, '') 
        setCurrentAmount(formattedAmount)
    }

    const handleSubmit = async () => {
        
        let hasErrors = checkForErrors()

        if(hasErrors){ 

            return
        }

        dispatch(createPaymentGivingIntent({
            amount: currentAmount,
            description: currentDescription,
            churchId: profileChurch._id
        }))
    }

    const checkForErrors = () => {

        if(currentAmount.length > 1 && currentDescription.length > 1){

            return false

        } else{
            
            setErrorField({
                amount: currentAmount.length < 1,
                description: currentDescription.length < 1
            })

            return true

        }
        
    }

    useEffect(() => {
        if(createPaymentGivingIntentSuccess && clientSecret){
            navigate(`?section=giving&v=give&status=confirm`)
        }
    }, [createPaymentGivingIntentSuccess, clientSecret])


    useEffect(() => {
        if(status !== 'initialize'){
            // reset any previous state from the payment process 
            navigate('?section=giving&v=give&status=initialize')
        }
    }, [])
 

    return (
        <FormWrap>
            <LeftSide>
                <div>You are giving:</div>
                <h3>
                    <AttachMoneyIcon style={{fontSize: '40px' }} />
                    <span> { currentAmount } </span> 
                </h3>
                <div style={{   marginTop: '15px'  }} >
                    to {profileChurch.fullName}
                </div>
            </LeftSide>
            <GiveForm>
                <StyledInput>
                    <label>Amount</label> 
                    <InputIconWrap
                        isError={errorField['amount']}
                        >
                        <input 
                            placeholder='Amount'
                            type="text" 
                            value={currentAmount.replace(/\,/g, '')}
                            onChange={handleAmountChange}
                            />
                    </InputIconWrap>
                </StyledInput> 
                <StyledInput>
                    <label>Description</label> 
                    <InputIconWrap
                        isError={errorField['description']}
                        >
                        <input 
                            placeholder='Description: e.g. Tithe, Offering, etc.'
                            onChange={handleDescriptionChange}
                            value={currentDescription}
                            type="text" 
                            />                        
                    </InputIconWrap> 
                </StyledInput>  
                <BottomWrap> 
                    <SubmitButton
                        style={{fontWeight: '500', width: 'fit-content', padding: '10px 20px'}}
                        onClick={handleSubmit}
                        disabled={createPaymentGivingIntentLoading}
                        >
                        { 
                            createPaymentGivingIntentLoading ? (
                                <SpinnerSmall />
                            )
                            :
                            (
                                givingLanguage.continueToPayment[user.preferredLanguage]
                            )
                        }
                    </SubmitButton>
                </BottomWrap>  
            </GiveForm>                 
        </FormWrap>
    )
}




