import ModalCloseButton from 'components/buttons/ModalCloseButton'
import { ModalWrap } from 'components/modal/modal.styled'
import React, {useState, useRef, useEffect} from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { createChurchFeedback, setViewChurchFeedbackModal, resetChurchFeedback } from 'features/profileChurch/profileChurchSlice'
import { VscFeedback } from "react-icons/vsc";
import { ButttonWrap, FileUploadButton } from 'components/files/files.styled'
import { LuBadgeInfo } from "react-icons/lu";
import { StyledHelpIcon } from 'components/settings/settings.styled'
import { Tooltip } from '@mui/material'
import SpinnerSmall from 'components/SpinnerSmall'
import { LuSend } from "react-icons/lu";
import { toast } from 'react-toastify'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { imgUrl } from 'features/constants';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import { addAc } from 'features/auth/authSlice'
import { addAcFunc } from 'funcs/ac'
import { language } from 'app/language'

export default function ChurchFeedbackModal() {

    const dispatch = useDispatch()
    const { profileChurch, churchFeedbackLoading, churchFeedbackSuccess, churchFeedbackMessage } = useSelector((state) => state.profileChurch)
    const [viewInfo, toggleViewInfo] = useState(false) 
    const textAreaRef = useRef(null)
    const [isAnonymous, setIsAnonymous] = useState(false)
    const { user } = useSelector((state) => state.auth)

    const handleSubmit = () => {

        if(textAreaRef.current.value === '') return toast.error('No feedback to submit')

        dispatch(createChurchFeedback({
            churchId: profileChurch._id, 
            content: textAreaRef.current.value,
            anonymous: isAnonymous
        }))
    }

    const handleAnonymous = () => {
        setIsAnonymous(!isAnonymous)
    }

    useEffect(() => {
        if(churchFeedbackSuccess) {
            toast.success('Feedback submitted successfully!')

            dispatch(resetChurchFeedback())

            dispatch(setViewChurchFeedbackModal(false))
        }
    }, [churchFeedbackSuccess])


    useEffect(() => {

        const dis = () => dispatch(addAc({
          type: 'feedback', 
        }))
    
        addAcFunc('feedback', dis)
    
      }, [])



    return (
        <ModalWrap
            height='550px'
            >
            <ModalCloseButton
                handler={() => dispatch(setViewChurchFeedbackModal(false))}
                top='15px'
                right='15px'
                size='35px'
                />

            <FeedbackModalWrap>
                <FeedbackMain>
                    <FeedbackHeading> 
                        {
                            language.feedbackHeading[user.preferredLanguage]
                        }
                    </FeedbackHeading>     
                    <Description>
                        {
                            language.feedbackDescription[user.preferredLanguage]
                        }
                    
                        <br/>
                        {
                            viewInfo ?
                            (
                                <i
                                    style={{
                                        fontSize: '12px',
                                        color: 'var(--lightThemeFontColor)',
                                        marginTop: '10px', 
                                    }}
                                    >
                                    {
                                        language.feedbackInfo[user.preferredLanguage]
                                    }
                                </i>
                            )            
                            :
                            (
                                <Tooltip title="More Info" placement='top'>
                                    <StyledHelpIcon
                                        hoverColor='#e3e3e3'
                                        onClick={() => toggleViewInfo(true)}
                                        >
                                        <LuBadgeInfo style={{ color: 'var(--lightThemeFontColor)'}}/>
                                    </StyledHelpIcon>
                                </Tooltip>                                
                            )           
                        }
                    </Description>

                    <NameSection>

                        {
                            isAnonymous ?
                            (
                                <>
                                    <NoAccountsIcon style={{width: '40px', height: '40px', borderRadius: '50%'}} alt='create-post'  />
                                    <span>
                                        {language.anonymous[user.preferredLanguage]}
                                    </span>                         
                                </>
     
                            )
                            :
                            (
                                <>
                                    <img    
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            marginRight: '10px'
                                        }}
                                        src={`${imgUrl}/profile/${user.imagePath}`}
                                        alt={user.fullName}
                                        />
                                    <span>{user.fullName}</span>
                                </>
                            )
                        }

 

                    </NameSection>

                    <FeedbackTextArea
                        ref={textAreaRef}
                        style={{width: '100%', height: '200px', padding: '10px', fontSize: '16px'}}
                        placeholder={language.startWriting[user.preferredLanguage]}
                        />
                </FeedbackMain>
                <ButttonWrap
                    justify='space-between'
                    >


                    <ATPEle
                        onClick={handleAnonymous}
                        isSelected={isAnonymous}
                        > 
                        <VisibilityOffIcon style={{color: isAnonymous && ('var(--themeColor)')}} /> 
                        <BtnText>
                            {
                                language.anonymous[user.preferredLanguage]
                            }
                        </BtnText> 
                    </ATPEle>



                    <FileUploadButton
                        width='150px'
                        disabled={churchFeedbackLoading}
                        onClick={handleSubmit}
                        > 
                        {
                            churchFeedbackLoading ?
                            (
                                <SpinnerSmall/>
                            ) 
                            :
                            (
                                <>
                                    <LuSend style={{marginRight: '10px'}}/>
                                    <span>
                                        {
                                            language.submit[user.preferredLanguage]
                                        }
                                    </span>
                                </> 
                            )
                        }
                    </FileUploadButton>
                </ButttonWrap> 
            </FeedbackModalWrap>
        </ModalWrap>
    )
}

const NameSection = styled.div`

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    color: var(--lightThemeFontColor);

`

const Description = styled.div`
    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
    font-weight: 400;
    color: var(--lightThemeFontColor);
`

const FeedbackModalWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
`

const FeedbackMain = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start; 
`

const FeedbackHeading = styled.h1`
    font-size: 20px;
    width: 100%; 
    margin-bottom: 20px;
    font-weight: 500;
    color: var(--lightThemeFontColor);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

`

const FeedbackTextArea = styled.textarea`
    width: 100%;
    height: 200px;
    padding: 10px;
    font-size: 16px;
    color: var(--lightThemeFontColor);
    font-weight: 400;
    border: 1px solid var(--borderColor);
    border-radius: 5px;
    resize: none;
    margin-bottom: 20px;
    font-family: Arial, Helvetica, sans-serif;

    ::placeholder{
        color: var(--lightThemeFontColor);
        font-weight: 400;
        font-family: Arial, Helvetica, sans-serif;
    }
`

const ATPEle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    margin-left: 20px;
    cursor: pointer;
    color: var(--lightThemeFontColor);
    border-radius: 10px;
    border:  ${props => props.isError ? ('1px solid red'):('none') };
    background-color: ${props => props.isSelected ? '#e7e7e7' : 'transparent'};

    &:hover{
        background-color: #e7e7e7;
        color: black;
    }

    @media (max-width: 768px) {
        padding: 5px;
        flex-direction: column;
    }

`
const BtnText = styled.span`
    margin-left: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 13px; 
    @media (max-width: 768px) {
        font-size: 11px;
    }
`