import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const URL_CREATE = '/api/notifs/createNotif'
const URL_GET = '/api/notifs/getNotifs'
const URL_UPDATE = '/api/notifs/update'
const URL_DELETE = '/api/notifs/delete'
const URL_DELETE_ALL = '/api/notifs/deleteAll'
const URL_SET_LAST = '/api/notifs/setLastNotif'

//for sending the token
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }

//---------------------------------------------------------------
//---------------------------------------------------------------

const createNotif = async (notifData, token) => {
  const response = await axios.post(URL+URL_CREATE, notifData, config(token))
  return response.data
}

// ---
const getNotifs = async (data, token) => {
  const response = await axios.post(URL+URL_GET, data, config(token))
  return response.data
}

// ---
const updateNotif = async (notif, token) => {
  const response = await axios.get(URL+URL_UPDATE, notif, config(token))
  return response.data
}


// ---
const deleteNotif = async (notif, token) => {
  const response = await axios.delete(URL+URL_DELETE, notif, config(token))
  return response.data
}

// ---
const deleteAllNotifs = async (token) => {
  const response = await axios.delete(URL+URL_DELETE_ALL, config(token))
  return response.data
}


const setLastNotif = async (token) => {
  const response = await axios.get(URL+URL_SET_LAST, config(token))
  return response.data
}

const updateMessageNotifs = async (data, token) => {
  const response = await axios.post(URL+'/api/notifs/updateMessageNotifs', data, config(token))
  return response.data
}


const notifService = {
  createNotif,
  getNotifs,
  updateNotif,
  deleteNotif,
  deleteAllNotifs,
  setLastNotif,
  updateMessageNotifs
}

export default notifService