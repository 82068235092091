import React from 'react'
import styled from 'styled-components'
import AddIcon from '@mui/icons-material/Add';

export default function CreateButton(props) {


  return (
    <CreateButtonContainer
        rightEnd={props.rightEnd}
        onClick={props.handler}
        >
        {
            props.customIcon ? (
                props.customIcon
            )
            :
            (
                <AddIcon />
            )
        }
        <span> {props.buttonText} </span>
    </CreateButtonContainer>
  )
}

const CreateButtonContainer = styled.div`
    padding: 8px 15px;
    border: 1px solid white;
    cursor: pointer;
    border-radius: 5px;
    background-color: white;
    color: var(--lightThemeFontColor);
    width: fit-content;
    box-shadow: 0 0 10px #ccc;
    display: flex; 
    flex-direction: row;
    align-items: center;
    margin-right: ${props => props.rightEnd ? "0px" : "15px"};
    transition: all 0.5s;

    &:hover{
        border: 1px solid var(--ltGreenButtonSolid);
        background-color: var(--ltGreenButtonSolid);
        color: white;

        svg{
            color: white;
        }
    }  
    // modify the icon
    svg{
        font-size: 17px;
        margin-right: 10px;
    }

    @media (max-width: 768px) {
        margin-bottom: 0px;

        span{
            font-size: 12px;
        }
    }

`
