
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import { TopHeaderSettings } from './settings.styled'


export default function MyChurchDirectory() {

  // const { isdirectoryLoading, isDirectorySuccess, directoryList } = useSelector((state) => state.directory)
  const dispatch = useDispatch() 
  const { user } = useSelector((state) => state.auth)
  // useEffect(() => { dispatch(getDirectory()) }, [ ])

  return (
    <SettingsContainer>

    <TopHeaderSettings> Manage My Church Directory </TopHeaderSettings>

      {
        user.isPremium ? 
        (
          <div>
             My Church Directory 
          </div> 
        )
        :
        (
          <div>
             Praysely Premium is required to access the online church directory.
          </div> 
        )
      }
    </SettingsContainer>
  )
}

const SettingsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

`
