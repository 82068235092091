import styled from 'styled-components';

export const AddBirthdayBtn = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
    background-color: white ;
  font-size: smaller;
  padding: 5px 8px;
  border-radius: 5px;
  color: gray;
  margin-bottom: 11px;
  box-shadow: 0px 0px 5px #ccc;

  cursor: pointer;
  svg{
    margin-right: 10px;
    color: gray;
  }
  transition: all 0.5s;
  &:hover{
    color: gray; 
  }
`

export const BottomInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

`

export const ModalWrap = styled.div`
  z-index: 100;
  width: 750px;
  min-height: 500px;
  height: fit-content;
  max-height: 620px;
  background: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 2px;
  position: relative;
  animation: animate 0.3s;
  padding: 10px;
  transition: all 0.5s;
  overflow-y: scroll;
  padding-bottom: 20px;
  padding-right: 20px;
  /* remove scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  &::-webkit-scrollbar {
      width: 0;
      height: 0;
  }


  @media (max-width: 708px) {
    height: 800px;
    width: 95%;
  }
`

export const BulletinFormTop = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--lightThemeFontColor);
    padding-left: 8px;
    justify-content: space-between; 
    border-radius: 8px 8px 0px 0px;
    @media (max-width: 768px) {
      height: fit-content;
      padding: 10px;
      font-size: 14px;
      svg{
        font-size: 20px;
      }
    }
`


export const ModalForm = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%; 
  padding: 15px;
  width:100%;
  justify-content: space-between;
  textarea{
    width: 100%;
    padding: 10px;
    border: 1px solid grey;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
  }
  textarea:focus{
    outline: none;
  }
  textarea::placeholder{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
    }
  @media (max-width: 768px) {
    flex-direction: column;
    
  }

`
export const AddUserInputWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    margin-top: 20px; 
    background-color: #eeeeee;
    padding: 30px;
    justify-self: center;
    align-self: center;
    border-radius: 2px;

    /*  add a classname style */

    .datePickerPraysely{ 
        width: 100%; 
        border: none;
        outline: none;
        border-radius: 5px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        margin-bottom: 10px;
        background-color: white;
    }

    @media (max-width: 768px) {
      padding: 20px;
    }

`

export const AddUserBtnWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
  margin-top: 20px;
  margin-bottom: 10px;
`

export const AddDirectoryMember = styled.div`
    border: 2px dashed grey;
    cursor: pointer;
    width: 100%;
    height: 60px;
    border-radius: 8px;
    align-self: center;
    justify-self: center;
    color: #5a5a5a;
    display: flex;
    font-size: smaller;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;
    &:hover{
      background-color: #efefef;
    }
`

export const AddImageBtnsWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
export const RemoveImage = styled.div`
    padding-left: 5px;
    cursor: pointer;
    font-size: 11px;
    color: rgb(80,80,80);
    &:hover{
      text-decoration: underline;
    }
`

export const ImagePlaceholder = styled.div`
    width: 100%;
    height: auto;
    background-color: rgb(200,200,200); 
    margin-bottom: 2px;
    border-radius: 8px;
`
export const AddImage = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 508px) {
      width: 65%;
      align-self: center;
      justify-self: center;
    }
`
export const ImageWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
`
export const ImgPreview = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 8px;
`

export const AddImageBtn = styled.label`
    display: flex;
    align-self: center;
    font-size: 13px;
    height: fit-content;
    width: fit-content;
    padding: 15px 30px;
    background-color: grey;
    color: white;
    border-radius: 5px;
    margin: 2px;
    cursor: pointer;
    &:hover{
      background-color: green;
      
    }

    @media (max-width: 768px) {
      width: 100%;
      align-items: center;
      justify-content: center;

    }
`

export const SideInfo = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between; 
    padding: 0px 10px 10px 10px;
    @media (max-width: 508px) {
      margin-top: 10px;
      width: 100%;
      align-self: center;
      justify-self: center;
    }
`

export const SideInfoTopWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;

`


export const AddUserBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  justify-content: center;
  background-color: var(--themeColor);
  font-size: smaller;
  padding: 10px 15px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: all 0.5s;
  &:hover{
    color: white;
    background-color: #0c860c;
  }
`

export const MembersList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
    height: fit-content; 
    min-height: 100px;
    /* hide scroll bar */

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
    z-index: 8;


`

export const MembersListHeading = styled.div`
  margin-top: 10px;
  margin-left: 7px;
  color: var(--lightThemeFontColor);

  @media (max-width: 768px) {
    font-size: 12px;
  }

`

export const MembersListWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-flow: wrap;
    padding: 5px;
    font-size: 10px;
    overflow-y: scroll;
    overflow-x: hidden;

    @media (max-width: 508px) {
      width: 100%;
      height: fit-content;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
    
`

export const MembersListItem = styled.div`
    display: flex;
    flex-direction: row;
    background-color: rgb(42, 146, 205);
    border-radius: 5px;
    color: white;
    padding: 5px;
    margin: 2px;
    height: fit-content;
    font-size: 12px;
    position: relative; 
    z-index: 9;

    .deleteMember{
      visibility: hidden;
      position: absolute;
      top: 0px;
      right: 0px;
      margin-top: -10px;
      margin-right: -10px;
      color: #5e2a2a;
      cursor: pointer;
    }

    .membersListItemToolTip{
      visibility: hidden;
      display: flex;
      flex-direction: column;
      background-color: rgba(43, 43, 43, 0.644);
      border-radius: 0px 10px 10px 10px;
      color: white;
      padding: 5px;
      margin-left: 15px;
      margin-top: 10px;
      font-size: 11px;
      position: absolute;
      align-items: flex-start;
      justify-content: flex-start;
      z-index: 99;
    }
    &:hover{
      .deleteMember{
        visibility: visible;
      }
      .membersListItemToolTip{
        visibility: visible;
      }
    }

`





export const InputWrapHeading = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 5px;
  font-size: 12px;
  color: var(--lightThemeFontColor);
  input{
    padding: 10px;
    width: 100%;
  }
`

export const InputWrap = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    font-size: 12px;
    transition: all 0.7s;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: white;
    padding-left: 10px;

    svg {
      color: var(--lightThemeFontColor);
    }

    input{  
      border: none;
      outline: none;
      padding: 15px;
      width: 100%;
      outline: none;
      border-radius: 4px;
    }

    textarea{
      width: 100%;
      font-size: 12px;
      border: none;
      outline: none;
      padding: 20px 10px;
      font-family: Arial, Helvetica, sans-serif;

    }
    textarea::placeholder{
      font-family: Arial, Helvetica, sans-serif;
      font-size: 12px;

    }

    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
`

export const IssueSubmit = styled.button`
    width: fit-content;
    border: none;
    background-color: green;
    margin-top: 15px;
    padding: 15px;
    color: white;
    border-radius: 5px;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    cursor: pointer;
    height: 50px;
    min-width: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

  &:hover{ 
    background-color: rgb(0, 156, 0);
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }

`

export const SubmitWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 20px; 
`


