import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveSection, setViewChurchFeedbackModal } from 'features/profileChurch/profileChurchSlice'
import {
    PCNavLi,
    PCNavLiTitle,
    PCNavLiTitleIcon, 
} from './extras.styled'
import { ICONS } from './navIcons'
import { language } from 'app/language'

export default function ChurchProfileNavMenu({items}) {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { profileChurch, activeSection } = useSelector((state) => state.profileChurch)

    const handleClick = (value) => {

        if(value === 'feedback'){
            // open feedback modal
            dispatch(setViewChurchFeedbackModal(true))
            return
        }

        navigate(`/${profileChurch.username}?section=${value}`)
        dispatch(setActiveSection(value))
    }

    return (
        <MenuContainer>

            {
                <NavMenuTitleForMobile>
                    { profileChurch.fullName + language.churchNavigation[user.preferredLanguage]}
                </NavMenuTitleForMobile>
            }

            {items.map((item, index) => { 
                if(item.enabled){
                    return (
                        <MenuItem 
                            key={item.value}
                            onClick={() => handleClick(item.value)}
                            >
                            <PCNavLiTitleIcon>
                                {ICONS[item.value]}
                            </PCNavLiTitleIcon>    
                            <PCNavLiTitle> 
                                {language[item.label][user.preferredLanguage]}
                            </PCNavLiTitle> 
                        </MenuItem>
                    )
                }
            })}
        </MenuContainer>
    )
}

const NavMenuTitleForMobile = styled.div`

    display: none;

    @media (max-width: 768px) {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        color: var(--lightThemeFontColor);
        width: 100%;
        font-size: 18px; 
        margin-bottom: 10px;
    }

`

const MenuContainer = styled.div`
    position: absolute;
    top: 45px;
    right: 0px;
    background-color: #fff;
    width: fit-content;
    min-width: 220px;
    height: fit-content;
    z-index: 100;
    padding: 8px;
    border-radius: 10px 0px 10px 10px;
    box-shadow: 0px 0px 10px #ccc;


    @media (max-width: 768px) {
        width: 90%;
        height: 80vh;
        top: 50px;
        right: 23px;
        padding: 11px 15px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
        flex-wrap: wrap;
        overflow: auto;
        background-color: #eaeaea;

    }
`
const MenuItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 25px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 10px;
    color: var(--lightThemeFontColor);
    &:hover {
        background-color: #eee; 
    }

    margin-bottom: 5px;
    
    @media (max-width: 768px) {
        padding: 10px 20px;
        width: 40%;
        flex-direction: column;
        align-items: center;
        justify-content: center;  
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 0px 10px #ccc;
    }

`

