import React from 'react'
import styled from 'styled-components'
import { MarketContainerMainContentWrapper } from '../../market.styled'

export default function MySavedListingsContainer() {
    return (
        <MarketContainerMainContentWrapper>
            
        </MarketContainerMainContentWrapper>
    )
}
