
import {React, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import styled from 'styled-components' 
import { createIssue, reportIssueReset } from '../../../features/reportIssue/reportIssueSlice'
import { setViewReportIssueModal  } from 'features/settings/settingSlice'
import { ModalContainerMain } from 'components/modal/modal.styled';
import ModalCloseButton from 'components/buttons/ModalCloseButton';

function ReportIssueModal() {
  const dispatch = useDispatch()
  const [text, setText] = useState('') 
  const { isIssueSuccess, isIssueLoading } = useSelector((state) => state.issues)

  const handleChange = (e) => {
    if(text.length > 150){return toast.error('Not allowed')}
    setText(e.target.value.replace(/[\|&;\$%@"<>\(\)\+#{}]/g, "")); 
  }

  const handleSubmit = () => {

    if(text.length > 11){
      dispatch(createIssue({ 
        issueMessage: text,
      }))
    }
  }

  useEffect(() => {
      if(isIssueSuccess){
        toast('Report submitted. Thank you!')
        dispatch(reportIssueReset())
        dispatch(setViewReportIssueModal(false))
      }
  }, [isIssueSuccess, dispatch])

  return (
    <ModalContainerMain> 
      <IssueModalWrap>
        <ModalCloseButton
          handler = {() => dispatch(setViewReportIssueModal(false))}
          size= '35px'
          top='10px'
          right='10px'
          />

        <ModalHeading>Report An Issue</ModalHeading>
          <IssueModalForm>
            <textarea value={text} onChange={handleChange} rows = "5" cols = "30" maxlength = "150"  placeholder='Tell us here...'/>
            <IssueSubmit 
              disabled={isIssueLoading}
              onClick={handleSubmit}> 
              Submit 
            </IssueSubmit>
          </IssueModalForm>
        </IssueModalWrap>

    </ModalContainerMain>
  )
}

export default ReportIssueModal

const IssueModalWrap = styled.div`
  z-index: 0;
  width: 450px;
  min-height: 320px;
  max-height: fit-content;
  background: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  border-radius: 8px;
  position: relative;
  animation: animate 0.3s;
  padding: 5px 5px 20px 5px;
`

const ModalHeading = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 30px;
  color: var(--lightThemeFontColor);
  font-family: 'Roboto', sans-serif;
`
const IssueModalForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  margin-top: 10px;
  font-family: 'Roboto', sans-serif;
  textarea{
    resize: none;
    padding: 10px;
    border: 1px solid green;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
  }
  textarea:focus{
    outline: none;
  }
  textarea::placeholder{
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    }

`

const IssueSubmit = styled.button`
  pointer-events: ${props => props.disabled ? 'none':'auto'};
  border: none;
  background-color: green;
  margin-top: 15px;
  padding: 15px;
  color: white;
  border-radius: 5px;
  &:hover{
    cursor:pointer;
    background-color: rgb(0, 156, 0);
  }
`
