
import {useSelector, useDispatch} from 'react-redux'
import { createIssue, getIssues } from '../../features/reportIssue/reportIssueSlice'
import styled from 'styled-components';

function ReportIssues() {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { issues, areIssuesLoaded } = useSelector((state) => state.issues)

    const handleClick = () => {
        dispatch(createIssue({
            creatorId: user._id,
            issueType: "sometype of Issue",
            message: "message text"
        }))
        console.log('reporting issue')
    }

    const handleShowIssues = () => {
        dispatch(getIssues())
    }

    return (

        <IssueContainer>
         
            <h1>Submitted Issues</h1>
            <button onClick={handleClick}>Send Issue</button>
            <button onClick={handleShowIssues}>Show Issues</button>
            {areIssuesLoaded && issues.length > 0 ?(
                issues.map((ele) => (
                    <div> {ele.message}</div>
                ))
            ):areIssuesLoaded && issues.length ===0 ?
            (
                <div>no issues</div>
            ):(
                <div>null</div>
            )}


        </IssueContainer>

  )
}

export default ReportIssues

const IssueContainer = styled.div`
    background-color: white;
    border: 1px solid grey;
    border-radius: 5px;
    width: 300px;
    min-height: 200px;
    margin: 30px auto;
    box-sizing: border-box;
`;