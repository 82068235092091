import React from 'react'
import styled from 'styled-components'
import CreateListingButton from './components/CreateListingButton'
import ListingSearchContainer from './listingFeed/ListingSearchContainer'
import LocationFilter from './listingFeed/LocationFilter'
import { SettingsLi } from 'components/settings/settings.styled'
import BookIcon from '@mui/icons-material/Book';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ForumIcon from '@mui/icons-material/Forum';
import { useNavigate } from 'react-router-dom'

export default function MarketSidebar() {

    const navigate = useNavigate()

    const handleNavigate = (path) => {
        navigate(`/market/${path}`)
    }
    
    
    return (
        <MarketSidebarContainer>

            <SectionWrap>
                <ListingSearchContainer />
                <LocationFilter />   

                <LinksSection>
                    <SidebarItem
                        onClick={() => handleNavigate('my-saved-listings')}
                        > 
                        <BookIcon /> <span>Saved Listings</span>  
                    </SidebarItem>
                    <SidebarItem
                        onClick={() => handleNavigate('my-listings')}
                        > 
                        <AccountBoxIcon /> 
                        <span>Created Listings </span>
                    </SidebarItem>
                    <SidebarItem> 
                        <ForumIcon /> 
                        <span>Market Converstations</span> 
                    </SidebarItem>
                </LinksSection>


            </SectionWrap>
        
            <CreateListingButton />
        </MarketSidebarContainer>
    )
}

const MarketSidebarContainer = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    height: calc(100vh - 60px)  ;
    border-right: 1px solid var(--borderColor);
    padding-bottom: 50px;
`
const SectionWrap = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;


`

const LinksSection = styled.div`

    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;


`

const SidebarItem = styled.div`
    display: flex;
    text-align: left;
    padding: 8px 30px;
    margin-bottom: 10px;
    font-size: 14px;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: 100%;
    color: var(--lightThemeFontColor);
    transition: all 0.4s;
    border-radius: 5px;

    svg{
        margin-right: 10px;
    
    }

    &:hover{
        background-color: rgb(221, 221, 221);
        cursor: pointer;
        border-radius: 5px;
        transition: all 0.4s;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 4px 10px;
        font-size: x-small;
        transition: all 0.4s;
    }
`