export const givingLanguage = {

    giveToChurch: {
        en: 'Give to Church',
        es: 'Dar a la iglesia',
    },

    give:   {
        en: 'Give',
        es: 'Dar',
    },

    viewTransactions: {
        en: 'View Transactions',
        es: 'Ver transacciones',
    },

    paymentMethods: {
        en: 'Payment Methods',
        es: 'Métodos de pago',
    },

    amount: {
        en: 'Amount',
        es: 'Cantidad',
    },

    description: {
        en: 'Description',
        es: 'Descripción',
    },

    youAreGiving: {
        en: 'You are giving:',
        es: 'Estás dando:',
    },

    to: {
        en: 'to',
        es: 'a',
    },

    continueToPayment: {
        en: 'Continue to Payment Method',
        es: 'Continuar al método de pago',

    },

}