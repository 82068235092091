
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCloseEmailConfirmModal } from '../../features/auth/authSlice'
import CodeForm from './CodeForm'
import { ModalContainerMain } from 'components/modal/modal.styled'

function EmailConfirmationModal() {
  const dispatch = useDispatch()

  const { isVerified } = useSelector((state) => state.auth)

  
  useEffect(() => {
    if(isVerified){
      dispatch(setCloseEmailConfirmModal())
    }
  }, [isVerified, dispatch ])

  return (
    <ModalContainerMain>

      <CodeForm />

    </ModalContainerMain>
  )
}

export default EmailConfirmationModal

