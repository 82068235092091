import { useState, useEffect, useRef}  from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getProfilePosts, postResetSaved } from '../features/posts/postSlice' 
import PostItem from './PostItem'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { logoutUserFaraSesiune, resetUserForLogout } from '../features/auth/authSlice'
import { setConfirmModalData, setViewConfirmModal } from 'features/modal/modalSlice'
import ConfirmModal from 'components/modals/ConfirmModal' 
import NoPosts from './postComponents/NoPosts'
import Modal from '@mui/material/Modal';
import Loader from 'loaders/Loader'
import AddContent from './userProfile/AddContent'
import useGetWindowSize from 'hooks/useGetWindowSize'
import GoToChurch from './userProfile/GoToChurch'

function MyPostFeed(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [url, setUrl] = useState('my-posts')
    const [urlLocation, setUrlLocation] = useState(window.location.href)
    const { user, admin, moderator } = useSelector((state) => state.auth) 
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const [isModerator, setIsModerator] = useState(false)
    const { viewConfirmModal, confirmModalData } = useSelector((state) => state.modal)
    const [width, height] = useGetWindowSize()

    const { 
        posts, 
        isPostLoading, 
        isPostError, 
        postsMessage,
        currentPage,
        filterValue, 
        isPostSaved,
        isPostDeletedSuccess,
      } = useSelector((state) => state.posts)

    const listInnerRef = useRef();


    useEffect(() => {
      if(isPostDeletedSuccess){
        dispatch(setConfirmModalData({}))
        dispatch(setViewConfirmModal(false))
      }
    }, [isPostDeletedSuccess, dispatch])

    useEffect(() => {
      if (isPostError) {
        toast.error(postsMessage)
        if(postsMessage === "Not logged in"){
          dispatch(logoutUserFaraSesiune())
          dispatch(resetUserForLogout())
          navigate('/login')
          window.location.reload()
        }
      }
    }, [isPostError, postsMessage, dispatch, navigate])

    useEffect(() => {
      if(isPostSaved){
        toast.success('Post Saved',
          {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
        dispatch(postResetSaved())
      }
    }, [isPostSaved, dispatch])

    useEffect(() => {
      if(!user.isOrganization && !user.isChurch){

        if(urlLocation.includes('home') && (!user.isOrganization)){
          setUrl('my-posts')
        }else if(urlLocation.includes('my-posts')){
          setUrl('my-posts')
        }else if(urlLocation.includes('saved-posts')){
          setUrl('saved-posts')
        }

      }  
    }, [user, urlLocation, url])


    useEffect(() => {
      
      dispatch(getProfilePosts({
        churchName: null,
        currentPage: currentPage,
        filter: filterValue,
        posts: url,
        sort: null
      }))

    }, [])



  return (
    <PostFeedContainer
      ref={listInnerRef}
      style={{ height: "80vh" }}
      > 

      <AddContent />

          {posts && posts.length > 0 ? (
            <div className='posts'>
                {posts.map((post, index) => (
                    <PostItem 
                      key={post._id} 
                      index={index} 
                      postId={post._id} 
                      post={post} 
                      isAdmin={admin[profileChurch._id] || user.isOrganization}
                      isModerator={moderator[profileChurch._id]}
                    />
                ))}
            </div>
          ):isPostLoading && !isPostError ?  
          (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
              <Loader />
            </div>
          )
          :
          (
            <NoPosts />  
          )}

        {
          (width < 768 && posts && posts.length < 2) && (
            <GoToChurch />
          )
        }

      <Modal
        open={viewConfirmModal}
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        onClose={() => dispatch(setViewConfirmModal(false))}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        > 
        <ConfirmModal 
          text={confirmModalData.text} 
          handler={confirmModalData.handler} 
          confirmBtnBackgroundHover={confirmModalData.confirmBtnBackgroundHover}
          extraInfo={confirmModalData.extraInfo}
          /> 
      </Modal>
        
    </PostFeedContainer>
  )
}


export default MyPostFeed


const PostFeedContainer = styled.div` 
    padding: 00px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 10px;
    width: 100%;   
    height: 100%;
    overflow-y: scroll;
    /* remove scroll bar width */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
    z-index: 1;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    @media (max-width: 768px) {
        padding: 10px;
    }
`