import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { test2GetProfilePosts, getProfilePosts, clearPosts, postReset, setMyPostsPage, setPostsView } from '../../features/posts/postSlice'
import '../../css/postItemSelectDropDown.css'
import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowDown, MdEmojiPeople, MdFilterListAlt, MdOutlineFilterList } from 'react-icons/md';
import { FaPrayingHands, FaHands } from 'react-icons/fa';
import { IoMdRefreshCircle } from 'react-icons/io';
import Spinner from '../Spinner';
import useToggleDropdown from '../../hooks/useToggleDropdown'
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import MenuBookIcon from '@mui/icons-material/MenuBook';

function ChoosePostPage( {profileName} ) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const ref1 = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref1)

    const { profile } = useSelector((state) => state.profile)
    const { filterValue, myPostsPage, userProfilePostsView } = useSelector((state) => state.posts)





    const handleClick = (value) => {
        dispatch(setMyPostsPage(value))
        dispatch(setPostsView(value))

        dispatch(getProfilePosts({
            churchName: null,
            currentPage: 1,
            filter: null,
            posts: value,
        }))
    }

    return (
        <CPPCont>
            {profile? (
                    <PostDropDown>
                        <PostSelect ref={ref1}  onClick={() => setIsDropped.toggle(ref1)} >
                            <span> View </span>
                            {isDropped ? (
                                <MdOutlineKeyboardArrowDown className='xDotsIcon' />
                                ):(
                                    <MdOutlineKeyboardArrowRight className='xDotsIcon'/>
                                )}
                        </PostSelect>

                        {isDropped && (
                            <PostSelectUl>

                                {userProfilePostsView !== 'my-posts' &&(
                                        <PostViewLI onClick={() => handleClick('my-posts')}>
                                            <PermIdentityIcon className='postTypeIcons' /> <span> My Posts </span>
                                        </PostViewLI>   
                                    )
                                }
                                {userProfilePostsView !== 'saved-posts' &&(
                                        <PostViewLI onClick={() => handleClick('saved-posts')}>
                                            <SaveAltIcon className='postTypeIcons' /><span>Saved Posts</span>
                                        </PostViewLI>    
                                    )
                                }
                            </PostSelectUl>  
                        )}
                    </PostDropDown>
                ):(
                    <p> <Spinner /> </p>
                )
            }
        </CPPCont>
    )
}

export default ChoosePostPage

const CPPCont = styled.div`
`

const PostDropDown =styled.div`
    margin-bottom: 0px; 
`
const PostSelect = styled.div` 
    color: var(--lightThemeFontColor);
    border-radius: 15px;
    padding: 10px 25px;
    background-color: white;
    box-shadow: 0 0 5px #ccc;
    width: fit-content;
    height: 40px;
    white-space: nowrap;
    font-size: smaller;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    span{
        margin-right: 5px;
    }
    @media (max-width: 768px) {
        display: flex;
        flex-direction: row;
        align-items: center; 
    }
`

const PostSelectUl = styled.ul`
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    background-color: white;
    z-index: 4;
    margin-top: 10px;
    border-radius: 0px 10px 10px 10px;
    /* box shadow all around */
    box-shadow: 0 0 5px #ccc;

    padding: 5px;

`

const PostViewLI = styled.div`
    color: rgb(125, 125, 125);
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    &:hover{
        
    background-color: rgb(238, 238, 238);
    }
`