import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    viewDirectoryModal: false,

    editDirectory: false,
    createDirectory: false,
    viewCard: false,

    imageFile: null,
    data: null,
    churchId: null,
    familyName: null,
    userLinkedId: null,
    familyMembers: [],
    directoryImage: {},
    directoryImagePreview: {},
    selectedImage: {},
    isImageSuccess: false,
    isImageLoading: false,
    isImageError: false,

    viewAddPersonModal: false,
  }

export const directoryModalSlice = createSlice({
    name: 'directoryModal',
    initialState,
    reducers: {
      setViewDirectoryModal: (state, action) => { 
        state.viewDirectoryModal = true
        state.editDirectory = action.payload.editDirectory
        state.createDirectory = action.payload.createDirectory
        state.viewCard = action.payload.viewCard
        state.data = action.payload.data
      },
      setViewAddPersonModal: (state, action) => { state.viewAddPersonModal = action.payload },
      closeDirectoryModal: (state) => initialState,
      resetDirectoryModal: (state) => { state.viewDirectoryModal = initialState },
      addFamilyMember: (state, action) => {state.familyMembers.push(action.payload)},
      deleteFamilyMember: (state, action) => { state.familyMembers = state.familyMembers.filter(ele => ele.name !== action.payload.name)},
      setDirectoryImagePreview: (state, action) => {state.directoryImagePreview = action.payload},
      chooseDirectoryImage: (state, action) => {state.selectedImage = action.payload},
      removeDirectoryImage: (state) => {
        state.directoryImagePreview = initialState.directoryImagePreview
        state.selectedImage = {}
      },
      },
    
})

export const { 
    setViewDirectoryModal, 
    closeDirectoryModal, 
    resetDirectoryModal, 
    addFamilyMember,
    deleteFamilyMember,
    setDirectoryImagePreview,
    chooseDirectoryImage,
    removeDirectoryImage,
    setViewAddPersonModal,
} = directoryModalSlice.actions
export default directoryModalSlice.reducer