import { useState } from "react";
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from "react-redux";
import AddCardIcon from '@mui/icons-material/AddCard';
import { attachPaymentMethod, setViewAddCardModal } from 'features/billing/billingSlice'
import { CardElement,  useStripe,  useElements } from '@stripe/react-stripe-js';
import Loader from "loaders/Loader";
import Switch from '@mui/material/Switch';

export default function AddCardModal(props) {
    const dispatch = useDispatch()
    const stripe = useStripe();
    const elements = useElements();
    
    const {
        allowMakeDefaultOption,
        isSetPaymentMethodsLoading,
      } = useSelector((state) => state.billing)
    
    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
      setChecked(event.target.checked);
    };

    const handleAddCard = async (e) => {
        e.preventDefault()
        
        const cardElement = elements.getElement(CardElement);

        const {paymentMethod, error} = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,

        });
      
        if(!error && paymentMethod){
          dispatch(attachPaymentMethod({ 
            setDefault:  checked ? true : false,
            paymentId: paymentMethod.id
          
          }))
        }
        if(error){
          console.log(error)
        }
      }

  return (
    <AddCardModalContainer>
      <ACModalContainer loading={isSetPaymentMethodsLoading} >
        <CreateXFormTop>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
              <AddCardIcon style={{color: 'white', fontSize: '30px', marginLeft: '10px', marginRight: '10px'}} />  
              <AnnouncementModalHeading>Add Card</AnnouncementModalHeading>
          </div>
          <CloseBtn onClick={() => dispatch(setViewAddCardModal())}><CloseIcon style={{color: 'white'}} /></CloseBtn>
        </CreateXFormTop>  

        <CardForm>
          <CardLabel htmlFor="card-element"> { isSetPaymentMethodsLoading ? ('Adding your payment method...'):('Add a Payment Method')}</CardLabel>
                
          { isSetPaymentMethodsLoading ?
            (
              <LoaderWrap>
                < Loader />
              </LoaderWrap>
              
            )
            :
            (
              <CardElement id="card-element" />
            )
          }

          <CardLower>
            <span>
                You will NOT be charged now. By providing your card information, you allow Praysely Inc to charge your card upon initial confirmation of subscription and for recurring payments in accordance with the terms of service and your subscription information
                Payments are securely managed by <u><a target="_blank" rel="noreferrer" href="https://stripe.com/">Stripe</a></u>. 
            </span>

            <AddCardBtn 
              onClick={handleAddCard} 
              disabled={isSetPaymentMethodsLoading}
              >
                { isSetPaymentMethodsLoading ? 
                  (
                    'Processing'
                  )
                  :
                  (
                    'Add Card'
                  )
                }
            </AddCardBtn> 
          </CardLower>
        </CardForm> 
        <SwitchWrap>
          <CheckedText enabled={checked}> { checked ? ('Setting as Default') : ('Make Default') } </CheckedText> 
          <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />  
        </SwitchWrap>  
      </ACModalContainer>
    </AddCardModalContainer>
  )
}

const AddCardModalContainer = styled.div`
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
`


const ACModalContainer = styled.div`
    background-color: white;
    width: 500px;
    min-height: fit-content;
    height: fit-content;
    padding-bottom: 20px;
    border-radius: 11px;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor:  ${props => props.loading ? 'wait':'pointer'}; 
    @media (max-width: 768px) {
        width: 95%;
        border-radius: 7px;
    }
    @media (max-width: 568px) {
        width: 95%;
        border-radius: 7px;
    }

`

const CreateXFormTop = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    padding-left: 8px;
    justify-content: space-between;
    background-color: var(--themeColor);    
    border-radius: 8px 8px 0px 0px;
    font-weight: 300;
`

const CloseBtn = styled.div`
    position: absolute;
    right: 0;
    padding-right: 10px;
    font-size: 13px;
    cursor: pointer;

`
const LoaderWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

`

const AnnouncementModalHeading = styled.div`
  font-size: 14px;
  font-weight: 100;
  padding-top: 15px;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    font-size: 14px;
    padding-top: 0px;
    margin-bottom: 5px;
    margin-top: -15px;
  }
`

const CardForm = styled.form`
  color: grey;
  padding: 20px;
  background-color: white;
  width: 100%;
  border-radius: 10px;
  font-size: larger;
  display: flex;
  flex-direction: column;

  span{
    padding-top: 15px;
    font-size: x-small;
    text-align: left;
  }
`

const AddCardBtn = styled.button`
  padding: 13px;
  outline: none;
  border: none;
  color: white;
  background-color: purple;
  align-self: end;
  justify-self: end;
  cursor:  ${props => props.disabled ? 'wait':'pointer'}; 
  margin-top: 20px;
  border-radius: 5px;
  width: fit-content;
  white-space: nowrap;
  margin-left: 30px;

`
const CardLabel = styled.label`
  margin-bottom: 30px;

`
const CardLower = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`

const SwitchWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

`

const CheckedText = styled.div`
  color: ${props => props.enabled ? '#1a58cc':'grey' };
  font-size: 12px;
  /* color: #1a58cc */
  font-style: ${props => props.enabled ? 'none':'italic' };
`