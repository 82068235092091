import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { FileInputWrap, FileModal, FileFormWrapper, FileUploadInput, FileUploadButton, ButttonWrap } from 'components/files/files.styled'
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import { useDispatch, useSelector } from 'react-redux'
import { setViewFileModal, renameFile, resetUploadState } from 'features/files/fileSlice'
import SpinnerSmall from 'components/SpinnerSmall'
import { CgRename } from "react-icons/cg";


export default function RenameFileModal() {

    const dispatch = useDispatch()
    const newFileName = useRef(null)
    const { selectedFile, selectedFolder, editNameLoading, editNameSuccess, collectionFiles, folderToAddFile } = useSelector((state) => state.file)
    const { user } = useSelector((state) => state.auth)
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const [newFileNameVal, setNewFileNameVal] = useState(selectedFile ? selectedFile.title : '')

    const handleClose = () => {
        dispatch(setViewFileModal({
            modal: 'viewRenameFileModal',
            view: false,
        }))
    }

    const handleRenameFile = () => {
        if(newFileName.current.value === ''){
            return
        }

        if(selectedFile ){
            if(selectedFile.title === newFileName.current.value){ 
                handleClose() 
                return
            }
        }

        dispatch(renameFile({
            fileId: selectedFile._id,
            folderId: selectedFolder ? selectedFolder._id : null,
            newTitle: newFileName.current.value,
            churchId: profileChurch._id,
        }))
    }
 
    useEffect(() => {
        if(editNameSuccess){
            dispatch(resetUploadState())
            handleClose()
        }
    }, [editNameSuccess])

    return (
        <FileModal>
            <ModalCloseButton
                handler={handleClose}
                top='10px'
                right='10px'
                size='30px'
                />

            <FileFormWrapper> 
                <FIH>Rename File</FIH>

                <FileInputWrap>
                    <FileUploadInput 
                        ref={newFileName}
                        value={newFileNameVal}
                        onChange={(e) => {setNewFileNameVal(e.target.value)}}
                        type="text" 
                        placeholder='Folder name' 
                        />
                </FileInputWrap>
                <ButttonWrap>
                    <FileUploadButton
                        disabled={editNameLoading}
                        width={'fit-content'}
                        onClick={handleRenameFile}
                        >
                        {
                            editNameLoading ? (
                                <SpinnerSmall />
                            )
                            :
                            (
                                <>
                                    <CgRename style={{ marginRight: '10px'}} />
                                    <span>Rename</span>
                                </>
                            )
                        }
                    </FileUploadButton>                 
                </ButttonWrap>
            </FileFormWrapper>
        </FileModal>
    )
}

const FIH = styled.h3`
    color: var(--lightThemeFontColor);
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 500;
    width: 100%;
    text-align: left;

`