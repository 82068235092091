import React, {useState, useEffect } from 'react'
import styled from 'styled-components'   
import { useSelector, useDispatch } from 'react-redux'
import { setViewCreateGivingCampaignModal } from 'features/givingCampaigns/givingCampaignSlice'
import { GiveForm, StyledInput, InputIconWrap } from '../../giving.styled'

export default function Step3Review() {

    const dispatch = useDispatch()

    const { images, title, description, goal, endDate } = useSelector((state) => state.givingCampaign)

    return (
        <GiveForm
            style={{flexDirection: 'row'}}
            > 
            <Aside>
                <ReviewItem>
                    <ReviewLabel>Title</ReviewLabel>
                    <ReviewValue>{title}</ReviewValue>
                </ReviewItem>
                <ReviewItem>
                    <ReviewLabel>Description</ReviewLabel>
                    <ReviewValue>{description}</ReviewValue>
                </ReviewItem>
                <ReviewItem>
                    <ReviewLabel>Goal</ReviewLabel>
                    <ReviewValue>{goal}</ReviewValue>
                </ReviewItem> 
                <ReviewItem>
                    <ReviewLabel>End date</ReviewLabel>
                    <ReviewValue>
                        {
                            endDate &&
                            (
                                <span>
                                    {
                                        Date.parse(endDate) ? new Date(endDate).toLocaleDateString() : endDate
                                    }
                                </span>
                            )
                        }
                    </ReviewValue>
                </ReviewItem>
            </Aside>
            <Aside> 
                <ImagesWrap>
                    {
                        images.map((image, index) => {
                            return (
                                <ImageItem key={index}>
                                    <Image src={image.src} alt={image.alt} />
                                </ImageItem>
                            )
                        })
                    }
                </ImagesWrap>
            </Aside>
        </GiveForm> 
    )
}
 
const Aside = styled.div`
    width: 50%;  
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    color: var(--lightThemeFontColor);
    transition: all 0.6s ease-in-out; 
    height: 100%;
    overflow-y: scroll;
    /* hide scroll bar */
    /* width */
    ::-webkit-scrollbar {
        width: 0;
    }
`
const ReviewItem = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
`

const ReviewLabel = styled.div`
    font-size: 11px; 
`
const ReviewValue = styled.div`
    font-size: 14px;
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
`

const ImagesWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    padding: 10px;   
    overflow-y: scroll;
    /* hide scroll bar */
    /* width */
    ::-webkit-scrollbar {
        width: 0;
    }  
    padding-left: 20px;
`

const ImageItem = styled.div`
    width: 80px;
    height: 70px;
    margin-right: 10px; 
    position: relative;
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
`
