import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const API_URL = '/api/createPost/getMemberships'

//for sending the token
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }

// Create memberships
const getMemberships = async (token) => {

  const response = await axios.get(URL+API_URL, config(token))
  return response.data
}


const membershipsService = { getMemberships }
export default membershipsService
