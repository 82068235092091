import styled from "styled-components";

export const HomeContainer = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  height: 75vh;
  color: rgb(80,80,80);
  font-size: 25px;
  z-index: 0; 
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
export const Wrap3 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 95px;
  width: 100%;
  @media (max-width: 768px) {
    margin-top: 50px; 
  }

`


export const LpButton = styled.div` 
  padding: 20px 40px;
  width: 300px;
  display: flex ;
  align-items: center;
  justify-content: center;
  border: ${props => props.btnType === "join" ? "3px solid #00B4CC" : "3px solid white"};
  margin-left: ${props => props.btnType === "cal" ? "30px" : "0px"};
  border-radius: 40px;
  cursor: pointer;
  background-color: ${props => props.btnType === "cal" ? "white":"#00B4CC"  };
  color: ${props => props.btnType === "cal" ? "#00B4CC":"white"  };
  font-weight: bold;
  &:hover{
    color: ${props => props.btnType === "cal" ? "#00B4CC":"white"  };
    background-color: ${props => props.btnType === "cal" ? "white":"#007180"  };
    border: ${props => props.btnType === "cal" ? "3px solid #00B4CC" : "3px solid #007180"};
    box-shadow: 3px 3px 7px rgb(179, 179, 179);
    transition: all .6s ;
  }
  @media (max-width: 768px) {
    font-size: 15px;
    width: fit-content;
    padding: 15px 25px;
    margin-top: 20px;
    
    flex-direction: column;
    box-shadow: none;
    &:hover{
      box-shadow: none;
    }
  }

  @media (max-width: 1700px) {
    font-size: 13px;
    width: fit-content;
    padding: 10px 20px; 
    &:hover{
      box-shadow: none;
    }
  }
`