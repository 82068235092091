import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setComment } from '../../features/comments/singlePostCommentSlice'
import { toast } from 'react-toastify'
import styled from 'styled-components' 
import SpinnerSmall from '../SpinnerSmall' 
import SendIcon from '@mui/icons-material/Send';

function SinglePostCommentForm() {
  const dispatch = useDispatch() 
  const { singlePost } = useSelector((state) => state.singlePost)
  const { isSPNewCommentLoading, isSPNewCommentSuccess } = useSelector((state) => state.sPComments)
  const [commentText, setCommentText] = useState('') 

  const handleSubmit = (e) => {
    e.preventDefault()
    if(commentText.length < 2){ return(toast.error('Comment must contain value'))}
    if(commentText.length > 1120){ return(toast.error('Comment should be less than 1000 characters')) }

    dispatch(setComment({ 
      postId: singlePost._id,
      comment: commentText, 
      residingId: singlePost.postedToId._id, 
    }))
  }

  useEffect(() => {
    if(isSPNewCommentSuccess){
      setCommentText('')
    }
    }, [isSPNewCommentSuccess]);

  return (
    <FormWrapper> 
      <SinglePostForm 
        type='submit' 
        onSubmit={handleSubmit}
        >
        <FormInput 
          type='text'
          onChange={(e) => setCommentText(e.target.value.replace(/[\|&;\%@"<>\(\)\+#{}]/g, ""))}
          value={commentText} 
          className = 'singlePostFormInput' 
          placeholder='Start writing a comment...'
        />
        
        <SubmitButton
          disabled={commentText.length < 2 || commentText.length > 1120 }
          isLoading={isSPNewCommentLoading}
          type='submit'
          >
          {isSPNewCommentLoading ? <SpinnerSmall /> : <SendIcon />}
        </SubmitButton>
        
      </SinglePostForm>
    </FormWrapper>  
  )
}

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  @media (max-width: 768px) {
    padding: 10px;
  }
`

const SinglePostForm = styled.form`
  display: flex;
  height: 56px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 6px 6px 6px 20px;
  border-radius: 28px;
  border: 2px solid #f0f2f5; 
  outline: none;
  background-color: #f0f2f5; 
  transition: all 0.8s;

  /* if in focus set border */
  &:focus-within{
    border: 2px solid var(--themeColor);
  }

  @media (max-width: 768px) {
    padding: 6px 6px 6px 10px;
  }

` 
const SubmitButton = styled.button`
    pointer-events: ${props => props.disabled || props.isLoading ? 'none' : 'auto'};
    width: fit-content;
    height: 100%;
    border: none;
    outline: none;
    justify-content: flex-end;
    color: white;
    transition: all 0.9s;
    background-color: ${props => props.disabled ? 'rgb(200,200,200)' : 'green'};
    padding: 0px 22px;
    border-radius: 18px;
    cursor: pointer;
`
const FormInput = styled.input`
    width: 100%;
    height: 100%;
    border: none;
    outline: none; 
    background-color: #f0f2f5;
    padding: 0px 10px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    vertical-align: middle;

`

export default SinglePostCommentForm