import './css/loader.css'

function Loader(props) {
  return (
    <div 
      className={props.color ? `lds-ellipsis-white` : 'lds-ellipsis'}
      
      ><div></div><div></div><div></div><div></div></div>
  )
}

export default Loader