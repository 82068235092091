import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import membershipsService from './membershipsService'

const initialState = {
  memberships: [],
  isMbsError: false,
  isMbsSuccess: false,
  isMbsLoading: false,
  mbsMessage: '',
}

// individual post after comment submission
export const getMemberships = createAsyncThunk(
    'createPost/getMemberships',
    async (_, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await membershipsService.getMemberships(token)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const membershipsSlice = createSlice({
    name: 'memberships',
    initialState,
    reducers: {
      mbsReset: (state) => initialState,
    },
    extraReducers: (builder) => {
      builder
        .addCase(getMemberships.pending, (state) => {
          state.isMbsLoading = true
        })
        .addCase(getMemberships.fulfilled, (state, action) => {
          state.isMbsLoading = false
          state.isMbsSuccess = true
          state.memberships = action.payload
        })
        .addCase(getMemberships.rejected, (state, action) => {
          state.isMbsLoading = false
          state.isMbsError = true
          state.mbsMessage = action.payload
        })
    },
})

export const { mbsReset } = membershipsSlice.actions
export default membershipsSlice.reducer