import React, { useState } from 'react'
import { MediaTitle, SortButtonsWrap, SortButton } from 'components/media/media.styled'
import { useDispatch, useSelector } from 'react-redux'
import { setSortAudio } from 'features/audio/audioSlice'
import { setSortVideo } from 'features/videos/videoSlice'
import SortIcon from '@mui/icons-material/Sort';
import { BsSortDown } from "react-icons/bs";
import { BsSortDownAlt } from "react-icons/bs";


export default function MediaSort({ section }) {

    const dispatch = useDispatch()
    
    const { audioSort } = useSelector(state => state.audio)
    const { videoSort } = useSelector(state => state.video)
    const [sortState, setSortState] = useState("ascending");

    return (
        <MediaTitle>   
            <SortButtonsWrap> 
                {   
                    section === 'audio' ?
                    (
                        <SortButton
                            onClick={() => dispatch(setSortAudio(audioSort))}
                            >
                                <SortIcon />
                                {audioSort === 'ascending' ? 'Newest First' : 'Oldest First'}
                        </SortButton>
                    )
                    :
                    (
                        <SortButton
                            onClick={() => dispatch(setSortVideo(audioSort))}
                            >
                                <SortIcon />
                            {videoSort === 'ascending' ? 'Newest First' : 'Oldest First'}
                        </SortButton>
                    )
                }
            </SortButtonsWrap>
        </MediaTitle>
    )
}
