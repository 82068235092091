import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const URL_VERIFY = '/api/churchVerification/verify'


//for sending the token
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }

//---------------------------------------------------------------

const submitVerify = async (data, token) => {
  const response = await axios.post(URL+URL_VERIFY, data, config(token))
  return response.data
}

const cvService = {
    submitVerify
}

export default cvService