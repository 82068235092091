import {React, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { closeDirectoryModal } from '../../../features/modal/directoryModalSlice'
import { imgUrl } from 'features/constants'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ContactsIcon from '@mui/icons-material/Contacts';
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import useImageLoader from 'hooks/useImageLoader' 
import { BsCakeFill } from "react-icons/bs";



export default function ViewDirectoryItem() {
  const dispatch = useDispatch()
  const { data } = useSelector((state) => state.directoryModal)
  const [ hasBeenClicked, setHasBeenClicked ] = useState(false)
  const [ memberData, setMemberData ] = useState(null)
  const [ imageSource, { blur }] = useImageLoader(`${imgUrl}/directory/${data.image.imagePath1}`, `${imgUrl}/directory/${data.image.imagePath2}`)
  

  const viewMember = (memberData) => {  
    if(!hasBeenClicked){
      setHasBeenClicked(true)
    }
    
    if(memberData && memberData.birthday){
      const months = ['January','February','March','April','May','June','July','August','September','October','November','December']
      let birthday = memberData.birthday.split('/')
      let month = months[parseInt(birthday[0])-1]
      memberData.birthday = `${month} ${birthday[1]}`
    }
    setMemberData(memberData)   
  }

  return (
    <ModalWrap>
      <ModalCloseButton 
        handler={() => dispatch(closeDirectoryModal())}
        size='35px'
        top='10px'
        right='10px'
        />
      <ModalMain>
        <Image>

          <img 
            src={imageSource} 
            alt='family' 
            style={{
              filter: blur ? 'blur(2px)' : 'none',
              transition: blur ? 'none' : 'filter 1s ease-in-out',
            }}
            />

        </Image>
        <InfoWrap>
          <Info>
            <InfoItem>
                <PeopleAltIcon style={{fontSize: "25px", color: "rgb(130,130,130)"}}/> 
                { data.familyList.map((ele) => { 
                      
                      let dirData = JSON.parse(ele) 
                      return( 
                        <FamilyList onClick={() => viewMember(dirData)}> {dirData.name} </FamilyList>
                      ) 
                    } 
                  )
                }
            </InfoItem>
          </Info>       

          { memberData && hasBeenClicked ?
            (
              <MemberDataViewWrap>
                <MemberViewItem clicked={hasBeenClicked}>
                  <Item> <PersonIcon/>  <span>{memberData.name}</span></Item>
                  { memberData.phone && ( <Item> <PhoneAndroidIcon/>  <span>{memberData.phone}</span></Item>  ) }
                  { memberData.email && ( <Item> <EmailIcon/>  <span>{memberData.email}</span></Item>  ) }
                  { memberData.birthday && ( <Item> <BsCakeFill style={{fontSize: '21px', marginLeft: '2px'}} />  <span>{memberData.birthday}</span></Item>  ) }
                  { memberData.about && ( <Item about={true}> <ContactsIcon/>  <span>{memberData.about}</span></Item>  ) }
                </MemberViewItem>
              </MemberDataViewWrap>
            )
            :
            (
              <MemberDataViewWrap>
                <MemberViewItem clicked={hasBeenClicked}>
                  <MVIClick><i>click on a name to view user info</i></MVIClick>
                </MemberViewItem>
              </MemberDataViewWrap>
            )
          } 
        </InfoWrap> 
      </ModalMain>
    </ModalWrap>
  )
}


const ModalWrap = styled.div`
  z-index: 2;
  width: 890px;
  min-height: 450px;
  max-height: fit-content;
  background: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 14px;
  position: relative;
  animation: animate 0.3s;
  padding: 5px 5px 20px 5px;
  color: rgb(100,100,100);
  @media (max-width: 768px) {
    width: 97%;
  }
`

const ModalMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
  } 
  `

const Image = styled.div`
  margin-top: 20px;
  padding: 10px 27px;
  width: 60%;

  img{
    width: 100%;
    border-radius: 10px;
  }


  @media (max-width: 768px) {
    
    width: 100%;
    padding: 10px 20px;
  }

`
const InfoWrap = styled.div`
  margin-top: 20px;
  display: flex;
  max-width: 100%;
  flex-direction: column;
  width: 40%;

  @media (max-width: 768px) {
    margin-top: 5px;
    width: 100%;
    padding: 10px 20px;
  }

`

const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-size: 18px;
  color: rgb(100,100,100);
  height: fit-content; 

`



const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  flex-flow: row wrap;
  & span{
    padding-left: 10px;
  }
`

const FamilyList = styled.div`
  padding: 4px 8px;
  background-color: rgb(220,210,210);
  border: 1px solid rgb(220,210,210);
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  &:hover{
    background-color: #f1f1f1;
    border: 1px solid rgb(220,210,210);
    transition: all 0.4s;
  }
`


const MemberDataViewWrap = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 11px;
`

const MemberViewItem = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: ${props => props.clicked ? '0 0 10px #ccc' : 'none' };

  /* 0 0 10px #ccc */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  border-radius: 10px;
`

const Item = styled.div`
    padding: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: ${props => props.about ? '10px': '13px'};
    margin-bottom: 4px;
    span{
      margin-left: 10px;
    }

`

const MVIClick = styled.div`
  width: 100%;
  position: absolute;
  bottom: 5px;
  font-size: 12px;
  text-align: center;
`

