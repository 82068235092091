
export const givingLanugauge = {

    country: {
        en: 'Country',
        es: 'País'
    },
    unitedStates: {
        en: 'United States',
        es: 'Estados Unidos'
    },
    canada: {
        en: 'Canada',
        es: 'Canadá'
    },
    mexico: {
        en: 'Mexico',
        es: 'México'
    },
    memberGiving: {
        en: 'Member Giving',
        es: 'Donaciones de Miembros'
    },
    accountType: {
        en: 'Account Type',
        es: 'Tipo de Cuenta'
    },
    company:    {
        en: 'Company',
        es: 'Empresa'
    },
    individual: {
        en: 'Individual',
        es: 'Individual'
    },
    non_profit: {
        en: 'Non Profit',
        es: 'Sin Fines de Lucro'
    },
    noPrayselyGivingAcct: {
        en: 'You do not have a Praysely Giving account',
        es: 'La cuenta para recibir donaciones en Praysely no está activada'
    },
    startCreatingAccount: {
        en: 'Start receiving',
        es: 'Comenzar a recibir'
    },
    description: {
        en: 'The Praysely platform for giving is powered by Stripe. To create your giving account to begin receiving your members donations, you must first fulfill the list of requirements with Stripe. We will provide a list of requirements for you to complete, contact us at info@praysely.com if you have any questions throughout this process.',
        es: 'La plataforma Praysely para donaciones funciona con Stripe. Para crear su cuenta de donaciones y comenzar a recibir las donaciones de sus miembros, primero debe cumplir con la lista de requisitos con Stripe. Le proporcionaremos una lista de requisitos para que los complete, contáctenos en info@praysely.com si tiene alguna pregunta durante este proceso.'
    },
    continue: {
        en: 'Continue',
        es: 'Continuar'
    },
    toastErrorSelectCountry: {
        en: 'Please select a country',
        es: 'Por favor seleccione un país'
    },
    toastErrorSelectAccountType: {
        en: 'Please select an account type',
        es: 'Por favor seleccione un tipo de cuenta'
    },
    
}