import React from 'react'
import styled from 'styled-components'
import { imgUrl } from 'features/constants'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSingleCampaignData } from 'features/givingCampaigns/givingCampaignSlice';
import useImageLoader from 'hooks/useImageLoader';
import { IoIosArrowBack, IoIosArrowForward  } from "react-icons/io";

export default function CampaignItem({ campaign }) {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleNavigate = () => { 
        dispatch(setSingleCampaignData(campaign))
        navigate(`/campaigns/${campaign._id}`)
    }

    const [view, setView] = React.useState('details')

    const [imageSource, { blur }] = useImageLoader( 
        `${imgUrl}/givingCampaigns/${campaign.images[0].imagePath1}`,
        `${imgUrl}/givingCampaigns/${campaign.images[0].imagePath2}`
    )

    return (
        <CIC onClick={handleNavigate}> 
            <CICL>

                <img
                    src={imageSource} 
                    style={{
                        filter: blur ? 'blur(2px)' : 'none',
                        transition: blur ? 'none' : 'filter 0.3s ease-out',
                    }}
                    alt="campaign" 
                    />
            </CICL>
            <CICR>
                <span style={{  marginTop: '5px' }}>{campaign.title}</span>  

                {
                    campaign.totalDonations !== 0 &&
                    <TotalsContainer>
                        <StatusContainer>
                            <RaisedColor
                                progress={
                                    `${Number(campaign.totalDonations/campaign.goal).toFixed(2) * 100}%`
                                }    
                                >
                            </RaisedColor>
                        </StatusContainer>
                        <Totals>
                            <p>Total Donations</p>
                            <p>${campaign.totalDonations.toLocaleString()}</p>
                        </Totals>
                    </TotalsContainer>
                } 
            </CICR> 
        </CIC>
    )
}

const RaisedColor = styled.div`
    width: ${props => props.progress};
    height: 10px;
    background-color: var(--themeColor);
    border-radius: 10px;
`

const CIC = styled.div`
    width: 49%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    cursor: pointer;

    @media (max-width: 768px){
        width: 400px;
        height: 400px;
    }

`
const CICL = styled.div`
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
`

const ImageArrow = styled.div`
    width: 30px;
    height: 30px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden  ;
`

const CICR = styled.div`
    width: 100%;
    height: 20%;
    color: var(--lightThemeFontColor);
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
`
const TotalsContainer = styled.div`
    width: 100%; 
    margin-top: 3px;
`
const StatusContainer = styled.div`
    width: 100%;
    display: flex;
    height: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #dbeaf6;
    border-radius: 10px;
`
const Totals = styled.div`
    width: 100%;
    display: flex;
    margin-top: 3px;
    justify-content: space-between;
    font-size: 13px;
    color: #b6b6b6;
    
`