import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux' 
import styled from 'styled-components'
import useGetWindowSize from 'hooks/useGetWindowSize'
import { baseUrl } from 'features/constants'
import { HomeContainer, Wrap3, LpButton  } from './landingPage.styled'
import FooterLP from './FooterLP'
import About from './About'
import OurMission from './OurMission'
import UserType from './UserType'
import Loader from 'loaders/Loader'
import { pastravzta } from 'features/auth/authSlice'


export default function HomeComponentSmall(props) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth)
    const { isLoading, isError, message } = useSelector((state) => state.posts)

    const [imagePath, setImagePath] = useState('prys/lp-church.jpg')

    const [screenWidth, screenHeight] = useGetWindowSize()

    let userName;
    if(user){
      userName = user.name
    }
    const [currentDetail, setCurrentDetail] = useState()
  
    const change = () => { 
      const randomIndex = shareDetailsArray[(Math.floor(Math.random() * shareDetailsArray.length))]
      setCurrentDetail(randomIndex)
    }

    useEffect(() => {

      console.log('pastravzta')

      dispatch(pastravzta())

    }, [])
  
    useEffect(() => { 
      const interval = setInterval(change, 1050)
      return () => clearInterval(interval)
    }, [])
   
    useEffect(() => {
      if (isError) {
        console.log(message)
      }
      if (user) {
        navigate('/home')
      }

      if(screenWidth > 1850){
        setImagePath('lp-church-big.jpg')
      }
      else if(screenWidth < 1850 && screenWidth > 1268){
        setImagePath('lp-church.jpg')
      } 
      else if(screenWidth < 1268 && screenWidth > 768){
        setImagePath('lp-church-small.jpg')
      }

    }, [user, navigate, isError, message, screenWidth])
   
    if (isLoading) {
      return <Loader />
    }
  
    const shareDetailsArray = [
      'Prayer Requests', 'Testimonies', 'Events & Anouncements', 'Church Directory', 'Polls', 'Weekly Bulletin'
    ]


    return (
        <HomeContainer>
            <HCTop>
                <img src={`${baseUrl}/prys/lp-church-big.jpg`} alt={'Praysely'} />  
            </HCTop>
            <HCBottom>
                <h1>  <LeftHeader>Connect with your church family!</LeftHeader>  </h1>
                
                <Details>
                    A social media platform for churches and members to connect, grow and stay informed. Praysely gives churches and its members tools
                    to stay connected, share prayer requests, testimonies, praises, events and announcements. Members can view their church directory,
                    send messages, participate in polls and much more. 
                </Details>

                <LeftDetails>{currentDetail}</LeftDetails>

                <Wrap3>  
                <LpButton
                  btnType='join'
                  onClick={() => navigate('/register')}
                  >
                    Join Praysely
                </LpButton> 

                <LpButton

                  btnType='cal'
                  onClick={() => window.open("https://calendly.com/praysely")}
                  >
                    Book a demo
                </LpButton>
              </Wrap3> 
                <AlreadyRegistered>Already Registered? <Link to='/login'><u style={{cursor: "pointer"}}>Login</u></Link> </AlreadyRegistered>
               
            </HCBottom>
            { props.includeMission ? 
              (
                <OurMission />
              )
              :
              (
                <About />
              )
            }
            <UserType />
            <FooterLP />
        </HomeContainer>
    )
}

const HCTop = styled.div` 
    width: 100%;
    height: fit-content;
    img{
        width: 100%;
        height: auto;
    }
`
const HCBottom = styled.div`
    width: 100%;
    height: 100%;
    margin-top: -7px;
    background-color: #6f952e;
    color: #f2f2f2;
    padding: 20px;

    h1{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: baseline;
        width: 100%;
        text-align: justify;
        font-size: 40px;
        font-weight: 900;
    }


`

const Details = styled.div`
    font-size: 15px;
    margin-top: 30px;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 87%;
    line-height: 1.5; 
  //media queries
  @media (max-width: 1750px) { 
  }
  @media (max-width: 1208px) { 
    width: 95%;
  }
  @media (max-width: 768px) {

  }

`

const LeftHeader = styled.div`
  font-size: 30px;
  font-weight: 400; 
  margin-left: 0px;
  text-align: left;
  @media (max-width: 1750px) {
    font-size: 25px;
    margin-left: 0px;
    margin-top: 10px;
  }


`


const LeftDetails = styled.div`
  margin-top: 40px;
  font-size: 15px;
  padding: 20px;
  text-align: left;
  border-radius: 10px;
  background-color: #8fbf3c;
  box-shadow: 3px 3px 7px #49611e;;
  color: #3b5017;
`
const JoinPraysely = styled.div`
  margin-top: 55px;
  padding: 20px 80px;
  background-color: #00B4CC;
  /* border: 1px solid #00B4CC; */
  border-radius: 40px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  &:hover{
    background-color: #009bb0;
    color: white;
    box-shadow: 3px 3px 7px rgb(1, 86, 126);
  }
`
const AlreadyRegistered = styled.div`
  font-size: 15px;
  margin-top: 30px;
  span{
    text-decoration: underline
  }
  
`