import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import audioService from './audioService'
import { act } from 'react-dom/test-utils'
import { setView } from 'features/giving/givingSlice'

const errorMessage = (error) => {
    return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
  }

const initialState = {

    audios: [],

    audioUploading: false,
    audioUploadSuccess: false,
    audioUploadError: false,
    audioUploadErrorMessage: '',

    getAudiosLoading: false,
    getAudiosSuccess: false,
    audiosError: false,
    audiosLoadingErrorMessage: '',

    selectedAudio: null,
    audioTitle: '',  
  
    totalAudios: 0,
    totalPages: 0,
    hasPrevPage: false,
    nextPage:null,
    currentPage: 1,
    prevPage: null,  
    hasNextPage: false,
    audioSort: 'ascending',
    searchValue: '',
    
    searchAudios: [],
    searchAudiosTemp: [],
    isSearchAudiosLoading: false,
    isSearcAudiosSuccess: false,
    isSearchAudiosError: false,

    uploadAudioModal: false,
    selectedAudioSource: null,
    selectedAudioFile: null,

    audioDeleteLoading: false,
    audioDeleteSuccess: false,
    audioDeleteError: false,
    audioDeleteErrorMessage: '',

    audioData: null,
    viewAudioUpdateModal: false,
    audioUpdateLoading: false,
    audioUpdateSuccess: false,
    audioUpdateError: false,
    audioUpdateErrorMessage: '',
} 

export const uploadAudio = createAsyncThunk(
    'audio/upload',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await audioService.uploadAudio(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAudios = createAsyncThunk(
    'audio/getAudios',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await audioService.getAudios(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const addListenCount = createAsyncThunk(
    'audio/addListenCount',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await audioService.addListenCount(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const deleteAudio = createAsyncThunk(
    'audio/deleteAudio',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await audioService.deleteAudio(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const searchAudio = createAsyncThunk(
    'audio/searchAudio',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await audioService.searchAudio(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const updateAudio = createAsyncThunk(
    'audio/updateAudio',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await audioService.updateAudio(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export  const audioSlice = createSlice({
    name: 'audio',
    initialState,
    reducers: {
        setViewUploadAudioModal: (state, action) => {
            state.uploadAudioModal = action.payload
        },
        setSelectedAudioSource: (state, action) => {
            state.selectedAudioSource = action.payload
        },
        setSelectedAudioFile: (state, action) => {
            state.selectedAudioFile = action.payload
        },
        resetAudioUploadSuccess: (state) => {
            state.audioUploadSuccess = false
            state.audioUploadError = false
            state.audioUploadErrorMessage = ''
        },
        setSortAudio(state, action) {
            if(action.payload === 'ascending'){
                state.audioSort = 'descending'
            }else{
                state.audioSort = 'ascending'
            }
        },
        resetActiveSearch(state) {
            state.searchValue = ''
            state.audios = state.searchAudiosTemp
            state.isSearchActive = false
        },
        setViewAudioUpdateModal(state, action) {
            state.viewAudioUpdateModal = action.payload
        },
        setAudioData: (state, action) => {
            state.audioData = action.payload
        }


    },
    extraReducers: (builder) => {
      builder
        .addCase(uploadAudio.pending, (state) => { 
            state.audioUploading = true  
        })
        .addCase(uploadAudio.fulfilled, (state, action) => { 
            state.audioUploading = false
            state.audioUploadSuccess = true
            state.audios.push(action.payload.audio)
        })
        .addCase(uploadAudio.rejected, (state, action) => {
            state.audioUploading = false
            state.audioUploadError = true
            state.audioUploadErrorMessage = action.payload
        })  
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(getAudios.pending, (state) => {
            state.getAudiosLoading = true
        })
        .addCase(getAudios.fulfilled, (state, action) => {


            if(action.payload.pageNumber === 1) { 
                state.audios = action.payload.audios
            }else{ 
                state.audios.push(...action.payload.audios)
            }


            state.currentPage = action.payload.pageNumber
            // state.totalAudios = action.payload.totalAudios
            // state.totalPages = action.payload.totalPages
            // state.currentPage = action.payload.currentPage
            // state.hasNextPage = action.payload.hasNextPage
            // state.hasPrevPage = action.payload.hasPrevPage
            // state.nextPage = action.payload.nextPage
            // state.prevPage = action.payload.prevPage
            state.getAudiosLoading = false
            state.getAudiosSuccess = true

        })
        .addCase(getAudios.rejected, (state, action) => {
            state.getAudiosLoading = false
            state.audiosError = true
            state.audiosLoadingErrorMessage = action.payload
        })
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(deleteAudio.pending, (state) => { 
            state.audioDeleteLoading = true  
        
        })
        .addCase(deleteAudio.fulfilled, (state, action) => { 
            state.audioDeleteLoading = false
            state.audioDeleteSuccess = true
            state.audios = state.audios.filter(audio => audio._id !== action.payload.audioId)
        })
        .addCase(deleteAudio.rejected, (state, action) => {
            state.audioDeleteLoading = false
            state.audioDeleteError = true
            state.audioDeleteErrorMessage = action.payload
        })
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(searchAudio.pending, (state) => {
            state.isSearchAudiosLoading = true
        })
        .addCase(searchAudio.fulfilled, (state, action) => {
            state.searchAudios = action.payload.audios
            state.isSearchAudiosLoading = false
            state.isSearcAudiosSuccess = true

            if(action.payload.audios.length > 0) {

                state.searchAudiosTemp = state.audios
                state.audios = action.payload.audios
                state.isSearchActive = true
            }
 
        })
        .addCase(searchAudio.rejected, (state, action) => {
            state.isSearchAudiosLoading = false
            state.isSearchAudiosError = true
            state.audiosLoadingErrorMessage = action.payload
        })
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(updateAudio.pending, (state) => {
            state.audioUpdateLoading = true
        })
        .addCase(updateAudio.fulfilled, (state, action) => {
            state.audioUpdateLoading = false
            state.audioUpdateSuccess = true

            let audioIndex = state.audios.findIndex(audio => audio._id === action.payload.audio._id)
            
            if(audioIndex !== -1){
                state.audios[audioIndex] = action.payload.audio
            }

            state.viewAudioUpdateModal = false

        })
        .addCase(updateAudio.rejected, (state, action) => {
            state.audioUpdateLoading = false
            state.audioUpdateError = true
            state.audioUpdateErrorMessage = action.payload
        })
    },

})

export const { 
    resetActiveSearch, setSortAudio, setViewUploadAudioModal, 
    setSelectedAudioSource,setSelectedAudioFile, resetAudioUploadSuccess,
    setViewAudioUpdateModal, setAudioData
} = audioSlice.actions

export default audioSlice.reducer

