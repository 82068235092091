import './messagePanel.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMessages, getMoreMessages, messageReset } from '../../features/messenger/messengerSlice';
import Spinner from '../Spinner';
import MessageItem from './MessageItem';
import NewMessageForm from './NewMessageForm';
import { useNavigate, useParams } from 'react-router-dom';
import { resetAlreadyExists } from '../../features/messenger/conversationSlice';
import styled from 'styled-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useGetWindowSize from 'hooks/useGetWindowSize';


function MessagePanelMobile() {
  const dispatch = useDispatch()
  let { convId } = useParams();
  const navigate = useNavigate()
  const [width, height] = useGetWindowSize()
  const { 
    messages, isMessagesError, isMessagesSuccess, isMessagesLoading, isLoadingMore, messagesMessage,
    totalMessages, totalPages, page, pagingCounter, hasPrevPage, hasNextPage, nextPage,
  } = useSelector(state => state.messages)
  const { user } = useSelector(state => state.auth)
  const { viewConversation, currentMessagePanel } = useSelector( state => state.conversations )

  const handleLoadMore = () => {
    
    //here I can maybe include the array list, if i have access to it.

    if(hasNextPage){ 
      dispatch(getMoreMessages({ 
        conversationId: convId,
        pageNumber: nextPage,
      }))
    }
  }

  useEffect(() => {
        // if(convId !== )
        dispatch(getMessages({ conversationId: convId }))
        console.log('useffect getmessafes')

        if(currentMessagePanel &&  (currentMessagePanel.id !== convId)){
          navigate('/messages')
        }


        return () => { //cleanup certain global state variables
          dispatch(resetAlreadyExists())
          dispatch(messageReset())
        }
        },[dispatch, convId ])

  return (
    <MessagePanelContainer windowHeight={height}>


      <BackToMessages onClick={() => navigate('/messages')} > 
        <ArrowBackIcon /> <span>Back to Messages</span>
      </BackToMessages>

      <MPTop> 
        <div className="mpTopName">Conversation with: {currentMessagePanel.name} </div>
        <div className='mpTopIcon'>  </div>
      </MPTop>
      <MessageContent>
      {
        isMessagesLoading ? 
          (<Spinner />)
          : isMessagesSuccess && !isMessagesLoading && messages ?
          (
            user.isOrganization || user.isChurch ? 
                (
                  messages.map(ele => (
                      ele.church ? (
                        <MessageItem key={ele._id} church={ele.church._id} messageId={ele._id} isMine={true} text={ele.text} sender={user._id} dateSent={ele.createdAt} />
                      )
                      :
                      (
                        <MessageItem key={ele._id} messageId={ele._id} isMine={false} text={ele.text} dateSent={ele.createdAt} sender={ele.user} />
                      )
                  ))
                )
                :
                (
                  messages.map(ele => (
                      ele.user ? (
                        <MessageItem key={ele._id} user={ele.user._id} messageId={ele._id} isMine={true} text={ele.text}  sender={user._id} dateSent={ele.createdAt} />
                      )
                      :
                      (
                        <MessageItem key={ele._id} messageId={ele._id} isMine={false} text={ele.text} dateSent={ele.createdAt} sender={ele.church} />
                      )
                    ))
                )
          )
          : isMessagesSuccess && !isMessagesLoading && !messages ?
          (
            <div> No messages </div>
          )
          :
          (<Spinner />)
      }
        { isLoadingMore && ( <LoadMoreBtn> Loading... </LoadMoreBtn> )}
        { (hasNextPage && !isLoadingMore) && (<LoadMoreBtn onClick={handleLoadMore}> Load more messages</LoadMoreBtn>)}
      </MessageContent>
      <NewMessageForm id={convId}/>
    </MessagePanelContainer>
  )
}

const MessagePanelContainer = styled.div`
  min-width: 600px;
  //width: 100%;
  height: 80vh;
  border-right: 1px solid var(--borderColor);
  display: flex;
  flex-direction: column;
  justify-content: space-between;


  @media (max-width: 1268px) {
      min-width: 400px;
      min-height: 350px ;
      height: ${props => props.windowHeight ? (`${props.windowHeight -160}px`):('50vh') };
      /* height: 45vh; */
      width: 450px;
      box-shadow: none;
      border-radius:0px;
      margin: 0px;
      
  }  
  @media (max-width: 768px) {
      min-height: unset;
      height: ${props => props.windowHeight ? (`${props.windowHeight -160}px`):('50vh') };
      /* height: 45vh; */
      min-width: unset;
      width: 100%;
      box-shadow: none;
      border-radius: 0px;
      margin: 0px;
      border-right: none;
      height: calc(100vh - 50px);
  }
`

const BackToMessages = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;

    margin: 7px 0px 7px 0px;
    width: fit-content;
    border-radius: 8px;
    color: #595959;
    cursor: pointer;
    transition: .4s;
    &:hover{
      border: 1px solid green;
      background-color: green;
      color: white
    }
  }
`

const MPTop = styled.div`
  height: 70px;
  color: var(--dFontColor);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--borderColor);
  @media (max-width: 768px) {
    height: 30px;
    border-top: 1px solid var(--borderColor);
    border-bottom: none;
    padding: 20px;
    background-color: #ffffff;
  }
`

const MessageContent = styled.div`
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    flex: 1;
    height: 90%;
    background-color: white;
    padding: 8px;
    overflow-y: scroll;
    overflow-x: hidden;
    
    @media (max-width: 768px) {
      height: 70%;
      box-shadow: none
    }
`

const LoadMoreBtn = styled.div`
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid grey;
  width: fit-content;
  align-self: center;
  justify-self: center;
  cursor: pointer;
  &:hover{
    background-color: rgb(230,230,230)
  }
`

export default MessagePanelMobile