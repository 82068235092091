import React from 'react'
import '../css/spinnerSmall.css'
import styled from 'styled-components'

function SpinnerSmall({  border = '#fff',   }) {
  return (
    <Cxw
      borderColor={border}
      className='spinnerSmall'
      >

    </Cxw>
  )
}

const Cxw = styled.div`
  border-top-color: ${props => props.borderColor}; 

`

export default SpinnerSmall