import React, { useEffect, useState } from 'react'
import { ConversationFeed } from '../zStyling.styled'
import { useDispatch, useSelector } from 'react-redux'
import ConversationItem from '../ConversationItem'
import { cleanupConversations, getConversations } from 'features/messenger/conversationSlice'
import Loader from '../../../loaders/Loader'  
import Member2MemberConversationFeed from '../Member2MemberConversationFeed'


export default function ConversationsFeed() {

    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { conversations, otherMemberConversations, isConversationsLoading, isConversationsSuccess } = useSelector(state => state.conversations)
    const { myProfile, isMyProfileLoading } = useSelector((state) => state.profile) 



    useEffect(() => { 
        if(!conversations) {
            dispatch(getConversations())
        }
    }, [dispatch, conversations] )


    return (
        <ConversationFeed>
            {
                isConversationsLoading || isMyProfileLoading?
                (
                    <Loader />
                )
                : isConversationsSuccess && conversations.length > 0  &&
                (  
                    user.isChurch || user.isOrganization ?
                    (
                        conversations.map((ele) => (
                            ele.user && (
                                <ConversationItem key={ele._id}  
                                    conversationId={ele._id} 
                                    image={ele.user.profileImg.imagePath2} 
                                    fullName={ele.user.fullName} 
                                    date={ele.updatedAt}
                                    lastMessage={ele.lastMessage}
                                    /> 
                            )
                        ))
                    )
                    :
                    (
                        conversations.map((ele) => (
                            ele.church ? 
                            (
                                <ConversationItem key={ele._id}   
                                    conversationId={ele._id} 
                                    image={ele.church.profileImg.imagePath2} 
                                    fullName={ele.church.fullName}  
                                    date={ele.updatedAt}
                                    lastMessage={ele.lastMessage}
                                    />
                            )
                            :
                            (
                                <ConversationItem key={123}  
                                    conversationId={'error'} 
                                    image={'generic.png'} 
                                    fullName={'user not available'} 
                                    /> 
                            )
                        ))
                    )  
                )
            } 

            {
                !user.isOrganization && (
                    <Member2MemberConversationFeed />
                )
            }
        </ConversationFeed>
    )
}
