import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const API_URL = '/api/users/resetPassword'
const API_URL_VALIDATE = '/api/users/validatePassword'

const tokenCheck = async (urlData) => {
    // const config = { headers: { Authorization: `Bearer ${token}`,},}
  
    const response = await axios.post(URL+API_URL, urlData)
  
    return response.data
  }


  const passwordUpdate = async (passwordData) => {
    // const config = { headers: { Authorization: `Bearer ${token}`,},}
  
    const response = await axios.post(URL+API_URL_VALIDATE, passwordData)
  
    return response.data
  }


const passResetService = {
    tokenCheck, passwordUpdate
  }
  
  export default passResetService