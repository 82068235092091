import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import reportIssueService from './reportIssueService'

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

const initialState = {
  issues: [],

  myReportedIssue: [],
  isIssueError: false,
  isIssueSuccess: false,
  areIssuesLoaded: false,
  isIssueLoading: false,
  isCreateIssueLoading: false,
  isCreateIssueSuccess: false,
  issueMessage: '',
}

//---- report an Issue
export const createIssue = createAsyncThunk(
  'issues/createIssue',
  async (issueData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await reportIssueService.createIssue(issueData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const reportPost = createAsyncThunk(
  'issues/ReportPost',
  async (issueData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await reportIssueService.reportPost(issueData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const createCommentIssue = createAsyncThunk(
  'issues/ReportComment',
  async (issueData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await reportIssueService.createCommentIssue(issueData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)


//------
export const getIssues = createAsyncThunk(
    'issues/getIssues',
    async (_, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await reportIssueService.getIssues(token)
      } catch (error) {
        const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const reportIssueSlice = createSlice({
    name: 'issue',
    initialState,
    reducers: {
      reportIssueReset: (state) => initialState,
    },
    extraReducers: (builder) => {
      builder
        .addCase(createIssue.pending, (state) => {
          state.isIssueLoading = true
        })
        .addCase(createIssue.fulfilled, (state, action) => {
          state.isIssueLoading = false
          state.isIssueSuccess = true
        })
        .addCase(createIssue.rejected, (state, action) => {
          state.isIssueLoading = false
          state.isIssueError = true
          state.issueMessage = action.payload
        })
        .addCase(getIssues.pending, (state) => {
            state.isIssueLoading = true
          })
          .addCase(getIssues.fulfilled, (state, action) => {
            state.isIssueLoading = false
            state.areIssuesLoaded = true
            state.issues = action.payload
          })
          .addCase(getIssues.rejected, (state, action) => {
            state.isIssueLoading = false
            state.isIssueError = true
            state.issueMessage = action.payload
          })
//=============================================
          .addCase(reportPost.pending, (state) => {
            state.isCreateIssueLoading = true
          })
          .addCase(reportPost.fulfilled, (state, action) => {
            state.isCreateIssueLoading = false
            state.isCreateIssueSuccess = true
          })
          .addCase(reportPost.rejected, (state, action) => {
            state.isCreateIssueLoading = false
            state.isIssueError = true
            state.issueMessage = action.payload
          })
//=============================================
          .addCase(createCommentIssue.pending, (state) => {
            state.isCreateIssueLoading = true
          })
          .addCase(createCommentIssue.fulfilled, (state, action) => {
            state.isCreateIssueLoading = false
            state.isCreateIssueSuccess = true
          })
          .addCase(createCommentIssue.rejected, (state, action) => {
            state.isCreateIssueLoading = false
            state.isIssueError = true
            state.issueMessage = action.payload
          })
        },
  })

  
export const { reportIssueReset } = reportIssueSlice.actions
export default reportIssueSlice.reducer