
import React from 'react' 
import useToggleDropdown from 'hooks/useToggleDropdown';
import { useSelector, useDispatch } from 'react-redux';
import { resetCategory, setForSaleCategory, setSelectedCategory } from 'features/marketplace/marketSlice'; 
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { DropdownSelect, DropdownTitle, DropdownItem, DropdownMenuItems } from '../market.styled';

export default function ListingTypeCategorySelect() {

    const dispatch = useDispatch()
    const { forSaleCategories, forSaleCategory, forSaleSubCategory, selectedCategory} = useSelector(state => state.market)
    const ref1 = React.useRef(null)
    const [showDropdown, setShowDropdown] = useToggleDropdown(ref1) 

    const handleDrop = () => {
        setShowDropdown.toggle(!showDropdown)
    }

    const handleSelectedCategory = (e, value) => {
        e.stopPropagation() 
        dispatch(setSelectedCategory( value ))
        setShowDropdown.toggle(!showDropdown)
    }

    return (
        <DropdownSelect 
            onClick={handleDrop}
            > 
            <DropdownTitle>
                <span>Category: </span>
                <b>{selectedCategory && (selectedCategory)} </b>
            </DropdownTitle>


            {
                showDropdown && (
                    <DropdownMenuItems>
                        {

                            !forSaleCategory && !forSaleSubCategory ? (

                                forSaleCategories.map((item, index) => {

                                    let keys = Object.keys(item)

                                    return(
                                        <DropdownItem 
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                dispatch(setForSaleCategory({
                                                    type: 'forSaleCategory',
                                                    value: keys,
                                                    index: index,
                                                }))}
                                            }
                                            >
                                            {
                                                keys
                                            }
                                        </DropdownItem>
                                    )

                                    
                                })
                            )
                            : forSaleCategory &&
                            (   

                                <div>
                                    <DropdownItem onClick={(e) => {
                                            e.stopPropagation()
                                            dispatch(resetCategory())}
                                        }>
                                        <KeyboardBackspaceIcon/>   back
                                    </DropdownItem>
                                {
                                    forSaleSubCategory.map((item, index) => {

                                        return(
                                            
                                            <DropdownItem   
                                                onClick={(e) => handleSelectedCategory(e, item)}
                                                >
                                                {item}
                                            </DropdownItem>                                        
                                        )
                                    })
                                }
                                </div>
                            )
                        }
                    </DropdownMenuItems>
                )
            }
        </DropdownSelect>
    )
}
