
import React from 'react' 
import useToggleDropdown from 'hooks/useToggleDropdown';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setListingTypeFilter } from 'features/marketplace/marketSlice';  
import { DropdownSelect, DropdownTitle, DropdownItem, DropdownMenuItems } from '../market.styled';

export default function ListingTypeDropdown() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { listingTypeFilter, listingTypes } = useSelector(state => state.market)
    const ref1 = React.useRef(null)
    const [showDropdown, setShowDropdown] = useToggleDropdown(ref1) 

    const handleDrop = () => {
        setShowDropdown.toggle(ref1)
    }


    return (
        <DropdownSelect 
            style={{ margin: '0px' }}
            ref={ref1}
            onClick={handleDrop}
            > 
            <DropdownTitle>
                {
                    listingTypes[`${listingTypeFilter}`].icon
                }
                <span>{ listingTypeFilter }</span>
            </DropdownTitle>


            {
                showDropdown && (
                    <DropdownMenuItems 
                        >

                        {   
                            Object.keys(listingTypes).map((item, index) => {
                                return(
                                    <DropdownItem  
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            dispatch(setListingTypeFilter(listingTypes[item].type))
                                            setShowDropdown.toggle(ref1)
                                            navigate(`/market/${listingTypes[item].type}`)
                                        
                                        }}
                                        >

                                        {
                                            listingTypes[item].icon
                                        }
                                        <span>
                                            {
                                                listingTypes[item].type
                                            } 
                                        </span>
                                        

                                    </DropdownItem>
                                )
                            })
                        }

                        
                    </DropdownMenuItems>
                )
            }
        </DropdownSelect>
    )
}
