import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { getChurchTransactions } from 'features/giving/givingSlice'
import styled from 'styled-components'
import { TransactionsTableContainer } from 'components/giving/giving.styled' 
import ChurchTransactionItem from './ChurchTransactionsItem'

export default function MemberGivingTransactions() {

    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { profileChurch } = useSelector(state => state.profileChurch)
    const { 
        transactions,
        getTransactionsLoading,
        getTransactionsSuccess,
        getTransactionsError,
    } = useSelector(state => state.giving)

    useEffect(() => {
        dispatch(getChurchTransactions({
            metadata: JSON.stringify({memberGiving: true})
        }))
    }, [])

    const [view, setView] = useState('transactions')

    return (
        <TransactionsContainer> 
            <TransactionsTableContainer
                style={{marginTop: '20px'}}
                >
                <tbody>
                    <tr>
                        <th>Date</th>
                        <th>Amount</th> 
                        <th>Description</th>
                        <th>User Email</th>
                        {/* <th>Status</th> */}
                    </tr>

                    {
                        getTransactionsLoading ?
                        (
                            <tr>
                                <td colSpan="4"> 
                                    <i>Loading Transactions...</i>
                                </td>
                            </tr>
                        )
                        :
                        (
                            transactions.map((transaction) => 
                            (
                                <ChurchTransactionItem  
                                    key={transaction.id} 
                                    data={transaction} 
                                    />
                            ) 
                        ) 
                    )}            
                    </tbody>
            </TransactionsTableContainer>
        </TransactionsContainer>
    )
}

const TransactionsContainer = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

`