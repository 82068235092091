
import About from './landingPage/About'
import FooterLP from './landingPage/FooterLP'
import HomeComponent from './landingPage/HomeComponent'
import useGetWindowSize from 'hooks/useGetWindowSize'
import styled from 'styled-components'
import UserType from './landingPage/UserType'
import HomeComponentSmall from './landingPage/HomeComponentSmall'

function Home() {

  const [screenWidth, screenHeight] = useGetWindowSize()
  console.log(screenWidth, screenHeight)

  return (
    <> 
      {
        screenWidth < 768 ?
        (
          <LPCont> 
            <HomeComponentSmall/>        
          </LPCont>

        )
        :
        (
          <LPContMain>
            <HomeComponent/> 
            <UserType />
            <About/>
            <FooterLP />
          </LPContMain>
        )
      }

    </>
  )
}
 
export default Home

const LPCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;   
  background-color: #6f952e;
`

const LPContMain = styled.div`
  display: flex;
  flex-direction: column;
`
