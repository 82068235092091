
export const directoryLanguage = {
    directory: {
        en: 'Directory',
        es: 'Directorio',
    },
    sort: {
        en: 'Sort',
        es: 'Ordenar',
    },
    nameAZ: {
        en: 'Name A-Z',
        es: 'Nombre A-Z'
    },
    nameZA: {
        en: 'Name Z-A',
        es: 'Nombre Z-A'
    },
    dateNewestFirst: {
        en: 'Newest First',
        es: 'Más reciente primero'
    },
    dateOldestFirst: {
        en: 'Oldest First',
        es: 'Más antiguo primero'
    },
    familyName: {
        en: 'Family Name',
        es: 'Apellido'
    },
    dateAdded: {
        en: 'Date Added',
        es: 'Fecha Agregado'
    },
    toastSearchError: {
        en: 'Enter a name to search',
        es: 'Ingrese un nombre para buscar'
    },
    searchDirectory: {
        en: 'Search Directory...',
        es: 'Buscar en el directorio...'
    },
    createDirectoryItem: {
        en: 'Create a directory profile',
        es: 'Crear un perfil de directorio'
    },
    itemPresent: {
        en: 'Your directory profile is present',
        es: 'Su perfil de directorio está presente'
    },
    addMyDirectory: {
        en: 'Add my directory here!',
        es: 'Agregue mi directorio aquí'
    },
    createYourDirectory: {
        en: 'Create Your Directory Profile',
        es: 'Crear perfil de directorio'
    },
    clickToAddImage: {
        en: 'Click to add image',
        es: 'Hacer clic para agregar una imagen'
    },
    replaceImage: {
        en: 'Replace Image',
        es: 'Reemplazar imagen'
    },
    uploadImage: {
        en: 'Upload Image',
        es: 'Subir imagen'
    },
    removeImage: {
        en: 'Remove image',
        es: 'Eliminar imagen'
    },
    directoryName: {
        en: 'Directory Name eg: Family last name',
        es: 'Nombre del directorio, por ejemplo: apellido familiar'
    },
    addPersonToDirectory: {
        en: 'Add Person to Directory',
        es: 'Agregar persona al directorio'
    },
    peopleInDirectory: {
        en: 'People in Directory',
        es: 'Personas en el directorio'
    },
    submitDirectory: {
        en: 'Submit Directory Profile',
        es: 'Enviar perfil de directorio'
    },
    toastErrorAddName: {
        en: 'Please add a name',
        es: 'Por favor agregue un nombre'
    },
    name: {
        en: 'Name',
        es: 'Nombre'
    },
    phone: {
        en: 'Phone (optional)',
        es: 'Teléfono (opcional)'
    },

    about: {
        en: 'About (optional). Use this section to add anything you would like others to know about this person.',
        es: 'Acerca de (opcional)s. Utilice esta sección para agregar cualquier cosa que le gustaría que otros supieran sobre esta persona.'
    },

    email: {
        en: 'Email (optional)',
        es: 'Correo electrónico (opcional)'
    },
    addBirthday: {
        en: 'Add Birthday',
        es: 'Agregar cumpleaños'
    },
    cancel: {
        en: 'cancel',
        es: 'cancelar'
    },
    edit: {
        en: 'Edit',
        es: 'Editar'
    },
    delete: {
        en: 'Delete',
        es: 'Eliminar'
    },
    confirmDelete: {
        en: 'Are you sure you want to delete this item?',
        es: 'Confirmar eliminación de este directorio'
    },
    confirmDeleteDetails: {
        en: 'This will remove it from your church profile. The creator of this directory will still have access to its contents on their own account.',
        es: 'Esto lo eliminará de su perfil de iglesia. El creador de este directorio seguirá teniendo acceso a su contenido en su cuenta personal'
    },
}