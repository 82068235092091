
import { getBulletins } from 'features/bulletin/bulletinSlice'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import BulletinItem from './BulletinItem'
import { MobilePageTitle } from 'components/main.styled'
import { addAc } from 'features/auth/authSlice'
import { addAcFunc } from 'funcs/ac'
import { language } from 'app/language'
import { ChatCenteredSlash  } from "@phosphor-icons/react";
import { NoItemsContainer } from 'components/main.styled';
import { File } from "@phosphor-icons/react";


export default function BulletinFeed() {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { bulletinList, isBulletinLoading, isBulletinSuccess } = useSelector((state) => state.bulletin)
    const [isUserAdmin, setIsUserAdmin] = useState(false)

    useEffect(() => {
        
        if(!user.isOrganization){
            setIsUserAdmin(profileChurch.admins.includes(user._id))
        }

        dispatch(getBulletins({
            churchId: profileChurch._id
        
        }))

    }, [dispatch, profileChurch._id, profileChurch.admins, user._id, user.isOrganization])

    useEffect(() => {

        const dis = () => dispatch(addAc({
          type: 'bulletin', 
        }))
    
        addAcFunc('bulletin', dis)
    
      }, [])

  return (
    <BulletinFeedContainer>

        {

            isBulletinLoading ? 
            (
                <NoItemsContainer> 
                    <File />
                    <div> Loading Bulletins </div>    
                </NoItemsContainer>
            )
            : isBulletinSuccess && bulletinList.length === 0 ? 
            (
                <NoItemsContainer> 
                    <File />
                    <div> No Bulletins  </div>    
                </NoItemsContainer>
            )
            : isBulletinSuccess && bulletinList.length > 0 &&
            (
                    [...bulletinList]
                    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                    .map((bulletin, index) => (
                        
                        <BulletinItem 
                            key={bulletin._id} 
                            index={index} 
                            data={bulletin} 
                            isUserAdmin={isUserAdmin}
                            />
                    )
                )
            )
        }
    </BulletinFeedContainer>
  )
}

const BulletinFeedContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start; 
    @media (max-width: 768px) {
        padding: 5px;
        margin-top: 0px;
    }
`