import axios from 'axios'

import { baseUrl } from '../constants'
const URL = baseUrl


const config = (token) => { return { headers: { Authorization: `Bearer ${token}`, }, } }
const API_URL = '/api/search/'
const API_URL_PAGINATE = '/api/search/paginate'

// Create new goal
const search = async (searchData, token) => {
  const response = await axios.post(URL+API_URL, searchData, config(token))
  return response.data
}


// test pagination
const searchPaginate = async (searchData, token) => {
  const response = await axios.post(URL+API_URL_PAGINATE, searchData, config(token))
  return response.data
}



const searchService = {
  search, searchPaginate
}

export default searchService
