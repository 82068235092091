import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import bulletinService from './bulletinService'

const errorMessage = (error) => {
    return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
  }

const initialState = {
  bulletinList: [],

  isBulletinError: false,
  isBulletinSuccess: false,
  isBulletinLoading: false,
  bulletinErrorMessage: '',

  isBulletinCreatedSuccess: false,
  isBulletinUploading: false,
  isBulletinCreatedError: false,
  bulletinCreatedErrorMessage: '',

  isDeleteBulletinLoading: false,
  isDeleteBulletinSuccess: false,  

  viewBulletinFileInModal: false,
  currentBulletinFileView: null,

  viewBulletinUploadForm: false,
  viewBulletinPDF: false,
  selectedPDF: null,

  viewDeleteFileModal: false,
  file2Delete: null,
} 

export const uploadBulletin = createAsyncThunk(
    'bulletin/upload',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await bulletinService.uploadBulletin(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )


  export const getBulletins = createAsyncThunk(
    'bulletin/get',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await bulletinService.getBulletins(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )


  export const deleteBulletin = createAsyncThunk(
    'bulletin/delete',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await bulletinService.deleteBulletin(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )


  export  const bulletinSlice = createSlice({
    name: 'bulletin',
    initialState,
    reducers: {
        bulletinReset: (state) => initialState,
        bulletinResetCreated: (state) => {
          state.isBulletinCreatedSuccess = false
          state.isBulletinUploading = false
          state.isBulletinCreatedError = false
          state.bulletinCreatedErrorMessage = ''
          state.selectedPDF = null
        },
        setViewBulletinUploadForm: (state, action) => { 
          state.viewBulletinUploadForm = action.payload
        },
        choosePDF: (state, action) => { state.selectedPDF = action.payload },
        setBulletinPDF: (state) => { state.showCreatePollModal = false },
        setViewBulletinFile: (state, action) => {
          state.viewBulletinFileInModal = true;
          state.currentBulletinFileView = action.payload
        },
        closeViewBulletinModal: (state, action) => {
          state.viewBulletinFileInModal = false;
          state.currentBulletinFileView = null;
        },
        setViewModal2DeleteBulletinFile: (state, action) => {
          state.viewDeleteFileModal = true;
          state.file2Delete = action.payload
        },
        closeModal2DeleteBulletinFile: (state, action) => {
          state.viewDeleteFileModal = false;
          state.file2Delete = null;
          state.isDeleteBulletinSuccess = false
        },
    },
    extraReducers: (builder) => {
      builder
        .addCase(uploadBulletin.pending, (state) => {
            state.isBulletinUploading = true
        })
        .addCase(uploadBulletin.fulfilled, (state, action) => {
          state.isBulletinUploading = false
          state.isBulletinCreatedSuccess = true
          state.bulletinList.unshift( action.payload.newBulletin )
        })
        .addCase(uploadBulletin.rejected, (state, action) => {
            state.isBulletinUploading = false
            state.isBulletinCreatedError = true
            state.bulletinCreatedErrorMessage = action.payload
        })
        //------------------
        .addCase(getBulletins.pending, (state) => {
          state.isBulletinLoading = false
        })
        .addCase(getBulletins.fulfilled, (state, action) => {
          state.isBulletinLoading = false
          state.isBulletinSuccess = true
          state.bulletinList = action.payload
        })
        .addCase(getBulletins.rejected, (state, action) => {
            state.isBulletinLoading = false
            state.isBulletinError = true
            state.bulletinErrorMessage = action.payload
        })
        //------------------

        .addCase(deleteBulletin.pending, (state) => {
          state.isDeleteBulletinLoading = false
        })
        .addCase(deleteBulletin.fulfilled, (state, action) => {
          state.isDeleteBulletinLoading = false
          state.isDeleteBulletinSuccess = true
          state.bulletinList = state.bulletinList.filter(
            (file) => file._id !== action.payload.id
          )
        })
        .addCase(deleteBulletin.rejected, (state, action) => {
            state.isDeleteBulletinLoading = false
            state.isBulletinError = true
            state.bulletinErrorMessage = action.payload
        })
        //------------------

      },
})

export const { 
  setViewBulletinFile, 
  bulletinReset, 
  bulletinResetCreated, 
  setViewBulletinUploadForm, 
  setBulletinPDF, 
  choosePDF, 
  closeViewBulletinModal, 
  setViewModal2DeleteBulletinFile,
  closeModal2DeleteBulletinFile

} = bulletinSlice.actions
export default bulletinSlice.reducer

