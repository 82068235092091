import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { SettingsMainContainer, TopHeaderSettings } from '../settings.styled' 
import { SubmitButton } from 'components/giving/giving.styled'
import { addBankForChurch } from 'features/giving/givingSlice'
import SpinnerSmall from 'components/SpinnerSmall'

export default function CreateExternalBankAccount() {

    const dispatch = useDispatch()

    const { user } = useSelector(state => state.auth)
    const { profileChurch } = useSelector(state => state.profileChurch)
    const { 
        createExternalBankAccountLoading,
        createExternalBankAccountSuccess,
        createExternalBankAccountError,
        createExternalBankAccountMessage,
        addBankForChurchLoading
    } = useSelector(state => state.giving)


    const handleSubmitBankForm = () => {
        const data = {  
            external_account: { 
                account_number: '000123456789',
                country: 'US',
                currency: 'usd',
                object: "bank_account",
                routing_number: '110000000',             
                account_holder_name: 'Test User Bank Name'    
            }

        }

        dispatch(addBankForChurch(data))

    }



    // include this link somewhere in the component
    // https://support.stripe.com/questions/is-my-financial-account-information-safe      &&&&&&&----------

    

    return (
        <SettingsMainContainer>
            <BankFormWrap>
                Bank Form 
                <SubmitButton
                    onClick={handleSubmitBankForm}
                    disabled={addBankForChurchLoading}
                    >
                    {
                        addBankForChurchLoading ? 
                        (
                            <SpinnerSmall />
                        )
                        :
                        (
                            'Submit'
                        )
                    }
                </SubmitButton>

            </BankFormWrap>
        </SettingsMainContainer>
    )
}

const BankFormWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%; 
`