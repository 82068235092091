import React, { useEffect, useRef, useState } from 'react'
import { FormWrap, GiveForm, LeftSide, BottomWrap, SubmitButton } from '../giving.styled'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { resetIntent, cancelProcess, getMemberPaymentMethods, setSelectedPaymentMethod, giveToChurch } from 'features/giving/givingSlice'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MenuItem from '@mui/material/MenuItem'; 
import SpinnerSmall from 'components/SpinnerSmall' 
import { toast } from 'react-toastify'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; 
import { PaymentElement,  useStripe,  useElements } from '@stripe/react-stripe-js';
import { useNavigate, useLocation } from 'react-router-dom'
import { RiSecurePaymentFill } from "react-icons/ri";
import WestIcon from '@mui/icons-material/West'; 
import Loader from 'loaders/Loader'
import { sendGivingTransactionEmail } from 'features/email/emailSlice'

const termsText = 'By providing your payment information and confirming this payment, you authorise (A) Praysely Inc and Stripe, our payment service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
  
export default function ConfirmPayment() { 
    const navigate = useNavigate()
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch()
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { user } = useSelector((state) => state.auth)
    const {  
        amount, description, paymentCards, giveToChurchLoading, giveToChurchSuccess, giveToChurchError,
        usBanks, selectedPaymentMethod,  getPaymentMethodsLoading,  getPaymentMethodsSuccess,  paymentGivingIntentData,
    } = useSelector((state) => state.giving)

    const [newPaymentMethod, setNewPaymentMethod] = useState(false)
    const [paymentSelected, setPaymentSelected] = useState(false)
    const [paymentLoading, setPaymentLoading] = useState(false)
    const [viewTerms, setViewTerms] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setPaymentLoading(true)
        if (!stripe || !elements) { return }

        if(!selectedPaymentMethod){
            const {error} = await stripe.confirmPayment({ 
                elements,
                confirmParams: { },
                redirect: 'if_required',
            }); 
            if (error) {  
                setPaymentLoading(false)
                toast.error(error.message)
            } else { 
                setPaymentLoading(false)
                dispatch(sendGivingTransactionEmail({
                    recipient: profileChurch.email,
                    createdBy: user.fullName,
                }))
                toast.success('Payment successful')
                navigate('?section=giving&v=give&status=success')
            } 
        }else{ 
            dispatch(giveToChurch({
                churchId: profileChurch._id,
                amount: amount,
                description: description,
                paymentMethod: selectedPaymentMethod.id,
                paymentIntentId: paymentGivingIntentData.id,
            }))   
        } 
        setPaymentLoading(false)
    }

    const handleCancel = () => {
        dispatch(cancelProcess())
        navigate('?section=giving&v=give&status=initialize')
    }

    const handleBack = () => {
        dispatch(resetIntent())
        navigate('?section=giving&v=give&status=initialize')
    }

    const handlePaymentSelect = (e) => {
        setPaymentSelected(true)
        setNewPaymentMethod(false)
        dispatch(setSelectedPaymentMethod(e.target.value))

        if(e.target.value.type !== 'card'){ 
            if(e.target.value.type === 'us_bank_account'){
                setViewTerms(true)
            }
        }else{
            if(viewTerms) setViewTerms(false) 
        } 
    }

    const handleNewMethod = () => {
        setPaymentSelected(false)
        dispatch(setSelectedPaymentMethod(''))
        setNewPaymentMethod(true)
        setViewTerms(false)
    } 

    const handleUseExistingMethod = () => {
        setPaymentSelected(true)
        setNewPaymentMethod(false)
        setViewTerms(false)
    } 

    useEffect(() => {
        if(selectedPaymentMethod.id){
            if(selectedPaymentMethod.type === 'us_bank_account'){
                setViewTerms(true)
            } 
        }
    }, [selectedPaymentMethod.id])

    useEffect(() => {
        if(paymentCards.length === 0 && usBanks.length === 0){ 
            dispatch(getMemberPaymentMethods({ churchId: profileChurch._id,  }))
        }
    }, [])

    useEffect(() => {
        if(giveToChurchSuccess){ 
            navigate('?section=giving&v=give&status=success') 
        }else{
            if(giveToChurchError){
                toast.error('Error occured, please try again later')
                dispatch(cancelProcess())
                navigate('?section=giving&v=give&status=initialize')
            }
        }
    }, [giveToChurchSuccess, giveToChurchError, dispatch, navigate])

    return ( 
        <PageContainer >
            <BackButton onClick={handleBack}> 
                <WestIcon />  
                Back 
            </BackButton> 
            <FormWrap>  
                <LeftSide> 
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',  width: '100%',   }}>
                        <CFM>confirm</CFM>  You are giving:
                    </div>
                    <h3>
                        <AttachMoneyIcon style={{fontSize: '40px' }} />
                        <span> { amount } </span> 
                    </h3>
                    <div style={{   marginTop: '15px'  }} >  to {profileChurch.fullName} </div> 
                    <CfmDesc>  {description} </CfmDesc> 
                </LeftSide> 

                <GiveForm> 
                    <RightSideWrap>
                    {
                        getPaymentMethodsLoading ? 
                        (  <Loader />  )
                        :  !newPaymentMethod ?
                        ( 
                            <FormControl fullWidth>
                                <Select
                                    style={{backgroundColor: 'white'}} 
                                    id="demo-simple-select" 
                                    value={selectedPaymentMethod } 
                                    displayEmpty 
                                    onChange={handlePaymentSelect}
                                    MenuProps={MenuProps}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <em>Select from your saved payment methods</em>;
                                        } 
                                        if(selected.type === 'card'){
                                            return selected.card.brand + ' **** ' + selected.last4
                                        }else if(selected.type === 'us_bank_account'){ 
                                            return selected['us_bank_account'].bank_name + ' **** ' + selected['us_bank_account'].last4
                                        }
                                        }}
                                    >
                                        <MenuItem disabled value="">
                                            <em>Your saved payment methods</em>
                                        </MenuItem>
                                    {
                                        usBanks.map((bank, index) => (
                                            <MenuItem 
                                                value={bank}  
                                                key={bank.id}
                                                > 
                                                {bank[bank.type].bank_name} .... {bank[bank.type].last4}
                                            </MenuItem>
                                        ))
                                    }
                                    {
                                        paymentCards.map((card, index) => (
                                            <MenuItem 
                                                value={card} 
                                                key={card.id}
                                                >
                                                {card.card.brand} **** {card.last4}
                                            </MenuItem>
                                        ))
                                    } 
                                </Select>
                            </FormControl>           
                        )
                        : newPaymentMethod && 
                        (
                            <CardElementWrap> 
                                <CheckoutHeading>
                                    <RiSecurePaymentFill style={{fontSize: '20px', marginRight: '10px'}} />
                                    Secure Payment Details
                                </CheckoutHeading>
                                <PaymentElement  /> 
                                <AddNewBtn 
                                    style={{ textDecoration: 'underline', border: 'none', boxShadow: 'none', marginTop: '20px', fontSize: '15px', width: '80px' }}
                                    onClick={handleUseExistingMethod}
                                    >
                                    cancel
                                </AddNewBtn>      
                            </CardElementWrap>
                        )
                    }
                            {!newPaymentMethod &&
                            (
                                <div style={{ marginTop: '20px' }} >
                                    <AddNewBtn   onClick={handleNewMethod}  >
                                        <AddCircleIcon style={{marginRight: '10px'}} /> Add a new card
                                    </AddNewBtn>
                                </div>    
                            )}
                                     
                    </RightSideWrap>
                    {
                        (selectedPaymentMethod || newPaymentMethod) &&
                        (
                            <BottomWrap  style={{ width: '100%', flexDirection: 'column', }}>
                                {
                                    viewTerms &&
                                    (
                                        <MandateAcceptanceText>{termsText}</MandateAcceptanceText>
                                    )
                                }
                                <ISW>
                                    <CancelBtn onClick={handleCancel}>Cancel</CancelBtn>
                                    <SubmitButton
                                        onClick={handleSubmit}
                                        disabled={paymentLoading || giveToChurchLoading}
                                        >
                                        {
                                            paymentLoading || giveToChurchLoading ?
                                            (  <SpinnerSmall />  )
                                            :
                                            ( 'Confirm' )
                                        }
                                    </SubmitButton>
                                </ISW>
                            </BottomWrap>
                        )
                    } 
                </GiveForm>
            </FormWrap>
        </PageContainer>
    )
}

const ISW = styled.div`
    margin-top: 30px;
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
`

const MandateAcceptanceText = styled.div`
    font-size: 10px;
    color: var(--lightThemeFontColor);
    text-align: left;
`
const PageContainer = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
`

const AddNewBtn = styled.div`

    color: var(--lightThemeFontColor);
    font-weight: 500;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: center;
    background-color: white;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    margin-top: 10px; 
    box-shadow: 0px 0px 5px #ccc;
    &:hover{
        color: #515151;
    
        box-shadow: 0px 0px 5px #ccc;
    }
`

const BackButton = styled.div`  
    cursor: pointer;
    margin-bottom: 15px;
    color: var(--lightThemeFontColor);
    transition: 0.3s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px; 
    &:hover{ color: #515151; } 
    svg{ margin-right: 10px; }
`

const CheckoutHeading = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--lightThemeFontColor);
    margin-bottom: 20px;
`

const RightSideWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    min-height: 250px;

    @media (max-width: 768px){
        width: 100%;
        margin-top: 30px;
        justify-content: center;
        align-items: center;
    }


`   

const CardElementWrap = styled.div`
    margin-bottom: 20px;
    padding: 14px;
    border-radius: 4px;
    background-color: white ;
    border: 2px solid #eaeaea;
    width: 100%;
    box-shadow: 0px 0px 5px #e7e7e7;

    @media (max-width: 768px){
        width: 80%;
        margin-top: 30px; 
    }

`
const CFM = styled.div`
    font-size: 12px;
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
    background-color: #3c9d3c; 
    margin-right: 10px;
`

const CfmDesc = styled.div`
    margin-top: 25px;
    font-size: 14px;
    width: 100%;
    height: fit-content;
    color: var(--lightThemeFontColor);
    background-color: #e1e1e1;
    padding: 10px;
    max-height: 200px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 0px;
    }


`

const CancelBtn = styled.div` 
    color: #a8a8a8;
    font-weight: 500; 
    padding: 0px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    margin-right: 50px; 
    &:hover{
        color: #515151;
        border-bottom: 1px solid #515151;
    }
`