import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  postType: '',
  isAnonymous: false,
}

export const selectPostTypeSlice = createSlice({
  name: 'postType',
  initialState,
  reducers: {
    setIsAnonymous: (state, action) => {
      state.isAnonymous = action.payload
    },
    setPostType: (state, action) => {
      state.postType = action.payload
    },
    resetPostType: (state) => initialState,
  },
})

export const { setPostType, resetPostType, setIsAnonymous } = selectPostTypeSlice.actions

export default selectPostTypeSlice.reducer
