import { useState } from 'react'
import styled from 'styled-components'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'; 
import { useSelector, useDispatch } from 'react-redux'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { toast } from 'react-toastify';

export default function SearchPosts() {
  const dispatch = useDispatch()
  const [searchVal, setSearchVal] = useState('')
  const { searchTerm, isSearchActive, isSearchLoading } = useSelector((state) => state.posts)
  const [isFocused, setIsFocused] = useState(false)

  const handleSearch = (e) => {

    e.preventDefault()
    // if(searchVal.length <= 2){
    //   return toast.error('Enter a name to search', {
    //     position: "top-right",
    //     autoClose: 1500,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: false,
    //     progress: undefined,
    //     theme: "light",
    //   })
    // } 

    // dispatch(setSearchTerm(searchVal))

    // dispatch(searchDirectory({
    //   searchVal: searchVal
    // })) 
  }

  const handleClearSearch = () => {
    setSearchVal('')
  } 

// in redux create a copy of the current directoryItems array so that
// when the user is done searching ( the original search will create a new array of directoryItems to be displayed )
// the original array of directory items will be returned

  return (
    <PostSearchWrap>
      {/* <DirectorySearchForm 
        isFocused={isFocused}
        >
        <DirectoryInput 
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          value={searchVal}
          onChange={(e) => setSearchVal(e.target.value)} 
          placeholder="Search Posts" 
          /> 
        { isSearchLoading ? 
          (
            <SearchBtn> <HourglassEmptyIcon/> </SearchBtn> 
          )
          :
          (
            <SearchBtn onClick={handleSearch} onSubmit={handleSearch}> <PersonSearchIcon/> </SearchBtn> 
          ) 
        } 

      </DirectorySearchForm> */}
      
      {
        isSearchActive && (
          <SearchTermVal onClick={handleClearSearch}>
            <ST>{searchTerm}</ST>
            <SearchOffIcon style={{
              marginLeft: '0px',
              marginTop: '0px',
              display: 'flex', 
              alignSelf: 'start',
              justifySelf: 'start',
              // fontSize: 'smaller', 
              cursor: 'pointer', 
              color: '#ff0000bf'}}/>
          </SearchTermVal>                  
        )
      }
    </PostSearchWrap>
  )
}
const PostSearchWrap = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%; 
  margin-left: 10px;
  @media (max-width: 768px) {
    width: 100%; 
    margin-right: 5px;
  }

`

const SearchTerm = styled.div`
  
`
const DirectorySearchForm = styled.form`
    display: flex;
    justify-content: row;
    align-self: flex-start; 
    justify-self: flex-start;
    box-shadow: ${props => props.isFocused ? '0 0 5px #414141' : '0 0 10px #ccc'};
    width: fit-content;
    border-radius: 8px;
    background-color: white;
    padding: 4px;
    transition: all 0.3s ease-in-out;
    @media (max-width: 768px) {
      width: 100%;
    }
`
const DirectoryInput = styled.input`
    padding: 5px;
    outline: none;
    border: none;
    border-radius: 5px 0px 0px 5px;
    @media (max-width: 768px) {
      width: 100%;
    }
`
const SearchBtn = styled.button`
  cursor: pointer;
  color: #ffffff;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
  border: none;
  background-color: #898989;
  border-radius: 4px;
  &:hover{
     background-color: green;
     color: white;
  }
`
const ST = styled.span`
  padding: 5px 8px;
  background-color: #00800091;
  color: white;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  border-radius: 4px;
  box-shadow:  0 0 10px #ccc;

`
const SearchTermVal = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  align-self: center;
  justify-self: center;
`