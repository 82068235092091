import React, { useEffect } from 'react'
import { checkConnectAccountStatus, getConnectOnboardingLink } from 'features/giving/givingSlice'
import { useDispatch, useSelector } from 'react-redux'
import { SettingsMainContainer, TopHeaderSettings } from '../settings.styled' 
import CreateExternalBankAccount from './CreateExternalBankAccount'
import VerifiedConnectAccount from './VerifiedConnectAccount'
import Oboarding from './Onboarding'
import RequirementsDue from './RequirementsDue'


export default function ChurchConnectAccount() {

    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { profileChurch } = useSelector(state => state.profileChurch)
    const { 
        connectAccount,
        churchConnectAccountStatusLoading,
        churchConnectAccountStatusSuccess,
        churchConnectAccountStatusError,
        churchConnectAccountStatusMessage
    } = useSelector(state => state.giving)


    useEffect(() => {
        dispatch(checkConnectAccountStatus())
    }, [])


    if(churchConnectAccountStatusLoading){
        return (
            <SettingsMainContainer> 
                Verifying Praysely Giving Account Status...
            </SettingsMainContainer>
        )
    }else if(churchConnectAccountStatusSuccess && connectAccount && connectAccount.payouts_enabled === false){

        return (
            <RequirementsDue />
        )
    }else if(churchConnectAccountStatusSuccess && connectAccount && connectAccount.payouts_enabled){
        return (
            <VerifiedConnectAccount />
        )
    }else{
       return (
            <Oboarding />
        ) 
    }  
}
