import React from 'react'
import { PageWrapper, PageContainerMain } from '../pages.styled'
import { useSelector } from 'react-redux'
import ChurchExtras from 'components/userProfile/ChurchExtras'
import UserExtras from 'components/userProfile/UserExtras'
import MarketContainerMain from 'components/marketplace/MarketContainerMain'
import MarketSidebar from 'components/marketplace/MarketSidebar'

export default function Marketplace() {
    
    const { user } = useSelector((state) => state.auth) 
    return (
        <PageWrapper>
            <PageContainerMain  > 
                <div className='churchProfileLeft'>
                {
                    user && (
                    user.isOrganization ?  ( <ChurchExtras /> ) : (  <UserExtras /> )
                    )
                } 
                </div>  

                <MarketSidebar />

                <MarketContainerMain />

            </PageContainerMain>
        </PageWrapper>
    )
}
