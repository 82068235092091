import React from 'react'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux' 
import { useNavigate, useLocation } from 'react-router-dom'
import { setActiveSection } from 'features/profileChurch/profileChurchSlice'
import { ICONS } from './navIcons'
import {
    MainItems,
    PCNavLi,
    PCNavLiTitle,
    PCNavLiTitleIcon,
} from './extras.styled'
import { language } from 'app/language'

export default function ChurchProfileTopNavDesktopView() {
 
    const { user } = useSelector((state) => state.auth)
    const { profileChurch, churchFeatures, activeSection } = useSelector((state) => state.profileChurch)
    const navigate = useNavigate()
    const dispatch = useDispatch()  
    const location = useLocation(); 
    const section =  new URLSearchParams(location.search).get('section') ? new URLSearchParams(location.search).get('section') : 'none'

    const [mainNavItems, setMainNavItems] = useState([])
    const [extraNavButton, setExtraNavButton] = useState([])

    const handleClick = (value) => {
        navigate(`/${profileChurch.username}?section=${value}`)
        dispatch(setActiveSection(value))
    }

    useEffect(() => {
        if(section !== activeSection){
            dispatch(setActiveSection(section))
        } 

    }, [activeSection, section])

    
    useEffect(() => {
        // check if current section is part of the main sections if it isn't set the extra buttons to the current section
        if(!churchFeatures.main.some(item => item.value === section)){
            // find the current section in the extra buttons
            const extraButton = churchFeatures.extra.find(item => item.value === section)
            setExtraNavButton(extraButton)
        }else{
            setExtraNavButton(null)
        }
    }, [section])

    useEffect(() => {
        
        //get the arrays from churchFeatures.main and churchFeatures.extra
        const main = churchFeatures.main
        const extra = churchFeatures.extra

        //combine the two arrays
        const combined = [...main, ...extra]

        //set the first 4 items of the combined array to the mainNavItems
        setMainNavItems(combined.slice(0, 4))

        // set the rest of the items to the extraNavItems
        // setExtraNavItems(combined.slice(4)) 
    }, [])


    return ( 
        <MainItems>
        {
            mainNavItems.map((item, index) => {
                if(item.enabled){
                    return(
                        <PCNavLi 
                            key={item.value} 
                            active={activeSection === item.value} 
                            onClick={()=> handleClick(item.value) }
                            >
                            <PCNavLiTitleIcon>
                                {ICONS[item.value]}
                            </PCNavLiTitleIcon>    
                            <PCNavLiTitle>
                                {language[item.label][user.preferredLanguage]}
                            </PCNavLiTitle> 
                        </PCNavLi>                                        
                    )
                }
            })
        }
        {
            // // When on desktop view, if a user clicks on a menu item, 
            // // the menu item should be highlighted as active and visible in the top menu
            
            extraNavButton && (
                <PCNavLi 
                    key={extraNavButton.value} 
                    active={activeSection === extraNavButton.value} 
                    onClick={()=> handleClick(extraNavButton.value) }
                    > 
                    <PCNavLiTitleIcon>
                        {ICONS[extraNavButton.value]}
                    </PCNavLiTitleIcon>    
                    <PCNavLiTitle>
                        {extraNavButton.label}
                    </PCNavLiTitle> 
                </PCNavLi>
            ) 
        }

        </MainItems>
    )
}
