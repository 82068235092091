import ModalCloseButton from 'components/buttons/ModalCloseButton'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { setViewCreatePayoutModal, createPayout, resetCreatePayout } from 'features/giving/givingSlice'
import { FaMoneyBillTransfer } from "react-icons/fa6";
import Select  from '@mui/material/Select';
import FormControl from '@mui/material/FormControl'; 
import MenuItem from '@mui/material/MenuItem'; 
import { SubmitButton, BottomWrap } from 'components/giving/giving.styled'
import { toast } from 'react-toastify'
import SpinnerSmall from 'components/SpinnerSmall'
import { FaMoneyBill1Wave } from "react-icons/fa6";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

export default function RequestPayoutModal() {

    const dispatch = useDispatch()

    const { user } = useSelector(state => state.auth)
    const { profileChurch } = useSelector(state => state.profileChurch)
    const { 
        destInfo,  
        connectAccountBalance,
        createPayoutSuccess,
        createPayoutLoading,
        createPayoutError,
        createPayoutMessage
    } = useSelector(state => state.giving)
    const [destinationId, setDestinationId] = useState('')
    const [amount, setAmount] = useState('')

    const handleAmountChange = (e) => { 
        if(e.target.value.includes(' ')){return }
        if(isNaN(e.target.value)){return}
        let rawa = e.target.value
        let formattedAmount = rawa.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // should not be more that 10 digits
        if(formattedAmount.length > 12){return }
        formattedAmount = formattedAmount.replace(/\,/g, '') 
        setAmount(formattedAmount)
    }

    const handleSubmit = () => {

        if(amount === ''){
            return toast.error('Request amount is required')
        } 
        if(destinationId === ''){
            return toast.error('Destination is required')
        }

        if(Number(amount) > Number(connectAccountBalance.available)){
            return toast.error('Request amount exceeds available balance')
        }
 
        dispatch(createPayout({
            amount: amount,
            destination: destinationId
        })) 

    }

    useEffect(() => {
        if(createPayoutSuccess){
            dispatch(setViewCreatePayoutModal(false))
            toast.success('Payout request sent successfully')
        } 
        if(createPayoutError){
            if(createPayoutMessage){ 
                toast.error(createPayoutMessage)
            }else{
                toast.error('An error occured. Please try again')
            }
            dispatch(setViewCreatePayoutModal(false))
        } 
        dispatch(resetCreatePayout()) 
    }, [createPayoutSuccess, createPayoutError])


    return (
        <RequestPayoutModalContainer>
            <ModalCloseButton top='10px' right='10px' size='40px'
                handler={() => dispatch(setViewCreatePayoutModal(false))}
                /> 
            <ModalHeading> <FaMoneyBill1Wave fontSize={'30px'} /> Request Payout </ModalHeading>
        
            <ModalBody> 
                <TopWrap>  
                    { Object.keys(destInfo).length > 0 ?
                        (
                            <FormControl fullWidth> 
                                <Select
                                    style={{backgroundColor: 'white'}} 
                                    id="demo-simple-select" 
                                    value={destinationId } 
                                    displayEmpty 
                                    onChange={(e) => setDestinationId(e.target.value)}
                                    MenuProps={MenuProps}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <em>Select a destination</em>;
                                        }else{
                                            return `${destInfo[selected].name } - ${destInfo[selected].last4} `
                                        }
                                        }}
                                    >
                                    {
                                        Object.keys(destInfo).map((key, index) => {
                                            return (
                                                <MenuItem 
                                                    key={index} 
                                                    value={key}
                                                    >
                                                    {destInfo[key].name} - {destInfo[key].last4}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        )
                        :
                        (
                            <p>You have no bank accounts to send payouts to. Please add a bank account to receive payouts.</p>
                        )
                    }
                    <InputWrao> 
                        <PayoutInput
                            type="text"
                            placeholder="Amount"
                            value={amount}
                            onChange={handleAmountChange}
                            /> 
                    </InputWrao>  
                    <div style={{color: '#767676', fontSize: '14px'}} > 
                        Available Balance: ${connectAccountBalance.available.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
                        <br />
                        <br />
                        Request Amount: ${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
                    </div> 
                </TopWrap>
                <BottomWrap> 
                    <SubmitButton
                        disabled={createPayoutLoading}
                        onClick={handleSubmit}
                        > 
                        {
                            createPayoutLoading ? (
                                <SpinnerSmall />
                            )
                            :
                            (
                                'Request Payout'
                            )
                        }
                    </SubmitButton>
                </BottomWrap>
            </ModalBody> 
        </RequestPayoutModalContainer>
    )
}

const PayoutInput = styled.input`
    padding: 15px;
    width: 100%;
    border: 2px solid #ababab6c;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 20px;
    transition: all 0.3s ease-in-out;
    outline: none;
    /* focus within */
    color: ${props => props.notAllowed ? 'red' : 'grey'};

    &:focus{
        border: 2px solid var(--themeColor);
    }

`

const LeftSideTopWrap = styled.div`

    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%; 
`

const RightSideTopWrap = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%; 
    background-color: #e6e6e6;
    border-radius: 5px; 
    height: calc(100% - 20px);
`


const InputWrao = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: var(--lightThemeFontColor);
    width: 100%;
    margin-top: 40px;
    label{
        font-size: 14px;
        color: #767676;
        margin-bottom: 5px;
    }

    &:hover{
        input{
            border: 2px solid grey;
        } 
    }

`

const TopWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    height: 300px;
`

const ModalBody = styled.div`
    margin-top: 30px;
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
`

const RequestPayoutModalContainer = styled.div`
    border-radius: 5px;
    background-color: white;
    width: 400px;
    height: 400px;
    position: relative;
    padding: 20px 30px 30px 30px;
`

const ModalHeading = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--lightThemeFontColor);
    svg{
        margin-right: 10px;
    }
`