import {useState,useRef} from 'react'
import styled from 'styled-components'
import useToggleDropdown from '../../hooks/useToggleDropdown';
import { } from '../../features/events/eventSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import { imgUrl } from 'features/constants';

export default function EventItemSaved(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth)
    const { isResponseLoading, viewEventModalEdit, eventEditIndex } = useSelector((state) => state.events)
    const [isInterested, setIsInterested] = useState(false)
    const [isAttending, setIsAttending] = useState(false)

    const handleViewEvent = () => {
        navigate(`/events/${props.data._id}`)
    }

    let imageToShow;
    try{ imageToShow =  `${imgUrl}/events/${props.data.image.imagePath2}`}
    catch(e){  imageToShow =  `${imgUrl}/events/${props.data.image.imagePath2}`}

    let theEventDate = new Date(props.data.eventDate)


    useEffect(() => {
        
        const foundInInterested = props.data.interested.find(ele => ele._id.toString() === user._id)
        const foundInAttending = props.data.attending.find(ele => ele._id.toString() === user._id)
        if(foundInInterested){
            setIsInterested(true)
        }else if(foundInAttending){
            setIsAttending(true)
        }else{
            console.log('none founds')
        }

    }, [])

    
  return (
        <EventItemContainer>
            <UserResponse>{isInterested ? ("I'm interested"): isAttending && ("I'm attending")}</UserResponse>

            <EventImageWrap><img src={imageToShow} alt={'Event'} /> </EventImageWrap>

            <EventItemWrap>
                <EventDetails>
                    <EventName>{props.data.eventName}</EventName>
                    <EventDate>
                        <DateDay>{theEventDate.toDateString()}</DateDay><span>at</span>
                        <DateTime> { theEventDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} </DateTime> 
                    </EventDate>
                </EventDetails>

                <InterestWrap>

                    <Respond onClick={handleViewEvent} > View Event </Respond>
  
                    <Interest>
                            <InterestText>  <InterestNumber>{props.data.interested.length}</InterestNumber>Interested </InterestText>
                            <InterestText>  <InterestNumber>{props.data.attending.length}</InterestNumber>Attending</InterestText>
                    </Interest>
                    
                </InterestWrap>
        </EventItemWrap>
    </EventItemContainer>
  )
}

const EventItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 20px;
    border-radius: 10px;
    width: fit-content;
    background-color: white;
    box-shadow: 0 0 10px #ccc;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    @media (max-width: 768px) {
        flex-direction: column;
    }

`

const UserResponse = styled.div`
    position: absolute;
    left:11px;
    top: 0;
    background-color: #00B4CC;
    padding: 9px;
    border-radius: 8px;
    font-size: 10px;
    color: white;
    margin-top: 10px;
    margin-right: 10px;
    box-shadow: 0 0 10px #ccc;
    @media (max-width: 768px) {
        padding: 13px;
    }
`
const EventImageWrap = styled.div`
    width: 300px;
    height: 200px;
    background-color: grey; 
    & img {
        width: 100%;
        height: 100%;
    }

    @media (max-width: 768px) {
        width: 100%;
        height: auto;

    }

`
const EventItemWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    justify-content: space-between;
`
const EventDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    /* margin-top: 30px; */
`

const EventDate = styled.div`
    display: flex;
    flex-direction: row;
    color: var(--lightThemeFontColor);
    @media (max-width: 768px) {
        margin-left: 0px;
        padding: 0px 10px 20px 10px;
    }
`
const DateDay = styled.div`
    margin-right: 5px;
`
const DateTime = styled.div`
    margin-left: 5px;

`


const EventName = styled.div`
    font-weight: bold;
    font-size: 20px;
    color: var(--lightThemeFontColor);
    @media (max-width: 768px) {
        padding: 10px;
    }
`

const InterestWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
const Interest = styled.div`
    display: flex;
    flex-direction: row;

`

const InterestText = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--lightThemeFontColor);
`
const InterestNumber = styled.div`
    padding: 8px;
    color: var(--lightThemeFontColor);
`

const Respond = styled.div`
    padding: 10px 30px;
    color: #424242;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 8px;
    cursor: pointer;
    &:hover{
        background-color: rgb(212, 212, 212);
        border: 1px solid rgb(163, 163, 163);
    }
`