import ModalCloseButton from 'components/buttons/ModalCloseButton'
import React from 'react'
import styled from 'styled-components'  
import { useSelector, useDispatch } from 'react-redux'
import { setViewRsvpModal, sendResponse } from 'features/events/eventSlice'
import { FaEnvelopeOpenText } from "react-icons/fa6";
import { IoPersonOutline , IoPerson, IoPersonAddOutline, IoMdPersonAdd, IoPersonRemoveOutline, IoPersonRemove    } from "react-icons/io5";
import { IoAdd } from "react-icons/io5";
import { LuMinus } from "react-icons/lu";
import LoaderSmall from 'loaders/LoaderSmall'
import SpinnerSmall from 'components/SpinnerSmall'

export default function RsvpModal({attending, attendingArray}) {

    const dispatch = useDispatch()

    const { rsvpData, isResponseLoading } = useSelector(state => state.events)
    const { user } = useSelector(state => state.auth)

    const [numberAttending, setNumberAttending] = React.useState(0) 
    const [tempAttending, setTempAttending] = React.useState(rsvpData.attending)
    
    const handleResponse = () => {
        dispatch(sendResponse({
            eventId: rsvpData.eventId,
            response: 'attending',
            numberAttending: numberAttending
        }))
    }
    
    const handleNotAttending = () => {
        dispatch(sendResponse({
            eventId: rsvpData.eventId,
            response: 'notAttending',
            numberAttending: 0
        }))
    }

    const handlePeopleAttending = (num) => { 

        if(num > 0){
            setNumberAttending(num)

        } else {
            setNumberAttending(1)
        }
    }

    React.useEffect(() => {
        
 

        // find my entry in the array and use that to set the number of people attending
        const found = attendingArray.find((ele) =>   ele._id.toString() === user._id.toString() )

        if(found){
            setNumberAttending(found.attending)
        }else {
            setNumberAttending(0)
        }


        // setNumberAttending(total)
    }, [attendingArray])
    
    return (
        <RsvpModalContainer>

            <ModalCloseButton 
                handler={() => dispatch(setViewRsvpModal(false))}
                size='35px'
                top='10px'
                right='10px'
                />

                <RsvpHeader>
                    <FaEnvelopeOpenText size='19px' />
                    RSVP  
                </RsvpHeader>

                { !tempAttending && 
                    (
                        rsvpData &&  rsvpData.status === 'attending' ?
                        (
                            <RsvpFlexWrap>  
                                <RsvpBtn 
                                    borderColor='var(--themeColor)'
                                    borderHoverColor='var(--themeColorDark)'
                                    onClick={() => {setTempAttending(true)}}
                                    >Update RSVP
                                </RsvpBtn>
                                <RsvpBtn
                                    borderColor='#ccc'
                                    borderHoverColor='#8d8d8d'
                                    style={{backgroundColor: '#ccc', flexGrow: '1'   }}
                                    onClick={() => handleNotAttending()}
                                    >
                                    {
                                        isResponseLoading ? 
                                        (
                                            <SpinnerSmall />
                                        )
                                        :
                                        (
                                            'No Longer Attending'
                                        )
                                    }
                                </RsvpBtn>
                            </RsvpFlexWrap>
                        )
                        : rsvpData &&  rsvpData.status === 'notAttending' &&
                        (
                            <RSVPwrap>  
                                <RsvpBtn3
                                    grow='1'
                                    onClick={() => {setTempAttending(true)}}
                                    >
                                        RSVP for this event
                                </RsvpBtn3>   
                            </RSVPwrap>
                        )
                    )
                }
                {
                    tempAttending && 
                        <RSVPwrap>
                            <h3>Who is attending?</h3> 
                            <RsvpFlexWrap>
 
                                <RsvpBtn2
                                    grow='2'
                                    onClick={() => handlePeopleAttending(1)}
                                    >
                                    Just Me
                                </RsvpBtn2> 

                                <RsvpBtn2
                                    grow='1'
                                    onClick={() => handlePeopleAttending(numberAttending + 1)}
                                    >
                                        <IoAdd size='20px' />
                                        <IoPersonOutline size='20px' />
                                </RsvpBtn2>
                                <RsvpBtn2
                                    grow='1'
                                    onClick={() => handlePeopleAttending(numberAttending - 1)}
                                    >
                                        <LuMinus size='20px' />
                                        <IoPersonOutline size='20px' />
                                </RsvpBtn2>
                            </RsvpFlexWrap>
                            <div>
                                { numberAttending > 1 ? 
                                    <p><Num>{numberAttending}</Num> people are attending this event</p>
                                    : numberAttending === 1 ?
                                    <p><Num>{numberAttending}</Num> person is attending this event</p>
                                    : <p>0 people are attending this event</p>
                                }
                            </div>

                            <CnfrmBW> 

                                <ConfirmRsvpBtn
                                    disabled={isResponseLoading}
                                    onClick={() => handleResponse()}
                                    >
                                    {
                                        isResponseLoading ? 
                                        (
                                            <SpinnerSmall />
                                        )
                                        :
                                        (
                                            'Confirm RSVP'
                                        )

                                    }
                                </ConfirmRsvpBtn>                                
                            </CnfrmBW>
                        </RSVPwrap>
                }
        </RsvpModalContainer>
    )
}

const CnfrmBW = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`

const ConfirmRsvpBtn = styled.div`

    background-color: var(--themeColor);
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 40px;
    
    transition: background-color 0.3s;
    &:hover {
        background-color: var(--themeColor);
        box-shadow: 0 0 5px rgba(0,0,0,0.2);
    }

`

const Num = styled.span`
    font-size: 20px;
    font-weight: 600;

`

const RsvpFlexWrap = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: stretch; 
`

const RsvpBtn2 = styled.div`
    display: flex;
    flex-grow: ${props => props.grow};
    background-color: #ccc;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border: 3px solid;
    border-color: #ccc;

    &:hover {
        transition: all 0.3s;
        border-color: #8d8d8d;
    }

`
const RsvpBtn3 = styled.div`
    display: flex;
    width: 100%;
    background-color: var(--themeColor);
    padding: 20px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 20px;
`

const RSVPwrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
`

const RSVPBTNWRAP = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

`

const RsvpBtn = styled.button`
    transition: all 0.3s;
    background-color: var(--themeColor);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 18px;
    border: 3px solid ${props => props.borderColor};
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500; 
    flex-grow: 2;
    margin-top: 20px;
    transition: background-color 0.3s;
    &:hover {
        transition: all 0.3s;
        border: 3px solid ${props => props.borderHoverColor};
        
    }
`

const RsvpModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    background-color: white;
    border-radius: 10px;
    width: 500px;
    height: fit-content;
    padding: 25px;
    color: var(--lightThemeFontColor);

    transition: all 0.3s;

`
const RsvpHeader = styled.div`
    font-size: 13px;
    width: 100%;
    margin-bottom: 20px;
    display: flex   ;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: var(--lightThemeFontColor);

    svg {   
        margin-right: 10px;
    }
    
`