
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { aboutChurchStore } from 'app/aboutChurchStore' 
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import { ModalContainerMain } from 'components/modal/modal.styled';
import ModalCloseButton from 'components/buttons/ModalCloseButton';
import { deleteChurchPerson, setViewConfirmModaliInAbout } from 'features/profileChurch/profileChurchSlice';
import { setViewConfirmModal, setConfirmModalData } from 'features/modal/modalSlice';
import { StyledBio, ShowMore } from './about.styled';
import { Tooltip } from '@mui/material';

export default function PersonCardModal() {
    const dispatch = useDispatch()
    const personDetails = aboutChurchStore(state => state.personDetails)
    const setViewPersonCardModal = aboutChurchStore(state => state.setViewPersonCardModal)
    const linkIcons = aboutChurchStore(state => state.linkIcons)
    const { user, admin } = useSelector((state) => state.auth)
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const setIsEditingCard = aboutChurchStore(state => state.setIsEditingCard)
    const setPersonEditDetails = aboutChurchStore(state => state.setPersonEditDetails)
    const setPersonDetails = aboutChurchStore(state => state.setPersonDetails)
    const [showMore, setShowMore] = useState(false)

    const handleClick = () => {
        setViewPersonCardModal(false)
        setPersonDetails(null)
    }

    const handleEdit = () => {
        setPersonEditDetails(personDetails)
        setIsEditingCard(true)
        setViewPersonCardModal(false)
    }



    if(!personDetails){
        return (
            <div>loading...</div>
        )
    }else{
        return (
            <ModalContainerMain>
                <PersonCardModalWrap>
                    <ModalCloseButton
                        handler={handleClick}
                        size='45px'
                        top='10px'
                        right='10px'
                        />

                    <PersonCardWrap>
                        <PersonCardModalImage>
                            { personDetails.image && (
                                    <img src={personDetails.image} alt={'person'}/>
                                )
                            }
                            
                        </PersonCardModalImage>   
                        <PersonCardModalInfo>
                            <h2 style={{display: "flex", flexDirection: "row", alignItems: 'center'}}>
                                {personDetails.fullName} 
                                
                                { 
                                    user && (
                                        (admin[profileChurch._id] || user.isOrganization) && (  
                                            <EditCard 
                                                onClick={handleEdit}
                                                >
                                                <EditIcon />
                                                <span>Edit</span>
                                            </EditCard>  
                                        )
                                    )
                                }
                            </h2>   
                            <h3>{personDetails.role}</h3>

                            <StyledBio
                                onClick={() => setShowMore(!showMore)} 
                                blurBottom={!showMore}
                                >
                                {   personDetails.bio.split('\n').map((item, i) => {
                                        return <section key={i}>{item}</section>;
                                    }) 
                                }
                            </StyledBio>
                            { showMore ? 
                                    (
                                        <ShowMore >
                                            <p onClick={() => setShowMore(!showMore)} >
                                                Show Less
                                            </p>
                                        </ShowMore>
                                    )
                                    : 
                                    (
                                        <ShowMore>
                                            <p onClick={() => setShowMore(!showMore)}>Show More</p>
                                        </ShowMore>
                                    )
                            }
                            <p> <PhoneIphoneIcon />  <span>{personDetails.phone }</span></p>
                            <p> <EmailIcon />  <span>{personDetails.email }</span></p> 
                            {
                                Object.keys(personDetails.links).map((key, index) => { 
                                     if(key !== "active" && personDetails.links[key]) {
                                        return <p> {linkIcons[key]} <span>{personDetails.links[key]}</span></p> 
                                     }   
                                })
                            }
                        </PersonCardModalInfo>                      
                    </PersonCardWrap> 
                </PersonCardModalWrap>
            </ModalContainerMain>
        )        
    }
}



const PersonCardModalWrap = styled.div`
  z-index: 2;
  width: 850px;
  min-height: 450px;
  height: 600px;
  background: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
  position: relative;
  overflow-y: scroll;
  animation: animate 0.3s;
  padding: 40px 30px 30px 30px;
  color: rgb(100,100,100);

  /* remove scrollbar */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
    &::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        background: transparent;
    }

  @media (max-width: 768px) {
        width: 97%;
    }
`



const PersonCardModalImage = styled.div`
    width: 50%;
    height: auto; 
    border-radius: 10px;
    img{
        width: 100%;
        height: auto;
        border-radius: 10px;
    }
    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 10px;
    }
`

const PersonCardModalInfo = styled.div`
    width: 50%;
    margin-left: 20px; 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll;
    height: 100%; 

    h3{
        font-weight: 400;
        color: #878787;
    }

    p{ 
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 10px;
        text-align: left;
        font-size: 13px;
    }
    span{
        width: 100%;
        margin-left: 10px;
    }
    @media (max-width: 768px) {
        width: 100%;
        margin-left: 0px;
    }

    `
const PersonCardWrap = styled.div`
    width: 100%;
    /* height: auto; */
    height: 100%;
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`

const EditCard = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px 12px;
    color: #595959;
    border-radius: 5px;
    cursor: pointer;   
    background-color: #dadada;
    span{
        font-size: 12px;
        margin-left: 5px;
        padding-right: 5px;
    }
    
    &:hover{
        background-color: #e8e8e8;
    }
`