import React, { useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getChurchFeedback, setViewFeedbackItemModal } from 'features/profileChurch/profileChurchSlice'
import { ConversationFeed, ConvItemContainer } from 'components/messenger/zStyling.styled'
import TimeAgo from 'timeago-react';
import styled from 'styled-components'
import FeedbackItem from './FeedbackItem';
import Loader from 'loaders/Loader';
import { Modal } from '@mui/material'
import FeedbackItemModal from './FeedbackItemModal';


export default function ChurchFeedbacksFeed() {

    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { 
        profileChurch, 
        churchFeedbackLoading, 
        churchFeedbackSuccess,
        churchFeedbackMessage,
        churchFeedbacks,
        feedbackItemData,
        viewFeedbackItem
    } = useSelector((state) => state.profileChurch)

    useEffect(() => {
        if(churchFeedbacks.length === 0) {
            dispatch(getChurchFeedback())
        }
    }, [])

 

    return (
        <ConversationFeed>

            {
                churchFeedbackLoading?
                (
                    <Loader />
                )
                : churchFeedbackSuccess &&
                (  
                    churchFeedbacks.map((item) => (
                        <FeedbackItem 
                            key={item._id}
                            data={item}
                            />
                    ))
                ) 
            }

            <Modal
                open={viewFeedbackItem}
                onClose={() => dispatch(setViewFeedbackItemModal({view: false, data: {}}))}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    >
                    <FeedbackItemModal />
                </div>
            </Modal>
        </ConversationFeed>
    )
}

