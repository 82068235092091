import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux' 
import SpinnerSmall from '../SpinnerSmall';
import { TopHeaderSettings, CenterSettingsContainer } from './settings.styled';
import { SettingsInputContainer, SettingsSubmitButton, SettingsBody } from './settings.styled';
import { updateFullName } from 'features/settings/settingSlice';
import { toast } from 'react-toastify';
import { updateUser } from 'features/auth/authSlice';

function UpdateDisplayName(props) {

  const dispatch = useDispatch() 
  const [fullName, setFullName] = useState('')
  const { user } = useSelector((state) => state.auth)
  const { isUpdateFullNameLoading, isUpdateFullNameSuccess, newFullName } = useSelector((state) => state.settings)

  useEffect(() => {

    setFullName(user.fullName)

  }, [])


  useEffect(() => {
      if(isUpdateFullNameSuccess && newFullName){
        toast.success('Display name has been updated')
        setFullName(newFullName)
        dispatch(updateUser({
          type: 'fullName',
          value: newFullName
        }))
      }
  
  }, [isUpdateFullNameSuccess, newFullName])


  const handleSubmit = () => {

    if(!fullName){
      return toast.error('Please enter a name')
    }

    if(fullName === user.fullName){
      return toast.error('No changes made')
    }

    if(fullName.length < 3){
      return toast.error('Name must be at least 3 characters long')
    }

    dispatch(updateFullName({
      fullName: fullName
    }))

  }

  return (
    <CenterSettingsContainer >
      <TopHeaderSettings> Update Display Name </TopHeaderSettings>
      
      <SettingsBody>
        <SettingsInputContainer> 
          <input 
            value={fullName} 
            onChange={(e) => setFullName(e.target.value)}
            name='email' 
            placeholder={ fullName } 
            disabled={isUpdateFullNameLoading}
          />
          <SettingsSubmitButton
            disabled={isUpdateFullNameLoading}
            onClick={handleSubmit}
            >
            {
              isUpdateFullNameLoading ? <SpinnerSmall /> : 'Update'
            }
          </SettingsSubmitButton>
        </SettingsInputContainer>

      </SettingsBody>

    </CenterSettingsContainer>
  )
}

export default UpdateDisplayName
