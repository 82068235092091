import './css/horizontalLoader.css'

function HorizontalLoader() {
  return (

<div class="horizontalLoader"><div></div><div></div><div></div><div></div></div>

)
}

export default HorizontalLoader