// My members is for churches to see who their members are and remove if necessary later we will be able to message them from here too
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Loader from "loaders/Loader";
import MemberOfItem from './MemberOfItem';
import { getMemberOf } from '../../../features/memberships/membershipSlice'
import './members.css'
import styled from 'styled-components'
import ConfirmModal from 'components/modals/ConfirmModal'
import { setViewConfirmModal } from 'features/modal/modalSlice';
import {  setViewChurchSearchModal } from 'features/memberships/membershipSlice'
import SearchIcon from '@mui/icons-material/Search';
import Modal from '@mui/material/Modal'; 

function MemberOf(props) {

  const dispatch = useDispatch()
  const {  memberOf, hasLeftChurchSuccess,getMemberOfLoading, getMemberOfSuccess} = useSelector((state) => state.memberships2)
  const { viewConfirmModal, confirmModalData } = useSelector((state) => state.modal)

  useEffect(()=> {

    console.log('getMemberOf in member of')

    if(!getMemberOfLoading && !getMemberOfSuccess){
      dispatch( getMemberOf() )
    }
  }, [])

  useEffect(() => {
    if(hasLeftChurchSuccess){
      dispatch( setViewConfirmModal(false) )
    }
  }, [dispatch, hasLeftChurchSuccess])

 

  return (
    <MyMembersContainer>
      <MyMembersHeading> My Membership </MyMembersHeading>

      <MemberItemContainer> 
      {
        getMemberOfLoading ? 
        (
          <Loader />
        )
        : getMemberOfSuccess ?
        (
          memberOf.length > 0 ? 
          (
            memberOf.map((ele) => (
              <MemberOfItem 
                  key={ ele._id } 
                  userId={ele._id} 
                  fullName={ele.fullName} 
                  username={ele.username} 
                  image={ele.profileImg.imagePath2}  
                />
            ))
          ):
          (
            <NoMembership> 
              You don't have a church membership 

              <FindChurchBtn
                onClick={() => dispatch(setViewChurchSearchModal())}
                >
                  <SearchIcon />
                  <span>Find your church</span>
              </FindChurchBtn>
            </NoMembership>
          )
        )
        :
        (
          <div> Something went wrong </div>
        )
      }
      </MemberItemContainer> 

      <Modal
        open={viewConfirmModal}
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        onClose={() => dispatch(setViewConfirmModal(false))}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <ConfirmModal 
          text={confirmModalData.text}  
          confirmBtnBackgroundHover={confirmModalData.confirmBtnBackgroundHover} 
          handler={confirmModalData.handler}
          />
      </Modal>
        
    </MyMembersContainer>
  )
}

export default MemberOf

const MyMembersContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

`

const MemberItemContainer = styled.div`
  padding: 10px;
  color: var(--lightThemeFontColor);
`

const MyMembersHeading = styled.div`
  border-bottom: 1px solid var(--borderColor);
  padding: 20px;
  margin-bottom: 20px;
  color: var(--lightThemeFontColor);
`

const NoMembership = styled.div`


  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--lightThemeFontColor);
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 20px;
`

const FindChurchBtn = styled.div`
  margin-top: 20px;
  padding: 10px;
  width: fit-content;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  background-color: #37bdc2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg{
    margin-right: 10px;
  }
  `