import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import mapService from './mapService'

const errorMessage = (error) => {
    return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
  }

const initialState = {
    
    location: {},

    locationCoordinates: null,
    searchTerm: null,

    getCoordinatesLoading: false,
    getCoordinatesSuccess: false,
    getCoordinatesMessage: '',

}

export const getCoordinates = createAsyncThunk(
    'map/getCoordinates',
    async (data, { rejectWithValue }) => {
        try {
            const response = await mapService.getCoordinates(data)
            return response
        } catch (error) {
            return rejectWithValue(errorMessage(error))
        }
    }
)


const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        resetMap: (state) => initialState,
        setLocationCoordinates: (state, action) => { 
            state.locationCoordinates = [null, null]

            state.locationCoordinates[0] = action.payload[0]
            state.locationCoordinates[1] = action.payload[1]


        },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getCoordinates.pending, (state) => {
          state.getCoordinatesLoading = true
        })
        .addCase(getCoordinates.fulfilled, (state, action) => {
          state.getCoordinatesLoading = false
          state.getCoordinatesSuccess = true
          state.location = action.payload
          state.searchTerm = action.payload.searchTerm

          if(action.payload.data.status === "OK"){
            state.locationCoordinates = [
              action.payload.data.results[0].geometry.location.lat,
              action.payload.data.results[0].geometry.location.lng
            ]
          }
        })
        .addCase(getCoordinates.rejected, (state, action) => {
          state.getCoordinatesLoading = false
          state.getCoordinatesSuccess = false
          state.getCoordinatesMessage = action.payload
        })
        //------------------
    },
})

export const { resetMap, setLocationCoordinates } = mapSlice.actions
  export default mapSlice.reducer