import { useParams } from 'react-router-dom'
import { useEffect}  from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getPost } from '../features/posts/singlePostSlice'
import { getComments } from '../features/comments/singlePostCommentSlice'
import { toast } from 'react-toastify'
import '../css/singlePost.css'
import SinglePostTop from '../components/singlePost/SinglePostTop'
import PostActions from '../components/singlePost/PostActions'
import SinglePostCommentForm from '../components/singlePost/SinglePostCommentForm'
import SinglePostComments from '../components/singlePost/SinglePostComments'
import { MdArrowBackIosNew } from 'react-icons/md'
import { singlePostCommentReset } from '../features/comments/singlePostCommentSlice'
import ReportPostModal2 from '../components/modals/ReportPostModal2'
import { reportIssueReset } from '../features/reportIssue/reportIssueSlice'
import Loader from 'loaders/Loader'
import Modal from '@mui/material/Modal'
import WhoIsPrayingModal from 'components/posts/WhoIsPrayingModal'
import { setViewWhoIsPrayingModal } from 'features/posts/postSlice'
import { language } from 'app/language'

function ShowPost() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { viewWhoIsPrayingModal } = useSelector((state) => state.posts)
    const { user } = useSelector((state) => state.auth)
    const { singlePost, isPostSuccess, isPostLoading, isPostError, postMessage } = useSelector((state) => state.singlePost)
    const { isSPCommentError, sPCommentMessage } = useSelector((state) => state.sPComments)
    let { postId } = useParams();
    const { viewModal } = useSelector((state) => state.modal) 

    useEffect(() => {
        if (isPostError || isSPCommentError ) { 
            console.log(`postMessage: ${postMessage} and ${sPCommentMessage} `); 
            toast.error('Error occurred, try again later') 
        }
        if (!user) {  navigate('/login') }
        dispatch(getPost({ postId: postId }))
        dispatch(getComments({postId: postId}) )
        
    }, [dispatch, isPostError, isSPCommentError, navigate, postId, postMessage, sPCommentMessage, user])


    const handleClick = () => {
        navigate(-1)
    }


    useEffect( () => () => {
        dispatch(singlePostCommentReset())
        dispatch(reportIssueReset())
      }, [] );

    return (
        <>
            {
                isPostLoading ?
                (
                    <p><Loader/></p>
                )
                : (isPostSuccess && singlePost) && 
                (
                    <div className='singlePostContainer'>
                        <div className='singlePostPostedToWrap'>
                            <div className='singlePostGoBack' onClick={handleClick}
                             style={{marginBottom: '10px'}} > 
                                <MdArrowBackIosNew style={{marginRight: '10px'}} /> 
                                {language.goBack[user.preferredLanguage]}
                            </div>
                        </div>
                            
                        <div className='singlePostItem'>
                                <SinglePostTop />
                                <PostActions />
                                <SinglePostCommentForm />
                                <SinglePostComments postId={postId} />
                        </div>

                        <Modal
                            open={viewWhoIsPrayingModal}
                            onClose={() => dispatch(setViewWhoIsPrayingModal(false))}
                            aria-labelledby="modal-modal-title"
                            >
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center', 
                                }}
                                >
                                <WhoIsPrayingModal />            
                            </div>

                        </Modal>

                    </div>
                )
            }

            {
                viewModal === true && (
                <ReportPostModal2  />
                )
            }

        </>  
    )
}

export default ShowPost


