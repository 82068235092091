import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  hasError: false,
}

export const setError = createAsyncThunk(
    'error/setError',
    function(){
        return true
      }
  )


export const hasErrorSlice = createSlice({
    name: 'hasError',
    initialState,
    reducers: {
      errorReset: (state) => initialState,
    },

    extraReducers: (builder) => {
        builder
          .addCase(setError.fulfilled, (state, action) => {
            state.hasError = true
          })
        },
    })

    export const { errorReset } = hasErrorSlice.actions
    export default hasErrorSlice.reducer