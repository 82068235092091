import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import givingService from './givingService' 
import { reset } from 'features/organization/organizationSlice'

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

const initialState = {

    paymentCards: [],
    bankAccounts: [],
    bankAcountsFConnections: [],
    selectedPaymentMethod: '',
    usBanks: [],

    checkIsMemberAStripeCustomerLoading: false,
    checkIsMemberAStripeCustomerError: false,
    checkIsMemberAStripeCustomerSuccess: false,

    connectAccount: {},    
 
    churchConnectAccountCheckLoading: false,
    churchConnectAccountCheckError: false,
    churchConnectAccountCheckSuccess: false,
    churchConnectAccountCheckMessage: '',   
   
    churchConnectAccountStatusLoading: false,
    churchConnectAccountStatusError: false,
    churchConnectAccountStatusSuccess: false,
    churchConnectAccountStatusMessage: '',


    getConnectOnboardingLinkLoading: false,
    getConnectOnboardingLinkError: false,
    getConnectOnboardingLinkSuccess: false,
    getConnectOnboardingLinkMessage: '',
    onboardingLink: '',

    addBankForChurchLoading: false,
    addBankForChurchError: false,
    addBankForChurchSuccess: false,
    addBankForChurchMessage: '',
    churchBanks: [],

    transactions : [],
    getTransactionsLoading: false,
    getTransactionsError: false,
    getTransactionsSuccess: false,

    payouts: [],
    payoutSchedule: '',
    getPayoutsLoading: false,
    getPayoutsError: false,
    getPayoutsSuccess: false,

    getChurchBanksLoading: false,
    getChurchBanksError: false,
    getChurchBanksSuccess: false,


    getPaymentMethodsLoading: false,
    getPaymentMethodsError: false,
    getPaymentMethodsSuccess: false,

    giveToChurchLoading: false,
    giveToChurchError: false,
    giveToChurchSuccess: false,

    startCreatingAccount: false,
    createAccountLoading: false,
    createAccountError: false,
    createAccountSuccess: false,

    createProductLoading: false,
    createProductError: false,
    createProductSuccess: false,

    addPaymentMethodLoading: false,
    addPaymentMethodError: false,
    addPaymentMethodSuccess: false,

    createPaymentIntentLoading: false,
    createPaymentIntentError: false,
    createPaymentIntentSuccess: false,

    paymentGivingIntentData: {},
    createPaymentGivingIntentLoading: false,
    createPaymentGivingIntentError: false,
    createPaymentGivingIntentSuccess: false,

    amount: '',
    description: '',

    view: 'give',
    viewAddCardModal: false,
    clientSecret: '',

    viewAddPaymentMethodModal: '',

    giveSection: 'initialize',


    piLoading: false,
    piError: false,
    piSuccess: false,

    viewGiveModal: false,
    selectedChurchToGiveTo: {
        id: '',
        fullName: '',
        image: ''
    },
    
    isCustomer: false,

    removePaymentMethodLoading: false,
    removePaymentMethodError: false,
    removePaymentMethodSuccess: false,

    sessionData: {},
    sessionUrl: '',
    financialConnectionSessionLoading: false,
    financialConnectionSessionSuccess: false,
    financialConnectionSessionError: false, 
    financialConnectionSessionClientSecret: '',


    createFinancialConnectionPaymentIntentLoading: false,
    createFinancialConnectionPaymentIntentError: false,
    createFinancialConnectionPaymentIntentSuccess: false,
    createFinancialConnectionPaymentIntentClientSecret: '',

    addFinancialConnectionAsPaymentMethodLoading: false,
    addFinancialConnectionAsPaymentMethodError: false,
    addFinancialConnectionAsPaymentMethodSuccess: false,

    requirementsForm: {},
 
    refreshConnectAccountDataLoading: false,
    refreshConnectAccountDataError: false,
    refreshConnectAccountDataSuccess: false,

    viewAddConnectBankModal: false,
    addConnectBankLoading: false,
    addConnectBankError: false,
    addConnectBankSuccess: false,
    connectBanks: [],

    viewCreatePayoutModal: false,
    createPayoutLoading: false,
    createPayoutError: false,
    createPayoutSuccess: false,
    createPayoutMessage: '',

    connectAccountBalance: {
        available: 0,
        pending: 0
    },
    destInfo: {},
    getConnectAccountBalanceLoading: false,
    getConnectAccountBalanceError: false,
    getConnectAccountBalanceSuccess: false,

}

export const refreshConnectAccountData = createAsyncThunk( 
    'giving/refreshAccountData',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.refreshConnectAccountData(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const checkIsMemberAStripeCustomer = createAsyncThunk(
    'giving/checkIsMemberAStripeCustomer',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.checkIsMemberAStripeCustomer(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const giveToChurch = createAsyncThunk(
    'giving/giveToChurch',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.giveToChurch(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const giveToChurchWithNewPaymentMethod = createAsyncThunk(
    'giving/giveToChurchWithNewPaymentMethod',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.giveToChurchWithNewPaymentMethod(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const createAccount = createAsyncThunk(
    'giving/createAccount',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.createAccount(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getPaymentMethods = createAsyncThunk(
    'giving/getPaymentMethods',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.getPaymentMethods(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }   
)

export const addCardForChurchMember = createAsyncThunk(
    'giving/addCardForChurchMember',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.addCardForChurchMember(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const addBank = createAsyncThunk(
    'giving/addBank',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.addBank(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const setGivingPaymentIntent = createAsyncThunk(
    'giving/setGivingPaymentIntent',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.setGivingPaymentIntent(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const createProduct = createAsyncThunk(
    'giving/createProduct',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.createProduct(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getMemberPaymentMethods = createAsyncThunk(
    'giving/getMemberPaymentMethods',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.getMemberPaymentMethods(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)


export const getTransactions = createAsyncThunk(
    'giving/getTransactions',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.getTransactions(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const createPaymentIntent = createAsyncThunk(
    'giving/createPaymentIntent',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.createPaymentIntent(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const removeMemberCardPaymentMethod = createAsyncThunk(  
    'giving/removeMemberCardPaymentMethod',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.removeMemberCardPaymentMethod(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const createPaymentGivingIntent = createAsyncThunk(
    'giving/createPaymentGivingIntent',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.createPaymentGivingIntent(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const churchConnectAccountCheck = createAsyncThunk(
    'giving/churchConnectAccountCheck',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.churchConnectAccountCheck(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const checkConnectAccountStatus = createAsyncThunk(
    'giving/checkConnectAccountStatus',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.checkConnectAccountStatus(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const addBankForChurch = createAsyncThunk(
    'giving/addBankForChurch',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.addBankForChurch(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)


export const getConnectOnboardingLink = createAsyncThunk(
    'giving/getConnectOnboardingLink',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.getConnectOnboardingLink(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getChurchTransactions = createAsyncThunk(
    'giving/getChurchTransactions',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.getChurchTransactions(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getChurchPayouts = createAsyncThunk(
    'giving/getChurchPayouts',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.getChurchPayouts(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getChurchBanks = createAsyncThunk(
    'giving/getChurchBanks',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.getChurchBanks(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const startFinancialConnection = createAsyncThunk(
    'giving/startFinancialConnection',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.startFinancialConnection(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const createFinancialConnectionPaymentIntent = createAsyncThunk(
    'giving/createFinancialConnectionPaymentIntent',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.createFinancialConnectionPaymentIntent(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const removeMemberPaymentMethod = createAsyncThunk(
    'giving/removeMemberPaymentMethod',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.removeMemberPaymentMethod(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const addFinancialConnectionAsPaymentMethod = createAsyncThunk(
    'giving/addFinancialConnectionAsPaymentMethod',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.addFinancialConnectionAsPaymentMethod(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)


export const addConnectBank = createAsyncThunk(
    'giving/addConnectBank',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.addConnectBank(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const createPayout = createAsyncThunk(
    'giving/createPayout',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.createPayout(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getConnectAccountBalance = createAsyncThunk(
    'giving/getConnectAccountBalance',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingService.getConnectAccountBalance(data, token)

        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)



export const givingSlice = createSlice({
    name: 'giving',
    initialState,
    reducers: { 
        setView: (state, action) => {
            state.view = action.payload
        },
        setViewAddCardModal: (state, action) => {
            state.viewAddCardModal = action.payload
        },
        setViewGiveModal: (state, action) => {
            state.viewGiveModal = action.payload
        },
        setSelectedChurchToGiveTo: (state, action) => {
            state.selectedChurchToGiveTo.fullName = action.payload.fullName
            state.selectedChurchToGiveTo.id = action.payload.id
            state.selectedChurchToGiveTo.image = action.payload.image
        },
        setSelectedPaymentMethod : (state, action) => {
            state.selectedPaymentMethod = action.payload
        },
        resetGiveToChurch: (state) => {
            state.giveToChurchLoading = false
            state.giveToChurchSuccess = false
            state.giveToChurchError = false
        },
        cancelProcess: (state) => {
            state.createPaymentGivingIntentError = false
            state.createPaymentGivingIntentLoading = false
            state.createPaymentGivingIntentSuccess = false
            state.paymentGivingIntentData = {}
            state.amount = ''
            state.description = ''
            state.clientSecret = ''
            state.selectedPaymentMethod = ''
            state.giveToChurchError = false
            state.giveToChurchLoading = false
            state.giveToChurchSuccess = false
        },
        resetIntent: (state) => {
            state.createPaymentIntentError = false
            state.createPaymentIntentLoading = false
            state.createPaymentIntentSuccess = false 
            state.clientSecret = ''
        },
        setstartCreatingAccount: (state, action) => {
            state.startCreatingAccount = action.payload
        },
        setViewAddPaymentMethodModal: (state, action) => {
            state.viewAddPaymentMethodModal = action.payload
        },
        addFConBankToList: (state, action) => {
            state.bankAcountsFConnections.push(action.payload)
        },
        resetFinancialConnection: (state) => {  
            state.createFinancialConnectionPaymentIntentLoading = false
            state.createFinancialConnectionPaymentIntentError = false
            state.createFinancialConnectionPaymentIntentSuccess = false
            state.createFinancialConnectionPaymentIntentClientSecret = ''
            state.financialConnectionSessionLoading = false
            state.financialConnectionSessionSuccess = false
            state.financialConnectionSessionError = false
            state.financialConnectionSessionClientSecret = ''
            state.sessionData = {}
        },

        setRequirementsForm: (state, action) => {
            state.requirementsForm = {
                ...state.requirementsForm,
                [action.payload.id]: action.payload.value
            }
        },

        resetOnboardingLink: (state) => {
            state.getConnectOnboardingLinkLoading = false
            state.getConnectOnboardingLinkSuccess = false
            state.getConnectOnboardingLinkError = false
            state.onboardingLink = ''
        },
        setViewAddConnectBankModal: (state, action) => {
            state.viewAddConnectBankModal = action.payload
        },
        setViewCreatePayoutModal: (state, action) => {
            state.viewCreatePayoutModal = action.payload
        },
        resetCreatePayout: (state) => {
            state.createPayoutLoading = false
            state.createPayoutError = false
            state.createPayoutSuccess = false
            state.createPayoutMessage = ''
        },
    },
    extraReducers: (builder) => { 
        builder
            .addCase(giveToChurch.pending, (state, action) => {
                state.giveToChurchLoading = true
            })
            .addCase(giveToChurch.fulfilled, (state, action) => {
                state.giveToChurchLoading = false
                state.giveToChurchSuccess = true
            })
            .addCase(giveToChurch.rejected, (state, action) => {
                state.giveToChurchError = action.payload
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(createAccount.pending, (state, action) => {
                state.createAccountLoading = true
            })
            .addCase(createAccount.fulfilled, (state, action) => {
                state.createAccountLoading = false
                state.createAccountSuccess = true
            })
            .addCase(createAccount.rejected, (state, action) => {
                state.createAccountLoading = false
                state.createAccountError = action.payload
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(addCardForChurchMember.pending, (state, action) => {
                state.addPaymentMethodLoading = true
            })
            .addCase(addCardForChurchMember.fulfilled, (state, action) => {
                state.addPaymentMethodLoading = false
                state.addPaymentMethodSuccess = true
            })
            .addCase(addCardForChurchMember.rejected, (state, action) => {
                state.addPaymentMethodError = action.payload
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(addBank.pending, (state, action) => {
                state.addPaymentMethodLoading = true
            })
            .addCase(addBank.fulfilled, (state, action) => {
                state.addPaymentMethodLoading = false
                state.addPaymentMethodSuccess = true
                state.bankAccounts.push(action.payload.bankAccount)
               
            })
            .addCase(addBank.rejected, (state, action) => {
                state.addPaymentMethodError = action.payload
            })

            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(getPaymentMethods.pending, (state, action) => {
                state.getPaymentMethodsLoading = true
            })
            .addCase(getPaymentMethods.fulfilled, (state, action) => {
                state.getPaymentMethodsLoading = false
                state.getPaymentMethodsSuccess = true
                state.paymentCards = action.payload.paymentCards
            })
            .addCase(getPaymentMethods.rejected, (state, action) => {
                state.getPaymentMethodsError = action.payload
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(setGivingPaymentIntent.pending, (state, action) => {
                state.piLoading = true
            })
            .addCase(setGivingPaymentIntent.fulfilled, (state, action) => {
                state.piLoading = false
                state.piSuccess = true
                state.clientSecret = action.payload.setupIntent.client_secret
            })
            .addCase(setGivingPaymentIntent.rejected, (state, action) => {
                state.piError = action.payload
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(createProduct.pending, (state, action) => {
                state.createProductLoading = true
            })
            .addCase(createProduct.fulfilled, (state, action) => {
                state.createProductLoading = false
                state.createProductSuccess = true
            })
            .addCase(createProduct.rejected, (state, action) => {
                state.createProductError = action.payload
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(checkIsMemberAStripeCustomer.pending, (state, action) => {
                state.checkIsMemberAStripeCustomerLoading = true
            })
            .addCase(checkIsMemberAStripeCustomer.fulfilled, (state, action) => {

                if(action.payload.message){
                    state.checkIsMemberAStripeCustomerError = 'Your church is not yet set up to receive donations. Please contact your church administrator.'
                }

                state.checkIsMemberAStripeCustomerLoading = false
                state.checkIsMemberAStripeCustomerSuccess = true
                if(action.payload.customer){

                    if(action.payload.customer.id){
                        state.isCustomer = true
                    } 
                }
            })
            .addCase(checkIsMemberAStripeCustomer.rejected, (state, action) => {
                state.checkIsMemberAStripeCustomerError = action.payload
                state.checkIsMemberAStripeCustomerLoading = false
                state.createCustomerError = action.payload
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(getMemberPaymentMethods.pending, (state, action) => {
                state.getPaymentMethodsLoading = true
            })
            .addCase(getMemberPaymentMethods.fulfilled, (state, action) => {
                state.getPaymentMethodsLoading = false
                state.getPaymentMethodsSuccess = true
                state.paymentCards = action.payload.cards
                state.usBanks = action.payload.banks 

            })
            .addCase(getMemberPaymentMethods.rejected, (state, action) => {
                state.getPaymentMethodsError = action.payload
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(getTransactions.pending, (state, action) => {
                state.getTransactionsLoading = true
            })
            .addCase(getTransactions.fulfilled, (state, action) => {
                state.getTransactionsLoading = false
                state.getTransactionsSuccess = true
                state.transactions = action.payload.transactions
            })
            .addCase(getTransactions.rejected, (state, action) => {
                state.getTransactionsError = action.payload
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(createPaymentGivingIntent.pending, (state, action) => {
                state.createPaymentGivingIntentLoading = true
            })
            .addCase(createPaymentGivingIntent.fulfilled, (state, action) => {
                state.createPaymentGivingIntentLoading = false
                state.createPaymentGivingIntentSuccess = true
                state.paymentGivingIntentData = action.payload.paymentIntentData
                state.clientSecret = action.payload.clientSecret   
                state.amount = action.payload.amount
                state.description = action.payload.description
            })
            .addCase(createPaymentGivingIntent.rejected, (state, action) => {
                state.createPaymentGivingIntentError = action.payload
                state.createPaymentGivingIntentLoading = false 
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(churchConnectAccountCheck.pending, (state, action) => {
                state.churchConnectAccountCheckLoading = true
            })
            .addCase(churchConnectAccountCheck.fulfilled, (state, action) => {
                state.churchConnectAccountCheckLoading = false
                state.churchConnectAccountCheckSuccess = true
                state.churchConnectAccount = action.payload
            })
            .addCase(churchConnectAccountCheck.rejected, (state, action) => {
                state.churchConnectAccountCheckError = true
                state.churchConnectAccountCheckLoading = false
                state.churchConnectAccountCheckMessage = action.payload
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(checkConnectAccountStatus.pending, (state, action) => {
                state.churchConnectAccountStatusLoading = true
            })
            .addCase(checkConnectAccountStatus.fulfilled, (state, action) => {
                state.churchConnectAccountStatusLoading = false
                state.churchConnectAccountStatusSuccess = true
                state.onboardingLink = action.payload.link
                state.connectAccount = action.payload.account
            })
            .addCase(checkConnectAccountStatus.rejected, (state, action) => {
                state.churchConnectAccountStatusError = true
                state.churchConnectAccountStatusLoading = false
                state.churchConnectAccountStatusMessage = action.payload
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(getConnectOnboardingLink.pending, (state, action) => {
                state.getConnectOnboardingLinkLoading = true
            })
            .addCase(getConnectOnboardingLink.fulfilled, (state, action) => {
                state.getConnectOnboardingLinkLoading = false
                state.getConnectOnboardingLinkSuccess = true
                state.onboardingLink = action.payload.accountLink.url
            })
            .addCase(getConnectOnboardingLink.rejected, (state, action) => {
                state.getConnectOnboardingLinkError = true
                state.getConnectOnboardingLinkLoading = false
                state.getConnectOnboardingLinkMessage = action.payload
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(addBankForChurch.pending, (state, action) => {
                state.addBankForChurchLoading = true
            })
            .addCase(addBankForChurch.fulfilled, (state, action) => {
                state.addBankForChurchLoading = false
                state.addBankForChurchSuccess = true
                state.churchBanks = action.payload.banks
            })
            .addCase(addBankForChurch.rejected, (state, action) => {
                state.addBankForChurchError = true
                state.addBankForChurchLoading = false
                state.addBankForChurchMessage = action.payload
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(getChurchTransactions.pending, (state, action) => {
                state.getTransactionsLoading = true
            })
            .addCase(getChurchTransactions.fulfilled, (state, action) => {
                state.getTransactionsLoading = false
                state.getTransactionsSuccess = true
                state.transactions = action.payload.transactions
            })
            .addCase(getChurchTransactions.rejected, (state, action) => {
                state.getTransactionsLoading = false
                
                state.getTransactionsError = true
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(getChurchPayouts.pending, (state, action) => {
                state.getPayoutsLoading = true
            })
            .addCase(getChurchPayouts.fulfilled, (state, action) => {

                state.payouts = action.payload.payouts
                state.payoutSchedule = action.payload.schedule
                state.connectAccountBalance.available = (action.payload.balance.available[0].amount/100).toFixed(2)
                state.connectAccountBalance.pending = (action.payload.balance.pending[0].amount/100).toFixed(2)
                state.destInfo = action.payload.destInfo
                state.getPayoutsLoading = false
                state.getPayoutsSuccess = true  
                          
            })
            .addCase(getChurchPayouts.rejected, (state, action) => {
                state.getPayoutsLoading = false
                state.getPayoutsError = true
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(getChurchBanks.pending, (state, action) => {
                state.getChurchBanksLoading = true
            })
            .addCase(getChurchBanks.fulfilled, (state, action) => {
                state.getChurchBanksLoading = false
                state.getChurchBanksSuccess = true
                state.churchBanks = action.payload.banks
            })
            .addCase(getChurchBanks.rejected, (state, action) => {
                state.getChurchBanksLoading = false
                state.getChurchBanksError = true
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(startFinancialConnection.pending, (state, action) => {
                state.financialConnectionSessionLoading = true
            })
            .addCase(startFinancialConnection.fulfilled, (state, action) => {
                state.financialConnectionSessionLoading = false
                state.financialConnectionSessionSuccess = true
                state.financialConnectionSessionClientSecret = action.payload.clientSecret
                // state.sessionUrl = action.payload.sessionUrl
                state.sessionData = action.payload.session

            })
            .addCase(startFinancialConnection.rejected, (state, action) => {
                state.financialConnectionSessionLoading = false
                state.financialConnectionSessionError = true
            }) 
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(createFinancialConnectionPaymentIntent.pending, (state, action) => {
                state.createFinancialConnectionPaymentIntentLoading = true
            })
            .addCase(createFinancialConnectionPaymentIntent.fulfilled, (state, action) => {
                state.createFinancialConnectionPaymentIntentLoading = false
                state.createFinancialConnectionPaymentIntentSuccess = true
                state.createFinancialConnectionPaymentIntentClientSecret = action.payload.clientSecret
            })
            .addCase(createFinancialConnectionPaymentIntent.rejected, (state, action) => {
                state.createFinancialConnectionPaymentIntentLoading = false
                state.createFinancialConnectionPaymentIntentError = true
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(removeMemberPaymentMethod.pending, (state, action) => {
                state.removePaymentMethodLoading = true
            })
            .addCase(removeMemberPaymentMethod.fulfilled, (state, action) => {
                state.removePaymentMethodLoading = false
                state.removePaymentMethodSuccess = true

                if(action.payload.type === 'card'){
                    state.paymentCards = state.paymentCards.filter(card => card.id !== action.id)
                } else if(action.payload.type === 'bank'){
                    state.bankAcountsFConnections = state.bankAcountsFConnections.filter(bank => bank.id !== action.payload.id)
                } 
            })
            .addCase(removeMemberPaymentMethod.rejected, (state, action) => {
                state.removePaymentMethodLoading = false
                state.removePaymentMethodError = true
            }) 
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

            .addCase(addFinancialConnectionAsPaymentMethod.pending, (state, action) => {
                state.addFinancialConnectionAsPaymentMethodLoading = true
            })
            .addCase(addFinancialConnectionAsPaymentMethod.fulfilled, (state, action) => {
                state.addFinancialConnectionAsPaymentMethodLoading = false
                state.addFinancialConnectionAsPaymentMethodSuccess = true

                state.usBanks.push(action.payload.paymentMethod)

            })
            .addCase(addFinancialConnectionAsPaymentMethod.rejected, (state, action) => {
                state.addFinancialConnectionAsPaymentMethodLoading = false
                state.addFinancialConnectionAsPaymentMethodError = true
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
 
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(refreshConnectAccountData.pending, (state, action) => {
                state.refreshConnectAccountDataLoading = true
            })
            .addCase(refreshConnectAccountData.fulfilled, (state, action) => {
                state.refreshConnectAccountDataLoading = false
                state.refreshConnectAccountDataSuccess = true
                state.connectAccount = action.payload.account
            })
            .addCase(refreshConnectAccountData.rejected, (state, action) => {
                state.refreshConnectAccountDataLoading = false
                state.refreshConnectAccountDataError = true
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(addConnectBank.pending, (state, action) => {
                state.addConnectBankLoading = true
            })
            .addCase(addConnectBank.fulfilled, (state, action) => {
                state.addConnectBankLoading = false
                state.addConnectBankSuccess = true
                // state.connectBanks.push(action.payload.bank)
            })
            .addCase(addConnectBank.rejected, (state, action) => {
                state.addConnectBankLoading = false
                state.addConnectBankError = true
            }) 
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(createPayout.pending, (state, action) => {
                state.createPayoutLoading = true
            })
            .addCase(createPayout.fulfilled, (state, action) => {

                if(action.payload.message){
                    
                    if(action.payload.message.includes('insufficient')){
                        state.createPayoutMessage = 'Requested amount exceeds available balance.'
                        state.createPayoutError = true
                        state.createPayoutLoading = false

                    } 
                }else{
                         
                    state.connectAccountBalance.available = (action.payload.balance.available[0].amount/100).toFixed(2)
                    state.connectAccountBalance.pending = (action.payload.balance.pending[0].amount/100).toFixed(2)
                    state.payouts.unshift(action.payload.payout)       
                    
                    state.createPayoutLoading = false
                    state.createPayoutSuccess = true        
                } 
            })
            .addCase(createPayout.rejected, (state, action) => {
                state.createPayoutLoading = false
                state.createPayoutError = true
                
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            // .addCase(getConnectAccountBalance.pending, (state, action) => {
            //     state.getConnectAccountBalanceLoading = true
            // })
            // .addCase(getConnectAccountBalance.fulfilled, (state, action) => {
            //     state.getConnectAccountBalanceLoading = false
            //     state.getConnectAccountBalanceSuccess = true
            //     state.connectAccountBalance = action.payload.balance
            // })
            // .addCase(getConnectAccountBalance.rejected, (state, action) => {
            //     state.getConnectAccountBalanceLoading = false
            //     state.getConnectAccountBalanceError = true
            // })

        }   
})

export const { 
    cancelProcess, resetIntent, resetGiveToChurch, setSelectedPaymentMethod, 
    setView, setViewAddCardModal, setViewGiveModal, setSelectedChurchToGiveTo,
    setstartCreatingAccount, setViewAddPaymentMethodModal, addFConBankToList, 
    resetFinancialConnection, setRequirementsForm, resetOnboardingLink, 
    setViewAddConnectBankModal, setViewCreatePayoutModal, resetCreatePayout
} = givingSlice.actions

export default givingSlice.reducer
