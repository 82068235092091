import React, { useEffect } from 'react'
import {useNavigate} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'  
import PublicNonMemberViewItems from './PublicNonMemberViewItems'
import { resetChurchProfile } from 'features/profileChurch/profileChurchSlice'

export default function NotLoggedIn() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
 
    const handleLogin = () => {
        navigate('/login') 
        sessionStorage.setItem('loginRedirect', `${profileChurch.username}`) 
    }

    const { profileChurch, isChurchProfileSuccess } = useSelector((state) => state.profileChurch)


    // `https://api.praysely.com/profile/${profileChurch.profileImg.imagePath2}` 



    // add unmount function
    useEffect(() => {
        return () => {
            dispatch(resetChurchProfile())
        }
    }, [])


    return (
        <NotLoggedInContainer>

            { isChurchProfileSuccess && (
                    <PublicNonMemberViewItems
                        profileImg={profileChurch.settings.profileImg ? profileChurch.profileImg.imagePath2 : null} 
                        location={profileChurch.settings.location ? profileChurch.location : null}
                        fullName={profileChurch.fullName}
                        description={profileChurch.settings.description ? profileChurch.description : null}
                        stats={profileChurch.settings.stats ? profileChurch.stats : null}
                        bulletin={profileChurch.settings.bulletin ? profileChurch.bulletin : null}
                        people={profileChurch.settings.people ? profileChurch.people : null}
                        />
                )    
            }



            <LoginView>
                <i>Must be logged in and a member of {profileChurch.fullName} to view more content</i>
                <SignInButton
                    onClick={handleLogin}
                    >Login</SignInButton>
            </LoginView>

        </NotLoggedInContainer>
    )
}

const NotLoggedInContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; 
    border-radius: 10px;
    padding: 40px; 
    // mobile view
    @media (max-width: 800px) {
        flex-direction: column;
        padding: 5px;
        margin-top: 0px;
        flex-direction: column-reverse;
    }
`


const LoginView = styled.div` 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%; 
    padding: 20px;

    i{
        color: rgb(109, 109, 109);
    }

    @media (max-width: 800px) {
        i{
            font-size: 13px;
        }
        margin-top: 10px;
    }
`

const SignInButton = styled.div`
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: rgb(161, 0, 0);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    &:hover{
        background-color: rgb(161, 0, 0, 0.8);
    }
    @media (max-width: 800px) {
        width: 80%;
    }


`
