import { create } from "zustand";
import produce from "immer";

const iState = {
    userType: "church",
    isOptionHovered: false,
}

export const landingPageStore = create((set) => ({ 
    ...iState,

    setUserType: (payload) => set(produce((state) => {
        state.userType = payload
    })),

    setIsOptionHovered: (payload) => set(produce((state) => {
        state.isOptionHovered = payload
    })), 
}));

