import './startConversation.css'
import { useState, useEffect } from 'react'
import { MdManageSearch } from 'react-icons/md'
import {  useSelector } from 'react-redux'
import SearchUsers from '../SearchUsers.jsx/SearchUsers'
import ViewAllMembers from './ViewAllMembers'
import ViewAllMemberOf from './ViewAllMemberOf'
import SearchResults from '../SearchResults'
import styled from 'styled-components'
import ViewAllMemberOF from './ViewAllMemberOf'
//members or members of has been fetched when a user clicks on "NewConversationBtn"
import ViewFellowChurchMembers from './ViewFellowChurchMembers'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom'


function StartConversation() {
  const navigate = useNavigate()
  const [type, setType] = useState()
  const [showAllMembers, setShowAllMembers] = useState(false)
  const { user } = useSelector(state => state.auth)
  const { searchUserResults , isSearchUsersSuccess, isSearchUsersLoading, searchUsersErrorMessage } = useSelector((state) => state.conversations)

  useEffect(() => {
    if(user && user.isOrganization){setType('members')} else if(user && !user.isOrganization){setType('groups')}
  }, [user])  

  const search = (value, array) => { //not yet implemented
    console.log(`Searching for: ${value} in ${array}`)
  }


// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  // design inspiration below

  // https://search.muz.li/ODFjMzhkNzM0


// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%


  return (
    <StartConversationContainer>
      <BackToMessages onClick={() => navigate('/messages')} > 
        <ArrowBackIcon /> <span>Back to conversations</span>
      </BackToMessages>


        {!user.isOrganization && !user.isChurch ?
          (
            <FellowMembersWrap>
              <ViewAllMemberOF />
              <ViewFellowChurchMembers />      
            </FellowMembersWrap>
          )
          :
          (
            <ViewAllMembers />
          )  
        }  
    </StartConversationContainer>
  )
}

const FellowMembersWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%; 
`

const StartConversationContainer = styled.div` 
    background-color: #eeeeee;
    display: flex; 
    width: 350px; 
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start; 
    height: calc(100vh - 55px);
    padding-left: 10px;
    @media (max-width: 768px) {
      width: 100%;
      margin-left: 0px; 
      padding: 10px;
    }
`

const BackToMessages = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #ccc;
    margin: 0px 0px 7px 0px;
    width: fit-content;
    border-radius: 8px;
    background-color: white;
    color: #818181;
    cursor: pointer;
    transition: .4s;
    &:hover{
      border: 1px solid green;
      background-color: green;
      color: white
    }
  }
`
const NewMsgHeading = styled.div`
  
`

const MyChurchWrap = styled.div`
  
`

export default StartConversation