import { useState, useEffect } from 'react';


//along with this hook, you need to pass in the useRef value

function useToggleDropdown(reactRef) {
    const [isDropOpen, setIsDropOpen] = useState(false)
   
    useEffect(() => {
        const checkIfClickedOutside = e => {
            if(isDropOpen && reactRef.current && !reactRef.current.contains(e.target))
                {
                  setIsDropOpen(false)
                }
        }
        document.addEventListener("click", checkIfClickedOutside)
    
        return () => { document.removeEventListener("click", checkIfClickedOutside)} // Cleanup the event listener
    
      }, [isDropOpen, reactRef])

   return [
     isDropOpen, 
     {
       set: setIsDropOpen,
       off: () => setIsDropOpen(false),
       toggle: () => setIsDropOpen(state => !state)
     }
   ];

}




export default useToggleDropdown;


//example usage
// function TestDD2() {
//   const ref1 = useRef(null)
  
//   const [isDropped, setIsDropped] = useToggleDropdown(ref1)

//   const handleClick = () => {setIsDropped.toggle(ref1)}


// return (
//   <div class='testDropContainer'>
//       <div ref={ref1} onClick={handleClick} className='testDropBtn'>Drop Down Button</div>
//       {
//         isDropped && (
//            <TestDDBox2  ref={ref1} />
//         )
//       }
//   </div>
// )
// }

// export default TestDD2