import { create } from "zustand";
import produce from "immer";


import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LanguageIcon from '@mui/icons-material/Language'; 
import { BsTwitterX } from "react-icons/bs";

const iState = {
    viewPersonCardModal: false, 
    personDetails: null,
    personEditDetails: null,
    isEditingCard: false,
    viewAddPersonModal: false,
    viewEditDescriptionModal: false,

    linkIcons :{
        facebook: <FacebookIcon style={{ color: '#3b5998'}} />,
        twitter: <BsTwitterX style={{ color: 'black'}} />,
        instagram: <InstagramIcon style={{ color: '#C13584'}} />,
        linkedin: <LinkedInIcon style={{ color: '#0077B5'}} />,
        youtube: <YouTubeIcon style={{ color: '#FF0000'}} />,
        website: <LanguageIcon style={{ color: '#000000'}} />,
    },

}

export const aboutChurchStore = create((set) => ({

    ...iState,

    setViewPersonCardModal: (payload) => set(produce((state) => {
        state.viewPersonCardModal = payload
    })),

    setViewAddPersonModal: (payload) => set(produce((state) => {
        state.viewAddPersonModal = payload
    })),

    setViewEditDescriptionModal: (payload) => set(produce((state) => {
        state.viewEditDescriptionModal = payload
    })),

    setPersonDetails: (payload) => set(produce((state) => {
        state.personDetails = payload
    })),

    setPersonEditDetails: (payload) => set(produce((state) => {
        state.personEditDetails = payload
    })),

    setIsEditingCard: (payload) => set(produce((state) => {
        state.isEditingCard = payload
        if(state.viewPersonCardModal){
            state.viewPersonCardModal = false
        }
        if(state.viewEditDescriptionModal){
            state.viewEditDescriptionModal = false
        }
        if(!state.viewAddPersonModal){
            state.viewAddPersonModal = true
        }
    })),
}));