 
import React from 'react'
import styled from 'styled-components'


export default function OurMission() {
  return (
    <OurMissionWrap>
    <OurMissionTitle>Our Mission</OurMissionTitle>
    <OurMissionText>
        <p>
        At Praysely, our mission is to create a vibrant and connected community of believers, fostering spiritual growth and unity through our innovative digital platform. 
        Through heartfelt posts about prayer and powerful testimonies, we aim to inspire and strengthen the faith of individuals, encouraging them to deepen their connection to God.
        </p>

    </OurMissionText>
    <OurMissionTitle>How are we doing this?</OurMissionTitle>
    <OurMissionText>
        <p>
        At Praysely, we are dedicated to leveraging technology to enhance the spiritual experience, making faith-building resources and connections accessible anytime, anywhere. 
        We are committed to nurturing a space where individuals can grow in their relationship with God and with each other, ultimately spreading love, compassion, 
        and the transformative power of prayer throughout the digital realm and beyond.
         
        By building a robust and user-friendly digital platform that seamlessly integrates your favorite features,
        Our development approach prioritizes creating an intuitive and engaging user experience while ensuring the security and privacy of our users' information. 
        <br />
        Here's how we are building Praysely:
        </p>

        <p>
        User-Centric Design: <br />
        Our platform's design is centered around the needs and preferences of our users. We are investing in creating a clean, modern, and responsive 
        interface that is easy to navigate across different devices, including smartphones, tablets, and desktops.
        </p>
        
        <p>
        Content Management: 
        <br />We are developing a content management system that allows administrators to effortlessly create, manage, and organize posts about prayer and testimonies. 
        This system enables us to curate inspiring content that resonates with our community's spiritual journey.
        </p>


        <p>
        Church Directory: <br />The church directory is being designed to help members find and connect with each other. We are implementing search and filtering options to enable users to 
        locate fellow members based on different criteria, fostering a stronger sense of community.
        </p>  

        <p>
        Events Calendar:<br /> Our events calendar is designed to be dynamic and informative. Users will be able to view upcoming events, register for attendance, and receive event reminders. 
        This feature helps maintain engagement by providing a clear overview of the church's schedule.
        </p>

        <p>
        Messaging System:<br /> The messaging system is being developed to facilitate direct communication between members. We are implementing real-time messaging with features 
        like group chats and private conversations, enabling users to share prayer requests, offer support, and build meaningful connections.
        </p>
        
        <p>
        Polls and Surveys:<br /> Our interactive polls feature empowers the community to participate in decision-making processes and share opinions on various matters. 
        This functionality enhances engagement and ensures that the platform serves the evolving needs of the users.
        </p>

        <p>
        Security and Privacy:<br /> We are employing robust security measures to protect user data and maintain the confidentiality of conversations. User authentication, 
        data encryption, and adherence to best practices in data privacy are at the core of our development process.
        </p>
        
        <p>
        Scalability:<br />As Praysely grows, we are building the platform to be scalable, accommodating an increasing number of users and content. This ensures that the platform 
        remains responsive and efficient even as the community expands.

        
        </p>

        <p>
        Feedback Integration:<br /> We are actively seeking input from users throughout the development process. By incorporating user feedback and suggestions, we aim to 
        continuously improve the platform and tailor it to the community's evolving needs.
        </p>

        <p>
        Testing and Optimization: <br />Rigorous testing procedures are being implemented to identify and rectify any bugs or issues. Regular updates and optimizations will 
        ensure that Praysely operates smoothly and offers the best possible experience to users.
        <br /><br />
        Overall, our approach to building Praysely involves a combination of user-centric design, advanced technology, security measures, and a commitment to fostering a 
        strong and connected community. We are dedicated to creating a platform that uplifts and empowers individuals on their spiritual journeys while promoting unity and engagement within the church community.
        </p>
        </OurMissionText>


</OurMissionWrap>
  )
}

const OurMissionWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    align-items: flex-start;
    margin-top: 40px;
    font-family: 'Roboto', sans-serif;
`
const OurMissionTitle = styled.h1`
    font-size: xx-large;
`


const OurMissionText = styled.div`
    font-size: larger;
    padding: 20px 450px 20px 20px;
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    p{
        margin-bottom: 20px;
        line-height: normal;
        width   : 80%;
    }

    @media (max-width: 1268px) {
        padding: 20px 20px 20px 20px;
        p{
            width: 100%;
        }
    }
    @media (max-width: 768px) {
        padding: 20px 20px 20px 20px;
    
        p{
            width: 100%;
        }
    }
`

const OMSubheading = styled.h2`
    
`