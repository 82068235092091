import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const API_URL = '/api/organization/'

//for sending the token
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }

// Create new post
const getOrganizationData = async (organizationData, token) => {

  const response = await axios.post(URL+API_URL, organizationData, config(token))

  return response.data
}

const organizationService = {
    getOrganizationData,
  }
  
  export default organizationService