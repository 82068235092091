import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, logoutUser, resetUser } from '../features/auth/authSlice' 
import styled from 'styled-components'
import logo from '../prayselyLogo.png'
import HeaderProfile from './header/HeaderProfile' 
import { closeMemberModal } from 'features/memberships/membershipSlice'
import useGetWindowSize from "../hooks/useGetWindowSize"
import { lazy } from 'react'
// lazy load MobileMenu


import MembershipModal from 'components/memberships/MembershipModal';
import SearchForChurchModal from './memberships/SearchForChurchModal'
import { Modal } from '@mui/material'
const MobileMenu = lazy(() => import('./header/mobile/MobileMenu'));

function Header({largerNav = false}) {



  const dispatch = useDispatch()
  const { user, isRegisterSuccessUser, isRegisterSuccessChurch } = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const [width, height] = useGetWindowSize()
  const { viewMemberModal, viewChurchSearchModal } = useSelector((state) => state.memberships2)


  const handleLogoClick = () => {
    if(user){

      if(user.isOrganization || user.isChurch){
        navigate(`/${user.name}`) //churches just have 1 myProfile view
      }else{
        navigate('/home')
      }
      
    }else{
      navigate(`/`)
    }
  }

  const handleLogout = () => {
    navigate('/login')
    dispatch(logout())
    dispatch(resetUser())
    window.location.reload(false);
    
  }

  return (
    <StyledHeader 
      largerNav={largerNav}
      className='header'>
      {user ? (      
              <div className='headerLeft'>
                
                    <div onClick={handleLogoClick} className='prayselyLogo'>
                      <img src={logo} alt="Logo" className='prayselyLogoImage' />
                    </div>

                    {/* <HeaderSearch />  we can use this to have users find their church*/}


              </div>

              ) : (
                <div className='prayselyLogo'>
                  <Link to='/'><img src={logo} alt="Logo" className='prayselyLogoImage' /> </Link>
                </div>
              )
      }

      {
        width < 760 ?
        (
          // display mobile nav
          <MobileMenu />
        )
        :
        (
          <ul
            style={{zIndex: '50'}}
          >
          {user ? (
                <div className="headerRight">
                    {/* <HeaderNotif name={user.name}/> */}
                    <HeaderProfile name={user.name} />
                    {/* <Link to='/'><button className='usrBtn' onClick={handleLogout}><FaSignOutAlt /> Logout</button></Link> */}
                </div>
  
          ) : (!isRegisterSuccessChurch && !isRegisterSuccessUser) && (
            <>
              <li>
                <Link to='/login'><button className='usrBtn' >Login</button></Link>
              </li>
              <li>
                <Link to='/register'><button className='usrBtn'>Register</button> </Link>
              </li>
            </>
          )}
        </ul>
        )
      }      
        {
            viewMemberModal && (
              <Modal
                open={viewMemberModal}
                onClose={() => dispatch(closeMemberModal())}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div
                  style={{
                    position: 'absolute', 
                    width: '100%',
                    height: '100%',  
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  >

                < MembershipModal /> 
                </div>
              </Modal>
            )
        }
        {
            viewChurchSearchModal && (
                < SearchForChurchModal /> 
            )
        }

    </StyledHeader>
  )
}

const StyledHeader = styled.header`

  padding: ${props => props.largerNav ? '35px' : '20px 25px'};
`

export default Header

//if url is public make the default search parameter to be public