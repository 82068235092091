import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import SettingsIcon from '@mui/icons-material/Settings';
import MessageIcon from '@mui/icons-material/Message';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { imgUrl } from 'features/constants';
import styled from 'styled-components';
import { getNotifs } from 'features/notifs/notifSlice';
import { ExtrasList, ExtrasContainer, ExtrasIcon, ExtrasItem } from './extras.styled'
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useParams } from 'react-router-dom';
import { getProfileChurchData } from 'features/profileChurch/profileChurchSlice';
import CreatePostBtn from './CreatePostBtn';
import { language } from 'app/language';

function ChurchExtras() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { lastNotifViewedIndex, isNotifLoading, newMessages } = useSelector((state) => state.notifs)
    
    const { churchName } = useParams()

    let profileImageToShow;
    try{ profileImageToShow =  `${imgUrl}/profile/${user.imagePath}` }
    catch(e){  profileImageToShow =  require(`../../profileImage/generic.png`)  }

    const handleNav = () => {

        if(churchName){
            if(churchName  !== user.name){
                dispatch(getProfileChurchData({
                    profileName: user.name,
                }))
            }
        }

        navigate(`/${user.name}`)
    }
    

    useEffect(() => {
        if(!isNotifLoading){
            dispatch(getNotifs())
        }
    }, [])

    useEffect(() => { }, [user.name, user.fullName])

  return (
    <ExtrasContainer> 
        <ProfileImageSidePane onClick={handleNav}>
            <PITop>
                <PCImage src={profileImageToShow} alt='u'/>
            </PITop>

            <PIBottom >
                <PITopName>
                    { //if the user is a church, show the church name
                        user.isOrganization ? (
                            `${user.fullName}`
                        )
                        :
                        (
                        `${profileChurch.fullName}`
                        )
                    }     
                </PITopName> 
            </PIBottom>
        </ProfileImageSidePane>


        <ExtrasList
            onClick={() => navigate(`/messages`)}
            >
            <ExtrasIcon>
                <MessageIcon/>
                {
                    newMessages.length > 0 && (
                        <NotifNumber>{newMessages.length}</NotifNumber>
                    )
                } 
            </ExtrasIcon>
            <ExtrasItem>{language.messages[user.preferredLanguage]}</ExtrasItem>
        </ExtrasList>
        <ExtrasList
            onClick={() => navigate(`/settings?section=memberships`)}
            >
            <ExtrasIcon><SettingsIcon/></ExtrasIcon> 
            <ExtrasItem>
                {language.settings[user.preferredLanguage]}
            </ExtrasItem> 
        </ExtrasList> 
  
        <ExtrasList
            onClick={() => navigate(`/notifications`)}
            >
            <ExtrasIcon>
                <NotificationsIcon/>
                    {
                        lastNotifViewedIndex > 0 && (
                            <NotifNumber>{lastNotifViewedIndex}</NotifNumber>
                        )
                    } 
            </ExtrasIcon>
            <ExtrasItem>
                {language.notifications[user.preferredLanguage]}
            </ExtrasItem> 
        </ExtrasList> 

        <CreatePostBtn /> 
        
    </ExtrasContainer>
  )
}
 
export default ChurchExtras


const ProfileImageSidePane = styled.div`
    width: 100%;
    margin-top: 2cqb;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px;
    align-items: center;
`

const PCImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: grey;
    margin-bottom: 10px;

`

const PITop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
const PIBottom = styled.div`
    display: flex;
    flex-direction: column;
` 
const MbsCt = styled.div`

`
const PITopName = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    color: var(--lightThemeFontColor);
`


const NotifNumber = styled.div`
    position: absolute;
    top: -4px;
    right: -4px;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: smaller;
`