import React, {useEffect} from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getMyChurches } from 'features/profileChurch/profileChurchSlice'
import { imgUrl } from 'features/constants'
import SpinnerSmall from 'components/SpinnerSmall'

export default function GoToChurch() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getMyChurches())
    }, [])

    const { getMyChurchesLoading, getMyChurchesSuccess, myChurches } = useSelector((state) => state.profileChurch)

    return (
        <GoToChurchContainer>
            <GoToTitle>Go to church</GoToTitle>

            <ChurchList>

                {
                    getMyChurchesSuccess && myChurches && myChurches.length > 0 ? 
                    (
                        myChurches.map((church, index) => (
                            <ChurchItem
                                onClick={() => navigate(`/${church.username}`)}
                                >
                                <ChurchImage> 
                                    <img 
                                        src={ `${imgUrl}/profile/${church.profileImg.imagePath2}` } 
                                        alt="church" 
                                        />
                                </ChurchImage>
                                <ChurchName>{church.fullName}</ChurchName> 
                            </ChurchItem>
                        ))
                    )
                    : getMyChurchesLoading ?
                    (
                        <SpinnerSmall 
                            border={'var(--themeColor)'} 
                            />
                    )
                    :
                    (
                        <h1>No churches found</h1>
                    )
                }

            </ChurchList>
        </GoToChurchContainer>
    )
}

const ChurchItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;   
`

const ChurchImage = styled.div`
    width: 45px;
    height: 45px;
    border-radius: 50%;  

    img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
`

const ChurchName = styled.h4`
    color: var(--lightThemeFontColor);
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 10px;
 
`

const GoToChurchContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 5px #ccc;
    margin-top: 10px;
`

const GoToTitle = styled.h1` 
    margin-bottom: 10px;
    color: var(--lightThemeFontColor);
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    @media (max-width: 768px) {
        font-size: 1.2em;
    }
`

const ChurchList = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

`