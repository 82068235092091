import { useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom' 
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import CreatePostBtn from './CreatePostBtn'
import useToggleDropdown from '../../hooks/useToggleDropdown'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'; 
import { getMemberOf, setViewChurchSearchModal } from '../../features/memberships/membershipSlice'
import { setViewCreatePostModal, postCreateReset } from '../../features/posts/postSlice'
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings'; 
import MessageIcon from '@mui/icons-material/Message';
import GroupsIcon from '@mui/icons-material/Groups';
import { toast } from 'react-toastify'
import { setPostType } from "features/createPost/selectPostTypeSlice";
import { setImagePreview, chooseImage } from "features/settings/profileImageSlice";
import { imgUrl } from 'features/constants'
import { getNotifs } from 'features/notifs/notifSlice'
import { ExtrasList, ExtrasContainer, ExtrasIcon, ExtrasItem } from './extras.styled'
import { useParams } from 'react-router-dom'
import { getProfileChurchData } from 'features/profileChurch/profileChurchSlice'
import useImageLoader from 'hooks/useImageLoader'
import { genericAvatar } from 'features/constants'
import { language } from 'app/language'


function UserExtras() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // get params from url
    const { churchName } = useParams()


    const ref1 = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref1)
    const { user } = useSelector((state) => state.auth)
    const {  isPostCreatedSuccess } = useSelector((state) => state.posts)
    const { lastNotifViewedIndex, newMessages } = useSelector((state) => state.notifs)
    const { getMemberOfLoading, memberOf, getMemberOfSuccess } = useSelector((state) => state.memberships2)

    let profileImageToShow;
    try{ profileImageToShow =  `${imgUrl}/profile/${user.imagePath}` }
    catch(e){  profileImageToShow =  null  }

    
    const [imageSource, { blur }] = useImageLoader(`${imgUrl}/profile/${user ? user.imagePath : genericAvatar}`, `${imgUrl}/profile/${user ? user.imagePath: genericAvatar}`)


    useEffect(() => { 
        
        if(!getMemberOfLoading){
            dispatch(getMemberOf())
        } 


    }, [])

    useEffect(() => {
        // get notifications
        dispatch(getNotifs())
    }, [])

    useEffect(() => { }, [user.name, user.fullName])

    useEffect(() => {
        if(isPostCreatedSuccess){
            navigate('/home')
            toast.success("Post created!")
            dispatch(setViewCreatePostModal(false)) 
            dispatch(chooseImage(''))
            dispatch(setImagePreview(''))
            dispatch(setPostType(''))
            dispatch(postCreateReset()) // this is added to prevent toast double toast notification when navigating after a successful post
        }
    }, [isPostCreatedSuccess, dispatch])


    const handleMembersDropDown = () => { 
        if(getMemberOfSuccess){ 
            if(memberOf.length === 0){ 
                dispatch(setViewChurchSearchModal())
            }else{ 
                setIsDropped.toggle(ref1)
            }
        } 
    }

    const handleGoToChurch = (username) => {

        if(churchName !== username){
            dispatch(getProfileChurchData({
                profileName: username
            }))
        } 
        navigate(`/${username}`)
    }


  return (
    <ExtrasContainer>

        <ProfileImageSidePane>
            <PITop>
                <img 
                    src={imageSource} 
                    alt="" 
                    style={{
                        width: '35px', height: '35px', borderRadius: '50%', objectFit: 'cover', marginRight: '15px', 
                        
                        }}
                    />
                <PITopName>{user.fullName}</PITopName>
            </PITop>
        </ProfileImageSidePane>

        <ExtrasList  
            onClick={handleMembersDropDown} 
            >
            <>
                <ExtrasIcon><GroupsIcon/></ExtrasIcon>
                <ExtrasItem>
                    {
                        getMemberOfSuccess ? 
                        ( memberOf.length > 1 ? 
                            (
                                language.myChurches[user.preferredLanguage]
                            )
                            :
                            (
                                language.myChurch[user.preferredLanguage]
                            )
                        )
                        : getMemberOfLoading &&
                        (
                            language.loading[user.preferredLanguage]
                        )
                    }
                </ExtrasItem>
            </>
            <MoreHorizIcon /> 
        </ExtrasList> 
        
        { ( isDropped && getMemberOfSuccess ) && 
                ( 
                    memberOf.map(ele => ( 
                        <MemberOfItem onClick={() => handleGoToChurch(ele.username)}>
                            
                                    <img 
                                        src={`${imgUrl}/profile/${ele.profileImg.imagePath2} `} 
                                        alt="" 
                                        style={{
                                            width: '35px', height: '35px', borderRadius: '50%', objectFit: 'cover', marginRight: '15px', 
                                            
                                            }}
                                        />
                            <span style={{
                                paddingLeft: "0px",
                                fontSize: "11px"
                                }}
                                >
                                    {ele.fullName}
                            </span>
                        </MemberOfItem> 
                    ))      
                )
            }

        <ExtrasList
            onClick={() => navigate(`/settings?section=memberships`)}
            >
            <ExtrasIcon><SettingsIcon/></ExtrasIcon>
            <ExtrasItem>
                {
                    language.settings[user.preferredLanguage]
                }
            </ExtrasItem>
        </ExtrasList>
        <ExtrasList
            onClick={() => navigate(`/messages`)}
            >
            <ExtrasIcon>
                <MessageIcon/>
                {
                    newMessages.length > 0 && (
                        <NotifNumber>{newMessages.length}</NotifNumber>
                    )
                } 
            </ExtrasIcon>
            <ExtrasItem>
                {
                    language.messages[user.preferredLanguage]
                }
            </ExtrasItem>
        </ExtrasList>
        {/* <ExtrasList>
            <div className='extrasIcon'><StorefrontIcon/></div>
            <Link to={`/market`}><div className='extrasItem'>Market</div></Link>
        </ExtrasList> */}
        <ExtrasList
            onClick={() => navigate(`/notifications`)}
            >
            <ExtrasIcon>
                <NotificationsIcon/>
                {
                    lastNotifViewedIndex > 0 && (
                        <NotifNumber>{lastNotifViewedIndex}</NotifNumber>
                    )
                } 
            </ExtrasIcon>
            <ExtrasItem>
                {
                    language.notifications[user.preferredLanguage]
                }
            </ExtrasItem>
        </ExtrasList>

        <CreatePostBtn />
    
    </ExtrasContainer>
  )
}


export default UserExtras


const MemberOfItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 7px;
    span{
        font-size: 12px;
        font-family: 'Roboto', sans-serif;
        color: var(--lightThemeFontColor);

        /* overflow text should be hidden with elipsis */
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

    }

    :hover{
        background-color: #e1e1e1;

        span{
            text-decoration: underline;
        }

    }

`

const ProfileImageSidePane = styled.div`
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    
    img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #c7c7c7;
    }
`

const PITop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const PITopName = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    font-family: 'Roboto', sans-serif;
    color: var(--lightThemeFontColor);
`
const NotifNumber = styled.div`
    position: absolute;
    top: -4px;
    right: -4px;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: smaller;
`
