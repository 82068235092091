import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { postReset } from '../../features/posts/postSlice'
import PostFeed from '../../components/MyPostFeed'
import UserProfileTop from '../../components/userProfile/UserProfileTop'
import ChurchExtras from '../../components/userProfile/ChurchExtras'
import UserExtras from '../../components/userProfile/UserExtras'
import styled from 'styled-components'
import ChoosePostPage from '../../components/postComponents/ChoosePostPage'

function MyPosts() {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

    useEffect(() => {
        return () => {
            dispatch(postReset())
        }
    },[])

  return (
    <>
        {user.isOrganization || user.isChurch ? (
            <ProfileRow>
                <ProfileLeft>
                    <ChurchExtras/>
                </ProfileLeft>  

                <UserProfileMain>
                    <UserProfileTop />
                    <PostFeed name={'user'} />
                 </UserProfileMain>
            </ProfileRow>


// for the churches they will only see filter posts
// since they don't need save posts, or church posts, or my posts

        )
        :
        (

          //for the users they will not see filter posts
          //since they can filter at the church profile
          //they will only see different post pages like: myPosts, savedPost, and churchPosts

            <ProfileRow>
                <ProfileLeft>
                    <UserExtras />
                </ProfileLeft>  



                <UserProfileMain>

                    <UPMTop>
                        <ChoosePostPage />
                    
                        <UserProfileTop /> 
                    </UPMTop>

                    
                    <PostFeed name={'churchUser'} />

                

                </UserProfileMain>
            </ProfileRow>
        )}
    </>
)
}


export default MyPosts

const UserProfileMain = styled.div`
    margin-left: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;

`

const ProfileRow = styled.div`
    display: flex;
    width: 1300px;
    flex-direction: row;
    justify-content: flex-start; 
    margin: 0 auto;
    @media (max-width: 868px) {
      width: 100%;
    }
`
const UPMTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const ProfileLeft = styled.div`
    display: flex;
    flex-direction: column;
`