export const sorted = (field = 'title', sortState = 'ascending') => {
    const sortMethods = {
        ascending: { 
            method: (a, b) => a[field] > b[field] ? 1 : -1
        },
        descending: { 
            method: (a, b) => a[field] < b[field] ? 1 : -1
        },
    };
    return sortMethods[sortState].method
}