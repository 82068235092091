import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  postImagePreview: {},
}

export const postImagePreviewSlice = createSlice({
  name: 'postImagePreview',
  initialState,
  reducers: {
    setImagePreview: (state, action) => {
      state.postImagePreview = action.payload
    },
    removeImagePreview: (state) => initialState,
  },
})

export const { setImagePreview, removeImagePreview } = postImagePreviewSlice.actions

export default postImagePreviewSlice.reducer