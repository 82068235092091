import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import mailService from './mailService'

const initialState = {
  mail: [],
  mailError: false,
  mailSuccess: false,
  mailLoading: false,
  mailMessage: '',

  fpLoading: false,
  fpError: false,
  fpSuccess: false,
  fpMessage: '',

}


export const sendMailRegister = createAsyncThunk(
  'mail/mailer',
  async (data, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.user.token
      return await mailService.sendMail(data)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const sendMailRegisterChurch = createAsyncThunk(
  'mail/church',
  async (data, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.user.token
      return await mailService.sendMailChurch(data)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const forgotPasswordMail = createAsyncThunk(
  'mail/forgotPassword',
  async (data, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.user.token
      return await mailService.forgotPasswordMail(data)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const mailSlice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetFPState: (state) => {  
      state.fpLoading = false
      state.fpError = false
      state.fpSuccess = false
      state.fpMessage = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMailRegister.pending, (state) => {
        state.mailLoading = true
      })
      .addCase(sendMailRegister.fulfilled, (state, action) => {
        state.mailLoading = false
        state.mailSuccess = true
        state.mail = "Success"
        state.mailError = false
      })
      .addCase(sendMailRegister.rejected, (state, action) => {
        state.mailLoading = false
        state.mailError = true
        state.mailMessage = action.payload
      })
      //---------------------------------------------------    
      .addCase(sendMailRegisterChurch.pending, (state) => {
        state.mailLoading = true
      })
      .addCase(sendMailRegisterChurch.fulfilled, (state, action) => {
        state.mailLoading = false
        state.mailSuccess = true
        state.mail = "Success"
        state.mailError = false
      })
      .addCase(sendMailRegisterChurch.rejected, (state, action) => {
        state.mailLoading = false
        state.mailError = true
        state.mailMessage = action.payload
      })
      //---------------------------------------------------  
      .addCase(forgotPasswordMail.pending, (state) => {
        state.fpLoading = true
      })
      .addCase(forgotPasswordMail.fulfilled, (state, action) => {
        state.fpLoading = false
        state.fpSuccess = true
        state.fpError = false
      })
      .addCase(forgotPasswordMail.rejected, (state, action) => {
        state.fpLoading = false
        state.fpError = true
        state.fpMessage = action.payload
      })
  },
})

export const { mailReset, resetFPState } = mailSlice.actions
export default mailSlice.reducer
