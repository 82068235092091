import React, { useState } from 'react'
import styled from 'styled-components'
import { CiImageOn } from "react-icons/ci";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify'
import { setCampaignImages, setSpliceImageArray } from 'features/givingCampaigns/givingCampaignSlice'
import { BiImageAdd } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";

import { Tooltip } from '@mui/material';

export default function AddExtraImage({ number, button }) {

    const dispatch = useDispatch()

    const { images } = useSelector((state) => state.givingCampaign)
    const [viewDeleteIcon, setViewDeleteIcon] = useState(false)

    const handleAddImage = (e) => {
        let fileName = e.target.files[0].name
        let fileType =  fileName.slice(fileName.lastIndexOf('.')+1).toLowerCase()
        if(fileType !== 'jpeg' && fileType !== 'jpg' && fileType !== 'png'){  return toast.error('Select a JPG or PNG files only') }
  
        dispatch(setCampaignImages({
            index: number,
            image: {
                src: URL.createObjectURL(e.target.files[0]),
                alt: e.target.files[0].name,
                fileSrc: e.target.files[0], 
            } 
        })); 
    }

    const handleImageRemove = (e) => {
        e.stopPropagation()
        e.preventDefault()
        dispatch(setSpliceImageArray({
            index: number
        }))
    }

    return (
        <ExtraImageContainer 
            onMouseEnter={() => setViewDeleteIcon(true)}
            onMouseLeave={() => setViewDeleteIcon(false)}
            bgColor={!button}
            >
            {
                viewDeleteIcon && !button && ( 
                    <RemoveImage
                        onClick={handleImageRemove}
                        >
                        <RxCross2 size={15} color='red' />
                    </RemoveImage> 
                )
            } 

            <input 
                hidden  
                id="imageUpload"  
                type="file" 
                accept=".png, .jpg, .jpeg" 
                name="file" 
                onChange={handleAddImage}
                />
            { 
                images.length > 0 && !button ? (
                    <ImageWrap>
                        <img 
                            src={images[number].src} 
                            alt={images[number].alt}  
                            />
                    </ImageWrap>
                )
                :
                (
                    <>
                        <BiImageAdd size={20} />Add Image 
                    </>
                )
            }
        </ExtraImageContainer>
    )
}

const RemoveImage = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #ffffff9c;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    svg{
        margin-top: 3px;
    
    }
`

const ImageWrap = styled.div`
    width: 100%;
    height: 100%;
    img{
        border-radius: 6px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

const ExtraImageContainer = styled.label.attrs(() => ({
    htmlFor: 'imageUpload'
  }))`
    background-color: ${props => props.bgColor ? 'transparent' : 'aliceblue'};
    width: 90px;
    height: 90px;
    border-radius: 6px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px dashed transparent;
    transition: all 0.3s ease-in-out;
    font-size: 12px;
    cursor: pointer;
    position: relative;
    svg{
        margin-bottom: 5px;
    }
    &:hover{
        border: 3px dashed #ccc;
    }
`