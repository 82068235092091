import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const URL_CREATE = '/api/polls/createPoll'
const URL_GET = '/api/polls/getPolls'
const URL_CAST = '/api/polls/castVote'
const URL_DELETE = '/api/polls/deletePoll'

//for sending the token
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }


const createPoll = async (data, token) => {
    const response = await axios.post(URL+URL_CREATE, data, config(token))
    return response.data
}

const getPolls = async (data, token) => {
  const response = await axios.post(URL+URL_GET, data, config(token))
  return response.data
}

const castVote = async (data, token) => {
  const response = await axios.post(URL+URL_CAST, data, config(token))
  return response.data
}

const deletePoll = async (data, token) => {
  const response = await axios.post(URL+URL_DELETE, data, config(token))
  return response.data
}

const pollService = {
    createPoll, getPolls, castVote, deletePoll
}

export default pollService