export const toggle24hr = (time, onoff) => {
    if(onoff===undefined) onoff = isNaN(time.replace(':',''))//auto-detect format
    var pm = time.toString().toLowerCase().indexOf('pm')>-1 //check if 'pm' exists in the time string
    time = time.toString().toLowerCase().replace(/[ap]m/,'').split(':') //convert time to an array of numbers
    time[0] = Number(time[0])
    if(onoff){//convert to 24 hour:
        if((pm && time[0]!==12)) time[0] += 12
        else if(!pm && time[0]===12) time[0] = '00'  //handle midnight
        if(String(time[0]).length===1) time[0] = '0'+time[0] //add leading zeros if needed
    }else{ //convert to 12 hour:
        pm = time[0]>=12
        if(!time[0]) time[0]=12 //handle midnight
        else if(pm && time[0]!==12) time[0] -= 12
    }
    return onoff ? time.join(':') : time.join(':')+(pm ? 'pm' : 'am')
}