import ReceiptIcon from '@mui/icons-material/Receipt';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import WorkIcon from '@mui/icons-material/Work';
import DriveEtaIcon from '@mui/icons-material/DriveEta';

const units = [ 
    {label: 'Miles', value: 'mi'},
    {label: 'Kilometers', value: 'km'},
]

export const searchRadius = [
    { value: 30 , unit: units[0].label },
    { value: 100 , unit: units[0].label },
    { value: 500 , unit: units[0].label },
    { value: 999 , unit: units[0].label },
]

export const listingTypes = {
    "Vehicles": { 
        type: 'Vehicles',
        tooltip: 'Motorized vehicles for sale',
        searchPlaceholder: 'Search for vehicles',
        tooltipPlacement: "top",
        icon: <DriveEtaIcon />
    }, 
    "Housing" : {
        type: 'Housing',
        tooltip: 'Homes, apartments, and real estate sales or rentals',
        searchPlaceholder: 'Search for housing',
        tooltipPlacement: "top",
        icon: <MapsHomeWorkIcon />
    },
    "Services": {
        type: 'Services',
        tooltip: 'Services offered or needed',
        searchPlaceholder: 'Search for services offfered',
        tooltipPlacement: "top",
        icon: <HomeRepairServiceIcon />
    },
    "Job" : {
        type: 'Jobs',
        tooltip: 'Jobs and employment opportunities',
        searchPlaceholder: 'Search for jobs',
        tooltipPlacement: "bottom",
        icon: <WorkIcon />
    },   
    "For Sale": { 
        type: 'For Sale',
        tooltip: 'All other items for sale',
        searchPlaceholder: 'Search for items for sale',
        tooltipPlacement: "bottom",
        icon: <ReceiptIcon />
    }, 
    "Wanted": {
        type: 'Wanted',
        tooltip: 'Items you are looking for',
        searchPlaceholder: 'Search for items wanted',
        tooltipPlacement: "bottom",
        icon: <ManageSearchIcon />
    },
}

export const forSaleCategories = [
    {'Antiques & Collectibles': ['Electronics', 'Furniture','Jewelry', 'Musical Instruments', 'Tools', 'Toys', 'Coins', 'Stamps', 'Other Antiques & Collectibles'] },        
    {'Appliances': ['Air Conditioners', 'Air Purifiers', 'Blenders', 'Bread Makers', 'Coffee Makers', 'Dishwashers', 'Dryers', 'Fans', 'Food Processors', 'Freezers', 'Fryers', 'Heaters', 'Ice Cream Makers', 'Ice Makers', 'Ironing Machines', 'Juicers', 'Microwaves', 'Mixers', 'Ovens', 'Pressure Cookers', 'Refrigerators', 'Sewing Machines', 'Stoves', 'Toasters', 'Vacuum Cleaners', 'Washers', 'Water Filters', 'Water Heaters', 'Other Appliances']},
    {'Arts & Crafts': [ 'Supplies', 'Tools', 'Other Arts & Crafts', 'Sewing'],},  
    {'Vehicle Parts': ['Car & Truck Parts', 'Boat Parts', 'Motorcycle Parts', 'Other Vehicle Parts']},
    {'Baby & Kids': ['Baby & Kids Clothing', 'Baby & Kids Furniture', 'Baby & Kids Gear', 'Baby & Kids Shoes', 'Baby & Kids Toys', 'Other Baby & Kids']},
    {'Beauty & Health': ['Bath & Body', 'Dental Care', 'Fragrances', 'Hair Care', 'Health Care', 'Makeup', 'Massage', 'Medical Supplies', 'Nail Care', 'Natural & Homeopathic Remedies', 'Shaving & Hair Removal', 'Skin Care', 'Sun Protection & Tanning', 'Tattoos & Body Art', 'Vision Care', 'Vitamins & Supplements', 'Other Beauty & Health']},
    {'Bicycles': ['Bicycle Accessories', 'Bicycle Clothing', 'Bicycle Parts', 'Bicycles', 'Other Bicycles']},
    {'Books & Magazines': ['Books', 'Magazines', 'Other Books & Magazines']},
    {'Campers & RVs': ['Campers', 'RVs', 'Other Campers & RVs']},
    {'Cell Phones': ['Cell Phone Accessories', 'Cell Phones', 'Other Cell Phones']},
    {'Clothing & Apparel': ['Clothing', 'Shoes', 'Other Clothing & Apparel']},
    {'Computers & Electronics': ['Cameras & Camcorders', 'Cell Phones', 'Computers & Accessories', 'Electronics', 'Gaming', 'Headphones', 'Home Audio & Theater', 'Networking & Communication', 'Portable Audio', 'Portable Video', 'Printers & Scanners', 'Security & Surveillance', 'Software', 'TV & Video', 'Video Game Consoles', 'Video Games', 'Other Computers & Electronics']},
    {'Farm & Ranch': ['Farm & Ranch Equipment', 'Farm & Ranch Supplies', 'Other Farm & Ranch']},
    {'Free Stuff': ['Free Stuff']},
    {'Furniture': ['Beds & Bed Frames', 'Bookcases', 'Cabinets & Cupboards', 'Chairs', 'Desks', 'Dressers & Chests', 'Entertainment Centers & TV Stands', 'Futons', 'Nightstands', 'Ottomans & Footstools', 'Sofas, Loveseats & Chaises', 'Tables', 'Other Furniture']},
    {'Garage Sales': ['Garage Sales']},
    {'Household': ['Household Items']},
    {'Jewelry': ['Bracelets', 'Earrings', 'Necklaces', 'Pendants', 'Rings', 'Watches', 'Other Jewelry']},
    {'Motorcycles': ['Motorcycles']},
    {'Musical Instruments': 'Musical Instruments'},
    {'Pet Supplies': ['Pet Supplies']},
    {'Sporting & Outdoors': ['Camping & Hiking', 'Exercise Equipment', 'Fishing & Hunting', 'Golf Equipment', 'Indoor Games', 'Outdoor Games', 'Paintball Equipment', 'Skateboarding & Rollerblading', 'Skiing & Snowboarding', 'Sports Equipment', 'Water Sports', 'Other Sporting & Outdoors']},
    {'Tools & Equipment': ['Automotive', 'Hand Tools', 'Welding', 'Building', 'Hobby', 'Lawn & Garden', 'Power Tools', 'Tool Storage & Workbenches', 'Other Tools & Equipment']},
]

export const servicesCategories =  [
    {'Automotive': ['Auto Body', 'Auto Repair', 'Auto Upholstery', 'Car Wash', 'Motorcycle Repair', 'Other Automotive']},
    {'Beauty & Health': ['Barbers', 'Beauty Salons', 'Day Spas', 'Dentists', 'Dermatologists', 'Doctors', 'Hair Salons', 'Massage', 'Nail Salons', 'Optometrists', 'Other Beauty & Health', 'Tattoo Shops']},
    {'Business Services': ['Accountants', 'Advertising', 'Business Consultants', 'Business Law', 'Business Services', 'Career Counseling', 'Computer Services', 'Employment Agencies', 'Financial Services', 'Insurance', 'Lawyers', 'Marketing', 'Other Business Services', 'Printing Services', 'Public Relations', 'Security Services', 'Signmaking', 'Tax Preparation', 'Web Design', 'Web Hosting']},
    {'Child Care': ['Child Care']},
    {'Home': ['Appliance Repair', 'Carpenters', 'Carpet Cleaning', 'Carpet Installation', 'Contractors', 'Electricians', 'Flooring', 'Garage Door Services', 'Gardeners', 'Handyman', 'Heating & Air Conditioning/HVAC', 'Home Cleaning', 'Home Inspectors', 'Home Organization', 'Home Theatre Installation', 'Home Window Tinting', 'House Sitters', 'Interior Design', 'Internet Service Providers', 'Keys & Locksmiths', 'Landscaping', 'Movers', 'Painters', 'Pest Control', 'Plumbing', 'Pool Cleaners', 'Roofing', 'Security Systems', 'Solar Installation', 'Structural Engineers', 'Television Service Providers', 'Tree Services', 'Upholstery', 'Window Washing', 'Other Home']},
    {'Lessons & Classes': ['Music, Dance, & Theater', 'Sports & Fitness', 'Education', 'Other Lessons & Classes']},
    {'Other Services': ['Other Services']},
]