import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { setListingImages } from 'features/marketplace/marketSlice'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

export default function ListingImages() {

    const dispatch = useDispatch()
    const [imageURLS, setImageURLs] = useState([]);
    const { listingImages } = useSelector(state => state.market)

    useEffect(() => {
      if (listingImages.length < 1) return;
      const newImageUrls = [];
      listingImages.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
      setImageURLs(newImageUrls);
    }, [listingImages]);


    
    function onImageChange(e) { 
        dispatch(setListingImages([
            ...listingImages,
            ...e.target.files
        ]));
    }


    return (

        <ListingImagesContainer>

            { listingImages.length === 0 ? 
                (
                    <AddImageButton
                        width="100%"
                        htmlFor="file-upload"
                        >
                        <InsertPhotoIcon />

                        <span> Upload Images </span> 
                        <input hidden id="file-upload" type="file" multiple accept="image/*" onChange={onImageChange} />
                    </AddImageButton>
                )
                :
                (
                    <ImagesWrapper>
                        {imageURLS.map((image) => (
                            <ListingImage src={image} />
                        ))}
                        <AddImageButton
                            width="110px"
                            htmlFor="file-upload"
                            >
                            <InsertPhotoIcon />
                            <span> Upload More <br/> (Limit 10) </span> 
                            <input hidden id="file-upload" type="file" multiple accept="image/*" onChange={onImageChange} />
                        </AddImageButton>
                        <button 
                            onClick={() => dispatch(setListingImages([]))}
                            style={{fontSize: '10px'}}
                            > Remove images
                        </button>
                    </ImagesWrapper>            
                )
            }
         
        </ListingImagesContainer>


    )
}

const ListingImagesContainer = styled.div`
    margin-top: 40px;
    /* border: 1px solid #ccc; */
    display: flex;
    flex-direction: row;
    align-items: center ;
    justify-content: center;
`
const AddImageButton = styled.label`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center ;
    color: var(--lightThemeFontColor);
    justify-content: center; 
    width: ${props => props.width ? props.width : '100%'};
    height: 100px; 
    border-radius: 10px;
    border: 2px solid #ccc;
    span{
        font-size: 11px;
        cursor: pointer;
    }

`

const ImagesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center ;
    justify-content: flex-start; 
    button{
        cursor: pointer;
        margin-left: 10px;
    }
    overflow-x: scroll;
    /* make scrollbar thinner */
    ::-webkit-scrollbar {
        height: 2px;
    }
`

const ListingImage = styled.img`
    width: 100px;
    height: 100px;
    /* object-fit: cover; */
    border-radius: 10px;
    margin: 5px;
    border: 2px solid #ccc;
`