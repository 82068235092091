import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { getChurchTransactions } from 'features/giving/givingSlice'
import styled from 'styled-components'
import { TransactionsTableContainer } from 'components/giving/giving.styled' 
import ChurchTransactionItem from './ChurchTransactionsItem'
import { getGivingCampaigns } from 'features/givingCampaigns/givingCampaignSlice'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MenuItem from '@mui/material/MenuItem'; 
import SpinnerIntermintentSmall from 'loaders/SpinnerIntermintentSmall'
import Spinner from 'loaders/Spinner'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 1;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            color: 'var(--lightThemeFontColor)'
        },
    },
};

export default function CampaignTransactions() {

    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { profileChurch } = useSelector(state => state.profileChurch)
    const { 
        transactions,
        getTransactionsLoading,
        getTransactionsSuccess,
        getTransactionsError,
    } = useSelector(state => state.giving)

    const { 
        campaigns,
        getGivingCampaignsLoading,
        getGivingCampaignsSuccess,
     } = useSelector(state => state.givingCampaign)

    const handleViewCampaignTransactions = (id) => {
        dispatch(getChurchTransactions({
            metadata: JSON.stringify({campaignId: id})
        }))
    }

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')
 
    useEffect(() => {
        dispatch(getGivingCampaigns())
    }, [])

    const handlePaymentSelect = (e) => {
        setSelectedPaymentMethod(e.target.value)
        dispatch(getChurchTransactions({
            metadata: JSON.stringify({campaignId: e.target.value._id})
        }))
    }

    return (
        <TransactionsContainer> 
            <FormControlContainer>
                <FormControl
                    fullWidth
                    >
                    <Select
                        style={{
                            backgroundColor: 'white',
                            height: '40px',
                            outline: 'none',
                            // border: '2px solid var(--themeColor)',
                            color: 'var(--lightThemeFontColor)',
                            borderRadius: '7px',
                        }} 
                        sx={{ 
                            // boxShadow: 'none', 
                            // '.MuiOutlinedInput-notchedOutline': { 
                            //     border: 0 ,
                            //     outline: 'none', 
                            // } 
                        }}
                        id="demo-simple-select" 
                        value={selectedPaymentMethod} 
                        displayEmpty 
                        onChange={handlePaymentSelect}
                        MenuProps={MenuProps}
                        inputProps={{ 'aria-label': 'Without label' }}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <em>Select a campaign</em>;
                            } 
                            if(selected.title){
                                return selected.title
                            }
                            }}
                        >
                            {
                                getGivingCampaignsLoading ?
                                (                
                                    <MenuItem disabled value="">
                                        <em>Loading Your active campaigns</em>
                                    </MenuItem>
                                )
                                :
                                ( 
                                    campaigns.map((campaign, index) => (
                                        <MenuItem 
                                            value={campaign}  
                                            key={campaign._id}
                                            >  
                                            {campaign.title}
                                        </MenuItem>
                                    )) 
                                )
                            }
                    </Select>
                </FormControl>      
            </FormControlContainer>
            <TransactionsTableContainer
                style={{marginTop: '20px'}}
                >
                <tbody>
                    <tr>
                        <th>Date</th>
                        <th>Amount</th> 
                        <th>Description</th>
                        <th>User Email</th>
                        {/* <th>Status</th> */}
                    </tr>

                    {
                        getTransactionsLoading ?
                        (
                            <tr>
                                <td colSpan="4"> 
                                    <i>Loading Transactions...</i>
                                </td>
                            </tr>
                        )
                        :
                        (
                            transactions.map((transaction) => 
                            (
                                <ChurchTransactionItem  
                                    key={transaction.id} 
                                    data={transaction} 
                                    />
                            ) 
                        ) 
                    )}          
                    </tbody>
            </TransactionsTableContainer>
        </TransactionsContainer>
    )
}

const FormControlContainer = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

`

const TransactionsContainer = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

`