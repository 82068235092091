import React, {useEffect, useState} from 'react'
import styled from 'styled-components'  
import useToggleDropdown from 'hooks/useToggleDropdown';
import { useSelector, useDispatch } from 'react-redux';
import { addListing, setListingType, setViewAddListingModal } from 'features/marketplace/marketSlice';
import { LocationInputWrap } from '../market.styled';
import ListingImages from './ListingImages'; 
import ListingTypeCategorySelect from './ListingTypeCategorySelect'; 
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ListingLocation from './listingForms/ListingLocation';
import { getCoordinates, resetMap } from 'features/maps/mapSlice';
import useKeypress from 'hooks/useKeypress';

export default function ListingForm() {

    const dispatch = useDispatch()
    const { listingType, listingImages, forSaleCategory, selectedCategory, isAddListingSuccess, isAddListingLoading } = useSelector(state => state.market)
    const ref1 = React.useRef(null)
    const locationRef = React.useRef(null)
    const [showDropdown, setShowDropdown] = useToggleDropdown(ref1) 
    const [listingTitle, setListingTitle] = useState('')
    const [listingPrice, setListingPrice] = useState('')
    const [listingDescription, setListingDescription] = useState('')
    const [locationInput, setLocationInput] = useState('')
    const [inputFocus, setInputFocus] = useState('')
    const { user } = useSelector(state => state.auth)
    const { getCoordinatesLoading, getCoordinatesSuccess, getCoordinatesMessage, location, locationCoordinates, searchTerm } = useSelector(state => state.maps)
    // const [coordinates, setCoordinates] = useState()

    const handleDrop = () => {
        setShowDropdown.toggle(!showDropdown)
    }

    const handleListingTitle = (e) => {
        setListingTitle(e.target.value)
    }

    const handleListingPrice = (e) => {
        setListingPrice(e.target.value)
    }

    const handleListingDescription = (e) => {
        setListingDescription(e.target.value)
    }

    const handleLocation = (e) => {
        setLocationInput(e.target.value)
    }

    const handleSubmit = () => {

        // dispatch to backend
        // dispatch to store to add listing
        // close modal
        const formData = new FormData();

        for (let i = 0; i < listingImages.length; i++) {
            formData.append('files', listingImages[i])
        }

        formData.append('listingType', listingType)

        if(listingType === 'For Sale'){
            formData.append('listingCategory', forSaleCategory[0])
            formData.append('listingSubCategory', selectedCategory)
        }

        formData.append('listingTitle', listingTitle)
        formData.append('listingPrice', listingPrice)
        formData.append('listingDescription', listingDescription)
        formData.append('locationAddress', locationInput)
        formData.append('coordinatesLng', locationCoordinates[1])
        formData.append('coordinatesLat', locationCoordinates[0])

        dispatch(addListing(formData) )

    }

    const handleLocationFocus = () => { 
        // if(!user.isOrganization){
            // location might not be known or available, so ask for it
            // navigator.geolocation.getCurrentPosition(function(position) {
            //     console.log("Latitude is :", position.coords.latitude);
            //     console.log("Longitude is :", position.coords.longitude);
            //     setLocationInput(`${position.coords.latitude}, ${position.coords.longitude}`)
            // }); 
        // }
    }

    useEffect(() => {
        if(getCoordinatesSuccess && location.bestMatch){
            setLocationInput(location.bestMatch)
        }
    }, [getCoordinatesSuccess, location.bestMatch])


    useEffect(() => {
        if(isAddListingSuccess){
            dispatch(setViewAddListingModal(false))
        }
    }, [isAddListingSuccess])

    const handleLocationEnter = () => {
        // check if input is in focus
        // if so, then dispatch to get coordinates


        if(searchTerm !== locationInput){

            if(document.activeElement === locationRef.current){
                dispatch(getCoordinates({address: locationInput}))
            }
        }
    }

    useKeypress('Enter', handleLocationEnter)

    // add unmount to reset map
    useEffect(() => {
        return () => {
            dispatch(resetMap())
        }
    }, [])

    return (
        <FormView>
            <BackButton
                onClick={() => dispatch(setListingType(null))}
                >
                <KeyboardBackspaceIcon />
                <span>Back</span>
            </BackButton>

 
  
        <ListingImages />

        <ListingInputWrapperMain>
           <ListingInputWrapper>

                { listingTitle.length > 0 && (<ListingInputTitle> Title</ListingInputTitle> ) }
                
                <ListingInput
                    type="text"
                    value={listingTitle}
                    onChange={handleListingTitle}
                    placeholder="Listing Title"
                    />
            </ListingInputWrapper>

            {
                listingType === 'For Sale' && (
                    <ListingTypeCategorySelect />
                )

            } 
            <ListingInputWrapper
                width={'50%'}
                >
                { listingPrice.length > 0 && (<ListingInputTitle> Price</ListingInputTitle> )} 
                <ListingInput
                    type="text"
                    value={listingPrice}
                    onChange={handleListingPrice}
                    placeholder="Listing Price"
                    />
            </ListingInputWrapper>

            <ListingInputWrapper 
                style={{borderBottom: 'none'}}
                >
                { listingDescription.length > 0 && (<ListingInputTitle> Description</ListingInputTitle> )}
                {/* <ListingInputTitle> Description</ListingInputTitle> */}
                <ListingDescriptionInput
                    type="text"
                    value={listingDescription}
                    onChange={handleListingDescription}
                    placeholder="Listing Description"
                    />
            </ListingInputWrapper>

            <ListingInputWrapper
                
                onFocus={handleLocationFocus}
                >
                    { locationInput.length > 0 && (<ListingInputTitle>Location</ListingInputTitle> )} 

                <LocationInputWrap>
                    <ListingInput
                        onFocus={() => setInputFocus('location')}
                        ref={locationRef}
                        type="text"
                        value={locationInput}
                        onChange={handleLocation}
                        placeholder={`Location ${inputFocus === 'location' ? '(i.e. zip code, city name, and/or address)': ''}`}
                        />
                    {
                        searchTerm === locationInput || locationInput === location.bestMatch ? 
                        (
                            <button
                                onClick={() => {
                                    setLocationInput('')
                                    dispatch(resetMap())
                                }}
                                >
                                Clear
                            </button>    
                        )
                        :
                        (
                            <button
                                onClick={() => dispatch(getCoordinates({address: locationInput}))}
                                >
                                Set Location 
                            </button>
                        )
                    }
                </LocationInputWrap>
            </ListingInputWrapper>

            { 
                getCoordinatesSuccess && !getCoordinatesLoading && (
                    <MapContainer style={{height: '200px', position: "relative", marginTop: '10px'}}> 
                        <ListingLocation
                            markerPosition={locationCoordinates}
                            // markerPosition={[46.85230749999999, -121.7603229]}
                            />
                    </MapContainer>
                )

            }



        </ListingInputWrapperMain>

 

        {/* <ListingLocation /> */}

        <SubmitButton
            disabled={isAddListingLoading}
            onClick={handleSubmit}
            >
            { isAddListingLoading ? 'Creating...' : 'Submit' }
        </SubmitButton>
    </FormView>
    )
}

const ListingInputWrapperMain = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column  ;
    align-items: flex-start;
    justify-content: flex-start; 
    margin-top: 20px;
`   

const ListingInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: ${props => props.width ? props.width : '100%'};
    padding: 5px;
    border-bottom: 1px solid #e4e4e4; 
    margin-top: 8px;
`

const ListingInputTitle = styled.div`
    color: var(--lightThemeFontColor);
    font-size: 10px;
`

const ListingInput = styled.input`
    width: 100%;
    padding: 5px;
    border: none;
    outline: none;
`

const ListingDescriptionInput = styled.textarea`
    width: 100%;
    height: 100px;
    border: 1px solid #e4e4e4;
    font-family: 'Robot', sans-serif;
    /* border: none; */
    border-radius: 5px;
    outline: none;
    padding: 5px;
    color: var(--lightThemeFontColor);
`

const SubmitButton = styled.div`
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    width: 100px;
    height: 50px;
    border-radius: 10px;
    background-color: #00a82d;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    &:hover{
        background-color: #008c26;
    }

`

const FormView = styled.div`
    padding: 20px;
    padding-top: 30px;
    /* width: 35%;  removed since not haivng a preview view */
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;  
    overflow-y: scroll;
    /* hide scroll bar */
    ::-webkit-scrollbar {
        display: none;
    }

`

const BackButton = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    color: var(--lightThemeFontColor);
    font-size: 12px; 
    svg{
        margin-right: 10px;
    }

`

const MapContainer = styled.div`
  position: relative;
  min-height: 200px;
  height: 50%;
  width: 100%;
  margin-top: 10px;
  border-radius: 15px; 
  border: 1px solid #d6d6d6;
  box-shadow: 0 0 2px rgba(0,0,0,0.2);
`
