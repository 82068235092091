import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'  
import { setViewAddConnectBankModal, addConnectBank } from 'features/giving/givingSlice'
import ModalCloseButton from 'components/buttons/ModalCloseButton';
import { CiBank } from "react-icons/ci";
import { SubmitButton } from 'components/giving/giving.styled';
import SpinnerSmall from 'components/SpinnerSmall';
import { toast } from 'react-toastify';


export default function AddConnectBankModal() {

    const dispatch = useDispatch() 

    const { user } = useSelector(state => state.auth)
    const { profileChurch } = useSelector(state => state.profileChurch)

    const [bankDetails, setBankDetails] = useState({
        accountName: '',
        routingNumber: '',
        accountNumber: '',
        confirmAccountNumber: ''
    })

    const { 
        accountName, 
        routingNumber, 
        accountNumber,
        confirmAccountNumber
    } = bankDetails

    const handleBankDetails = (e) => {

        if(e.target.name !== 'accountName'){
            // do not allow user to enter non numeric characters or spaces
            // if(e.target.name === 'routingNumber' || e.target.name === 'accountNumber' || e.target.name === 'confirmAccountNumber'){
               

                // if length of string is more than   characters, do not allow user to enter more characters
                if(e.target.value.length > 21){
                    return
                }
            
                
                if(e.target.value.match(/[^0-9]/g)){
                    return
                }
   
        }


        setBankDetails({
            ...bankDetails,
            [e.target.name]: e.target.value
        })
    } 
    const { 
        viewAddConnectBankModal,
        addConnectBankLoading,
        addConnectBankSuccess,
        addConnectBankError,
        addConnectBankMessage
    } = useSelector(state => state.giving)

    
    const handleSubmitBank = () => {

        if(accountName === '' || routingNumber === '' || accountNumber === '' || confirmAccountNumber === ''){
            return toast.error('All fields are required')
        }

        if(accountNumber !== confirmAccountNumber){
            return toast.error('Account number and confirm account number do not match')
        }

        if(routingNumber.length !== 9){
            return toast.error('Routing number must be 9 digits')
        }

        const bankDetails = {
            accountName: accountName,
            routingNumber: routingNumber,
            accountNumber: accountNumber, 
            confirmAccountNumber: confirmAccountNumber,
            churchId: profileChurch.id,
        }

        dispatch(addConnectBank(bankDetails))
 
    }

    useEffect(() => {
        
        if(addConnectBankSuccess){
            dispatch(setViewAddConnectBankModal(false)) 
        }

    }, [addConnectBankSuccess])


    return (
        <AddBankModalContainer>
            <ModalCloseButton
                handler={() => dispatch(setViewAddConnectBankModal(false))}
                />
            <ABMHeader>  
            
                <CiBank size={30} color="green" />
                Connect Bank 
            </ABMHeader>
            
            <FormABMContainer>
                <FormElementsContainer>

                    <HeadingTitle>Bank account details </HeadingTitle>

                    <FormElementWrap>
                        <label>Account Name</label>
                        <input
                            type="text"
                            value={accountName}
                            onChange={handleBankDetails}
                            name="accountName"
                            placeholder="Account Name"
                            />
                    </FormElementWrap>

                    <FormElementWrap>
                        <label>Routing Number</label>
                        <input
                            type="text"
                            value={routingNumber}
                            onChange={handleBankDetails}
                            name="routingNumber"
                            placeholder="Routing Number"
                            />
                    </FormElementWrap>

                    <FormElementWrap>
                        <label>Account Number</label>
                        <input
                            type="text"
                            value={accountNumber}
                            onChange={handleBankDetails}
                            name="accountNumber"
                            placeholder="Account Number"
                            />
                    </FormElementWrap>

                    <FormElementWrap>
                        <label>Confirm Account Number</label>

                        <input
                            type="text"
                            value={confirmAccountNumber}
                            onChange={handleBankDetails}
                            name="confirmAccountNumber"
                            placeholder="Confirm Account Number"
                            />
                    </FormElementWrap>

                </FormElementsContainer> 
                <ButtonContainer>
                    <SubmitButton
                        disabled={addConnectBankLoading}
                        onClick={handleSubmitBank}
                        >
                        {
                            addConnectBankLoading ? 
                            (
                                <SpinnerSmall />
                            ) : 
                            (
                                "Connect Bank"
                            )
                        }
                    </SubmitButton> 
                </ButtonContainer>
            </FormABMContainer> 
        </AddBankModalContainer>
    )
}

const HeadingTitle = styled.div`
    font-size: 16px;
    margin-bottom: 15px;
`

const FormElementWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 10px;
 

    label{
        width: 37%;
    }

    input{
        transition: all 0.5s ease-in-out;
        width: 63%;
        padding: 12px;
        border-radius: 5px;
        border: 3px solid #b2b2b235;
        outline: none;

        &:focus{
            border: 3px solid var(--themeColor);
        }

    }

`
 
const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
` 
const FormElementsContainer = styled.div` 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    color: var(--lightThemeFontColor);
`



const FormABMContainer = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: calc(100% - 85px); 
    margin-top: 15px;
`

const ABMHeader = styled.div`
    width: 100%;
    font-size: 18px;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    color: var(--lightThemeFontColor);
    svg{
        margin-right: 10px; 
    }
`

const AddBankModalContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 760px;
    height: 500px;
    background-color: white;
    z-index: 1000;
    padding: 0px 40px 40px 40px;
    border-radius: 5px;
`