import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

function HaveAnAccount() {
    const navigate = useNavigate()
    const handleClick = () => { navigate('/register') }

  return (
    <DHAC>
        <DHACText>Don't have an account? </DHACText>
        <DHACText2 onClick={handleClick}>Register</DHACText2>
    </DHAC>
  )
}

export default HaveAnAccount

const DHAC = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    color: #343434;
    font-size: smaller;
`
const DHACText = styled.div`
    padding-right: 5px;
`

const DHACText2 = styled.div`
    text-decoration: underline;
    cursor: pointer;
    &:hover{
        color: black;
    }

`