import styled from "styled-components";

export const MImage = styled.img`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
`
export const MImageSmall = styled.img`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 10px;
`
export const ConversationFeed = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: scroll;
    justify-content: flex-start;

    /* hide the scroll bar */
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }

    @media (max-width: 768px) {
        height: fit-content;
    }
`

export const ConvItemContainer = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background-color: ${props => props.isSelected ? 'white' : props.isUnread ? 'white' : 'none'};
    cursor: pointer;
    border: ${props => props.isSelected ? '2px solid var(--themeColor)' : 'none'};
    border-left: ${props => props.isSelected ? '6px solid var(--themeColor)' : 'none'};
    margin-bottom: 5px;

    &:hover{
        background-color: #ffffff;
        border-radius: ${props => props.isSelected ? 'none' : '10px'};
        transition: all 0.4s ease-in-out;
    }

    /* hide p if parent props is unread */



    section{
        display: ${props => props.isUnread ? 'flex' : 'none'};
        position: absolute;
        top: 1px;
        right: 1px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--themeColor);
    }

`