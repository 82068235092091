
import {React, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import styled from 'styled-components'
import { closeMemberModal, getMemberOf } from 'features/memberships/membershipSlice'
import { useNavigate } from 'react-router-dom' 
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import { imgUrl } from 'features/constants'
import { language } from 'app/language'


export default function MembershipModal() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
    const {user} = useSelector((state) => state.auth)
    
    const [isMembershipLoaded, setIsMembershipLoaded] = useState(false)
    const { memberOf, getMemberOfSuccess } = useSelector((state) => state.memberships2)

    useEffect(() => {
        if(!memberOf.length){
            dispatch(getMemberOf())
        }
    }, [])

    const handleChurchNav = (value) => {
        dispatch(closeMemberModal())
        navigate(`/${value}`)
    }

  return ( 
      <MModalWrap>  
        <ModalCloseButton
          handler={() => dispatch(closeMemberModal())}
          top='10px'
          right='10px'
          size='25px'
          />

        <ModalHeading>
          {
            language.churchMembership[user.preferredLanguage]
          }
        </ModalHeading>

        { getMemberOfSuccess && 
          (
            <ViewMembershipFeed> 
              { memberOf.map(ele => ( 
                  <MbsItem onClick={() => handleChurchNav(ele.username)}> 
                    <img
                      src={`${imgUrl}/profile/${ele.profileImg.imagePath2}`}
                      alt="church profile"
                      style={{width: "35px", height: "35px", borderRadius: "50%"}}
                      />
                    <span style={{marginLeft: "10px", fontSize: '12px'}}>
                      {ele.fullName}
                    </span>
                  </MbsItem> 
                ))
              }
            </ViewMembershipFeed>
          )
        }
    </MModalWrap> 
  )
}

const ViewMembershipFeed = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    width: 100%;
    overflow-y: scroll;

`

const MModalWrap = styled.div` 
  width: 550px;
  min-height: 520px;
  max-height: 700px;
  background: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 3px;
  position: relative;
  animation: animate 0.3s;
  padding: 20px;

  @media (max-width: 768px) {
    width: 90%;
    min-height: unset;
    height: fit-content;
  }

`

const ModalHeading = styled.div`
  font-size: 14px;
  margin-bottom: 15px;
  margin-top: 20px;
  color: grey;
`

const MbsItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    border-radius: 10px;
    padding: 10px 10px;
    color: var(--lightThemeFontColor);
    cursor: pointer;
    width: 100%;
    &:hover{
        background-color: #d0d0d0;
    }
`