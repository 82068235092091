import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NoConvSelected from '../../components/messenger/NoConvSelected'
import Conversations from '../../components/messenger/Conversations'
import { useNavigate, useLocation  } from 'react-router-dom'
import styled from 'styled-components'
import UserExtras from 'components/userProfile/UserExtras'
import ChurchExtras from 'components/userProfile/ChurchExtras'
import useGetWindowSize from 'hooks/useGetWindowSize'
import { logoutUser, resetUserForLogout } from 'features/auth/authSlice'
import { addAc } from 'features/auth/authSlice'
import { addAcFunc } from 'funcs/ac'

function Messenger() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { id, fullName } = location.state ?? 'none'
    const { user } = useSelector(state => state.auth )
    const { isConversationsError, conversationsErrorMessage, isCreateConversationSuccess, alreadyExists, alreadyExistsId, newConversation } = useSelector(state => state.conversations)
    const [width, height] = useGetWindowSize()


    useEffect(() => {

        if(isConversationsError && conversationsErrorMessage === 'Not logged in') {
            dispatch(logoutUser())
            dispatch(resetUserForLogout())
            navigate('/login')
            window.location.reload(false);
            
        } else if(id && id !== user._id){

            if(isCreateConversationSuccess && alreadyExists){
                // dispatch(chooseConversation({id: alreadyExistsId, fullName: data.fullName}))
                navigate(`/messages/${alreadyExistsId}`, {replace: true})

            }else if( isCreateConversationSuccess && !alreadyExists && newConversation ){
                navigate(`/messages/${newConversation}`, {replace: true})
            }
        }
    }, [ dispatch, alreadyExists, alreadyExistsId, id, isCreateConversationSuccess, navigate, newConversation, user, isConversationsError, conversationsErrorMessage ])
    
    useEffect(() => {

        const dis = () => dispatch(addAc({
          type: 'messages', 
        }))
    
        addAcFunc('messages', dis)
    
    }, [])


    return (
        
        <MessageContainer>
            {
                user.isOrganization ? 
                (
                    <ChurchExtras />
                )
                :
                (
                    <UserExtras />
                )
            }

            <Conversations /> {/*  this is on left side of page */}

            {
                width >= 768 && (
                    <MessagesWrap>
                        < NoConvSelected />  {/*  this is on right side of page */}
                    </MessagesWrap>         
                )
            } 
        </MessageContainer>
    )
}

export default Messenger

const MessageContainer = styled.div`
    display: flex;
    width: 1300px;
    flex-direction: row;
    justify-content: flex-start; 
    margin: 0 auto; 
    height: calc(100vh - 55px);
    @media (max-width: 868px) {
      width: 100%;
        
    }

`

const MessagesWrap = styled.div`
    width: 550px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: start;
    margin-left: 10px;
    height: 90vh;

`