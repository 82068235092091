// My members is for churches to see who their members are and remove if necessary later we will be able to message them from here too
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Loader from "loaders/Loader";
import { SettingsMainContainer, TopHeaderSettings } from '../settings.styled'
import MyMembersItem from './MyMembersItem';
import { getMembers, mbsReset } from '../../../features/memberships/membershipSlice'
import './members.css'
import { imgUrl } from 'features/constants';
import MemberSearch from './MemberSearch';
import styled from 'styled-components';
import GroupsIcon from '@mui/icons-material/Groups';
import { settingsLanguage } from '../settingsLanguage';

function MyMembers(props) {
  const dispatch = useDispatch()
  const [loadingMore, loadingMoreSet] = useState()
  const { user } = useSelector((state) => state.auth)
  const {  
    members, 
    isMemberSearchActive, 
    isMbsLoading, 
    isMbsSuccess, 
    isMbsError,
    totalMembers,
    filter,
    hasNextPage,
    nextPage,
    totalPages,
    currentPage,
    searchResults

  } = useSelector((state) => state.memberships2)


  useEffect(()=> {
    // NOTE: here we can think of a way to optimize the data retrieval
    dispatch(getMembers())  

  }, [dispatch])


  useEffect( () => () => { //cleanup
    dispatch(mbsReset())
  }, [] ); 


  const handleLoadMore  = () => {
    if(hasNextPage){

      loadingMoreSet(true)
      dispatch(getMembers({
        page: nextPage
      }))
    }
  }

  return (
      <SettingsMainContainer>

        <TopHeaderSettings>
          {
            settingsLanguage.memberManagement[user.preferredLanguage]
          }
        </TopHeaderSettings>

        <MemberTopContainer>
          <MemberSearch />

          {
              isMbsSuccess && (
                <span>  
                  {
                    settingsLanguage.members[user.preferredLanguage]
                  }: 
                   {' ' + totalMembers} 
                </span>                
              )
            }

        </MemberTopContainer>

        <MemberItemContainer>
          
          {
                isMbsLoading && !loadingMore ? 
                (
                  <Loader />
                )
                : isMbsSuccess ?
                ( members.map((member, index) => {
                  let min = 1
                  const max = 100
                  const rand = min + Math.random() * (max - min)
                  return (
                      <MyMembersItem
                          key={ member._id +  rand } 
                          index={index}
                          userId={member._id} 
                          fullName={member.fullName} 
                          image={member.profileImg.imagePath2}  
                      />
                  )
                })
                )
                :
                (
                  <div> Something went wrong </div>
                )
          }
          {hasNextPage ? 
            (
              <LoadMoreBtn onClick={handleLoadMore}>
                {
                  settingsLanguage.loadMore[user.preferredLanguage] 
                }
              </LoadMoreBtn>
            )
            : !isMemberSearchActive && !hasNextPage && members.length === 0 ?
            (
              <div className='noRequestsWrap'>
                <div> <GroupsIcon className="noRequestsIcon" /></div>
                <div className='noRequests'>
                  {
                    settingsLanguage.noMembers[user.preferredLanguage]
                  }
                </div>
              </div>
            )
            : isMemberSearchActive && searchResults.length === 0 &&
            (
              <div
                style={{
                  color: 'grey',
                }}
                >
                  {
                    settingsLanguage.noSearchResults[user.preferredLanguage]
                  }
                </div>
            )
          }
          </MemberItemContainer>
      </SettingsMainContainer>
  )
}

const MemberTopContainer = styled.div`
  width: 100%;
  display: flex; 
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;

    span{
      margin-bottom: 10px;
      font-size: 12px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

`

const LoadMoreBtn = styled.div`
  padding: 6px 40px;
  background-color: #00800094;
  border-radius: 5px;
  color: white;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  cursor: pointer;
`
const MemberItemContainer = styled.div`
  padding: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;
`

export default MyMembers