import styled from "styled-components"

export const MobilePageTitle = styled.h1`
    display: none;

    @media (max-width: 768px) {

        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: fit-content;
        color: var(--lightThemeFontColor);
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        /* margin-top: 15px; */
        /* margin-bottom: 15px; */
    }
`

export const NoItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    font-size: 20px;
    color: #ccc;

    svg{
        font-size: 50px;
        color: #ccc;
        margin-top: 20px;
        margin-bottom: 10px;
    }

`