import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import pollService from './pollService'

const errorMessage = (error) => {
    return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
  }

const initialState = {
  polls: [],

  isPollsError: false,
  isPollsSuccess: false,
  isPollsLoading: false,
  pollErrorMessage: '',

  
  isPollUpdatedSuccess: false,
  isPollUpdatedLoading: false,

  isPollDeleteSuccess: false,
  isPollDeleteLoading: false,

  isPollCreatedSuccess: false,
  isPollCreatedLoading: false,
  isPollCreatedError: false,
  pollCreatedErrorMessage: '',

  isVoteError: false,
  isVoteLoading: false,
  isVoteSuccess: false,
  voteErrorMessage: '',



  totalPolls: 0,
  totalPages: 0,
  hasPrevPage: false,
  nextPage: null,
  pageNumber: 0,
  prevPage: null,  
  hasNextPage: false,
  hasFilter: false,
  filterValue: null,
  hasSortValue: null,
  sortValue: null,

  showCreatePollModal: false,
}

export const createPoll = createAsyncThunk(
    'polls/create',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await pollService.createPoll(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const getPolls = createAsyncThunk(
    'polls/get',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await pollService.getPolls(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const castVote = createAsyncThunk(
    'polls/castVote',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await pollService.castVote(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const deletePoll = createAsyncThunk(
    'polls/deletePoll',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await pollService.deletePoll(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

export  const pollSlice = createSlice({
    name: 'poll',
    initialState,
    reducers: {
        pollReset: (state) => initialState,

        setShowCreatePollModal: (state) => { state.showCreatePollModal = true },

        setCloseCreatePollModal: (state) => { state.showCreatePollModal = false },

        softPollReset: (state) => {
          state.isPollCreatedSuccess = false
          state.isPollCreatedLoading = false
          state.pollCreatedErrorMessage = false
          state.isPollCreatedError = ''
        }
    },
    extraReducers: (builder) => {
      builder
        .addCase(createPoll.pending, (state) => {
          state.isPollCreatedLoading = true
        })
        .addCase(createPoll.fulfilled, (state, action) => {
          state.polls.unshift(action.payload)
          state.isPollCreatedLoading = false
          state.isPollCreatedSuccess = true
        })
        .addCase(createPoll.rejected, (state, action) => {
            state.isPollCreatedLoading = false
            state.isPollCreatedError = true
            state.pollCreatedErrorMessage = action.payload
        })
        //------------------
        .addCase(getPolls.pending, (state) => {
            state.isPollsLoading = true
        })
        .addCase(getPolls.fulfilled, (state, action) => {
            state.isPollsLoading = false
            state.isPollsSuccess = true

            if(action.payload.pageNumber === 1) {
              state.polls = action.payload.pollsArray
            }else{
              state.polls.push(...action.payload.pollsArray)
            }
 
            state.totalPolls = action.payload.totalPolls
            state.totalPages = action.payload.totalPages
            state.pageNumber = action.payload.pageNumber
            state.hasNextPage = action.payload.hasNextPage
            state.prevPage = action.payload.previousPage
            state.nextPage = action.payload.nextPage 
            state.sortValue = action.payload.sortValue

        })
        .addCase(getPolls.rejected, (state, action) => {
            state.isPollsLoading = false
            state.isPollsError = true
            state.pollErrorMessage = action.payload
        })
        //------------------
        .addCase(castVote.pending, (state) => {
            state.isVoteLoading = true
        })
        .addCase(castVote.fulfilled, (state, action) => {
            state.isVoteLoading = false
            state.isVoteSuccess = true
            state.polls[action.payload.index] = action.payload.updatedPoll

        })
        .addCase(castVote.rejected, (state, action) => {
            state.isVoteLoading = false
            state.isVoteError = true
            state.voteErrorMessage = action.payload
        })
        //------------------
        .addCase(deletePoll.pending, (state) => {
          state.isPollDeleteLoading = true
        })
        .addCase(deletePoll.fulfilled, (state, action) => {
            state.isPollDeleteLoading = false
            state.isPollDeleteSuccess = true
            state.polls = state.polls.filter(
              (poll) => poll._id !== action.payload.id
            )
        })
        .addCase(deletePoll.rejected, (state, action) => {
            state.isPollDeleteLoading = false
            state.isVoteError = true
        })
      //------------------
    },
})


export const { pollReset, setShowCreatePollModal, setCloseCreatePollModal, softPollReset } = pollSlice.actions
export default pollSlice.reducer
