import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux' 
import { setViewBulletinUploadForm} from 'features/bulletin/bulletinSlice'
import UploadBulletinModal from 'components/bulletin/UploadBulletinModal'
import BulletinFeed from 'components/bulletin/BulletinFeed'
import BulletinFile from 'components/bulletin/BulletinFile'
import CreateButton from 'components/buttons/CreateButton'
import { Modal } from '@mui/material'
import { MUIModalWrap } from 'components/modal/modal.styled'
import { setViewConfirmModal } from 'features/modal/modalSlice'
import ConfirmModal from 'components/modals/ConfirmModal'
import { MobilePageTitle } from 'components/main.styled'
import { bulletinLanugage } from 'components/bulletin/bulletinLanguage'

export default function Bulletin() {
    const { user } = useSelector((state) => state.auth)
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { 
        viewBulletinUploadForm, 
        viewBulletinFileInModal, 
        currentBulletinFileView,
        isDeleteBulletinLoading,
        isDeleteBulletinSuccess
    } = useSelector((state) => state.bulletin)
    const dispatch = useDispatch()
    const [isUserAdmin, setIsUserAdmin] = useState(false)

    const { confirmModalData, viewConfirmModal } = useSelector((state) => state.modal)

    const handleUploadBulletin = () => {
        dispatch(setViewBulletinUploadForm(true))
    }

    useEffect(() => {
        if(profileChurch){
            setIsUserAdmin(profileChurch.admins.includes(user._id))
        }
        
    }, [profileChurch, user._id])

  return (
    <BulletinContainer>

        <MobilePageTitle>
            {
                bulletinLanugage.bulletins[user.preferredLanguage]
            }
        </MobilePageTitle>

        { (user.isChurch || user.isOrganization || isUserAdmin) && (

            <CreateButton 
                handler={handleUploadBulletin}
                buttonText={
                    bulletinLanugage.uploadBulletin[user.preferredLanguage]
                }
                />
        )
        }

        <BulletinFeed />


        {
            viewBulletinFileInModal && (
                <BulletinFile file={currentBulletinFileView} />
            )
        }

        <Modal
            open={viewBulletinUploadForm}
            onClose={() => dispatch(setViewBulletinUploadForm(false))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <MUIModalWrap>
                <UploadBulletinModal  />
            </MUIModalWrap>
        </Modal>


        {/* {
            viewDeleteFileModal && (
               <DeleteFileModal />
            )
        }
 */}
        <Modal
            open={viewConfirmModal}
            onClose={() => dispatch(setViewConfirmModal(false))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <MUIModalWrap>
                <ConfirmModal 
                    text={confirmModalData.text} 
                    handler={confirmModalData.handler} 
                    confirmBtnBackgroundHover={confirmModalData.confirmBtnBackgroundHover}
                    extraInfo={confirmModalData.extraInfo}
                    doSomethingAfterClose={false}
                    loadingState={isDeleteBulletinLoading}
                    conditionToCloseModal={isDeleteBulletinSuccess}
                    /> 
            </MUIModalWrap>
        </Modal>



    </BulletinContainer>
  )
}

const BulletinContainer = styled.div`
    width: 100%;    
    padding: 10px;
    @media (max-width: 768px) {
        padding: 10px;
    }
`