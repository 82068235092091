
import {React, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { closeForgotPassword } from 'features/auth/authSlice' 
import { forgotPasswordMail, resetFPState } from 'features/mail/mailSlice'
import { ModalContainerMain } from 'components/modal/modal.styled'
import LockResetIcon from '@mui/icons-material/LockReset';
import  { setUserType } from 'features/auth/authSlice'
import RadioButton from 'components/utils/RadioButton'
import { FileUploadButton } from 'components/files/files.styled'
import SpinnerSmall from 'components/SpinnerSmall'
import ModalCloseButton from 'components/buttons/ModalCloseButton'


export default function ForgotPasswordModal() {

    const dispatch = useDispatch()
    const { userType } = useSelector((state) => state.auth)
    const { fpLoading, fpSuccess, fpError, fpMessage } = useSelector((state) => state.mail)
    const [email, setEmail] = useState('')
 

    const handleSubmit = () => {
        let emailType = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if ( emailType.test(email) ) {
            //send email with link to this email

            dispatch(forgotPasswordMail({
                email: email,
                userType: userType
            }))

        }else{
            return toast.error('Please enter an email', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
    }

    useEffect(() => {
        if(fpSuccess){
            dispatch(closeForgotPassword())
            toast.success(
                'Email with a link to reset your password has been sent.', {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }

        if(fpError){
            toast.error(fpMessage, {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })

            // dispatch(resetFPState())
        }
        

    }, [fpSuccess, fpError, fpMessage])


    return (
        <ModalContainerMain>
            <FPModalWrap>

                <ModalCloseButton
                    top='15px'
                    right='15px'
                    size='40px'
                    handler={() => dispatch(closeForgotPassword())}
                    />

                <ModalHeading> <LockResetIcon /> <span>Reset Password</span> </ModalHeading>

                <FPText>
                    Please enter the email associated with your account to change your password.                </FPText>

                <div style={{  
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'flex-start', 
                        width: '100%', 
                        marginTop: '10px'
                    }} 
                    >
                    <span style={{  fontSize: '12px', color: 'grey', marginBottom: '4px', marginTop: '10px'}}>
                        Who are you? 
                    </span>
                    <div style={{  display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                        <RadioButtonWrapper> 
                            <RadioButton 
                                
                                size={'20px'}
                                fillColor={'#2a6e96'}
                                checked={userType === 'church' ? true : false}
                                handler={() => dispatch(setUserType('church'))}
                                />
                            <RadioTitle
                                style={{marginLeft: '10px'}}
                                >
                                Church
                            </RadioTitle>
                        </RadioButtonWrapper>
                        <RadioButtonWrapper>
                            <RadioButton
                                size={'20px'}
                                fillColor={'#2a6e96'}
                                checked={userType === 'user' ? true : false}
                                handler={() => dispatch(setUserType('user'))}
                                />
                            <RadioTitle
                                style={{marginLeft: '10px'}}
                                >
                                Member User
                            </RadioTitle>
                        </RadioButtonWrapper>
                    </div>
                </div>

                <FPInputWrap>
                    <FPInput 
                        value={email} 
                        type={'email'} 
                        onChange={(e) => setEmail(e.target.value)} placeholder='Enter email' 
                        />
                </FPInputWrap>

                <BottomSection>
                    {/* <FPBtn
                        type={'cancel'}
                        onClick={() => dispatch(closeForgotPassword())}
                        >
                        Cancel
                    </FPBtn> */}
                    <FileUploadButton
                        disabled={fpLoading} 
                        width={fpLoading ? '150px' : 'fit-content'}
                        type={'submit'}
                        onClick={handleSubmit}
                        >
                        {
                            fpLoading ?
                            (
                                <SpinnerSmall />
                            ) 
                            : 
                            (
                                'Get Email Link'
                            )
                        }
                    </FileUploadButton>    
                </BottomSection>
            </FPModalWrap>  
        </ModalContainerMain>
    )
}

const RadioTitle = styled.div`
    font-size: 12px;
    margin-right: 5px;
    white-space: nowrap;
`

const RadioButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
`

const BottomSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 10px;
`

const FPInput = styled.input`
    padding: 10px;
    outline: none;
    border: none;
    width: 100%;
    border-radius: 10px;
    /* style placeholder */
    &::placeholder {
        color: #636363;
        font-size: 15px;
        opacity: 1; /* Firefox */
    }
    font-size: 15px;
`

const FPInputWrap = styled.div`
    margin-top: 25px;
    margin-bottom: 10px;
    width: 100%;
    padding: 11px;
    display: flex;
    align-self: flex-start;
    justify-self: flex-start;
    border: 1px solid #b6b6b6;
    border-radius: 5px;
`

const FPText = styled.span`
    display: flex;
    width: 100%;
    word-wrap: break-word;
    white-space: normal;
    text-align: left;
    line-height: 1.5;
    margin-top: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

`

const FPModalWrap = styled.div`
    z-index: 11;
    width: 600px;
    height: fit-content;
    background: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: start;
    border-radius: 8px;
    position: relative;
    animation: animate 0.3s;
    padding: 20px 40px;
    color: var(--lightThemeFontColor);

    @media (max-width: 768px) {
        width: 100%;
        min-height: unset;
        height: fit-content;
        padding: 20px 20px;
    }

`

const ModalHeading = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    margin-bottom: 10px;
    svg{
        margin-right: 10px;
        font-size: 30px;
    }
`
const FPBtn = styled.div`
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: ${({type}) => type === 'submit' ? '#ccc' : 'none'};

    &:hover{
        color: ${({type}) => type === 'submit' && 'white' };
        background-color: ${({type}) => type === 'cancel' ? 'none' : 'green'};
        text-decoration: ${({type}) => type === 'cancel' ? 'underline' : 'none'};
    }
`
