
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Conversations from '../../components/messenger/Conversations'
import StartConversation from '../../components/messenger/startConversation/StartConversation'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import ChurchExtras from 'components/userProfile/ChurchExtras'
import UserExtras from 'components/userProfile/UserExtras'
import useGetWindowSize from 'hooks/useGetWindowSize'


function NewConversation() {
    const navigate = useNavigate()
    const { user } = useSelector(state => state.auth )
    const [width, height] = useGetWindowSize()

    console.log(height)

    useEffect(() => {

        if(!user){ navigate('/login') }



    }, [user, navigate])

  return (
    <MessagesContainer>

      {user.isChurch || user.isOrganization ? (
        <ChurchExtras />
      )
      :
      (
        <UserExtras />
      )}

      {
        width > 768 && 
        (
          <Conversations />
        )
      }
      
      <StartConversation /> 


    </MessagesContainer>
  )
}

export default NewConversation

const MessagesContainer =styled.div`
    display: flex;
    width: 1300px;
    flex-direction: row;
    justify-content: flex-start; 
    margin: 0 auto;
    @media (max-width: 868px) {
      width: 100%;
      margin: 0;
    }

`