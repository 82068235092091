import React, {useRef} from 'react'
import styled from 'styled-components'
import { DotEle, DotsWrap } from 'components/dots/dots.styled';
import useToggleDropdown from 'hooks/useToggleDropdown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { setConfirmModalData, setViewConfirmModal2 } from 'features/modal/modalSlice';
import { removeMemberPaymentMethod } from 'features/giving/givingSlice';


export default function PaymentMethodBankItem({bank}) {

    const dispatch = useDispatch()
    const ref = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref)
    const handleDropDown = () => { setIsDropped.toggle(ref) }

    const { profileChurch } = useSelector((state) => state.profileChurch)

    const handleRemoveBank = () => {
        
        dispatch(setConfirmModalData({ 
            text: `You will no longer be able make payments associated with account ending in ${bank[bank.type].last4}?`,
            handler: () => { dispatch(removeMemberPaymentMethod({ 
                churchId: profileChurch._id,
                type: 'bank',   
                id: bank.id,
            }))  
        }
        }))

        dispatch(setViewConfirmModal2({ 
            view: true, 
            location: 'paymentMethodItem' 
        }))
    }

    return (
        <PMCItem>  
            <MoreHorizIcon 
                style={{ cursor: 'pointer', position: 'absolute', top: '10px', right: '10px'}}
                ref={ref} onClick={handleDropDown} />

            {
                isDropped && (
                    <DotsWrap>
                        <DotEle onClick={handleRemoveBank} >
                            <DeleteIcon  style={{ marginRight: '11px' }} /> 
                            Remove
                        </DotEle>  
                    </DotsWrap>
                )
            }
 
            <CardBottom>

                <BankName>{bank[bank.type].bank_name}</BankName>

                {/* {
                    bank[bank.type].financial_connections_account && 
                    (
                        <p style={{fontSize: '11px'}} >
                            financial connection account
                        </p>
                    )
                } */}

                <BankNumber>
                    ****  
                    {bank[bank.type].last4}
                </BankNumber>

            </CardBottom> 
        </PMCItem>
    )
}

const BankNumber = styled.div`
    font-size: 13px;
    font-weight: 500;
    margin-top: 15px; 
`

const CardBottom = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
`

const BankName = styled.div`
    font-size: 15px; 
    margin-bottom: 10px;
`

const PMCItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px;
    width: 350px;
    height: 120px; 
`