import React from 'react'
import styled from 'styled-components'
import {  useNavigate } from 'react-router-dom'; 
import useGetWindowSize from 'hooks/useGetWindowSize';


function FooterLP() {
    const navigate = useNavigate()
    const [screenWidth, screenHeight] = useGetWindowSize()
    let imageToShow;
    try{ imageToShow =  require('../../prayselyLogo.png')}
    catch(e){  imageToShow =  null  }

    const handleNav = (value) => {
        if(value === 'home'){
            navigate('/')
        }else{
            navigate(`/info/${value}`)
        }
    }

  return (
    <FooterContainer>
        <FooterLinks> 
            <FooterLinkElement onClick={() => handleNav('home')}>Home</FooterLinkElement>
            {
                screenWidth > 768 && <FooterLinkElement onClick={() => handleNav('about')}>About</FooterLinkElement>
            }
           
            <FooterLinkElement onClick={() => handleNav('privacy')}>Privacy Policy</FooterLinkElement>
            <FooterLinkElement onClick={() => handleNav('terms')}>Terms and Conditions</FooterLinkElement>
            <FooterLinkElement onClick={() => handleNav('feedback')}>Feedback and Support</FooterLinkElement>

            {
                screenWidth > 768 && <FooterLinkElement onClick={() => handleNav('pricing')}>Pricing</FooterLinkElement>
            }
            
        </FooterLinks>
        <img style = {{maringLeft: '40px', padding: '10px', width: '100px', height: 'auto'}} src={imageToShow} alt={'Praysely'} />
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: fit-content;
    width: 100%;
    background-color:  #2e2e2e;
    color: #9e9e9e;
    padding: 20px;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
    }

`
const FSection = styled.div`
    display: flex;
    flex-direction: column;
`

const FSectionTitle = styled.div`
    color: #9e9e9e;

`
const FooterLinks = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 13px;
  @media (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
    }
`
const FooterLinkElement = styled.div`
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  &:hover{
    /* background-color: #bebebe; */
    text-decoration: underline;
  }
`

const FooterLinkElementA = styled.a`
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    color: #9e9e9e;

    &:hover{
        /* background-color: #bebebe; */
        text-decoration: underline;
    }

`
export default FooterLP