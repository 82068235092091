import React from 'react'
import { PageWrapper, PageContainerMain } from '../pages.styled'
import { useSelector } from 'react-redux'
import ChurchExtras from 'components/userProfile/ChurchExtras'
import UserExtras from 'components/userProfile/UserExtras' 
import MarketSidebar from 'components/marketplace/MarketSidebar' 
import MySavedListingsContainer from 'components/marketplace/myListings/mySaved/MySavedListingsContainer'

export default function MyCreatedListingsPage() {
    
    const { user } = useSelector((state) => state.auth) 

    return (
        <PageWrapper>
            <PageContainerMain  > 
                <div className='churchProfileLeft'>
                {
                    user && (
                    user.isOrganization ?  ( <ChurchExtras /> ) : (  <UserExtras /> )
                    )
                } 
                </div>  

                <MarketSidebar />

                <MySavedListingsContainer />

            </PageContainerMain>
        </PageWrapper>
    )
}
