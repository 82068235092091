import './startConversation.css'
import {  useDispatch, useSelector } from 'react-redux'
import Spinner from '../../Spinner'
import ViewAllItem from './ViewAllItem'
import { useEffect } from 'react'
import { getMemberOf } from '../../../features/memberships/membershipSlice'
import styled from 'styled-components'
import MemberSearch from 'components/settings/memberships/MemberSearch'
import Loader from 'loaders/Loader'

function ViewAllMemberOF() {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth) 

    const {  
        members, 
        isMemberSearchActive, 
        isMbsLoading, 
        isMbsSuccess, 
        isMbsError,
        totalMembers,
        filter,
        hasNextPage,
        nextPage,
        totalPages,
        currentPage,
        searchResults,
        isMbsSearchSuccess
    
      } = useSelector((state) => state.memberships2)


  return (
    <ViewAllContainer>

        <ViewAllHeading>Search for church members</ViewAllHeading>

        <MemberSearch searchForFellowMembers={true} />

        <FellowMemberFeed>
            {
                isMbsLoading ?
                (
                    <Loader />
                )
                : isMbsSearchSuccess && searchResults ?
                (
                    searchResults.map((ele) => {
                        let min = 1
                        const max = 100
                        const rand = min + Math.random() * (max - min)
                        return (
                            <ViewAllItem 
                                isOtherMember={true}
                                key={ ele._id +  rand } 
                                userId={ele._id}
                                fullName={ele.fullName} 
                                username={ele.username} 
                                image={ele.profileImg.imagePath2}  
                            />
                        )
                    })
                )
                :
                (
                    <div>...</div>
                ) 
            }
        </FellowMemberFeed> 
    </ViewAllContainer>
  )
}

export default ViewAllMemberOF

const ViewAllHeading = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; 
    height: fit-content;
    font-size: 11px;
    color: rgb(100,100,100);
`
const ViewAllContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;  
`
const FellowMemberFeed = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start; 
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
    overflow-y: scroll;
    /* hide the scroll bar */
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }
`