import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const config = (token) => { return { 
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}`, }, 
  } }
  

const uploadVideo = async (data, token) => {
    const response = await axios.post(URL+'/api/videos/uploadVideo' , data, config(token))
    return response.data
}

const getVideos = async (data, token) => {
    const response = await axios.post(URL+'/api/videos/getVideos' , data, config(token))
    return response.data
}

const deleteVideo = async (data, token) => {
    const response = await axios.post(URL+'/api/videos/deleteVideo', data, config(token))
    return response.data
}

const updateVideo = async (data, token) => {
    const response = await axios.post(URL+'/api/videos/updateVideo', data, config(token))
    return response.data
}

const searchVideos = async (data, token) => {
    const response = await axios.post(URL+'/api/videos/searchVideos', data, config(token))
    return response.data
}

const getCurrentVideo = async (data, token) => {
    const response = await axios.post(URL+'/api/videos/getCurrentVideo', data, config(token))
    return response.data
}

const videoService = {
    uploadVideo, getVideos, deleteVideo, updateVideo, searchVideos, getCurrentVideo
}

export default videoService