import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Icon from '@mdi/react';
import { 
    mdiPostOutline, mdiCalendarMultiple, mdiBadgeAccountHorizontalOutline, mdiChartBoxOutline,
    mdiMessageTextFastOutline,mdiNewspaperVariantOutline, mdiBullhornOutline, mdiSecurity, 
    mdiVectorLink, mdiAccountVoice, mdiTools 
    } from '@mdi/js'; 
import AboutItem from './AboutItem';
import useGetWindowSize from 'hooks/useGetWindowSize';
import { landingPageStore } from 'app/landingPageStore';

import { BiDonateHeart } from "react-icons/bi";


function About() {
    const [width, height] = useGetWindowSize();
    const [iconSize, setIconSize] = useState(4);

    const { userType } = landingPageStore()

    useEffect(() => {
        if(width < 768){
            setIconSize(2)
        }else{
            setIconSize(4)
        }
    },[width])

    return (
        <AboutPrayselyContainer id='about'>
            <AboutPraysely>  
            <AboutItem 
                reverseRow = {false}
                iconComponent={<Icon path={mdiPostOutline} size={iconSize} />}
                itemName='Posts'
                h3Name = 'Prayer Requests, Testimonies, and Praises'
                userInfo = "By publishing prayer requests, testimonies and praises, you as a church member can rally the support of your church community. Knowing that many are praying for the same cause creates a sense of unity and shared purpose while reading and receiving feedback from others in response to posted testimonies or prayers can provide personal encouragement, comfort, and reassurance."
                churchInfo ="By facilitating shared prayer and testimonies, Praysely can help fortify the bond among your church members, fostering unity. Your church leadership can identify and address the needs of their congregation more effectively by keeping tabs on the posted prayer requests. In an increasingly digital world, having a platform tailored to spiritual needs helps churches maintain relevance and accessibility."
                paragraph ={"No more trying to remember what the prayer list contains. Ditch the email chains and group texts. Praysely makes it easy to share prayer requests and updates with your church family."}
                imagePath = {'/img/post.png'}
                />

            <AboutItem  
                reverseRow = {true}
                iconComponent={<BiDonateHeart fontSize={65} />}
                itemName='Giving'
                h3Name = 'Online Monetary Giving Made Easy'
                userInfo = "With Praysely, you can easily give to your church online. Whether you're tithing, giving an offering, or donating to a special cause, you can do it all from the comfort of your home. Giving online is not just about convenience; it's about being a good steward of your resources and supporting your church's mission and vision."
                churchInfo = "Praysely Giving is a tool that empowers your church to receive donations, tithes, and offerings securely and efficiently. By providing a seamless giving experience, your church can encourage generosity and stewardship among its members, ensuring that the church's mission and vision are supported and sustained."
                paragraph = {"Giving online is a convenient and secure way to support your church's mission and vision. Whether you're tithing, giving an offering, or donating to a special cause, Praysely makes it easy to give back to your church."}
                imagePath = {'/img/give.png'}
                />

            <AboutItem
                reverseRow = {false}
                iconComponent={<Icon path={mdiBadgeAccountHorizontalOutline} size={iconSize} />}
                itemName='Directory'
                h3Name = 'User Friendly Online Church Directory'

                // also "An online church directory from Praysely can act as a centralized, digital hub for church communities."
                userInfo="Being part of an online church directory empowers can empower you, as a church member, with instant access to your faith community. This isn't just about knowing who's who; it's about deepening connections, facilitating outreach, and having a support system available. This constant access to a faith-driven community can be incredibly empowering for your spiritual journey."
                churchInfo="It's more than just a list of names and numbers. It’s a tool that enables seamless connection among your members, promotes active engagement, and facilitates pastoral care. With Praysely's platform, the church isn't just maintaining a roster; it's fostering an interconnected community where every member can easily reach out, support, and minister to one another. "
                paragraph = {"A user-friendly directory enables church members to easily access and search for information about fellow congregants. From contact details to shared interests, this directory fosters a sense of belonging and helps strengthen the bonds that unite in faith."}
                imagePath = {'/img/directory.jpeg'}
                />

            <AboutItem
                reverseRow = {true}
                iconComponent={<Icon path={mdiCalendarMultiple} size={iconSize} />}
                itemName='Events'
                h3Name = 'Keep track of events and interest'
                churchInfo="A dedicated events calendar center for your church on Praysely allows this platform to become the 
                central heartbeat of your community's spiritual activities. By consolidating your church-related events, 
                from services to Bible studies to community outreach, in one easily accessible platform, your can help your
                 members be more in sync with events and guiding them towards  engagement and growth within your faith community."
                userInfo="Having access to your church's events calendar on Praysely means effortlessly integrating your church 
                activities into your daily lives. Respond and express your attendance interest to your church's events from commitments and community interactions, 
                to community outreach and bible studies, you can better remain engaged and participating in all you church events "
                paragraph = {"With this user-friendly platform, you're not just an attendee; you're an active participant in shaping the vibrant tapestry of your faith community. Join your church on this journey of shared experiences, spiritual enrichment, and meaningful connections."}
                imagePath = {'/img/event.png'}
                />


            <AboutItem  
                reverseRow = {false}
                iconComponent={<Icon path={mdiChartBoxOutline } size={iconSize} />}
                itemName='Polls'
                h3Name = 'Polling made simple'
                churchInfo = "We make it easy to create polls and surveys to get feedback from your church family. Conducting polls allows your church to tap directly into the collective sentiments, preferences, and needs of its congregation. By seeking input from members on various matters — from logistical considerations like service timings to deeper issues like sermon topics or community outreach priorities - your church demonstrates a commitment to inclusivity through a digital and active feedback mechanism"
                userInfo = "With just a few clicks, you can voice your thoughts on various topics, from upcoming event themes to preferred sermon topics. Witness the power of unity as your input merges with fellow believers, shaping the direction of your church community.Participating in your churchs polls gives you a voice, allowing you to actively shape the direction and priorities of your church."
                imagePath = {'/img/poll.png'}
                />

        </AboutPraysely>
        <MoreFeaturesWrap>

            <MoreFeaturesHeading>More Features</MoreFeaturesHeading>

            <MoreFeatures>  
                <FeatureItem>   
                    <FeatureIconWrap> <Icon path={mdiMessageTextFastOutline } size={2} /></FeatureIconWrap>
                    <FeatureItemName>Private Messaging</FeatureItemName> 
                </FeatureItem>
                <FeatureItem>
                    <FeatureIconWrap> <Icon path={mdiNewspaperVariantOutline } size={2} /></FeatureIconWrap>
                    <FeatureItemName>Weekly Church Bulletin</FeatureItemName>
                </FeatureItem>
                <FeatureItem>
                    <FeatureIconWrap> <Icon path={mdiBullhornOutline} size={2} /></FeatureIconWrap>
                    <FeatureItemName>Anouncements</FeatureItemName>
                </FeatureItem> 
                    <FeatureItem>
                        <FeatureIconWrap> <Icon path={mdiSecurity} size={2} /></FeatureIconWrap>
                        <FeatureItemName>Videos and Audio Sharing</FeatureItemName>
                    </FeatureItem>  
                    <FeatureItem>
                        <FeatureIconWrap> <Icon path={mdiVectorLink} size={2} /></FeatureIconWrap>
                        <FeatureItemName>File Hosting and Sharing</FeatureItemName>
                    </FeatureItem> 
                <FeatureItem>
                    <FeatureIconWrap> <Icon path={mdiAccountVoice} size={2} /></FeatureIconWrap>
                    <FeatureItemName>
                        {
                            userType === 'church' ? 'Recieve Feedback from members' : 'Provide Your Church Feedback'
                        }
                    </FeatureItemName>
                </FeatureItem> 
            </MoreFeatures>
        </MoreFeaturesWrap>
    </AboutPrayselyContainer>
  )
}

const MoreFeaturesWrap = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #6f952e;
`

const MoreFeaturesHeading = styled.h1`
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: white;
    width: 100%;
    text-align: center;
    font-size: 30px;
    margin-top: 20px;
`

const MoreFeatures = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    padding: 30px 100px ;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #6f952e;
    @media (max-width: 768px) {
        flex-flow: row wrap;
        padding: 10px;
    }
`
const FeatureItem = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        width: 30%;
        padding: 10px;
    }

`

const FeatureItemName = styled.h3`
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: white; 
    width: 100px;
    margin-top: 20px;
    font-size: 13px;
    @media (max-width: 768px) {
        font-size: 12px;
    }
`


const FeatureIconWrap = styled.div`
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: #80ac33;
    box-shadow: 1px 1px 5px 5px #607e26;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
`

const AboutPrayselyContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
`


const AboutPraysely = styled.div`
    height: fit-content;
    width: 100%;
    background-color: #6f952e;
    color: #2e2e2e;    
    font-weight: bold;
    padding-bottom: 50px;

`

export default About