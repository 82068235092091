import styled from 'styled-components';


export const DropDownListItemDiv = styled.div`
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    padding: 5px 25px 5px 15px;
    color: var(--lightThemeFontColor);
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;
    transition: .5s;

    &:hover {
        background-color: rgb(231, 231, 231); 
        
    }

`