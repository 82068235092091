import React from 'react'
import styled from 'styled-components'
import { CiBank } from "react-icons/ci";
import { useDispatch, useSelector } from 'react-redux';
import { addConnectBank, setViewAddConnectBankModal } from 'features/giving/givingSlice';
import AddCircleIcon from '@mui/icons-material/AddCircle';


export default function AddConnectBank() {

    const dispatch = useDispatch()

    const { connectAccount } = useSelector(state => state.giving)

    const handleClick = () => {
        dispatch(setViewAddConnectBankModal(true))
    }

    return (
        <ABBWrap>
            <span> Bank Account </span>
            <AddBankButton onClick={handleClick} > 
                <AddCircleIcon style={{fontSize: 20, color: 'white', marginRight: 5}}  />
                Add Bank Account
            </AddBankButton> 
        </ABBWrap>
    )
}

const ABBWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 

    span{
        font-size: 16px;
        margin-right: 15px; 
    } 
` 
const AddBankButton = styled.div`

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:  6px 10px;
    border-radius: 5px;
    background-color: #979797;
    color: white;
    cursor: pointer;
    text-align: center; 
    transition: all 0.3s ease-in-out;
    font-size: 14px;

    &:hover{
        background-color: green;
    } 

`