const punc = (string) => {
    let finalArray = []
    const words = string.split(' ')
    words.forEach( ele => {
          let worr = ele.charAt(0).toUpperCase() + ele.slice(1).toLowerCase();
          finalArray.push(worr)
    })
      return finalArray.join(' ')
};


  
exports.punc = punc;