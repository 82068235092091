import styled from 'styled-components';

export const MarketContainerMainContentWrapper = styled.div`
    width: 850px;

`


export const DropdownSelect = styled.div`
    width: fit-content;
    height: 35px;
    color: var(--lightThemeFontColor);
    border-radius: 6px;
    background-color: white;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid #ccc;
    cursor: pointer; 
    box-shadow: 0px 0px 2px #ccc;
`

export const DropdownTitle = styled.div`
    padding: 5px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    span{
        margin-right: 5px;
    }
    svg{
        margin-right: 5px; 
    }

`

export const DropdownMenuItems = styled.div`
    padding: 10px;
    border-radius: 0px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: 40px;
    left: 0px;
    width: fit-content ;
    background-color: white;
    box-shadow: 0px 0px 5px #ccc; 
`
export const DropdownItem = styled.div`
    padding: 5px 10px;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 5px;
    transition: all 0.5s;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    svg{
        margin-right: 10px;
    }

    &:hover{
        background-color: #e0e0e0;
    }
`

export const LocationInputWrap = styled.div`

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button{
        width: fit-content;
        height: 30px;
        outline: none;
        padding: 0px 15px;
        border: none;
        font-size: 10px;
        border-radius: 5px;
        background-color: #e0e0e0;
        color: #777777;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover{
            background-color: #cecece;
        }
    }

`