import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import givingCampaignService from './givingCampaignService'     

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

const dateStyle = {
    borderColor: "white",
    width: "100%",
    input: { color: '#868686' },
    "& .MuiInputLabel-root.Mui-focused": { 
      color: "#656565" 
    }, //styles the label
    "& .MuiOutlinedInput-root": {
     "&:hover > fieldset": { borderColor: "white" },
     height: "48px",
     borderRadius: "10px",
     backgroundColor: 'aliceblue',
     borderColor: "white"
    },
    ".MuiOutlinedInput-notchedOutline":{
    //   border: "none",
        borderColor: "transparent"
    }
}   

const initialState = {

    campaigns: [],

    singleCampaignData: null,

    createPaymentIntentLoading: false,
    createPaymentIntentSuccess: false,
    createPaymentIntentError: null,
    clientSecret: '',
    amount: 0,
    description: '',
    paymentGivingIntentData: null,


    getSingleCampaignLoading: false,
    getSingleCampaignSuccess: false,
    getSingleCampaignError: null,
    church: null,

    createGivingCampaignLoading: false,
    createGivingCampaignSuccess: false,
    createGivingCampaignError: null,

    getGivingCampaignsLoading: false,
    getGivingCampaignsSuccess: true,
    getGivingCampaignsError: null,

    viewCreateGivingCampaignModal: false,

    campaignDonations: [],
    getDonationsByCampaignLoading: false,
    getDonationsByCampaignSuccess: false,
    getDonationsByCampaignError: null,

    updateCampaignTotalsLoading: false,
    updateCampaignTotalsSuccess: false,
    updateCampaignTotalsError: null,

    errorField: {
        title: false,
        description: false,
        goal: false,
        endDate: false,
        image: false
    },


    // form data
    formStep: 1, 
    title: '',
    hasGoal: true,
    hasEndDate: false,
    endDate: '',
    goal: '',
    description: '',

    endDateInputStyle: dateStyle,   
    images: [],
    activeImageCarousel: 0,

    viewShareModal: false,
    //
}

export const createGivingCampaign = createAsyncThunk(
    'givingCampaign/createGivingCampaign',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingCampaignService.createGivingCampaign(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getGivingCampaigns = createAsyncThunk( 
    'givingCampaign/getGivingCampaigns',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingCampaignService.getGivingCampaigns(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getSingleCampaign = createAsyncThunk(
    'givingCampaign/getSingleCampaign',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingCampaignService.getSingleCampaign(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getSingleCampaignPublic = createAsyncThunk(
    'givingCampaign/getSingleCampaignPublic',
    async (data, thunkAPI) => {
        try { 
            return await givingCampaignService.getSingleCampaignPublic(data)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const createCampaignGivingIntentMembers = createAsyncThunk(
    'givingCampaign/createCampaignGivingIntentMembers',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await givingCampaignService.createCampaignGivingIntentMembers(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const createCampaignGivingIntentPublic = createAsyncThunk(
    'givingCampaign/createCampaignGivingIntentPublic',
    async (data, thunkAPI) => {
        try { 
            return await givingCampaignService.createCampaignGivingIntentPublic(data)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getDonationsByCampaign = createAsyncThunk(
    'givingCampaign/getDonationsByCampaign',
    async (data, thunkAPI) => {
        try {
            // token is not needed for this request
            return await givingCampaignService.getDonationsByCampaign(data)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const updateCampaignTotals = createAsyncThunk(
    'givingCampaign/updateCampaignTotals',
    async (data, thunkAPI) => {
        try {
            // token is not needed for this request
            return await givingCampaignService.updateCampaignTotals(data)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const givingCampaignSlice = createSlice({
    name: 'givingCampaign',
    initialState,
    reducers: { 
        setViewCreateGivingCampaignModal: (state, action) => { 
            state.viewCreateGivingCampaignModal = action.payload
        },
        setFormStep: (state, action) => {
            state.formStep = action.payload
        },
        setFormData: (state, action) => {
            state[action.payload.key] = action.payload.value
        },
        setGoal: (state, action) => {
            
            // format for currency with commas
            let formattedNumber = action.payload.value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")

            state.goal = '$ ' + formattedNumber 
        },
        setCampaignImages: (state, action) => {
            state.images.push(action.payload.image)
        },
        setSpliceImageArray: (state, action) => {
            state.images.splice(action.payload.index, 1)
        },
        setActiveImageCarousel: (state, action) => { 
            if(action.payload === 'right'){
                if(state.activeImageCarousel === state.images.length - 1){
                    state.activeImageCarousel = 0
                }
                else{
                    state.activeImageCarousel = state.activeImageCarousel + 1
                }
            }
            else{
                if(state.activeImageCarousel === 0){
                    state.activeImageCarousel = state.images.length - 1
                }
                else{
                    state.activeImageCarousel = state.activeImageCarousel - 1
                }
            }
        }, 
        setErrorField: (state, action) => { 
            state.errorField = {
                ...state.errorField,
                [action.payload.key]: action.payload.value
            } 
        },
        resetInitialState: (state) => {
            // reset the state to initial state
            state.campaigns = []
            state.createGivingCampaignLoading = false
            state.createGivingCampaignSuccess = false
            state.createGivingCampaignError = null
            state.getGivingCampaignsLoading = false
            state.getGivingCampaignsSuccess = true
            state.getGivingCampaignsError = null
            state.viewCreateGivingCampaignModal = false
            state.errorField = {
                title: false,
                description: false,
                goal: false,
                endDate: false,
                image: false
            }
            state.formStep = 1
            state.title = ''
            state.hasGoal = true
            state.hasEndDate = false
            state.endDate = ''
            state.goal = ''
            state.description = ''

            state.endDateInputStyle = dateStyle
            state.images = []
            state.activeImageCarousel = 0
        },
        setSingleCampaignData: (state, action) => {
            state.singleCampaignData = action.payload
        },
        resetCampaignIntent: (state) => {
            state.createPaymentIntentLoading = false
            state.createPaymentIntentSuccess = false
            state.createPaymentIntentError = null
            state.clientSecret = ''
            state.amount = ''
            state.description = ''
            state.paymentGivingIntentData = null

            // state.updateCampaignTotalsError = null
            // state.updateCampaignTotalsLoading = false
            // state.updateCampaignTotalsSuccess = false
 
        },

        resetCreateCampaign: (state) => {
            state.createGivingCampaignLoading = false
            state.createGivingCampaignSuccess = false
            state.createGivingCampaignError = null
            state.errorField = {
                title: false,
                description: false,
                goal: false,
                endDate: false,
                image: false
            }
            state.formStep = 1
            state.title = ''
            state.hasGoal = true
            state.hasEndDate = false
            state.endDate = ''
            state.goal = ''
            state.description = ''

            state.endDateInputStyle = dateStyle
            state.images = []
            state.activeImageCarousel = 0
        },
        
        resetCampaignPayment: (state) => {

            state.createPaymentIntentLoading = false
            state.createPaymentIntentSuccess = false
            state.createPaymentIntentError = null
            state.clientSecret = ''
            state.amount = ''
            state.description = ''
            state.paymentGivingIntentData = null

            state.updateCampaignTotalsError = null
            state.updateCampaignTotalsLoading = false
            state.updateCampaignTotalsSuccess = false
 
        },
        
        setCampaignLoading: (state, action) => {
            state[action.payload.key] = action.payload.value
        },
        setViewShareModal: (state, action) => {
            state.viewShareModal = action.payload
        }
    },
    extraReducers: (builder) => { 
        builder
            .addCase(getGivingCampaigns.pending, (state, action) => { 
                state.getGivingCampaignsLoading = true
                state.getGivingCampaignsSuccess = false
                state.getGivingCampaignsError = null
            })
            .addCase(getGivingCampaigns.fulfilled, (state, action) => { 
                state.getGivingCampaignsLoading = false
                state.getGivingCampaignsSuccess = true
                state.campaigns = action.payload.campaigns
                state.church = action.payload.church
            })
            .addCase(getGivingCampaigns.rejected, (state, action) => { 
                state.getGivingCampaignsLoading = false
                state.getGivingCampaignsSuccess = false
                state.getGivingCampaignsError = action.payload
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(createGivingCampaign.pending, (state, action) => { 
                state.createGivingCampaignLoading = true
                state.createGivingCampaignSuccess = false
                state.createGivingCampaignError = null
            })
            .addCase(createGivingCampaign.fulfilled, (state, action) => { 
                state.createGivingCampaignLoading = false
                state.createGivingCampaignSuccess = true
                state.viewCreateGivingCampaignModal = false
            })
            .addCase(createGivingCampaign.rejected, (state, action) => { 
                state.createGivingCampaignLoading = false
                state.createGivingCampaignSuccess = false
                state.createGivingCampaignError = action.payload
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(getSingleCampaign.pending, (state, action) => { 
                state.getSingleCampaignLoading = true
                state.getSingleCampaignSuccess = false
                state.getSingleCampaignError = null
            })
            .addCase(getSingleCampaign.fulfilled, (state, action) => { 
                state.getSingleCampaignLoading = false
                state.getSingleCampaignSuccess = true
                state.singleCampaignData = action.payload.campaign
                state.church = action.payload.church
            })
            .addCase(getSingleCampaign.rejected, (state, action) => { 
                state.getSingleCampaignLoading = false
                state.getSingleCampaignSuccess = false
                state.getSingleCampaignError = action.payload
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(getSingleCampaignPublic.pending, (state, action) => { 
                state.getSingleCampaignLoading = true
                state.getSingleCampaignSuccess = false
                state.getSingleCampaignError = null
            })
            .addCase(getSingleCampaignPublic.fulfilled, (state, action) => { 
                state.getSingleCampaignLoading = false
                state.getSingleCampaignSuccess = true
                state.singleCampaignData = action.payload.campaign
                state.church = action.payload.church
            })
            .addCase(getSingleCampaignPublic.rejected, (state, action) => { 
                state.getSingleCampaignLoading = false
                state.getSingleCampaignSuccess = false
                state.getSingleCampaignError = action.payload
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(createCampaignGivingIntentMembers.pending, (state, action) => { 
                state.createPaymentIntentLoading = true
                state.createPaymentIntentSuccess = false
                state.createPaymentIntentError = null
            })
            .addCase(createCampaignGivingIntentMembers.fulfilled, (state, action) => { 
                state.createPaymentIntentLoading = false
                state.createPaymentIntentSuccess = true

                state.paymentGivingIntentData = action.payload.paymentIntentData
                state.clientSecret = action.payload.clientSecret   
                state.amount = action.payload.amount
                state.description = action.payload.description
            })
            .addCase(createCampaignGivingIntentMembers.rejected, (state, action) => { 
                state.createPaymentIntentLoading = false
                state.createPaymentIntentSuccess = false
                state.createPaymentIntentError = action.payload
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(createCampaignGivingIntentPublic.pending, (state, action) => { 
                state.createPaymentIntentLoading = true
                state.createPaymentIntentSuccess = false
                state.createPaymentIntentError = null
            })
            .addCase(createCampaignGivingIntentPublic.fulfilled, (state, action) => { 
                state.createPaymentIntentLoading = false
                state.createPaymentIntentSuccess = true

                state.paymentGivingIntentData = action.payload.paymentIntentData
                state.clientSecret = action.payload.clientSecret   
                state.amount = action.payload.amount
                state.description = action.payload.description
            })
            .addCase(createCampaignGivingIntentPublic.rejected, (state, action) => { 
                state.createPaymentIntentLoading = false
                state.createPaymentIntentSuccess = false
                state.createPaymentIntentError = action.payload
            }) 
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(getDonationsByCampaign.pending, (state, action) => { 
                state.getDonationsByCampaignLoading = true
                state.getDonationsByCampaignSuccess = false
                state.getDonationsByCampaignError = null
            })
            .addCase(getDonationsByCampaign.fulfilled, (state, action) => { 
                state.campaignDonations = action.payload.donations
                state.getDonationsByCampaignLoading = false
                state.getDonationsByCampaignSuccess = true
                
            })
            .addCase(getDonationsByCampaign.rejected, (state, action) => { 
                state.getDonationsByCampaignLoading = false
                state.getDonationsByCampaignSuccess = false
                state.getDonationsByCampaignError = action.payload
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(updateCampaignTotals.pending, (state, action) => { 
                state.updateCampaignTotalsLoading = true
                state.updateCampaignTotalsSuccess = false
                state.updateCampaignTotalsError = null
            })
            .addCase(updateCampaignTotals.fulfilled, (state, action) => { 
                state.updateCampaignTotalsLoading = false
                state.updateCampaignTotalsSuccess = true
                state.singleCampaignData.totalDonations = action.payload.totalDonationsAmount
            })
            .addCase(updateCampaignTotals.rejected, (state, action) => { 
                state.updateCampaignTotalsLoading = false
                state.updateCampaignTotalsSuccess = false
                state.updateCampaignTotalsError = action.payload
            })

        }   
})

export const { 
    setGoal,
    setFormData, 
    setFormStep,  
    setErrorField,
    setViewShareModal, 
    setCampaignImages,
    resetInitialState,
    setCampaignLoading,
    resetCreateCampaign,
    resetCampaignIntent,
    setSpliceImageArray,
    resetCampaignPayment,
    setSingleCampaignData,
    setActiveImageCarousel,
    setViewCreateGivingCampaignModal, 
 } = givingCampaignSlice.actions

export default givingCampaignSlice.reducer
