import styled from "styled-components";

export const StyledBio = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;
    text-align: left;
    font-size: 13px;
    height: ${props => !props.blurBottom ? 'fit-content' : '250px'};
    mask-image: ${props => props.blurBottom ? 'linear-gradient(to bottom, black 50%, transparent 100%)' : 'none'};
    transition: height 1s ease-in-out;
    max-width: 800px;
    width: 100%;

    section{
        margin-bottom: 6px;
        text-align: left; 
    }

`

export const ShowMore = styled.div`
    width: 100%;
    color: #b5b5b5; 
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;

    &:hover{
        text-decoration: underline;
    }
    p{
        cursor: pointer; 
    }
`