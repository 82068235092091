import { useState, useEffect} from 'react'
import styled from 'styled-components'
import EventItem from './EventItem'
import { useSelector, useDispatch } from 'react-redux'
import { addAc } from 'features/auth/authSlice'
import { toast } from 'react-toastify'
import { getEvents,  } from '../../features/events/eventSlice' 
import ConfirmModal from 'components/modals/ConfirmModal'  
import Modal from '@mui/material/Modal';
import { setViewConfirmModal } from 'features/modal/modalSlice'
import Loader from 'loaders/Loader'
import { addAcFunc } from 'funcs/ac' 
import { eventLanguage } from './eventsLanguage'
import { NoItemsContainer } from 'components/main.styled' 
import { CiCalendar } from "react-icons/ci";
import SpinnerSmall from 'components/SpinnerSmall'
import SpinnerIntermintentSmall from 'loaders/SpinnerIntermintentSmall'

export default function EventFeed() {

  const dispatch = useDispatch()
  const { user, admin, moderator } = useSelector((state) => state.auth)
  const { profileChurch } = useSelector((state) => state.profileChurch) //if no profile data, it will fetch it
  const { viewConfirmModal, confirmModalData } = useSelector((state) => state.modal)
  const { isEventLoading, eventsList, isEventSuccess, eventMessage, isEventError } = useSelector((state) => state.events)


 // const page =  new URLSearchParams(location.search).get('myEvents') ? new URLSearchParams(location.search).get('section') : 'none'

  useEffect(() => {
    if(isEventError){toast.error(eventMessage)} 
  }, [dispatch, profileChurch.events, eventMessage, isEventError])

  useEffect(() => { 
    if(eventsList.length === 0){
      dispatch(getEvents({churchId: profileChurch._id}))
    }
  }, [])

  useEffect(() => {

    const dis = () => dispatch(addAc({
      type: 'events', 
    }))

    addAcFunc('events', dis)

  }, [])


  if(isEventLoading){
    return (
      
        <div
          style={{marginTop: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: '100%', width: '100%'}}
          >
            <SpinnerIntermintentSmall 
              color='var(--themeColor)'
              size={'50px'}

              />
        </div>
    )
  }

  return (
    <EventFeedContainer>

      { isEventSuccess ? 
        (
          eventsList.length > 0 ?
          (
            eventsList.map((ele, index) => (
              <EventItem 
                key={ele._id} 
                data={ele} index={index}
                isAdmin={admin[profileChurch._id]}
                isModerator={moderator[profileChurch._id]}
                />
            ))
          )
          :
          (
            <NoItemsContainer> 
                <CiCalendar />
                <div>
                  {
                    eventLanguage.noEvents[user.preferredLanguage]
                  }
                </div>     
            </NoItemsContainer>
          ) 
        )
        :
        (
            <div>Error loading directory</div>
        )
      }


      <Modal
        open={viewConfirmModal}
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        onClose={() => dispatch(setViewConfirmModal(false))}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <ConfirmModal 
          text={confirmModalData.text} 
          handler={confirmModalData.handler} 
          confirmBtnBackgroundHover={confirmModalData.confirmBtnBackgroundHover}
          extraInfo={confirmModalData.extraInfo}
          /> 
      </Modal>
    </EventFeedContainer>
  )
}


  
const EventFeedContainer = styled.div`
  width: 100%; 
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: 100%; 
  overflow-y: scroll;

    /* remove scroll bar width */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  &::-webkit-scrollbar {
      width: 0;
      height: 0;
  }
  /* border: 1px solid red; */
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%; 
    flex-wrap: unset;
    padding: 0px 10px;
    padding-top: 10px;
  }
`
