import React, { useEffect } from 'react'
import styled from 'styled-components'
import { RiSecurePaymentFill } from "react-icons/ri"; 
import {Elements, PaymentElement,  useStripe,  useElements } from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom'
import { updateCampaignTotals, setCampaignLoading, resetCampaignIntent } from 'features/givingCampaigns/givingCampaignSlice';
import { useDispatch, useSelector } from 'react-redux';
import SpinnerSmall from 'components/SpinnerSmall';


export default function ConfirmGivingPayment() {

    const stripe = useStripe();
    const elements = useElements();
    const { campaignId } = useParams()
    const dispatch = useDispatch()

    const { updateCampaignTotalsLoading, 
        paymentGivingIntentData,
        updateCampaignTotalsSuccess
    } = useSelector(state => state.givingCampaign)

    const handleConfirmPayment = async () => {

        dispatch(setCampaignLoading({
                key: 'updateCampaignTotalsLoading',
                value: true
        }))

        if (!stripe || !elements) { 
            console.log('stripe or elements not loaded')
            return  
        }

        
        const {error} = await stripe.confirmPayment({ 
            elements,
            confirmParams: {
                
            },
            redirect: 'if_required',
        }); 
        if (error) {  
            console.log(error)
        } else {  

            // here make sure we reset ANY payment intent data... And in other places too!
            dispatch(updateCampaignTotals({ 
                campaignId: campaignId,
                paymentIntentId: paymentGivingIntentData.id,
            })) 
        } 
    }

    useEffect(() => {
        if(updateCampaignTotalsSuccess){

 

            dispatch(resetCampaignIntent())
        }
    }, [updateCampaignTotalsSuccess])

    return ( 
        <CardElementWrap> 
            <CheckoutHeading>
                <RiSecurePaymentFill style={{fontSize: '20px', marginRight: '10px'}} />
                Secure Payment Details
            </CheckoutHeading>
            <PaymentElement  /> 

            <ConfrimPaymentBtn
                disbabled={updateCampaignTotalsLoading}
                onClick={handleConfirmPayment}
                >
                {
                    updateCampaignTotalsLoading ? 
                    (
                        <SpinnerSmall />
                    ) : (
                        'Confirm Payment'
                    )
                }
            </ConfrimPaymentBtn> 
        </CardElementWrap> 
    )
}

const CheckoutHeading = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--lightThemeFontColor);
    margin-bottom: 20px;
`


const CardElementWrap = styled.div` 
    padding: 14px;
    border-radius: 4px;
    background-color: white ;
    border: 2px solid #eaeaea;
    width: 100%;
    box-shadow: 0px 0px 5px #e7e7e7;

    @media (max-width: 768px){
        width: 100%;
        margin-top: 10px; 

    }

`
const ConfrimPaymentBtn = styled.div` 
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    color: var(--lightThemeFontColor); 
    width: 100%;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: center;
    background-color: var(--themeColor);
    color: white;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    margin-top: 10px; 
    box-shadow: 0px 0px 5px #ccc;
    text-align: center;
    &:hover{ 
        box-shadow: 0px 0px 5px #ccc;
    }
`
