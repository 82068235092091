import React, { useEffect } from 'react'
import { checkConnectAccountStatus, getConnectOnboardingLink } from 'features/giving/givingSlice'
import { useDispatch, useSelector } from 'react-redux'
import { SettingsMainContainer, TopHeaderSettings } from '../settings.styled' 
import CreateExternalBankAccount from './CreateExternalBankAccount'
import VerifiedConnectAccount from './VerifiedConnectAccount'
import styled from 'styled-components'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';


export default function Oboarding() {

    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { profileChurch } = useSelector(state => state.profileChurch)
    const { 
        connectAccount,
        onboardingLink
    } = useSelector(state => state.giving)


    useEffect(() => {
    }, [])



    return (
        <SettingsMainContainer>
            <StyledInfo>
                Onboarding for Praysely Giving is handled by 
                Stripe, Inc - A trusted payment processor. The form
                takes care of collecting identity verification information to safely start 
                receiving payments from your church members.  
                <br />
                <br />
                In order to start receiving payments, you will need to complete the onboarding process using the link provided below.
            </StyledInfo>

            <LinkButton
                onClick={() => window.open(onboardingLink, '_blank')}
                >
                <OpenInNewIcon />
                Praysely Giving Onboarding Link
            </LinkButton> 
        </SettingsMainContainer>
    )
}

const LinkButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 5px;
    background-color: #979797;
    color: white;
    cursor: pointer;
    text-align: center;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    transition: 0.3s ease-in-out;
    &:hover{
        background-color: #757575;
        color: white;
    }
    svg{
        margin-right: 15px;
    }

`

const StyledInfo = styled.div`
    padding: 30px;
    text-align: left;
    width: 100%;
    display: flex;
`
