import styled from "styled-components";

export const PageWrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;   

  @media (max-width: 768px) {
    flex-direction: column;
    height: 100%;
  }
`

export const ComponentPageWrapper = styled.div`
    width: 100%; 
    padding: 10px;
    display: flex; 
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;

    /* remove scrollbar */
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
    

    @media (max-width: 768px) { 
        width: 100vw;  
        padding: 10px;
        padding-top: 10px; 
    }
`

export const PageContainerMain = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;   
  height: calc(100vh - 60px); 
  width: fit-content;  
  transition: 0.8s ; 
  
  @media (max-width: 768px) {
    width: 100%;
    min-width: unset;
  }

`

export const UserProfileMain = styled.div`
  margin-left: 0px;
  width: 800px;
  display: flex; 
  flex-direction: column;  
  position: relative; 
  border-right: 1px solid rgb(200, 200, 200);
  height: 100%;
`

export const PrayerWallWrap = styled.div`
  width: ${props => props.width};
  transition: 0.8s ; 
  overflow: hidden; 
`

export const StyledSideBar = styled.div`
  width: ${props => props.width};
  transition: 0.8s ; 
  overflow: hidden; 

`

