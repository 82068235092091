import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
    makeMemberAnAdmin, 
    makeMemberAModerator, 
    removeMember, 
    removeAModerator, 
    removeAnAdmin,
    resetMemberLoadingState
} from '../../../features/memberships/membershipSlice'
import { imgUrl } from 'features/constants';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import styled from 'styled-components';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { setConfirmModalData, setViewConfirmModal } from 'features/modal/modalSlice';
import useToggleDropdown from 'hooks/useToggleDropdown';
import { useSelector } from 'react-redux';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { MemberNameWrap } from './memberships.styled';
import { ToolTipLabel } from 'components/utils/commonStyles.styled';
import ToolTip from 'components/utils/ToolTip';
import { settingsLanguage } from '../settingsLanguage';
import { Tooltip } from '@mui/material';

function MyMembersItem( { userId, fullName, image, index } ) {
//should be paginated since churches will potentially have a lot of members
//alos, allow to search your members
    const ref = useRef()
    const ref1 = useRef()
    const dispatch = useDispatch()
    const [isDropped, setIsDropped] = useToggleDropdown(ref1)
    const [isAdmin, setIsAdmin] = useState(false)
    const [isModerator, setIsModerator] = useState(false)
    const { user } = useSelector((state) => state.auth)

    const { 
        memberActionSuccess, 
        removeFromChurchLoading, 
        removeFromChurchSuccess, 
        admins, 
        moderators 
    } = useSelector((state) => state.memberships2)
  
    const handleDropDown = () => { 
        setIsDropped.toggle(ref1) 
    }
    
    useEffect(() => {        
        if(admins.includes(userId)){
            setIsAdmin(true)
        }else if(moderators.includes(userId)){
            setIsModerator(true)
        }
    }, [admins, moderators, userId, memberActionSuccess])


    const handleRemove = () => {
        dispatch(setConfirmModalData( {
            text: `${settingsLanguage.remove[user.preferredLanguage]} ${fullName} ${settingsLanguage.fromMembership[user.preferredLanguage] }?`, 
            handler: () => dispatch(removeMember( { userId: userId } )),
            confirmBtnBackgroundHover: 'red',
            
        } ))
        
        dispatch(setViewConfirmModal(true))
        // dispatch(removeMember( { userId: userId } ))
    }
      
    const handleRemoveModerator = () => {
        dispatch(setConfirmModalData( {
            text: `${settingsLanguage.remove[user.preferredLanguage]} ${fullName} ${settingsLanguage.removeModerator[user.preferredLanguage] }?`,
            handler: () => dispatch(removeAModerator( { userId: userId } )),
            confirmBtnBackgroundHover: 'red',
        } ))
        dispatch(setViewConfirmModal(true))
    }

    const handleRemoveAdmin = () => {
        dispatch(setConfirmModalData( {
            text: `${settingsLanguage.remove[user.preferredLanguage]} ${fullName} ${settingsLanguage.removeAdmin[user.preferredLanguage] }?`,
            handler: () => dispatch(removeAnAdmin( { userId: userId } )),
            confirmBtnBackgroundHover: 'red',
        } ))
        dispatch(setViewConfirmModal(true))
    }
  
    const handleMakeAdmin = () => {
        dispatch(setConfirmModalData( {
            text: `${settingsLanguage.make[user.preferredLanguage]} ${fullName} ${settingsLanguage.admin[user.preferredLanguage]}?`,
            handler: () => dispatch(makeMemberAnAdmin({ 
                userId: userId,
                index: Number(index)
            })),
            confirmBtnBackgroundHover: 'green',
        } ))
        
        dispatch(setViewConfirmModal(true))
    }     

    const handleMakeModerator = () => {
        dispatch(setConfirmModalData( {
            text: `${settingsLanguage.make[user.preferredLanguage]} ${fullName} ${settingsLanguage.moderator[user.preferredLanguage]}?`,
            handler: () => dispatch(makeMemberAModerator( { userId: userId } )),
            confirmBtnBackgroundHover: 'green',
        } ))

        dispatch(setViewConfirmModal(true))
    }

    useEffect(() => {
        if(removeFromChurchSuccess){
            dispatch(setConfirmModalData({}))
            dispatch(setViewConfirmModal(false))

            dispatch(resetMemberLoadingState({
                valuesToResetArray: ['removeFromChurchSuccess', 'removeFromChurchLoading']
            }))

        }
    }, [removeFromChurchSuccess, dispatch])



    let imageToShow; try{ imageToShow =  `${imgUrl}/profile/${image}`} catch(e){  imageToShow =  null}


    return (
        <div className='myMembersItemWrap'>
                <div className='mMIInfo'>
                    {
                        imageToShow ? (
                            <img className='reqItemImage'  src={imageToShow} alt='none' />
                        )
                        :
                        (
                            <img className='reqItemImage'  src={`${imgUrl}/profile/generic/2024-01-01T14-13-49.160Z-person.png`} alt='none' />
                        )
                    }
                    
                    <MemberNameWrap>
                        <div className='mMIFullName'>{fullName}</div>
                        {
                            isAdmin ? 
                            (
                                <Tooltip 
                                    title={settingsLanguage.admin[user.preferredLanguage]} 
                                    placement='top'>
                                    <AdminPanelSettingsIcon style={{color: 'green'}} /> 
                                </Tooltip>
                            )
                            : isModerator && 
                            ( 
                                <Tooltip
                                    placement='top'
                                    title={settingsLanguage.moderator[user.preferredLanguage]}
                                    >
                                    <SupervisorAccountIcon style={{color: '#73ac73'}}/> 
                                </Tooltip>
                            )
                        }    
                    </MemberNameWrap>  
                </div>


                <div ref={ref1}  className='mMIDots'>
                    <div  onClick={handleDropDown}>< MoreHorizIcon   />   </div>
                    { isDropped && (
                            <ul className='memberDotsWrap'>
                                <MemberMenuItem onClick={handleRemove} > 
                                    <PersonRemoveIcon />  
                                    <span style={{marginLeft: '11px'}}>
                                        {settingsLanguage.removeUser[user.preferredLanguage]}
                                    </span> </MemberMenuItem>
                                {
                                    isAdmin ?
                                    (
                                        <MemberMenuItem onClick={handleRemoveAdmin} > 
                                            <AdminPanelSettingsIcon />  
                                                <span>
                                                    {
                                                        settingsLanguage.removeUserAdmin[user.preferredLanguage]
                                                    }
                                                </span> 
                                            </MemberMenuItem>
                                    )
                                    : isModerator ?
                                    (
                                        <MemberMenuItem onClick={handleRemoveModerator}> 
                                            <SupervisorAccountIcon />  
                                            <span>
                                                {
                                                    settingsLanguage.removeUserModerator[user.preferredLanguage]
                                                }
                                            </span> 
                                        </MemberMenuItem>
                                    )
                                    : !isAdmin && !isModerator && (
                                        <>
                                            <MemberMenuItem onClick={handleMakeAdmin}> 
                                                <AdminPanelSettingsIcon />  
                                                <span>
                                                    {
                                                        settingsLanguage.makeUserAdmin[user.preferredLanguage]
                                                    }
                                                </span> 
                                            </MemberMenuItem>
                                            <MemberMenuItem onClick={handleMakeModerator}> 
                                                <SupervisorAccountIcon />  
                                                <span>
                                                    {
                                                        settingsLanguage.makeUserModerator[user.preferredLanguage]
                                                    }
                                                </span> 
                                            </MemberMenuItem>  
                                        </>                                  
                                    )

                                }

                            </ul>
                        )
                    }
                </div> 
        </div>
    )
}

export default MyMembersItem

const MemberMenuItem = styled.li`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    padding: 5px 10px;
    color: rgb(103, 103, 103);
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    span{
        margin-left: 10px;
    }
    &:hover{
        background-color: rgb(228, 228, 228);
    }
`