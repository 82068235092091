
import { useSelector, useDispatch } from 'react-redux'
import { TopHeaderSettings, CenterSettingsContainer } from './settings.styled';
import { setViewResetItemModal } from 'features/settings/settingSlice';
import ResetSettingsItemModal from './accountInformation/ResetSettingsItemModal';
import { SettingsInputContainer, SettingsSubmitButton, SettingsBody } from './settings.styled';

function UpdateEmail(props) {

  const dispatch = useDispatch()
  const { profile } = useSelector((state) => state.profile)
  const { viewResetItemModal, isResetEmailSuccess, newEmail } = useSelector((state) => state.settings)

  // once the user has been verified, we can then send the html form from backend




  return (
    <CenterSettingsContainer>
      <TopHeaderSettings > Update Email </TopHeaderSettings>

      <SettingsBody>
        <SettingsInputContainer> 
          <input
            onFocus={() => dispatch(setViewResetItemModal(true))}
            value={ isResetEmailSuccess ? newEmail : profile.email} 
            name='email' 
            placeholder={ isResetEmailSuccess ? newEmail : profile.email} 
            disabled={viewResetItemModal ? true : false}
          />
          <SettingsSubmitButton 
            onClick={() => dispatch(setViewResetItemModal(true))}
          >
            Update
          </SettingsSubmitButton>
        </SettingsInputContainer>

      </SettingsBody>

      { viewResetItemModal && ( <ResetSettingsItemModal item={"Email"} /> ) }

    </CenterSettingsContainer>
  )
}

export default UpdateEmail