import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import { setViewShareModal } from 'features/givingCampaigns/givingCampaignSlice'
import { baseUrl } from 'features/constants'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

export default function ShareCampaignModal() {

    const dispatch = useDispatch()
    const { viewShareModal, singleCampaignData } = useSelector(state => state.givingCampaign)
    const [isLinkCopied, setIsLinkCopied] = React.useState(false)

    const handleCopyLink = () => {
        navigator.clipboard.writeText(`${baseUrl}/campaign/${singleCampaignData._id}`)
        setIsLinkCopied(true)
    }

 

    return (
        <ShareModal>
            <ModalCloseButton
                handler={
                    () => dispatch(setViewShareModal(false))
                }
                top='20px'
                right='20px'
                size='30px'
                />  

            <h1>Share Campaign</h1>

            <SCMain>

                <FInput>
                    {
                        `https://www.praysely.com/campaign/${singleCampaignData._id}`
                    } 
                </FInput> 
            </SCMain>

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center', 
                
                }}
                >
                <CopyLinkButton
                        onClick={handleCopyLink}
                    >
                        
                        {
                            isLinkCopied ? 
                            (
                                 <>
                                    <AssignmentTurnedInIcon style={{marginRight: '5px'}}/>
                                    <span>Link Copied!</span>
                                </>
                            )
                            :
                            (
                                <>
                                    <ContentCopyIcon style={{marginRight: '5px'}}/>
                                    Copy Link
                                </>
                            )
                        }
                </CopyLinkButton>                    
            </div> 
         </ShareModal>
    )
}

const CopyLinkButton = styled.button`
    margin-top: 20px;
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--themeColor);
    color: white;
    border: none;
    border-radius: 5px; 
    cursor: pointer;
    transition: 0.3s;
    &:hover{
        background-color: var(--themeColor);
    }
`

const FInput = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #bec8ff;
    /* display an ellipsis on overflow text */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const SCMain = styled.div`

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    background-color: aliceblue;
    /* box-shadow: 0px 0px 5px #ccc; */
    height: 100px;
    margin-top: 30px;
`

const ShareModal = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 600px;
    height: fit-content;
    background-color: white;
    color: var(--lightThemeFontColor);
    border-radius: 10px;
    padding: 20px;
    h1{
        font-size: 18px;
        margin-bottom: 20px;
    }

    @media (max-width: 760px){
        width: 100%;
    }

`