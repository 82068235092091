import React from 'react'
import styled from 'styled-components'
import TimeAgo from 'timeago-react'
import { useDispatch } from 'react-redux'
import { setViewFeedbackItemModal } from 'features/profileChurch/profileChurchSlice'

export default function FeedbackItem({data}) {

    const dispatch = useDispatch()

    const handleView = () => {
        dispatch(setViewFeedbackItemModal({
            view: true,
            data: data
        }))
    }


    return (
        <FeedbackItemContainer
            onClick={handleView}
            >

            <ContentText>
                {data.content}
            </ContentText>

            <DateAgo>
                <TimeAgo datetime={new Date(data.createdAt)} />
            </DateAgo>

        </FeedbackItemContainer>
    )
}

const FeedbackItemContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; 
    padding: 11px;
    border-radius: 5px;
    height: 50px;
    cursor: pointer;
    
    &:hover{
        background-color: #ffffff;
        transition: all 0.4s ease-in-out;
    
    }
`
const DateAgo = styled.div`
    font-size: 11px;
    color: #888;
    margin-top: 5px;
    height: 100%;
    max-width: 20%; 
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start; 
`

const ContentText = styled.div`
    font-size: 13px;
    color: var(--lightThemeFontColor);
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80%; 

`