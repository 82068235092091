import {useState, useEffect, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { 
  register,
  resetAuth,
  verifyEmailAvailable, 
  setViewEmailConfirmModal, 
  sendVerificationEmailCode,
  resetEverythingButUser,
  setErrorField
} from '../../features/auth/authSlice'
import { FormWrap, FormElement, FormHeading, Available, NotAvailable, InputWrap, SubmitButton, NonSubmittableButton, RegisterButton } from './forms.styled'

import {ImCheckboxChecked} from 'react-icons/im';
import {MdCancel} from 'react-icons/md';
import AlreadyHaveAccount from 'components/register/AlreadyHaveAccount'
import AcceptTerms from './AcceptTerms'
import SpinnerSmall from 'components/SpinnerSmall'


export default function UserRegisterForm() {

    const checkMark = <ImCheckboxChecked style={{color: 'green'}} />
    const notValid = <MdCancel style={{color: 'red'}} />
    const emailInputRef = useRef();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // const [errorField, setErrorField] = useState('')
    const [email, setEmail] = useState('')
    const [emailValid, setEmailValid] = useState()
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [passwordValid, setPasswordValid] = useState()
    const [passwordValid2, setPasswordValid2] = useState()
    const [isRegisterUserLoading, setIsRegisterUserLoading] = useState(false)
    const [isEmailEdited, setIsEmailEdited] = useState(false)
    const [isEmailVerifyDispatched, setEVD] = useState(false)

    const { 
       isError, isSuccess, message, errorField,isEmailCodeVerificationSuccess,
      isEmailAvailable, codeVerified, isAvailableError, isAvailableLoadingType,
      isRegisterSuccessChurch, isRegisterSuccessUser, termsAccepted
    } = useSelector( (state) => state.auth )
  

    useEffect(() => { }, [password, password2, passwordValid, passwordValid2])

    const onChange = (e) => {
        setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }))
      }

      const handleEmailChange = (e) => {
        if(!isEmailEdited){
          setIsEmailEdited(true)
        }
        let emailType = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setEmail(e.target.value)
        if ( emailType.test(e.target.value) ) {
          setEmailValid(true)
        }
        else {
          setEmailValid(false)
        }
      }

      useEffect(() => {
        if(isEmailEdited && ( email.length > 4 ) && emailValid){

          dispatch(verifyEmailAvailable({
            userType: 'user',
            email: email,
          }))

          setIsEmailEdited(false)
          setEVD(true)
        }else if(isEmailEdited && !emailValid){
          setIsEmailEdited(false)
          setEVD(false)
        }
      }, [isEmailAvailable, isEmailEdited, dispatch, email, emailValid])

      const handlePassword = (e) => {
        setPassword(e.target.value)
        if(password.length > 5){ setPasswordValid(true) }
      }

      const handlePassword2 = (e) => {
        setPassword2(e.target.value)
        if(password === e.target.value){ setPasswordValid2(true) }
        else if(e.target.value.length !== password.length){ setPasswordValid2(false) }
      }

      const [formData, setFormData] = useState({
        fullName: '',  
      })

      const { fullName } = formData

      useEffect(() => {
        if (isError) { toast.error(message)}
        // if (isSuccess || user) { navigate('/')} 01/12/2023
        if (isRegisterSuccessChurch || isRegisterSuccessUser) { 
          
          navigate('/login')
          toast.success('Account Created, Login to Continue!')
          dispatch(resetAuth())
        }
        // dispatch(resetUser())
      }, [isError, isSuccess, message, navigate, isRegisterSuccessChurch, isRegisterSuccessUser, dispatch])

      const handleSubmit = () => {
        if(fullName.length < 3){
          setErrorField('fullName')
          return(toast.error('Enter a Valid Name'))
        }else{
          let firstChar = fullName.charAt(0)
          if(!firstChar.match(/[a-z]/i)){
            setErrorField('fullName')
            return(toast.error('Name must start with an alphabet letter'))
          }
        }



        if(email.length < 1 || !emailValid || !isEmailAvailable){
          setErrorField('email')
          return(toast.error('Enter a Valid Email address'))
        }

        if(password.length < 8 || password2.length < 8){
          setErrorField('password')
          return(toast.error('Passwords must be at least 8 characters'))
        }
        if (password !== password2) {
          setErrorField('password')
          return(toast.error('Passwords do not match')) 
        }

        const userData = {
            fullName, email, password, password2,       
          }
    
        setErrorField('')

        dispatch(register(userData))

      }

      const handleSendEmailVerificationCode = () => {

        if(fullName.length < 3){
          dispatch(setErrorField('fullName'))
          return(toast.error('Enter a Valid Name'))
        }

        if(termsAccepted === false){
          dispatch(setErrorField('terms'))
          return(toast.error('You must accept the terms and conditions'))
        }

        if(email.length < 1 || !emailValid || !isEmailAvailable){
          dispatch(setErrorField('email'))
          return(toast.error('Enter a Valid Email address'))
        }

        if(password.length < 8 || password2.length < 8){
          dispatch(setErrorField('password'))
          return(toast.error('Passwords must be at least 8 characters'))
        }
        if (password !== password2) {
          dispatch(setErrorField('password'))
          return(toast.error('Passwords do not match')) 
        }



        dispatch(setViewEmailConfirmModal({
          email: email
        }))

        dispatch(sendVerificationEmailCode({
          fullName: fullName,
          email: email,
        }))

        setIsRegisterUserLoading(true)
        setErrorField('')
      }

      useEffect(() => {
        // if the email verification is a success, then call handleSubmit
        if(isEmailCodeVerificationSuccess && codeVerified){
          handleSubmit()
        }
      }, [isEmailCodeVerificationSuccess, codeVerified])

      useEffect(( ) => {  

        return () => {

          console.log('unmounting user register form')

          dispatch(resetEverythingButUser())
          
        }
      }, [])

  return (
    <FormWrap>
      
        <FormElement isError={errorField === 'fullName' ? ('red'):(null) }>
            <FormHeading>Full Name</FormHeading>
            <input 
                type={'text'} 
                id='fullName'
                name='fullName'
                value={fullName}
                placeholder='Enter Your Full Name'
                onChange={onChange}
                autoComplete='off'
            /> 
        </FormElement>
    
        <FormElement isError={errorField === 'email' ? ('red'):(null) }> 
            <FormHeading>
              <span>Email</span> 
              {  ( emailValid && !isEmailAvailable && isEmailVerifyDispatched) || isAvailableError ? 
                (
                  <NotAvailable>Not Available</NotAvailable>
                )
                :emailValid && isAvailableLoadingType === 'email' ? (
                  <span>verifying...</span>
                )
                :emailValid && isEmailAvailable &&
                (
                  <Available>Available</Available>
                )
              } 
            
            </FormHeading>
            <InputWrap>
            { codeVerified ? 
              (
                <input value={email} readOnly/>
              )
              :
              (
                <input
                      ref={emailInputRef}
                      type='text'
                      className='form-control'
                      id='email'
                      name='email'
                      value={email}
                      placeholder='Enter your email'
                      onChange={handleEmailChange}
                      autoComplete='off'
                    />
              )
            }

            </InputWrap>

        </FormElement>

       
        <FormElement  isError={errorField === 'password' ? ('red'):(null) } >
            <FormHeading>Password</FormHeading>
            <InputWrap>
              <input
                    type='password'
                    className='form-control'
                    id='password'
                    name='password'
                    value={password}
                    placeholder='Enter password'
                    onChange={handlePassword}
                    autoComplete='off'
                  />
              <span>  { passwordValid ? (checkMark): password.length > 4 ? (notValid):('')} </span>
            </InputWrap>
        </FormElement>
        { !codeVerified && (
          <FormElement  isError={errorField === 'password' ? ('red'):(null) }  >
              <FormHeading>Confirm Password</FormHeading>
              
              <InputWrap>
                <input
                    type='password'
                    className='form-control'
                    id='password2'
                    name='password2'
                    value={password2}
                    placeholder='Confirm password'
                    onChange={handlePassword2}
                    autoComplete='off'
                    />
                <span>  { passwordValid2 ? (checkMark): password2.length > 4 ? (notValid):('')} </span>
              </InputWrap>
          </FormElement>
        )}

        { !codeVerified && <AcceptTerms /> }

        { isEmailAvailable && emailValid && !codeVerified ? 
          (
            <SubmitButton onClick={handleSendEmailVerificationCode}>
              Continue
            </SubmitButton>
          )
          : isRegisterUserLoading ?
          (
            <RegisterButton onClick={handleSubmit}>
              <SpinnerSmall />
            </RegisterButton>
          )
          :
          (
            <NonSubmittableButton>
              Continue
            </NonSubmittableButton>
          )
        }
        
        { !codeVerified && 
          (
            <AlreadyHaveAccount />
          )
        }
        


    </FormWrap>
  )
}
