import React, { useEffect, useState } from 'react'
import { ModalContainerMain } from 'components/modal/modal.styled'
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { setViewStartConversationModal, getChurchMembers, searchMembers } from 'features/messenger/conversationSlice' 
import ViewAllItem from './ViewAllItem'
import SearchIcon from '@mui/icons-material/Search';
import Loader from 'loaders/Loader'


export default function StartConversationModal() {

    const dispatch = useDispatch()
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [searchActive, setSearchActive] = useState(false)
    const [churchMembers, setChurchMembers] = useState( JSON.parse(sessionStorage.getItem('churchMembers')))
    const { 
        users, church, churches, isGetChurchMembersLoading, isGetChurchMembersSuccess, isCreateConversationSuccess,searchLoading , isSearchSuccess,
        totalMembers, totalPages, pageNumber, hasNextPage, nextPage, filter, searchResults, searchUserResults, isGetChurchMembersError
    } = useSelector(state => state.conversations)
    const { user } = useSelector(state => state.auth)

    const handleLoadMore = () => {
        setIsLoadingMore(true)
        dispatch(getChurchMembers({nextPage: nextPage}))
    }

    useEffect(() => {
       // get session storage data of fellow users 

        if(churchMembers){
            // do something
        }else{

            dispatch(getChurchMembers())

        }

    }, [])

    useEffect(() => {
        if(isCreateConversationSuccess){
            dispatch(setViewStartConversationModal(false))
        }
    }, [isCreateConversationSuccess])

    const handleSearch = (e) => {

        setSearchTerm(e.target.value)

        if(e.target.value.length > 1) {
            setSearchActive(true)
            dispatch(searchMembers({searchTerm: e.target.value}))
        }else{
            setSearchActive(false)
        }
    }

    return (
        <ModalContainerMain> 
            <ModalWrap>
                <ModalCloseButton handler={() => dispatch(setViewStartConversationModal(false))} />

                <MainContent>
                    <h1>New Message</h1>    

                    <SearchNewMessage >
                         <SearchIcon />
                         <input value={searchTerm} type="text" placeholder="Search church members" onChange={handleSearch} />
                    </SearchNewMessage>
 
                    <SearchNewMessageFeed>
                        <section>

                            {
                                ((isGetChurchMembersLoading && !isLoadingMore) || searchLoading) &&
                                (
                                    <Loader />
                                ) 
                            }

                            {
                                (searchActive && isSearchSuccess) ?
                                (

                                    searchUserResults.length > 0 ?
                                    (
                                        searchUserResults.map((ele) => {
                                            let min = 1
                                            const max = 100
                                            const rand = min + Math.random() * (max - min)

                                            return (
                                                <ViewAllItem 
                                                    isOtherMember={true}
                                                    key={ ele._id +  rand } 
                                                    userId={ele._id}
                                                    fullName={ele.fullName}  
                                                    image={ele.profileImg.imagePath2}  
                                                    />
                                            )
                                        })                                          
                                    )
                                    :
                                    (
                                        <p>No results found</p>
                                    )


                                )
                                : isGetChurchMembersSuccess && 
                                (
                                    <>


                                        {!user.isOrganization &&
                                            (
                                                churches.map((church) => {
                                                    let min = 1
                                                    const max = 100
                                                    const rand = min + Math.random() * (max - min)

                                                    return (
                                                        <ViewAllItem 
                                                            isOtherMember={false}
                                                            key={ church._id +  rand } 
                                                            userId={church._id}
                                                            fullName={church.fullName} 
                                                            username={'Church'} 
                                                            image={church.profileImg.imagePath2}  
                                                            />
                                                    )
                                                })
                                            )
                                        }
                                  
                                        {
                                            users.map((ele) => {
                                                let min = 1
                                                const max = 100
                                                const rand = min + Math.random() * (max - min)

                                                return (
                                                    <ViewAllItem 
                                                        isOtherMember={true}
                                                        key={ ele._id +  rand } 
                                                        userId={ele._id}
                                                        fullName={ele.fullName} 
                                                        username={ele.username} 
                                                        image={ele.profileImg.imagePath2}  
                                                        />
                                                )
                                            })  
                                        }   
                                    </> 
                                )
                            }                            
                        </section>

                            {
                                isLoadingMore && isGetChurchMembersLoading &&
                                (
                                    <Loader />
                                )
                            }

                        {
                            hasNextPage &&
                            (
                                <LoadMore onClick={handleLoadMore}>Load More</LoadMore>
                            )
                        }
                    </SearchNewMessageFeed>
                </MainContent>
            </ModalWrap>
        </ModalContainerMain>
    )
}

const ModalWrap = styled.div`

    width: 500px;
    height: fit-content;
    background-color: white;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px; 

    @media (max-width: 768px) {
        width: 100%;  
        border: none;
        box-shadow: none;
    }
    position: relative;

`

const MainContent = styled.div`

    width: 100%;
    height: fit-content;

    h1{
        font-size: 1.2rem;
        font-weight: 500;
        margin-top: 15px;
        margin-bottom: 20px;
        color: var(--lightThemeFontColor);
    }


`

const SearchNewMessage = styled.div`

    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    input{
        padding: 10px;
        border-radius: 5px;
        border: none;
        outline: none;
    }

    svg{ 
        margin-right: 10px;
        color: var(--lightThemeFontColor);
    }

`

const SearchNewMessageFeed = styled.div`
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    overflow-y: scroll;
    /* hide the scroll bar */
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 20px;
    &::-webkit-scrollbar {
        width: 0px;  /* remove scrollbar space */
        background: transparent;  /* optional: just make scrollbar invisible */
    }

    p{
        font-size: 12px;
        color: var(--lightThemeFontColor);
    }

    section{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }



    @media (max-width: 768px) {
        padding: 0px;
    }


`

const LoadMore = styled.div`



    width:110px;
    height: 30px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: var(--themeColor);
    border-radius: 10px;
    color: white;
    cursor: pointer;
    transition: .4s;
    margin: 10px 0px 10px 0px;
    padding: 10px 30px;
    &:hover{
        border: 1px solid green;
        background-color: #0b69a9;
        color: white
    }
    `