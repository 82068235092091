import { useRef } from 'react';
import styled from 'styled-components' 
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ClearIcon from '@mui/icons-material/Clear';
import useToggleDropdown from 'hooks/useToggleDropdown';
import { convertTime, formatPrice } from './stripeUtilityFuncs';
import { DotEle, DotsWrap } from 'components/dots/dots.styled';

export default function SubItem(props) {
    const ref1 = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref1)
    const handleDropDown = () => { setIsDropped.toggle(ref1) }
    let fontIconStyle = {fontSize: '26px', color: 'rgb(77,77,77'}
    let subItemImage; 
    try{ subItemImage =  `https://www.praysely.com/static/media/prayselyLogo.dd8331fe6d2a1e929be6.png`}
    catch(e){ subItemImage = null}
  
    let time = convertTime(props.data.current_period_end)
    let price = formatPrice(props.data.plan.amount)


    return (
    <SubItemContainer>
        <SubWrap>
            { subItemImage && (
                    <img src={subItemImage} width='80px' height='auto' alt="Logo" />
                )
            }
            
            <DIVWrap>
                <DIV1>
                    <SubType>Praysely { props.data.plan.interval }ly subscription</SubType>
                    <Status>{ props.data.status }</Status>
                </DIV1>

                <DIV2>
                    <NextPeriod>Next payment date on {time.month} {time.day}, {time.year}</NextPeriod>
                    <Price> for {price}</Price>
                </DIV2>
            </DIVWrap>
        </SubWrap>
        <MoreDots onClick={handleDropDown} ref={ref1}>
            <IconDiv><MoreHorizIcon style={fontIconStyle} /></IconDiv>
        </MoreDots>
        {isDropped && (
            <DotsWrap>
                <DotEle > 
                    <ClearIcon className='postItemDotsIcon'/> 
                    Cancel Subscription
                </DotEle>
            </DotsWrap>
        )}
    </SubItemContainer>
  )
}

const SubItemContainer = styled.div`
position: relative;
    margin-top: 20px;
    width: 90%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 5px;
    padding-left: 10px;
    box-shadow: 0 0 10px #ccc;

    img{
        margin-right: 10px;
        margin-left: 10px;
        padding: 8px;
        border: 2px solid var(--themeColor);
        border-radius: 5px;
    }
`

const SubWrap = styled.div`
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const MoreDots = styled.div`
    margin: 10px;
    cursor: pointer;
    padding: 3px;
    border-radius: 10px;
    align-self: flex-start;
    justify-self: flex-start;
    display: flex;
    flex-direction: row;
    transition: all 0.5s;
    &:hover{
        background-color: #dfdfdf;
    }

`

const IconDiv = styled.div`
    margin-right: 3px;
    margin-left: 3px;
    border-radius: 4px;
    cursor: pointer;
`

const DIVWrap = styled.div`
    display: flex;
    flex-direction: column;

`

const DIV1 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
`

const DIV2 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: grey;
`

const SubType = styled.div`
    font-weight: bold;
    margin-right: 10px;
`
const Price = styled.div`
    margin-left: 5px;
` 

const Status =  styled.div`
    padding: 3px 5px;
    border-radius: 5px;
    width: fit-content;
    background-color: lightgreen;
    color: #054b05;

`
const NextPeriod = styled.div`
    

`