import React from 'react'
import styled from 'styled-components'

export default function ContextMenu({top, left, children}) {
    return (
        <ContextMenuWrap
            left={left}
            top={top}
            >
            {
                children
            }
        </ContextMenuWrap>
    )
}

const ContextMenuWrap = styled.div`
    position: absolute;
    min-width: 160px;
    width: fit-content;
    background-color: #f0f0f0;
    box-shadow: 0px 0px 5px #a1a1a1;
    border-radius: 5px;
    box-sizing: border-box;
    top: ${props => props.top}px;
    left: ${props => props.left}px;

    section {
        box-sizing: border-box;
        padding: 4px;
        margin: 0;
        list-style: none;
    }
    section div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        white-space: nowrap;
        padding: 8px 12px;
        cursor: pointer;
        font-size: 13px;
        color: var(--lightThemeFontColor);
        
        svg{
            margin-right: 7px;
        }
    
    }
    /* hover */
    section div:hover {
        cursor: pointer;
        background-color: #dfdfdf;
    }
`;