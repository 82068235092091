import React, { useEffect } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentVideo } from 'features/videos/videoSlice'
import { useNavigate } from 'react-router-dom'

export default function VideoPlayerModal({url}) {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { currentVideo, currentVideoLoading, currentVideoSuccess, videoToFetchId } = useSelector((state) => state.video)

    useEffect(() => {
        if(!url){
            dispatch(getCurrentVideo({
                videoId: videoToFetchId,
                churchId: profileChurch._id
            }))

            navigate(`?section=media&view=videos&vid=${videoToFetchId}`)
        }
    }, [])


    if(currentVideoLoading){
        return <div>Loading...</div>
    }

    return (
        <ReactPlayerContainer>
            <ReactPlayer
                url={url ? url : currentVideo}
                controls={true}
                width='100%'
                height='100%'
                />            
        </ReactPlayerContainer>
    )
}

const ReactPlayerContainer = styled.div`
    width:1000px;
    height:700px;
    background-color: #242424;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        width: 100vw;
        height: auto;
    }

`
