import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getProfileChurchDataNotLoggedIn } from 'features/profileChurch/profileChurchSlice'
import styled from 'styled-components'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'; 
import Loader from 'loaders/Loader'
import NotLoggedIn from 'components/churchProfile/NotLoggedIn'

export default function NotLoggedInViewChurch() {

    const dispatch = useDispatch()

    useEffect(() => {  
        dispatch(getProfileChurchDataNotLoggedIn({
            churchName: churchName
        })) 
    }, [])

    let { churchName } = useParams()

    const { isChurchProfileError, isChurchProfileSuccess , isChurchProfileLoading, churchMessage } = useSelector((state) => state.profileChurch)

    return (
        <div>
            {   isChurchProfileLoading  ? 
                (
                    <ChurchLoading> <Loader /> </ChurchLoading>
                )  
                : isChurchProfileSuccess ?
                (
                    <NotLoggedIn />
                )
                : isChurchProfileError &&
                (
                    <NotFound><ErrorOutlineIcon /><span>{churchMessage}</span></NotFound>
                )
            }
        </div>
    )
}

//
const NotFound = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    font-size: 20px;
    color: #575757;
    span{
        margin-left: 10px; 
    }
`

const ChurchLoading = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
`