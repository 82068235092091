import { useEffect } from 'react'
import '../../css/pages/directory.css'
import { toast } from 'react-toastify'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import UserExtras from '../../components/userProfile/UserExtras'
import MyEventFeed from '../../components/events/MyEventFeed'
import { resetProfile, getProfileData } from '../../features/profile/profileSlice'


export default function MyEvents() {

    let { profileName } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth)
    const { profile, isError, message, isSuccess } = useSelector((state) => state.profile)


    useEffect(() => {
        if(profile.length && profile._id !== user._id){
          dispatch(resetProfile())
        }
      }, [])

    // useEffect(() => {
    //     if (isError) { toast.error(message)}
    //     if (!user) {  navigate('/login') }
    //     if(profile.length === 0){ //if there's no profile

    //         console.log('this ran herererere')
    //         dispatch(getProfileData({
    //             userId: user._id, //include for faster load times
    //             profileName: profileName
    //           }))

    //       }
        
    //   }, [user, isError, message, dispatch, navigate, profileName, profile])




    
  return (

    <MainEventPageContainer>
        <SideBar>
            <UserExtras />
        </SideBar>  
        <MyEventsContainer>


            {/* <MyEventsFilter/>  */}


            <MyEventsTitle>Saved Events</MyEventsTitle>
            

            {isSuccess && (
                    <MyEventFeed idList={profile.myData.savedEvents}  />
                )
            }                                    
            
                                              
        </MyEventsContainer>
    </MainEventPageContainer>
  )
}
 

const MainEventPageContainer = styled.div`
    display: flex;
    width: 1300px;
    flex-direction: row;
    justify-content: flex-start; 
    margin: 0 auto;
    @media (max-width: 768px) {
      width: 100%;
      padding: 10px; 
      height: fit-content;
    }
`

const MyEventsTitle = styled.div`
    margin-top: 30px;
    margin-left: 20px;
    margin-bottom: 20px;
    color: rgb(70,70,70);
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    justify-self: flex-start;
    @media (max-width: 768px) {
        margin-left: 0px;
        margin-top: 10px;
    }
`

const SideBar = styled.div`
    display: flex;
    flex-direction: column;

`

const MyEventsContainer = styled.div`
    margin-left: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    @media (max-width: 768px) {
        margin-left: 0px;
    }
`
