import { useEffect } from "react";
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { createSubscription, setViewSubscribeModal } from 'features/billing/billingSlice'
import Loader from "loaders/Loader";
import { updateUser } from "features/auth/authSlice";

export default function SubscribeModal() {
    const dispatch = useDispatch()

    const {
        isCreateSubscriptionLoading,
        isCreateSubscriptionSuccess,
        billingPrice,
        billingInterval,
      } = useSelector((state) => state.billing)
    

      const handleConfirmSubscription = () => {  

        dispatch(createSubscription({ 
          billingInterval: billingInterval
        }))
      }

      useEffect(() => {
        if(isCreateSubscriptionSuccess){

          dispatch(updateUser({
            type: 'isPremium',
            value: true
          }))

          toast.success('Subscription activated successfully!')
          dispatch(setViewSubscribeModal(false))

          // update the user data isPremium value
          // dispatch(getUserData())

        }
      }, [isCreateSubscriptionSuccess, dispatch])
  
     
  return (
    <SubModalContainer>
        <SubModalWrap loading={isCreateSubscriptionLoading}>
            <CreateSFormTop>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                    <CardMembershipIcon style={{color: 'white', fontSize: '30px', marginLeft: '10px', marginRight: '10px'}} />  
                    <SubModalHeading>Confirm Your Subscription</SubModalHeading>
                </div>
                <CloseBtn onClick={() => dispatch(setViewSubscribeModal())}><CloseIcon style={{color: 'white'}} /></CloseBtn>
            </CreateSFormTop>  

            <ConfirmWrap>

              <ConfirmContent>
                { isCreateSubscriptionLoading ? 
                (
                  <LoaderWrap>
                    < Loader />
                  </LoaderWrap>
                )
                :
                (
                  <span>
                    Start {billingInterval} subscription for {billingPrice} <span style={{marginLeft: '5px'}}>Your first charge will be today.</span>
                  </span>
                )

                }                
              </ConfirmContent>


              
              
              <ConfirmBtn 
                onClick={handleConfirmSubscription}
                disabled={isCreateSubscriptionLoading}
                >
              { isCreateSubscriptionLoading ? 
                            (
                             'Processing'
                            )
                            :
                            (
                             'Confirm'
                            )
                         }

              </ConfirmBtn>
            </ConfirmWrap>


            

        </SubModalWrap>
    </SubModalContainer>
  )
}

const SubModalContainer = styled.div`
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
`

const SubModalWrap = styled.div`
    background-color: white;
    width: 500px;
    min-height: fit-content;
    height: fit-content;
    padding-bottom: 20px;
    border-radius: 11px;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor:  ${props => props.loading ? 'wait':'auto'}; 
    @media (max-width: 768px) {
        width: 95%;
        border-radius: 7px;
    }
    @media (max-width: 568px) {
        width: 95%;
        border-radius: 7px;
    }

`

const CreateSFormTop = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    padding-left: 8px;
    justify-content: space-between;
    background-color: var(--themeColor);    
    border-radius: 8px 8px 0px 0px;
    font-weight: 300;
`

const CloseBtn = styled.div`
    position: absolute;
    right: 0;
    padding-right: 10px;
    font-size: 13px;
    cursor: pointer;

`

const SubModalHeading = styled.div`
  font-size: 14px;
  font-weight: 100;
  padding-top: 15px;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    font-size: 14px;
    padding-top: 0px;
    margin-bottom: 5px;
    margin-top: -15px;
  }
`

const ConfirmContent = styled.div`
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ConfirmBtn = styled.button`
  margin-top: 10px;
  padding: 15px 38px; 
  border: 2px solid purple;
  color: purple;
  border-radius: 6px;
  cursor:  ${props => props.disabled ? 'wait':'pointer'}; 
  transition: all 0.3s;
  font-weight: bold;
  pointer-events: ${props => props.disabled ? 'none':'all'}; 
  &:hover{
      background-color: purple;
      color:white;
      font-weight: bold;
      padding: 15px 36px; 
  }
`
const ConfirmWrap = styled.div`
  color: #505050;
  font-size: smaller;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
`

const LoaderWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

`