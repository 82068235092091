import { useEffect } from 'react'
import '../css/userProfile.css'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getProfileChurchData, resetChurchProfile, setViewChurchFeedbackModal } from '../features/profileChurch/profileChurchSlice' 
import NotAMember from '../components/userProfile/NotAMember'
import ReportPostModal2 from '../components/modals/ReportPostModal2'
import ChurchExtras from '../components/userProfile/ChurchExtras'
import UserExtras from '../components/userProfile/UserExtras'
import ChurchProfileTopInfo from '../components/userProfile/ChurchProfileTopInfo' 
import Events from 'components/events/Events'
import Polls from './polls/Polls'
import Bulletin from './bulletin/Bulletin'
import Anouncements from './anouncements/Anouncements'
import Directory from './directory/Directory'
import {  logoutUserFaraSesiune, resetUserForLogout } from 'features/auth/authSlice'
import About from 'components/aboutChurch/About'
import ChurchLinks from 'components/links/ChurchLinks'
import Loader from 'loaders/Loader'
import Posts from 'components/posts/Posts'
import PrayerWall from 'components/postComponents/prayerWall/PrayerWall'
import { PageWrapper, PageContainerMain, UserProfileMain, PrayerWallWrap, StyledSideBar  } from './pages.styled'
import Videos from 'components/videos/Videos'
import Files from 'components/files/Files'
import { setAdmin, setModerator } from 'features/auth/authSlice'
import { Modal } from '@mui/material'
import ChurchFeedbackModal from 'components/churchFeedback/ChurchFeedbackModal'
import MediaPage from 'components/media/Media'
import Giving from 'components/giving/Giving'

export default function UserProfileChurch() {
  let { churchName } = useParams()

  const location = useLocation();
  const section =  new URLSearchParams(location.search).get('section') ? new URLSearchParams(location.search).get('section') : 'none'
  // const folder = new URLSearchParams(location.search).get('folder') ? new URLSearchParams(location.search).get('folder') : 'none'

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.auth)
  const { profileChurch, isChurchProfileError, isChurchProfileSuccess , isChurchProfileLoading, churchMessage, viewChurchFeedbackModal } = useSelector((state) => state.profileChurch)
  const { viewModal } = useSelector((state) => state.modal)  
  const { viewPrayerWall } = useSelector((state) => state.prayerWall)

  const { viewBirthdays } = useSelector((state) => state.directory)

  useEffect(() => {
    sessionStorage.setItem('cpk', profileChurch.stripeId)
  }, [profileChurch])

  useEffect(() => {
    if (!user) {     
      navigate(`/church/${churchName}`) 
    }
  }, [user, navigate, churchName])


  useEffect(() => {
  }, [section])

  
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// bug here.. October 2023. When a user visits another church profile. A user can no longer navigate to their church from the left pane.
  // useEffect(() => { 
  //     return () => {
  //       if(profileChurch) { 
  //           dispatch(resetChurchProfile())
  //       }
  //     }
  //   }, [])
 
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%





  useEffect(() => { 
    if(user){
      if(profileChurch.length === 0 && !isChurchProfileLoading){ //if there's no profile
        dispatch(getProfileChurchData({
          //userIdX: user._id, //<== this is g4ewabeing sent as undefined
          profileName: churchName
        }))
      }else{
        // if there is a profile but it is a different one, then get the new profile
        if(profileChurch && profileChurch.username !== churchName && !isChurchProfileLoading){

          dispatch(resetChurchProfile())

          dispatch(getProfileChurchData({ 
            profileName: churchName
          }))
        }
      }
    }
     
  // }, [user, isChurchProfileLoading, churchName, profileChurch])
  }, [])


  useEffect(() => {
    if (isChurchProfileError) { 

      if(churchMessage === "Not logged in"){
            dispatch(logoutUserFaraSesiune())
            dispatch(resetUserForLogout())
            navigate('/login') 
            // window.location.reload()

      } else if(churchMessage === 'No profile found'){

        // here we should have a 404  page

        if(user.isChurch || user.isOrganization){
          navigate(`/${user.name}`)


        } else{
          navigate('/')  
        } 
      }
    }
  }, [isChurchProfileError, churchMessage, dispatch, navigate])


  useEffect(() => {

    if(profileChurch && profileChurch.isViewable){

    if(user){
      let lr = JSON.parse(localStorage.getItem('pr'))

        if(
          isChurchProfileSuccess && 
          profileChurch && 
          profileChurch.username === churchName
          ){
    
          //  this works for someone being added as an admin or moderator or who is already an admin or moderator
          // however, if the user is removed as an admin or moderator, the state will not be updated immediately for the user until after a new page session
          // since it is stored in session storage, i don't believe this is such a big deal, but it is something to be aware of
    
          if(profileChurch.admins.includes(user._id)){
            dispatch(setAdmin({
              id: profileChurch._id,
              value: true
            }))
          } else if(profileChurch.moderators.includes(user._id)){
            dispatch(setModerator({
              id: profileChurch._id,
              value: true
            }))
          }
        }
      }
    } 
  }, [isChurchProfileSuccess, profileChurch, user, dispatch, churchName])


  if(isChurchProfileLoading){
    return <Loader />
  }


  return (  
    <PageWrapper>
      <PageContainerMain 
          fullWidth={viewPrayerWall} 
          > 
            <div className='churchProfileLeft'>
              {
                user && (
                  user.isOrganization ?  ( <ChurchExtras /> ) : (  <UserExtras /> )
                )
              } 
            </div>  
            { isChurchProfileLoading ? 
              (
                <Loader/>
              ) 
              : isChurchProfileSuccess &&
              (
                profileChurch ? 
                (
                  profileChurch.isViewable ? 
                    ( 

                      <UserProfileMain
                        fullWidth={viewPrayerWall}
                        >
                        <ChurchProfileTopInfo />

                          { 
                            section === 'posts' || section === 'none' ? 
                            (  <Posts church={true} /> )
                            : section === 'events' ? 
                            ( <Events/> )
                            : section === 'directory' ? 
                            ( <Directory /> )
                            : section === 'announcements' ? 
                            ( <Anouncements /> )
                            : section === 'bulletin' ?
                            (  <Bulletin /> )
                            : section === 'polls' ? 
                            ( <Polls /> ) 
                            : section === 'about' ?
                            ( <About /> )
                            : section === 'media' ?
                            (  <MediaPage />  )
                            : section === 'videos' ?
                            (  <Videos />  )
                            : section === 'files' ?
                            ( <Files /> )
                            : section === 'giving' ?
                            (
                              <Giving />
                            )
                            : section === 'links' &&
                            (  <ChurchLinks />  )
                          }  
                      </UserProfileMain>
                    )
                    :  
                    (
                      <NotAMember />
                    )
                  )
                  :
                  (
                    <div>Not a church</div>
                  ) 
              )}

            {
                viewModal === true && (
                  <ReportPostModal2 />
                )
            } 

        </PageContainerMain>

        <StyledSideBar
          width={viewPrayerWall ? '20%' : '0px'}
          >
          <PrayerWall />
        </StyledSideBar> 

      <Modal
        open={viewChurchFeedbackModal}
        onClose={() => dispatch(setViewChurchFeedbackModal(false))}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div 
          style={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center', 
            width: '100%',
            height: '100%',
          }}
          >
          <ChurchFeedbackModal />

        </div>
      </Modal>
    </PageWrapper>

  )
}
