import React, { useEffect, useState } from 'react'
import { SettingsViewContainer } from '../settings.styled' 
import Toggle from 'react-toggle'
import "react-toggle/style.css" // for ES6 modules
import { getSettings, setSettings, updateSettings, resetSettings } from 'features/profileChurch/profileChurchSlice'
import { settingsStore } from 'app/settingsStore'
import { useSelector, useDispatch } from 'react-redux'
import { SettingsMainBtn, SettingsMainBtnCancel } from '../settings.styled'
import { SDesc, SettingsBtnWrap, SettingsInfo, SettingsItem, ProfileSettingsContainer, SettingsItemWrap, SettingsNameWrap, SettingsName, ToggleButtonWrap } from './profileSettings.styled'
import Loader from 'loaders/Loader'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { toast } from 'react-toastify'
import { settingsLanguage } from '../settingsLanguage'


export default function ProfilePublicSettings() {
    const dispatch = useDispatch()  
    const handleToggleChange = (event) => { 
        dispatch(setSettings({
            settingType: 'public',
            name: event,
            value: !settings[event]
        }))
    }
    const [hovered, setHovered] = useState(null);
    const { isEdited, settingsInfo, setIsEdited } = settingsStore()
    const [viewDetails, setViewDetails] = useState() 
    const { settings0, settings, isChurchSettingsLoading,isChurchSettingUpdating, isChurchSettingsSuccess, areSettingsEdited, arePublicSettingsSaved } = useSelector((state) => state.profileChurch)
    const { user } = useSelector((state) => state.auth)

    useEffect(() => { 
        dispatch(getSettings({
            settingType: 'public'
        })) 
        return () => {  }
    }, [])

    useEffect(() => {
        if(JSON.stringify(settings0) === JSON.stringify(settings)){
            setIsEdited(false)
            console.log('not edited')
        }else{
            setIsEdited(true)
            console.log('edited')
        }
    }, [settings, settings0,])
 
    useEffect(() => {   }, [settings])
         

    const handleSubmit = () => { 
        dispatch(updateSettings({
            settingType: 'public',
            settings: settings
        })) 
    }

    const handleCancel = () => { 
        setIsEdited(false) 
        dispatch(resetSettings())
    }

    useEffect(() => {
        if(arePublicSettingsSaved){
            toast.success('Settings updated')
        }
    }
    , [arePublicSettingsSaved])
    

    return (
        <SettingsViewContainer>
            <SDesc> 
                {settingsLanguage.publicViewDescription[user.preferredLanguage]}    
            </SDesc> 
            <ProfileSettingsContainer> 
                {
                    (isChurchSettingsLoading || isChurchSettingUpdating) && 
                    (  
                        <div style={{ 
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}> 
                            <Loader /> 
                        </div>
                    )
                }

                <SettingsItemWrap> 
                { 
                        isEdited ? ( 
                            Object.keys(settings).map((setting, index) => {
                                return (
                                    <SettingsItem  
                                        key={index}
                                        onMouseEnter={() => setHovered(setting)}
                                        onMouseLeave={() => setHovered(null)}
                                        >
                                        
                                        <SettingsName>
                                            <SettingsNameWrap>
                                                {
                                                    setting === "profileImg" ?  
                                                    (
                                                        settingsLanguage.profileImage[user.preferredLanguage]
                                                    ) 
                                                    : 
                                                    (
                                                        settingsLanguage[setting][user.preferredLanguage]
                                                    )
                                                }

                                                { hovered === setting && ( 

                                                    <HelpOutlineIcon 
                                                        onClick={() => { 
                                                            if(viewDetails !== setting ){ 
                                                                setViewDetails(setting) 
                                                            }else{ 
                                                                setViewDetails(null) 
                                                            }
                                                        }}
                                                        style={{ fontSize: '16px', marginLeft: '15px' }} />
                                                )}                                                
                                            </SettingsNameWrap>

                                                {   viewDetails === setting &&
                                                    <SettingsInfo>
                                                        {settingsLanguage[setting].desc[user.preferredLanguage]}. Current Status: {settings0[setting] ? "Enabled" : "Disabled"}
                                                    </SettingsInfo> 
                                                }
                                            
                                        </SettingsName>
                                        <ToggleButtonWrap> 
                                            <Toggle
                                                defaultChecked={settings[setting]}
                                                icons={{
                                                    checked: null,
                                                    unchecked: null,
                                                }}
                                                onChange={() => handleToggleChange(setting)} />  
                                        </ToggleButtonWrap> 
                                    </SettingsItem>
                                )
                            })
                        ) : (
                            Object.keys(settings0).map((setting, index) => {
                                return (
                                    <SettingsItem 
                                        key={index*112 + 2231}
                                        onMouseEnter={() => setHovered(setting)}
                                        onMouseLeave={() => setHovered(null)}
                                        >
                                        <SettingsName>
                                            <SettingsNameWrap>
                                                {
                                                    setting === "profileImg" ?  
                                                    (
                                                        settingsLanguage.profileImage[user.preferredLanguage]
                                                    ) 
                                                    : 
                                                    (
                                                        settingsLanguage[setting][user.preferredLanguage]
                                                    )
                                                }


                                                { hovered === setting && (
                                                    <HelpOutlineIcon 
                                                        onClick={() => { 
                                                            if(viewDetails !== setting ){ 
                                                                setViewDetails(setting) 
                                                            }else{ 
                                                                setViewDetails(null) 
                                                            }
                                                        }}
                                                        style={{ fontSize: '16px', marginLeft: '15px' }} />
                                                )}                                                
                                            </SettingsNameWrap>

                                                    {   viewDetails === setting &&
                                                        <SettingsInfo>
                                                            {settingsLanguage[setting].desc[user.preferredLanguage]}. Current Status: {settings0[setting] ? "Enabled" : "Disabled"}
                                                        </SettingsInfo> 
                                                    
                                                    }
                                            
                                        </SettingsName>
                                        <ToggleButtonWrap> 
                                            <Toggle
                                                defaultChecked={settings0[setting]}
                                                icons={{
                                                    checked: null,
                                                    unchecked: null,
                                                }}
                                                onChange={() => handleToggleChange(setting)} />  
                                        </ToggleButtonWrap> 
                                    </SettingsItem>
                                )
                            })
                        )
                    } 

                </SettingsItemWrap>  
                { isEdited && (
                    <SettingsBtnWrap>
                        <SettingsMainBtnCancel
                            disabled={isChurchSettingUpdating}
                            onClick={handleCancel}
                            >Cancel</SettingsMainBtnCancel> 
                        <SettingsMainBtn
                            disabled={isChurchSettingUpdating}
                            onClick={handleSubmit}
                            >Save</SettingsMainBtn>
                    </SettingsBtnWrap>
                ) }
            </ProfileSettingsContainer>   
        </SettingsViewContainer>
    )
}

