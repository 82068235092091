import React from 'react'
import styled from 'styled-components'

export default function RadioButton({size, fillColor, checked, disabled, handler}) {

    return (
        <RadioButtonBorder
            onClick={handler}
            size={size}
            disabled={disabled}
            >
                <FillColorContainer
                    checked={checked}
                    fillColor={fillColor}
                    >
                </FillColorContainer>
        </RadioButtonBorder>
    )
}

const RadioButtonBorder = styled.div`
    cursor: pointer;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    border: 1px solid  #727272;
    border-radius: 50%;
    width: ${props => props.size ? props.size : '20px'};
    height: ${props => props.size ? props.size : '20px'};
    padding: 2px;
    transition: all 0.3s ease-in-out;

    &:hover{
        box-shadow: 0px 0px 7px #9e9e9e;
    }

`
const FillColorContainer = styled.div`
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-color: ${props => props.checked ? props.fillColor : 'white'};
`   