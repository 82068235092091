import { getConversationsWithOtherMembers } from 'features/messenger/conversationSlice'
import { useEffect } from 'react'
import { useDispatch ,useSelector } from 'react-redux'
import ConversationItem from './ConversationItem'
import { ConversationFeed } from './zStyling.styled'
import Loader from 'loaders/Loader'

export default function Member2MemberConversationFeed() {

    const dispatch = useDispatch()
    const { otherMemberConversations, isConversations2Success, isConversations2Loading } = useSelector(state => state.conversations)
    const { user } = useSelector(state => state.auth)


    useEffect(() => {
        
        if(otherMemberConversations.length === 0){
            dispatch(getConversationsWithOtherMembers())
        }

    }, [])


    return (
        <ConversationFeed>
        {
            isConversations2Loading ?
            (
                <Loader />
            )
            : isConversations2Success && otherMemberConversations.length > 0 &&
            (
                otherMemberConversations.map((ele) => {

                    if(ele.user1._id === user._id){
                        return (
                            <ConversationItem
                                key={ele._id}
                                conversationId={ele._id} 
                                image={ele.user2.profileImg.imagePath2} 
                                fullName={ele.user2.fullName} 
                                date={ele.updatedAt}
                                lastMessage={ele.lastMessage}
                                member2member={true}
                                /> 
                        )
                    }else{
                        return (
                            <ConversationItem
                                key={ele._id}
                                conversationId={ele._id} 
                                image={ele.user1.profileImg.imagePath2} 
                                fullName={ele.user1.fullName} 
                                date={ele.updatedAt}
                                lastMessage={ele.lastMessage}
                                member2member={true}
                                /> 
                        )
                    }
                })
            )
        }
        </ConversationFeed>
    )
}
