import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components' 
import { FiDownload } from "react-icons/fi";
import { Tooltip } from '@mui/material'; 
import { staticUrl } from 'features/constants';
import { icons } from '../icons';
import { useSelector, useDispatch } from 'react-redux';
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { DroItem, DroTitle } from '../files.styled';
import { CiEdit } from "react-icons/ci";
import { AiOutlineDelete } from "react-icons/ai";
import { TbArrowMoveRight } from "react-icons/tb";
import useToggleDropdown from 'hooks/useToggleDropdown';
import { deleteFile, setSelectedFile, setViewFileModal, resetUploadState } from 'features/files/fileSlice';
import { setConfirmModalData, setViewConfirmModal } from 'features/modal/modalSlice';
import { FaExternalLinkAlt } from "react-icons/fa";
import { DotsWrap, IconStyled, DotEle } from 'components/dots/dots.styled';

export default function FileItem({index, id, file}) {

    const dispatch = useDispatch()
    let fileDate = new Date(file.createdAt).toDateString()
    const { widths, newFileName, selectedFolder, isFileDeletedSuccess, isFileDeleting } = useSelector(state => state.file)
    const ref = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref)
    const handleDropDown = () => { setIsDropped.toggle(ref) }
    const [locked, setLocked] = useState(false)

    const {profileChurch} = useSelector(state => state.profileChurch)
    const { user, admin } = useSelector(state => state.auth)
    const { viewConfirmModal, confirmModalData } = useSelector((state) => state.modal)

    const [fileTitle, setFileTitle] = useState(file.title)

    const download = async () => {
        // download using the file path
        //  here we can do some sorting based on file types
        //  for now we will just download the file since all files 
        //  are pdfs for now.
        // in future we will have more file types 

        fetch(`${staticUrl}/cf/${file.path}`).then((response) => {
            response.blob().then((blob) => {  
                const fileURL = window.URL.createObjectURL(blob); 
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = file.title;
                alink.click();
            });
        });
    }

    const handleLink = () => {
        //  open link in new tab

        window.open(file.url, '_blank');
        

    }

        const handleViewPdfURLinNewTab = () => {    
        //  open link in new tab
        let baseUrl = 'https://api.praysely.com/static/cf/'
        let pdfUrl = baseUrl + file.path    
        
        window.open(pdfUrl, '_blank');
    }

    const handleDelete = () => {

        dispatch(setConfirmModalData({
            text: `Are you sure you want to delete ${fileTitle}?`,
            handler: confirmDelete,
            confirmBtnBackgroundHover: '#ff0000',
            extraInfo: 'This action cannot be undone.',
        }))

        dispatch(setViewConfirmModal(true))
    }

    const confirmDelete = () => {
        dispatch(deleteFile({
            churchId: profileChurch._id,
            folderId: selectedFolder ? selectedFolder._id : null,
            fileId: file._id,
        }))
    }

    const handleMove = () => {
        dispatch(setSelectedFile(file))
        dispatch(setViewFileModal({
            modal: 'viewMoveFileModal',
            view: true,
        }))
    }

    const handleRename = () => {
        dispatch(setSelectedFile(file))
        dispatch(setViewFileModal({
            modal: 'viewRenameFileModal',
            view: true,
        }))
    }

    useEffect(() => {
        if(newFileName.id === id){
            setFileTitle(newFileName.value)
        }
    }, [newFileName, id])

    return (
        <FileItemContainer>
            <LeftFileItem> 
                <FileTitle
                    style={{width: `${widths.title}px`}}
                    >
                        {icons[file.fileType]}
                    {fileTitle}
                </FileTitle>              

                <FileDate
                    style={{width: `${widths.date}px`}}
                    >
                    {fileDate}
                </FileDate>

                <FileType
                    style={{width: `${widths.type}px`}}
                    >
                    {file.fileType}
                </FileType>

            </LeftFileItem>

            <RightFileItem> 


                {
                    (admin[profileChurch._id] || user._id === file.creator || user.isOrganization || user._id === profileChurch._id) &&
                    (
                        <>
                            <SBa 
                                style={{marginRight: '20px'}}
                                ref={ref}
                                onClick={handleDropDown}
                                > 
                                <HiOutlineDotsHorizontal size={20} /> 
                            </SBa> 

                    
                            { isDropped && 
                                (
                                    <DotsWrap
                                        top='10px'
                                        right='45px'
                                        >
                                                                                {
                                            // if the file is a pdf allow to view in new tab
                                            
                                            file.fileType === 'pdf' &&
                                            (
                                                <DotEle>
                                                    <IconStyled><FaExternalLinkAlt /></IconStyled>
                                                    <DroTitle onClick={handleViewPdfURLinNewTab}>View File</DroTitle>
                                                </DotEle>
                                            )
                                            

                                        }
                                        <DotEle>
                                            <IconStyled><TbArrowMoveRight  /></IconStyled>
                                            <DroTitle onClick={handleMove}>Move File</DroTitle>
                                        </DotEle>    
                                        <DotEle>
                                            <IconStyled><CiEdit /></IconStyled>
                                            <DroTitle onClick={handleRename}>Rename File</DroTitle>
                                        </DotEle>
                                        <DotEle>
                                            <IconStyled><AiOutlineDelete /></IconStyled>
                                            <DroTitle onClick={handleDelete}>Delete File</DroTitle>
                                        </DotEle>
                                    </DotsWrap>
                                )
                            }
                        </>
                    )
                }


                    {
                        file.url ? 
                        (
                            <Tooltip title="Go To File" placement='top'>
                                <SBa
                                    style={{marginRight: '0px'}}
                                    onClick={handleLink}

                                    >
                                    <FaExternalLinkAlt size={20} /> 
                                </SBa>
                            </Tooltip>     
                        )
                        :
                        (
                            <Tooltip title="Download" placement='top'>
                                <SBa
                                    style={{marginRight: '0px'}}
                                    onClick={download}
                                    download 
                                    >
                                    <FiDownload size={20} /> 
                                </SBa>
                            </Tooltip>                              
                        )
                    }


            </RightFileItem> 
        </FileItemContainer>
    )
}


const RightFileItem = styled.div`

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: fit-content;
    position: relative;

`

const FileType = styled.div`

    color: var(--lightThemeFontColor);

    @media (max-width: 768px) {
        display: none;
    }

`

const FileDate = styled.div`
    color: var(--lightThemeFontColor);

    @media (max-width: 768px) {
        display: none;
    }

`

const FileItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    font-size: 13px;
    color: var(--lightThemeFontColor);
    transition: all 0.9s; 
    margin-right: 10px;
    cursor: pointer;
    padding: 11px;



    span{
        margin-top: 5px;
        margin-left: 2px;
        width: 100%;
        text-align: center;
    }

    &:hover{
        background-color: #d5d5d5;
        border-radius: 5px;
        color: #313131;

    }

`

const FileTitle = styled.div`
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;

    /* hide overflow text with ellipsis */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    svg{
       color: #8ca7c3; 
       margin-right: 15px;
    }
    


    @media (max-width: 768px) {
        width: 200px;
    }

`

const LeftFileItem = styled.div` 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;

    svg{
        font-size: 20px;
        margin-right: 15px; 
    }

`
const SBa = styled.button`
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #959595;
    transition: 0.4s;
    width: fit-content;
    padding: 4px;

    svg{
        margin: 0px;
        padding: 0px;
        color: #858585;
    }

    &:hover{
        background-color: white;
        border-radius: 10px;
    }

`
