import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { getPaymentMethods, resetAddPaymentMethod, setViewAddCardModal } from 'features/billing/billingSlice'
import Loader from "loaders/Loader";
import { ViewHeading } from '../settings.styled';
import WalletIcon from '@mui/icons-material/Wallet';
import AddCardIcon from '@mui/icons-material/AddCard';
import { toast } from 'react-toastify'
import WalletCardItem from './WalletCardItem'
import AddCardModal from './AddCardModal'

export default function Wallet() {
  const dispatch = useDispatch()
  const { 
    
    paymentMethods, 
    viewAddCardModal,
    isGetPaymentMethodsSuccess,
    isGetPaymentMethodsLoading,
    isSetPaymentMethodsSuccess
  } = useSelector((state) => state.billing)
  
  useEffect(() => {
    dispatch(getPaymentMethods())
  }, [])

  useEffect(() => {
    if(isSetPaymentMethodsSuccess){
      toast.success('Payment Successfully Added')
    }
    return () => {  dispatch(resetAddPaymentMethod()) }
    
  }, [isSetPaymentMethodsSuccess, dispatch])

  const handleAddCardClick = (allowMakeDefaultOption) => { 
    dispatch(setViewAddCardModal(allowMakeDefaultOption)) 
  }

  return (
    <WalletContainer>
      { isGetPaymentMethodsLoading ? 
        ( <SpinnerWrap> <Loader  /> </SpinnerWrap> )
        : isGetPaymentMethodsSuccess &&
        (
          <>
          <ViewHeading> <WalletIcon/><span style={{marginLeft: '10px'}}>Wallet</span>   </ViewHeading>
          { paymentMethods && paymentMethods.length > 0 && 
            (
              <CTPTitle>Cards and Payment Methods</CTPTitle>
            )
          }

          { paymentMethods && paymentMethods.length > 0 ? 
            (   
              <>
                {
                  paymentMethods.map((card) => {
                    return(
                        <WalletCardItem key={card.id} data={card} />
                    ) 
                  })
                }
                <NewCardAdd onClick={() => handleAddCardClick(true)} >
                  <AddCardIcon style={{fontSiz: '30px'}} /> 
                  <span style={{marginLeft: '10px'}}>Add Card</span>
                </NewCardAdd>
              </>
            )
            :
            (
              <NewCardAdd onClick={() => handleAddCardClick(false)} > 
                <AddCardIcon style={{fontSiz: '30px'}} /> 
                <span style={{marginLeft: '10px'}}>Add Card</span>
              </NewCardAdd>
            )
          }
        </>
        )
      }
      { viewAddCardModal && ( 
          <AddCardModal /> 
        )  
      }
    </WalletContainer>
  )
}

const SpinnerWrap = styled.div`margin-top: 10px;`

const WalletContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
 
const CTPTitle = styled.div`
  margin-bottom: 10px;
  color: var(--dFontColor);
  border-top: 1px solid var(--borderColor);
  width: 100%;
  padding-top: 10px;
`

const NewCardAdd = styled.div`
  position: relative;
  color: var(--dFontColor);
  border-radius: 5px;
  /* border-left: 5px solid green; */
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-bottom: 20px;
  border: 2px dashed grey;
  cursor: pointer;
  
  &:hover{
    transition: background-color 0.7s ease;
    background-color: #dbdbdb;
  }
`