import axios from 'axios' 
import { baseUrl } from '../constants'
const URL = baseUrl  

 
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
}}

 
const giveToChurch = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/giveToChurch', data, config(token))
  return response.data
}

const createAccount = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/createAccount', data, config(token))
  return response.data
}

const addCardForChurchMember = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/addCardForChurchMember', data, config(token))
  return response.data
}

const addBank = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/addBank', data, config(token))
  return response.data
}

const setGivingPaymentIntent = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/setGivingPaymentIntent', data, config(token))
  return response.data
}

const getPaymentMethods = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/getPaymentMethods', data, config(token))
  return response.data
}

const createProduct = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/createProduct', data, config(token))
  return response.data
}


const checkIsMemberAStripeCustomer = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/checkIsMemberAStripeCustomer', data, config(token))
  return response.data
}

const getMemberPaymentMethods = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/getMemberPaymentMethods', data, config(token))
  return response.data
}

const addMemberPaymentMethods = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/addMemberPaymentMethods', data, config(token))
  return response.data
}

const getTransactions = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/getTransactions', data, config(token))
  return response.data
}

const createPaymentIntent = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/createPaymentIntent', data, config(token))
  return response.data
}

const createPaymentGivingIntent = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/createPaymentGivingIntent', data, config(token))
  return response.data
}

const churchConnectAccountCheck = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/churchConnectAccountCheck', data, config(token))
  return response.data
}

const checkConnectAccountStatus = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/checkConnectAccountStatus', data, config(token))
  return response.data
}

const getConnectOnboardingLink = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/getConnectOnboardingLink', data, config(token))
  return response.data
}

const addBankForChurch = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/addBankForChurch', data, config(token))
  return response.data
}

const getChurchTransactions = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/getChurchTransactions', data, config(token))
  return response.data
}

const getChurchPayouts = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/getChurchPayouts', data, config(token))
  return response.data
}

const getChurchBanks  = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/getChurchBanks', data, config(token))
  return response.data
}

const startFinancialConnection = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/startFinancialConnection', data, config(token))
  return response.data
}

const createFinancialConnectionPaymentIntent = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/createFinancialConnectionPaymentIntent', data, config(token))
  return response.data
}

const removeMemberPaymentMethod = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/removeMemberPaymentMethod', data, config(token))
  return response.data
}

const addFinancialConnectionAsPaymentMethod = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/addFinancialConnectionAsPaymentMethod', data, config(token))
  return response.data
}

 
const refreshConnectAccountData = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/refreshConnectAccountData', data, config(token))
  return response.data
}

const addConnectBank = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/addConnectBank', data, config(token))
  return response.data
}

const createPayout = async (data, token) => {
  const response = await axios.post(URL+'/api/giving/createPayout', data, config(token))
  return response.data
}

const givingService = {
    giveToChurch, createAccount, addCardForChurchMember, checkIsMemberAStripeCustomer,
    addBank, setGivingPaymentIntent, getPaymentMethods, createProduct, getMemberPaymentMethods, checkConnectAccountStatus,
    addMemberPaymentMethods, getTransactions, createPaymentIntent, createPaymentGivingIntent, churchConnectAccountCheck,
    getConnectOnboardingLink, addBankForChurch, getChurchTransactions, removeMemberPaymentMethod,
    getChurchPayouts, getChurchBanks, startFinancialConnection, createFinancialConnectionPaymentIntent,
    addFinancialConnectionAsPaymentMethod, refreshConnectAccountData, addConnectBank, createPayout
}
export default givingService