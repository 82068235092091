import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import commentService from './commentService'

const initialState = {
  comments: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Create new post
export const getComments = createAsyncThunk(
  'comments/getComments',
  async (postId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await commentService.getComments(postId, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


// Create new post
export const getCommentsTest = createAsyncThunk(
  'comments/getCommentsTest',
  async (thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await commentService.getComments(token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Create new post
export const setComment = createAsyncThunk(
  'comments/setComment',
  async (commentData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await commentService.setComment(commentData, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const commentSlice = createSlice({
    name: 'comment',
    initialState,
    reducers: {
      reset: (state) => initialState,
    },
    extraReducers: (builder) => {
      builder
        .addCase(setComment.pending, (state) => {
          state.isLoading = true
        })
        .addCase(setComment.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.comments.push(action.payload)
        })
        .addCase(setComment.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        //-----------------
        .addCase(getComments.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getComments.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.comments = action.payload
        })
        .addCase(getComments.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })

//-----------------
        .addCase(getCommentsTest.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getCommentsTest.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.comments = action.payload
        })
        .addCase(getCommentsTest.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
//-----------------
},
})

export const { reset } = commentSlice.actions
export default commentSlice.reducer
