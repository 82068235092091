
import { useEffect, useState, React } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { verifyEmailCode, setCloseEmailConfirmModal, resetAuth } from '../../features/auth/authSlice'
import HorizontalLoader from 'loaders/HorizontalLoader'
import SpinnerSmall from 'components/SpinnerSmall' 
import { sendVerificationEmailCode } from '../../features/auth/authSlice'
import SpinnerIntermintentSmall from 'loaders/SpinnerIntermintentSmall'

function CodeForm(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [errorField, setErrorField] = useState(false)
  const [codeValue, setCodeValue] = useState()
  const { 
    registrantEmail,
    isEmailVerificationLoading, // tracks if email is being sent to the user
    isEmailCodeVerificationLoading,
    isEmailCodeVerificationSuccess,
    codeVerified,
    registerModalData,
    isRegisterUserLoading, 
    isEmailCodeVerificationError,
    errorCount,
  } = useSelector( (state) => state.auth )

  const handleCodeValue = (event) => {
    if(event.target.value.length > 6){
      return
    }
    setErrorField(false)
    setCodeValue(event.target.value)
  }

  const handleSubmit = () => {
    dispatch(verifyEmailCode({
      email: registrantEmail,
      code: codeValue
    }))
  }

  const handleResendEmail = () => {
    dispatch(sendVerificationEmailCode({
      fullName: 'New User', 
      email: registrantEmail,
    }))
  }


  useEffect(() => {
    if(isEmailCodeVerificationError){
      if(errorCount >= 3){
        dispatch(setCloseEmailConfirmModal())
        navigate('/')
        dispatch(resetAuth())
      }else{
        setErrorField(true)
      }
    }

    if(isEmailCodeVerificationSuccess && codeVerified){

      // close modal
      dispatch(setCloseEmailConfirmModal())
      
    }
  }, [dispatch, codeVerified, isEmailCodeVerificationError, errorCount, navigate, isEmailCodeVerificationSuccess, registerModalData])


  if(isRegisterUserLoading){
    return (
      <ModalLoadingWrap>
        <HorizontalLoader />
      </ModalLoadingWrap>
    )
  }



  return (
    <CodeFormWrap>
      <ModalRegisterWrap>
      {/* <CurvedUpwardSection>   */}
        {/* add the praysely logo */}

        {/* <img  
          src={require('../../prayselyLogo.png')}
          alt='logo'
          style={{width: '150px', height: '100px'}}
          /> */}

      {/* </CurvedUpwardSection> */}

        <ModalHeading>Check your email inbox, please!</ModalHeading>

        <Description>
          We have sent a verification code to your email address. Please enter the code below to verify your email address.
        </Description>

        <CodeInputWrap>
          <CodeInput
            isError={errorField}
            >
            <input 
                type='text'
                className='form-control'
                id='locationCountry'
                name='locationCountry'
                value={codeValue}

                onChange={handleCodeValue}
                autoComplete='off'
                placeholder='Enter Code' />
          </CodeInput>
        </CodeInputWrap>

        <SubmitCode 
          disabled={isEmailCodeVerificationLoading}
          onClick={handleSubmit}>
          { isEmailCodeVerificationLoading ? <SpinnerSmall /> : 'Submit' }
        </SubmitCode>      


        <DidntReceiveCode>   
          <span>Didn't receive the email?</span>
          <ResendCode
            onClick={handleResendEmail}
            > 
            <span 
              style={{marginRight: '10px'}}
              > 
              Resend Code
            </span>

            {
              isEmailVerificationLoading ? <SpinnerIntermintentSmall color='#ccc' size='15px' border='1px' /> : ''
            }
          </ResendCode>

        </DidntReceiveCode>


      </ModalRegisterWrap>
    </CodeFormWrap>
  )
}

export default CodeForm

const DidntReceiveCode = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px;
  color: var(--lightThemeFontColor);
  font-size: 14px;
  transition: all .6s ease-in-out;
`

const ResendCode = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  color: grey;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover{
    color: #4a90ae;
  }
`


const CurvedUpwardSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #e5e8fd; */
  border-radius: 0 0 50% 50%;
  height: 50px; 
  top: -50px;
  z-index: 1;
  margin-bottom: 10px;
`
const Description = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--lightThemeFontColor);
  text-align: center;
  line-height: 1;
  width: 100%;
  padding: 10px 40px;
`
 
const ModalHeading = styled.h1`
  font-size: 24px;
  margin-bottom: 15px;
  margin-top: 40px;
  color: var(--lightThemeFontColor);
`


const CodeFormWrap = styled.div` 
    z-index: 2;
    width: 550px;
    min-height: 320px;
    max-height: 100vh;
    background: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: start;
    border-radius: 8px;
    position: relative;
    animation: animate 0.3s;
    padding: 5px 5px 20px 5px;
`



const CodeInputWrap = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

`

const CodeInput = styled.div`
  & input{
    padding: 15px;
    font-size: 23px;
    border-radius: 10px;
    text-align: center;
    /* border: ${props => props.isError ? '1px solid red' : '4px solid #6cd2ff'}; */
/* i really like this color */

    border: ${props => props.isError ? '1px solid red' : '4px solid var(--themeColor) '}; 
    width: 250px;
    margin: 10px;
    outline: none;
    transition: all .6s ease-in-out;
    color: var(--lightThemeFontColor);

    &:focus{
      border: 4px solid var(--themeColor);
      box-shadow: 0 0 10px #ccc;
    }

    ::placeholder {
      color: #d4d4d4;
      font-weight: 500;
    }

  }
`
const SubmitCode = styled.div`

  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  color: white;
  margin-top: 10px;
  padding: 15px 40px;
  background-color: var(--themeColor) ;
  cursor: pointer;
  border-radius: 25px;
  transition: 0.3s ease-in-out;
  
  &:hover{
    background-color: var(--themeColor);
  }
`

const ModalRegisterWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  `

const ModalLoadingWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  `