import { useParams } from 'react-router-dom'
import { useState, useEffect, useRef}  from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { MdArrowBackIosNew } from 'react-icons/md'
import { getSingleCampaign, getSingleCampaignPublic, setViewShareModal } from 'features/givingCampaigns/givingCampaignSlice'
import { imgUrl, dateObj } from 'features/constants'
import styled from 'styled-components'
import Loader from 'loaders/Loader'
import Details from '../../components/events/singlePageEventComponents/Details'
import Discussion from '../../components/events/singlePageEventComponents/Discussion' 
import useImageLoader from 'hooks/useImageLoader'
import { eventLanguage } from 'components/events/eventsLanguage'
import Donate from 'components/giving/campaigns/Donate'
import Donations from 'components/giving/campaigns/Donations' 
import { Modal } from '@mui/material'
import ShareCampaignModal from 'components/giving/campaigns/ShareCampaignModal'
import { MUIModalWrap } from 'components/modal/modal.styled'
import { useMetaTags } from 'react-metatags-hook';

import {
    FacebookShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    FacebookIcon,
  } from 'react-share';

export default function SingleCampaign( ) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { campaignId } = useParams()
    const { singlePageEventData, isSingleEventLoading,  } = useSelector((state) => state.events)
    const [date, setDate] = useState(null) // ['detail', 'discussion' 
    const { singleCampaignData, getSingleCampaignLoading, church, viewShareModal } = useSelector((state) => state.givingCampaign)
    const ref = useRef(null)

    const [imageSource, { blur }] = useImageLoader(
        `${imgUrl}/givingCampaigns/${singleCampaignData && singleCampaignData.images && singleCampaignData.images[0].imagePath2}`, 
        `${imgUrl}/givingCampaigns/${singleCampaignData && singleCampaignData.images && singleCampaignData.images[0].imagePath2}`
    )

    const [ contentView, setContentView ] = useState('donations') // ['donations', 'donate' 

    useEffect(() => {
        if(singleCampaignData === null){ 
            if(user){  
                dispatch(getSingleCampaign({
                    campaignId: campaignId,
                    userId: user._id
                }))

            }else{  
                dispatch(getSingleCampaignPublic({
                    campaignId: campaignId
                }))
            }
        } else{ 
 
        }        
    }, [])

    const handleClick = () => {
        navigate(-1)
    }

    const scrollingBottom = () => {
        const e = ref;
    
        e.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      };

    const handleShare = () => {
         
        dispatch(setViewShareModal(true))

    }

 

  return (
        <SinglePageContainer>

            {   user  && 
                (
                    <div className='singlePostPostedToWrap'>
                        <div className='singlePostGoBack' onClick={handleClick} > 
                            <MdArrowBackIosNew style={{marginRight: '10px'}} /> Go Back
                        </div>
                    </div>
                ) 
            }

                            
            {
                singleCampaignData && (

                    getSingleCampaignLoading ? (
                        <Loader />
                    )
                    :
                    (
                        <SinglePageWrap  addTopMargin={!user}>
                            <SinglePageLeft>
                                <ItemContainer >
                                    <img 
                                        // onContextMenu={(e) => e.preventDefault()}
                                        src={imageSource} 
                                        alt='campaign' 
                                        style={{
                                            width: '100%', 
                                            height: '100%', 
                                            objectFit: 'cover', 
                                            borderTopLeftRadius: '15px', 
                                            borderTopRightRadius: '15px'
                                        }} 
                                        />
                                </ItemContainer>
        
                                <EventInfo> 
                                    <ChurchInfo>
                                        <ChurchImage>
                                            <img 
                                                onContextMenu={(e) => e.preventDefault()}
                                                src={`${imgUrl}/profile/${church.profileImg.imagePath2}`} 
                                                alt='event' 
                                                style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%'}} 
                                                />
                                        </ChurchImage>
                                        <ChurchName>
                                            {church.fullName}
                                        </ChurchName>
                                    </ChurchInfo> 
                                    <EventInfoWrap>
                                        <EventTitle>
                                            <span style={{width: "100%", display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                                                {singleCampaignData.title}
                                            </span>
                                            <AmountRaised>
                                                <p>Amount Raised</p> 
                                                <p style={{marginLeft: '10px'}}>${singleCampaignData.totalDonations }</p>
                                                <p style={{marginLeft: '5px', color: '#bbbbbb'}}>of ${singleCampaignData.goal.toLocaleString()} goal</p>
                                            </AmountRaised> 
                                            <StatusContainer>
                                                <RaisedColor
                                                    progress={
                                                        `${Number(singleCampaignData.totalDonations/singleCampaignData.goal).toFixed(2) * 100}%`
                                                    }    
                                                    >
                                                </RaisedColor>
                                            </StatusContainer> 
                                        </EventTitle>
                                        <EventDate> 
                                            { date && !isSingleEventLoading && ` 
                                                ${dateObj.dayLong[date.getDay()]} 
                                                ${dateObj.monthLong[date.getMonth()]} 
                                                ${date.getDate()} 
                                                ${date.getFullYear()}
                                            `} 
                                        </EventDate>
                                    </EventInfoWrap>
                                </EventInfo> 
                                <CampaignDescription> 
                                    { 
                                        singleCampaignData.description.split('\n').map((item, index) => {
                                            return <p key={index}>{item}</p>
                                        })

                                    } 
                                </CampaignDescription>

                            </SinglePageLeft>
                            <SinglePageRight>
                                <TopRightItemContainer> 
                                    {/* {
                                        user && (
                                            <MyEventStatus>
                                                have donated?
                                            </MyEventStatus>
                                        )
                                    }  */}

                                    {
                                        contentView === 'donate' ? (
                                            <ActionButton
                                                onClick={() => {
                                                        scrollingBottom()
                                                        setContentView('donations')
                                                    }
                                                }
                                                >
                                                <span >View Donations</span>
                                            </ActionButton>
                                        )
                                        : contentView === 'donations' && 
                                        (
                                            <ActionButton
                                                onClick={() => {  
                                                    scrollingBottom() 
                                                    setContentView('donate')
                                                }}
                                                >
                                                <span >Donate</span>
                                            </ActionButton>
                                        )
                                    }  
                                    <ActionButton
                                        onClick={handleShare}
                                        >
                                        <span >Share</span>
                                    </ActionButton>

                                    {/* <FacebookShareButton
                                        url={`https://www.praysely.com/campaigns/665e8b3f22340f105a9113ee`}
                                        quote={singleCampaignData.title}
                                        >
                                        <FacebookIcon size={32} round={true} />
                                    </FacebookShareButton>
                                 */}
                                </TopRightItemContainer>


                                <RightMainContent ref={ref}>  
                                {
                                    contentView === 'donate' ? (
                                        <Donate />
                                    )
                                    : contentView === 'donations' && (
                                        <Donations />
                                    )
                                } 
                                </RightMainContent>
                            </SinglePageRight>
                        </SinglePageWrap>
                    )
                )
            }

            <Modal
                open={viewShareModal}
                onClose={() => dispatch(setViewShareModal(false))}
                >
                <MUIModalWrap>   
                    <ShareCampaignModal />
                </MUIModalWrap>
            </Modal>


        </SinglePageContainer>
    )
}

const CampaignDescription = styled.div`
    
    font-size: 15px;
    color: var(--lightThemeFontColor);
    font-weight: 500;
    padding: 40px 20px;
    background-color: white;
    border-radius: 10px;
    margin-top: 20px;
    box-shadow: 0 0 5px #ccc;
    text-align: left;

    @media (max-width: 768px) {
        padding: 10px;
    }

`

const TopRightItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start; 
    width: 100%; 
    height: fit-content; 
    padding: 0px;

    @media (max-width: 768px) {
        width: 100%;

        z-index: 100;
        position: absolute;
        bottom: 0px;
        left: 0px;
        background-color: var(--themeColor);
        align-items: center;
        justify-content: center;
        padding: 11px;
    }

` 

const ActionButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: 35%;
    height: 40px;
    border-radius: 10px;
    background-color: white;
    color: var(--lightThemeFontColor);
    box-shadow: 0px 0px 5px #ccc;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        color: white;
        background-color: var(--themeColor);
    }
`

const StatusContainer = styled.div`
    width: 100%;
    display: flex;
    height: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #dbeaf6;
    border-radius: 10px;
    margin-top: 10px;
`
const RaisedColor = styled.div`
    width: ${props => props.progress};
    height: 10px;
    background-color: var(--themeColor);
    border-radius: 10px;

    transition: all 1s ease;

`

const AmountRaised = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%; 
    p {
        font-size: 15px;
        color: var(--lightThemeFontColor);
        font-weight: 500;
    } 
`

const DonationTotalsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid red;
`

const SinglePageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
    margin-top: 20px;
    height: fit-content;
    margin: 0px auto;

    @media (max-width: 1468px) {
        width: 80%;
    }

    @media (max-width: 1068px) {
        width: 100%;
    }

    @media (max-width: 968px) {
        width: 100%;
    }

`
const SinglePageWrap = styled.div`
    display: flex;
    height: calc(100vh - 100px);
    flex-direction: row;
    width: 100%;  
    margin-top: ${props => props.addTopMargin ? '20px' : '0px'};

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 0px;
    }
`
const SinglePageLeft = styled.div`
    display: flex;
    flex-direction: column;
    width: 55%;
    height: fit-content ;

    @media (max-width: 768px) {
        width: 100%;  
        padding: 10px; 
        height: fit-content;    
    }
`
const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    width: 100%;
    height: 60%;
    background-color: white;
    @media (max-width: 768px) {
        height: 100%;
    }
`
const EventInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 10px;
    background-color: white;
    border-radius: 0px 0px 15px 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    @media (max-width: 768px) {
        padding: 10px;
        height: 100%;
    }
`
const EventInfoWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    height: fit-content;
    padding: 10px;

    @media (max-width: 768px) {
        padding: 0px;
    }
`

const ChurchInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 10px;
    border-bottom: 1px solid #ececec;
    @media (max-width: 768px) {
        padding: 10px; 
    }
`
const ChurchImage = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
`
const ChurchName = styled.div`
    font-size: 15px;
    color: var(--lightThemeSecondaryFontColor);
    font-weight: 500;
    padding: 10px;
    @media (max-width: 768px) {
        padding-left: 10px;
    }
`

const EventTitle = styled.div`
    font-size: 17px;
    color: var(--lightThemeFontColor);
    font-weight: 600;
    display: flex;
    flex-direction: column;
    width: 100%; 
    align-items: flex-start;
    @media (max-width: 768px) {
        margin-top: 10px;
    }
`

const EventDate = styled.div`
    font-size: 12px;
    color: var(--lightThemeSecondaryFontColor);
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    @media (max-width: 768px) {
        padding: 5px;
    }
`

const SinglePageRight = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%; 
    width: 45%;
    padding: 0px 20px 10px 20px; 
    @media (max-width: 768px) { 
        padding: 5px;
        width: 100%;
    }
`



const RightMainContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content ; 
    margin-top: 20px; 
    @media (max-width: 768px) {
        margin-top: 10px;
        height: fit-content;
        padding: 5px;
    }
`