import { createSlice } from '@reduxjs/toolkit'


const initialState = {
  viewModal: false,
  fromPostItem: false,
  fromPostComment: false,
  postId: null,
  postContent: null,
  postText: null,
  creatorId: null,
  commentId: null,
  commentText: null,
  postedToId: null,
  postType: null, // post or comment
  residingId: null,


  viewConfirmModal: false,
  confirmModalLocation: '',

  confirmModalData: {
    text: null,
    handler: null,
    confirmBtnBackgroundHover: null,
    extraInfo: null,
    conditionToCloseModal: null,
    loadingState: null,
    thingsToResetAfterClose: null
  }

}

export const modalSlice = createSlice({
    name: 'viewModal',
    initialState,
    reducers: {
      
      setViewModal: (state, action) => { 
        state.viewModal = true
        state.postId = action.payload.postId
        state.creatorId = action.payload.creatorId
        state.postText = action.payload.postText
        state.residingId = action.payload.residingId
      },

      setViewModalInPostItem: (state, action) => { 
        state.viewModal = true
        state.fromPostItem = true
        state.postId = action.payload.postId
        state.creatorId = action.payload.creatorId
        state.postText = action.payload.postText
        state.residingId = action.payload.residingId
      },
      setViewModalFromComment: (state, action) => { 
        state.viewModal = true
        state.fromPostComment = true
        state.postId = action.payload.postId
        state.commentId = action.payload.commentId
        state.creatorId = action.payload.creatorId
        state.postedToId = action.payload.directedToId
        state.commentText = action.payload.commentText
        state.residingId = action.payload.residingId
      },

      setViewConfirmModal: (state, action) => {
        state.viewConfirmModal = action.payload
      },

      setViewConfirmModal2: (state, action) => {
        state.viewConfirmModal = action.payload.view
        state.confirmModalLocation = action.payload.location
      },

      setConfirmModalData: (state, action) => {
        state.confirmModalData.text = action.payload.text
        state.confirmModalData.handler = action.payload.handler
        state.confirmModalData.confirmBtnBackgroundHover = action.payload.confirmBtnBackgroundHover
        state.confirmModalData.extraInfo = action.payload.extraInfo
        state.confirmModalData.conditionToCloseModal = action.payload.conditionToCloseModal
        state.confirmModalData.loadingState = action.payload.loadingState
      }, 

      closeModal: (state) => initialState,
      resetModal: (state) => { state.viewModal = initialState }
    }
})

export const { 
  setViewModal, 
  closeModal, 
  resetModal, 
  setViewModalInPostItem,
  setViewModalFromComment,
  setViewConfirmModal,
  setConfirmModalData,
  setViewConfirmModal2
} = modalSlice.actions
export default modalSlice.reducer