import React from 'react'
import { staticUrl } from 'features/constants';
import styled from 'styled-components';
import useGetWindowSize from 'hooks/useGetWindowSize';
import { closeViewBulletinModal } from 'features/bulletin/bulletinSlice';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { ModalContainerMain } from 'components/modal/modal.styled';

export default function BulletinFile(props) {
  const dispatch = useDispatch()
  // const [numPages, setNumPages] = useState(null);
  const [width, height] = useGetWindowSize()

  let pdfFile;
  try{ pdfFile =  `${staticUrl}/bulletin/${props.file}`}
  catch(e){  pdfFile =  null }


  console.log('pdfFile', pdfFile)

  return (
    <ModalContainerMain> 
        <CloseBulletinFileModal onClick={() => dispatch(closeViewBulletinModal())}> 
          < CloseIcon  style={{fontSize: `${5 + 4*(width*.01)}px`,}} />
          <span style={{fontSize: '12px'}}>Close window</span>
        </CloseBulletinFileModal>

        <BulletinModalWrap height={height} width={width} mobileWidth={width} >
          <object data={pdfFile} type="application/pdf" width={width*.80} height={height*.80}> </object> 
        </BulletinModalWrap> 
    </ModalContainerMain>
  )
}


const CloseBulletinFileModal = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    color: white;
    padding: 5px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 40px;
    &:hover{
      background-color: #00000086;
    }
`

const BulletinModalWrap = styled.div` 
    width: ${props => props.width ? props.width : "fit-content"};
    height: ${props => props.height ? props.height : "fit-content"};
    background: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 8px;
    position: relative;
    animation: animate 0.3s;
    padding: 0px;
    @media (max-width: 768px) {
        align-self: center;
        justify-self: center;
        width: ${props => props.width ? props.width : "fit-content"};
        height: ${props => props.height ? props.height : "fit-content"};
    }

`