import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { BsFolder } from "react-icons/bs";
import { MdFolder } from "react-icons/md";
import { setSelectedFolder } from 'features/files/fileSlice';

export default function FolderItem({title, id, index}) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { collectionFiles } = useSelector(state => state.file)

    const handleClick = () => {
        navigate(`?section=files&folder=${id}`)
        
        dispatch(setSelectedFolder(collectionFiles.folders[index]))
        
    }


    return (
        <FICW
            onClick={handleClick}
            >

            {/* <SOO></SOO>       */}

            <MdFolder size={70} />

            <span >{title}</span>

        </FICW>

    )
}

const FICW = styled.div`
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 123px;
    height: fit-content;
    font-size: 13px;
    color: var(--lightThemeFontColor);
    transition: all 0.9s;

    svg{
       color: #8ca7c3; 
    }
    

    span{
        margin-top: 5px;
        margin-left: 2px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; 
        /* hide the vertical text overflow */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: left;
    }

    &:hover{
        svg{
            color: #5e748d;
        
        }
    }
`

const SOO = styled.div`
    width: 116px;
    height: 88px;
    margin: 0 auto;
    margin-top: 10px;
    position: relative;
    background-color: #8ca7c3;
    border-radius: 0 6px 6px 6px;
    /* box-shadow: 4px 0px 7px #b5b5b5; */
    border: 2px solid #8ca7c3; 

    &::before{
        content: '';
        width: 50px;
        height: 10px;
        border-radius: 5px 20px 0 0;
        background-color: #8ca7c3;
        border: 2px solid #8ca7c3;
        position: absolute;
        top: -10px;
        left: -2px;
    }

    &:hover{
        cursor: pointer;
        background-color: #6f8eaa;
        border: 2px solid #6f8eaa;
        &::before{
            background-color: #6f8eaa;
            border: 2px solid #6f8eaa;
        }
    }



`