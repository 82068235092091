import React, { useEffect, useMemo } from 'react'
import { ViewContainer } from './giving.styled'
import { useDispatch, useSelector } from 'react-redux'
import {   
    getMemberPaymentMethods, 
    setViewAddPaymentMethodModal, 
    
} from 'features/giving/givingSlice'
import { setViewConfirmModal2 } from 'features/modal/modalSlice'
import Loader from 'loaders/Loader'
import styled from 'styled-components'
import PaymentMethodItem from './PaymentMethodItem'
import { Modal } from '@mui/material'
import ConfirmModal from 'components/modals/ConfirmModal'
import AddPaymentMethodModal from './PaymentMethodModal/AddPaymentMethodModal' 
 import AddCircleIcon from '@mui/icons-material/AddCircle';
import PaymentMethodBankItem from './PaymentMethodBankItem'
import { CiCreditCardOff } from "react-icons/ci";
import { CiBank } from "react-icons/ci";
import {  Elements } from '@stripe/react-stripe-js';
import AddBankModal from './PaymentMethodModal/AddBankModal'
import { pkStripe } from 'features/constants'
import {loadStripe} from '@stripe/stripe-js'; 
 

// const stripePromise = loadStripe( pkStripe, {  stripeAccount: sessionStorage.getItem('cpk') } );


export default function PaymentMethods() {
    const dispatch = useDispatch()
    const { profileChurch } = useSelector((state) => state.profileChurch)

    // const stripePromise = loadStripe( pkStripe,  { stripeAccount: profileChurch.stripeId } );
        // wrap the stripePromise in a useMemo to prevent it from being recreated on every render
        const stripePromise = useMemo(
            () => loadStripe( pkStripe,  { stripeAccount: profileChurch.stripeId } ), 
            [profileChurch.stripeId]
        )

    const { user } = useSelector((state) => state.auth)
    const { 
        confirmModalData, 
        viewConfirmModal, 
        confirmModalLocation 
    } = useSelector((state) => state.modal)

    const { 
        getPaymentMethodsLoading, 
        paymentCards, 
        viewAddPaymentMethodModal,
        removePaymentMethodSuccess,
        removePaymentMethodLoading,
        usBanks,
        createFinancialConnectionPaymentIntentClientSecret
    } = useSelector((state) => state.giving)

    useEffect(() => {  
        if(paymentCards.length === 0 && usBanks.length === 0){ 
            dispatch(getMemberPaymentMethods({ churchId: profileChurch._id,  }))
        }
    }, [])

    const handleAddPaymentMethod = () => {
        dispatch(setViewAddPaymentMethodModal('start'))
    }

    if(getPaymentMethodsLoading){
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: '100%', width: '100%' }} >
                <Loader />
            </div>
        )
    }else{ 
        return (
            <ViewContainer>
                <PMContainer> 
                    <AddCardButton onClick={handleAddPaymentMethod}  >
                        <AddCircleIcon /> Add a payment method
                    </AddCardButton>
                <BankWrap>
                    <h2>Your saved bank accounts</h2>
                    {
                        usBanks && usBanks.length > 0 ? 
                        ( 
                            usBanks.map((bank, index) => (
                                <PaymentMethodBankItem  
                                    bank={bank} 
                                    key={bank.id}
                                />
                            ))
                        ) : !getPaymentMethodsLoading && 
                        (
                            <section>
                                <CiBank style={{fontSize: '30px', marginRight: '10px'}} /> No bank accounts saved
                            </section>
                        )
                    }
                </BankWrap>

                <CardsWrap>
                    <h2>Your saved cards</h2>
                    {
                        paymentCards && paymentCards.length > 0 ? ( 
                            paymentCards.map((card, index) => (
                                <PaymentMethodItem  card={card} key={card.id}  /> 
                            )) 
                        ): !getPaymentMethodsLoading && (
                            <section>
                                <CiCreditCardOff style={{fontSize: '30px', marginRight: '10px'}} />  No saved cards 
                            </section>
                        )
                    }
                </CardsWrap>  
                { 
                    stripePromise && createFinancialConnectionPaymentIntentClientSecret &&
                    (  
                        <Elements 
                            stripe={stripePromise} 
                            options={{ clientSecret: createFinancialConnectionPaymentIntentClientSecret }}
                            >
                            <AddBankModal />
                        </Elements>
                    ) 
                }
                </PMContainer>  
              
                <Modal
                    open={viewAddPaymentMethodModal}
                    onClose={() => dispatch(setViewAddPaymentMethodModal('')) }
                    >
                    <div
                        style={{
                            display: 'flex', margin: '0 auto', flexDirection: 'column',
                            justifyContent: 'center',  alignItems: 'center', height: '100vh', width: 'fit-content',
                        }}
                        >
                        <AddPaymentMethodModal />  
                    </div>
                </Modal>
                <Modal
                    open={viewConfirmModal && confirmModalLocation === 'paymentMethodItem'}
                    onClose={() => dispatch(setViewConfirmModal2({ view: false, location: '' }))}
                    >
                    <div
                        style={{
                            display: 'flex', margin: '0 auto', flexDirection: 'column',
                            justifyContent: 'center',  alignItems: 'center', height: '100vh', 
                            width: '100vw',
                        }}
                        >
                        <ConfirmModal
                            text={confirmModalData.text}
                            handler={confirmModalData.handler}
                            confirmBtnBackgroundHover={'#ff0000'}
                            conditionToCloseModal={removePaymentMethodSuccess}
                            loadingState={removePaymentMethodLoading}
                            />
                    </div>

                </Modal>
            </ViewContainer>
        )
    }
}

const BankWrap = styled.div`    

    display: flex;  
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    color: var(--lightThemeFontColor);

    h2{
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 10px;
    }

    section{ 
        margin-top: 10px;
        height: fit-content;
        color: #ccc;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

`

const CardsWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    color: var(--lightThemeFontColor);

    h2{
        font-weight: 500;
        font-size: 16px; 
    }

    section{ 
        margin-top: 10px;
        height: fit-content;
        color: #ccc;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

`

const PMContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
`

const AddCardButton = styled.button`
    background-color: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #585858; 
    border: 2px dashed #909090; 
    padding: 10px;
    height: 100px;
    width: 300px;
    border-radius: 20px; 
    transition: 0.3s;
    cursor: pointer;
    svg{
        margin-right: 10px;
    }
 
`