import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { SubmitButton } from 'components/giving/giving.styled';
import { useDispatch, useSelector } from 'react-redux';
import { createAccount, setstartCreatingAccount } from 'features/giving/givingSlice';
import SpinnerSmall from 'components/SpinnerSmall';
import { toast } from 'react-toastify';
import { givingLanugauge } from './givingSettingsLanguage';

export default function CreateConnectAcctForm() {

    const dispatch = useDispatch()
    const [country, setCountry] = useState('');
    const [accountType, setAccountType] = useState('');
    const { user } = useSelector(state => state.auth)

    const { createAccountLoading, createAccountSuccess, createAccountError } = useSelector(state => state.giving)

    const handleCountry = (event) => {
        setCountry(event.target.value);
    };
  
    const handleAccountType = (event) => {
        setAccountType(event.target.value);
    }

    const handleSubmit = () => {

        if(!country){
            return toast.error(givingLanugauge.toastErrorSelectCountry[user.preferredLanguage])
        }

        if(!accountType){
            return toast.error(givingLanugauge.toastErrorSelectAccountType[user.preferredLanguage])
        }

        dispatch(createAccount({
            country: country,
            accountType: accountType
        }))
    }

    useEffect(() => {
        if(createAccountSuccess){
            dispatch(setstartCreatingAccount(false))
        }
    }, [createAccountSuccess])


    return (
        <CnctActForm>
            <FormControl sx={{ m: 1, minWidth: 160 }} size="small">
                <InputLabel id="demo-select-small-label">
                    {
                        givingLanugauge.country[user.preferredLanguage]
                    }
                </InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={country}
                    label={givingLanugauge.country[user.preferredLanguage]}
                    onChange={handleCountry}
                > 
                    <MenuItem value={'US'}>
                        {
                            givingLanugauge.unitedStates[user.preferredLanguage]
                        }
                    </MenuItem>
                    <MenuItem value={'MX'}>
                        {
                            givingLanugauge.mexico[user.preferredLanguage]
                        }
                    </MenuItem>
                    <MenuItem value={'CA'}>
                        {
                            givingLanugauge.canada[user.preferredLanguage]
                        }
                    </MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 160 }} size="small">
                <InputLabel id="demo-select-small-label">
                    {
                        givingLanugauge.accountType[user.preferredLanguage]
                    }
                </InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={accountType}
                    label="Account Type"
                    onChange={handleAccountType}
                > 
                    <MenuItem value={'company'}>
                        {
                            givingLanugauge.company[user.preferredLanguage]
                        }
                    </MenuItem>
                    <MenuItem value={'individual'}>
                        {
                            givingLanugauge.individual[user.preferredLanguage]
                        }
                    </MenuItem>
                    <MenuItem value={'non_profit'}>
                        {
                            givingLanugauge.non_profit[user.preferredLanguage]
                        }
                    </MenuItem>
                </Select>
            </FormControl>

            <div
                style={{
                    width: '90%',
                    textAlign: 'left',
                    marginTop: '10px', 
                    fontSize: '12px'
                
                }}
                >
                {
                    givingLanugauge.description[user.preferredLanguage]
                }
            </div>

            <SubmitButton 
                disabled={createAccountLoading}
                onClick={handleSubmit}
                style={{
                    marginTop: '20px' 
                }}
                >
                {
                    createAccountLoading ? 
                    (
                        <SpinnerSmall   />
                    )
                    :
                    (
                        givingLanugauge.continue[user.preferredLanguage]
                    )
                }
            </SubmitButton> 
        </CnctActForm>
    )
}

const CnctActForm = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
`