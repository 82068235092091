import {useState} from 'react'
import styled from 'styled-components'
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { resetMemberSearchTerm, searchMembers, searchFellowMembers, setMemberSearchTerm } from 'features/memberships/membershipSlice';
import { useSelector, useDispatch } from 'react-redux'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'; 
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { toast } from 'react-toastify';
import { settingsLanguage } from '../settingsLanguage';

function MemberSearch({searchForFellowMembers}) {
  const dispatch = useDispatch()
  const [searchVal, setSearchVal] = useState('')
  const { searchTerm, isMemberSearchActive, isMbsSearchLoading } = useSelector((state) => state.memberships2)
  const [searchTermHover, setSearchTermHover] = useState()
  const [isFocused, setIsFocused] = useState(false)
  const { user } = useSelector((state) => state.auth)

  const handleSearch = (e) => {
    e.preventDefault()
    
    if(searchVal.length <= 2){
      return toast.error('Enter a name to search', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      })
    }

    dispatch(setMemberSearchTerm(searchVal))


    
    if(searchForFellowMembers){

      dispatch(searchFellowMembers({
          searchVal: searchVal 
      }))


    }else{
      dispatch(searchMembers({
        searchVal: searchVal
      }))
    }

  }

  const handleClearSearch = () => {
    setSearchVal('')
    dispatch(resetMemberSearchTerm())
  } 

//in redux create a copy of the current directoryItems array so that
// when the user is done searching ( the original search will create a new array of directoryItems to be displayed )
// the original array of directory items will be returned

  return (
    <MSWrap>
      <MemberSearchForm
        isFocused={isFocused}
        >
          <MSInput 
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            value={searchVal} 
            onChange={(e) => setSearchVal(e.target.value)} 
            placeholder={settingsLanguage.searchMembers[user.preferredLanguage]}
             /> 
          { isMbsSearchLoading ? (
            <SearchBtn> <HourglassEmptyIcon/> </SearchBtn> 
          )
          :
          (
            <SearchBtn 
                onClick={handleSearch} 
                // onSubmit={handleSearch}
                > 
                    <PersonSearchIcon/> 
            </SearchBtn> 
          )

          }

      </MemberSearchForm>
      {
            isMemberSearchActive && (
                  <SearchTermVal 
                    onMouseEnter={() => setSearchTermHover(true)} 
                    onMouseLeave={() => setSearchTermHover(false)}
                    onClick={handleClearSearch}
                    >

                            <ST>{searchTerm}</ST>
                        
                    <SearchOffIcon style={{
                      marginLeft: '0px',
                      marginTop: '0px',
                      display: 'flex', 
                      alignSelf: 'start',
                      justifySelf: 'start',
                      // fontSize: 'smaller', 
                      cursor: 'pointer', 
                      color: '#ff0000bf'}}/>
                  </SearchTermVal>      
            )
        }
    </MSWrap>

  )
}
const MSWrap = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) { 
    width: 100%; 
    flex-direction: row;
    margin-bottom: 5px;
    align-items: flex-start;
  }
`

const MemberSearchForm = styled.form`
    margin-left: 10px;
    padding: 0px;
    display: flex;
    justify-content: row;
    align-self: flex-start; 
    justify-self: flex-start;
    /* border: 1px solid #ccc; */
    width: fit-content;
    border-radius: 5px 5px 5px 5px;
    border: ${props => props.isFocused ? '1px solid #ccc' : 'none'};
    background-color: white;
    /* box-shadow: ${props => props.isFocused ? '0 0 5px #414141' : '0 0 10px #ccc'}; */
    box-shadow: ${props => props.isFocused ? '0 0 5px #929292' : '0 0 10px #ccc'};
    padding: 3px;
    transition: all 0.3s ease-in-out;
    @media (max-width: 768px) {
      margin-left: 0px;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 5px;
    }
`
const MSInput = styled.input`
    padding: 10px;
    outline: none;
    border: none;
    color: var(--lightThemeFontColor);
    border-radius: 5px 0px 0px 5px;
`
const SearchBtn = styled.button`
  cursor: pointer;
  color: #ffffff;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
  border: none;
  background-color: #898989;
  border-radius: 4px;
  &:hover{
     background-color: var(--ltGreenButton);
     color: white;
  }
`
const ST = styled.span`
  padding: 5px 8px;
  background-color: #00800091;
  color: white;
  min-width: 50px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 0 10px #ccc;

`
const SearchTermVal = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  align-self: center;
  justify-self: center;
  /* @media (max-width: 768px) {
    margin-left: 0px; 
    align-self: flex-end;
    justify-self: flex-end;
  } */
`

export default MemberSearch