import React, { useEffect } from 'react'
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, useMap } from 'react-leaflet'



export default function EventMap({markerTitle, markerPosition}) {

    return (
        <MapContainer 
            center={markerPosition} 
            zoom={13}  
            > 
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
            <Marker position={markerPosition}>
                <Popup>
                    {markerTitle}
                </Popup>
            </Marker> 
        </MapContainer>
    )
}
