import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { getChurchTransactions } from 'features/giving/givingSlice'
import styled from 'styled-components'
import { TransactionsTableContainer } from 'components/giving/giving.styled' 
import ChurchTransactionItem from './ChurchTransactionsItem'
import MemberGivingTransactions from './MemberGivingTransactions'
import CampaignTransactions from './CampaignTransactions'

export default function Transactions() {

    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { profileChurch } = useSelector(state => state.profileChurch)
    const { 
        transactions,
        getTransactionsLoading,
        getTransactionsSuccess,
        getTransactionsError,
    } = useSelector(state => state.giving)



    const [view, setView] = useState('giving')

    return (
        <TransactionsContainer>

            <TbtnWrap>
                <Tbtn
                    active={view === 'giving'}
                    onClick={() => setView('giving')}
                    >
                    Member Giving
                </Tbtn>
                    
                <Tbtn   
                    active={view === 'campaigns'}
                    onClick={() => setView('campaigns')}
                    >
                    Campaigns
                </Tbtn>            
            </TbtnWrap>


            {
                view === 'giving' ?
                (
                    <MemberGivingTransactions  />
                )
                :
                (
                    <CampaignTransactions />
                )
            }


        </TransactionsContainer>
    )
}
const TbtnWrap = styled.div` 
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;    
    margin-top: 20px;
`

const Tbtn = styled.button`
    color: ${props => props.active ? 'var(--themeColor)' : 'var(--lightThemeFontColor)'};
    font-weight: ${props => props.active ? '600' : '500'};
    border: ${props => props.active ? '2px solid var(--themeColor)' : '2px solid #f5f5f5'};
    padding: 10px 20px;
    background: #f5f5f5; 
    margin-right: 10px;
    cursor: pointer;
    border-radius: 5px; 

    &:hover {
        border: 2px solid #ccc;
    }
`

const TransactionsContainer = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

`