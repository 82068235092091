// import 'frontent/pages/messenger.css'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ConversationItem from './ConversationItem'
import { cleanupConversations, getConversations } from '../../features/messenger/conversationSlice'
import Loader from '../../loaders/Loader'
import NewConversationBtn from './startConversation/NewConversationBtn'
import NoConversations from './NoConversations'
import { getMyProfile } from '../../features/profile/profileSlice'
import styled from 'styled-components'
import Member2MemberConversationFeed from './Member2MemberConversationFeed'
import { ConversationFeed } from './zStyling.styled'
import { useState } from 'react'
import ConversationsFeed from './conversation/ConversationsFeed'
import ChurchFeedbacksFeed from 'components/churchFeedback/ChurchFeedbacksFeed'

function Conversations() {

    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { conversations, otherMemberConversations, isConversationsLoading, isConversationsSuccess } = useSelector(state => state.conversations)
    const { myProfile, isMyProfileLoading } = useSelector((state) => state.profile) 
    const [view, setView] = useState('conversations')

    useEffect(() => { 
        if(!myProfile) {
            dispatch(getMyProfile())
        }
    }, [dispatch,  myProfile] )

    useEffect(() => {
        // unmount function
        return () => {

            // filter conversation ids

            if(user.isOrganization){

                let ids;

                if(conversations && conversations.length > 0){
                    ids = conversations.map((ele) => ele._id)
                

                    dispatch(cleanupConversations({ 
                        userType: 'church',
                        convIdsMC: JSON.stringify(ids),
                        convIdsMM: JSON.stringify([])
                    }))

                }

            }else{

                let mcs, mms;

                if(conversations && conversations.length > 0){
                    mcs = conversations.map((ele) => ele._id)
                }

                if(otherMemberConversations && otherMemberConversations.length > 0){
                    mms = otherMemberConversations.map((ele) => ele._id)

                    dispatch(cleanupConversations({ 
                        userType: 'user',
                        convIdsMC: JSON.stringify(mcs),
                        convIdsMM: JSON.stringify(mms)
                    }))                  

                }

            }
        }
    }, [])

    // useEffect(() => {
    //     // get unread conversations
    //     let newMessages = JSON.parse(localStorage.getItem('_8ts2ccx00'))
    //     if(newMessages.length > 0) {
    //         setNewMessagesArray(newMessages)
    //     }
    //     console.log(newMessagesArray)
    // }, [])

  return (
    <ConversationsWrap>
        <ConvoTop>
            <MyConvos>
                {
                    !user.isOrganization &&
                    (
                        'My Conversations'
                    )
                }

            </MyConvos>
            <NewConversationBtn  />
        </ConvoTop>

        {
            user.isOrganization ? 
            (
                <ViewContainer>

                    <ViewTabs>
                        <ViewTab 
                            onClick={() => setView('conversations')} 
                            active={view === 'conversations'}
                            >
                                Conversations
                        </ViewTab>
                        <ViewTab 
                            onClick={() => setView('feedbacks')} 
                            active={view === 'feedbacks'}
                            >
                                Feedbacks
                        </ViewTab>
                    </ViewTabs>

                    {
                        view === 'conversations' ?
                        (
                            <ConversationsFeed />
                        )
                        :
                        (
                            <ChurchFeedbacksFeed />
                        )
                    }
                </ViewContainer>
            )
            :
            (
                <ConversationsFeed />
            )
        }
        
    </ConversationsWrap> 
  )
}

export default Conversations

const ViewTabs = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 10px;
`

const ViewTab = styled.div`
    pointer-events: ${props => props.active ? 'none' : 'auto'};
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 12px;
    color: ${props => props.active ? 'white' : 'var(--lightThemeFontColor)'};
    background-color: ${props => props.active ? 'var(--themeColor)' : 'transparent'};
    cursor: pointer;
    transition: 0.3s;
    &:hover{
        background-color: #d5d5d5;
    }
`

const ViewContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%; 

`

const ConversationsWrap = styled.div`
    width: 400px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #b8b8b8; 
    padding: 0px 10px;
    height: calc(100vh - 55px);
    @media (max-width: 768px) {
        min-width: unset;
        max-width: unset;
        width: 100%;
        height: calc(100vh - 77px);  
        border-right: unset; 
        overflow-y: scroll;
    }
`
const ConvoTop = styled.div` 
    width: 100%;
    display: flex;
    flex-direction: row;
    height: fit-content;
    align-items: center; 
    justify-content: space-between;
    padding: 10px;
    @media (max-width: 768px) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding-right: 10px;
    }
`

const MyConvos = styled.div` 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; 
    height: 100%;
    font-size: 11px;
    color: rgb(100,100,100);
    @media (max-width: 768px) {
        padding: 0px; 
        padding-left: 5px;
        margin-right: 10px;
        margin-top: 0px;
    }
`