import { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import EmailConfirmationModal from '../components/register/EmailRegisterModal'
import RegisterBody from 'components/register/RegisterBody'
import ChooseUserType from 'components/forms/ChooseUserType'
//https://dev.to/jlong4223/how-to-implement-email-functionality-with-node-js-react-js-nodemailer-and-oauth2-2h7m
//follow this for a more efficient implementation of nodemailer^^

import { useTransition } from 'react-transition-state';

const Box = styled.div`
  transition: all 500ms;

  ${({ status }) =>
    (status === 'preEnter' || status === 'exiting') &&
    `
      opacity: 0;
      transform: scale(0.9);
    `}
`;


export default function Register() {
 
  const { viewEmailConfirmModal  } = useSelector((state) => state.auth)
  const [{ status, isMounted }, toggle] = useTransition({
    timeout: 500,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  return ( 
    <RegisterPage>  
      {viewEmailConfirmModal && 
        (
          <EmailConfirmationModal />
        )
      } 
      {isMounted && 
        (
          <Box status={status}>
            <RegisterBody />
          </Box>
        )
      } 
      { !isMounted && 
        (
          <Box status={status}>
            <ChooseUserType 
              handler={toggle}
              />
          </Box>
        ) 
      }  
    </RegisterPage> 
  )
}


const RegisterPage = styled.div`
  width: 100%;
  position: relative;
  height: calc(100vh - 80px);
`