import styled from 'styled-components';

export const StyledSearchForm = styled.form` 
    display: flex;
    justify-content: row;
    align-self: flex-start; 
    justify-self: flex-start;
    box-shadow: ${props => props.isFocused ? '0 0 5px #414141' : '0 0 10px #ccc'};
    width: ${props => props.width ? props.width : 'fit-content'};
    border-radius: 6px 6px 6px 6px;
    background-color: white;
    padding: 4px;
    transition: all 0.3s ease-in-out;

    @media (max-width: 768px) {
        width: 100%;
    }
`

export const SearchInput = styled.input`
    padding: 5px;
    outline: none;
    border: none;
    border-radius: 5px 0px 0px 5px;
    @media (max-width: 768px) {
      width: 100%;
    }
`

export const SearchBtn = styled.button`
    cursor: pointer;
    color: #ffffff;
    padding-left: 5px;
    padding-right: 5px;
    outline: none;
    border: none;
    background-color: #898989;
    border-radius: 4px;
    &:hover{
        background-color: green;
        color: white;
    }
`

export const SearchResultsTitle = styled.div`

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export const ClearSearchBtn = styled.button`
    cursor: pointer;
    color: var(--lightThemeFontColor);
    background-color: #ccc;
    padding: 5px;
    border-radius: 4px;
    border: none;
    outline: none;

`