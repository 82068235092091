import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const URL_UPLOAD = '/api/bulletins/upload'
const URL_GET = '/api/bulletins/get'
const URL_DEL = '/api/bulletins/delete'

const config = (token) => { return { 
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}`, }, 
  } }
  

const sendInviteEmail = async (data, token) => {
    const response = await axios.post(URL+'/api/email/sendInviteEmail', data, config(token))
    return response.data
}

const addUserWithEmail = async (data, token) => {
    const response = await axios.post(URL+'/api/email/addUserWithEmail', data, config(token))
    return response.data
}

const addUsersWithEmailInBulk = async (data, token) => {
    const response = await axios.post(URL+'/api/email/addUsersWithEmailInBulk', data, config(token))
    return response.data
}

const sendGivingTransactionEmail = async (data, token) => {
    const response = await axios.post(URL+'/api/email/sendGivingTransactionEmail', data, config(token))
    return response.data
}

const bulletinService = {
    sendInviteEmail, addUserWithEmail, addUsersWithEmailInBulk,sendGivingTransactionEmail
}

export default bulletinService