import styled from "styled-components";

export const MediaSectionContainer = styled.div`

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start; 

`

export const MediaTitle = styled.div`
    margin-top: 10px;
    color: var(--lightThemeFontColor);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`

export const SortButtonsWrap = styled.div`
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; 
`

export const SortButton = styled.div`
    background-color: white;
    color: grey;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 5px #ccc;
    transition: background-color 0.3s;
    &:hover{
        background-color: #e1e1e1;
    }
    svg{
        margin-right: 10px;
    }
`

export const SearchWrap = styled.div`
    width: ${props => props.extendWidth ? '350px': '45px'};
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    background-color: white;
    border-radius: ${props => props.extendWidth ? '5px': '25px'};
    box-shadow: 0px 0px 5px #ccc;
    padding: 5px 5px;
    color: var(--lightThemeFontColor);
    transition: all 0.6s;  
    /* if focus change border */
    &:focus-within{
        box-shadow: 0px 0px 5px rgb(150,150,150);
    }
    @media (max-width: 768px) {
        width: ${props => props.extendWidth ? '170px': '45px'};
    }

`
export const  StyledSearchInput = styled.input`
    border: none;
    outline: none;
    width: ${props => props.extendWidth ? '100%': '0px'};
    height: 100%;
    background-color: transparent;
    color: var(--lightThemeFontColor);
    margin-left: 10px;
`
export const MediaControls = styled.div`

    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 10px;
`

export const SearchBtn = styled.button`
    cursor: pointer;
    color: #ffffff;
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;
    outline: none;
    border: none;
    background-color: #898989;
    border-radius: 4px;
    &:hover{
        background-color: green;
        color: white;
    }
`

export const MediaFeed = styled.div`

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: ${props => props.viewDirection };
    align-items: flex-start;
    justify-content: flex-start; 

    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    &::-webkit-scrollbar-thumb {
        background: #888; 
    }

`

export const MediaUpdateModal = styled.div` 
    position: relative;
    width: 500px;
    height: fit-content;
    max-height: 600px;

    overflow-y: scroll;

    /* hide scrollbar */

    /* width */
    ::-webkit-scrollbar {
        width: 0;
    }


    display: flex; 
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    padding-bottom: 40px;

    h1{
        font-size: 15px;
        color: var(--lightThemeFontColor);
        display: flex;
        align-items: center;
        font-weight: 600;
        margin-bottom: 20px;
    }

    @media (max-width: 768px) {
        width: 100%;
    }

`