import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SendIcon from '@mui/icons-material/Send';

import { deletePost,setSelectPostToViewPrayers, likePost, setQuickMessageData, unlikePost, savePost, setViewWhoIsPrayingModal } from '../features/posts/postSlice'
import TimeAgo from 'timeago-react'; 
import {  MdEmojiPeople } from 'react-icons/md'
import '../css/components/postItem.css'
import { setViewSendQuickMessageModal } from 'features/posts/postSlice'
import useToggleDropdown from '../hooks/useToggleDropdown';
import { FaPray } from 'react-icons/fa'
import { imgUrl } from 'features/constants';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { setConfirmModalData, setViewConfirmModal } from 'features/modal/modalSlice'; 
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import styled from 'styled-components'; 
import PostItemImage from './posts/PostItemImage';
import { addItemToPrayerWall } from 'features/prayerWall/prayerWallSlice';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { DotEle, DotsWrap } from './dots/dots.styled';
import { language } from 'app/language';

import * as timeago from 'timeago.js';
// import it first.
import es from 'timeago.js/lib/lang/es';

// register it.
timeago.register('es', es);

function PostItem( {index, post, postId, canViewWhoisPraying } ) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const ref1 = useRef(null)
  const [isDropped, setIsDropped] = useToggleDropdown(ref1)
  const handleDropDown = () => { setIsDropped.toggle(ref1) }
  const [postLikes, setPostLikes] = useState()
  const [liked, setLiked] = useState()
  const [color, setColor] = useState('gray')
  const { user, admin, moderator } = useSelector((state) => state.auth)
  const [profileImage, setProfileImage] = useState('generic.png') 
  const { profileChurch } = useSelector((state) => state.profileChurch)
  const [isUserInLikes, setIsUserInLikes] = useState(false)

  useEffect(() => {
    if (!user) {  navigate('/login') }    // if (isError) { console.log(message) }
    if(post.likes && post.likes.length > 0){setPostLikes(post.likes.length)}else{ setPostLikes(0)}
    if(post.user.profileImg && !post.isAnonymous ){ setProfileImage(post.user.profileImg.imagePath2)}

    let obj = post.likes.find( ele => ele.toString() === user._id)

    if(obj){
      setIsUserInLikes(true)
    }else{ 
      setIsUserInLikes(false)
    }

}, [post.likes, user, navigate, post.isAnonymous, post.user, profileChurch.moderators, profileChurch])

  useEffect(() => {
    if(isUserInLikes){setColor('#00B4CC')}
  }, [isUserInLikes])

  const handleLikePost = () => {

    if(isUserInLikes){     
      if(canViewWhoisPraying && post.likes.length > 0){ 

        dispatch(setSelectPostToViewPrayers({
          id: post._id,
          usersPraying: post.likes
        }))

        //show modal with users who are praying
        dispatch(setViewWhoIsPrayingModal(true))
        return 
      } 
    }else{
      dispatch(likePost({
        postId: post._id,
        index: index
      }))      
    } 
  }

  const handleUnLikePost = () => {
    dispatch(unlikePost({
      postId: post._id,
      isPublic: false,
      index: index
    }))
    setPostLikes(postLikes - 1)
    setIsUserInLikes(false)
  }

  let newDate = new Date(post.createdAt)
  const handleGoToPost = () => {
    // dispatch(postReset())
    navigate(`/posts/${postId}`)
  
  }  
  const handleSavePost = () => {
    dispatch(savePost({ 
      postId: post._id,
      index: index
    }))
  }
  const handleDelete = () => { 
    dispatch(setConfirmModalData({ 
      text: 'Are you sure you want to delete this post?',
      handler: () => dispatch(deletePost({  postId: post._id, index: index })),
      confirmBtnBackgroundHover: '#642419',
    }))

    dispatch(setViewConfirmModal(true))
  }

  const handleSendQuickMessage = () => {
    dispatch(setQuickMessageData({
      userId: post.user._id,
      fullName: post.user.fullName,
      userType: post.userType,
      profileImage:  post.user.profileImg.imagePath2,
    }))
    dispatch(setViewSendQuickMessageModal(true))
  }

  let profileImageToShow;
  try{  profileImageToShow =  `${imgUrl}/profile/${profileImage}` }
  catch(e){ profileImageToShow =  `${imgUrl}/profile/${profileImage}`}

  const [showType, setShowType] = useState(false) 

  const handleAddToPrayerWall = () => {
    dispatch(addItemToPrayerWall({
      churchId: profileChurch._id,
      postData: {
        postDate: post.createdAt,
        userId: post.user._id,
        fullName: post.user.fullName,
        postText: post.postText.text,
        postTextCondensed: post.postText.postTextCondensed ? post.postText.postTextCondensed : post.postText.text,
        userImage: post.user.profileImg.imagePath2
      },
      postId: post._id
    }))
  }

  return (
    <div 
      style={{zIndex: `${Math.abs(99 - Number(index))}`}} 
      className='post' 
      onMouseEnter={()=>setShowType(true)} 
      onMouseLeave={() => setShowType(false)}
      >  
      <div className='postInfo'>
        <div className='postItemDate' > 
        <TimeAgo 
          datetime={newDate}
          locale={user.preferredLanguage}
          />
        </div> 
        <div className="postItemDots" ref={ref1}>
          <div onClick={handleDropDown}><MoreHorizIcon /></div>
            { isDropped && (
              <DotsWrap> 
                  <DotEle onClick={handleGoToPost}> 
                    <NewspaperIcon className='postItemDotsIcon'/> 
                    {language.viewPost[user.preferredLanguage]}
                  </DotEle>

                  {
                    !post.isAnonymous && post.user._id !== user._id &&
                      <DotEle onClick={handleSendQuickMessage}> 
                        <SendIcon className='postItemDotsIcon'/> 
                        Send Message
                      </DotEle>
                  }
                  <DotEle onClick={handleSavePost}> 
                    <SaveAltIcon className='postItemDotsIcon'/>
                    {language.save[user.preferredLanguage]} 
                  </DotEle>
                  { (post.user._id === user._id || post.postedToId.toString() === user._id || admin[profileChurch._id] ||  moderator[profileChurch._id]) && (
                      <>
                        <DotEle onClick={handleAddToPrayerWall}> 
                        <FormatListBulletedIcon className='postItemDotsIcon'/>
                         {language.addToPrayerWall[user.preferredLanguage]}
                        </DotEle>
                        <DotEle style={{color: 'red'}} onClick={handleDelete}> <DeleteOutlineIcon className='postItemDotsIcon' /> 
                          {language.delete[user.preferredLanguage]}
                        </DotEle>
                      </>
                    )
                  }
              </DotsWrap>
            )}
          </div>
        </div>
        <div className="postItemTop">
          <div className='postUser'>
            <div className='postItemUserImg' > 
              {
                !post.isAnonymous ? 
                (
                  <img className="postImageImg" src={profileImageToShow} alt="u" /> 
                )
                :
                (
                  <AccountCircleIcon 
                    style={{
                      color: 'var(--lightThemeFontColor)',
                      width: '35px',
                      height: '35px',
                      }}
                    />
                )
              }
            </div>
            <div className='postItemName' >
              { post.isAnonymous ? (<i>Anonymous</i>) : (post.user.fullName) }  
              <StyledMTA>
                <TimeAgo datetime={newDate}/>
              </StyledMTA>
            </div>

            <PostItemType
              style={{
                backgroundColor: post.postType === 'Prayer Request' ? '#46801fac' : post.postType === 'Praise' ? '#f5a623ac' : '#4a90e2ac' 
              }}
              visible={true}
              // visible={showType}
              >

                {language[post.postType][user.preferredLanguage]}

            </PostItemType> 
          </div>
        </div>

          { (post.hasImage) ? (
              <div className="postItemMiddle" >
                <div className='postImageWrap' onClick={handleGoToPost}> 
                  <PostItemImage image={post.image} />
                </div>
                <div className='postText' >{post.postText.text}</div>
              </div>
            )
            :
            (
              <div className="postItemMiddle" >
                <div className='postTextNoImage' style={{display: 'flex', flexDirection: 'column'}} >
                  {post.postText.text.split('\n').map((subtext) => {
                    return (
                      <span
                        style={{
                          display: 'flex', 
                          marginTop: '5px'
                        }}
                        >
                        {subtext}  
                      </span>
                    )
                  })} 
                </div>
              </div>
            )
          }
        <div className='postItemBottom'>
          <div className='postItemActions'>
            <div  className='commentSectionIcon'  onClick={handleGoToPost}>  
                  <QuestionAnswerIcon />
                  <div className='commentSectionIconText' >
                    {post.comments.length > 0 ? 
                      (
                        language.comments[user.preferredLanguage]
                      )
                      :
                      (
                        language.comment[user.preferredLanguage]
                      )
                    }
                  </div>
                  <div style={{ fontSize: '12px', paddingLeft: '5px'}} > { post.comments.length !== undefined && post.comments.length > 0 && (` (${post.comments.length})`)} </div>
            </div>
            
            { post.postType === 'Prayer Request' ? 
            ( 
              <PrayerButtonContainer
                isUserPraying={isUserInLikes}
                onClick={handleLikePost}
                className='commentSectionIcon'
                >
                <FaPray /> 
                {
                  canViewWhoisPraying && post.likes > 0 ? (
                    <div>
                      {language.seeWhoIsPraying[user.preferredLanguage]}
                    </div>
                  )
                  : isUserInLikes ? 
                  (
                    <div className='commentSectionIconText' >
                      {language.youArePraying[user.preferredLanguage]}
                    </div>
                  )
                  :
                  (
                    <div className='commentSectionIconText' > Pray </div>
                  )
                } 
              </PrayerButtonContainer>
            )
            :
            (
              isUserInLikes ? 
              (
                <div className='commentSectionIcon'  onClick={handleUnLikePost} >    
                    <MdEmojiPeople style={{color: `${color}`, fontSize: '24px'}} />
                    {language.praisingTheLord[user.preferredLanguage]} 
                  </div>
              )
              :
              (
                <div className='commentSectionIcon' onClick={handleLikePost}>    
                  <MdEmojiPeople
                    style={{ fontSize: '14px'}}  
                    onClick={handleLikePost}/> 
                  {language.praisingTheLord[user.preferredLanguage]}
                </div>
              )
            )
          } 
        </div>
      </div> 
    </div>
  )
}

const PrayerButtonContainer = styled.div`

  color: ${props => props.isUserPraying ? '#009eb3' : 'gray'};

  svg{ 
  
  }

`

const StyledMTA = styled.div`
  font-size: x-small;
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }

`

const PostItemType = styled.div`
    padding: 4px 8px;
    color: ${props => props.visible ? 'white' : 'transparent'};
    background-color: ${props => props.visible ? '#46801fac' : 'transparent'};
    border-radius: 4px;
    margin-left: 15px;
    font-size: x-small;
    transition: all 0.5s ease-in-out;
    @media (max-width: 768px) {
      margin-left: 10px;
      padding: 4px 4px;
    }
`

export default PostItem