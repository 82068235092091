import styled from "styled-components";

export const SortContainerButtons = styled.div`
padding: 10px;
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start; 

button{
    padding: 7px 10px;
    margin-right: 10px;
    border: none;
    background: #f4f4f4;
    color: var(--lightThemeFontColor);
    cursor: pointer;
    transition: 0.3s;
    border-radius: 5px;
    &:hover{
        text-decoration: underline;
    }
}

@media (max-width: 768px) {
    padding: 0px;
}

`

export const FIH = styled.h3`
    color: var(--lightThemeFontColor);
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
    width: 100%;
    text-align: left;

`

export const FileInputWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    border: 1px solid var(--borderColor);   
    border-radius: 5px;
    padding: 8px 15px;
    margin-bottom: 10px;
`


export const GoBackButton = styled.div`
    display: flex;
    margin-bottom: 26px;
    font-size: 13px;
    color: var(--lightThemeFontColor);
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    transition: 0.3s;
    width: fit-content;
    border-radius: 15px;
    background-color: rgb(235, 235, 235);
    padding: 8px 10px;

    svg{
        margin-right: 10px;
    }

    &:hover{ 
        background-color: rgb(220, 220, 220);
    }

`

export const FolderFeedContainer = styled.div`

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    flex-wrap: wrap; 
    height: fit-content;
 
    /* remove scrollbar */

    &::-webkit-scrollbar {
        width: 0px;
    }

    @media (max-width: 768px) {
        gap: 5px;
        
    }
`

export const FilesFeedContainer = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

`

export const FileModal = styled.div`
    z-index: 0;
    width: 500px;
    height: fit-content;
    background: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 1px;
    position: relative;
    animation: animate 0.3s;
    padding: 25px;
    @media (max-width: 768px) {
        align-self: center;
        justify-self: center;
        width: 100%;
        height: fit-content
    }
`

export const FileFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;

`

export const FileUploadInput = styled.input`
    width: 100%;
    color: var(--lightThemeFontColor);
    padding: 8px;
    outline: none;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    /* border: 1px solid var(--borderColor); */
`

export const FileUploadButton = styled.div`

    padding: 10px 15px;
    color: var(--themeColorBtnText); 
    border: 2px solid var(--themeColor); 
    width: ${props => props.width ? props.width : 'fit-content'};
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: 300ms;
    background-color: ${props => props.disabled ? `var(--themeColor)` : 'none'};
    pointer-events: ${props => props.disabled ? 'none' : 'all'};

    @media (max-width: 768px) {
        align-self: center;
        justify-self: center;
    }
    &:hover{ 
        color: white;
        border: 2px solid var(--themeColor);
        background-color: var(--themeColor);
    }
`
export const ButttonWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.justify ? props.justify : 'flex-end'};
    align-items: center; 
    margin-top: 20px; 
    padding: ${props => props.padding ? props.padding : '0px'};
`   

export const DroTitle = styled.div`
    width: 100px;
    display: flex;
    color: var(--lightThemeFontColor);
    align-items: center;    
    justify-content: center;
`   


export const FolderSelect = styled.div`

    width: 100%; 
    border-radius: 5px;
    /* border: 1px solid var(--borderColor); */
    margin-top: 10px;

`


export const FormWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
`

export const BulletinFile = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
    border-radius: 5px;
    justify-content: space-between;
    /* border: 1px solid var(--borderColor); */
`

export const BulletinFileChild1 = styled.div`
    display: flex;
    flex-direction: column;
    width: 65%;
    align-items: flex-start;
    
`

export const BulletinFileName = styled.div`
    background-color: #dfdfdfd0;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    color: var(--lightThemeFontColor);
`

export const ChooseFileBtn = styled.label` 
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    height: 40px;
    background-color: #0b550b;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.4s;
    font-size: small;
    justify-content: center;
    &:hover{
        background-color: green;
    }
`
