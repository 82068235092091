import React, { useState } from 'react'
import { useEffect } from 'react'
import styled from "styled-components"
import SelectFeedbackType from './SelectFeedbackType'
import FeedbackForm from './FeedbackForm'
import { useSelector } from 'react-redux' 
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


export default function FeedbackMain() {
  const [active, setActive] = useState()
  const [render, setRender] = useState(<SelectFeedbackType />)
  
  const { 
    isCreatefeedbackLoading, 
    isCreatefeedbackSuccess, 
    isCreatefeedbackError, 
    issueMessage }
   = useSelector((state) => state.feedback)

  const handleClick = (val) => {
    setActive(val)
  }

  useEffect(() => {
      switch (active){
        case 'ri':
          setRender(<FeedbackForm type={active} title={"Report Issue"} />)
        break;
        case 'fr':
          setRender(<FeedbackForm type={active} title={"Feature Request"} />)

        break;
        case 'cs':
          setRender(<FeedbackForm type={active} title={"Contact Support"} />)

        break;
        default: console.log('set')
      }
  }, [active])



  return (
    <SupportContainer>
      <FSHeading>Feedback and Support Center</FSHeading>

      <p>If you need help with an urgent matter, send us an email.</p>

      <ContactWrap>
        <CBCont onClick={() => handleClick("ri")} isActive={active === "ri" ? true : false } >Report Issue </CBCont>
        <CBCont onClick={() => handleClick("fr")} isActive={active === "fr" ? true : false } >Submit Feature Request</CBCont>
        <CBCont onClick={() => handleClick("cs")} isActive={active === "cs" ? true : false } >Contact Support</CBCont>
      </ContactWrap>


      {
        active && !isCreatefeedbackLoading && !isCreatefeedbackSuccess ? 
        (
          render
        )
        : isCreatefeedbackLoading && !isCreatefeedbackSuccess ? 
        (
          <div>sending feedback...</div>
        )
        : !isCreatefeedbackLoading && isCreatefeedbackSuccess ? 
        (
          <FBSuccess>
            <CheckCircleOutlineIcon  style={{fontSize: '50px', color: 'green', margin: '10px'}}
              />
            <span>
              Thank you for your submission. 
            </span>
          </FBSuccess>
        )
        : isCreatefeedbackError && 
        (
          <div>error occured try again later</div>
        )
      }
 
    </SupportContainer>
  )
}


const SupportContainer = styled.div`
  color: #777777;
  font-family: Arial, Helvetica, sans-serif;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`
const ContactWrap = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 668px) {
    flex-direction: column;
    align-items: center;
  }
`
const FSHeading = styled.h1`
  font-weight: lighter;
  margin-top: 30px;


`



const FBSuccess = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`



const CBCont = styled.div`

    border-radius: 5%;
    min-width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 14vh;
    width: 20vw;
    margin: 1%;
    cursor: pointer;
    transition: .4s;

    background-color: ${props => props.isActive ? "#36a13667" : "none"} ;
    border: ${props => props.isActive ? "1px solid green " : "1px solid lightgray"} ;
    color: ${props => props.isActive ? "green" : "#777777"} ;
    
    @media (max-width: 668px) {
        flex-direction: column;
        align-items: center;
        height: 10vh;
        width: 50vw;
    }
    &:hover{
        color: green;
        border: 1px solid green;
    }
`