import React, {useState, useRef, useEffect} from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { deleteChurchPerson, setViewConfirmModaliInAbout, addPersonAboutSectionX, setSocialLinksFromEditing, editChurchPerson } from 'features/profileChurch/profileChurchSlice'
import { imgUrl } from 'features/constants'; 
import AddIcon from '@mui/icons-material/Add';
import  useToggleDropdown  from 'hooks/useToggleDropdown';
import SocialMediaDropdown from './SocialMediaDropdown';
import Loader from 'loaders/Loader';
import { toast } from 'react-toastify';
import { aboutChurchStore } from 'app/aboutChurchStore';
import { setConfirmModalData, setViewConfirmModal } from 'features/modal/modalSlice';
import Modal from '@mui/material/Modal';
import ConfirmModal from 'components/modals/ConfirmModal';

export default function AddPersonForm() {
    const personDetails = aboutChurchStore(state => state.personDetails)
    const isEditingCard = aboutChurchStore(state => state.isEditingCard)
    const setViewAddPersonModal = aboutChurchStore(state => state.setViewAddPersonModal)
    const personEditDetails = aboutChurchStore(state => state.personEditDetails)
    const setViewPersonCardModal = aboutChurchStore(state => state.setViewPersonCardModal)
    const setIsEditingCard = aboutChurchStore(state => state.setIsEditingCard)
    const setPersonEditDetails = aboutChurchStore(state => state.setPersonEditDetails)
    const setPersonDetails = aboutChurchStore(state => state.setPersonDetails) 
    const dispatch = useDispatch() 
    const [ isDropdownOpen, setIsDropdownOpen ] = useToggleDropdown()

    const [otherRole, setOtherRole] = useState({
        status: false,
        value: ''
    })
    const { viewConfirmModal, confirmModalData }  = useSelector((state) => state.modal)

    const dropdownRef = useRef(null)
    const { 
        profileChurch, 
        addPersonLinks, 
        linkIcons,
        addingPersonInAboutLoading,
        addingPersonInAboutSuccess,
        deletingPersonInAboutSuccess,
        deletingPersonInAboutLoading
    } = useSelector((state) => state.profileChurch)

    const { user, admin } = useSelector((state) => state.auth)

    const [fullName, setFullName ] = useState("")
    const [role, setRole ] = useState("")
    const [bio, setBio ] = useState("") 
    const [phone, setNumber ] = useState("")
    const [ imgPrev, setImgPrev ] = useState('')
    const [ imageToUpload, setImageToUpload ] = useState('')
    const [ isImageChanged, setIsImageChanged ] = useState(false)

    const [email, setEmail ] = useState("")

    const handleDropdown = () => {
        setIsDropdownOpen.toggle(dropdownRef) 
    }
    

    const handleDelete = () => {
        // setViewPersonCardModal(false)
        dispatch(setConfirmModalData({
            text: `Are you sure you want to delete ${personDetails.fullName} ?`,
            handler: () => {
                dispatch(deleteChurchPerson({
                    index: personDetails.index,
                    personName: personDetails.fullName,
                    churchId: profileChurch._id,
                }))
            },
            closeHandler: () => {dispatch(setViewConfirmModal(false))},
            confirmBtnBackgroundHover: 'red',
            extraInfo: 'This action cannot be undone.'
        }))
        dispatch(setViewConfirmModal(true))
    }



    const handleRole = (e) => {
        if(e.target.value !== "other") { 
            setRole(e.target.value)
            setOtherRole({status: false, value: ''})
        } else {
            setOtherRole({status: true, value: ''})
            setRole('')
        } 
    }

    const handleImageChange = (e) => { 

        if(isEditingCard){
            setIsImageChanged(true)
        }

        let url = URL.createObjectURL(e.target.files[0])
        setImgPrev({ url: url })
        setImageToUpload(e.target.files[0])
    }

    const handleSubmit = () => {
        const formData = new FormData(); 

        if(!imageToUpload && !isEditingCard) { return toast.error("Please add at least an image, name and role") }
        if(!fullName) { return toast.error("Please add at least an image, name and role") }
        if(!role && !otherRole.value) { return toast.error("Please add at least an image, name and role") } 
        if(bio.length > 2000) { return toast.error("Bio should be less than 2000 characters") } 

        formData.append("churchId", profileChurch._id)
        formData.append("fullName", fullName);
        formData.append("phone", phone)
        formData.append("role", role ? role : otherRole.value)
        formData.append("bio", bio) 
        formData.append("email", email)
        formData.append("links", JSON.stringify(addPersonLinks))

        if(isEditingCard){
            if(isImageChanged){
                formData.append("file", imageToUpload)
            }

            formData.append("id", personEditDetails._id)

            return dispatch(editChurchPerson(formData))
        }else{
            formData.append("file", imageToUpload)
            dispatch(addPersonAboutSectionX(formData))            
        }
    } 

    const isImgPreview = Object.keys(imgPrev).length > 0

    useEffect(() => {
        if(isEditingCard && personEditDetails) {
            setFullName(personEditDetails.fullName)
            setRole(personEditDetails.role)
            setBio(personEditDetails.bio)
            setNumber(personEditDetails.phone)
            dispatch(setSocialLinksFromEditing(personEditDetails.links))
        }
    }, [isEditingCard, personEditDetails])

    useEffect(() => {
       if(addingPersonInAboutSuccess){
        setViewAddPersonModal(false)
       }
    }, [addingPersonInAboutSuccess, setViewAddPersonModal])

    useEffect(() => {
        if(isEditingCard && deletingPersonInAboutSuccess) {
            
            setViewPersonCardModal(false)
            setIsEditingCard(false)
            setPersonEditDetails(null)
            setPersonDetails(null)
            setViewAddPersonModal(false)
        }
    }   , [isEditingCard, deletingPersonInAboutSuccess])

    return (
        <AddPersonFormContainer>  
            <ImageWrapPerson>
                <ImagePlaceholderPerson> 
                { imgPrev ? 
                    ( <ImgPreview src={imgPrev.url} alt="Add Image" /> )
                    : isEditingCard && personEditDetails ? 
                    (
                        <ImgPreview src={personEditDetails.image} alt="Add Image" />
                    )
                    :
                    ( 
                        <ImgPreview src={`${imgUrl}/aboutSection/generic/2024-01-01T14-24-59.780Z-person.png`} alt="Add Image" /> 
                    ) 
                } 
                </ImagePlaceholderPerson >
                <AddImageBtnsWrapPerson>
                    <AddImageBtnPerson htmlFor="imageUpload">
                        { isImgPreview ? 
                            (
                                <span>Replace Image</span>
                            )
                            : isEditingCard && personEditDetails ?
                            (
                                <span>Replace Image</span> 
                            )
                            :
                            (
                                <span>Upload Image</span>
                            )
                        }
                        <input hidden id="imageUpload" type="file" accept=".png, .jpg, .jpeg" name="file" onChange={handleImageChange}/>
                    </AddImageBtnPerson> 

                    { isImgPreview && (
                        <RemoveImagePerson onClick={() => { setImageToUpload(''); setImgPrev('')}}> Remove image </RemoveImagePerson>
                    )}

                </AddImageBtnsWrapPerson>
            </ImageWrapPerson>


            <RoleInput>
                <label for="role">Role</label>  
                 <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                 }}>
                          
                    <select id="role" onChange={handleRole}>
                        <option value="">--Please choose an option--</option>
                        <option selected={isEditingCard && personEditDetails.role === 'Pastor'} value="Pastor">Pastor</option>
                        <option selected={isEditingCard && personEditDetails.role === 'Lead Pastor'} value="Lead Pastor">Lead Pastor</option>
                        <option selected={isEditingCard && personEditDetails.role === 'Associate Pastor'} value="Associate Pastor">Associate Pastor</option>
                        <option selected={isEditingCard && personEditDetails.role === 'Youth Pastor'} value="Youth Pastor">Youth Pastor</option>
                        <option selected={isEditingCard && personEditDetails.role === 'Childrens Pastor'} value="Childrens Pastor">Childrens Pastor</option>
                        <option selected={isEditingCard && personEditDetails.role === 'Administrative Pastor'} value="Administrative Pastor">Administrative Pastor</option>
                        <option value="other">Other</option>
                    </select>
                    {
                        otherRole.status &&
                            (
                                <input
                                    style={{ padding: '10px', marginLeft: '10px', borderRadius: '5px', border: '1px solid grey', outline: 'none'}}
                                    onChange={(e) => setOtherRole({status: true, value: e.target.value})}
                                    type="text" id="role"  name="role" placeholder='Enter other role...'  />
                            )
                    }
                </div> 
            </RoleInput>
            <TextInput>
                <label for="name">Name</label>
                
                <input
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    type="text" id="name"  name="name"  />
            </TextInput>
            <TextInput>
                <label for="bio">Bio</label>
                <textarea 
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                    id="bio" name="bio" rows="4" cols="50"></textarea>
            </TextInput>
            <TextInput>
                <label for="email">Email</label>
                <input 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text" id="email" name="email"/>
            </TextInput> 
            <TextInput>
                <label for="number">Phone Number</label>
                <input 
                    value={phone}
                    onChange={(e) => setNumber(e.target.value)}
                    type="text" id="number" name="number"/>
            </TextInput> 
            <TextInput>
                <label for="social">Links</label>
                <SocialButtonWrap>
                    <AddSocialButton ref={dropdownRef} onClick={handleDropdown}><AddIcon/></AddSocialButton>

                        { isDropdownOpen && ( 
                                <SocialMediaDropdown dropdownRef={dropdownRef} />
                            )
                        } 
                </SocialButtonWrap>
            </TextInput> 
            <SocialLinkWrap >
            {
                // loop through object of links and display those that have a value
                Object.keys(addPersonLinks).map((key, index) => {
                    if(addPersonLinks[key]){
                        return (
                            <SocialLinkIcons>
                                <SocialLinkLabel>{linkIcons[key]}</SocialLinkLabel>
                                <SocialLink>{addPersonLinks[key]}</SocialLink>
                            </SocialLinkIcons>
                        )
                    }
                }
                )
            }
            </SocialLinkWrap>
            {/* <div>{JSON.stringify(addPersonLinks)}</div> */}


            <SubmitPersonBtn 
                disabled={addingPersonInAboutLoading}
                onClick={handleSubmit}>  
                { addingPersonInAboutLoading ? 
                    (
                        <span> 
                            <Loader />
                        </span>
                    ) 
                    : isEditingCard ?
                    (
                        <span> Update </span>
                    )
                    :
                    (
                        <span>
                            Add Person
                        </span>
                    )
                }
            </SubmitPersonBtn> 
            {

            ((isEditingCard && user && (user.isOrganization || admin[profileChurch._id])) && (
                <DeletePerson
                    onClick={handleDelete}
                    >
                    Delete This Entry
                </DeletePerson>
            ))
            }


            <Modal
                open={viewConfirmModal}
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                onClose={() => dispatch(setViewConfirmModal(false))}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition 
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
                >
                    <ConfirmModal 
                        text={confirmModalData.text}
                        hasCloseHandler={true}
                        handler={confirmModalData.handler}  
                        closeHandler={confirmModalData.closeHandler}  
                        confirmBtnBackgroundHover={confirmModalData.confirmBtnBackgroundHover}
                        extraInfo={confirmModalData.extraInfo}
                        loadingState={deletingPersonInAboutLoading}
                        conditionToCloseModal={deletingPersonInAboutSuccess}
                        />
            </Modal>    



        </AddPersonFormContainer>   
    )
}

const DeletePerson = styled.div`
    cursor: pointer; 
    margin-top: 20px;
    bottom: 20px;
    right: 20px;
    width: fit-content;
    background-color: red;
    padding: 10px 15px;
    border-radius: 5px;
    color: white;
    font-size: 11px;
    &:hover{
        
        text-decoration: underline;
    }
`

const AddPersonFormContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    @media (max-width: 768px) {
        padding: 10px;
    }
`
const RoleInput = styled.div`
    margin-top: 20px;
    width: 100%; 
    display: flex;
    flex-direction: column; 
    align-items: flex-start;
    justify-content: flex-start;
    select{
        border: 1px solid grey;
        padding: 10px;
        outline: none;
        border-radius: 5px;
    }
    option{
        padding: 10px;
    }
`
const TextInput = styled.div`
    margin-top: 20px;
    width: 80%;
    height: fit-content;
    display: flex;
    flex-direction: column; 
    align-items: flex-start;
    justify-content: flex-start;
    input{
        border: 1px solid grey;
        padding: 10px;
        outline: none;
        border-radius: 5px;
        width: 100%
    }
    textarea{
        border: 1px solid grey;
        padding: 10px;
        outline: none;
        border-radius: 5px;
        font-family: Arial, Helvetica, sans-serif;
        width: 100%
    }

`

const AddImageBtnsWrapPerson = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
const RemoveImagePerson = styled.div`
    padding-left: 5px;
    cursor: pointer;
    font-size: 11px;
    color: rgb(80,80,80);
    &:hover{
      text-decoration: underline;
    }
`
const ImagePlaceholderPerson = styled.div`
    width: 100%;
    height: auto;
    background-color: rgb(200,200,200);
    border: 1px solid grey;
    margin-bottom: 2px;
    border-radius: 8px;
`

const ImageWrapPerson = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    cursor: pointer;
`

const ImgPreview = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 8px;
`

const AddImageBtnPerson = styled.label`
    display: flex;
    align-self: center;
    font-size: 10px;
    height: fit-content;
    width: fit-content;
    padding: 10px;
    background-color: grey;
    color: white;
    border-radius: 5px;
    margin: 2px;
    cursor: pointer;
    &:hover{
        background-color: green;
      
    }
`

const SocialButtonWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:    flex-start;
    align-items: center;
    position: relative;
`
const AddSocialButton = styled.div` 
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    height: 30px;
    width: 30px; 
    background-color: grey;
    color: white;
    border-radius: 5px; 
    cursor: pointer;
    &:hover{
        background-color: green;
    }
`

const SubmitPersonBtn = styled.div`
    margin-top: 20px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--btnSubmit);
    color: white;
    border-radius: 5px;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    cursor: pointer;
    &:hover{
        opacity: 0.8;
    }
`



const SocialLink = styled.div`
    margin-left: 10px;
    font-size: 12px;
    height: fit-content;
`

const SocialLinkLabel = styled.div`
`
const SocialLinkWrap = styled.div`
`

const SocialLinkIcons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
`