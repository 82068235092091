import axios from 'axios' 
import { baseUrl } from '../constants'
const URL = baseUrl  

 
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
}}

 
const getGivingCampaigns = async (data, token) => {
  const response = await axios.post(URL+'/api/givingCampaign/getGivingCampaigns', data, config(token))
  return response.data
}

const createGivingCampaign = async (data, token) => {
    const response = await axios.post(URL+'/api/givingCampaign/createGivingCampaign', data, config(token))
    return response.data
}

const getSingleCampaign = async (data, token) => {
    const response = await axios.post(URL+'/api/givingCampaign/getSingleCampaign', data, config(token))
    return response.data
}

const getSingleCampaignPublic = async (data) => {
    const response = await axios.post(URL+'/api/givingCampaign/getSingleCampaignPublic', data)
    return response.data
} 

const createCampaignGivingIntentMembers = async (data, token) => {
    const response = await axios.post(URL+'/api/givingCampaign/createCampaignGivingIntentMembers', data, config(token))
    return response.data
}

const createCampaignGivingIntentPublic = async (data) => {
    const response = await axios.post(URL+'/api/givingCampaign/createCampaignGivingIntentPublic', data)
    return response.data
}

const getDonationsByCampaign = async (data) => {
    const response = await axios.post(URL+'/api/givingCampaign/getDonationsByCampaign', data)
    return response.data
}

const updateCampaignTotals = async (data) => {
    const response = await axios.post(URL+'/api/givingCampaign/updateCampaignTotals', data)
    return response.data
}


const givingCampaignService = {
    getGivingCampaigns, createGivingCampaign, getSingleCampaign, getSingleCampaignPublic,
    createCampaignGivingIntentMembers, createCampaignGivingIntentPublic, getDonationsByCampaign,
    updateCampaignTotals    
}
export default givingCampaignService