import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const URL_CREATE = '/api/directory/createDirectoryItem'
const URL_GET = '/api/directory/getDirectory'
const URL_DELETE = '/api/directory/delete'
const URL_UPDATE = '/api/directory/update'
const URL_GET_MINE = '/api/directory/getMyDirectory'
const URL_ADDMINE = '/api/directory/addMyDirectory'
const URL_SEARCH = '/api/directory/search'
const URL_GET_STATUS = '/api/directory/getDirectoryStatus'

//for sending the token
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }

//---------------------------------------------------------------

const getDirectory = async (data, token) => {
  const response = await axios.post(URL+URL_GET, data, config(token))
  return response.data
}

const addMyDirectory = async (data, token) => {
  const response = await axios.post(URL+URL_ADDMINE, data, config(token))
  return response.data
}


const getMyDirectory = async (data, token) => {
  const response = await axios.post(URL+URL_GET_MINE, data, config(token))
  return response.data
}

const createDirectoryItem = async (data, token) => {
  const response = await axios.post(URL+URL_CREATE, data, config(token))
  return response.data
}

const deleteDirectoryItem = async (data, token) => {
  const response = await axios.post(URL+URL_DELETE, data, config(token))
  return response.data
}

const updateDirectoryItem = async (data, token) => {
  const response = await axios.post(URL+URL_UPDATE, data, config(token))
  return response.data
}
const searchDirectory = async (data, token) => {
  const response = await axios.post(URL+URL_SEARCH, data, config(token))
  return response.data
}

const getDirectoryStatus = async (data, token) => {
  const response = await axios.post(URL+URL_GET_STATUS, data, config(token))
  return response.data
} 

const getBirthdays = async (data, token) => {
  const response = await axios.post(URL+'/api/directory/getBirthdays', data, config(token))
  return response.data
}
const directoryService = {
    getDirectory,
    createDirectoryItem,
    addMyDirectory,
    deleteDirectoryItem,
    updateDirectoryItem,
    getMyDirectory,
    searchDirectory,
    getDirectoryStatus,
    getBirthdays
}

export default directoryService