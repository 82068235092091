import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import billingService from './billingService'

const errorMessage = (error) => {
    return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
  }

const initialState = {
    billingPlan: null,

    isBillingError: false,
    isBillingSuccess: false,
    isBillingLoading: false,
    billingMessage: '',

    isUpdatePaymentLoading: false,
    isUpdatePaymentSucess: false,
    isUpdatePaymentError: false, 
    isGetUserDataLoading: false,
    isGetUserDataSuccess: false,
    isGetUserDataError: false,
    getUserDataMessage: '',
    userData: null,
    defaultPmId: null,
    memberCount: null,
    hasPaymentMethod: null,

    isCreateSubscriptionLoading: false,
    isCreateSubscriptionSuccess: false,
    isCreateSubscriptionError: false,
    createSubscriptionMessage: '',

    isGetSubscriptionLoading: false,
    isGetSubscriptionSuccess: false,
    isGetSubscriptionError: false,
    getSubscriptionMessage: '',


    subscription: null,
    subscriptionInterval: '',

    isGetTransactionsLoading: false,
    isGetTransactionsSuccess: false,
    isGetTransactionsError: false,
    getTransactionsMessage: '',
    transactions: [],

    isGetPaymentMethodsLoading: false,
    isGetPaymentMethodsSuccess: false,
    isGetPaymentMethodsError: false,
    paymentMethods: [],
    paymentIntent: null,
    clientSecret: null,

    isSetPaymentMethodsLoading: false,
    isSetPaymentMethodsSuccess: false,
    isSetPaymentMethodsError: false,

    billingPrice: '',
    billingInterval: '',
    
    billingView: null,
    viewAddCardModal: false,
    allowMakeDefaultOption: false, 
    manageSubscriptionModal: false,
    viewSubscribeModal: false,
} 

export const getUserData = createAsyncThunk(
    'billing/getUserData',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await billingService.getUserData(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
)

export const updateCard = createAsyncThunk(
  'billing/updateCard',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await billingService.updateCard(data, token)
    } catch (error) {
    const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const createSubscription = createAsyncThunk(
    'billing/createSubscription',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await billingService.createSubscription(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

export const getSubscription = createAsyncThunk(
    'billing/getSubscription',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await billingService.getSubscription(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
)

export const getPaymentMethods = createAsyncThunk(
    'billing/getPaymentMethods',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await billingService.getPaymentMethods(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
)

export const setPaymentIntent = createAsyncThunk(
    'billing/setPaymentIntent',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await billingService.setPaymentIntent(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
)

export const attachPaymentMethod = createAsyncThunk(
    'billing/attachPaymentMethod',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await billingService.attachPaymentMethod(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
)

export const getTransactions = createAsyncThunk(
  'billing/getTransactions',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await billingService.getTransactions(data, token)
    } catch (error) {
    const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

  export  const billingSlice = createSlice({
    name: 'billing',
    initialState,
    reducers: { 
        setBillingView: (state, action) => {
          state.billingView = action.payload
        },
        resetAddPaymentMethod: (state) => {
            state.isSetPaymentMethodsLoading = false
            state.isSetPaymentMethodsSuccess = false
            state.isSetPaymentMethodsError = false
        },
        setViewAddCardModal: (state, action) => { 
          state.allowMakeDefaultOption = action.payload
          state.viewAddCardModal = !state.viewAddCardModal 
        },
        setViewSubscribeModal: (state, action) => { 
          if(action.payload){
            state.viewSubscribeModal = action.payload.view
            state.billingPrice = action.payload.price
            state.billingInterval = action.payload.interval

          }else{
            state.viewSubscribeModal = !state.viewSubscribeModal 
          } 
        } 
      },
    extraReducers: (builder) => {
      builder
        .addCase(createSubscription.pending, (state) => {
            state.isCreateSubscriptionLoading = true
        })
        .addCase(createSubscription.fulfilled, (state, action) => {
          state.isCreateSubscriptionLoading = false
          state.isCreateSubscriptionSuccess = true
          state.subscription = action.payload
          // state.subscriptionInterval = action.payload.items.data[0].plan.interval
        })
        .addCase(createSubscription.rejected, (state, action) => {
            state.isCreateSubscriptionLoading = false
            state.isCreateSubscriptionError = true
            state.createSubscriptionMessage = action.payload
        })
        //------------------
        .addCase(getUserData.pending, (state) => {
            state.isGetUserDataLoading = false
        })
        .addCase(getUserData.fulfilled, (state, action) => {
            state.isGetUserDataLoading = false
            state.isGetUserDataSuccess = true
            state.userData = action.payload.id
            state.daysSinceCreated = action.payload.createdAt
            state.memberCount = action.payload.memberCount

            if(action.payload.invoice_settings){
              if(action.payload.invoice_settings.default_payment_method ){
                state.defaultPmId = action.payload.invoice_settings.default_payment_method
                state.hasPaymentMethod = true
              }
            }
        })
        .addCase(getUserData.rejected, (state, action) => {
            state.isGetUserDataLoading = false
            state.isGetUserDataError = true
            state.getUserDataMessage = action.payload
        })
        //------------------
        .addCase(updateCard.pending, (state) => {
            state.isUpdatePaymentLoading = false
        })
        .addCase(updateCard.fulfilled, (state, action) => {
            state.isUpdatePaymentLoading = false
            state.isUpdatePaymentSuccess = true
            // if(action.payload.deleted){

            // }
            state.paymentMethods = action.payload.paymentMethods.data
            state.userData = action.payload.userData
            state.defaultPmId = action.payload.defaultPmId
        })
        .addCase(updateCard.rejected, (state, action) => {
            state.isUpdatePaymentLoading = false
            state.isUpdatePaymentError = true
            state.getUserDataMessage = action.payload
        })
        //------------------
        .addCase(getSubscription.pending, (state) => {
          state.isGetSubscriptionLoading = false
        })
        .addCase(getSubscription.fulfilled, (state, action) => {
          state.isGetSubscriptionLoading = false
          state.isGetSubscriptionSuccess = true

          if(action.payload.data.length > 0){
            state.subscription = action.payload.data[0] 
            state.subscriptionInterval = action.payload.data[0].plan.interval
          }

        })
        .addCase(getSubscription.rejected, (state, action) => {
            state.isGetSubscriptionLoading = false
            state.isGetSubscriptionError = true
            state.getSubscriptionMessage = action.payload
        })
        //------------------
        .addCase(getPaymentMethods.pending, (state) => {
            state.isGetPaymentMethodsLoading = false
          })
        .addCase(getPaymentMethods.fulfilled, (state, action) => {
            state.isGetPaymentMethodsLoading = false
            state.isGetPaymentMethodsSuccess = true
            state.paymentMethods = action.payload.data
          })
        .addCase(getPaymentMethods.rejected, (state, action) => {
              state.isGetPaymentMethodsLoading = false
              state.isGetPaymentMethodsError = true
              state.getSubscriptionMessage = action.payload
          })
        //------------------
        .addCase(setPaymentIntent.pending, (state) => {
            state.isGetPaymentMethodsLoading = false
          })
        .addCase(setPaymentIntent.fulfilled, (state, action) => {
            state.isGetPaymentMethodsLoading = false
            state.isGetPaymentMethodsSuccess = true
            state.paymentIntent = action.payload.data
            state.clientSecret = action.payload.client_secret
          })
        .addCase(setPaymentIntent.rejected, (state, action) => {
              state.isGetPaymentMethodsLoading = false
              state.isGetPaymentMethodsError = true
              state.getSubscriptionMessage = action.payload
          })
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(attachPaymentMethod.pending, (state) => {
            state.isSetPaymentMethodsLoading = true
          })
        .addCase(attachPaymentMethod.fulfilled, (state, action) => {

          if(action.payload.message){
            state.isSetPaymentMethodsLoading = false
            state.isSetPaymentMethodsError = true
            state.getSubscriptionMessage = action.payload.message
            return
          }


          if(action.payload.setDefault === true){
            state.hasPaymentMethod = true
            state.defaultPmId = action.payload.paymentId
          }

            state.isSetPaymentMethodsLoading = false
            state.isSetPaymentMethodsSuccess = true

            state.viewAddCardModal = false
            state.paymentMethods.push(action.payload.paymentMethod)
            
          })
        .addCase(attachPaymentMethod.rejected, (state, action) => {
              state.isSetPaymentMethodsLoading = false
              state.isSetPaymentMethodsError = true
              state.getSubscriptionMessage = action.payload
          })
          // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
          .addCase(getTransactions.pending, (state) => {
            state.isGetTransactionsLoading = true
          })
          .addCase(getTransactions.fulfilled, (state, action) => {
            state.isGetTransactionsLoading = false
            state.isGetTransactionsSuccess = true
            state.transactions = action.payload.data
            
          })
          .addCase(getTransactions.rejected, (state, action) => {
              state.isGetTransactionsLoading = false
              state.isGetTransactionsError = true
              state.getTransactionsMessage = action.payload
          })
    },
})

export const {  setBillingView, resetAddPaymentMethod, setViewAddCardModal, setViewSubscribeModal  } = billingSlice.actions
export default billingSlice.reducer

