import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { addNewMessageNotification, createMessage } from '../../features/messenger/messengerSlice'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import SpinnerSmall from 'components/SpinnerSmall'

function NewMessageForm( { id } ) {
    const dispatch = useDispatch()
    const [messageText, setMessageText] = useState('')
    
    // get params
    const { mm } = useParams()
    const [isAllowed , setIsAllowed] = useState(false)
    const { 
        isCreateError, 
        isCreateSuccess, 
        isCreateLoading, 
        createMessageError, 
        lstMsgUsrId,
        lstMsgUsrTyp,
        hiddenUsers
    } = useSelector(state => state.messages)

    const handleChange = (e) => {
        if(messageText.length >= 3 ){setIsAllowed(true)}else{setIsAllowed(false)}
        setMessageText(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if(messageText.length < 3){
            toast.error('Message must contain something...') 
        }else{
            dispatch(createMessage({
                conversationId: id,
                text: messageText,
                isMember2member: mm ? true : false,
            }))
        }
        setMessageText('')
    }

    useEffect(() => {
        if(isCreateSuccess && lstMsgUsrId !== null){
            if(!hiddenUsers.includes(lstMsgUsrId)){
                dispatch(addNewMessageNotification({
                    otherUserType: lstMsgUsrTyp,
                    otherUserId: lstMsgUsrId,
                    convId: id,
                }))
            }
        }
    }, [isCreateSuccess, lstMsgUsrId, lstMsgUsrTyp, hiddenUsers, id, dispatch])

    return (
        <MsgFormContainer>
            <MsgForm onSubmit={handleSubmit}>
                <MsgInput 
                    value={messageText} 
                    onChange={handleChange} 
                    placeholder='Enter New Message...' 
                    />

                <SendMsgBtn 
                    type='submit' 
                    disabled={isCreateLoading || !isAllowed}
                    >
                    {
                        isCreateLoading ? 
                        (
                            <SpinnerSmall />
                        )
                        :
                        (
                            'Send' 
                        )
                    } 
                </SendMsgBtn>
            </MsgForm>
        </MsgFormContainer>
    )
}

export default NewMessageForm

const MsgFormContainer = styled.div`
    width: 100%;
    background-color: rgb(222, 221, 221);
    padding: 15px;
    /* box-shadow: 4px 4px 4px rgb(159, 159, 159); */
    @media (max-width: 768px) {
        padding: 15px;
    }
`

const MsgForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    min-height: 50px;
    height: fit-content;
`

const MsgInput = styled.textarea`  
    width: 75%;
    padding: 10px;
    outline: none;
    border-radius: 15px;
    border: none;
    box-shadow: 2px 2px 2px rgb(201, 201, 201);
    margin-right: 5px;
    @media (max-width: 768px) {
        padding: 15px;
        border-radius: 10px;
    }
    &::placeholder{
        color: rgb(39, 39, 39);
    }
`

const SendMsgBtn = styled.button`
    width: 19%;
    padding: 10px 0px 10px 0px;
    margin-left: 4px;
    background-color: rgb(37, 35, 165);
    border: none;
    border-radius: 15px 15px 0px 15px;
    color: white;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    @media (max-width: 768px) {
        padding: 15px;
        border-radius: 15px 15px 0px 15px;
    }
`
const LoadingMsgBtn = styled.div`
    width: 19%;
    padding: 10px 0px 10px 0px;
    margin-left: 4px;
    pointer-events: none;
    border-radius: 5px;
    border: none;
`