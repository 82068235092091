import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux' 
import Requests from './Requests' 
import { toast } from 'react-toastify' 
import UpdateUserName from './UpdateUserName';
import UpdateDisplayName from './UpdateDisplayName'
import UpdateEmail from './UpdateEmail'
import UpdatePassword from './UpdatePassword'
import AddImage from './AddImage'
import MembersWrap from './memberships/MembersWrap'
import UpdateDirectory from './UpdateDirectory'
import BillingComponent from './billing/BillingComponent'
import MyChurchDirectory from './MyChurchDirectory'
import AboutSettings from './about/AboutSettings'
import ProfileSettingsContainer from './profileSettings/ProfileSettingsContainer'
import InviteMembers from './inviteMembers/InviteMembers'
import styled from 'styled-components' 
import AddMembers from './inviteMembers/AddMembers'
import MemberGiving from './giving/MemberGiving'

function UpdateComponent() {

  
  // let { churchName } = useParams()
  const location = useLocation();
  const section =  new URLSearchParams(location.search).get('section') ? new URLSearchParams(location.search).get('section') : 'none'

    const { profile, isError, message, isLoading, hasUpdated } = useSelector((state) => state.profile) 
    const { user, imageUpdated } = useSelector((state) => state.auth)

    useEffect(() => {
        if(isError && !isLoading){ //if it's loading it will refresh, but I don;t want toastofy to refresh if its just loading
            toast.error(message,{
             position: "top-right",
             autoClose: 1000,
             hideProgressBar: true,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
           })
         }
         if(hasUpdated && !isLoading && !imageUpdated){
             toast.success('Successfuly Updated',{
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
         }
   
    },[isError, message, hasUpdated, isLoading])


  return (
    <SelectSettingsWrapper>
      {
        section  === 'username' ?
        (
          <UpdateUserName data={'username'} />
        ) 
        : section === 'fullName' ?
        (
          <UpdateDisplayName data={'fullName'} />
        ) 
        : section === 'addImage' ?
        (
          <AddImage data={'addImage'} />
        ) 
        : section === 'email' ?
        (
          <UpdateEmail data={'email'} />
        ) 
        : section === 'directory' ?
        (
          <UpdateDirectory data={'directory'} />
        ) 
        : section === 'myChurchDirectory' ?
        (
          <MyChurchDirectory />
        ) 
        : section === 'password' ?
        (
          <UpdatePassword data={'password'} />
        ) 
        : section === 'memberships' ?
        (
          <MembersWrap data={'memberships'} />
        ) 
        : section === 'requestsReceived' ?
        (
          <Requests data={'requestsReceived'} />
        ) 
        : section === 'billing' ?
        (
          <BillingComponent data={'billing'} />
        ) 
        : section === 'about' ?
        (
          <AboutSettings />
        ) 
        : section === 'profile' ?
        (
          <ProfileSettingsContainer />
        )
        : section === 'invite' ?
        (
          <AddMembers />
        )
        : section === 'giving' ?
        (
          <MemberGiving />
        )
        : section === 'requests' &&
        (
          <Requests data={'memberships'} />
        )  
      }
    </SelectSettingsWrapper>
  )
}

export default UpdateComponent


const SelectSettingsWrapper = styled.div`
  width: 800px;
  height: 100%; 
  display: flex;
  flex-direction: column;
  align-items: center;


  @media (max-width: 768px) {
    width: 100%;

  }
`