import React, { useEffect, useState, useRef} from 'react' 
import { MediaSectionContainer, MediaTitle, StyledSearchInput, MediaControls,SearchBtn, SearchWrap, MediaFeed } from 'components/media/media.styled'
import MediaSort from '../MediaSort'
import { MUIModalWrap } from 'components/modal/modal.styled'
import { Modal } from '@mui/material'
import UploadAudioModal from './UploadAudioModal'
import { useSelector, useDispatch } from 'react-redux'
import { setViewUploadAudioModal, getAudios, searchAudio, resetActiveSearch, setViewAudioUpdateModal, setAudioData } from 'features/audio/audioSlice'
import AudioItem from './AudioItem'
import ConfirmModal from 'components/modals/ConfirmModal' 
import SearchIcon from '@mui/icons-material/Search';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { SearchResultsTitle, ClearSearchBtn } from 'components/search/search.styled'
import Loader from 'loaders/Loader'
import { NoItemsContainer } from 'components/main.styled'
import { mediaLanguage } from '../mediaLanguage'
import { PiCameraSlashLight } from "react-icons/pi";
import EditAudioModal from './EditAudioModal'

export default function Audio() {

    const searchInputRef = useRef(null)
    const dispatch = useDispatch()
    const [extendSearchBar, setExtendSearchBar] = useState(false)

    const { user } = useSelector((state) => state.auth)
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const {
        uploadAudioModal, audios, getAudiosLoading, getAudiosSuccess,
        audioDeleteLoading, audioDeleteSuccess, audioSort, isSearchActive, viewAudioUpdateModal

    } = useSelector((state) => state.audio)
    const { viewConfirmModal, confirmModalLocation, confirmModalData } = useSelector((state) => state.modal)

    useEffect(() => {
        dispatch(getAudios({
            sort: audioSort,
            churchId: profileChurch._id
        }))
    }, [audioSort])


    const handleSearch = () => { 
        if(searchInputRef.current.value === ''){
            return
        } 
        dispatch(searchAudio({ searchTerm: searchInputRef.current.value })) 
    }

    const handleClearSearch = () => {
        searchInputRef.current.value = ''
        dispatch(resetActiveSearch())
        setExtendSearchBar(false)
    }

    return (
        <MediaSectionContainer> 
            <MediaControls>
                <MediaSort section={'audio'} />                
                <SearchWrap 
                    extendWidth={extendSearchBar}
                    onClick={() => {if(!extendSearchBar){setExtendSearchBar(!extendSearchBar)}}}
                    > 
                    {
                        !extendSearchBar && 
                        <SearchIcon 
                            style={{cursor: 'pointer', marginLeft: '5px'}}
                            onClick={() => setExtendSearchBar(!extendSearchBar)}
                            />
                    }
                    
                    <StyledSearchInput
                        ref={searchInputRef}
                        extendWidth={extendSearchBar}
                        placeholder='Search Audio...'
                        />

                    {
                        extendSearchBar &&
                        <SearchBtn
                            onClick={handleSearch}
                            >
                            <ManageSearchIcon />
                        </SearchBtn>
                    }
                    
                </SearchWrap>      
            </MediaControls>


            {
                isSearchActive &&
                <SearchResultsTitle>
                    <MediaTitle>Search Results</MediaTitle>
                    <ClearSearchBtn
                        onClick={handleClearSearch}
                        >Clear Search</ClearSearchBtn>
                </SearchResultsTitle>
            }

            <MediaFeed
                viewDirection='column'
                >
                { getAudiosLoading ? 
                    (
                        <div 
                            style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}
                            >
                            <Loader />
                        </div>
                    )
                    : getAudiosSuccess ?
                    (
                        audios.length > 0 ?
                        (
                            // [...audios]
                            // .sort(sorted('createdAt', audioSort))
                            audios
                            .map((audio, index) => {
                                return (
                                    <AudioItem key={index} audio={audio} />
                                )
                            })
                        )
                        :
                        (
                            <NoItemsContainer>
                                <PiCameraSlashLight />
                                <div>
                                    {
                                        mediaLanguage.noAudio[user.preferredLanguage]
                                    }
                                </div>
                            </NoItemsContainer>
                        )
                    )
                    :
                    (
                        <div>No Audios Found</div>
                    ) 
                }                       
            </MediaFeed>
         

            <Modal
                open={uploadAudioModal}
                onClose={() => dispatch(setViewUploadAudioModal(false))} 
                >
                    <MUIModalWrap><UploadAudioModal  /></MUIModalWrap>
            </Modal>
            <Modal
                open={viewAudioUpdateModal}
                onClose={() => dispatch(setViewAudioUpdateModal(false))} 
                >
                    <MUIModalWrap>
                        <EditAudioModal />
                    </MUIModalWrap>
            </Modal>

            <Modal  
                open={viewConfirmModal && confirmModalLocation === 'audio'}
                onClose={() => dispatch(setViewUploadAudioModal(false))} 
                >
                    <MUIModalWrap>
                        <ConfirmModal  
                            text={confirmModalData.text}
                            handler={confirmModalData.handler}
                            extraInfo={confirmModalData.extraInfo}
                            confirmBtnBackgroundHover={'red'}
                            loadingState={audioDeleteLoading}
                            conditionToCloseModal={audioDeleteSuccess}
                            />
                    </MUIModalWrap>
            </Modal>

        </MediaSectionContainer>
    )
}
