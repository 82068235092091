import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux' 
import styled from 'styled-components' 
import FooterLP from '../landingPage/FooterLP'
import HomeComponent from 'pages/landingPage/HomeComponent' 
import Loader from 'loaders/Loader'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { FaCheckSquare } from "react-icons/fa";
import { CgUnavailable } from "react-icons/cg";


function Pricing() {
  const navigate = useNavigate() 
  const { user } = useSelector((state) => state.auth)
  const { isLoading, isError, message } = useSelector((state) => state.posts)

  const [isActive, setIsActive] = useState(false)
  const [currentDetail, setCurrentDetail] = useState()
  const [memberAmount, setMemberAmount] = useState('')

  const change = () => { 
    const randomIndex = shareDetailsArray[(Math.floor(Math.random() * shareDetailsArray.length))]
    setCurrentDetail(randomIndex)
  }

  const handleInput = (e) => {

    

    setMemberAmount(e.target.value)
  }

  useEffect(() => {
        if(memberAmount.length > 0){ setIsActive(true)
        }else{ setIsActive(false) }
    }, [memberAmount])

  useEffect(() => {
        console.log('useEffect ran')
        const interval = setInterval(change, 1050)
        return () => clearInterval(interval)
    }, [])
 
  useEffect(() => {
    if (isError) {
      console.log(message)
    }
    if (user) {
      navigate('/home')
    }
  }, [user, navigate, isError, message])
 
  if (isLoading) {
    return <Loader />
  }

  const shareDetailsArray = [
    'Prayer Requests', 'Testimonies', 'Praises', 'Events & Anouncements', 'Church Directory'
  ]

  const handleClickGetStarted = () => {

    localStorage.setItem('registerType', 'church')

    navigate('/register')
  }


  let imageToShow;
  try{ imageToShow =  require('../../images/lp-church.jpg')}
  catch(e){  imageToShow =  null  }

  return (
    <>
    {/* <HomeComponent /> */}
      <PricingSection>
        <PTitle> Simple Pricing. </PTitle>
        <PricingDetails>
          <PricingItem>
            <section>                
              <h1>Praysely Basic</h1>
              <span> Free </span>
              
              <FeaturesAvailable>
                Features Available:
                <ul>
                  <li>
                    <FaCheckSquare />
                    Posts <SubText>(Prayer Requests, Testimonies, Praises)</SubText> 
                  </li>
                  <li>
                    <FaCheckSquare />
                    Events
                  </li>

                  <li>
                    <FaCheckSquare />
                    Private Messaging
                  </li>

                  <li>
                    <FaCheckSquare />
                    Announcements
                  </li>

                  <li>
                    <FaCheckSquare />
                    Polls
                  </li> 

                  <li>
                    <FaCheckSquare />
                    Announcements
                  </li>

                  <li>
                    <FaCheckSquare />
                    Member Feedback
                  </li>

                  <li>
                    <FaCheckSquare />
                    Bulletins
                  </li>
 
                <br />
                    Not Available: 
                      <li>
                        <CgUnavailable color="grey" />
                        Online Church Directory
                      </li>

                      <li>
                        <CgUnavailable color="grey" />
                        Member Giving <SubText>(Tithes, Offerings, and Campaigns)</SubText>
                      </li>

                      <li>
                        <CgUnavailable color="grey" />
                        File Sharing <SubText>(Pdf, Google Drive, etc.)</SubText>
                      </li>

                      <li>
                        <CgUnavailable color="grey" />
                        Media Content <SubText>(Videos, Audio, etc.)</SubText>
                      </li> 

                </ul>
              </FeaturesAvailable>


            </section> 
            <section>
              <GetStartedButton
                onClick={handleClickGetStarted}
                >
                Get Started
              </GetStartedButton>                
            </section> 
          </PricingItem>



          <PricingItem
              annual={true}
            >
            <section>
              <h1>Praysely Premuim</h1>
              <span><AttachMoneyIcon /> 15.00</span> per month
              
              <FeaturesAvailable>
                <ul>
                  <li>
                    <FaCheckSquare color="green" />
                    Posts
                  </li>
                  <li>
                    <FaCheckSquare color="green" />
                    Events
                  </li>
                  <li>
                    <FaCheckSquare color="green" />
                    Private Messaging
                  </li>
                  <li>
                    <FaCheckSquare color="green" />
                    Announcements
                  </li>
                  <li>
                    <FaCheckSquare color="green" />
                    Polls
                  </li> 
                  <li>
                    <FaCheckSquare color="green" />
                    Member Feedback
                  </li>
                  <li>
                    <FaCheckSquare color="green" />
                    Bulletins
                  </li>
                  <li>
                    <FaCheckSquare color="green" />
                    Online Church Directory
                  </li>
                  <li>
                    <FaCheckSquare color="green" />
                    Member Giving
                  </li>
                  <li>
                    <FaCheckSquare color="green" />
                    File Sharing
                  </li>
                  <li>
                    <FaCheckSquare color="green" />
                    Media Content
                  </li>
                </ul>
              </FeaturesAvailable>

            </section>
            <section>
              <GetStartedButton
                onClick={handleClickGetStarted}
                annual={true}
                >
                Get Started
              </GetStartedButton>                
            </section>
          </PricingItem>
        </PricingDetails>

        <MorePricingDetails
          style={{marginTop: '150px'}}
          >
          <h1>How does pricing work on Praysely Premium?</h1>

          <DetailsSection>
            <SectionItem>
              <h2>Try it for free for 60 days</h2>
              <p>
                Try it for free, no credit card required. Praysely will continue to be free until your church 
                has 15 members registered or has reached the end of the 60 day trial period. Whichever comes <b>latest.</b> 
              </p>
            </SectionItem>

            <SectionItem>
              <h2>Do church members have to pay?</h2>
              <p> 
                  Your church members can create and maintain an account and be a member of a church on Praysely for free without any additional costs or obligations. 
                  Only church accounts are required to keep a paid monthly or yearly subscription after the trial period.
              </p>
           
            </SectionItem>
          </DetailsSection>
        </MorePricingDetails>
      </PricingSection>
    <FooterLP />
    </>
  )
}
 
export default Pricing

const SubText = styled.div`
  font-size: 11px;
  color: #ccc;
  margin-left: 10px;
`

const FeaturesAvailable = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ul{ 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  li{
   width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  svg{
    margin-right: 10px;
    color: green;
  }

`

const SectionItem = styled.div`

  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left; 
  padding: 20px;

  h2{
    font-size: 20px;
    margin-bottom: 10px;
  
  }

`

const MorePricingDetails = styled.div`
  margin-top: 50px;
  width: 100%;
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1{ 
    margin-bottom: 40px;
  }

`

const DetailsSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    border: none;
`

const GetStartedButton = styled.div`
  width: 100px;
  height: 40px;
  background-color: ${props => props.annual ? 'white' : 'var(--lightThemeFontColor)'};
  color: ${props => props.annual ? 'var(--themeColor)' : 'white'};
  border: 3px solid ${props => props.annual ? 'white' : 'var(--lightThemeFontColor)'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
  font-size: 14px;

  &:hover{
    background-color: ${props => props.annual ? 'var(--themeColor)' : 'white'};
    color: ${props => props.annual ? 'white' : 'var(--lightThemeFontColor)'};
    border: 3px solid ${props => props.annual ? 'white' : 'grey'};
  }

`
const PricingItem = styled.div` 
  width: 480px;
  height: 760px;
  border-radius: 20px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${props => props.annual ? 'white' : 'var(--lightThemeFontColor)'};
  /* color: var(--lightThemeFontColor); */
  justify-content: space-between;

  padding: 20px;
  background-color: ${props => props.annual ? 'var(--themeColor)' : 'white'};
  box-shadow: 0 0 10px #164a0f;
  padding-top: 40px;

  h1{
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 10px;
  }

  section{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  p{
    font-size: 12px;
    width: 85%; 
    text-align: center;
  }
  span{
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;

    & > svg{
      font-size: 30px;
    }
  }

  transition: 0.3s;

  border: 6px solid transparent;

  &:hover{
    box-shadow: 0 0 45px rgba(22, 74, 15, 0.3);
    border-color: #a0d6b4;
  }

`

const PricingSection = styled.div`
    padding-top: 50px;
    height: fit-content;
    width: 100%;
    background-color: #f0f8ff; /* Light blue background */
    color: #2e2e2e;    
    font-weight: bold;
    padding-bottom: 50px;

    /* Styling for PricingItem */
    // & > div > div {
    //   border: 6px solid #e0e0e0;
    //   box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    //   transition: 0.3s;

    //   &:hover {
    //     box-shadow: 0 0 25px rgba(22, 74, 15, 0.3);
    //     border-color: #a0d6b4;
    //   }

    //   &[annual="true"] {
    //     border-color: var(--themeColor);
    //     box-shadow: 0 0 20px rgba(22, 74, 15, 0.2);

    //     &:hover {
    //       box-shadow: 0 0 30px rgba(22, 74, 15, 0.4);
    //     }
    //   }
    // }

    /* add a gap between the two row elements */
    
 
    
`
const PricingDetails = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content; 
    align-self: center;
    justify-self: center; 
    border-radius: 20px;
    margin-top: 30px;

      /* add a gap between the two row elements */
    & > div{
        margin-left: 30px;

    }

`
const PTitle = styled.div`
    font-size: xx-large;
`
