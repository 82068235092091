import { useSelector, useDispatch } from 'react-redux'
import { deleteComment, setCommentReply, setLoadingCommentIdReplies, getCommentReplies, isCommentDeleteLoading, isCommentDeleteSuccess } from '../../features/comments/singlePostCommentSlice'
import { useState, useRef, useEffect } from 'react'
import TimeAgo from 'timeago-react'
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdOutlineReport } from 'react-icons/md';
import useToggleDropdown from '../../hooks/useToggleDropdown';
import { setViewModalFromComment } from '../../features/modal/modalSlice';
import { imgUrl } from 'features/constants';
import styled from 'styled-components'
import SendIcon from '@mui/icons-material/Send';
import { DotsWrap, DotEle } from 'components/dots/dots.styled';

function CommentItem({ comment, index }) {
    
    const [isHovered, setIsHovered] = useState(false)
    const [viewReplies, setViewReplies] = useState(false)
    // const [repliesLoaded, setRepliesLoaded] = useState(false)
    // const [storedReplies, setStoredReplies] = useState([])
    const [createReply, setCreateReply] = useState(false)
    const [reply, setReply] = useState('')
    const [replyingTo, setReplyingTo] = useState(null)

    const dispatch = useDispatch();
    const ref1 = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref1)
    const handleDropDown = () => { setIsDropped.toggle(ref1) }

    const handleDelete = () => { 
        dispatch(deleteComment({ 
            postId: comment.postId,
            commentId: comment._id,
            residingId: comment.residingId,
        })) 
    }


    const { user, admin, moderator} = useSelector((state) => state.auth)
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { 
        sPComments,
        commentRepliesSuccess,
        commentIdReplies,
        commentIdsOfRepliesLoaded,
        commentIndexLoading,
        commentRepliesLoading
    } = useSelector((state) => state.sPComments)

    const handleReportClick = () => {
        dispatch(setViewModalFromComment({
            postId: comment.postId,
            commentId: comment._id,
            authorId: comment.author._id, 
            residingId: comment.residingId,
            commentText: comment.comment,
            postType: 'comment'
        }))
    }
    const handleCreateReply = () => {


        // setViewReplies(false)
        dispatch(setCommentReply({ 
            commentId: comment._id,
            reply: reply,
            replyingTo: replyingTo ? replyingTo : null,
            commentIndex: index
        }))
        setReply('')
        setCreateReply(false)
    }
    let newDate = new Date(comment.createdAt)
    let path

    if(comment.author.profileImg){
        path = comment.author.profileImg.imagePath2;
    } else { 
        path = 'generic.png'; 
    }


    let profileImageToShow;
    try{ profileImageToShow =  `${imgUrl}/profile/${path}`}
    catch(e){  profileImageToShow =  require(`../../profileImage/generic.png`)  }

    const handleViewReplies = () => { 
        //check if replies are loaded
        // if not loaded, dispatch getEventDiscussionCommentReplies

        dispatch(setLoadingCommentIdReplies({
            commentId: comment._id,
            index: index,
            loading: true,
            status: 'loading',
        }))

        if(!viewReplies){
            // if the replies are not loaded, dispatch getEventDiscussionCommentReplies

            if(!commentIdsOfRepliesLoaded.includes(comment._id)){
                dispatch(getCommentReplies({
                    commentId: comment._id,
                    repliesArrayList: comment.replies,
                    index: index,
                })) 
            }else if(commentIdsOfRepliesLoaded.includes(comment._id)){
                // in redux, we store the commentIds for which replies have been loaded
                setViewReplies(true)
            
                dispatch(setLoadingCommentIdReplies({
                    commentId: comment._id,
                    index: index,
                    loading: false,
                    status: 'success',
                }))
            }            
        }else{
            setViewReplies(false)
        } 
    }


    useEffect(() => {
        if(commentRepliesSuccess && commentIdReplies === comment._id){
            if(sPComments[index].repliesLoaded === comment._id){
                setViewReplies(true)
            }           
            else{
                setViewReplies(false)
            } 
        }
    }, [comment._id, commentIdReplies, commentRepliesSuccess, index, sPComments])


    return (
        <SPCommentItem
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            >
            <SinglePostCommentItem>
                <img className='spcProfileImage' src={profileImageToShow} alt='profile'/>

                <CommentWrapper>
                    <CommentItemInfo>
                        <CommentItemInfoRow>
                            <p style={{ fontWeight:'500'}}> {comment.author.fullName}</p>
                            <p style={{ paddingLeft: '10px', fontSize: '11px', color: 'var(--lightThemeSecondaryFontColor)'}}> 
                                <TimeAgo datetime={newDate}/> 
                            </p>
                        </CommentItemInfoRow>
                        <CommentItemText>{comment.comment}</CommentItemText>
                    </CommentItemInfo>
                    <div ref={ref1} > 
                        <div className="commentDots" onClick={handleDropDown}><BiDotsHorizontalRounded/></div>
                        {isDropped && (
                            <DotsWrap
                                top={'5px'}
                                right={'5px'}
                                >
                                { ((user._id === comment.author._id) || user.isOrganization || admin[profileChurch._id] || moderator[profileChurch._id] ) && (
                                        <DotEle onClick={handleDelete}>  
                                        
                                            <AiOutlineDelete className='commentItemDotsIcon' />  
                                            Delete
                                        
                                        </DotEle>
                                    )
                                }
                                <DotEle 
                                    onClick={handleReportClick}
                                    > 
                                    <MdOutlineReport className='commentItemDotsIcon'/> 
                                    Report
                                </DotEle>
                            </DotsWrap>
                        )}
                    </div>
                </CommentWrapper>
            </SinglePostCommentItem>     
            
            {     
                <Btns>
                    {comment.replies && comment.replies.length > 0 && (
                        <CommentButton
                            visible={isHovered}
                            disabled={commentRepliesLoading}
                            onClick={handleViewReplies}
                            >
                                {
                                    // ((commentIndexLoading.commentId === props.data._id)
                                    // && 
                                    // (commentIndexLoading.status === 'loading') ) ? ('loading') :
                                    (
                                        viewReplies ? ('hide replies') : ('view replies')
                                    )
                                }
                        </CommentButton>  
                    )}                      
                    <CommentButton 
                        visible={isHovered}
                        onClick={() => setCreateReply(!createReply)}
                        >
                        reply
                    </CommentButton>
                </Btns>  
            }

            {
                createReply && (
                    <ReplyInputWrap>
                        <ReplyInput
                            style={{backgroundColor: '#f0f2f5'}}
                            type="text"
                            placeholder="Add a reply"
                            value={reply}
                            onChange={(e) => setReply(e.target.value)}
                            />
                        <ReplyButton
                            disabled={reply.length < 3}
                            onClick={handleCreateReply}
                            >
                            <SendIcon />
                        </ReplyButton>
                    </ReplyInputWrap>
                )
            }

            { 
                (
                    viewReplies &&
                    commentIndexLoading.commentId === comment._id && 
                    commentIndexLoading.status === 'success'
                ) 
                && 
                (
                    <RepliesContainer>
                        <h2>Replies</h2>
                        {
                            comment.replies.map((ele) => (
                                <TGWrap>
                                    <TGImage> <img src={`${imgUrl}/profile/${ele.author.profileImg.imagePath2}`} alt="user" /></TGImage>    
                                    <TG
                                        isReply={true}
                                        key={ele._id}
                                        >
                                        
                                        <TopCommentSection>
                                            <TopLeftSection>
                                                <HeadingText>{ele.authorName}</HeadingText>
                                            </TopLeftSection>
                                            <TopRightSection>
                                                <CommentDate><TimeAgo datetime={newDate}/></CommentDate>
                                            </TopRightSection>
                                        </TopCommentSection>
                                        <CommentText>{ele.reply}</CommentText>
                                    </TG>
                                </TGWrap>
                            ))
                        }
                    </RepliesContainer>
                )
            }


        </SPCommentItem>

    )
}
export default CommentItem

const TGWrap = styled.div` 
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;

`

const ReplyInputWrap = styled.div`
    margin-bottom: 7px;
    margin-top: 5px;
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 7px; 
    background-color: #f0f2f5;
`

const TG = styled.div`
    background-color: white;
    width: ${props => props.isReply ? 'fit-content' : '100%'};
    margin-bottom: ${props => props.isReply ? '5px' : '0px'};
    min-width: ${props => props.isReply ? '50%' : '100%'};
    max-width: ${props => props.isReply ? '80%' : '100%'};
    padding: 10px; 
    height: fit-content;
    border-radius: ${props => props.isReply ? '15px 0px 15px 15px':'5px'  };
    border: 1px solid #ebebeb;
    display: flex;
    flex-direction: column;
    text-align: left;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
`
const HeadingText = styled.div` 
    font-weight: 500;
    font-size: 12px;
    color: var(--lightThemeFontColor);
`

const TopCommentSection = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

`

const TopLeftSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 10px;
`

const TopRightSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
const CommentDate = styled.div`
    font-size: 10px;
    padding-left: 10px;
    color: var(--lightThemeSecondaryFontColor);
`

const TGImage = styled.div`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 6px;
    margin-top: 3px;
    img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
`

const CommentText = styled.div`
    padding-top: 10px;
    padding-right: 10px;
    font-size: 13px;
    color: var(--lightThemeFontColor);
`

const ReplyButton = styled.button`
    padding: 2px 10px;
    font-size: 10px;
    border: none;
    outline: none;
    color: ${props => props.disabled ? 'grey' : 'white'};
    border-radius: 5px;
    cursor: pointer;
    background-color: ${props => props.disabled ? 'none' : 'green'};

    &:hover{
        transition: all 0.4s; 
        /* background-color: #d8d8d8;  */
    }

`
const RepliesContainer = styled.div`
    width: 100%;
    height: fit-content;  
    display: flex;
    flex-direction: column;
    align-items: flex-end;  
    
    background-color: #f0f0f0;
    border: 1px solid #ececec;
    padding: 2px 15px 10px 0px;
    border-radius: 15px;
    max-height: 300px;
    overflow-y: scroll;
    // hide scrollbar

    &::-webkit-scrollbar {
        width: 0px;
        background: transparent; 
    }
    -ms-overflow-style: none; 
    scrollbar-width: none; 

    h2{
        width: 100%;
        text-align: left;
        font-size: 14px;
        font-weight: 300;
        padding: 5px 0px 5px 10px;
        color: var(--lightThemeFontColor);
    }
`

const ReplyInput = styled.input`
    /* background-color: none; */
    border: none;
    outline: none;
    width: 100%;
    padding: 5px;
`

const Btns = styled.div`
    width: 100%; 
    display: flex; 
    flex-direction: row;
    justify-content: flex-end;
`

const SPCommentItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    position: relative;
    transition: all 0.4s ;
    /* &:hover{
        transition: all 0.4s ;
        ${Btns}{
            transition: all 0.4s ;
            display: flex;
        }
    } */
`

const SinglePostCommentItem = styled.div`
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    
    padding: 15px 10px 5px 15px;
    border-top: 1px solid rgb(233, 233, 233);
    @media (max-width: 768px) {
        padding: 10px;
        border-top: 1px solid rgb(221, 221, 221);
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: 0px;
    }
`

const CommentWrapper = styled.div`
    overflow-wrap: break-word;
    min-width: 280px;
    width: 100%;
    max-width: 100%;
    margin-left: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* background-color: rgb(234, 232, 232); */
    border-radius: 8px;
    /* box-shadow: rgba(222, 222, 222, 0.54) 0px 3px 8px; */
    padding: 0px;
    position: relative;
    @media (max-width: 768px) {
        word-wrap: break-word;
        width: 100%;
        margin-left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px;
        position: relative;
    }
`

const CommentItemInfo = styled.div`
    display: flex;
    flex-direction: column; 
    padding-left: 10px;
    
`

const CommentItemInfoRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    justify-content: flex-start;
    p{
        font-size: 13px;
        color: var(--lightThemeFontColor);
    }
`

const CommentItemText = styled.div`
    padding: 10px 10px 0px 0px;
    text-align: left;
    overflow-wrap: anywhere;
    color: var(--lightThemeFontColor);
    font-size: 13px;
`


//https://www.codingdeft.com/posts/react-on-click-outside/


const CommentButton = styled.div`
    padding: 0px 10px;
    font-size: 11px;
    color: ${props => props.visible ? 'var(--lightThemeSecondaryFontColor)' : 'transparent'};
    cursor: pointer;
    transition: all 0.5s;
    margin-bottom: 5px;
    &:hover{
        transition: all 0.5s;
        color: #474747;
    }
`
