import React, {useRef} from 'react'
import styled from 'styled-components'
import { FaCcVisa } from "react-icons/fa6";
import { SiMastercard } from "react-icons/si";
import { FaCcDiscover } from "react-icons/fa6";
import { SiAmericanexpress } from "react-icons/si";
import { DotEle, DotsWrap } from 'components/dots/dots.styled';
import useToggleDropdown from 'hooks/useToggleDropdown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { setConfirmModalData, setViewConfirmModal2 } from 'features/modal/modalSlice';
import { removeMemberCardPaymentMethod } from 'features/giving/givingSlice';

const cards = {
    visa: <FaCcVisa fontSize={'30px'} />,
    mastercard: <SiMastercard fontSize={'30px'} />,
    discover: <FaCcDiscover fontSize={'30px'}/>,
    amex: <SiAmericanexpress fontSize={'30px'}/>
}

export default function PaymentMethodCardItem({card}) {

    const dispatch = useDispatch()
    const ref = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref)
    const handleDropDown = () => { setIsDropped.toggle(ref) }


    const handleRemoveCard = () => {
        
        dispatch(setConfirmModalData({ 
            text: `Are you sure you want to remove this card ending in ${card[card.type].last4}?`,
            handler: () => { dispatch(removeMemberCardPaymentMethod({ cardId: card._id }))  }
        }))

        dispatch(setViewConfirmModal2({ 
            view: true, 
            location: 'paymentMethodItem' 
        }))
    }

    return (
        <PMCItem>  
            <MoreHorizIcon 
                style={{ cursor: 'pointer', position: 'absolute', top: '10px', right: '10px'}}
                ref={ref} onClick={handleDropDown} />

            {
                isDropped && (
                    <DotsWrap>
                        <DotEle
                            onClick={handleRemoveCard}
                            >
                            <DeleteIcon 
                                style={{ 
                                    marginRight: '11px'
                                }}
                                />
                                Remove
                        </DotEle> 
                    </DotsWrap>
                )
            }
 
            <CardBottom>
                <div>...{card[card.type].last4}</div> 
                <CardLogo>
                    {cards[card.card.brand]}
                </CardLogo>
            </CardBottom> 
        </PMCItem>
    )
}

const CardBottom = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const CardLogo = styled.div`  
`

const PMCItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px;
    width: 200px;
    height: 100px; 
`