import axios from 'axios' 
import { baseUrl } from '../constants'
const URL = baseUrl  

 
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }

 
const getPrayerWallItems = async (data, token) => {
  const response = await axios.post(URL+'/api/prayerWall/getPrayerWallItems', data, config(token))
  return response.data
}

const addItemToPrayerWall = async (data, token) => {
  const response = await axios.post(URL+'/api/prayerWall/addItemToPrayerWall' , data, config(token))
  return response.data
}

const prayerWallService = {
    getPrayerWallItems, addItemToPrayerWall
}
export default prayerWallService