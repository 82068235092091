import { useState, useEffect } from 'react'
import '../../css/components/settings/requests.css'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import RequestsRecieved from './RequestsRecieved'
import RequestsSent from './RequestsSent'
import { getRequestsReceived, getRequestsSent } from '../../features/requests/requestSlice'

function Requests() {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { requestsReceived, requestsSent, isRequestGetSuccess, isRequestGetError } = useSelector((state) => state.requests)

    useEffect(() => {

        if(user && !user.isOrganization && !user.isChurch){ // run this if a user is logged in
            if(!isRequestGetSuccess && !isRequestGetError){
                console.log(`getting your requests`)
                dispatch( getRequestsSent() )
            }else if( requestsReceived || ( isRequestGetSuccess && !isRequestGetError ) ){
                console.log('already fetced')
                //i really dont need this other if condition
            }
        }else if(user && (user.isOrganization || user.isChurch)){// run this if a CHURCH is logged in
            if(!isRequestGetSuccess && !isRequestGetError){
                console.log(`getting your requests`)
                dispatch( getRequestsReceived() )
            }else if( requestsSent || ( isRequestGetSuccess && !isRequestGetError ) ){
                console.log('already fetced')
                //i really dont need this other if condition
            }
        }


    }, [ dispatch, user, requestsReceived, requestsSent, isRequestGetSuccess, isRequestGetError ]   )

  return (
    <>
        {
            user.isOrganization || user.isChurch? 
            (
                <RequestsRecieved />
            )
            :
            (
                <RequestsSent />
            )
        }         
    </>
  )
}

export default Requests