import axios from 'axios';
import { baseUrl } from '../constants'
const URL = baseUrl

const API_URL_MAILER = '/api/users/sendMailRegister'
const API_URL_MAILER_CHURCH = '/api/users/sendMailRegister/church'
const API_URL_FORGOTPASSWORD = '/api/users/forgotPassword'

// const sendMail = async (data) => {
//                 // const config = {headers: {Authorization: `Bearer ${token}`,},}
//     const response = await axios.post(API_URL_MAILER, data)
//     return response.data
//   }

//   const sendMailChurch = async (data) => {
//             // const config = {headers: {Authorization: `Bearer ${token}`,},}
//     const response = await axios.post(API_URL_MAILER_CHURCH, data)
//     return response.data
//   }
//
//**************These two functions are instead being used in the server to facilitate the validation */


const forgotPasswordMail = async (data) => {
  // const config = {headers: {Authorization: `Bearer ${token}`,},}
  const response = await axios.post(URL+API_URL_FORGOTPASSWORD, data)
  return response.data
}


const mailService = { forgotPasswordMail }
export default mailService
