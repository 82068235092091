import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import membershipService from './membershipService'

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

const initialState = {
  memberOf: [],
  members: [],
  searchResults: [],
  confirmDelete: false,

  getMemberOfLoading: false,
  getMemberOfSuccess: false,
  getMemberOfError: false,

  registerUserFromInviteLoading: false,
  registerUserFromInviteSuccess: false,
  registerUserFromInviteError: false,
  registerUserFromInviteMessage: '',

  regInvData: null,
  getRegInvDataLding: false,
  getRegInvDataSuccess: false,
  getRegInvDataError: false,
  getRegInvDataMessage: '',

  admins: [],
  moderators: [],

  isMemberActionLoading: false,
  isMemberActionSuccess: false,
  isMemberActionError: false,
  memberActionMessage: '',

  isMbsError: false,
  isMbsSuccess: false,
  isMbsLoading: false,
  mbsMessage: '',

  isMbsSearchLoading: false,
  isMbsSearchSuccess: false,
  isMbsSearchError: false,
  hasLeftChurchSuccess: false,
  isMemberSearchActive: false,
  searchTerm: '',
  tempPlaceholder: null,

  removeFromChurchLoading: false,
  removeFromChurchSuccess: false,

  filter: null,
  totalMembers: null,
  hasNextPage: null,
  nextPage: null, 
  totalPages: null, 
  currentPage: null,
  previousPage: null,

  churchesSearchResult: [],  
  churchSearchTerm: '',
  isChurchSearchLoading: false,
  isChurchSearchSuccess: false,
  isChurchSearchActive: false,
  viewChurchSearchModal: false,
  churchTmp: null,

  filterC: null,
  totalChurches: null,
  hasNextPageC: null,
  nextPageC: null, 
  totalPagesC: null, 
  currentPageC: null,
  previousPageC: null,
  searchChurchCount: 0,
  noMoreResults: false,
  viewMemberModal: false,
}
//gets list of where a user is a member
export const getMemberOf = createAsyncThunk(
  'memberships/getMemberOf',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await membershipService.getMemberOf(token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//gets list of users that belong to a church
export const getMembers = createAsyncThunk(
  'memberships/getMembers',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await membershipService.getMembers(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//gets list of users that belong to a church
export const removeMember = createAsyncThunk(
  'memberships/removeMember',
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await membershipService.removeMember(userId, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//gets list of users that belong to a church
export const removeMemberOf = createAsyncThunk(
  'memberships/removeMemberOf',
  async (churchId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await membershipService.removeMemberOf(churchId, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//gets list of users that belong to a church
export const searchMembers = createAsyncThunk(
  'members/search',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await membershipService.searchMembers(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const searchChurch = createAsyncThunk(
  'members/searchChurch',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await membershipService.searchChurch(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const makeMemberAnAdmin = createAsyncThunk(
  'members/makeMemberAnAdmin',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await membershipService.makeMemberAnAdmin(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const makeMemberAModerator = createAsyncThunk(
  'members/makeMemberAModerator',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await membershipService.makeMemberAModerator(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const removeAnAdmin = createAsyncThunk(
  'members/removeAnAdmin',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await membershipService.removeAnAdmin(data, token)
    } catch (error) {

      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    } 
  }
)


export const removeAModerator = createAsyncThunk(
  'members/removeAModerator',
  async (data, thunkAPI) => {
    try {   
      const token = thunkAPI.getState().auth.user.token
      return await membershipService.removeAModerator(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const searchFellowMembers = createAsyncThunk(
  'members/searchFellowMembers',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await membershipService.searchFellowMembers(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const registerUserFromInvite = createAsyncThunk(
  'members/registerFromInvite', async (data, thunkAPI) => {
  try {
    return await membershipService.registerUserFromInvite(data)
  } catch (error) {
    const message = errorMessage(error)
    return thunkAPI.rejectWithValue(message)
  }
})

export const getRegInvData = createAsyncThunk(
  'members/getRegInvData', async (data, thunkAPI) => {
  try {
    return await membershipService.getRegInvData(data)
  } catch (error) {
    const message = errorMessage(error)
    return thunkAPI.rejectWithValue(message)
  }
})


export const membershipsSlice = createSlice({
  name: 'memberships',
  initialState,
  reducers: {
    mbsReset: (state) => initialState,
    resetChurchMembers: (state) => {
      state.members = []
    },
    setMemberFilter: (state, action) => {
      state.filter = action.payload.filter
    },
    resetMemberSearchTerm: (state) => {
      state.searchTerm = ''
      state.isMemberSearchActive = false
      // state.searchResults = []
      state.members = state.tempPlaceholder
      // state.tempPlaceholder = null
    },
    setMemberSearchTerm: (state, action) => {
      state.searchResults = []
      state.searchTerm = action.payload
    },
    closeMemberModal: (state) => {
      state.viewMemberModal = false
    },
    setViewMemberModal: (state) => {
      state.viewMemberModal = true
    },
    setViewChurchSearchModal: (state) => {
      state.viewChurchSearchModal = true
    },
    closeSearchChurchModal: (state) => {
      state.viewChurchSearchModal = false    
    },
    resetSearchResults: (state) => { 
      state.churchesSearchResult = []
      state.churchSearchTerm = ''
    },
    resetChurchSearchTerm: (state) => {
        state.churchSearchTerm = ''
        state.isChurchSearchActive = false
        state.churchesSearchResult = state.tempPlaceholder
    },
    setChurchSearchTerm: (state, action) => {
        state.churchSearchTerm = action.payload
    },

    resetMemberLoadingState: (state, action) => {
      action.payload.valuesToResetArray.forEach((ele) => {
        state[ele] = false
      })
    }


  },
  extraReducers: (builder) => {
    builder
      .addCase(getMemberOf.pending, (state) => {
        state.getMemberOfLoading = true
      })
      .addCase(getMemberOf.fulfilled, (state, action) => {
        state.getMemberOfLoading = false
        state.getMemberOfSuccess = true
        state.memberOf = action.payload
        state.getMemberOfError = false
      })
      .addCase(getMemberOf.rejected, (state, action) => {
        state.getMemberOfError = true
        state.getMemberOfLoading = false
        state.mbsMessage = action.payload
      })
      // -----------------------------------------------------------------------
      .addCase(getMembers.pending, (state) => {
        state.isMbsLoading = true
      })
      .addCase(getMembers.fulfilled, (state, action) => {
        state.isMbsLoading = false
        state.isMbsSuccess = true
        
        state.members.push(...action.payload.membersArray)
        state.admins = action.payload.admins
        state.moderators = action.payload.moderators

        state.totalMembers = action.payload.totalMembers
        state.hasNextPage = action.payload.hasNextPage
        state.nextPage = action.payload.nextPage  
        state.totalPages = action.payload.totalPages
        state.currentPage = action.payload.currentPage
        state.previousPage = action.payload.previousPage
        state.filter = action.payload.filter

      })
      .addCase(getMembers.rejected, (state, action) => {
        state.isMbsLoading = false
        state.isMbsError = true
        state.mbsMessage = action.payload
      })
      // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
      // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%


      .addCase(removeMember.pending, (state) => {
        state.removeFromChurchLoading= true
      })
      .addCase(removeMember.fulfilled, (state, action) => {
        state.removeFromChurchLoading = false
        state.removeFromChurchSuccess = true

        if(state.searchResults){
          state.searchResults = state.searchResults.filter(
            (ele) => ele._id !== action.payload.deletedId
          )
        }
        if(state.members){
          state.members = state.members.filter(
            (ele) => ele._id !== action.payload.deletedId
          )
        }        
      })
      .addCase(removeMember.rejected, (state, action) => { 
        state.mbsMessage = action.payload
      })


      // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
      // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
      .addCase(removeMemberOf.pending, (state) => {
        state.removeFromChurchLoading= true
      })
      .addCase(removeMemberOf.fulfilled, (state, action) => { 
        state.removeFromChurchLoading = false
        state.removeFromChurchSuccess = true

        state.memberOf = state.memberOf.filter(
          (ele) => ele._id !== action.payload.deletedId
        )

      })
      .addCase(removeMemberOf.rejected, (state, action) => { 

        state.mbsMessage = action.payload

      })
            // -----------------------------------------------------------------------
            .addCase(searchMembers.pending, (state) => {
              state.isMbsSearchLoading = true
              // state.isMbsSearchSuccess = false
              // state.isMemberSearchActive = false
            })
            .addCase(searchMembers.fulfilled, (state, action) => {
              if(!state.tempPlaceholder){

                state.tempPlaceholder = state.members
                state.members = []
              }
              state.searchResults = action.payload
              state.isMbsSearchLoading = false
              state.isMbsSearchSuccess = true
              state.isMemberSearchActive = true

              state.members = action.payload
            })
            .addCase(searchMembers.rejected, (state, action) => {
              state.isMbsSearchLoading = false
              state.isMbsSearchSuccess = false
              state.isMemberSearchActive = false
              state.isMbsError = true
              state.mbsMessage = action.payload
            })
 // -----------------------------------------------------------------------
            .addCase(searchChurch.pending, (state) => {
              state.isChurchSearchLoading = true
            })
            .addCase(searchChurch.fulfilled, (state, action) => {
              state.isChurchSearchLoading = false
              state.isChurchSearchSuccess = true
              state.isChurchSearchActive = true

              if(!state.churchesSearchResult){
                state.churchesSearchResult = action.payload.searchResults
              }else{
                state.churchesSearchResult.push(...action.payload.searchResults)
              }

              // // state.totalChurches = action.payload.totalChurches
              // state.hasNextPageC = action.payload.hasNextPage
              // state.nextPageC = action.payload.nextPage  
              // state.totalPagesC = action.payload.totalPages
              // state.currentPageC = action.payload.currentPage
              // state.previousPageC = action.payload.previousPage
              state.searchChurchCount = action.payload.searchChurchCount
              state.noMoreResults = action.payload.noMoreResults




              if(!state.churchTmp){
                state.churchTmp = state.churchesSearchResult
              }
            })
            .addCase(searchChurch.rejected, (state, action) => {
              state.isChurchSearchLoading = false
              state.isChurchSearchSuccess = false
              state.isChurchSearchActive = false
              state.isMbsError = true
              state.mbsMessage = action.payload
            })
   // -----------------------------------------------------------------------
    .addCase(makeMemberAnAdmin.pending, (state) => {
      state.isMemberActionLoading = true
    })
    .addCase(makeMemberAnAdmin.fulfilled, (state, action) => {
      state.admins = action.payload.admins    
      state.isMemberActionLoading = false
      state.isMemberActionSuccess = true
      state.memberActionMessage = 'Member is now an admin'
 
    })
    .addCase(makeMemberAnAdmin.rejected, (state, action) => {
      state.isMemberActionLoading = false
      state.isMemberActionSuccess = false
      state.isMemberActionError = true
      state.memberActionMessage = action.payload
    })
  // -----------------------------------------------------------------------
      .addCase(makeMemberAModerator.pending, (state) => {
      state.isMemberActionLoading = true
    })
    .addCase(makeMemberAModerator.fulfilled, (state, action) => {
      state.moderators = action.payload.moderators
      state.isMemberActionLoading = false
      state.isMemberActionSuccess = true
      state.memberActionMessage = 'Member is now moderator'
    })
    .addCase(makeMemberAModerator.rejected, (state, action) => {
      state.isMemberActionLoading = false
      state.isMemberActionSuccess = false
      state.isMemberActionError = true
      state.memberActionMessage = action.payload
    })
  // -----------------------------------------------------------------------
  .addCase(removeAModerator.pending, (state) => {
    state.isMemberActionLoading = true
  })
  .addCase(removeAModerator.fulfilled, (state, action) => {
    state.moderators = action.payload.moderators
    state.isMemberActionLoading = false
    state.isMemberActionSuccess = true
    state.memberActionMessage = 'Member is no longer a moderator'
  })
  .addCase(removeAModerator.rejected, (state, action) => {
    state.isMemberActionLoading = false
    state.isMemberActionSuccess = false
    state.isMemberActionError = true
    state.memberActionMessage = action.payload
  })    
  // -----------------------------------------------------------------------
  .addCase(removeAnAdmin.pending, (state) => {
    state.isMemberActionLoading = true
  })
  .addCase(removeAnAdmin.fulfilled, (state, action) => {
    state.admins = action.payload.admins
    state.isMemberActionLoading = false
    state.isMemberActionSuccess = true 
    state.memberActionMessage = 'Member is no longer an admin'
  })
  .addCase(removeAnAdmin.rejected, (state, action) => {
    state.isMemberActionLoading = false
    state.isMemberActionSuccess = false
    state.isMemberActionError = true
    state.memberActionMessage = action.payload
  })     
  
    // -----------------------------------------------------------------------
    .addCase(searchFellowMembers.pending, (state) => {
      state.isMbsSearchLoading = true
      // state.isMbsSearchSuccess = false
      // state.isMemberSearchActive = false
    })
    .addCase(searchFellowMembers.fulfilled, (state, action) => {
      if(!state.tempPlaceholder){ 
        state.tempPlaceholder = state.members
        state.members = []
      }
      state.searchResults = action.payload
      state.isMbsSearchLoading = false
      state.isMbsSearchSuccess = true
      state.isMemberSearchActive = true

      state.members = action.payload
    })
    .addCase(searchFellowMembers.rejected, (state, action) => {
      state.isMbsSearchLoading = false
      state.isMbsSearchSuccess = false
      state.isMemberSearchActive = false
      state.isMbsError = true
      state.mbsMessage = action.payload
    })
    //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    .addCase(registerUserFromInvite.pending, (state) => {
      state.registerUserFromInviteLoading = true
    })
    .addCase(registerUserFromInvite.fulfilled, (state, action) => {

      localStorage.setItem('loginType', 'user')

      state.registerUserFromInviteLoading = false
      state.registerUserFromInviteSuccess = true
      state.registerUserFromInviteMessage = action.payload
    })
    .addCase(registerUserFromInvite.rejected, (state, action) => {
      state.registerUserFromInviteLoading = false
      state.registerUserFromInviteError = true
      state.registerUserFromInviteMessage = action.payload
    })
    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    .addCase(getRegInvData.pending, (state) => {
      state.getRegInvDataLding = true
    })
    .addCase(getRegInvData.fulfilled, (state, action) => {

      state.getRegInvDataLding = false
      state.getRegInvDataSuccess = true
      state.regInvData = action.payload.church
      
    })
    .addCase(getRegInvData.rejected, (state, action) => {
      state.getRegInvDataLding = false
      state.getRegInvDataError = true
      state.getRegInvDataMessage = action.payload
    })

  },
})

export const { 
  mbsReset, 
  resetChurchMembers, 
  setMemberFilter,
  resetMemberSearchTerm,
  setMemberSearchTerm,
  closeMemberModal,
  setViewMemberModal,
  setViewChurchSearchModal,
  closeSearchChurchModal,
  resetChurchSearchTerm,
  setChurchSearchTerm,
  resetSearchResults,
  resetMemberLoadingState
} = membershipsSlice.actions
export default membershipsSlice.reducer
