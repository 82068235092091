import React, {useEffect} from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { startFinancialConnection, resetFinancialConnection, addFinancialConnectionAsPaymentMethod } from 'features/giving/givingSlice'
import { useStripe } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';

export default function AddBankModal() {

    const dispatch = useDispatch()
    const stripe = useStripe()
    const {
        financialConnectionSessionClientSecret,
        financialConnectionSessionSuccess,
        financialConnectionSessionError,
        financialConnectionSessionOptions
    } = useSelector((state) => state.giving)

    const { profileChurch } = useSelector((state) => state.profileChurch)

    const handleStartFinancialConnection = async () => {
        // let result = await stripe.collectFinancialConnectionsAccounts({ 
        //     clientSecret: financialConnectionSessionClientSecret
        // })
        // if(result.error){ toast.error(result.error) }


        stripe.collectFinancialConnectionsAccounts({
            clientSecret: financialConnectionSessionClientSecret
          })
            .then(function(result) {
              if (result.error) {
                // Inform the customer that there was an error.
                toast.error('Error connecting bank account');
          
              // Handle next step based on length of accounts array
              } else if (result.financialConnectionsSession.accounts.length === 0) {
                toast.error('No accounts were linked');
              } else {
                    toast.success('Bank account connected') 

                    dispatch(addFinancialConnectionAsPaymentMethod({
                        churchId: profileChurch._id,
                        financialConnectionId: result.financialConnectionsSession.accounts[0].id 
                    })) 
              }
        });
    }

    useEffect(() => {
        dispatch(startFinancialConnection({
            churchId: profileChurch._id
        }))
    }, [])


    useEffect(() => {
        return () => {
            dispatch(resetFinancialConnection())
        }
    }, [])

    useEffect(() => {
        if(financialConnectionSessionSuccess && financialConnectionSessionClientSecret){
            handleStartFinancialConnection()
        }
    }, [financialConnectionSessionSuccess, financialConnectionSessionClientSecret])

    return (
        <div style={{ 
                display: 'flex', flexDirection: 'column', justifyContent: 'center',
                alignItems: 'center', height: '100%', width: '100%', zIndex: 5555000
            }}
            >
        </div>
    )
}
