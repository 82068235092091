import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux' 
import { FIH, FileModal } from '../files/files.styled'
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import { MdComputer } from "react-icons/md";
import { IoIosLink } from "react-icons/io"; 
import { CgRename } from "react-icons/cg";
import {setViewUploadAudioModal, setSelectedAudioSource} from 'features/audio/audioSlice'

export default function SourceSelect({handler1, handler2, mediaType}) {

    const dispatch = useDispatch() 

    const handleCloseModal = () => {
        dispatch(setSelectedAudioSource(null)) 
        dispatch(setViewUploadAudioModal( false ))
    }

    return (
        <FileModal>
            <ModalCloseButton
                handler={handleCloseModal}
                top={'20px'}
                right={'20px'}
                size='30px'
                />

            <FIH>Where is your {mediaType} file?</FIH> 
            <CCC>
                <FItem 
                    onClick={handler1}
                    >
                    <MdComputer />
                    <span>On my Device</span>
                </FItem>
                <FItem onClick={ handler2 }
                    >
                    <IoIosLink />
                    <span>On the Web</span> 
                </FItem>
            </CCC>

        </FileModal>
    )
}

const FItem = styled.div`
    width: 50%;
    height: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    transition: 0.3s;
    cursor: pointer;
    color: var(--lightThemeFontColor);
    &:hover{
        background: #f5f5f5;
        border-radius: 5px;

        p{
            display: flex;
        }

    }
    svg{
        font-size: 30px;
        margin-bottom: 10px;
        color: var(--lightThemeFontColor);
    }

    p{
        display: none;
        font-style: italic;
        position: absolute;
        bottom: 0;
        font-size: small;
        color: var(--lightThemeFontColor);
    }

`

const CCC = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    transition: 0.3s;
    cursor: pointer;
    color: var(--lightThemeFontColor);

`