import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import feedbackService from './feedbackService'

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

const initialState = {
  feedback: null,


  isfeedbackError: false,
  isfeedbackSuccess: false,
  isfeedbackLoaded: false,

  isCreatefeedbackLoading: false,
  isCreatefeedbackSuccess: false,
  isCreatefeedbackError: false,
  issueMessage: '',

  viewFeedbackModal: false,
}


export const sendFeedback = createAsyncThunk(
    'feedback/sendFeedback',
    async (data, thunkAPI) => {
      try {
        // const token = thunkAPI.getState().auth.user.token
        return await feedbackService.sendFeedback(data, {})
      } catch (error) {
        const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )


  export const feedbackSlice = createSlice({
    name: 'feedback',
    initialState,
    reducers: {
      feedbackReset: (state) => initialState,
      setViewFeedbackModal: (state) => { state.viewFeedbackModal = !state.viewFeedbackModal }
    },
    extraReducers: (builder) => {
      builder
        .addCase(sendFeedback.pending, (state) => {
          state.isCreatefeedbackLoading = true
        })
        .addCase(sendFeedback.fulfilled, (state, action) => {
          state.isCreatefeedbackLoading = false
          state.isCreatefeedbackSuccess = true
        })
        .addCase(sendFeedback.rejected, (state, action) => {
          state.isCreatefeedbackLoading = false
          state.isCreatefeedbackSuccess = false
          state.isCreatefeedbackError = true
          state.issueMessage = action.payload
        })
    },
})


export const { feedbackReset, setViewFeedbackModal } = feedbackSlice.actions
export default feedbackSlice.reducer