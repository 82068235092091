import { ImFilePdf } from "react-icons/im";
import { SiGoogledocs } from "react-icons/si";
import { FaGoogleDrive } from "react-icons/fa6";
import { LuLink } from "react-icons/lu";

export const icons = {
    'pdf': <ImFilePdf />,
    'jpeg': 'jpeg',
    'png': 'png',
    'gif': 'gif',
    'google': <FaGoogleDrive />,
    'link': <LuLink />,
};
