import React, { useRef, useState, useEffect} from 'react'
import styled from 'styled-components'
import useImageLoader from 'hooks/useImageLoader'
import { deleteVideo, setVideoData, setViewEditVideoModal, setViewVideoPlayerModal } from 'features/videos/videoSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { DotsButton, DotsWrap, DotsDropDown, DotEle } from 'components/dots/dots.styled'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit'; 
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import useToggleDropdown from 'hooks/useToggleDropdown'
import { setViewConfirmModal, setConfirmModalData } from 'features/modal/modalSlice'
import { BsThreeDots } from 'react-icons/bs' 

const defaultImage = `${process.env.PUBLIC_URL + '/img/video.jpg'}`

export default function VideoCard({data}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const dotsRef = useRef(null)
    const [imageSource, { blur }] = useImageLoader(defaultImage, data.imageUrl, defaultImage)
    const { user, admin, moderator } = useSelector((state) => state.auth)
    const [isDropped, setIsDropped] = useToggleDropdown(dotsRef)
    const {profileChurch} = useSelector((state) => state.profileChurch)

    const handleDotsDropdown = (e) => {
        e.stopPropagation()
        setIsDropped.toggle(dotsRef) 
    }

    const handleDelete = () => {  
        dispatch(deleteVideo({
            videoId: data._id,
            churchId: profileChurch._id
        }))
    }

    const handleConfirm = (e) => {

        e.stopPropagation()

        dispatch(setConfirmModalData({ 
            text: 'Are you sure you want to delete this video?',
            handler: handleDelete,  
            confirmBtnBackgroundHover: 'red',
            extraInfo: null,  
        }))

        dispatch(setViewConfirmModal(true))
    }

    const handleEdit = (e) => {
        e.stopPropagation()

        dispatch(setViewEditVideoModal(true))
        dispatch(setVideoData(data))

    }

    return (
        <VideoCardContainer
            onClick={() => {
                    dispatch(setViewVideoPlayerModal({
                        view: true,
                        videoUrl: data.videoUrl
                    }))
                    navigate(`?section=media&view=videos&vid=${data._id}`)
                }}
            > 

            {(user.isOrganization || admin[profileChurch._id]) && (
                <>
                    <DotsButton
                        ref={dotsRef}
                        onClick={handleDotsDropdown}
                        ><BsThreeDots />
                    </DotsButton>

                    { isDropped && (
                        <DotsWrap>
                            <DotEle style={{color: 'red'}} 
                                onClick={handleConfirm}
                                > 
                                <DeleteOutlineIcon style={{marginRight: '10px'}} />
                                Delete
                            </DotEle>
                            <DotEle onClick={handleEdit}> 
                                <EditIcon style={{marginRight: '10px'}} /> 
                                Edit
                            </DotEle>
                        </DotsWrap>
                    )}         
                </>

            )}

            <CardImageWrap>
                <img
                    src={imageSource}
                    alt="thumbnail"
                    style={{
                        filter: blur ? 'blur(2px)' : 'none',
                        transition: blur ? 'none' : 'filter 0.3s ease-out',
                    }}
                    />              
                <PlayIcon>
                    <PlayArrowIcon 
                        className='play-icon'
                        style={{color: 'white', fontSize: '60px'}} 
                        />
                </PlayIcon>  
            </CardImageWrap>

            <h4>{data.title}</h4>
        
        </VideoCardContainer>
        
    )
}

const PlayIcon = styled.div`

    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%; 
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; 

    svg{
        display: none;
    }

`

const CardImageWrap = styled.div`
    width: 100%;

    @media (max-width: 768px) {
        & img{
            width: 100%;
            height: 40px;
            border-radius: 10px;
        }
    }
    & img{
        width: 100%;
        height: 120px;
        border-radius: 7px;
        object-fit: cover;
    }
`

const VideoCardContainer = styled.div`
    padding: 10px;
    width: 200px;
    height: 180px;
    color: rgb(94, 94, 94);
    background-color: white;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px #ccc;
    cursor: pointer;
    transition: all 0.5s;
    position: relative;

    h4{
        height: 10%;
        width: 100%;
        display: flex;
        text-align: left;
        font-weight: 400;
        font-size: 13px;
        margin-top: 10px;
        color: var(--lightThemeFontColor);
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &:hover{
        /* transition all width changes on hover */
        transition: all 0.5s;
        box-shadow: 0 0 5px rgb(83, 83, 83); 
        /* show dots on hover */

        /* display the svf 'play-icon'  */
        ${PlayIcon}{
            svg{
                transition: all 0.5s;
                display: flex;
            }
        }
        
    }
    @media (max-width: 768px) {
        padding: 8px;
        min-width: 150px;
        width: 20%;
        margin: 5px; 
        flex-basis: 20%;
    }

`