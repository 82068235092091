import React from 'react'
import styled from 'styled-components'
import { NoneContainer } from './postComponents.styled'
import { ImArrowUpRight2 } from "react-icons/im";
import { CgArrowTopRightR } from "react-icons/cg";
import { PiArrowBendRightUpBold } from "react-icons/pi";
import { PiArrowDownLeftBold } from "react-icons/pi";
import useGetWindowSize from 'hooks/useGetWindowSize';

export default function NoPosts() {

    const [ width, height ] = useGetWindowSize()

    return (
        <NoneContainer2>
            <span>You don't have any posts. Click on "Create Post" to share a prayer request, praise, or testimony.</span>
        </NoneContainer2>
    )
}

 const NoneContainer2 = styled.div`
    width: 100%;
    font-size: 13px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px; 
    box-shadow: 0 0 5px #ccc;
    background-color: white;
    color: var(--lightThemeFontColor);
    margin-bottom: 10px;
    position: relative;

    span{ 
    }

    svg{
        margin-bottom: 10px;
        font-size: 18px;
    }
    @media (max-width: 768px) {
        flex-direction: row;

        span{
            width: 50%;
        }
        svg{
            font-size: 40px;
        }
    }


`