import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const URL_GET_COORDINATES = '/api/maps/getCoordinates'

//for sending the token
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }

//---------------------------------------------------------------

const getCoordinates = async (data, token) => {
  const response = await axios.post(URL+URL_GET_COORDINATES, data)
  return response.data
}


const mapService = { getCoordinates }

export default mapService