import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { addEventDiscussionComment } from 'features/events/eventSlice'
import SpinnerSmall from 'components/SpinnerSmall'
import DiscussionCommentItem from './DiscussionCommentItem'
import SendIcon from '@mui/icons-material/Send';
import { VscCommentDiscussion } from "react-icons/vsc";
import { eventLanguage } from '../eventsLanguage' 
import Spinner from 'loaders/Loader'
import { AiOutlineComment } from "react-icons/ai";


export default function Discussion() {
  const dispatch = useDispatch()
  const [newComment, setNewComment] = useState('')
  const { user } = useSelector((state) => state.auth)
  const { profileChurch } = useSelector((state) => state.profileChurch)
  const [inputFocus, setInputFocus] = useState(false)

  const { 
    singlePageEventData, 
    eventDiscussionComments,
    addEventDiscussionCommentLoading,
    addEventDiscussionCommentSuccess,
    addEventDiscussionCommentError,
    isEventDiscussionCommentsLoading

  } = useSelector((state) => state.events)

  const handleCommentSubmit = () => {


    if (newComment.length < 1) {
      return
    }

    dispatch(addEventDiscussionComment({
      eventId: singlePageEventData._id,
      authorId: user._id, 
      authorName: user.fullName, 
      comment: newComment, 
    }))

    setNewComment('')
    setInputFocus(false)

  }


  if(!user){
    return (
      <div style={{color: 'var(--lightThemeFontColor)'}}>
       <i>login to view discussion</i> 
      </div>
    )
  }else { 
    return ( 
      <DiscussionContainer>  
        <CommentsWrap>
            {
              // ensure comments are loaded before mapping
              (eventDiscussionComments.length > 0) ?
              (
                eventDiscussionComments.map((ele, index) => ( 
                    (ele.authorId !== null) && (
                      <DiscussionCommentItem
                        key = {ele._id}
                        data = {ele}
                        index = {index}
                        />                      
                    ) 
                ))              
              )
              : isEventDiscussionCommentsLoading ? (
                <div
                  style={{
                    color: 'grey',
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    height: '100%'
                  }}
                  >
                  {/* <Spinner /> */}
                  
                  <span 
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    >
                    <AiOutlineComment 
                      style={{
                        fontSize: '30px', 
                        marginRight: '10px',
                        //create spin animation
                        // animation: 'spin 2s linear infinite'


                        


                      }} 
                      />
                    <i>
                    Loading Discussion...
                  </i>
                  </span> 
                </div>
                
              ) 
              :
              (
                <NoCommentsYet>
                  <VscCommentDiscussion style={{fontSize: '30px', marginRight: '10px'}} />
                  <i>
                    {eventLanguage.noCommentsYet[user.preferredLanguage]}
                  </i>
                </NoCommentsYet>
              )
  
            }
        </CommentsWrap>

        <FormWrap
          onFocus={() => setInputFocus(true)}
          onBlur={() => setInputFocus(false)}
          isActive={inputFocus}
          >
          <input 
            type="text" 
            placeholder={eventLanguage.addComment[user.preferredLanguage]}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            /> 
          <FormButton
            disabled={newComment.length < 1 || addEventDiscussionCommentLoading}
            onClick={handleCommentSubmit}
            >
            {
              addEventDiscussionCommentLoading ? (<SpinnerSmall />) : (<SendIcon />)
            }
          </FormButton>
        </FormWrap>
      </DiscussionContainer>
    )
  }
}

const NoCommentsYet = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--lightThemeFontColor);
`

const DiscussionContainer = styled.div`
  width: 100%; 
  display: flex;
  flex-direction: column; 
  position: relative;
  min-height: 300px; 
  height: 100%;
  background-color: white; 
  scrollbar-width: none; /* Firefox */
  box-shadow: 0 0 10px #ccc;
  border-radius: 10px;
  -ms-overflow-style: none;  /* IE 10+ */

  border-radius: 10px;

  &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
    background: transparent;

  }

`
const FormWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 65px;
  bottom: 0px;
  display: flex;
  flex-direction: row; 
  border-radius: 0px 0px 10px 10px;
  /* box-shadow: 0 0 10px #ccc; */
  background-color: #efefef;
  padding: 10px;
  border: ${props => props.isActive ? '2px solid var(--themeColor)' : '2px solid #aliceblue'};
  transition: all 0.4s ease-in-out;

  input{
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    color: var(--lightThemeFontColor);
    border-radius: 10px;   
    background-color: transparent;
  }
`
const FormButton = styled.div`
  cursor: pointer;
  width: fit-content;
  height: 100%;
  background-color: ${props => props.disabled ? 'none' : 'green'};
  color: ${props => props.disabled ? 'grey' : 'white'};
  border-radius: 5px;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

`
const CommentsWrap = styled.div`
  /* position: absolute; */
  width: 100%;
  height: 100%; 
  margin-top: 10px;
  padding-bottom: 65px;
  display: flex;
  flex-direction: column; 
  background-color: white;
  max-height: 800px;
  overflow-y: scroll;
  border-radius: 10px;
  /* box-shadow: 0 0 10px #ccc; */
  &::-webkit-scrollbar { /* WebKit */ 
    width: 5px; 
  }
  &::-webkit-scrollbar-track{
    background: transparent;
    margin-bottom: 70px;
  }
`