import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getVideos } from 'features/videos/videoSlice'
import VideoCard from './VideoCard'

export default function VideoFeed() {

    const dispatch = useDispatch()
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { videos } = useSelector((state) => state.video)

    useEffect(() => {
        dispatch(getVideos({
            churchId: profileChurch._id
        }))
    }, [])

    return (
        <VideoFeedContainer>
            { videos.map((video) => {
                return (
                    <VideoCard key={video._id} data={video} />
                )
            })}
        </VideoFeedContainer>
    )
}

const VideoFeedContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`