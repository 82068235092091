import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

export default function VideoPlayerModal({url}) {
    return (
        <ReactPlayerContainer>
            <ReactPlayer
                url={url}
                controls={true}
                width='100%'
                height='100%'
                />            
        </ReactPlayerContainer>
    )
}

const ReactPlayerContainer = styled.div`
    width:1000px;
    height:700px;
    background-color: #242424;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        width: 100vw;
        height: auto;
    }

`
