import { Cropper, getCroppedImg } from "react-cropper-custom";
import "react-cropper-custom/dist/index.css";
import { useState } from 'react';
import styled from 'styled-components';
import { setCroppedImage } from "features/profile/profileSlice";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { settingsLanguage } from "./settingsLanguage";

const AddImageCropper = (props) => {

    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.auth)
    const [img, setImg] = useState(props.image);
    const [zoom, setZoom] = useState(1);
    const [aspect, setAspect] = useState(1);
    const [croppedImg, setCroppedImg] = useState(props.image);
    const { croppedImage } = useSelector((state) => state.profile)

    const onCropComplete = async (croppedArea) => {
      try {
        const canvasSize = {
            width: 1200,
            height: 1200 * aspect
          };

        const image = await getCroppedImg(props.image, croppedArea, canvasSize);
        setImg(image);
        setCroppedImg(image);


        function b64toBlob(b64, onsuccess, onerror) {
            var img = new Image();
        
            img.onerror = onerror;
        
            img.onload = function onload() {
                var canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
        
                var ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        
                canvas.toBlob(onsuccess);
            };
        
            img.src = b64;
        }
        
        var base64Data = image

        b64toBlob(base64Data,
            function(blob) {
                var url = window.URL.createObjectURL(blob);
                console.log(url)
                dispatch(setCroppedImage(url))
            }, function(error) {
                console.log(error)
            }
        );

      } catch (e) {
        console.error(e);
      }
    };

    return (
        <AddImageCropContainer>

                <Cropper
                    
                    src={props.image}
                    width={250}
                    height={250}
                    zoom={zoom}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                    />


                <SliderWrap>

                    <span>
                        {settingsLanguage.cropImage[user.preferredLanguage]}
                        <Tooltip 
                            title={settingsLanguage.aspectRatio[user.preferredLanguage]}
                            placement="top-end"
                            >
                            <HelpOutlineIcon sx={{ fontSize: 15, marginLeft: '5px' }} />
                        </Tooltip>
                    </span>

                    <input
                        type="range"
                        min={1}
                        max={3}
                        step={0.1}
                        value={zoom}
                        onChange={(e) => setZoom(parseFloat(e.target.value))}
                        />                    
                </SliderWrap>

        </AddImageCropContainer>
    );
};

export default AddImageCropper;

const SliderWrap = styled.div`
    width: 100%;
    margin-top: 20px;
    color: var(--lightThemeFontColor);

    input{
        padding: 10px;
        width: 80%;
    }
`

const AddImageCropContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    border: 2px solid #ccc;
    padding: 20px;
    border-radius: 10px;

    .croppedImg{
        width: 100px;
        height: 100px;
    }

    @media (max-width: 768px) {

        
        width: 80%;
        margin: 0 auto;
        margin-top: 30px;
        padding: 30px;

        .croppedImg{
            width: 80px;
            height: 80px;
        }

    }

`