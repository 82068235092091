import React, { useRef } from 'react'
import styled from 'styled-components' 
import { Tooltip } from '@mui/material';
import { RxDownload } from "react-icons/rx";
import { LiaExternalLinkAltSolid } from "react-icons/lia";

import { BsPlayCircle } from "react-icons/bs";
import { IoMusicalNotesSharp } from "react-icons/io5";
import { useDispatch } from 'react-redux';
import { addListenCount, deleteAudio, setAudioData, setViewAudioUpdateModal } from 'features/audio/audioSlice';
import { setViewAudioPlayer, setCurrentAudioSource } from 'features/audio/audioPlayerSlice';
import { baseUrl } from 'features/constants';
import { useSelector } from 'react-redux';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import useToggleDropdown from 'hooks/useToggleDropdown';
import { DotEle, DotsWrap } from 'components/dots/dots.styled';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { setConfirmModalData, setViewConfirmModal2 } from 'features/modal/modalSlice';

export default function AudioItem({ audio }) {


    const dispatch = useDispatch()
    const { user, admin } = useSelector(state => state.auth)
    const ref = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref)
    const handleDropDown = () => { setIsDropped.toggle(ref) }
    const { profileChurch } = useSelector(state => state.profileChurch)


    const handleOpenLink = () => {  

        window.open(audio.audioUrl, '_blank')


    }

    const handlePlayAudio = () => {

        let audioUrl;

        if(audio.origin === 'device'){
            audioUrl = `https://api.praysely.com/static/audio/${audio.audioFilePath}`
        }else{


                // open new browser tab to play audio
                window.open(audio.audioUrl, '_blank')

                return 
        } 

        dispatch(setCurrentAudioSource({
            url: audioUrl,
            title: audio.title,
            description: audio.description,
            creator: audio.creator,
            // downloads: audio.downloads,
        })) 

        dispatch(addListenCount({audioId: audio._id}))
        dispatch(setViewAudioPlayer('250px'))
    }

    const handleDownloadAudio = async () => { 

        let audioUrl = `https://api.praysely.com/static/audio/${audio.audioFilePath}`

        
        fetch(audioUrl).then((response) => {
            response.blob().then((blob) => {  
                const fileURL = window.URL.createObjectURL(blob); 
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = audio.title;
                alink.click();
            });
        });
    }

    const handleDelete = () => { 
        dispatch(setConfirmModalData({ 
            text: 'Are you sure you want to delete this audio: ' + audio.title + '?',
            extraInfo: 'This action cannot be undone',
            handler: () => dispatch(deleteAudio({
                audioId: audio._id,
                churchId: profileChurch._id
            }))
            
        }))

        dispatch(setViewConfirmModal2({
            view: true,
            location: 'audio',
        })) 
    }

    const handleEdit = () => {
        dispatch(setAudioData(audio))
        dispatch(setViewAudioUpdateModal(true))
    }

    return (
        <AudioItemContainer>
            <section>
                <AISection >
                    <IoMusicalNotesSharp style={{ marginRight: '10px' }} />
                    <div>{audio.title}</div>                 
                </AISection> 
            </section>

            <AISection>
                <Tooltip title="Play" placement='top'>
                    <IconButton
                        onClick={handlePlayAudio}
                        >
                        <BsPlayCircle />
                    </IconButton>
                </Tooltip>


                {
                    audio.origin === 'web' ?
                    (
                        <Tooltip title="Open Link" placement='top'>
                            <IconButton
                                onClick={handleOpenLink}
                                download
                                >
                                <LiaExternalLinkAltSolid style={{backgroundColor: 'inherit'}} />
                            </IconButton>
                        </Tooltip> 
                    )
                    :
                    (                    
                        <Tooltip title="Download" placement='top'>
                            <IconButton
                                onClick={handleDownloadAudio}
                                download
                                >
                                <RxDownload style={{backgroundColor: 'inherit'}} />
                            </IconButton>
                        </Tooltip>   
                    )                 
                }



                {(admin[profileChurch._id] || user.isOrganization) && ( 
                    <>
                        <IconButton
                            ref={ref}
                            onClick={handleDropDown}
                            >
                            <MoreHorizIcon />
                        </IconButton> 
                        {
                            isDropped &&
                            (
                                <DotsWrap
                                    top="5px"
                                    right="20px"
                                    >
                                    <DotEle
                                        onClick={handleDelete}
                                        >
                                        <DeleteIcon style={{marginRight: '10px', fontSize: '15px'}} />
                                        Delete</DotEle>
                                    <DotEle
                                        onClick={handleEdit}
                                        >
                                        <EditIcon style={{marginRight: '10px', fontSize: '15px'}} />
                                        Edit</DotEle>
                                </DotsWrap>
                            )
                        } 
                    </> 
                )} 
            </AISection>  
        </AudioItemContainer>
    )
}

const IconButton = styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    outline: none;
    border: none;
    width: 30px;
    height: 30px;

    svg{
        font-size: 20px;
        color: var(--lightThemeFontColor);
        background-color: inherit;
    }
    &:hover{
        transition: all 0.3s;
        background-color: #dfdfdf;
        border-radius: 30px;
    }
`

const AISection = styled.span`
    position: relative;
    padding: 0 10px; 
    display: flex; 
    flex-direction: row;
    align-items: center;
    justify-content: center;

`

const AudioItemContainer = styled.div`
    color: var(--lightThemeFontColor);
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    margin: 5px 0;
    /* background-color: #c2c2c2; */
    border-radius: 5px;
    /* box-shadow: 0px 0px 5px #ccc; */
    border: 1px solid #dfdfdf;

    section{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    &:hover{
        cursor: pointer;    
        border: 1px solid #ccc;
    }

    @media (max-width: 768px) {
        width: 100%;
    }

`
