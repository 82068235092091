import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import audioService from './audioService'

const errorMessage = (error) => {
    return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
  }

const initialState = {

    audioSource: null, // { url: '', title: '', creator: '', description: '', downloads: 0, views: 0, fileSize: '', origin: '' }


    audioPlaying: false,
    audioPlayError: false,
    audioPlayErrorMessage: '',
    audioPlaySuccess: false,

    audioPaused: false,
    audioStopped: false,
    audioResumed: false,
    audioMuted: false,
    audioVolume: 1,
    audioSeeking: false,
    audioSeeked: false,
    audioEnded: false,
    audioLoaded: false,
    audioLoading: false,
    audioError: false,
    audioErrorMessage: '',
    audioDuration: 0,
    audioCurrentTime: 0,
    audioProgress: 0,
    audioBuffered: 0,
    audioBuffering: false,
    audioBufferedAmount: 0,
    audioBufferedChange: 0,
    audioBufferedEnd: 0,
    audioBufferedStart: 0,

    audioPlaybackRate: 1,
    audioPlaybackRateChanged: false,
    audioPlaybackRateError: false,
    audioPlaybackRateErrorMessage: '',
    
    audioPlayerWindowHeight: '0px',

    playerMinimized: false, // aka minimize

} 


export const getAudios = createAsyncThunk(
    'audio/getAudios',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await audioService.getAudios(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export  const audioPlayerSlice = createSlice({
    name: 'audioPlayer',
    initialState,
    reducers: {
        setViewAudioPlayer: (state, action) => {
            state.audioPlayerWindowHeight = action.payload
        },  

        setMinimizeAudioPlayer: (state, action) => {
            state.playerMinimized = action.payload.status
            state.audioPlayerWindowHeight = action.payload.height
        },

        setCurrentAudioSource: (state, action) => {   
            state.audioSource = action.payload
        }

    },
    extraReducers: (builder) => {



    },

})

export const { setViewAudioPlayer, setMinimizeAudioPlayer, setCurrentAudioSource } = audioPlayerSlice.actions

export default audioPlayerSlice.reducer

