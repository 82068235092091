import React, { useEffect, useState } from 'react'
import { ModalContainerMain } from 'components/modal/modal.styled'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { getCoordinates } from 'features/maps/mapSlice'
import { updateMyMarketLocation } from 'features/auth/authSlice'
import { LocationInputWrap } from '../market.styled';
import ListingLocation from '../components/listingForms/ListingLocation';
import CloseIcon from '@mui/icons-material/Close';
import { setViewMarketLocationModal } from 'features/marketplace/marketSlice';
import ModalCloseButton from 'components/buttons/ModalCloseButton'

export default function SetLocationModal() {

    const dispatch = useDispatch()

    const { location, searchTerm, getCoordinatesLoading, getCoordinatesSuccess } = useSelector(state => state.maps)
    const { user, marketLocationLoading, marketLocationSuccess, myMarketLocation  } = useSelector(state => state.auth)
    const [locationInput, setLocationInput] = useState('')


    const handleLocationInput = (e) => {
        setLocationInput(e.target.value)
    }

    const handleGetLocation = () => {
        dispatch(getCoordinates({
            address: locationInput
        }))
    }

    const handleSaveLocation = () => {
        dispatch(updateMyMarketLocation({
            locationAddress: location.bestMatch,
            coordinatesLng: location.data.results[0].geometry.location.lng,
            coordinatesLat: location.data.results[0].geometry.location.lat,
        })) 
    }

    useEffect(() => {
        if(getCoordinatesSuccess && location.data.status === 'OK' && location.bestMatch) {
            setLocationInput(location.bestMatch)
        }
    }, [getCoordinatesSuccess, location])

    return (
        <ModalContainerMain>
            <SetLocationModalContainer>

                <ModalCloseButton 
                    top='5px'
                    right='10px'
                    size='30px'
                    handler={() => dispatch(setViewMarketLocationModal(false))}
                    />

                <h1>Set Location</h1>

                <LocationInputWrap
                    style={{marginTop: '20px'}}
                    >
                    <ListingInput 
                        type="text"
                        placeholder="Enter location"
                        value={locationInput}
                        onChange={handleLocationInput}
                        />

                    <StyledBtn
                        disabled={getCoordinatesLoading}
                        onClick={handleGetLocation}
                        >
                        { getCoordinatesLoading ? 'Loading...' : 'Set'}
                    </StyledBtn>
                </LocationInputWrap>
 
                {
                    !getCoordinatesLoading && (
                        <MapContainer style={{height: '200px', position: "relative", marginTop: '10px'}}> 
                                <ListingLocation
                                    markerPosition={

                                        getCoordinatesSuccess ?  
                                        
                                        (
                                            [
                                                location.data.results[0].geometry.location.lat,
                                                location.data.results[0].geometry.location.lng
                                            ]
                                        ) 
                                        : 
                                        (
                                            [user.marketLocation.coordinates[1], user.marketLocation.coordinates[0]]
                                        )
                                    }
                                    // remember, mongodb requires coordinates to be in [lng, lat] format because that follows a more mathematical convention of (x, y)
                                    // whereas google maps uses [lat, lng] because that follows a more geographical convention of (y, x)
                                    // so we have to reverse the coordinates when we save them to the database
                                    // and when we display them on the react map component.
                                />
                        </MapContainer>              
                    ) 
               
                }
 

                <SaveBtn
                    onClick={handleSaveLocation}
                    >
                        { marketLocationLoading ? 'Saving...' : 'Save'}
                </SaveBtn>

            </SetLocationModalContainer>
        </ModalContainerMain>
    )
}

const SetLocationModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 450px;
    height: fit-content;
    max-height: 600px;
    overflow-y: scroll;
    position: relative;
    /* hide scrollbar */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar {
        display: none;
    }


    background-color: white;
    border-radius: 10px;
    padding: 20px;

    h1{
        font-size: 15px;
        color: var(--lightThemeFontColor);
    }

`

const CloseButton = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
`

const MapContainer = styled.div`
  position: relative;
  min-height: 200px;
  height: 50%;
  width: 100%;
  margin-top: 10px;
  border-radius: 15px; 
  border: 1px solid #d6d6d6;
  box-shadow: 0 0 2px rgba(0,0,0,0.2);
`


const ListingInput = styled.input`
    width: 100%;
    padding: 5px;
    border: none;
    outline: none;
`
const StyledBtn = styled.button`
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
`

const SaveBtn = styled.button`
    width: 100px;
    padding: 10px;
    border: none;
    outline: none; 
    background-color: green;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    &:hover{
        background-color: #00b300;
    }
`