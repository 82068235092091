import React from 'react'
import styled from 'styled-components'
import { imgUrl } from 'features/constants'
import { aboutChurchStore } from 'app/aboutChurchStore';


export default function PersonCard(props) {

  const setViewPersonCardModal = aboutChurchStore(state => state.setViewPersonCardModal)
  const setPersonDetails = aboutChurchStore(state => state.setPersonDetails)
  

  let cardImageToShow;
  try{  cardImageToShow =  `${imgUrl}/aboutSection/${props.data.image.imagePath2}`}
  catch(e){  cardImageToShow = null}

  const handlePersonClick = () => {
    setPersonDetails({
      ...props.data,
      image: cardImageToShow,
      index: props.index
    })
    setViewPersonCardModal(true)
  }

  return (
    <PersonCardContainer first={props.index === 0} onClick={handlePersonClick}>
      <PersonCardImage>
        {
          cardImageToShow ? (
            <img src={cardImageToShow} alt={'card'}/>
          )
          :
          (
            <img src={`${imgUrl}/directory/default.jpg`} alt={'card'}/>
          )
        }
      </PersonCardImage>
      <PersonCardLower>
        <PersonCardName> {props.data.fullName} </PersonCardName>
        <PersonCardRole> {props.data.role} </PersonCardRole>         
      </PersonCardLower> 
    </PersonCardContainer>
  )
}

const PersonCardContainer = styled.div`
  width: 250px;
  height: 350px;
  background-color: #fff;
  border-radius: 5px;
  /* box-shadow: 0 0 10px rgba(0,0,0,0.5); */
  margin: 5px;
  margin-left: ${props => props.first ? '0px' : '20px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  cursor: pointer;
  position: relative;

  &:hover{
    transition: all 0.4s;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
  }

  @media (max-width: 768px) {
    margin-left: 0px;
    margin-bottom: 10px;
    width: 75%;
    height: 320px;
  }
`

const PersonCardImage = styled.div`
    width: 100%;
    height: 75%; 

    img{
      width: 100%;
      height: 100%;
      border-radius: 5px 5px 0px 0px;
      object-fit: cover;
    }    
    
    @media (max-width: 768px) {
      img{
        width: 100%;
        /* height: 40px; */
        border-radius: 5px;
      }
    }

`

const PersonCardName = styled.div`
  font-size: 18px;
  margin-top: 10px;
  color: rgb(100,100,100);
  font-weight: 600;
`

const PersonCardRole = styled.div`
  font-size: 14px;
  margin-top: 5px;
  color: rgb(100,100,100);
  font-weight: 400;
`

const PersonCardLower = styled.div`
  width: 100%;
  position: absolute;
  background-color: #fff;
  height: 25%;
  bottom: 0px;
  padding: 10px;
  border-radius: 0px 0px 10px 10px;

  @media (max-width: 768px) {
    padding: 5px;
    height: fit-content;
  }
`