import React, {useRef, useState, useEffect} from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { uploadAudio } from 'features/audio/audioSlice'
import { setSelectedAudioSource, setSelectedAudioFile, resetAudioUploadSuccess, setViewUploadAudioModal } from 'features/audio/audioSlice'
import SourceSelect from '../SourceSelect'
import { IoArrowBack } from "react-icons/io5";
import { 
    FileInputWrap, GoBackButton, FileUploadInput, FileUploadButton, ButttonWrap,
    FormWrap, BulletinFile, BulletinFileChild1, BulletinFileName, ChooseFileBtn
} from 'components/files/files.styled' 
import { IoIosLink } from "react-icons/io";
import { HiOutlineCloudUpload } from "react-icons/hi";
import SpinnerSmall from 'components/SpinnerSmall'
import { toast } from 'react-toastify'



export default function UploadAudioModal() {

    const dispatch = useDispatch() 
    const audioRef = useRef(null)
    const audioTitleRef = useRef(null)
    const urlRef = useRef(null)
    const audioDescriptionRef = useRef(null)
    const { selectedAudioSource, selectedAudioFile, audioUploading, audioUploadSuccess } = useSelector((state) => state.audio)
    const { profileChurch } = useSelector((state) => state.profileChurch)

    const handleUploadAudio = () => {


        if(selectedAudioSource === 'url' && !urlRef.current.value){
            return toast.error('Please enter a URL')
        }
        if(selectedAudioSource === 'device' && !audioRef.current.files[0]){
            return toast.error('Please select a file')
        }
        if(!audioTitleRef.current.value){
            return toast.error('Please enter a title')
        }
        if(!audioDescriptionRef.current.value){
            return toast.error('Please add a description')
        }

        let formData = new FormData()
 
        formData.append('churchId', profileChurch._id)
        if(selectedAudioSource === 'device'){
            formData.append('file', audioRef.current.files[0])
        } 
        formData.append('title', audioTitleRef.current.value)
        formData.append('description', audioDescriptionRef.current.value)
        formData.append('originSource', selectedAudioSource === 'device' ? 'device' : 'url')

        if(selectedAudioSource === 'url'){
            formData.append('audioUrl', urlRef.current.value)
        }

        dispatch(uploadAudio(formData))

    }

    const handleAudioFileChange = (e) => {
        dispatch(setSelectedAudioFile(e.target.files[0]))
    }

    useEffect(() => { 
        if(audioUploadSuccess){ 
            dispatch(setSelectedAudioFile(null))
            dispatch(setSelectedAudioSource(null))
            dispatch(resetAudioUploadSuccess())
            dispatch(setViewUploadAudioModal(false))
        }
    }, [ audioUploadSuccess ])


    if(!selectedAudioSource){
        return (
            <SourceSelect
                handler1={() => dispatch(setSelectedAudioSource('device'))}
                handler2={() => dispatch(setSelectedAudioSource('url'))}
                mediaType={'audio'}
                />
        )
    }

    return (
        <Wrap>
            <GoBackButton
                onClick={() => dispatch(setSelectedAudioSource(null))}
                >
                <IoArrowBack />
                    Go Back
            </GoBackButton>
            
            <FormWrap>
                <BulletinFile>
                    {
                        selectedAudioSource === 'url' ? 
                        (

                            <FileInputWrap>
                                <IoIosLink style={{fontSize: '20px', color: 'var(--lightThemeFontColor)'}} />

                                <FileUploadInput
                                    ref={urlRef}
                                    placeholder={'Enter URL'}
                                    />                                    
                            </FileInputWrap> 
                        )
                        : selectedAudioSource === 'device' &&
                        (
                            <>
                                <BulletinFileChild1 > 
                                    <BulletinFileName>
                                        {selectedAudioFile ? 
                                            (
                                                selectedAudioFile.name
                                            ) 
                                            :  
                                            ('No file Selected') 
                                        }
                                    </BulletinFileName>
                                    <FileUploadInput  
                                        hidden id="audioUpload"  
                                        ref={audioRef}
                                        type="file"   
                                        accept='audio/*'
                                        name="file"  
                                        onChange={handleAudioFileChange}
                                        />                     
                                </BulletinFileChild1>
                                <ChooseFileBtn htmlFor="audioUpload"> 
                                    <span>Select Audio File</span>
                                </ChooseFileBtn>                            
                            </> 
                        )
                    }  
                </BulletinFile>

                <FileInputWrap
                    style={{marginTop: '20px'}}
                    >  
                    <FileUploadInput
                        style={{paddingLeft: '0px'}}
                        placeholder={'Audio File Title'} 
                        ref={audioTitleRef}
                        />
                </FileInputWrap>


                <FileInputWrap
                    style={{padding: '0px', paddingBottom: '0px', paddingLeft: '5px'}}
                    >
                    <FileDescription
                        placeholder={'Audio File Description'}
                        ref={audioDescriptionRef}
                        /> 
                </FileInputWrap>

                <ButttonWrap>
                    <FileUploadButton 
                        disabled={audioUploading}
                        onClick={handleUploadAudio}
                        >
                            {
                                audioUploading ? (
                                    <SpinnerSmall />
                                )
                                :
                                (
                                    <>
                                        <HiOutlineCloudUpload style={{ marginRight: '10px'}} />
                                        <span>Upload</span>
                                    </>
                                )
                            }


                    </FileUploadButton>
                </ButttonWrap>
                </FormWrap>


        </Wrap>
    )
}

const Wrap = styled.div`
    width: 500px;
    min-height: 300px;
    background-color: #fff;
    padding: 20px;
`
const FileDescription = styled.textarea`
    padding: 10px;
    width: 100%;
    height: 100px;
    border: none; 
    border-radius: 5px;
    outline: none;
    font-size: 16px;
    color: var(--lightThemeFontColor); 
    resize: none;
    font-family: Arial, Helvetica, sans-serif;
`
