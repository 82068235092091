import { useRef } from 'react';
import styled from 'styled-components'
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useSelector, useDispatch } from 'react-redux'
// import Loader from "loaders/Loader";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import useToggleDropdown from 'hooks/useToggleDropdown';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import StarsIcon from '@mui/icons-material/Stars';
import { updateCard } from 'features/billing/billingSlice';
import { DotEle, DotsWrap } from 'components/dots/dots.styled';

export default function WalletCardItem(props) {
    let fontIconStyle = {fontSize: '26px', color: 'rgb(77,77,77'}
    const dispatch = useDispatch()
    const ref1 = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref1)
    const handleDropDown = () => { setIsDropped.toggle(ref1) }

    const {  defaultPmId  } = useSelector((state) => state.billing)
    
      const handleClick = (value) => {
        dispatch(updateCard({
            paymentId: props.data.id,
            action: value
        }))
      }
    
  return (
    <CardItem isDefault={props.data.id===defaultPmId} >
        <CreditCardIcon style={{fontSize:'50px'}}/>
        <CardInfo>
        {`${props.data.card.brand} ${props.data.card.funding} card ending in ****${props.data.card.last4}`}
        </CardInfo>
        { props.data.id===defaultPmId && (
            <Default><i>default</i></Default>
        )

        } 
        <MoreDotsW onClick={handleDropDown} ref={ref1}>
            <IconDiv><MoreHorizIcon style={fontIconStyle} /></IconDiv>
        </MoreDotsW>
        {/* should not be able to delete default payment */}
        {(isDropped && (props.data.id !== defaultPmId)) && 
            (
                <DotsWrap>
                    <DotEle 
                        onClick={() => handleClick('defaultCard')} 
                        > 
                        <StarsIcon className='postItemDotsIcon'/> 
                        Set Default 
                    </DotEle>
                    <DotEle 
                        onClick={() => handleClick('deleteCard')}
                        > 
                        <DeleteForeverIcon className='postItemDotsIcon'/> 
                        Delete 
                    </DotEle>
                </DotsWrap>
            )}
    </CardItem>  
  )
}

const CardItem = styled.div`
    position: relative;
  color: var(--dFontColor);
  background-color: white;
  border-radius: 5px;
  /* border-left: 5px solid green; */
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  border: ${props => props.isDefault ? ('2px solid green'):('none')};
  border-left: ${props => props.isDefault ? ('5px solid green'):('none')}
`

const CardInfo = styled.div`
  text-align: left;
  margin-left: 10px;
`

const Default = styled.div`
    position: absolute;
    right: 2px;
    bottom: 2px;
`

const MoreDotsW = styled.div`
    position: absolute;
    cursor: pointer;
    top: 2px;
    right: 2px;
    padding: 3px;
    border-radius: 10px;
    align-self: flex-start;
    justify-self: flex-start;
    display: flex;
    flex-direction: row;
    transition: all 0.5s;
    &:hover{
        background-color: #dfdfdf;
    }

`
const IconDiv = styled.div`
    margin-right: 3px;
    margin-left: 3px;
    border-radius: 4px;
    cursor: pointer;
`