 
import {React, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'  
import { addFamilyMember, setViewAddPersonModal } from '../../../features/modal/directoryModalSlice'  
import PersonAddIcon from '@mui/icons-material/PersonAdd';
  
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'; 
import InfoIcon from '@mui/icons-material/Info'; // for about section
import { Modal, Tooltip } from '@mui/material' 
import { InputWrap, AddUserInputWrap, AddUserBtnWrap, AddUserBtn, AddBirthdayBtn } from '../directory.styled'
import { directoryLanguage } from '../directoryLanguage';

export default function AddPersonInfoToDirectory() {

    const dispatch = useDispatch()
    const members = useRef()
    const [ viewAddUserForm, setViewAddUserForm ] = useState(false)
    const { familyMembers, viewAddPersonModal } = useSelector((state) => state.directoryModal)
    const { user } = useSelector((state) => state.auth)
 
    const [emailValid, setEmailValid] = useState()
    const [open, setOpen] = useState(false); 

    const I_STATE = {
        name: '',
        email: '',
        phone: '',
        about: '',
        birthday: ''
    }
    
      const [birthday, setBirthday] = useState(null);
      const [userInfo, setUserInfo] = useState(I_STATE)  
      const { name, email, phone, about } = userInfo
      const [includeBirthday, setIncludeBirthday] = useState(false)

    const addMember = (action) => {

        if(!name){ return toast.error(directoryLanguage.toastErrorAddName[user.preferredLanguage]) }

        if(familyMembers.length < 13){
    
            dispatch(addFamilyMember( userInfo  ))
            setUserInfo(I_STATE)
            dispatch(setViewAddPersonModal(false))
    
        }else{
            toast.error('Limit 12 family members')
        }
        
    }

    const handleName = (e) => {
        setUserInfo((prevState) => (
            {
                ...prevState,
                [e.target.name]: e.target.value
            } )
        )
      }
    
      const handleEmail = (e) => {
            let emailSanitize = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            setUserInfo((prevState) => (
                {
                    ...prevState,
                    [e.target.name]: e.target.value
                })
            )
            if ( emailSanitize.test(e.target.value) ) {setEmailValid(true)}
            else {setEmailValid(false)}
      }
    
      const handlePhone = (e) => {
        setUserInfo((prevState) => (
            {
                ...prevState,
                [e.target.name]: e.target.value
            })
        )
    }

    const handleBirthday = (value) => {

        let date =  new Date(value)
    
        let month = date.getMonth() + 1
        let day = date.getDate()
    
        console.log('month', month)
        console.log('day', day)
    
        setUserInfo((prevState) => (
            {
                ...prevState,
                birthday: `${month}/${day}`
            })
        )
    
    }

    const handleCancel = () => { 
        dispatch(setViewAddPersonModal(false))
 
    }

    return (
        <Modal
            open={viewAddPersonModal}
            onClose={() => dispatch(setViewAddPersonModal(false))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-descriptionx"
            >
            <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
        

                <AddUserInputWrap style={{width: '500px'}} >
                    <InputWrap hasInput={name}>  
                        <DriveFileRenameOutlineIcon  />
                        <input  
                            ref={members} 
                            name='name' 
                            value={name} 
                            onChange={handleName}  
                            maxLength = "25"  
                            placeholder={directoryLanguage.name[user.preferredLanguage]}
                            />
                    </InputWrap>
                    <InputWrap hasInput={email}>  
                        <EmailIcon  />
                        <input 
                            ref={members} 
                            name='email' 
                            value={email} 
                            onChange={handleEmail}  maxLength = "35"  
                            placeholder={directoryLanguage.email[user.preferredLanguage]}
                            />
                    </InputWrap>
                    <InputWrap hasInput={phone}>  
                        <PhoneIphoneIcon  />
                        <input 
                            ref={members} 
                            name='phone' 
                            value={phone} 
                            onChange={handlePhone}  maxLength = "10"  
                            placeholder={directoryLanguage.phone[user.preferredLanguage]}
                            />
                    </InputWrap>


                    {/* 
                    
                    
                MUST ADD CHURCH OFFICE POSITION HERE
                MUST ADD CHURCH OFFICE POSITION HERE
                MUST ADD CHURCH OFFICE POSITION HERE

                if applicable
                    
                    
                    */}


                    {
                        !includeBirthday && ( 
                            <AddBirthdayBtn onClick={() => setIncludeBirthday(!includeBirthday)}>
                                <CalendarMonthIcon />
                                {
                                    directoryLanguage.addBirthday[user.preferredLanguage]
                                }
                            </AddBirthdayBtn> 
                        )
                    }

                    {
                        includeBirthday && (
                            <InputWrap hasInput={phone}>  
                                <CalendarMonthIcon  />

                                <LocalizationProvider 
                                    
                                    dateAdapter={AdapterDayjs}>
                                    <DemoContainer   
                                        components={['DatePicker', 'DatePicker', 'DatePicker']}
                                        >
                                        <DatePicker 
                                            open={open}
                                            onClose={() => setOpen(false)}
                                            slotProps={{
                                                textField: {
                                                    onClick: () => setOpen(true),             
                                                },
                                            }} 
                                            className='datePickerPraysely'
                                            value={birthday} 
                                            onChange={(newValue) => handleBirthday(newValue)}
                                            views={['month', 'day']}
                                            /> 
                                    </DemoContainer>
                                </LocalizationProvider>     
                            </InputWrap>
                        )
                    }       
                    <InputWrap hasInput={about}>  
                        <InfoIcon  /> 
                        <textarea 
                            ref={members} 
                            name='about' 
                            value={about} 
                            onChange={handlePhone}  maxLength = "200"  
                            placeholder={directoryLanguage.about[user.preferredLanguage]}
                            />
                    </InputWrap>
                    <AddUserBtnWrap>
                        <AddUserBtn onClick={addMember}> 
                            <PersonAddIcon style={{fontSize: '16px', marginRight: '12px', marginLeft: '5px'}}/>
                            
                            {
                                directoryLanguage.addPersonToDirectory[user.preferredLanguage]
                            }
                        </AddUserBtn>  
                        <span 
                            onClick={handleCancel} 
                            style={{fontSize: '12px', cursor: 'pointer', marginLeft: '10px', color: 'rgb(129, 129, 129)'}}
                            >
                                {
                                    directoryLanguage.cancel[user.preferredLanguage]
                                }
                        </span>
                    </AddUserBtnWrap> 
                </AddUserInputWrap>
            </div>
        </Modal>
    )
}
