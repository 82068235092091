import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import marketService from './marketService'
import { forSaleCategories, listingTypes, searchRadius, servicesCategories } from './marketConstants'

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

// if the listingTypeFilter is not set in localStorage OR if it is something that is not in the listingTypes object, set it to 'For Sale'

if(!localStorage.getItem('marketListingTypeFilter') || !Object.keys(listingTypes).includes(localStorage.getItem('marketListingTypeFilter'))){
    localStorage.setItem('marketListingTypeFilter', 'For Sale')
}



const initialState = {

    // ======  constants =================
    listingTypes: listingTypes,
    forSaleCategories: forSaleCategories,
    servicesCategories: servicesCategories, 
    searchRadius: localStorage.getItem('marketSearchRadius')? Number(localStorage.getItem('marketSearchRadius')) : 100,
    searchRadii: searchRadius,  // array of objects
    // ===================================


    listings: [],
    listingType: null,
    listingTypeFilter: localStorage.getItem('marketListingTypeFilter') ? localStorage.getItem('marketListingTypeFilter') : 'For Sale',
    listingImages: [],

    viewMarketLocationModal: false,
    searchLocation: {
        address: '',
        coordinates: [null, null]
    },
 
    forSaleCategory: null,
    forSaleSubCategory: null,
    selectedCategory: null,

    isGetListingsSuccess: false,
    isGetListingsLoading: false,
    isGetListingsError: false,
    getListingsMessage: '',

    isAddListingSuccess: false,
    isAddListingLoading: false,
    isAddListingError: false,
    addListingMessage: '',

    searchResults: [],
    isSearchActive: false,
    searchQuery: '',
    searchCategory: '',
    isSearchLoading: false,
    isSearchSuccess: false,
    isSearchError: false,
    searchMessage: '',
    
    viewAddListingModal: false,

    formData: {
        title: '',
        description: '',
        price: '',
        category: '',
        subcategory: '',
        images: [],
        location: '',
        contact: '',
        type: '',
        user: '',
        listingType: '',
    },
    
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    hasPrevPage: false,
    nextPage: null,
    prevPage: null,
    hasNextPage: false,

}

 
export const getListings = createAsyncThunk(
    'market/getListings',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await marketService.getListings(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const addListing = createAsyncThunk(
    'market/addListing',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await marketService.addListing(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const searchListings = createAsyncThunk(
    'market/searchListings',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await marketService.searchListings(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)



export const marketSlice = createSlice({
    name: 'market',
    initialState,
    reducers: {
        resetListing: (state) => initialState,

        setViewAddListingModal: (state, action) => {
            state.viewAddListingModal = action.payload 
        },
        setListingType: (state, action) => {
            state.listingType = action.payload
        },
        setListingImages: (state, action) => {
            state.listingImages = action.payload
        },
        setForSaleCategory: (state, action) => {
            state.forSaleCategory = Object.keys(state.forSaleCategories[action.payload.index])
            state.forSaleSubCategory = state.forSaleCategories[action.payload.index][state.forSaleCategory]
        },
        resetCategory: (state) => {
            state.forSaleCategory = null
            state.forSaleSubCategory = null
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload
        },
        setSearchMarketRadius: (state, action) => {
            state.searchRadius = action.payload
        },
        setListingTypeFilter: (state, action) => {
            state.listingTypeFilter = action.payload
            localStorage.setItem('marketListingTypeFilter', action.payload)
        },
        setViewMarketLocationModal: (state, action) => {
            state.viewMarketLocationModal = action.payload
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(getListings.pending, (state) => {
                state.isGetListingsLoading = true
            })
            .addCase(getListings.fulfilled, (state, action) => {
                state.isGetListingsLoading = false
                state.isGetListingsSuccess = true

                if(action.payload.currentPage === 1) {
                    state.listings = action.payload.listings
                }else{
                    state.listings.push(...action.payload.listings)
                }

                state.totalItems = action.payload.totalItems
                state.totalPages = action.payload.totalPages
                state.currentPage = action.payload.currentPage
                state.hasPrevPage = action.payload.hasPrevPage
                state.hasNextPage = action.payload.hasNextPage
                state.prevPage = action.payload.prevPage
                state.nextPage = action.payload.nextPage


            })
            .addCase(getListings.rejected, (state, action) => {
                state.isGetListingsLoading = false
                state.isGetListingsError = true
                state.getListingsMessage = action.payload
            })
            //----------------------------------------------------------------
            .addCase(addListing.pending, (state) => {
                state.isAddListingLoading = true

            })
            .addCase(addListing.fulfilled, (state, action) => {
                state.isAddListingLoading = false
                state.isAddListingSuccess = true

                // state.listings = action.payload.listings

            })
            .addCase(addListing.rejected, (state, action) => {
                state.isAddListingLoading = false
                state.isAddListingError = true
                state.addListingMessage = action.payload
            })
            //----------------------------------------------------------------
            .addCase(searchListings.pending, (state) => {
                state.isSearchLoading = true
            })
            .addCase(searchListings.fulfilled, (state, action) => {
                state.isSearchLoading = false
                state.isSearchSuccess = true

                state.listings = action.payload.searchResults

                // state.isSearchActive = true
                // state.searchResults = action.payload

            })
            .addCase(searchListings.rejected, (state, action) => {
                state.isSearchLoading = false
                state.isSearchError = true
                state.searchMessage = action.payload
            })

    }
})

export const { 
    setSelectedCategory, resetListing, setViewAddListingModal, 
    setListingType, setListingImages, setForSaleCategory, resetCategory,
    setSearchMarketRadius, setListingTypeFilter, setViewMarketLocationModal, 

} = marketSlice.actions
export default marketSlice.reducer
