import ModalCloseButton from 'components/buttons/ModalCloseButton'
import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { setViewSendQuickMessageModal, setQuickMessageData, sendQuickMessage } from 'features/posts/postSlice'
import { imgUrl } from 'features/constants';
import SendIcon from '@mui/icons-material/Send';
import { BsSendCheckFill } from "react-icons/bs";


export default function SendQuickMessageModal() {

    const dispatch = useDispatch()
    const { quickMessageData, quickMessageStatus } = useSelector( (state) => state.posts)
    const messageRef = useRef( null )

    const handleSendMessage = () => { 
        dispatch(sendQuickMessage({
            receiverUserType: quickMessageData.userType,
            receiverId: quickMessageData.userId,
            message: document.querySelector('textarea').value
        }))
    }

    return (
        <SendQuickMessageModalContainer> 
            <ModalCloseButton
                handler={
                    () =>  {
                        dispatch(setQuickMessageData({
                            userId: '',
                            fullName: '',
                            profileImage: '',
                        }))
                        dispatch(setViewSendQuickMessageModal(false))
                        
                    }
                }
                /> 

            {
                quickMessageStatus === 'error' ? 
                (
                    <div> Error sending message. Please try again later </div>
                )
                : quickMessageStatus === 'success' ?
                (
                    <SuccessContainer> 
                        <BsSendCheckFill 
                            style={{
                                fontSize: '50px', 
                                color: 'green', 
                                marginBottom: '20px',
                                // spin animation only 1 rotation
                                animation: 'spin .5s linear'
                                }} 
                                />
                        <span> 
                            Message sent to {quickMessageData.fullName}
                        </span>  
                    </SuccessContainer>
                )
                : (quickMessageStatus === 'idle' || quickMessageStatus === 'loading') &&  
                ( 
                    <FormContainer>
                        <Recipient> 
                            <span> 
                                Your message to 
                            </span>
                            <span
                                style={
                                    {
                                        marginTop: '10px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }
                                }
                                >
                                <RecipientImage
                                alt='quickmessage'
                                src={`${imgUrl}/profile/${quickMessageData.profileImage}`}
                                />  
                            
                                {quickMessageData.fullName}
                            </span>
                        </Recipient>

                        <MessageEntryContainer> 
                            <MessageEntry
                                ref={messageRef}
                                placeholder="Type your message here..."
                                rows="5"
                                />
                        </MessageEntryContainer>

                        <BtnContainer>
                            <SendBtn 
                                disabled={quickMessageStatus === 'loading' ? true : false}
                                onClick={handleSendMessage}
                                > 
                                <SendIcon />
                                {
                                    quickMessageStatus === 'loading' ? 'Sending...' : 'Send Message'
                                }
                            </SendBtn>
                        </BtnContainer> 
                    </FormContainer>
                )
            }
        </SendQuickMessageModalContainer>
    )
}

const SuccessContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const FormContainer = styled.div` 
    width: 100%;
    height: 100%;
`

const BtnContainer = styled.div`
    margin-top: 20px;
    width: 100%; 
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;

`

const SendBtn = styled.div` 
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    width: fit-content;
    height: 40px;
    border-radius: 10px;
    background-color: var(--themeColor);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    font-size: 12px;
    padding: 0px 20px;
    svg {   
        margin-right: 10px;
    }
    &:hover {
        background-color: #0d6efd;
    }
`

const Recipient = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; 
`

const RecipientImage = styled.img`
    width: 45px;
    height: 45px;
    border-radius: 45px;
    margin-right: 10px;
`

const MessageEntryContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 50%; 
    width: 100%; 
`



const MessageEntry = styled.textarea`
    outline: none;
    border: none;
    padding: 20px;
    height: 100%;
    width: 100%;
    border-radius: 20px;
    background-color: aliceblue;

`
const SendQuickMessageModalContainer = styled.div`
    width: 600px;
    height: 400px;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    justify-content: flex-start;
    padding: 20px;
    color: var(--lightThemeFontColor);
    position: relative;
`