import {useState} from 'react'
import styled from 'styled-components'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useSelector, useDispatch } from 'react-redux'
import { toggleAcceptTerms } from 'features/auth/authSlice';

export default function AcceptTerms() {

  const dispatch = useDispatch()
  const { termsAccepted, errorField } = useSelector( (state) => state.auth)

  const handleClickCheck = () => {
    dispatch(toggleAcceptTerms())
  }

  return (
    <TermsWrap onClick={handleClickCheck}>
      <IconWrap errorField={errorField === 'terms'}>
        {termsAccepted ? 
          <CheckBoxIcon style={{color: 'green'}} /> 
          : 
          <CheckBoxOutlineBlankIcon style={{ color: 'rgb(77, 77, 77)' }} /> 
        }
      </IconWrap>
      <TermsHeading>Agree to 
        <InlineLink 
          onClick={(e) => e.stopPropagation()}
          target='_blank'
          href='https://www.praysely.com/info/terms'>
            Terms and Conditions
          </InlineLink>
        </TermsHeading>
    </TermsWrap>
  )
}

const TermsWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  height: fit-content; 
`

const IconWrap = styled.div`
  border: ${props => props.errorField === true ? '1px solid red' : 'none'};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const TermsHeading = styled.span`
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: rgb(77, 77, 77);
  margin-left: 10px;
`
const InlineLink = styled.a`
  margin-left: 5px;
  color: rgb(77, 77, 77);
  text-decoration: underline;
  cursor: pointer;
`