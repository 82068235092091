import React from 'react'
import styled from 'styled-components'
import FooterLP from './FooterLP'
import HomeComponent from './HomeComponent'
import useGetWindowSize from 'hooks/useGetWindowSize'
import HomeComponentSmall from './HomeComponentSmall'
import OurMission from './OurMission'


export default function AboutUs() {
    
    const [screenWidth, screenHeight] = useGetWindowSize()

    if(screenWidth < 768){
        <>
            <HomeComponentSmall 
                // includeMission={true}
                />
        </>

    }


    return (
    <>
        <HomeComponent />
        <AboutCont>

            <OurMission />

        </AboutCont>
        <FooterLP />
    
    </>
  )
}

const AboutCont = styled.div`
    color: #ffffff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #6f952e;
    padding: 50px;
`
