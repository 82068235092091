import { getBirthdays } from 'features/directory/directorySlice'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Loader from 'loaders/Loader'

export default function BirthdaysDropdown() {

    const dispatch = useDispatch()

    const { birthdays, getBirthdaysLoading, getBirthdaysSuccess } = useSelector((state) => state.directory)
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const [monthsAhead, setMonthsAhead] = useState(2)

    useEffect(() => {
        dispatch(getBirthdays({
            monthsAhead: monthsAhead,
            churchId: profileChurch._id, 
        }))

    }, [monthsAhead])

    return (
        <BirthdayDropContainer
            onClick={(e) => e.stopPropagation()}
            >

            <BDayTop>
                <span
                    style={{marginRight: '5px'}}
                    >Birthdays in the next</span>

                <select 
                    value={monthsAhead}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => setMonthsAhead(e.target.value)}
                    >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option> 
                    <option value={4}>4</option> 
                    <option value={5}>5</option> 
                    <option value={6}>6</option> 
                </select>

                <span
                    style={{marginLeft: '5px'}}
                    >months</span>

            </BDayTop>

            {
                getBirthdaysLoading ? 
                (
                    <Loader />
                )
                : getBirthdaysSuccess && birthdays.length > 0 ? 
                (
                    birthdays.map((birthday) => (
                        <BDayItem key={birthday.familyId}>

                            <span>{`${birthday.name} ${birthday.familyName}`}</span>
                            <p>{birthday.fullMonth} {birthday.dayOfMonth}</p>

                        </BDayItem>
                    ))
                )
                : birthdays.length === 0 && 
                (
                    <span>
                        No upcoming birthdays
                    </span>
                )
            }

        </BirthdayDropContainer>
    )
}

const BDayItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; 
    width: 100%;
    font-size: 12px;
    text-align: left;
    color: var(--lightThemeFontColor); 
    padding: 5px 10px;
    cursor: default;

    p{
        margin-left: 5px; 
        font-size: 10px;
    }

    &:hover{
        background-color: #e9e9e9;
        border-radius: 5px;
    }

`

const BDayTop = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 400;
    color: var(--lightThemeFontColor);
    & > select {
        font-size: 12px;
        font-weight: 400;
        color: var(--lightThemeFontColor);
        border: none;
        outline: none;
        background-color: transparent;
        margin: 0px 5px;
        padding: 0px 5px;
        border-bottom: 1px solid var(--lightThemeFontColor);
    }
`

const BirthdayDropContainer = styled.div`

    position: absolute;
    top: 30px;
    right: 10px;
    width: 250px;
    height: fit-content;
    max-height: 600px; 
    min-height: 300px;
    background-color: white;
    border-radius: 15px 0px 15px 15px;
    box-shadow: 0px 0px 11px #ccc;
    z-index: 100;
    padding: 10px;

    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

`