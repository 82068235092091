import React from 'react'
import styled from 'styled-components'
import { imgUrl } from 'features/constants'
import { landingPageStore } from 'app/landingPageStore'


export default function AboutItem(props) {

    const { userType } = landingPageStore()    


    return (
        <AboutItemContainer reverse={props.reverseRow}>
            <AboutLeft reverse={props.reverseRow}>
                <AboutIconWrap>{props.iconComponent}</AboutIconWrap>
                <AboutItemName reverse={props.reverseRow}>{props.itemName}</AboutItemName>                
            </AboutLeft>
            <AboutCenter>
                <h3>{props.h3Name}</h3>
                {
                    userType === 'church' ?
                    (<p>{props.churchInfo} </p>)
                    :
                    (<p>{props.userInfo} </p>)
                }
                {/* <p>{props.paragraph} </p> */}
            </AboutCenter>
            <AboutRight>  
                <RightExample> 
                    <ImageWrapper>
                        <img 
                            src={process.env.PUBLIC_URL + props.imagePath} 
                            alt='itm' 
                            />
                    </ImageWrapper> 
                </RightExample> 
            </AboutRight>
        </AboutItemContainer>
    )
}


const AboutItemContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
    justify-content: center;
    align-items: center;
    padding: 50px; 
    color: white; 
    @media (max-width: 768px) {
        flex-direction: column;
        padding: 10px;
        margin-bottom: 100px;
    }
`

const AboutIconWrap = styled.div`
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #80ac33;
    box-shadow: 1px 1px 5px 5px #607e26;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid grey;
    @media (max-width: 768px) {
        width: 100px;
        height: 100px;
    }
`
const AboutLeft = styled.div`
    display: flex;
    width: 250px;
    max-width: 33%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; 
    /* border: 1px solid red; */
    @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
        flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
    }
`

const AboutItemName = styled.div`
    font-size: 35px;  
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    @media (max-width: 768px) {
        font-size: 25px;
        margin-left: ${props => props.reverse ? '0px' : '20px'};
        margin-right: ${props => props.reverse ? '20px' : '0px'};
    }

`

const AboutCenter = styled.div`
    width: 33%; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    padding: 20px; 
    h3{
        font-size: 30px;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
        width: 100%;
    }
    p{
        font-size: 20px;
        display: flex;
        width: 100%;
        font-family: 'Roboto', sans-serif;
        margin-top: 20px;
        font-weight: 300;
        line-height: 1.5;
    }
    @media (max-width: 768px) {
        width: 100%;
        h3{
            font-size: 20px;
        }
        p{
            font-size: 15px;
        }
    }
`

const AboutRight = styled.div`
    display: flex;
    width: 33%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        width: 100%;
    }
`
const RightExample = styled.div`
    width: 80%;
    height: 100%;

`
const ImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 20px;
    background-color: white;
    display: flex;
    border: 1px solid grey;
    justify-content: center;
    align-items: flex-start; 
    img {
        width: 100%;
        height: 100%; 
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`