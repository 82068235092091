import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import profileService from './profileService'
import imageService from '../settings/imageService'

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

const initialState = {
  profile: [],
  myProfile: [],
  profileImage: {},
  profileImagePreview: null,
  chosenImage: {},
  requestSent: [],
  requestsReceived: [],

  isImageUpdateSuccess: false,
  isImageUpdateLoading: false,
  isImageUpdateError: false,

  croppedImage: null,

  isError: false,
  isSuccess: false,
  isLoading: false,
  isMyProfileLoading: false,
  isMyProfileError: false,
  isMyProfileSuccess: false,

  message: '',
  hasUpdated: false,


  isUpdateLoading: false,
  isUpdateSuccess: false,
  isUpdateError: false
}

// get user data
export const getProfileData = createAsyncThunk(
  'profile/getProfileData',
  async (profileData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await profileService.getProfileData(profileData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get profile data to dashboard
export const getMyData = createAsyncThunk(
  'profile/getMyData',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await profileService.getMyData(token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get profile data to dashboard
export const getMyProfile = createAsyncThunk(
  'profile/getMyProfile',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await profileService.getMyProfile(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const updateProfile = createAsyncThunk(
  'profile/updateProfile',
  async (updateData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await profileService.updateProfile(updateData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updatePassword = createAsyncThunk(
  'profile/updatePassword',
  async (updateData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await profileService.updatePassword(updateData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const addImage = createAsyncThunk(
  'profile/addImage',
  async (formData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await profileService.addImage(formData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Create new post
export const updateImage = createAsyncThunk(
  'profile/updateImage',
  async (imageData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await imageService.updateImage(imageData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// get user data
export const sendJoinRequest = createAsyncThunk(
  'profile/sendJoinRequest',
  async (profileData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await profileService.sendJoinRequest(profileData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setImagePreview: (state, action) => { state.profileImagePreview = action.payload},
    chooseImage: (state, action) => {state.chosenImage = action.payload},
    removeImage: (state) => {state.profileImagePreview = initialState.profileImagePreview},
    resetProfileHard: (state) => initialState,
    resetProfile: (state) => initialState,

    setCroppedImage: (state, action) => {
      state.croppedImage = action.payload 
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileData.pending, (state) => {
        state.isLoading = true
        state.isSuccess = false
      })
      .addCase(getProfileData.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.profile = action.payload
        // state.myProfile = action.payload
        state.profileImage = action.payload.profileImg.imagePath2
      })
      .addCase(getProfileData.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.isSuccess = false
        state.message = action.payload
      })
      //-------------------------------------------
      .addCase(getMyData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMyData.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.profile = action.payload
        state.profileImage = action.payload.profileImg.imagePath2
      })
      .addCase(getMyData.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      //-------------------------------------------
      .addCase(updateProfile.pending, (state) => {
        state.isUpdateLoading = true
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isUpdateLoading = false
        state.isUpdateSuccess = true
        state.isUpdateError = false
        state.profile = action.payload
        state.hasUpdated = true
        
        state.data = action.payload //this is a temporary variable which
        //only gets used during the updating

      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isUpdateLoading = false
        state.isUpdateError = true
        state.message = action.payload
        state.hasUpdated = false
      })
      //-------------------------------------------
      .addCase(updatePassword.pending, (state) => {
        state.isUpdateLoading = true
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.isUpdateLoading = false
        state.isUpdateSuccess = true
        state.isUpdateError = false
        state.profile = action.payload
        state.hasUpdated = true
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.isUpdateLoading = false
        state.isUpdateError = true
        state.message = action.payload
        state.hasUpdated = false
      })
      //-------------------------------------------
      .addCase(addImage.pending, (state) => {
        state.isUpdateLoading = true
      })
      .addCase(addImage.fulfilled, (state, action) => {
        state.isUpdateLoading = false
        state.isUpdateSuccess = true
        state.isUpdateError = false
        // state.profile = action.payload
        state.profileImage = action.payload
        state.hasUpdated = true
      })
      .addCase(addImage.rejected, (state, action) => {
        state.isUpdateLoading = false
        state.isUpdateError = true
        state.message = action.payload
        state.hasUpdated = false
      })
      //-------------------------------------------
      .addCase(updateImage.pending, (state) => {
        state.isImageUpdateSuccess = false
        state.isImageUpdateLoading = true
        state.isImageUpdateError = false
      })
      .addCase(updateImage.fulfilled, (state, action) => {
        state.isImageUpdateSuccess = true
        state.isImageUpdateLoading = false
        state.isImageUpdateError = false
        state.hasUpdated = true
        state.profileImage = action.payload
      })
      .addCase(updateImage.rejected, (state, action) => {
        state.isImageUpdateSuccess = false
        state.isImageUpdateLoading = false
        state.isImageUpdateError = true
        state.message = action.payload
      })
      //-------------------------------------------
      .addCase(sendJoinRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(sendJoinRequest.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        // state.requestSent = action.payload
      })
      .addCase(sendJoinRequest.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
    //-------------------------------------------
          //-------------------------------------------
          .addCase(getMyProfile.pending, (state) => {
            state.isMyProfileLoading = true
          })
          .addCase(getMyProfile.fulfilled, (state, action) => {
            state.isMyProfileLoading = false
            state.isMyProfileSuccess = true
            state.myProfile = action.payload
            state.profile = action.payload
          })
          .addCase(getMyProfile.rejected, (state, action) => {
            state.isMyProfileLoading = false
            state.isMyProfileError = true
            state.message = action.payload
          })
        //-------------------------------------------
  },
})

export const { resetProfileHard, softReset, setImagePreview, chooseImage, removeImage, resetProfile, setCroppedImage } = profileSlice.actions
export default profileSlice.reducer
