import { useEffect } from 'react'
import '../css/userProfile.css'
import { toast } from 'react-toastify'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getProfileData } from '../features/profile/profileSlice'
import { postReset } from '../features/posts/postSlice'
import { resetProfile } from '../features/profile/profileSlice' 
import PostFeed from '../components/MyPostFeed'
import UserProfileTop from '../components/userProfile/UserProfileTop'
import NotAMember from '../components/userProfile/NotAMember'
import ReportPostModal2 from '../components/modals/ReportPostModal2'
import ChurchExtras from '../components/userProfile/ChurchExtras'
import { resetUserForLogout, logoutUserFaraSesiune } from 'features/auth/authSlice'
import Loader from 'loaders/Loader'

function UserProfile() {
  let { profileName } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.auth)
  const { profile, isError, message, isLoading } = useSelector((state) => state.profile)
  const { viewModal } = useSelector((state) => state.modal) 
  const { posts, isPostError } = useSelector((state) => state.posts)
 
  useEffect(() => {

      if(profile.username !== profileName){
        dispatch(resetProfile())
        if(posts.length > 0){
          dispatch(postReset())
        }
      }
    }, [dispatch, profile, profileName, posts.message, posts.length, isPostError, navigate])

    //dispatch, profile, profileName, posts.length

    useEffect(() => {

      if (isError) { 
        toast.error(message) 
        if(message === "Not logged in"){
          dispatch(logoutUserFaraSesiune())
          dispatch(resetUserForLogout())
          navigate('/login') 
          window.location.reload()
        }else{
          toast.error(message)
        }
      }
      if (!user) {  navigate('/login') }else{
        if(profile.length === 0){ //if there's no profile
          dispatch(getProfileData({
            //userIdX: user._id, //<== this is being sent as undefined
            profileName: profileName
          }))
        }        
      }

      
    }, [user, isError, message, dispatch, navigate, profileName, profile])

    useEffect( () => () => {
      dispatch(postReset())
    }, [] ); // i could probably put this in postfeed
    

  return (
    <>    
          
        {isLoading? (<Loader/>) : (
            <>
                { profile && profile.isOrganization &&
                    (
                        profile.isViewable ? 
                            (
                                <div className='pageContainer'>
                                        <div className='churchProfileLeft'>
                                            <ChurchExtras/>
                                        </div>  
                                        <div className='userProfileMain'>
                                            <UserProfileTop />
                                            <PostFeed name={profileName} />
                                        </div>
                                </div>
                            )
                            :  
                            (
                                <NotAMember />
                            )
                        
                    )

                }
            </>
          )}

        {
            viewModal === true && (
              <ReportPostModal2 />
            )
        }
    </>
  )
}

export default UserProfile