import { Link } from 'react-router-dom';
import TimeAgo from 'timeago-react'; 
import { useDispatch, useSelector } from 'react-redux'
import { acceptRequest, deleteRequest } from '../../../features/requests/requestSlice'
import { imgUrl } from 'features/constants';
import styled from 'styled-components';
import { RequestsItemInfo, ReqReceivedItemContainer, RequstItemName, ReqItemImage, ReqButton } from './reqests.styled';


function ReqSentItem({reqId, churchId, fullName, username, image, about, createdAt}) {
    const dispatch = useDispatch()
    let date = new Date(createdAt)


    const handleCancel = () => {
        dispatch(deleteRequest({
            type: "cancel",
            reqId: reqId,
            churchId: churchId,
        }))
    }

    let imageToShow;
    try{ imageToShow =   `${imgUrl}/profile/${image}` }
    catch(e){  imageToShow =  require(`../../../profileImage/generic.png`)}


    return (
        <ReqReceivedItemContainer>
            <RequestsItemInfo>
                <ReqItemImage  src={imageToShow} alt='Request' />

                <RequstItemName> 
                    <div className='reqTooltip'>
                        <Link to={`/${username}`}><span>{fullName}</span></Link>
                        <span className="reqTooltiptext">View Profile</span>
                    </div> 
                </RequstItemName>
            </RequestsItemInfo>

 
            <ReqButton onClick={handleCancel}>
                Cancel
            </ReqButton> 

        </ReqReceivedItemContainer>
    )
}

export default ReqSentItem