import '../css/spinner.css'

function Spinner() {

  

  return (
    <div className="loader"></div>
  )
}

export default Spinner
