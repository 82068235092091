import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { setLastNotif } from 'features/notifs/notifSlice'
import { imgUrl } from 'features/constants'
import PostAddIcon from '@mui/icons-material/PostAdd'; // for Posts
import MapsUgcIcon from '@mui/icons-material/MapsUgc'; // for new messages
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'; // for a new user request to a church
import NotificationAddIcon from '@mui/icons-material/NotificationAdd'; // defualt icon
import TimeAgo from 'timeago-react'; 
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import { MdOutlineEventNote } from "react-icons/md";

export default function NotifItem(props) {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const [notifIcon, setNotifIcon] = useState()
  const navigate = useNavigate()
  const { lastNotifViewed } = useSelector((state) => state.notifs)
  const { memberOf } = useSelector((state) => state.memberships2)


  useEffect( () => {
    if(props.index === 0){

      // Only dispatch if the most recent notif (notifs[0]) is not the last one viewed
      if( props.data._id.toString() !== lastNotifViewed ){
        dispatch(setLastNotif({
          lastNotifViewed: props.data._id.toString()
        }))
      }
    } 
  }, [] ); 

  let userImage, imgSrc, name;

  try {
    if( user.isOrganization ){
      // if the current user is a church, the incoming requests a created by user members, so thats what we want
      userImage = props.data.creatorUserId.profileImg.imagePath2
      name = props.data.creatorUserId.fullName
      imgSrc = `${imgUrl}/profile/${userImage}`
    
    }else{
      // if the current user is a member, the incoming requests a created by church, so thats what we want
      userImage = props.data.creatorChurchId.profileImg.imagePath2
      name = props.data.creatorChurchId.fullName
      imgSrc = `${imgUrl}/profile/${userImage}`
    }
  } catch (error) {
    imgSrc = null
  }


  useEffect(() => {

    switch (props.data.contentType) {
      case 'Post': {
        setNotifIcon(< PostAddIcon />); break;
      }
      case 'MessageMM': {
        setNotifIcon(< MapsUgcIcon />); break;
      }
      case 'MessageMC': {
        setNotifIcon(< MapsUgcIcon />); break;
      }
      case 'Request': {
        setNotifIcon(< PersonAddAltIcon />); break;
      }
      case 'Announcement': {
        setNotifIcon(< NotificationAddIcon />); break;
      }
      case 'Event': {
        setNotifIcon(< MdOutlineEventNote />); break;
      }
      case 'Event Discussion Comment': {
        setNotifIcon(< MdOutlineEventNote />); break;
      }
      default: {
        // helps us avoid typos!
        setNotifIcon(< NotificationAddIcon />); break;
      }
    }

  }, [])

  let newDate = new Date(props.data.createdAt)

  const handleClick = () => {

    if(props.data.contentType === 'Announcement'){ 
      if(memberOf && memberOf.length > 0){
        return navigate(`/${memberOf[0].username}?section=anouncements`)
      }
    }else if(props.data.contentType === 'Event'){ 
      return navigate(`/events/${props.data.contentId}`)
    }else if(props.data.contentType === 'Event Discussion Comment'){ 
      return navigate(`/events/${props.data.contentId}`)
    }else if(props.data.contentType === 'Post Comment'){ 
      return navigate(`/posts/${props.data.contentId}`)
    } 

    if(user.isOrganization){
      if( props.data.contentType === 'Post' ){
        return navigate(`/posts/${props.data.contentId}`)
      }else if( props.data.contentType === 'Request' ){
        return navigate(`/settings?section=requestsReceived`)
      }
    }  
  }

  return (
    <NotifItemWrap onClick={handleClick}>
      <NotifIconWrap>
        {notifIcon}
      </NotifIconWrap>

      <CreatorWrap>
        <CreatorInfoWrap> 

          <NotifItemHeader>  
            <span> 
              <span style={{marginRight: '10px'}}>New {props.data.contentType.toLowerCase() + '  '}</span> 
              <TimeAgo className='time' datetime={newDate}/> 
            </span>
            <span>
              {
                props.data.isAnonymous ?
                (
                  <i>Anonymous</i>
                )
                :
                (
                  <span>{name}</span>
                )
              } 
            
            </span> 
          </NotifItemHeader>
        </CreatorInfoWrap>


        <ContentWrap>
          <i>{  props.data.content && (props.data.content.slice(0, 50)) }...</i>
        </ContentWrap>

      </CreatorWrap> 
    </NotifItemWrap>
  )
}

const NotifItemWrap = styled.div` 
  width: 100%;
  height: fit-content;
  padding: 30px;
  margin-bottom: 0px;
  border-bottom: 1px solid #b3b3b39d; 
  display: flex;
  flex-direction: row;
  align-items: center; 
  color: var(--lightThemeFontColor);
  cursor: pointer;
  &:hover{
    transition: all 0.5s ease-in-out;
    background-color: white;
  }

`

const NotifIconWrap = styled.div`
  color: var(--lightThemeFontColor);
  /* margin-right: 20px;
  margin-left: 10px; */
  width: 20%;

`

const CreatorWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  height: fit-content;
`
const CreatorInfoWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 60%;
 `

const NotifItemHeader = styled.div`
  margin-left: 5px;
  font-size: 11px;
  color: var(--lightThemeFontColor);
  display: flex;
  flex-direction: column;
  text-align: left;
  .time{
    align-self: flex-start;
    justify-self: flex-start;
    font-size: 11px;
  }
`

const ImageWrap = styled.div`
  width: 30px;
  height: 30px;
  img{
    width: 100%;
    border-radius: 50%;
  }
`


const ContentWrap = styled.div`
  /* background-color: #eeeeee; */
  border-left: 1px solid #cccccc;
  /* border-right: 1px solid #cccccc; */
  padding-left: 10px;
  margin-top: 10px;;
  width: 40%;
  height: 80%;
  font-size: small;
  text-align: left;
  color: var(--lightThemeFontColor);
`