export const pollLanguage = {

    noPolls: {
        en: 'No Polls Yet', 
        es: 'Aún no hay encuestas'
    },

    polls: {
        en: 'Polls',
        es: 'Encuestas'
    },
    createPoll: {
        en: 'Create Poll',
        es: 'Crear Encuesta'
    },
    toastErrorAtLeastTwoChoices: {
        en: 'At least two choices needed to create a poll',
        es: 'Esta encuesta necesita por lo menos dos opciones'
    },
    toastSuccess: {
        en: 'Poll created',
        es: 'Encuesta creada'
    },
    toastErrorTitle: {
        en: 'Title is required',
        es: 'Se requiere un título'
    },
    toastErrorAddSomething: {
        en: 'Add something meaningful',
        es: 'Agrega algo aqui'
    },
    add: {  
        en: 'Add',
        es: 'Añadir'
    },
    createButton: {
        en: 'Create',
        es: 'Crear'
    },
    pollTitle: {
        en: 'Poll Title',
        es: 'Título de la Encuesta'
    },
    addResponseOption: {
        en: 'Response Option',
        es: 'Opción de Respuesta'
    },
    delete: {
        en: 'Delete',
        es: 'Eliminar'
    },
    totalVotes: {
        en: 'Total Votes',
        es: 'Votos Totales'
    },
    
}