import React, { useEffect } from 'react'
import styled from 'styled-components'
import CreateListingButton from './components/CreateListingButton'
import { useDispatch, useSelector } from 'react-redux'
import { getListings, setViewAddListingModal, setViewMarketLocationModal } from 'features/marketplace/marketSlice'
import GlobalBtnWrapper from 'components/buttons/GlobalBtnWrapper' 
import ModalContainer from 'components/modal/ModalContainer'
import AddListingModal from './components/AddListingModal'
import ListingFeedContainer from './listingFeed/ListingFeedContainer'
import ListingSearchContainer from './listingFeed/ListingSearchContainer'
import LocationFilter from './listingFeed/LocationFilter'
import ListingTypeDropdown from './listingFeed/ListingTypeDropdown'
import SetLocationModal from './listingFeed/SetLocationModal'


export default function MarketContainerMain() {

    const dispatch = useDispatch()

    const { viewAddListingModal, viewMarketLocationModal } = useSelector(state => state.market)


    return (
        <MarketContainer> 

            <MarketFeedTop>
                {/* <SepRow >
                    <SepCol>
                        <ListingSearchContainer /> 

                        <LocationFilter />
                    </SepCol>

                    <ListingTypeDropdown />
                </SepRow>

                
                <CreateListingButton />          */}
                    
                <ListingTypeDropdown />

            </MarketFeedTop>


 


            {
                viewAddListingModal && (
                    <AddListingModal />
                )
            }
            {
                viewMarketLocationModal && (
                    <SetLocationModal />
                )
            }

            <ListingFeedContainer />


        </MarketContainer>
    )
}

const SepRow = styled.div` 
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start; 
`

const SepCol = styled.div`  
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 10px;
`

const MarketContainer = styled.div`
    padding: 10px;
    max-width: 1100px;
    height: 100%;
    border-right: 1px solid #cccccc;
    width: calc(100% - 300px);
    min-width: 850px; 
`

const MarketFeedTop = styled.div`
    width: 100%;
    height: fit-content; 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0px;
`