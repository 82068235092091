import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const API_URL = '/api/conversations/createConversation'
const API_URL2 = '/api/conversations/getConversations'
const API_URL3 = '/api/conversations/deleteConversation'
const API_URL4 = '/api/conversations/searchUsers'
const API_URL5 = '/api/conversations/getConversationsWithOtherMembers'

//for sending the token
const config = (token) => { return { 
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}`, }, 
}}

const createConversation = async (data, token) => {
    const response = await axios.post(URL+API_URL, data, config(token))
    return response.data
}

const getConversations = async (token) => {
    const response = await axios.get(URL+API_URL2, config(token))
    return response.data
}

const deleteConversation = async (conversationID, token) => {
    const response = await axios.post(URL+'/api/conversations/deleteConversation', conversationID, config(token))
    return response.data
}//if I delete a converation, it sould only be deleted from my side, the other user should still see 

const searchUsers = async (data, token) => {
    const response = await axios.post(URL+API_URL4, data, config(token))
    return response.data
}

const getConversationsWithOtherMembers = async (data, token) => {
    const response = await axios.post(URL+API_URL5, data, config(token))
    return response.data
}

const getChurchMembers = async (data, token) => {
    const response = await axios.post(URL+'/api/conversations/getChurchMembers', data, config(token))
    return response.data
}

const searchMembers = async (data, token) => {
    const response = await axios.post(URL+'/api/conversations/searchMembers', data, config(token))
    return response.data
}

const cleanupConversations = async (data, token) => {
    const response = await axios.post(URL+'/api/conversations/cleanupConversations', data, config(token))
    return response.data
}

const conversationService = { 
    createConversation, getConversations, deleteConversation, searchUsers, 
    getConversationsWithOtherMembers, getChurchMembers, searchMembers, cleanupConversations
}
export default conversationService
