import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import emailService from './emailService'

const errorMessage = (error) => {
    return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
  }

const initialState = {
    emailList: [],

    isInviteEmailLoading: false,
    isInviteEmailSuccess: false,
    isInviteEmailError: false,
    inviteEmailErrorMessage: '',

    addUserWithEmailLoading: false,
    addUserWithEmailSuccess: false,
    addUserWithEmailError: false,
    addUserWithEmailErrorMessage: '',

} 

export const sendInviteEmail = createAsyncThunk(
    'email/sendInviteEmail',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await emailService.sendInviteEmail(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const addUserWithEmail = createAsyncThunk(
    'email/addUserWithEmail',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await emailService.addUserWithEmail(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const addUsersWithEmailInBulk = createAsyncThunk(
    'email/addUsersWithEmailInBulk',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await emailService.addUsersWithEmailInBulk(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const sendGivingTransactionEmail = createAsyncThunk(
    'email/sendGivingTransactionEmail',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await emailService.sendGivingTransactionEmail(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    } 
  )

  export  const emailSlice = createSlice({
    name: 'email',
    initialState,
    reducers: {
        emailReset: (state) => initialState,

        resetAddEmailState: (state) => {
            state.addUserWithEmailLoading = false
            state.addUserWithEmailSuccess = false
            state.addUserWithEmailError = false
            state.addUserWithEmailErrorMessage = ''
        }
    },
    extraReducers: (builder) => {
      builder
        .addCase(sendInviteEmail.pending, (state) => {
            state.isInviteEmailLoading = true
        })
        .addCase(sendInviteEmail.fulfilled, (state, action) => {
            state.isInviteEmailLoading = false 
            state.isInviteEmailSuccess = true
        })
        .addCase(sendInviteEmail.rejected, (state, action) => {
            state.isInviteEmailLoading = false
            state.isInviteEmailError = true
            state.inviteEmailErrorMessage = action.payload
        })
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(addUserWithEmail.pending, (state) => {
            state.addUserWithEmailLoading = true
        })
        .addCase(addUserWithEmail.fulfilled, (state, action) => {
            state.addUserWithEmailLoading = false 
            state.addUserWithEmailSuccess = true
        })
        .addCase(addUserWithEmail.rejected, (state, action) => {
            state.addUserWithEmailLoading = false
            state.addUserWithEmailError = true
            state.addUserWithEmailErrorMessage = action.payload
        })
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(addUsersWithEmailInBulk.pending, (state) => {
            state.addUserWithEmailLoading = true
        })
        .addCase(addUsersWithEmailInBulk.fulfilled, (state, action) => {
            state.addUserWithEmailLoading = false 
            state.addUserWithEmailSuccess = true
        })
        .addCase(addUsersWithEmailInBulk.rejected, (state, action) => {
            state.addUserWithEmailLoading = false
            state.addUserWithEmailError = true
            state.addUserWithEmailErrorMessage = action.payload
        })
      },
})

export const { 
    emailReset,
    resetAddEmailState
} = emailSlice.actions
export default emailSlice.reducer

