import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { setViewFileModal, setNewFileType } from 'features/files/fileSlice'
import AddFileModal from './AddFileModal'
import { FIH, FileModal } from '../files.styled'
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import { MdComputer } from "react-icons/md";
import { IoIosLink } from "react-icons/io";
import AddLinkModal from './AddLinkModal'
import { CgRename } from "react-icons/cg";


export default function NewFileModal() {

    const dispatch = useDispatch()
    const { newFileType } = useSelector((state) => state.file)

    const handleCloseModal = () => {
        dispatch(setNewFileType(null))

        dispatch(setViewFileModal({
            modal: 'viewAddFileModal',
            view: false
        }))
    }

    if(!newFileType){
        return (
            <FileModal>
                <ModalCloseButton
                    handler={handleCloseModal}
                    top={'20px'}
                    right={'20px'}
                    size='30px'
                    />
 
                <FIH>Where is your file?</FIH>

                <CCC>
                    <FItem onClick={() => dispatch(setNewFileType('file'))}>
                        <MdComputer />
                        <span>My Device</span>
                    </FItem>
                    <FItem onClick={() => dispatch(setNewFileType('url'))}>
                        <IoIosLink />
                        <span>On the Web</span>
                        <p>Example: A google drive or docs link</p>
                    </FItem>
                </CCC>

            </FileModal>
        )
    }else if(newFileType === 'url'){
        return (
            <AddFileModal 
                selectedFileType={'url'}
                />
        )
    } else if(newFileType === 'file'){
        return (
            <AddFileModal 
                selectedFileType={'file'}
                />
        )
    }
}

const FItem = styled.div`
    width: 50%;
    height: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    transition: 0.3s;
    cursor: pointer;
    color: var(--lightThemeFontColor);
    &:hover{
        background: #f5f5f5;
        border-radius: 5px;

        p{
            display: flex;
        }

    }
    svg{
        font-size: 30px;
        margin-bottom: 10px;
        color: var(--lightThemeFontColor);
    }

    p{
        display: none;
        font-style: italic;
        position: absolute;
        bottom: 0;
        font-size: small;
        color: var(--lightThemeFontColor);
    }

`

const CCC = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    transition: 0.3s;
    cursor: pointer;
    color: var(--lightThemeFontColor);

`