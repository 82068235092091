import React from 'react'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { convertTime, formatPrice } from 'components/settings/billing/stripeUtilityFuncs'
import styled from 'styled-components';
import { GrStatusGood } from "react-icons/gr";
import { CiRoute } from "react-icons/ci";
import { VscError } from "react-icons/vsc";
import Tooltip from '@mui/material/Tooltip';

const statData = {
  succeeded: 'success',
  pending: 'pending',
  failed: 'failed'
}


export default function TransactionItem(props) {

  let date = convertTime(props.data.created)
  let price = formatPrice(props.data.amount)

  return (
    <tr> 
      <td>{`${date.month}  ${date.day}  ${date.year}`}</td>
      <td>{price}</td>
      {/* <td>***</td> source column removed */}
      <td>{props.data.description} { props.data.amount_refunded > 0 && (<Rfnd>Refunded</Rfnd>)} </td>
      <Tooltip title={props.data.receipt_url ? 'View Receipt' : 'No Receipt'} placement="top">
        <RecTd>
        {
          props.data.status === 'pending' ? 
          (
            <ReceiptLongIcon style={{color: 'grey'}} />
          )
          : props.data.status === 'failed' ?
          (
            <VscError style={{color: 'red'}} />
          )
          :
          (
            <a style={{color: 'var(--themeColor)'}} href={`${props.data.receipt_url}`} target="_blank" rel="noreferrer">
              <ReceiptLongIcon />
            </a>
          )
        }
  
        </RecTd>
      </Tooltip>
      <Tooltip title={props.data.status} placement="top">
      <td>
        { 
          props.data.status === 'succeeded' ?
          (
            <GrStatusGood style={{color: 'green'}} />
          )
          : props.data.status === 'pending' ? 
          (
            <CiRoute style={{color: 'grey'}} />
          ) 
          : props.data.status === 'failed' &&
          (
            <VscError style={{color: 'red'}} />
          ) 
        }
      </td>
      </Tooltip>
    </tr>
  )
}

const RecTd = styled.td`
  .recT{
    position: absolute;
    padding: 5px;
    display: none;
    width: fit-content;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #00000063;
    color: white;
    margin-bottom: 5px;
    margin-left: 5px;
    border-radius: 5px 5px 5px 0px;
    font-size: x-small;
    white-space: nowrap;
  }

  &:hover{
    .recT{
      display: unset;
    }
  }

  @media (max-width: 768px){
    svg{
      font-size: 15px;
    }
  }

`

const Rfnd = styled.div`
  display: inline;
  color: white;
  font-size: 10px;
  padding: 3px;
  border-radius: 2px;
  background-color: #8d0202b0;
`