import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'; 
import { useDispatch } from 'react-redux';
import { setViewSubscribeModal } from 'features/billing/billingSlice';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function SubSignuUpItem({price, interval}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()  
    let subItemImage;
    try{ subItemImage =  `https://www.praysely.com/static/media/prayselyLogo.dd8331fe6d2a1e929be6.png`}
    catch(e){ subItemImage = null}

    const { hasPaymentMethod } = useSelector((state) => state.billing)

    const handleSubscribe = () => {
        if(!hasPaymentMethod){
            navigate(`/settings?section=billing&view=wallet`)
            toast.success('Please set a default payment method')
            return;
        }else{
            dispatch(setViewSubscribeModal({
                view: true,
                price: price,
                interval: interval,
            }))
        }
    }

  return (
    <SubSignuUpItemContainer> 
        <SubItemContainer>
            <div style={{width: '100%', textAlign: 'right', fontSize: '13px', color: 'grey'}}><i>Available Plan</i></div> 
            <WRAP>
                <SubWrap>
                    { subItemImage && ( <img src={subItemImage} width='80px' height='auto' alt="Logo" /> ) } 
                    <DIVWrap>
                        <DIV1>
                            <SubType>Praysely {interval === 'monthly' ? ('Monthly'):('Yearly')} subscription</SubType>
                            <StatusI>Inactive</StatusI>
                        </DIV1> 
                        <DIV2>
                            <NextPeriod>Will renew every calendar {interval === 'monthly' ? ('month'):('year')}</NextPeriod>
                            <Price> for  {price} </Price>
                        </DIV2>
                    </DIVWrap>
                </SubWrap> 
                {/* in the dots here, we can add a "More Details" section */} 
                {/* <MoreDots onClick={handleDropDown} ref={ref1}> <IconDiv><MoreHorizIcon style={fontIconStyle} /></IconDiv> </MoreDots>
                {isDropped && (  <ul className="dropDownList"> <li className="dropDownListItem" >   <ClearIcon className='postItemDotsIcon'/> Cancel Subscription </li> </ul> )}  */}
            </WRAP> 
        </SubItemContainer> 

        <SubscribeBtnWrap> <span onClick={handleSubscribe}>Subscribe</span> </SubscribeBtnWrap>

    </SubSignuUpItemContainer>
  )
}

const SubSignuUpItemContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
`

const SubscribeBtnWrap = styled.div`
    margin-top: 10px;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    span{
        padding: 15px 38px; 
        border: 2px solid var(--themeColor);
        color: var(--themeColor);
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.3s;
        font-weight: bold;
        &:hover{
            background-color: var(--themeColor);
            color:white;
            font-weight: bold;
            padding: 15px 36px; 
        }
    }

`

const WRAP = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const SubItemContainer = styled.div`
    position: relative;
    margin-top: 20px;
    width: 80%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    padding: 30px 20px 40px 10px ;
    box-shadow: 0 0 10px #ccc;

    img{
        margin-right: 10px;
        margin-left: 10px;
        padding: 8px;
        border: 2px solid var(--themeColor);
        border-radius: 5px;
    }
`

const SubWrap = styled.div`
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const DIVWrap = styled.div`
    display: flex;
    flex-direction: column;

`

const DIV1 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
`

const DIV2 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: grey;
`

const SubType = styled.div`
    font-weight: bold;
    margin-right: 10px;
`
const Price = styled.div`
    margin-left: 5px;
` 

const StatusI =  styled.div`
    padding: 3px 5px;
    border-radius: 5px;
    width: fit-content;
    background-color: none;
    color: #2b2b2b;
    border: 1px solid grey;

`
const NextPeriod = styled.div`
    

`