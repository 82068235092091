import React, {useRef, useState} from 'react' 
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components' 
import MoreVertIcon from '@mui/icons-material/MoreVert'; 
import useToggleDropdown from 'hooks/useToggleDropdown';
import { toast } from 'react-toastify'



export default function PrayerWallItem({item}) {

    const navigate = useNavigate()
    const ref1 = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref1)
    const [viewFullText, setViewFullText] = useState(false)

    const handleViewText = ( ) => { 
        setViewFullText(!viewFullText)
    }

    const handleDotsClick = (e) => {
        e.stopPropagation()
        setIsDropped.toggle(ref1)
    }

    const handleAddPray = (e) => {
        e.stopPropagation()
        setIsDropped.toggle(ref1)
        toast.success(`You are praying for ${item.user.fullName.split(' ')[0]}'s prayer request`)
    }

    const handleViewPost = ( ) => {
        navigate(`/posts/${item._id}`)
    }

    return (

        <PrayerWallItemContainer 
            onClick={() => setViewFullText(!viewFullText)}
            >  
            <PWIMain>
                <PrayerWallItemName> {item.user.fullName} </PrayerWallItemName>

                <PrayerWallItemText> 
                    { 
                        viewFullText ? 
                        (
                            item.postText.text
                        )
                        : item.postText.textCondensed ? 
                        (
                            item.postText.textCondensed 
                        )
                        :
                        (
                            item.postText.text.slice(0, 75) + '...'
                        )
                    }                 
                </PrayerWallItemText>                 
            </PWIMain>

            <PWIIcon
                ref={ref1}
                onClick={handleDotsClick}
                >
                <MoreVertIcon />
            </PWIIcon>

            {
                isDropped && (
                    <DropContainer >
                        <div onClick={handleViewText}>{viewFullText ? 'Hide':'View'} Full Text</div>
                        <div onClick={handleAddPray}>Let {item.user.fullName.split(' ')[0]} know you are praying </div>
                        <div onClick={handleViewPost}>View Full Post</div>
                    </DropContainer>
                )
            }
            

        </PrayerWallItemContainer> 
    )
}

const PWIIcon = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    visibility: hidden;
    text-align: center;
    svg{
        font-size: 16px;
    }
    padding: 5px;
    border-radius: 9px;
    &:hover{
        cursor: pointer;
        background-color: #a3a3a3;
        color: white;
    }
`
const PrayerWallItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: fit-content;
    margin-bottom: 10px;
    color: var(--lightThemeFontColor);
    padding: 5px;
    border-radius: 5px;
    transition: all 0.5s;
    &:hover{
        cursor: pointer;
        background-color: #ccc;
        color: white;
        /* on hover display PWIIcon */
        ${PWIIcon}{
            visibility: visible;
        }
    }
`

const PWIMain = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`



const PrayerWallItemText = styled.div`
    font-size: 12px; 
    text-align: left;
`

const PrayerWallItemName = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 10px;   
`

const DropContainer = styled.div`
    width: 120px;
    height: fit-content;
    padding: 5px;
    background-color: white;
    position: absolute;
    border-radius: 8px 0px 8px 8px;
    top: 22px;
    right: 22px;
    z-index: 99;
    box-shadow: 0px 0px 5px #ccc;
    font-size: 10px;
    color: var(--lightThemeFontColor);
    text-align: left;
    div{
        border-radius: 5px;
        padding: 5px;
        transition: all 0.2s ease-in-out;
        &:hover{
            cursor: pointer;
            background-color: #ccc;
            color: white;
        }
    }
`