import styled from "styled-components";


export const FormWrap = styled.div `
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #37bdc2;
    @media (max-width: 768px) {
        width: 95%;
    }
`
export const FormElement = styled.div `
    display: flex;
    width: 80%;
    flex-direction: column;
    background-color: #5ae6eb;
    border-radius: 5px;
    padding: 0px 15px 15px 10px;
    margin-bottom: 15px;
    border:  ${props => props.isError ? ('1px solid red'):('none') };
    border-right:  ${props => props.isError ? ('4px solid red'):('none') };
    & input{
        border: none;
        background: transparent;
        outline: none;
        font-size: 17px;
        padding: 5px 0px ;
    }
    & select{
      border: none;
      background: transparent;
    }
    & select::after{
      border: none;
    }
    @media (max-width: 768px) {
    width: 95%;
  }
`

export const FormHeading = styled.div `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 12px;
    color: rgb(70,70,70);
    padding: 5px 0px ;
    svg{
        cursor: pointer;
        color: var(--lightThemeFontColor);
        font-size: 17px;
    }
`
export const Available = styled.div`
    color: green;
    padding-left: 5px;
    font-weight: bolder;

`
export const NotAvailable = styled.div`
    color: red;
    padding-left: 5px;
    font-weight: bolder;
`
export const InputWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & input{
        border: none;
        background: transparent;
        outline: none;
        font-size: 17px;
        padding: 5px 0px ;
        min-width: 80%;
    }
`

export const SubmitButton = styled.div`
    margin-top: 15px;
    padding: 20px 40px;
    color: white;
    background-color: grey;
    border-radius: 5px;
    cursor: pointer;
    &:hover{
      background-color: green;
    }
`

export const SubmitButtonInsideInputWrap = styled.div`
    padding: 8px 9px;
    font-size: 11px;
    color: white;
    background-color: grey;
    cursor: pointer;
    /*  do not break word */
    white-space: nowrap;
    width: fit-content;
    border-radius: 5px;
    &:hover{
        background-color: green;
        }
`

export const NonSubmittableButton = styled.div`
    margin-top: 15px;
    padding: 20px 40px;
    color: white;
    background-color: #949494;
    pointer-events: none;

`

export const VerifyLocationButton = styled.div` 
    padding: 5px 9px;
    font-size: 11px;
    color: white;
    background-color: grey;
    cursor: pointer;
    /*  do not break word */
    white-space: nowrap;
    width: fit-content;
    border-radius: 5px;
    &:hover{
      background-color: green;
    }
`

export const FormEleWrap1 = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%; 
    & input{
        border: none;
        background: transparent;
        outline: none;
        width: 100%;
    }
`
export const ZipDataWrap = styled.div`
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 0 0 2px rgba(0,0,0,0.2);
  width: fit-content;
  `

export const RegisterButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-top: 15px;
    padding: 20px 40px;
    color: white;
    background-color: green;
    border-radius: 5px;
    cursor: pointer;
    &:hover{
      background-color: #169416;
    }

`