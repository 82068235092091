import {React, useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {  
  closeDirectoryModal, deleteFamilyMember, setDirectoryImagePreview, 
  removeDirectoryImage, chooseDirectoryImage, setViewAddPersonModal,
  addFamilyMember
} from '../../../features/modal/directoryModalSlice'
import { createDirectoryItem, updateDirectoryItem } from '../../../features/directory/directorySlice' 
import { imgUrl } from 'features/constants'
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import ClearIcon from '@mui/icons-material/Clear'; 
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import ImageIcon from '@mui/icons-material/Image';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Modal, Tooltip } from '@mui/material' 
import SpinnerSmall from 'components/SpinnerSmall'
import styled from 'styled-components'
import { TfiImage } from "react-icons/tfi";

import {
  ModalWrap, BulletinFormTop, ModalForm, AddImage, ImageWrap, ImagePlaceholder, ImgPreview, AddImageBtnsWrap, AddImageBtn, RemoveImage, SideInfo, SideInfoTopWrap, InputWrapHeading, InputWrap, AddDirectoryMember, AddUserInputWrap, AddUserBtnWrap, AddUserBtn, AddBirthdayBtn, BottomInfo, MembersList, MembersListHeading, MembersListWrap, MembersListItem, SubmitWrap, IssueSubmit, InfoWrap, Info, InfoItem
} from '../directory.styled'

import { ButttonWrap, FileUploadButton } from 'components/files/files.styled'
import { directoryLanguage } from '../directoryLanguage'
import { set } from 'lodash'

export default function CreateDirectoryEntry() {

  const dispatch = useDispatch()
  const members = useRef()
  const [ viewAddUserForm, setViewAddUserForm ] = useState(false)
  const { familyMembers, directoryImagePreview, selectedImage, editDirectory, data } = useSelector((state) => state.directoryModal)
  const { isDirectoryCreatedLoading, isDirectoryUpdatedSuccess, isDirectoryUpdatedLoading } = useSelector((state) => state.directory)
  const { profileChurch } = useSelector((state) => state.profileChurch)
  const [ directoryHeading, setDirectoryHeading ] = useState('')
  const { user } = useSelector((state) => state.auth)
  const [editUpdateImage, setEditUpdateImage] = useState(false)

  const I_STATE = {
    name: '',
    email: '',
    phone: '',
    about: '', 
  }

  const [userInfo, setUserInfo] = useState(I_STATE) 

  const handleImageChange = (e) => { 

    if(editDirectory){
      set(editUpdateImage(true))  
    }

    let url = URL.createObjectURL(e.target.files[0])
    dispatch(setDirectoryImagePreview({ url: url }))
    dispatch(chooseDirectoryImage(e.target.files[0]))
  }

  const handleAddDirectoryHeading = (e) => {
    setDirectoryHeading(e.target.value)
  }

  const handleSubmit = () => {

    if(editDirectory){

      const formData = new FormData();

      if(editUpdateImage){
        if(!directoryImagePreview.url){
          return toast.error('Please add image and a name!')
        }else{
          formData.append("file", selectedImage)  
        } 
      }

      if(!directoryHeading){
        return toast.error('Please add image and a name!')
      }
      
      formData.append("isEditing", true)
      formData.append("directoryId", data._id)   
      formData.append("familyName", directoryHeading);
      formData.append("churchId", profileChurch._id) 
  
      familyMembers.map(
        ele => formData.append("familyList", JSON.stringify(ele))
      )

      dispatch(updateDirectoryItem(formData))

    }else{
      if(!directoryImagePreview.url || !directoryHeading){return toast.error('Please add image and a name!')}
      const formData = new FormData();
      formData.append("file", selectedImage)
      formData.append("familyName", directoryHeading);
      formData.append("churchId", profileChurch._id) 

      familyMembers.map(
        ele => formData.append("familyList", JSON.stringify(ele))
      )

      dispatch(createDirectoryItem(formData))      
    } 
  }

  const isImgPreview = Object.keys(directoryImagePreview).length > 0

  useEffect(() => {
    if(editDirectory){ 
      if(data){
        setDirectoryHeading(data.name)
        dispatch(setDirectoryImagePreview(
          {url: `${imgUrl}/directory/${data.image.imagePath2}`})
        )
        dispatch(chooseDirectoryImage({}))

        if(data.familyList.length > 0){
          data.familyList.map(ele => {
            dispatch(addFamilyMember(JSON.parse(ele)))
          })        
        }
      } 
    } 
  }, [editDirectory, data])

  useEffect(() => {

    if(isDirectoryUpdatedSuccess){
      dispatch(closeDirectoryModal())
    }

  }, [isDirectoryUpdatedSuccess])

  return (
    <ModalWrap>
      <BulletinFormTop>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <FolderSharedIcon style={{ marginLeft: '10px', marginRight: '10px'}} />  
          
          { directoryLanguage.createYourDirectory[user.preferredLanguage]}
        </div>
        <ModalCloseButton handler={() => dispatch(closeDirectoryModal())} top='10px' right='10px' size='35px' />
      </BulletinFormTop>

      <ModalForm>  
        <AddImage>
          <ImageWrap>
            <ImagePlaceholder> 
              { isImgPreview ? 
                ( 
                  <ImgPreview src={directoryImagePreview.url} alt="Add Image 1" /> 
                ) 
                :
                ( 
                  <NoImageWrapper 
                    htmlFor="imageUpload"
                    >
                    <TfiImage />
                    <span>
                      {directoryLanguage.clickToAddImage[user.preferredLanguage]}
                    </span>
                  </NoImageWrapper>
                  // <ImgPreview src={`${imgUrl}/directory/2024-01-09T16-44-37.193Z-directory.jpg`} alt="Add Image" /> 
                ) 
              } 
            </ImagePlaceholder >

            <input hidden id="imageUpload"  type="file" accept=".png, .jpg, .jpeg" name="file" onChange={handleImageChange}/>
            {
              isImgPreview && ( 
                <AddImageBtnsWrap>
                  <AddImageBtn htmlFor="imageUpload"> 
                    <ImageIcon style={{fontSize: '16px', marginRight: '12px'}}/>
                    { isImgPreview ? 
                      (<span>{directoryLanguage.replaceImage[user.preferredLanguage]}</span>)
                      :
                      (<span>{directoryLanguage.uploadImage[user.preferredLanguage]}</span> )}

                  </AddImageBtn>
                  { isImgPreview && ( 
                    <RemoveImage onClick={() => dispatch(removeDirectoryImage())}>
                      {
                        directoryLanguage.removeImage[user.preferredLanguage]
                      }
                    </RemoveImage>
                    
                  )}
                </AddImageBtnsWrap>   
              )
            }
          </ImageWrap>
        </AddImage>

        <SideInfo>
          <SideInfoTopWrap>
            <InputWrapHeading>  
              <InputWrap>  
                <input 
                  ref={members} 
                  name='name' 
                  value={directoryHeading} 
                  onChange={handleAddDirectoryHeading}  
                  maxLength = "25" 
                  placeholder={directoryLanguage.directoryName[user.preferredLanguage]}
                  />
              </InputWrap>  
            </InputWrapHeading>
          
            { !viewAddUserForm && 
              <AddDirectoryMember onClick={() => dispatch(setViewAddPersonModal(true))}>
                  <PersonAddIcon />
                  <span>
                    {directoryLanguage.addPersonToDirectory[user.preferredLanguage]}
                  </span>
              </AddDirectoryMember>
            }
          </SideInfoTopWrap>
        </SideInfo>     
      </ModalForm>  
      <BottomInfo>
      <MembersList>
        <MembersListHeading>
          {directoryLanguage.peopleInDirectory[user.preferredLanguage]}
        </MembersListHeading> 
          <MembersListWrap>
            {(familyMembers.length > 0 && familyMembers.length < 15 ) ? 
              (
                  familyMembers.map(ele => (
                    <MembersListItem>   
                      <Tooltip title={'delete'} placement="top-start" arrow>        
                        <span onClick={() => dispatch(deleteFamilyMember({name: ele.name}))} 
                          className='deleteMember'> 
                          <ClearIcon/> 
                        </span>     
                      </Tooltip>        
                      <span>{ele.name}</span>
                    </MembersListItem>
                  )
                )
              )
              :
              (
                <MembersListItem
                  style={{cursor: 'pointer'}}
                  onClick={() => dispatch(setViewAddPersonModal(true))}
                  >
                  <i>
                    {directoryLanguage.addPersonToDirectory[user.preferredLanguage]}
                  </i>
                </MembersListItem>
              )
            }              
          </MembersListWrap>
        </MembersList>
      </BottomInfo>  
      <ButttonWrap> 
          <FileUploadButton 
            disabled={isDirectoryCreatedLoading}
            onClick={handleSubmit}
            >
            {isDirectoryCreatedLoading ? 
              (
                <SpinnerSmall />
              ) 
              : 
              (
                directoryLanguage.submitDirectory[user.preferredLanguage]
              ) 
            }
          </FileUploadButton>
        </ButttonWrap>      
    </ModalWrap>
  )
}

const NoImageWrapper = styled.label`
  cursor: pointer;
  
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  background-color: #efefef;
  border-radius: 10px;
  color: var(--lightThemeFontColor);



  svg{
    margin-bottom: 10px;
    font-size: 40px;
    color: var(--lightThemeFontColor);
  }

  &:hover{
    transition: 0.3s;
    border: 2px dashed grey;
  }


  @media (max-width: 768px) {
    height: 150px;
  }

`
