export function convertTime (rawDate){
    let a = new Date(rawDate * 1000);
    let months = ['January','February','March','April','May','Junr','July','August','September','October','November','December'];
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let date = a.getDate();
    let hour = a.getHours();
    let min = a.getMinutes();
    let fullTime = month + ' ' + date + ' ' + year + ' ' + hour + ':' + min;
    
    let timeObj = {
        fullTime: fullTime,
        day: date,
        month: month,
        year: year,
        hour: hour,
        min: min
    }
    return timeObj;
}

// return string
export function formatPrice (rawPrice, currency){

    if(!currency){
        //default USD

        let price = rawPrice.toString()

        const dec = price.slice(price.length - 2);

        const doll = price.slice(0, price.length - 2)

        return `$${doll}.${dec}`

    }else if(currency){
        //switch cases for currency types


        let price = rawPrice.toString()

        const dec = price.slice(price.length - 2);

        const doll = price.slice(0, price.length - 2)

        return `##${doll}.${dec}`

    }
    
}