import React, {useState} from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { chooseThumbnailImage, setVideoUrl, setSelectedVideo, setVideoTitle, setThumbnailPreview } from 'features/videos/videoSlice'
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

export default function VideoForm({ type }) {
    const dispatch = useDispatch()
    const { viewUploadVideoModal, videoUrl, thumbnailPreview, videoTitle } = useSelector((state) => state.video)
    const [videoName, setVideoName] = useState('')
 

    const handleThumbmail = (e) => {
        let url = URL.createObjectURL(e.target.files[0])
        dispatch(setThumbnailPreview( url ))
        dispatch(chooseThumbnailImage(e.target.files[0]))
    }

    const handleGetYoutubeThumbnail = (url) => {
        const videoId = url.slice(url.indexOf("=") + 1, url.lastIndexOf("&"))
        const imageURL = `https://i3.ytimg.com/vi/${videoId}/hqdefault.jpg`
        dispatch(setThumbnailPreview(imageURL))
    }

    const handleVideoChange = (e) => { 
        dispatch(setSelectedVideo(e.target.files[0])) 
    }

    if(type === 'computer'){
        return (
            <ExtraWrap >
                <VideoFormWrap
                    style={{
                        width: '100%',
                        flexDirection: 'column', 
                        justifyContent: 'space-between',
                    }}
                    >
                    <input
                        style={{
                            width: '100%',
                        }}
                        type="text"  
                        value={videoTitle} 
                        placeholder='Video Title'
                        onChange={(e) => dispatch(setVideoTitle(e.target.value))}
                        />                     
                    <input 
                        style={{width: '100%', padding: '3px 10px'}}
                        id="myVideo" type="file" accept="video/*" name="file" placeholder={'Ehl'}
                        onChange={handleVideoChange}
                        /> 
                    {/* <MyLabel 
                        style={{
                            fontSize: '12px',
                            width: '30%', 
                            height: '100%',
                            right: '0px',
                        }}
                        for="myVideo"
                        >
                        <VideoCameraBackIcon />
                        <span>Select Video</span> 
                    </MyLabel> */}


                </VideoFormWrap>        
                {/* <input 
                    hidden
                    id="1"  
                    type="file" 
                    accept="image/*" 
                    name="file" 
                    placeholder={'Ehl'}
                    onChange={handleThumbmail}
                    />         
                <MyLabel 
                    style={{
                        position: 'absolute',
                        bottom: '20px',
                        left: '20px'
                    }}
                    for="1"
                    >
                    <InsertPhotoIcon />
                    <span>{previewImage ? 'Replace':'Add'} Thumbnail</span>
                </MyLabel> */}
                
                {/* {
                    thumbnailPreview && 
                    (
                        <img
                            src={thumbnailPreview}
                            alt="thumbnail"
                            style={{
                                width: '100px',
                                height: '100px',
                                objectFit: 'cover',
                                borderRadius: '10px',
                                marginTop: '10px'
                            }}
                            />    
                    )
                } */}
            </ExtraWrap>

        )
    }else if(type === 'youtube'){
        return (
            <ExtraWrap >
                <VideoFormWrap>
                    <input
                        style={{
                            borderBottom: '1px solid #ccc',
                        }}
                        type="text"
                        placeholder="YouTube URL"
                        onChange={(e) => {
                                dispatch(setVideoUrl(e.target.value))
                                handleGetYoutubeThumbnail(e.target.value)
                            }
                        }
                        /> 
                    <input
                        type="text" 
                        accept="video/*"   
                        value={videoTitle} 
                        placeholder='Video Title'
                        onChange={(e) => dispatch(setVideoTitle(e.target.value))}
                        /> 
                </VideoFormWrap>
                { thumbnailPreview && (

                    <img
                        src={thumbnailPreview}
                        alt="thumbnail"
                        style={{
                            width: '100px',
                            height: '100px',
                            objectFit: 'cover',
                            borderRadius: '10px',
                            marginTop: '10px'
                        }}
                        />    
                    )
                }
            </ExtraWrap>
        )
    }else{
        return (
            <ExtraWrap >
                <VideoFormWrap>
                <input
                    style={{
                        borderBottom: '1px solid #ccc',
                    }}
                    type="text"
                    placeholder="Add Video URL"
                    onChange={(e) => {
                            dispatch(setVideoUrl(e.target.value)) 
                        }
                    }
                    /> 
                <input
                    type="text" 
                    accept="video/*"   
                    value={videoName} 
                    placeholder='Video Title'
                    onChange={(e) => setVideoName(e.target.value)}
                    /> 

                </VideoFormWrap>
                <input 
                    hidden
                    id="1"  
                    type="file" 
                    accept="video/*" 
                    name="file" 
                    placeholder={'Ehl'}
                    onChange={handleThumbmail}
                    />         
                    <MyLabel 
                        style={{
                            position: 'absolute',
                            bottom: '20px',
                            left: '20px'
                        }}
                        for="1"
                        >
                        <InsertPhotoIcon />
                        <span>Add Thumbnail</span>
                </MyLabel>
            </ExtraWrap>
        )
    }

}

const VideoFormWrap = styled.div`

    width: 100%; 
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 5px;

    input{
        width: 100%;
        height: 100%;
        border: none;
        outline: none; 
        margin-right: 10px;
        padding: 15px;
    }
`

const VideoSubmitButton = styled.div`
    width: fit-content;
    height: 100%;   
    background-color: #cecece;
    padding: 0px 10px;
    color: #6e6e6e;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
`

const MyLabel = styled.label`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    bottom: 20px;
    right: 10px;
    width: fit-content;
    height: 40px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    border: 1px solid var(--lightThemeFontColor);
    color: var(--lightThemeFontColor);
    cursor: pointer;
    outline: none;

    svg{
        font-size: 20px;
        margin-right: 10px;
    }

`

const ExtraWrap = styled.div`
    width: 100%;
    min-height: 250px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`