import React from 'react'
import styled from 'styled-components'

export default function ModalContainer(props) {
  return (
    <MMCont1>

        {
            props.modalBody
        }

    </MMCont1>
  )
}

const MMCont1 = styled.div`
    transition: .4s;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
`