import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import searchService from './searchService'

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

const initialState = {
  searchValues: [],
  isSearchError: false,
  isSearchSuccess: false,
  isSearchLoading: false,
  searchMessage: '',
  nextPageR: 0,
  hasNextPageR: false,
}

// search
export const search = createAsyncThunk(
  'search/search',
  async (searchData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await searchService.search(searchData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)


// search
export const searchPaginate = createAsyncThunk(
  'search/searchPaginate',
  async (searchData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await searchService.searchPaginate(searchData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)


// search
export const loadMore = createAsyncThunk(
  'search/loadMore',
  async (searchData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await searchService.searchPaginate(searchData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    searchReset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state) => {
        state.isSearchLoading = true
      })
      .addCase(search.fulfilled, (state, action) => {
        state.isSearchLoading = false
        state.isSearchSuccess = true
        state.searchValues = action.payload
      })
      .addCase(search.rejected, (state, action) => {
        state.isSearchLoading = false
        state.isSearchError = true
        state.searchMessage = action.payload
      })
      //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
      .addCase(searchPaginate.pending, (state) => {
        state.isSearchLoading = true
      })
      .addCase(searchPaginate.fulfilled, (state, action) => {
        state.isSearchLoading = false
        state.isSearchSuccess = true
        state.searchValues = action.payload
        state.nextPageR = action.payload.nextPage
        state.hasNextPageR = action.payload.hasNextPage
      })
      .addCase(searchPaginate.rejected, (state, action) => {
        state.isSearchLoading = false
        state.isSearchError = true
        state.searchMessage = action.payload
      })
      //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
      .addCase(loadMore.pending, (state) => {
        state.isLoading = true
      })
      .addCase(loadMore.fulfilled, (state, action) => {
        state.isSearchLoading = false
        state.isSearchSuccess = true
        state.nextPageR = action.payload.nextPage
        state.hasNextPageR = action.payload.hasNextPage
        state.searchValues.originalQuery = action.payload.originalQuery
        state.searchValues.nextPage = action.payload.nextPage
        state.searchValues.hasNextPage = action.payload.hasNextPage
        state.searchValues.docsLength = action.payload.docsLength
        const newArray = state.searchValues.docs.concat(action.payload.docs)
        state.searchValues.docs = newArray

      })
      .addCase(loadMore.rejected, (state, action) => {
        state.isSearchLoading = false
        state.isSearchError = true
        state.searchMessage = action.payload
      })

  },
})

export const { searchReset } = searchSlice.actions
export default searchSlice.reducer
