import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const config = (token) => { return { 
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}`, }, 
  } }
  

const uploadAudio = async (data, token) => {
    const response = await axios.post(URL+'/api/audio/uploadAudio' , data, config(token))
    return response.data
}

const getAudios = async (data, token) => {
    const response = await axios.post(URL+'/api/audio/getAudios' , data, config(token))
    return response.data
}

const deleteAudio = async (data, token) => {
    const response = await axios.post(URL+'/api/audio/deleteAudio', data, config(token))
    return response.data
}

const updateAudio = async (data, token) => {
    const response = await axios.post(URL+'/api/audio/updateAudio', data, config(token))
    return response.data
}

const addListenCount = async (data, token) => {
    const response = await axios.post(URL+'/api/audio/addListenCount', data, config(token))
    return response.data
}

const searchAudio = async (data, token) => {
    const response = await axios.post(URL+'/api/audio/searchAudio', data, config(token))
    return response.data
}

const audioService = {
    uploadAudio, getAudios, deleteAudio, updateAudio, addListenCount, searchAudio
}

export default audioService