import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components' 
import { imgUrl } from 'features/constants'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createCampaignGivingIntentMembers, getDonationsByCampaign } from 'features/givingCampaigns/givingCampaignSlice'; 
import {Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js'; 
import { pkStripe } from 'features/constants';
import { toast } from 'react-toastify'
import { RiSecurePaymentFill } from "react-icons/ri";
import ConfirmGivingPayment from './ConfirmGivingPayment'
import { useParams } from 'react-router-dom'
import { LiaDonateSolid } from "react-icons/lia";
import Spinner from 'components/Spinner';
import Loader from 'loaders/Loader';
import SpinnerIntermintentSmall from 'loaders/SpinnerIntermintentSmall';
import { DH1, DonateContainer } from '../giving.styled';

export default function Donations() {

    const dispatch = useDispatch()
    const { campaignId } = useParams()

    const { user } = useSelector(state => state.auth) 
    const { 
        getDonationsByCampaignLoading,
        giverName,
        getDonationsByCampaignError,
        campaignDonations,
        
     } = useSelector((state) => state.givingCampaign)


    useEffect(() => {
        // get single campaign data
        dispatch(getDonationsByCampaign({ 
            campaignId: campaignId
        })) 
    }, [campaignId])


    return (
        <DonateContainer
            maxHeight={'500px'}
            >
            <DH1>
                <LiaDonateSolid />
                Donations
            </DH1>
            {
                getDonationsByCampaignLoading ? 
                (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center', 
                            color: 'var(--lightThemeFontColor)',
                            marginBottom: '20px',
                            marginTop: '20px'
                        }}
                        > 
                        <SpinnerIntermintentSmall color={'#ccc'} /> 
                    </div>
                )
                : 
                (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center', 
                            color: 'var(--lightThemeFontColor)',
                            marginBottom: '20px',
                            marginTop: '20px',
                            overflowY: 'scroll',
                            scrollbarWidth: 'thin',
                            // hide scrollbar
                            // scrollbarWidth: 'none', /* Firefox */
                            // msOverflowStyle: 'none', /* IE and Edge */
                            // '&::-webkit-scrollbar': {
                            //     width: '1px',
                            // }
                        
                        }}
                        >
                        {
                            campaignDonations.map((donation, index) => (
                                <DonationItem key={index}>
                                    <h3>${donation.amount.toLocaleString()}</h3>
                                    <p>{donation.donorName}</p>
                                </DonationItem>
                            ))
                        }
                    </div>
                )
            }
        </DonateContainer>
    )
}

const DonationItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #f1f1f1;
    
    h3 {
        font-size: 16px;
        font-weight: 500;
        color: var(--lightThemeFontColor);
    }
    p {
        font-size: 14px;
        font-weight: 400;
        color: var(--lightThemeFontColor);
    }

`
