import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux'
import { setViewAudioPlayer, setMinimizeAudioPlayer, setCurrentAudioSource } from 'features/audio/audioPlayerSlice'
import { IoMdClose } from "react-icons/io";
import { Tooltip } from '@mui/material';  
import "./audio.css"
import { MdOutlineQueueMusic } from "react-icons/md";
import { TbPlayerPlayFilled } from "react-icons/tb"; 

export default function AudioPlayerWindow() {

    const dispatch = useDispatch()
    const { audioPlayerWindowHeight, playerMinimized, audioSource } = useSelector((state) => state.audioPlayer)
    const [ isPlaying, setIsPlaying ] = useState(false)

    const audioPlayerRef = useRef(null)

    const toggleViewSize = () => {

        if(!audioPlayerRef.current.paused){
            if(!isPlaying){ 
                setIsPlaying(true)
            }
        }

        if(audioPlayerRef.current.paused){
            setIsPlaying(false)
        }

        dispatch(setMinimizeAudioPlayer({
            status: playerMinimized ? false : true,
            height: playerMinimized ? '250px' : '50px'
        }))
    }

    const handleClose = () => {
        dispatch(setCurrentAudioSource(null))
        dispatch(setViewAudioPlayer('0px'))
        dispatch(setMinimizeAudioPlayer({
            status: false ,
            height: '0px'
        }))
    }

    const handlePlayAudio = () => {

        

        if(audioPlayerRef.current.paused){
            audioPlayerRef.current.play()
            setIsPlaying(true)
        } else {
            audioPlayerRef.current.pause()
            setIsPlaying(false)
        }
    }


    return (
        <AudioPlayerCont>  
            <TopControls view={audioPlayerWindowHeight} > 
                {
                    audioSource && playerMinimized &&
                    (
                        <MinimizedWindowControls>
                            
                            {isPlaying ? 
                                (
                                    <MinimizedControls>
                                        <div class="soundwave"></div> 
                                        <TRE onClick={handlePlayAudio}  >
                                            Pause
                                        </TRE>
                                        {/* <IoPauseSharp onClick={handlePlayAudio} /> */}
                                    </MinimizedControls> 
                                )
                                : 
                                (
                                    <MinimizedControls>
                                        <TbPlayerPlayFilled /> 
                                        <TRE onClick={handlePlayAudio} >Play</TRE>
                                        {/*   */}
                                    </MinimizedControls> 
                                ) 
                            }
                        </MinimizedWindowControls>
                    )
                }

                <span
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}
                    >
                    <Tooltip 
                        title={playerMinimized ? "Maximize" : "Minimize"}
                        placement='top'>
                        <ControlButton onClick={toggleViewSize}> 
                            { playerMinimized ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> }
                        </ControlButton>
                    </Tooltip>
                    <Tooltip title="Close and stop" placement='top'>
                        <ControlButton onClick={handleClose}> 
                            <IoMdClose  />
                        </ControlButton>
                    </Tooltip>                    
                </span>

            </TopControls>  



            <AudioDescriptionContainer
                display={audioPlayerWindowHeight === '0px' ? 'none' : 'flex'}
                > 
                <AudioPlayerTitle
                    display={audioPlayerWindowHeight === '50px' ? 'none' : 'flex'}
                    >
                    <MdOutlineQueueMusic style={{ marginRight: '10px' }} />
                    {audioSource ? audioSource.title : 'Audio Title'} 
                </AudioPlayerTitle>
                <AudioDescription
                    display={audioPlayerWindowHeight === '50px' ? 'none' : 'flex'}
                    >
                    {audioSource && audioSource.description &&
                    (   
                        audioSource.description.split('\n').map((item, key) => {
                            return <span key={key}>{item}</span>
                        }
                    ))
                }
                </AudioDescription>
                     
            </AudioDescriptionContainer>
  

            <StyledAudioPlayer 
                ref={audioPlayerRef}
                heightProps={playerMinimized ? '0px' : '250px'}
                src={audioSource ? audioSource.url : ''}
                // src='https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3'
                autoPlay
                controls 
                />

        </AudioPlayerCont>
    )
}


const TRE = styled.div`
    cursor: pointer;
    color: var(--lightThemeFontColor);
    margin-left: 10px;
    font-size: 12px;
    background-color: #e9e9e9;
    padding: 5px 10px;
    border-radius: 5px;
    &:hover{
        background-color: #ccc;
    }
`

const MinimizedControls = styled.div`

    display: flex;
    padding: 5px 20px;
    align-items: center;

    svg { 
        cursor: pointer;
        color: var(--lightThemeFontColor); 

    }

`

const MinimizedWindowControls = styled.div` 
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const AudioPlayerTitle = styled.div`
    display: ${props => props.display};
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; 
`

const AudioDescription = styled.div`
    display: ${props => props.display};
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    color: var(--lightThemeFontColor);
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    font-size: 12px;

    /* hide scrollbar */

    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    span{
        width: 100%;
        text-align: left;
    }

`

const StyledAudioPlayer = styled.audio`
    height: ${props => props.heightProps};

`   

const AudioDescriptionContainer = styled.div`
    display: ${props => props.display};
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    margin-top: 10px;
    color: var(--lightThemeFontColor);
    width: 100%;
    height: 100%;
    padding: 10px;
`   

const TopControls = styled.div`
    display: ${props => props.view === '0px' ? 'none' : 'flex'};
    margin-top: 10px;
    flex-direction: row;
    justify-content: ${props => props.view === '50px' ?   'space-between':'flex-end'};
    align-items: center;
    height: fit-content;
    width: 100%;
    color: var(--lightThemeFontColor);

`

const ControlButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
    font-size: 30px;
    cursor: pointer;
    &:hover{
        color: var(--themeColor);
    }
`

const AudioPlayerCont = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
`
