

import { getAnnouncements, announcementReset } from 'features/anouncements/announcementSlice'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import AncmtItem from './AncmtItem'
import { setPrivilege } from 'features/auth/authSlice' 
import { addAc } from 'features/auth/authSlice'
import { addAcFunc } from 'funcs/ac'
import { NoItemsContainer } from 'components/main.styled'
import { language } from 'app/language'
import SpinnerIntermintentSmall from 'loaders/SpinnerIntermintentSmall'
import { MdOutlineAnnouncement } from "react-icons/md";
import { ChatCenteredSlash  } from "@phosphor-icons/react";


export default function AnnouncementFeed() {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { announcements, isAnouncementsSuccess, isAnouncementsLoading } = useSelector((state) => state.announcement)

    useEffect(() => {
        dispatch(getAnnouncements({
            churchId: profileChurch._id
        }))
    }, [dispatch, profileChurch._id])


    useEffect( () => () => { //cleanup
      dispatch(announcementReset())
    }, [] ); 
    

    useEffect(() => {

        const dis = () => dispatch(addAc({
          type: 'announcements', 
        }))
    
        addAcFunc('announcements', dis)
    
    }, [])

    if(isAnouncementsLoading){
        return (
            <div
                style={{marginTop: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: '100%', width: '100%'}}
                >
                <SpinnerIntermintentSmall 
                    color='var(--themeColor)'
                    size={'50px'}
    
                    />
            </div>
        )
    }


    return (
        <AFeedContainer> 
        { isAnouncementsSuccess ?
            (
                announcements.length > 0 ?
                (
                    announcements.map((announcement, index) => (
                        <AncmtItem 
                            key={announcement._id} 
                            data={announcement} 
                            index={index} 
                            />
                        )
                    )
                ) 
                : 
                (
                    <NoItemsContainer> 
                        <ChatCenteredSlash />
                        <div>
                            {
                                language.noAnnouncements[user.preferredLanguage]
                            }
                        </div>    
                    </NoItemsContainer>
                )
            ) 
            : 
            (
                <NoItemsContainer>{language.errorOccurred[user.language]}</NoItemsContainer>
            )
        }

        </AFeedContainer>
    )
}

const AFeedContainer = styled.div`

    width: 100%; 
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-y: scroll;
    height: 100%;  
    overflow-y: scroll;
    margin-top: 10px;
    scrollbar-width: none; 
    -ms-overflow-style: none;  

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    /* border: 1px solid red; */
    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%; 
        flex-wrap: unset;
        padding: 0px;
        margin-top: 0px;
    }
`
