import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { setSearchMarketRadius, setViewMarketLocationModal } from 'features/marketplace/marketSlice'

export default function LocationFilter() {

    const dispatch = useDispatch()  
    const { listings, searchRadii, searchRadius, searchLocation } = useSelector((state) => state.market)
    const { user } = useSelector((state) => state.auth)


    const handleSearchRadiusChange = (e, value) => {
        localStorage.setItem('marketSearchRadius', value)
        dispatch(setSearchMarketRadius(value))
    }

    const handleSetLocation = () => {
        dispatch(setViewMarketLocationModal(true))
    }

    return (
        <LocationFilterWrap>
            <span
                style={{ cursor: 'pointer', color: 'var(--lightThemeFontColor)', fontStyle: 'underline' }}
                onClick={handleSetLocation}
                >
                { user.marketLocation ? user.marketLocation.address : 'Set Location' }
            </span>
            <span>Search radius (mi):  </span>

            <select
                value={searchRadius}
                onChange={(e) => handleSearchRadiusChange(e, e.target.value)}
                >
                {
                    searchRadii.map((item, index) => {
                        return <option key={index}>{item.value}</option>
                    })
                
                }
            </select>
        </LocationFilterWrap>
    )
}

const LocationFilterWrap = styled.div`
    padding: 10px;
    display: flex;
    color: var(--lightThemeFontColor);

    span{
        font-size: 11px;
        margin-right: 10px;
    }

`