import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { setViewPrefferedLanguageModal, updatePreferredLanguage } from 'features/auth/authSlice'
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import { language } from 'app/language'


export default function LanguageModal() {

    const dispatch = useDispatch()
    const closeLanguageModal = () => dispatch(setViewPrefferedLanguageModal(false))
    const { user } = useSelector(state => state.auth)

    const setLanguage = (value) => {

        dispatch(updatePreferredLanguage({
            language: value
        }))
 
        closeLanguageModal()
    }




    return (  
        <LanguageModalContainer> 
            <ModalCloseButton
                handler={closeLanguageModal}
                top='10px'
                right='10px'
                size='40px'
                />
 
            
            <LanguageModalBody>
            <LanguageModalTitle>
                {language.chooseLanguage[user.preferredLanguage]}
            </LanguageModalTitle>  

                <LanguageModalOption 
                    selected={user.preferredLanguage === 'en'}
                    onClick={() => setLanguage('en')}
                    >
                    English
                </LanguageModalOption> 


                <LanguageModalOption 
                    selected={user.preferredLanguage === 'es'}
                    onClick={() => setLanguage('es')}
                    >
                    Español
                </LanguageModalOption>
            </LanguageModalBody>
        </LanguageModalContainer> 
    )
}
 
const LanguageModalContainer = styled.div` 
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    width: 500px;
    height: 300px;
`
 
const LanguageModalTitle = styled.div`
    color: var(--lightThemeFontColor);
    font-size: 20px;
    margin-bottom: 40px;
    margin-top: 30px;
`
 
const LanguageModalBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
`
const LanguageModalOption = styled.div`
    color: var(--lightThemeFontColor);
    border: ${props => props.selected ? '2px solid var(--themeColor)' : '2px solid transparent'};    
    cursor: pointer;
    padding: 10px 20px;
    text-align: left;
    border-radius: 10px;
    margin-bottom: 10px;
    pointer-events: ${props => props.selected ? 'none' : 'auto'};

    &:hover {
        background-color: #ededed;
    }
`
