import React from 'react'
// import './css/loader.css'
import styled from 'styled-components'
import { keyframes  } from 'styled-components'

export default function SpinnerIntermintentSmall({ color = '#909090', size = '48px', border = '5px'}) {
    return (
        <SpinnerWrap 
            size={size}
            spinnerColor={color}
            > 
        </SpinnerWrap>
    )
}

const RotateAction = keyframes`
    100%   {transform: rotate(360deg)}
`

const LoaderAnimation = keyframes`
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
`

const SpinnerWrap = styled.span`
    width: ${props => props.size};
    height: ${props => props.size};
    border-radius: 50%;
    position: relative;
    animation-name: ${RotateAction};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &:before{
        content: '';
        position: absolute;
        inset: 0; 
        border-radius: 50%; 
        border: ${props => props.border} solid ${props => props.spinnerColor};
        animation-name: ${LoaderAnimation};
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
`

