import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getListings, setListingTypeFilter } from 'features/marketplace/marketSlice'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from 'loaders/Loader'
import ListingItem from './ListingItem'
import ListingSearchContainer from './ListingSearchContainer'

export default function ListingFeedContainer() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()

    const { user } = useSelector(state => state.auth)
    const { 
        listings, 
        isGetListingsLoading, 
        isGetListingsSuccess, 
        listingTypeFilter, 
        searchRadius, 
        searchCategory,
        currentPage,
    } = useSelector(state => state.market)

    useEffect(() => {

        console.log(JSON.stringify(params))

        let paramsListingType = params.marketListingType
        let paramsListingCategory = params.listingCategory

        if(!paramsListingType) { 
            paramsListingType = 'For Sale'
        }

        if(paramsListingType !== listingTypeFilter) {  
            dispatch(setListingTypeFilter(paramsListingType))
            navigate(`/market/${paramsListingType}`)
        }

        dispatch(getListings({
            listingType: paramsListingType,
            searchRadius: searchRadius,
            category: paramsListingCategory ? paramsListingCategory : null,
            // location should come from local storage, but it should also be available in the redux store
            locationLng: user.marketLocation.coordinates[0] ? user.marketLocation.coordinates[0] : null,
            locationLat: user.marketLocation.coordinates[1] ? user.marketLocation.coordinates[1] : null,
            currentPage: currentPage,
        }))


        // dispatch(setListingTypeFilter(params.marketListingType))

    }, [listingTypeFilter, params, searchRadius, user.marketLocation, dispatch, navigate])

    return (
        <ListingFeedContainerPage>

            { 
                isGetListingsLoading ? 
                (
                    <Loader />
                )
                : isGetListingsSuccess && 
                (
                    listings.map((item, index) => { 
                        return <ListingItem 
                            key={index} 
                            item={item} 
                            />
                    })
                )
            }
        </ListingFeedContainerPage>
    )
}

const ListingFeedContainerPage = styled.div`
    width: fit-content;
    margin-top: 0px;
    height: fit-content ;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
`