import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  postImage: null,
}

export const postImageSlice = createSlice({
  name: 'postImage',
  initialState,
  reducers: {
    chooseImage: (state, action) => {
      state.postImage = action.payload
    },
    removeImage: (state) => initialState,
  },
})

export const { chooseImage, removeImage } = postImageSlice.actions

export default postImageSlice.reducer