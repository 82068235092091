import styled from "styled-components";

export const MembershipsContainer = styled.div`

`

export const MemberNameWrap = styled.div`
    margin-left: 10px;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start
`