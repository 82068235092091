import { useEffect } from 'react'
import '../css/userProfile.css'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getProfilePosts } from '../features/posts/postSlice'
import { postReset, setViewCreatePostModal } from '../features/posts/postSlice' 
import MyPostFeed from '../components/MyPostFeed' 
import UserExtras from '../components/userProfile/UserExtras'
import ChoosePostPage from '../components/postComponents/ChoosePostPage'
import { logout, resetUser } from 'features/auth/authSlice'
import styled from 'styled-components'
import CreatePostModal from 'components/createPostModal/CreatePostModal'
import AddIcon from '@mui/icons-material/Add';
import { PageWrapper, PageContainerMain, UserProfileMain  } from './pages.styled'


function MyProfile() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth)
    const { isError, message, } = useSelector((state) => state.profile)
//  const { viewModal } = useSelector((state) => state.modal) 
    const { isPostError, postsMessage, viewCreatePostModal } = useSelector((state) => state.posts)
    
    useEffect(() => {

        if(user.isOrganization){ 
            navigate(`/${user.username}`)
        }

        if(isPostError && postsMessage === 'Not logged in'){
            navigate('/login')
            dispatch(logout())
            dispatch(resetUser())
            window.location.reload(false);
        }

        if (isError) { 
            toast.error(message)
        }

        if (!user) {  
            navigate('/login') 
        }
        
    }, [user, isError, message, dispatch, navigate, isPostError, postsMessage])

    useEffect(() => {
        return () => {
            dispatch(postReset())
        }
    },[])

    // useEffect(() => {
    //     dispatch(getProfilePosts({
    //         churchName: null,
    //         currentPage: 1,
    //         filter: null,
    //         userPostView: 'my-posts',
    //         sort: null,
    //     }))
    // }, [])


    return (
        <PageWrapper>
            <PageContainerMain > 
                <div className='churchProfileLeft'>
                    <UserExtras /> 
                </div>  
                <UserProfileMain> 
                    <MyProfileTop1>
                        <ChoosePostPage /> 
                        
                        <CreatePostBtnMPT1 onClick={() => dispatch(setViewCreatePostModal(true))}> 
                            <span>Create Post</span> <AddIcon />
                        </CreatePostBtnMPT1>
                    </MyProfileTop1>
                    
                    <MyPostFeed name={'user'} />
                </UserProfileMain>
            </PageContainerMain>
        </PageWrapper>
    )
}



export default MyProfile


const MyProfileTop1 = styled.div` 
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    /* border: 1px solid green; */
    margin-bottom: 0px;
    justify-content: flex-start;
    /* padding: 10px 10px 0px 10px; */
    width: 100%;

    padding: 10px;

    @media (max-width: 768px) {
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
    }
`
const CreatePostBtnMPT1 = styled.div`
    display: none;
    color: white;
    border-radius: 15px;
    padding: 10px 20px;
    background-color: var(--themeColor);
    width: fit-content; 
    height: 40px;
    white-space: nowrap;
    font-size: smaller;
    span{
        margin-right: 5px;
    }
    @media (max-width: 768px) {
        display: flex;
        flex-direction: row;
        align-items: center; 
    }
`
