import React, { useEffect, useState } from 'react'
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import { setViewResetItemModal, verifyPassword, resetEmail } from 'features/settings/settingSlice'
import { ModalContainerMain } from 'components/modal/modal.styled'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { SubmitButtonInsideInputWrap } from 'components/forms/forms.styled'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import SpinnerSmall from 'components/SpinnerSmall'

export default function ResetSettingsItemModal({item}) {

    const dispatch = useDispatch()
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const { isVerifyPasswordSuccess, isVerifyPasswordLoading, isResetEmailError, resetEmailErrorMessage, isResetEmailSuccess, isResetEmailLoading } = useSelector((state) => state.settings)

    const handlePasswordSubmit = () => {

        if(!password){ 
            return toast.error('Please confirm your password')
        }

        dispatch(verifyPassword({
            password: password
        }))
    }

    const handleEmailReset = () => {
        dispatch(resetEmail({
            email: email
        }))
    }

    useEffect(() => {
        if(isResetEmailError){
            toast.error(resetEmailErrorMessage)
            setEmail('')
        }
    }, [isResetEmailError, resetEmailErrorMessage])

    useEffect(() => {
        if(isResetEmailSuccess){
            toast.success('Email has been updated')
            setEmail('')
            dispatch(setViewResetItemModal(false))
        }
    }, [isResetEmailSuccess, dispatch ])

    return (
        <ModalContainerMain>
            <ModalCont>
                <ModalCloseButton
                    top='15px'
                    right='15px'
                    size='40px'
                    handler = {() => dispatch(setViewResetItemModal(false))}
                    />                
            
                <ModalHeading>Reset {item} </ModalHeading>


                {  !isVerifyPasswordSuccess ? 
                    (
                        <>
                        <ModalSubHeading>Confirm your password to continue. </ModalSubHeading>

                        <EmailResetInputForm>
                            <input
                                placeholder='Enter Password'
                                type='password'
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                />
                            <SubmitButtonInsideInputWrap
                                disabled={isVerifyPasswordLoading}
                                onClick={handlePasswordSubmit}
                                >
                                {isVerifyPasswordLoading ? <SpinnerSmall /> : 'Submit'}
                            </SubmitButtonInsideInputWrap>
                        </EmailResetInputForm></>

                    )
                    :
                    (
                        <>
                            <ModalSubHeading>Enter your new email address </ModalSubHeading>
                            <EmailResetInputForm>
                                <input
                                    placeholder='Enter new email'
                                    type='email'
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    />
                                <SubmitButtonInsideInputWrap
                                    disabled={isResetEmailLoading}
                                    onClick={handleEmailReset}
                                    >
                                    {isResetEmailLoading ? <SpinnerSmall /> : 'Submit'}
                                </SubmitButtonInsideInputWrap>
                            </EmailResetInputForm>
                        </>
                        
                    )
                }
            </ModalCont>
        </ModalContainerMain>
    )
}

const ModalCont = styled.div`
    width: 500px;
    height: fit-content;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 30px 70px 70px 70px;
    position: relative;
    @media (max-width: 768px) {
        width: 100%;
        padding: 10px;
    }
`

const EmailResetInputForm = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    margin-top: 20px;
    input{
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        color: var(--lightThemeFontColor);
        font-weight: 500;
    }
    input::placeholder{
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        color: var(--lightThemeFontColor);
        font-weight: 400;
    }

`

const ModalHeading = styled.div`
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    color: var(--lightThemeFontColor);
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
    @media (max-width: 768px) {
        font-size: 20px;
    }


`

const ModalSubHeading = styled.div`
    width: 100%;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    color: var(--lightThemeFontColor);
    font-weight: 300;
    margin-bottom: 5px;
    text-align: left;

    @media (max-width: 768px) {
        font-size: 18px;
    }
`