import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const API_URL = '/api/singlePost'
const API_URL1 = '/api/singlePost/getPublicPost'
const API_URL_LIKE = '/api/singlePost/likePost'

//for sending the token
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }

//auth required
const getPost = async (postData, token) => {
  const response = await axios.post(URL+API_URL, postData, config(token))
  return response.data
}

const likePost = async (postData, token) => {
  const response = await axios.post(URL+API_URL_LIKE, postData, config(token))
  return response.data
}

//auth required
const getPublicPost = async (postData, token) => {
  const response = await axios.post(URL+API_URL1, postData, config(token))
  return response.data
}

const singlePostService = { getPost, getPublicPost, likePost }
export default singlePostService
