import styled from "styled-components";

export const MUIModalWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; 
    background-color: ${props => props.backgroundColor ? props.backgroundColor : 'none'};

    @media (max-width: 768px) {
        padding: 10px;
    }
`

export const ModalContainer = styled.div`
    transition: .4s;
    position: fixed;
    z-index: 22615;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
`

// this is the latest. As we make fixes to other modals, make sure they are all using this one as the container style.
export const ModalContainerMain = styled.div`
  position: fixed;
  z-index: 615;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
`

export const ModalWrapAbout = styled.div`
    width: 600px;
    height: 500px;
    overflow-y: scroll;
    position: relative;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-x: hidden;
    position: relative;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
    }
`

export const ModalWrap = styled.div`
    width: ${props => props.width ? props.width : '600px'};
    height: ${props => props.height ? props.height : '500px'};
    overflow-y: scroll;
    position: relative;
    background-color: white;
    border-radius: ${props => props.borderRadius ? props.borderRadius : '3px'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
    @media (max-width: 768px) {
        width: 95vw;
        height: 80%;
    }

`

export const CloseBtn = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 5;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
        background-color: #dadada;
        border-radius: 50%; 
    }
`