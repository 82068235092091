import React from 'react' 
import useImageLoader from 'hooks/useImageLoader'
import { imgUrl } from 'features/constants'

export default function PostItemImage({image}) {

    const [ imageSource, { blur } ] = useImageLoader(`${imgUrl}/posts/${image.imagePath1}`, `${imgUrl}/posts/${image.imagePath2}`)

    return (
        <img 
            className="postItemImageImg" 
            src={imageSource} 
            alt="post"
            style={{
                filter: blur ? 'blur(2px)' : 'none',
                transition: blur ? 'none' : 'filter 0.6s ease-out',
            }}
        />  
    )
}
