import styled from "styled-components";

export const ToolTipContainer = styled.button`
    visibility: hidden; 
    width: fit-content;
    background-color: ${props => props.fillColor ? props.fillColor : "#000"};
    color: #fff;
    text-align: center;
    border-radius: 8px 8px 8px 0px;
    padding: 5px 11px;
    font-size: 11px; 
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -10px;
    margin-bottom: 5px;
    border: none;
    &:after{
        content: "";
        position: absolute;
        border: none;
        top: 100%;
        left: 50%;
        margin-left: -5px;;
    }
`


export const ToolTipLabel = styled.span`
    margin-left: 8px;
    position: relative; 
    &:hover ${ToolTipContainer}{
        visibility: visible;
    }
`
