import {  useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { setUserType } from '../features/auth/authSlice'
import UserLoginForm from 'components/login/UserLoginForm'
import ChurchLoginForm from 'components/login/ChurchLoginForm'
import PersonIcon from '@mui/icons-material/Person';
import ChurchIcon from '@mui/icons-material/Church';
import ForgotPasswordModal from 'components/userProfile/ForgotPasswordModal'

function Login() {

  const dispatch = useDispatch()

  const { 
    userType, 
    isRegisterSuccessUser, 
    isRegisterSuccessChurch,
    viewForgotPasswordModal
   } = useSelector((state) => state.auth)

  const handleTypeClick = (value) => {
    localStorage.setItem('loginType', value )
    dispatch(setUserType(value))
  } 

  useEffect(() => {
    let userLoginType = localStorage.getItem('loginType')

    if(userLoginType){
      dispatch(setUserType(userLoginType))
    }else{
      dispatch(setUserType('church'))
    }
  }, [])


  return (
      <RegisterWrap>
          {userType === 'user' ||  isRegisterSuccessUser ? 
            (
              <RegisterHeading><PersonIcon/><span style={{marginLeft: '10px'}}>User Login</span></RegisterHeading>
            )
            : (userType === 'church' ||  isRegisterSuccessChurch) &&
            (
              <RegisterHeading><ChurchIcon/><span style={{marginLeft: '10px'}}>Church Login</span></RegisterHeading>
            )
          }

          <RegisterTabsWrap>
            <RegisterTab 
              onClick={() => handleTypeClick('user')}
              tabSelected={userType === 'user' ? (true):(false) }
              >
              
                Individual User
              </RegisterTab>

              <RegisterTab 
                onClick={() => handleTypeClick('church')}
                tabSelected={userType === 'church' ? (true):(false) }
                >
                Church Organization
              </RegisterTab>
            </RegisterTabsWrap>

            <RegisterBody>
              { userType === 'user' ?
                (
                  <UserLoginForm />  
                )
                : userType === 'church' &&
                (
                  <ChurchLoginForm />
                )
              }
            </RegisterBody>

              { viewForgotPasswordModal && (
                  <ForgotPasswordModal />
                )

              }
        </RegisterWrap>
  )
}

const RegisterWrap = styled.div`
  margin: 0 auto;
  min-width: 600px;
  width: fit-content;
  height: fit-content;
  align-self: center;
  justify-self: center;
  @media (max-width: 768px) {
    min-width: unset;
    height: fit-content;
    width: 95%;
  }
`

const RegisterHeading = styled.h1`
  margin-top: 20px;
  margin-bottom: 30px;
  font-family: Arial, sans-serif;
  color: rgb(105,105,105);
  @media (max-width: 768px) {
    width: 70vw;
    text-align: center;
  }
`

const RegisterTabsWrap = styled.div`
  width: 70%;
  justify-self: flex-start;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    width: 90vw;
  }
`

const RegisterTab = styled.div`
  width: 50%;
  border-radius: 20px 20px 0px 0px;
  cursor: pointer;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.tabSelected ? ('#37bdc2') : ('rgb(210,210,210)')};
  color: ${props => props.tabSelected ? ('white') : ('#525252')};
  @media (max-width: 768px) {
    width:45%;
  }
`

const RegisterBody =styled.div`
  background-color: #37bdc2;
  width: 100%;
  height: fit-content;
  border-radius: 0px 20px 20px 20px;
  box-shadow: #9e9e9ecc 0 0 26px;
  padding: 20px;
  @media (max-width: 768px) {
    width: 100%;
  }
`
export default Login
