import styled from "styled-components";

export const IconStyled = styled.div`   
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: ${props => props.color ? props.color : 'var(--lightThemeFontColor)'};
    cursor: pointer;
    margin-right: ${props => props.marginRight ? props.marginRight : '15px'};
    svg{
        font-size: 18px; 
    }
`

export const DotsButton2 = styled.div`

    @media (max-width: 768px) {
        margin-left: -50px; 
    }
    position: absolute;
    top: ${props => props.top ? props.top : '15px'};
    right: ${props => props.right ? props.right : '15px'};
    display: flex;
    font-size: 18px; 
    flex-direction: row;
    align-items: center;  
    border-radius: 50%;   
    z-index: 5;
    cursor: pointer; 
    color: var(--lightThemeFontColor);  
    padding: 4px;
    &:hover{
        background-color: #e1e1e1;
        color: var(--lightThemeFontColor);
    }
`

export const DotsButton = styled.div`

    @media (max-width: 768px) {
        margin-left: -50px; 
    }
    position: absolute;
    top: ${props => props.top ? props.top : '15px'};
    right: ${props => props.right ? props.right : '15px'};
    display: flex;
    font-size: 18px; 
    flex-direction: row;
    align-items: center;  
    border-radius: 50%;  
    background-color: rgba(0, 0, 0, 0.24);
    z-index: 5;
    cursor: pointer; 
    color: white;  
    padding: 4px;
    &:hover{
        background-color: #e1e1e1;
        color: var(--lightThemeFontColor);
    }
`


export const Dots = styled.div`
    @media (max-width: 768px) {
        margin-left: -50px;
        border-radius: 50%; 
        background-color: #dcdcdca6;
    }
    display: flex;
    font-size: 18px; 
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    border-radius: 5px;
    height: fit-content;
    color: #6b6b6b;
    z-index: 5;
    cursor: pointer;

    color: var(--lightThemeFontColor);

    padding: 5px 6px;
    &:hover{
        background-color: #e1e1e1;
    }
`
export const DotsWrap =  styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid rgb(215, 215, 215);
    list-style-type: none;
    width: fit-content;
    margin-top: 20px;
    justify-content: reverse;
    z-index: 9;
    padding: 8px;
    box-shadow: 0 0 5px #ccc; 
    border-radius: 10px 0px 10px 10px;
    position: absolute;
    top: ${props => props.top ? props.top : '15px'};
    right: ${props => props.right ? props.right : '15px'};
`
export const DotEle = styled.div`
    padding: 10px 20px;
    color: var(--lightThemeFontColor);
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 7px;
    white-space: nowrap;
    transition: .5s;
    cursor: pointer;

    &:hover {
        background-color: rgb(239, 239, 239); 
    }

`

export const DotsDropDown = styled.div` 
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid rgb(215, 215, 215);
    list-style-type: none;
    width: fit-content;
    margin-top: 20px;
    justify-content: reverse;
    z-index: 9;
    padding: 5px;
    box-shadow: 0 0 5px #ccc; 
    border-radius: 10px 0px 10px 10px;
    position: absolute;
    top: 15px;
    right: 15px;
`