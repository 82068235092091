import React, { useEffect } from 'react' 
import { useDispatch, useSelector } from 'react-redux'
import { createFinancialConnectionPaymentIntent, setViewAddPaymentMethodModal } from 'features/giving/givingSlice'
import Loader from 'loaders/Loader'
import styled from 'styled-components'  
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import SpinnerSmall from 'components/SpinnerSmall'
import { CiBank } from "react-icons/ci";
import { CiCreditCard1 } from "react-icons/ci";
import AddBank from './AddBank'
import AddCard from './AddCard'
import { IoArrowBackSharp } from "react-icons/io5";
import SpinnerIntermintentSmall from 'loaders/SpinnerIntermintentSmall'


export default function AddPaymentMethodModal() {

    const dispatch = useDispatch() 
    const { 
        createFinancialConnectionPaymentIntentLoading, 
        createFinancialConnectionPaymentIntentSuccess, 
        viewAddPaymentMethodModal 
    } = useSelector((state) => state.giving)
    const { profileChurch } = useSelector((state) => state.profileChurch)

    const [error, setError] = React.useState(false)

    const handleAddBank = () => {

        dispatch(createFinancialConnectionPaymentIntent({
            churchId: profileChurch._id
        }))
    }

    useEffect(() => {
        if(createFinancialConnectionPaymentIntentSuccess){
            dispatch(setViewAddPaymentMethodModal(''))
        }
    }, [createFinancialConnectionPaymentIntentSuccess])

    return (
        <APMCont>

            <ModalCloseButton 
                handler={() => dispatch(setViewAddPaymentMethodModal(''))}
                />

            { viewAddPaymentMethodModal === 'start' ?
                (
                    <h2>Select a payment method type</h2>
                )
                :
                (
                    <GoBackWrap>
                        <BackButtonWrap
                            onClick={() => dispatch(setViewAddPaymentMethodModal('start'))}
                            >
                            <IoArrowBackSharp />
                            Go back
                        </BackButtonWrap>
                    </GoBackWrap>
                )
            }


            { viewAddPaymentMethodModal === 'start' ?
                (
                    <SelectWrap>
                        <SelectItem onClick={handleAddBank}>
                            {
                                createFinancialConnectionPaymentIntentLoading ?
                                (
                                    <SpinnerIntermintentSmall />
                                )
                                :
                                (
                                    <>
                                        <CiBank style={{fontSize:'50px'}} />
                                        <span>Bank Account</span>                                    
                                    </>

                                )
                            }

                        </SelectItem>
                        <SelectItem onClick={() => setError(true)}>
                            <CiCreditCard1 style={{fontSize:'50px'}} />
                            <span>Credit or Debit Card</span>

                            {
                                error && 
                                (
                                    <p>Can not add a card payment at this time. Try again later.</p>
                                )
                            }
                        </SelectItem>
                    </SelectWrap>
                )
                : viewAddPaymentMethodModal === 'bank' ? 
                (
                    <AddBank />
                ) : viewAddPaymentMethodModal === 'card' &&
                (
                    <AddCard />
                )
            }

        </APMCont>
    )
}

const GoBackWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`

const BackButtonWrap = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    color: var(--lightThemeFontColor);

    svg{
        margin-right: 10px;
    }
    

`

const SelectItem = styled.div`  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    width: 50%;
    cursor: pointer;
    color: var(--lightThemeFontColor); 
    aspect-ratio: 2/1;
    border-radius: 10px;
    transition: all 0.5s ease;
    position: relative;

    p{
        position: absolute;
        color: red;
        font-size: 10px;
        margin-top: 5px;
        bottom: 10px;
        left: 10px;
        width: 100%;
    }

    &:hover{
        background-color: var(--lightThemeColor);
        color: #6c6c6c;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    }
`

const SelectWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
`



const APMCont = styled.div`
    width: 650px;
    min-height: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    position: relative;

    h2{
        margin-top: 20px;
        margin-bottom: 40px;
        font-size: 18px;
        font-weight: 500;
        color: var(--lightThemeFontColor);
    }

`
