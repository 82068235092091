import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import profileChurchService from './profileChurchService'
// import imageService from '../settings/imageService'

import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LanguageIcon from '@mui/icons-material/Language'; 
import { BsTwitterX } from "react-icons/bs";

import { modalSlice } from '../modal/modalSlice'

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

const churchFeatures = JSON.parse(localStorage.getItem('__C00348HDCH_8**%$'))
// const profileChurch = JSON.parse(sessionStorage.getItem('pc'))

const featuresDefaults = {
  main: [
    {index: 0, label: 'Posts', value: 'posts', enabled: true},
    {index: 1, label: 'Events', value: 'events', enabled: true},
    {index: 2, label: 'Announcements', value: 'announcements', enabled: true},
    {index: 3, label: 'Directory', value: 'directory', enabled: true},
  ],
  extra: [
    {index: 0, label: 'Videos', value: 'videos', enabled: true},
    {index: 1, label: 'Bulletin', value: 'bulletin', enabled: true},
    {index: 2, label: 'Polls', value: 'polls', enabled: true},
    {index: 3, label: 'About', value: 'about', enabled: true},
    {index: 4, label: 'Files', value: 'files', enabled: true},
  ]
}
 
const initialState = {
  // profileChurch: profileChurch ? profileChurch : [],
  profileChurch: [  ] ,
  // churchFeatures: churchFeatures ? churchFeatures : featuresDefaults,
  churchFeatures: {
    main: [],
    extra: []
  },
  churchFeatures0: {
    main: [],
    extra: []
  },
  areFeaturesEdited: false,
  arePublicSettingsSaved: false,
  areFeaturesSaved: false,
  activeSection:  'none',
  public: {}, // public viewable content to non-logged in users AND non MEMBER users.
  isChurchProfileError: false,
  isChurchProfileSuccess: false,
  isChurchProfileLoading: false,
  settings: {},
  settings0: {},
  features0: {
    main: [],
    extra: []
  },
  features: {
    main: [],
    extra: []
  },
  areSettingsEdited: false,
  isChurchSettingsLoading: false,
  isChurchSettingsError: false,
  isChurchSettingsSuccess: false,
  isChurchSettingUpdating: false,
  profileChurchImage: {},
  profileImagePreview: {},
  chosenImage: {},
  requestSent: [],
  requestsReceived: [],
  isImageUpdateSuccess: false,
  isImageUpdateLoading: false,
  isImageUpdateError: false,
  isMyProfileLoading: false,
  isMyProfileError: false,
  isMyProfileSuccess: false,
  churchMessage: '',
  hasUpdated: false,
  aboutSectionEditingLoading: false,
  aboutSectionEditingSuccess: false,
  aboutSectionEditingError: false,
  aboutSectionEditingMessage: '',
  sendingRequest: false,
  requestSentSuccess: false,
  requestSendError: false,
  aboutChurchModal: false,
  addPersonAddLinkButton: false,
  currentAddPersonLink: '',

  addingPersonInAboutLoading: false,
  addingPersonInAboutSuccess: false,
  addingPersonInAboutError: false,
  addingPersonInAboutMessage: '',

  deletingPersonInAboutLoading: false,
  deletingPersonInAboutSuccess: false,
  deletingPersonInAboutError: false,
  deletingPersonInAboutMessage: '',


  editChurchPersonSuccess: false,
  editingChurchDescriptionLoading: false,
  editingChurchDescriptionSuccess: false,
  editingChurchDescriptionError: false,
  editingChurchDescriptionMessage: '',
  linkIcons :{
    facebook: <FacebookIcon style={{ color: '#3b5998'}} />,
    twitter: <BsTwitterX style={{ color: 'black'}} />,
    instagram: <InstagramIcon style={{color: '#833AB4'}}  />,
    linkedin: <LinkedInIcon style={{color: '#0A66C2'}} />,
    youtube: <YouTubeIcon style={{color: '#CD201F'}} />,
    website: <LanguageIcon style={{color: 'grey'}} />,
  },
  addPersonLinks: {
    facebook: '',
    twitter: '',
    instagram: '',
    linkedin: '',
    youtube: '',
    website: '',
  },
  viewConfirmModaliInAbout: modalSlice.viewConfirmModal,

  myChurches: [],
  getMyChurchesLoading: false,
  getMyChurchesSuccess: false,
  getMyChurchesError: false,

  viewChurchFeedbackModal: false,
  viewFeedbackItem: false,
  feedbackItemData: {},

  churchFeedbackLoading: false,
  churchFeedbackSuccess: false,
  churchFeedbackError: false,
  churchFeedbackMessage: '',

  churchFeedbacks: [], // for churches only


}

// get user data
export const getProfileChurchData = createAsyncThunk(
    'profile/getProfileChurchData',
    async (profileChurchData, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await profileChurchService.getProfileChurchData(profileChurchData, token)
      } catch (error) {
        const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

export const getProfileChurchDataNotLoggedIn = createAsyncThunk(
  'profile/getProfileChurchDataNotLoggedIn',
  async (profileChurchData, thunkAPI) => {
    try {
      return await profileChurchService.getProfileChurchDataNotLoggedIn(profileChurchData)

    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    } 
  }
)

export const sendJoinRequest = createAsyncThunk(
  'profileChurch/joinRequest',
  async (profileChurchData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await profileChurchService.sendJoinRequest(profileChurchData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const addPersonAboutSectionX = createAsyncThunk(
  'profileChurch/addPersonAboutSection',
  async (profileChurchData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await profileChurchService.addPersonAboutSection(profileChurchData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)
export const editChurchPerson = createAsyncThunk(
  'profileChurch/editChurchPersonCard',
  async (profileChurchData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await profileChurchService.editChurchPerson(profileChurchData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const editChurchDescription = createAsyncThunk(
  'profileChurch/editChurchDescription',
  async (profileChurchData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await profileChurchService.editChurchDescription(profileChurchData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteChurchPerson = createAsyncThunk(
  'profileChurch/deleteChurchPerson',
  async (profileChurchData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await profileChurchService.deleteChurchPerson(profileChurchData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getSettings = createAsyncThunk(
  'profileChurch/getSettings',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await profileChurchService.getSettings(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateSettings = createAsyncThunk(
  'profileChurch/updateSettings',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await profileChurchService.updateSettings(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getMyChurches = createAsyncThunk(
  'profileChurch/getMyChurches',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await profileChurchService.getMyChurches(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const createChurchFeedback = createAsyncThunk(
  'profileChurch/createChurchFeedback',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await profileChurchService.createChurchFeedback(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getChurchFeedback = createAsyncThunk(
  'profileChurch/getChurchFeedback',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await profileChurchService.getChurchFeedback(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

  export const profileChurchSlice = createSlice({
    name: 'profileChurch',
    initialState,
    reducers: { 
      resetChurchProfile: (state) => initialState,

      resetErrors: (state) => {
        state.isChurchProfileError = false
        state.churchMessage = ''
      },

      setViewAboutChurchModal: (state, action) => {
        state.aboutChurchModal = action.payload
      },


      setAddPersonLinks: (state, action) => {
        state.addPersonLinks = {
          ...state.addPersonLinks,
          [action.payload.name]: action.payload.value
        }

        state.addPersonAddLinkButton = false
        state.currentAddPersonLink = ''

      },

      setSocialLinksFromEditing: (state, action) => {
        state.addPersonLinks = action.payload
      },

      setViewConfirmModaliInAbout: (state, action) => {
        state.viewConfirmModaliInAbout = action.payload
      },
      resetAddPersonLinks: (state) => {
        state.addPersonLinks = {
          facebook: '',
          twitter: '',
          instagram: '',
          linkedin: '',
          youtube: '',
          website: '',
        }
      },
      setAddPersonAddLinkButton: (state, action) => {
        state.addPersonAddLinkButton = action.payload.status
        state.currentAddPersonLink = action.payload.linkName
      },
      setCurrentAddPersonLink: (state, action) => {
        state.currentAddPersonLink = action.payload
      },
      setSettings: (state, action) => {
        if(action.payload.settingType === 'public') {
          state.settings = {
            ...state.settings,
            [action.payload.name]: action.payload.value
          }
        } else if(action.payload.settingType === 'features') {
          state.features = {
            ...state.features,
            [action.payload.name]: action.payload.value
          }
        }
      },

      setTestSettings: (state, action) => {

        if(JSON.stringify(state.features0) === JSON.stringify(action.payload)) {
          state.areFeaturesEdited = false
        } else {
          state.areFeaturesEdited = true

          state.features = action.payload
        }
      },

      resetSettings: (state) => {
        state.settings = JSON.parse(JSON.stringify(state.settings0))
      },
      setActiveSection: (state, action) => {
        state.activeSection = action.payload
      },
      setViewChurchFeedbackModal: (state, action) => {
        state.viewChurchFeedbackModal = action.payload
      },
      setViewFeedbackItemModal: (state, action) => {
        state.feedbackItemData = action.payload.data
        state.viewFeedbackItem = action.payload.view
      },
      resetChurchFeedback: (state) => {
        state.churchFeedbackLoading = false
        state.churchFeedbackSuccess = false
        state.churchFeedbackError = false
        state.churchFeedbackMessage = ''
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(getProfileChurchData.pending, (state) => {
          state.isChurchProfileLoading = true
          state.isChurchProfileSuccess = false
        })
        .addCase(getProfileChurchData.fulfilled, (state, action) => {
          sessionStorage.setItem('pc', JSON.stringify(action.payload))
          state.isChurchProfileLoading = false
          state.isChurchProfileSuccess = true
          state.profileChurch = action.payload
          state.profileChurch.public = action.payload.viewableContent
          state.profileChurchImage = action.payload.profileImg.imagePath2 ? action.payload.profileImg.imagePath2 : 'generic/2024-01-01T14-11-15.694Z-church.png'
          state.churchFeatures = action.payload.churchFeatures
          state.churchFeatures0 = action.payload.churchFeatures
        })
        .addCase(getProfileChurchData.rejected, (state, action) => {
          state.isChurchProfileLoading = false
          state.isChurchProfileError = true
          state.isChurchProfileSuccess = false
          state.churchMessage = action.payload
        })
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(sendJoinRequest.pending, (state) => {
          state.sendingRequest = true
        })
        .addCase(sendJoinRequest.fulfilled, (state, action) => {
          state.sendingRequest = false
          state.requestSentSuccess = true
        })
        .addCase(sendJoinRequest.rejected, (state, action) => {
          state.sendingRequest = false
          state.requestSentSuccess = false
          state.requestSendError = true
        }) 
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(addPersonAboutSectionX.pending, (state) => {  
          state.addingPersonInAboutLoading = true
        })
        .addCase(addPersonAboutSectionX.fulfilled, (state, action) => {
          state.addingPersonInAboutLoading = false
          state.addingPersonInAboutSuccess = true
          state.addingPersonInAboutMessage = action.payload

          state.profileChurch.organization.people.push(action.payload.organization.people.at(-1))

          state.aboutChurchModal = false
          state.addPersonLinks = {
            facebook: '',
            twitter: '',
            instagram: '',
            linkedin: '',
            youtube: '',
            website: '',
          }
          state.addPersonAddLinkButton = false
          state.currentAddPersonLink = ''

        } )
        .addCase(addPersonAboutSectionX.rejected, (state, action) => {
          state.addingPersonInAboutLoading = false
          state.addingPersonInAboutError = true
          state.addingPersonInAboutMessage = action.payload
        } ) 
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(editChurchPerson.pending, (state) => {  
          state.addingPersonInAboutLoading = true
        })
        .addCase(editChurchPerson.fulfilled, (state, action) => {
          state.addingPersonInAboutLoading = false
          state.addingPersonInAboutSuccess = true
          state.addingPersonInAboutMessage = action.payload
          state.editChurchPersonSuccess = true

          // find index of the card we are editing
          const index = state.profileChurch.organization.people.findIndex((person) => person._id === action.payload._id)

          // replace the old card with the new one
          state.profileChurch.organization.people[index] = action.payload


          state.aboutChurchModal = false
          state.addPersonLinks = {
            facebook: '',
            twitter: '',
            instagram: '',
            linkedin: '',
            youtube: '',
            website: '',
          }
          state.addPersonAddLinkButton = false
          state.currentAddPersonLink = ''

        } )
        .addCase(editChurchPerson.rejected, (state, action) => {
          state.addingPersonInAboutLoading = false
          state.addingPersonInAboutError = true
          state.addingPersonInAboutMessage = action.payload
        } ) 
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(deleteChurchPerson.pending, (state) => {  
          state.deletingPersonInAboutLoading = true
        })
        .addCase(deleteChurchPerson.fulfilled, (state, action) => {
          state.deletingPersonInAboutError = false
          state.deletingPersonInAboutSuccess = true
          state.deletingPersonInAboutLoading = false

          state.profileChurch.organization.people = action.payload
          state.viewConfirmModaliInAbout = false
        })
        .addCase(deleteChurchPerson.rejected, (state, action) => {
          state.addingPersonInAboutLoading = false
          state.addingPersonInAboutError = true 
        })


        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(editChurchDescription.pending, (state) => {
          state.editingChurchDescriptionLoading = true
        }
        )
        .addCase(editChurchDescription.fulfilled, (state, action) => {
          state.editingChurchDescriptionLoading = false
          state.editingChurchDescriptionSuccess = true
          state.editingChurchDescriptionMessage = action.payload
          state.profileChurch.organization.description = action.payload
        }
        )
        .addCase(editChurchDescription.rejected, (state, action) => {
          state.editingChurchDescriptionLoading = false
          state.editingChurchDescriptionError = true
          state.editingChurchDescriptionMessage = action.payload
        }
        )
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(getProfileChurchDataNotLoggedIn.pending, (state) => {
          state.isChurchProfileLoading = true
          state.isChurchProfileSuccess = false
        })
        .addCase(getProfileChurchDataNotLoggedIn.fulfilled, (state, action) => {
          state.isChurchProfileLoading = false
          state.isChurchProfileSuccess = true
          state.profileChurch = action.payload
          // state.myProfile = action.payload
          // state.profileChurchImage = action.payload.profileImg.imagePath2
        })
        .addCase(getProfileChurchDataNotLoggedIn.rejected, (state, action) => {
          state.isChurchProfileLoading = false
          state.isChurchProfileError = true
          state.isChurchProfileSuccess = false
          state.churchMessage = action.payload
        })
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(getSettings.pending, (state) => {
          state.isChurchSettingsLoading = true
          state.isChurchSettingsSuccess = false
        })
        .addCase(getSettings.fulfilled, (state, action) => {

          if(action.payload.settingType === 'public') {
            state.settings = action.payload.settings
            state.settings0 = action.payload.settings
          } else if(action.payload.settingType === 'features') {
            state.features0 = action.payload.settings
            state.features = action.payload.settings
          }
          state.isChurchSettingsLoading = false
          state.isChurchSettingsSuccess = true 
        })
        .addCase(getSettings.rejected, (state, action) => {
          state.isChurchSettingsLoading = false
          state.isChurchSettingsError = true
          state.isChurchSettingsSuccess = false
          state.churchMessage = action.payload
        })
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(updateSettings.pending, (state) => {
          state.isChurchSettingUpdating = true
          state.arePublicSettingsSaved = false
          state.areFeaturesSaved = false
        })
        .addCase(updateSettings.fulfilled, (state, action) => {

          state.isChurchSettingUpdating = false
          

          if(action.payload.settingType === 'public') {
            state.arePublicSettingsSaved = true
            state.settings0 = action.payload.settings
            state.settings = action.payload.settings
          } else if(action.payload.settingType === 'features') { 
            state.areFeaturesSaved = true
            state.churchFeatures = action.payload.settings
            state.churchFeatures0 = action.payload.settings
          }
        })
        .addCase(updateSettings.rejected, (state, action) => {
          state.isChurchSettingUpdating = false
          state.isChurchSettingsError = true
          state.isChurchSettingsSuccess = false
          state.churchMessage = action.payload
        })
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(getMyChurches.pending, (state) => {
          state.getMyChurchesLoading = true
          state.getMyChurchesSuccess = false
        })
        .addCase(getMyChurches.fulfilled, (state, action) => {
          state.getMyChurchesLoading = false
          state.getMyChurchesSuccess = true
          state.myChurches = action.payload.myChurches
        })
        .addCase(getMyChurches.rejected, (state, action) => {
          state.getMyChurchesLoading = false
          state.getMyChurchesError = true
          state.churchMessage = action.payload
        })
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(createChurchFeedback.pending, (state) => {
          state.churchFeedbackLoading = true 
        })
        .addCase(createChurchFeedback.fulfilled, (state, action) => {
          state.churchFeedbackLoading = false
          state.churchFeedbackSuccess = true
          state.churchFeedbackMessage = action.payload
        })
        .addCase(createChurchFeedback.rejected, (state, action) => {
          state.churchFeedbackLoading = false
          state.churchFeedbackError = true
          state.churchFeedbackMessage = action.payload
        })
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(getChurchFeedback.pending, (state) => {
          state.churchFeedbackLoading = true
        })
        .addCase(getChurchFeedback.fulfilled, (state, action) => {
          state.churchFeedbackLoading = false
          state.churchFeedbackSuccess = true
          state.churchFeedbacks = action.payload.feedbacks
        })
        .addCase(getChurchFeedback.rejected, (state, action) => {
          state.churchFeedbackLoading = false
          state.churchFeedbackError = true
          state.churchFeedbackMessage = action.payload
        })

    },
})

export const { 
  resetChurchProfile, 
  resetErrors, 
  setViewAboutChurchModal, 
  setAddPersonLinks, 
  resetAddPersonLinks,
  setAddPersonAddLinkButton,
  setCurrentAddPersonLink,
  setSocialLinksFromEditing,
  setSettings,
  resetSettings,
  setViewPrayerWall,
  setViewConfirmModaliInAbout,
  setActiveSection,
  setTestSettings,
  setViewChurchFeedbackModal,
  setViewFeedbackItemModal,
  resetChurchFeedback,
} = profileChurchSlice.actions
export default profileChurchSlice.reducer