import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import notifService from './notifService'

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

const initialState = {
  notifs: [],

  newMessages: [],

  isNotifError: false,
  isNotifSuccess: false,
  isNotifLoading: false,

  isNotifUpdateLoading: false,
  isNotifUpdateSuccess: false,
  isNotifUpdateError: false,
  notifMessage: '',
  lastNotifViewedIndex: null,
  lastNotifViewed: null,

  isSetLastNotifLoading: false,
  isSetLastNotifSuccess: false,
  isSetLastNotifError: false,
  setLastNotifMessage: '',

}

//---- sets a notification
export const createNotif = createAsyncThunk(
  'notifs/createNotif',
  async (notifData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await notifService.createNotif(notifData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//------
export const getNotifs = createAsyncThunk(
  'notifs/getNotifs',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await notifService.getNotifs(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)


//------
export const updateNotif = createAsyncThunk(
  'notifs/updateNotif',
  async (notif, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await notifService.updateNotif(notif, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)


//------
export const deleteNotif = createAsyncThunk(
  'notifs/deleteNotif',
  async (notif, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await notifService.deleteNotif(notif, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//------
export const deleteAllNotifs = createAsyncThunk(
  'notifs/deleteAllNotifs',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await notifService.deleteAllNotifs(token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//------
export const setLastNotif = createAsyncThunk(
  'notifs/setLastNotif',
  async (notif, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await notifService.setLastNotif(notif, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateMessageNotifs = createAsyncThunk(
  'notifs/updateMessageNotifs',
  async (data, thunkAPI) => {
    try { 
      const token = thunkAPI.getState().auth.user.token
      return await notifService.updateMessageNotifs(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const notifSlice = createSlice({
  name: 'notif',
  initialState,
  reducers: {
    notifReset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNotif.pending, (state) => {
        state.isNotifLoading = true
      })
      .addCase(createNotif.fulfilled, (state, action) => {
        state.isNotifLoading = false
        state.isNotifSuccess = true
        //   state.notifs.push(action.payload)
      })
      .addCase(createNotif.rejected, (state, action) => {
        state.isNotifLoading = false
        state.isNotifError = true
        state.notifMessage = action.payload
      })
      //------------------------------------------------------------------
      .addCase(getNotifs.pending, (state) => {
        state.isNotifLoading = true
      })
      .addCase(getNotifs.fulfilled, (state, action) => {
        state.isNotifLoading = false
        state.isNotifSuccess = true
        state.notifs = action.payload.notifs
        state.lastNotifViewedIndex = action.payload.lastNotifViewedIndex
        state.lastNotifViewed = action.payload.lastNotifViewed

        state.newMessages = action.payload.newMessages
        localStorage.setItem('_8ts2ccx00', JSON.stringify(action.payload.newMessages))
      })
      .addCase(getNotifs.rejected, (state, action) => {
        state.isNotifLoading = false
        state.isNotifError = true
        state.notifMessage = action.payload
      })
      //------------------------------------------------------------------
      .addCase(updateNotif.pending, (state) => {
        state.isNotifUpdateLoading = true
      })
      .addCase(updateNotif.fulfilled, (state, action) => {
        state.isNotifUpdateLoading = false
        state.isNotifUpdateSuccess = true
        // will change to isViewed = false and remove from state
        state.notifs = state.notifs.filter(
          (notif) => notif._id !== action.payload.id
        )
      })
      .addCase(updateNotif.rejected, (state, action) => {
        state.isNotifUpdateLoading = false
        state.isNotifError = true
        state.notifMessage = action.payload
      })
      //------------------------------------------------------------------
      .addCase(deleteNotif.pending, (state) => {
        state.isNotifUpdateLoading = true
      })
      .addCase(deleteNotif.fulfilled, (state, action) => {
        state.isNotifUpdateLoading = false
        state.isNotifUpdateSuccess = true
        state.notifs = state.notifs.filter(
          (notif) => notif._id !== action.payload.id
        )
      })
      .addCase(deleteNotif.rejected, (state, action) => {
        state.isNotifUpdateLoading = false
        state.isNotifUpdateSuccess = true
        state.notifMessage = action.payload
      })
      //------------------------------------------------------------------
      .addCase(deleteAllNotifs.pending, (state) => {
        state.isNotifUpdateLoading = true
      })
      .addCase(deleteAllNotifs.fulfilled, (state, action) => {
        state.isNotifUpdateLoading = false
        state.isNotifUpdateSuccess = true
        state.notifs = action.payload
      })
      .addCase(deleteAllNotifs.rejected, (state, action) => {
        state.isNotifUpdateLoading = false
        state.isNotifError = true
        state.notifMessage = action.payload
      })
    //------------------------------------------------------------------
    .addCase(setLastNotif.pending, (state) => {
      state.isSetLastNotifLoading = true
    })
    .addCase(setLastNotif.fulfilled, (state, action) => {
      state.isSetLastNotifLoading = false
      state.isSetLastNotifSuccess = true
      state.lastNotifViewedIndex = 0
      state.lastNotifViewed = action.payload.lastNotifViewed
    })
    .addCase(setLastNotif.rejected, (state, action) => {
      state.isSetLastNotifLoading = false
      state.isSetLastNotifError = true
      state.notifMessage = action.payload
    })
    //------------------------------------------------------------------
    .addCase(updateMessageNotifs.fulfilled, (state, action) => {
      state.newMessages = action.payload.newMessages
    })
  },
})

export const { notifReset } = notifSlice.actions
export default notifSlice.reducer