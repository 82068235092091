import styled from "styled-components";

export const PostItemButtonConatiner = styled.div` 
    cursor: pointer;
    width: fit-content; 
    height: 100%;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    align-items: center;
    padding: 10px;
    background-color: white;
    box-shadow: ${props => props.isFocused ? '0 0 5px #414141' : '0 0 10px #ccc'};
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    &:hover{
        transition: all 0.3s ease-in-out;
        box-shadow: 0 0 5px #7a7a7a;
    }
    @media (max-width: 768px) {
        width: fit-content;
    }   
`

export const FilterTextDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    color: var(--dFontColor);
    font-size: 11px;
`
export const PostSelectUl = styled.ul`
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    background-color: white;
    z-index: 99999;
    margin-top: 10px;
    border-radius: 10px;
    /* box shadow all around */
    box-shadow: 0 0 10px #afafaf;

    padding: 5px;
`

export const NoneContainer = styled.div`
    width: 100%;
    font-size: 13px;
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #ccc;
    color: grey;
    margin-bottom: 10px;
    position: relative;
    svg{
        margin-bottom: 10px;
        font-size: 18px;
    }
`