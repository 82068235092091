import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import EventNoteIcon from '@mui/icons-material/EventNote';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import BallotIcon from '@mui/icons-material/Ballot';
import CampaignIcon from '@mui/icons-material/Campaign';
import PollIcon from '@mui/icons-material/Poll';
import InfoIcon from '@mui/icons-material/Info';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ArticleIcon from '@mui/icons-material/Article';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { BiSolidDonateHeart  } from "react-icons/bi";

export const ICONS = {
    'posts': <DynamicFeedIcon/>,
    'announcements': <CampaignIcon/>,
    'events': <EventNoteIcon/>,
    'directory': <FolderSharedIcon/>,
    'bulletin': <BallotIcon/>,
    'polls': <PollIcon/>,
    'about': <InfoIcon/>,
    'files': <ArticleIcon/>,
    'videos': <VideoLibraryIcon/>,
    'feedback': <FeedbackIcon/>,
    'media': <PermMediaIcon/>,
    'giving': <BiSolidDonateHeart fontSize={22} />,
}