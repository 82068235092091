import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom' 
import { useState } from 'react'
import { imgUrl } from 'features/constants'
import useGetWindowSize from 'hooks/useGetWindowSize'
import styled from 'styled-components'
import TimeAgo from 'timeago-react'; 
import { MImage } from './zStyling.styled'
import { ConvItemContainer } from './zStyling.styled'

function ConversationItem({conversationId, image, fullName, date, lastMessage, member2member, isUnread}) {
    const [width, height] = useGetWindowSize()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isSelected, setIsSelected] = useState()
    //does not allow to click and fetch data on the same conversation as the current

    const { newMessages } = useSelector((state) => state.notifs)

    const handleView = () => { 
        localStorage.setItem(`${conversationId}`, JSON.stringify({
            image: image,
            name: fullName
        }))
        
        if(member2member){
            navigate(`/messages/${conversationId}/mm`)
        } else {
            navigate(`/messages/${conversationId}`)
        }
    }

    useEffect(() => {  
        if(window.location.pathname.includes(conversationId)){
            setIsSelected(true)
        }else{
            setIsSelected(false)
        }
    }, [window.location.pathname, conversationId])

    let profileImageToShow;
    try{ profileImageToShow = `${imgUrl}/profile/${image}` }
    catch(e){ profileImageToShow = null}

    let newDate = new Date(date)

    return (
        <ConvItemContainer
            isUnread={newMessages.includes(conversationId)}
            isSelected={isSelected}
            onClick={handleView}
            >
            <section className='blueIcon'></section>
            <ConversationInfo>
                {
                    profileImageToShow ? 
                    ( <MImage  src={`${imgUrl}/profile/${image}`} alt='generic.png' />)
                    :
                    ( <MImage  src={`${imgUrl}/profile/generic/2024-01-01T14-13-49.160Z-person.png`} alt='generic.png' />)
                }
                
                <NameAndLastMessage>
                    <span>{fullName}</span>
                    <p>{lastMessage ? lastMessage.slice(0, 35):'new message'}...</p>
                </NameAndLastMessage>
                
            </ConversationInfo>
            <CItemRight><TimeAgo datetime={newDate}/></CItemRight>
        </ConvItemContainer>
    )
}

export default ConversationItem


const ConversationInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: #595959;
`

const NameAndLastMessage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; 
    color: var(--lightThemeFontColor);
    font-size: 15px;
    font-weight: 500;
    span{
        color: var(--lightThemeFontColor);
        text-align: left;
        font-size: 13px;
        font-weight: 500;
    }
    p{
        font-size: 11px;
        font-weight: 400;
        color: var(--lightThemeSecondaryFontColor);
        text-align: left;
    }
`

const CItemRight = styled.div`
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start; 
    color: #818181;
    font-size: 9px;
`