import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getProfilePosts, setFetchPostAttributes } from '../../features/posts/postSlice'
import '../../css/postItemSelectDropDown.css'
import { MdEmojiPeople } from 'react-icons/md';
import { FaPrayingHands, FaHands } from 'react-icons/fa';
import { IoMdRefreshCircle } from 'react-icons/io';
import Spinner from '../Spinner';
import useToggleDropdown from '../../hooks/useToggleDropdown'  
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { PostItemButtonConatiner, FilterTextDiv, PostSelectUl } from './postComponents.styled';
import GlobalBtnWrapper from 'components/buttons/GlobalBtnWrapper';
import { btnHeight, btnWidth,  } from 'app/globalSettings'
import { language } from 'app/language';

function PostItemFilter( ) {
    const dispatch = useDispatch()
    const { churchName } = useParams()
    const ref1 = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref1)  
    const [isViewingAll, setIsViewingAll] = useState(true)
    const { profile } = useSelector((state) => state.profile)
    const [filter, setFilter] = useState(null)
    const { filterValue, myPostsPage } = useSelector((state) => state.posts)
    const { user } = useSelector((state) => state.auth)

    useEffect(() => {}, [isViewingAll, filter ])

    const handleClick = (value) => {

        if(value !== 'all' ){
            setIsViewingAll(false)
        }else{ 
            setIsViewingAll(true) 
        }
      
        dispatch(setFetchPostAttributes({
            type: 'filterValue',
            value: value
        })) 

        dispatch(getProfilePosts({
            churchName: churchName,
            currentPage: 1,
            filter: value
        }))

        console.log('postItemFilter')

    }

    return (
        <div style={{zIndex: '100'}}>
            <GlobalBtnWrapper 
                height={btnHeight}
                width={btnWidth}
                boxShadow={false}
                theme={'light'}
                >
                <PostItemButtonConatiner  
                    isFocused={isDropped}
                    ref={ref1}  
                    onClick={() => setIsDropped.toggle(ref1)}
                    >
                    <FilterTextDiv>
                        <FilterAltIcon style={{marginRight: '5px', fontSize: '18px'}} />
                        {
                            filterValue && filterValue !== 'all' ? 
                            (
                                <span>
                                    {
                                        language[filterValue][user.preferredLanguage]
                                    }
                                </span> 
                            )
                            :
                            (
                                <span>
                                    {
                                        language.filterPosts[user.preferredLanguage]
                                    }
                                </span>
                            )
                        }
                        
                    </FilterTextDiv>
                </PostItemButtonConatiner>
            </GlobalBtnWrapper>
                {isDropped && (
                    <PostSelectUl>
                        {!isViewingAll && (
                            <li className='postItemSelectLi' onClick={() => handleClick('all')} value="all">
                                <div>
                                    <IoMdRefreshCircle className='postTypeIcons' />
                                    {language["Show All"][user.preferredLanguage]}
                                </div>
                            </li>
                        )}
                        <li className='postItemSelectLi' onClick={() => handleClick('Prayer Request')} value="Prayer Request">
                            <div>
                                <FaPrayingHands className='postTypeIcons' />
                                {language['Prayers'][user.preferredLanguage]} 
                            </div>
                        </li>
                        <li className='postItemSelectLi' onClick={() => handleClick('Praise')}>
                            <div>
                                <FaHands className='postTypeIcons' />
                                {language['Praises'][user.preferredLanguage]} 
                            </div>
                        </li>
                        <li className='postItemSelectLi' onClick={() => handleClick('Testimony')}>
                            <div>
                                <MdEmojiPeople className='postTypeIcons' />
                                {language['Testimonies'][user.preferredLanguage]}
                            </div>
                        </li>        
                    </PostSelectUl>  
                )}

        </div>
    )
}

export default PostItemFilter
