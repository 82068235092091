import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { getChurchPayouts, setViewCreatePayoutModal } from 'features/giving/givingSlice'
import styled from 'styled-components'
import { TransactionsTableContainer } from 'components/giving/giving.styled' 
import ChurchTransactionItem from './ChurchTransactionsItem'
import PayoutsItem from './PayoutsItem'
import { Modal } from '@mui/material'
import RequestPayoutModal from './RequestPayoutModal'
import { FaMoneyBill1Wave } from "react-icons/fa6";

export default function Payouts() {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { profileChurch } = useSelector(state => state.profileChurch)
    const { 
        payouts,
        destInfo,
        payoutSchedule,
        connectAccountBalance,
        viewCreatePayoutModal,
        getTransactionsLoading,
        getTransactionsSuccess,
        getTransactionsError,
    } = useSelector(state => state.giving)

    useEffect(() => {
        dispatch(getChurchPayouts())
    }, [])

    const handleRequestPayout = () => {
        dispatch(setViewCreatePayoutModal(true))
    }

    // format prices with commas for the thousands
    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }


    // use this cool incrementor on load of balances
    // https://stackoverflow.com/questions/69948830/react-how-to-autoincrement-number-from-0-to-a-certain-value

    return (
        <TransactionsContainer> 
            <BalancesContainer>
                <span>Available Balance: 
                    <b style={{marginLeft: '10px', color: `${connectAccountBalance.available < 0 ? 'red' : 'green'}`}}
                        >
                        ${formatPrice(connectAccountBalance.available)}
                    </b>
                </span>
                <span style={{marginLeft: '20px'}}>
                    Pending Balance: 
                    <b style={{marginLeft: '10px', color: `${connectAccountBalance.available < 0 ? 'red' : 'green'}`}} >
                        ${formatPrice(connectAccountBalance.pending)}
                    </b>
                </span>
             </BalancesContainer>
            <StyledPayoutSettings> 
                <PayoutButton onClick={handleRequestPayout}  >
                    <FaMoneyBill1Wave style={{marginRight: '10px'}} />
                    Request Payout
                </PayoutButton> 
            </StyledPayoutSettings>


            <TransactionsTableContainer
                style={{marginTop: '20px'}}
                >
                <tbody>
                    <tr>
                        <th>Date</th>
                        <th>Amount</th> 
                        <th>Destination</th>
                        <th>Arrival Date</th>
                        <th>Status</th>
                    </tr>

                        { payouts.map((transaction) => 
                            (
                                <PayoutsItem 
                                    key={transaction.id} 
                                    data={transaction} 
                                    />
                            )
                        )}          
                    </tbody>
            </TransactionsTableContainer>

            <Modal
                open={viewCreatePayoutModal}
                onClose={() => dispatch(setViewCreatePayoutModal(false))}
                >
                <div
                    style={{
                        width: '100vw',
                        height: '100vh', 
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    >
                    <RequestPayoutModal />
                </div>
            </Modal>


        </TransactionsContainer>
    )
}

const BalancesContainer = styled.div`
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;

`

const PayoutButton = styled.button`
    padding: 10px 20px;
    border: none;
    outline: none;
    border-radius: 5px;
    background-color: #ccc;
    color: var(--lightThemeFontColor);
    font-size: 14px; 
    margin-top: 20px;
    cursor: pointer;
    transition: 0.3s; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &:hover{
        background-color: green;
        color: white;
    }

    svg{
        font-size: 21px;
    }


`

const StyledPayoutSettings = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    ;
    margin-bottom: 20px;

    h2{
        font-size: 14px;
        font-weight: 500;
        color: #767676;
    } 

`

const TransactionsContainer = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

`