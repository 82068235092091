import React from 'react'
import styled from 'styled-components'
import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Loader from "loaders/Loader";
import { GivingButton } from 'components/giving/giving.styled'

import { 
  setBillingView,
  setPaymentIntent,
  getUserData
} from 'features/billing/billingSlice'
import Subscriptions from './Subscriptions'
import Wallet from './Wallet'
import Transactions from './Transactions'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51MiUs9FN6wKpuMYyWcBgz0RQhp2CPlkedozNx1ry9KePdrAPH4UNy1uOG8wG6TA8Jat6B8uodfoxVlEMBO4Bv5tQ00LZRgV979')
// const stripePromise = loadStripe('pk_live_51MiUs9FN6wKpuMYyU94tH5fzMqjaNiUKmibLrkeXxWbDKoR4ruWLxQKqKg5LLabgwOi5nJcXcnmhOq7zZqPKfKPg00fckOQEUM');

export default function BillingComponent() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { clientSecret } = useSelector((state) => state.billing)

  useEffect(() => {
    dispatch(getUserData())
    dispatch(setPaymentIntent())
  }, [])

  const handleNav = (value) => {
    dispatch(setBillingView(value))
    navigate(`/settings?section=billing&view=${value}`)
  }

  const location = useLocation();
  const view =  new URLSearchParams(location.search).get('view') ? new URLSearchParams(location.search).get('view') : 'none'


  useEffect(() => {
    if(view === 'none'){
      handleNav('subscriptions')
    }
  }, [view])

  return (
    <BillingContainer>
      { stripePromise && clientSecret ? 
        (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <BillingHeader> Subscription Management </BillingHeader>

            <Btns>
              <GivingButton 
                active={view === 'subscriptions'}
                onClick={() => handleNav('subscriptions')}> 
                Subscription Details 
              </GivingButton>
              <GivingButton 
                active={view === 'wallet'}
                onClick={() => handleNav('wallet')}
                > 
                Payment Methods 
              </GivingButton>
              <GivingButton 
                active={view === 'transactions'}
                onClick={() => handleNav('transactions')} 
                > 
                View Transactions 
              </GivingButton>
            </Btns>
            

            <div
              style={{
                paddingLeft: '20px',
              }}
              >
              { view === 'none' || view === 'subscriptions'? 
                (
                  <Subscriptions />
                )
                : view === 'wallet' ?
                (
                  < Wallet />
                )
                : view === 'transactions' &&
                (
                  <Transactions />
                ) 
              }              
            </div>

          </Elements>
        )
        :
        (
          <BillingContainer style={{ flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center' }}>
            <Loader />
          </BillingContainer>
        ) 
      }




    </BillingContainer>
  )
}

const BillingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--lightThemeFontColor);
`


const BillingHeader = styled.div`
  border-bottom: 1px solid var(--borderColor);
  padding: 20px;
`

const Btns = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row; 
`
 