import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux' 
import { toast } from 'react-toastify'
import styled from 'styled-components'; 
import Loader from 'loaders/Loader';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { imgUrl } from 'features/constants';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { getRegInvData, registerUserFromInvite } from 'features/memberships/membershipSlice';
import SpinnerSmall from 'components/SpinnerSmall';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password'; 


export default function RegisterFromInvite() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { addKey, email} = useParams() 
    const [errorField, setErrorField] = useState('')

    const {
        getRegInvDataLding,
        getRegInvDataSuccess,
        getRegInvDataError,
        regInvData,
        registerUserFromInviteLoading,
        registerUserFromInviteSuccess,
        registerUserFromInviteError,
        getRegInvDataMessage,
        registerUserFromInviteMessage
    } = useSelector(state => state.memberships2)
     

    const [formData, setFormData] = useState({
        password: '',
        password2: '',
        displayName: '', 
    })

    const { password, password2, displayName } = formData

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        dispatch(getRegInvData( {
            email: email,
            addKey: addKey
        }))
    }, [])

    useEffect(() => {
        if(registerUserFromInviteSuccess){
            toast.success('Account created successfully')
            navigate('/login')
        }
    }, [registerUserFromInviteSuccess])

    useEffect(() => {
        if(registerUserFromInviteError){ 
            toast.error(registerUserFromInviteMessage)
        }
    }, [registerUserFromInviteError, registerUserFromInviteMessage])

    useEffect(() => {
        if(getRegInvDataError){

            console.log(getRegInvDataMessage)
            toast.error(getRegInvDataMessage)

        }
    } , [getRegInvDataError, getRegInvDataMessage])
    

    const handleRegister = () => {

        if(!displayName){
            toast.error('Name is required')
            setErrorField('displayName')
            return
        }

        if(password !== password2){
            toast.error('Check passwords')
            setErrorField('password')
            return
        }

        if(password.length < 8 || password2.length < 8 ){
            toast.error('Password must be at least 8 characters long')
            setErrorField('password')
            return
        }

        dispatch(registerUserFromInvite({
            email: email,
            addKey: addKey,
            password: password, 
            fullName: displayName
        }))
        

    }

    if(getRegInvDataLding){
        return <Loader />
    }

    return (
        <FPContainer> 
            <ResetPForm>
                <StyledH2>
                    <PersonOutlineIcon />
                    <span>Register Your Account</span>
                </StyledH2>

                {
                    getRegInvDataSuccess && regInvData &&
                    (
                        <div
                            style={{ 
                                marginBottom: '20px'
                            }}
                            >
                            <img 
                                src={`${imgUrl}/profile/${regInvData.profileImg.imagePath2}`} 
                                alt="profile pic" 
                                style={{
                                    width: '80px', 
                                    height: '80px', 
                                    borderRadius: '50%', 
                                    objectFit: 'cover'
                                }} 
                                />
                            <p>You are joining {regInvData.fullName},</p> 
                        </div>
                    )
                }

                <FormGroup
                    formError={errorField === 'displayName'}
                    > 
                    <DriveFileRenameOutlineIcon />
                    <input
                        type='text'
                        className='form-control'
                        autoComplete="new-password"
                        id='displayName'
                        name='displayName'
                        value={displayName}
                        placeholder='Enter your name'
                        onChange={onChange}
                        />
                </FormGroup>
                <FormGroup>
                    <EmailIcon />
                    <input
                        type='text'
                        className='form-control'
                        autoComplete="new-password"
                        id='email'
                        name='email'
                        value={email}
                        placeholder='Enter your email' 
                        />
                </FormGroup>
                <FormGroup
                    formError={errorField === 'password'}
                    >
                    <PasswordIcon 
                        style={{
                            fontSize: '20px'
                        }}
                        />

                    <input
                        type='password'
                        className='form-control'
                        autoComplete="new-password"
                        id='password'
                        name='password'
                        value={password}
                        placeholder='Enter your password'
                        onChange={onChange}
                        />
                </FormGroup>
                <FormGroup
                    formError={errorField === 'password'}  
                    >

                    <PasswordIcon
                        style={{
                            fontSize: '20px'
                        }}
                        />
                    <input
                        type='password'
                        className='form-control'
                        id='password2'
                        name='password2'
                        value={password2}
                        placeholder='Re-enter your password'
                        onChange={onChange}
                        />
                </FormGroup>

                <SubmitButtonP 
                    disabled={registerUserFromInviteLoading}
                    onClick={handleRegister}
                    > 
                    {
                        registerUserFromInviteLoading ?
                        <SpinnerSmall />
                        :
                        <span>Register</span>
                    }
                </SubmitButtonP>
        </ResetPForm>
        </FPContainer>
    )
}

const FPContainer = styled.div`
    margin-top: 30px;
    width: 100%;

    @media (max-width: 768px){
        width: 100vw;
    }
`

const FormGroup = styled.div`

    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background-color: white;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #ccc;
    padding: 10px;
    border: 1px solid ${props => props.formError ? 'red':'transparent'};

    input{
        padding: 10px;
        width: 100%;
        border: none;
        outline: none;
    }
`

const ResetPForm = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    margin: 0 auto;
    border-radius: 10px;
    padding: 30px;
    background-color: var(--lightThemeColor); 
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    color: var(--lightThemeFontColor);
    font-family: 'Roboto', sans-serif;
    font-weight: 300;


    form{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end; 
    }


    @media (max-width: 768px){
        width: 100%;
    }
`


const SubmitButtonP = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px #ccc;
    height: 40px;
    width: 100px;
    border-radius: 10px;
    color: white;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-top: 10px;
    background-color: var(--themeColor);
    
    pointer-events: ${props => props.disabled ? 'none':'all'};

`

const StyledH2 = styled.h2`
    margin-bottom: 30px;
    text-align: center; 
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: var(--lightThemeFontColor);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg{
        margin-right: 10px;
        font-size: 30px;
    }

    @media (max-width: 768px){
        font-size: 20px;
        width: 100%;

        svg{
            font-size: 25px;
        }
    }

`   