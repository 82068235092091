import React from 'react'
import styled from 'styled-components'
import { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import  useToggleDropdown  from 'hooks/useToggleDropdown' 
import SortIcon from '@mui/icons-material/Sort';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { setSortValue } from 'features/directory/directorySlice' 
import { getDirectory } from 'features/directory/directorySlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownZA, faArrowUpAZ} from '@fortawesome/free-solid-svg-icons'
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { directoryLanguage } from './directoryLanguage'

function DirectorySort() {

    const dispatch = useDispatch()
    const ref1 = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref1)
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const [arrowIconHovered, setArrowIconHovered] = useState(null)
    const { sortValue, sortArrays, sortLabels } = useSelector((state) => state.directory) 
    const { user } = useSelector((state) => state.auth)

    const handleClick = (value) => {
        // default sort value = 'nameAZ'
        let newSortValue;

        if(sortValue === sortArrays[`${value}`][0]){
            newSortValue = sortArrays[`${value}`][1]
        }else{
            newSortValue = sortArrays[`${value}`][0]
        }

        dispatch(setSortValue(newSortValue))

        // this should also get stored to local storage so that 
        // when the user refreshes the page, the same filter is still applied

        dispatch(getDirectory({
            ids: profileChurch.churchDirectoryList,
            currentPage: 1,
            sort: newSortValue
        }))
    }

  return ( 
    <SelectDropDownCotnainer>
        <FilterContainer 
            isFocused={isDropped}
            ref={ref1}  
            onClick={() => setIsDropped.toggle(ref1)}>
            <FilterTextDiv>

                <SortIcon style={{marginRight: '5px'}} />
                {
                    sortValue ? 
                    (

                        <span>  
                            {
                                directoryLanguage[`${sortValue}`][user.preferredLanguage]
                            }
                        </span>
                    )
                    :
                    (
                        <span>
                            {directoryLanguage.sort[user.preferredLanguage]}
                        </span>
                    )
                }

            </FilterTextDiv>
        </FilterContainer>
        {isDropped && (
            <PostSelectUl> 

                {/* 
                    by default directory will be sorted by family nameAZ first
                    it could also be sorted randomly each time a user visits the page
                    that way it will be fair to everyone
                */}

                <li className='postItemSelectLi' 
                    onMouseEnter={()=> setArrowIconHovered('name')}
                    onMouseLeave={()=> setArrowIconHovered(null)}
                    onClick={() => handleClick('name')}
                    >
                    <div>
                        <FontAwesomeIcon 
                            className='postTypeIcons' 
                            icon={sortValue === 'nameZA' ? faArrowDownZA : faArrowUpAZ  } />
                        <div>
                            <span>
                                {directoryLanguage.familyName[user.preferredLanguage]}
                            </span>
                            <ArrowIcon >
                                {
                                    (sortValue === 'nameZA' || sortValue === 'nameAZ') && 
                                        (
                                            sortValue === 'nameAZ' ?
                                            (
                                                arrowIconHovered === 'name' ? <SouthIcon />:<NorthIcon /> 
                                            )
                                            :
                                            (
                                                arrowIconHovered === 'name' ? <SouthIcon /> : <NorthIcon />
                                            )
                                        )
                                }
                            </ArrowIcon>
                        </div>
                    </div>
                </li>

                <li className='postItemSelectLi'  
                    onMouseEnter={()=> setArrowIconHovered('date')}
                    onMouseLeave={()=> setArrowIconHovered(null)}
                    onClick={() => handleClick('date')} 
                    >
                    <div>
                        <DateRangeIcon className='postTypeIcons' />
                        <div>
                            <span>
                                {directoryLanguage.dateAdded[user.preferredLanguage]}
                            </span>
                            <ArrowIcon >
                                {
                                    (sortValue === 'dateNewestFirst' || sortValue === 'dateOldestFirst') && 
                                        (
                                            sortValue === 'dateNewestFirst' ?
                                            (
                                                arrowIconHovered === 'date' ? <NorthIcon />: <SouthIcon />
                                            )
                                            :
                                            (
                                                arrowIconHovered === 'date' ? <SouthIcon /> : <NorthIcon />
                                            )
                                        )
                                }
                            </ArrowIcon>
                        </div>
                    </div>
                </li>
                
            </PostSelectUl>  
        )}
    </SelectDropDownCotnainer> 
  )
}


export default DirectorySort

const SelectDropDownCotnainer = styled.div`
    
    @media (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

    }
`

const FilterTextDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    color: var(--dFontColor);
    font-size: 13px;
`

const ArrowIcon = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 30px;
    height:30px;

`

const FilterContainer = styled.div`
    cursor: pointer;
    width: fit-content;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    align-items: center;
    padding: 10px;
    padding-right: 15px;
    background-color: white;
    box-shadow: ${props => props.isFocused ? '0 0 5px #414141' : '0 0 10px #ccc'}; 
    /* 0 0 10px #ccc; */
    border-radius: 8px;
    margin-left: 5px;
    &:hover{
        transition: all 0.3s ease-in-out;
        box-shadow: 0 0 5px #7e7e7e;
    }
    @media (max-width: 768px) {
      align-self: flex-start;
      margin-left: 0;
      justify-self: flex-start;
      margin-top: 0px;
      font-size: 11px;
      width: 140px;
      padding: 5px;
    }
`

const PostSelectUl = styled.ul`
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: 230px;
    width: fit-content;
    background-color: white;
    z-index: 9;
    margin-top: 10px;
    border-radius: 10px;
    /* box shadow all around */
    box-shadow: 0 0 10px #afafaf; 
    padding: 5px;
    @media (max-width: 768px) {
        margin-top: 60px;
        border-radius: 0px 10px 10px 10px;
    }
`
