import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkIsMemberAStripeCustomer, setView } from 'features/giving/givingSlice'
import styled from 'styled-components'
import GiveToChurch from './GiveToChurch'
import Transactions from './Transactions'
import PaymentMethods from './PaymentMethods' 
import { useNavigate, useLocation } from 'react-router-dom'
import Loader from 'loaders/Loader'
import { GivingButton } from './giving.styled'
import Campaigns from './campaigns/Campaigns'
import { setViewCreateGivingCampaignModal } from 'features/givingCampaigns/givingCampaignSlice'
import { Modal } from '@mui/material'
import CreateCampaignModal from './campaigns/CreateCampaignModal'
import { DotEle, DotsWrap, DotsButton2, DotsButton } from 'components/dots/dots.styled'
import { BsThreeDots } from "react-icons/bs";
import useToggleDropdown from '../../hooks/useToggleDropdown';


export default function Giving() {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { viewCreateGivingCampaignModal } = useSelector((state) => state.givingCampaign)

    const view = new URLSearchParams(location.search).get('v') ? new URLSearchParams(location.search).get('v') : 'none'
    const dotsRef = useRef(null)

    const {  
        isCustomer,  
        checkIsMemberAStripeCustomerError,
        checkIsMemberAStripeCustomerSuccess,
        checkIsMemberAStripeCustomerLoading,
        createGivingCampaignSuccess,
        createGivingCampaignError
     } = useSelector((state) => state.giving)

    const handleCreateCampaign = () => {
        dispatch(setViewCreateGivingCampaignModal(true))
    }

    useEffect(() => { 
        if(!user.isOrganization){
            dispatch(checkIsMemberAStripeCustomer({ churchId: profileChurch._id })) 
        }     
    }, [])

    const handleCreateCustomer = () => {
        console.log('creating customer')
    }

    const handleView = (value) => { 
        if(value === 'give'){
            navigate('?section=giving&v=give&status=initialize')
        }else{
            navigate(`?section=giving&v=${value}`) 
        } 
    }

    useEffect(() => { 
        if(!view || view === 'none'){
            navigate('?section=giving&v=campaigns')
        }  
    }, [view, navigate])

    const [isDotsDropped, setIsDotsDropped] = useToggleDropdown(dotsRef)


    const handleDotsDropdown = (e) => {
        e.stopPropagation()
        setIsDotsDropped.toggle(dotsRef) 
    }
    

    if(checkIsMemberAStripeCustomerError){
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100px',
                    width: '100%',
                    color: 'grey'
                }}
                >
                    {checkIsMemberAStripeCustomerError}
            </div>
        )
    }else{ 

        return (
            <MediaPageContainer>
                <ButtonWrap> 
                    {
                        checkIsMemberAStripeCustomerLoading ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100px',
                                    width: '100%'
                                }}
                                >
                                <Loader />
                            </div>
                            
                        )
                        :  checkIsMemberAStripeCustomerError ? 
                        (
                            <GivingButton
                            // active={true}
                                onClick={handleCreateCustomer}

                            >
                                Start Giving To {profileChurch.fullName}
                            </GivingButton>
                        )
                        : isCustomer && !user.isOrganization ?
                        (
                            <GivingTop>
                                <ViewBtns>
                                    <GivingButton
                                        active={view === 'give'}
                                        onClick={() => handleView('give')}
                                        >
                                        Give to your church
                                    </GivingButton>
                                    <GivingButton
                                        active={view === 'campaigns'}
                                        onClick={() => handleView('campaigns')}
                                        >
                                        Giving Campaigns
                                    </GivingButton> 
                                </ViewBtns>
                                <DotsContainer>
                                    <DotsButton2
                                        ref={dotsRef}
                                        onClick={handleDotsDropdown}
                                        ><BsThreeDots />
                                    </DotsButton2> 
                                    {
                                        isDotsDropped && (
                                            <DotsWrap>
                                                <DotEle
                                                    onClick={() => handleView('transactions')}
                                                    >
                                                    View Transactions
                                                </DotEle>
                                                <DotEle
                                                    onClick={() => handleView('payment-methods')}
                                                    >
                                                    Payment Methods
                                                </DotEle>
                                            </DotsWrap>                                    
                                        )    
                                    }

                                </DotsContainer> 
                            </GivingTop>
                        )
                        : user.isOrganization &&  
                        (
                            <>
                                <GivingButton
                                    active={view === 'campaigns'}
                                    onClick={() => handleView('campaigns')}
                                    >
                                    Campaigns
                                </GivingButton>    

                                <GivingButton 
                                    onClick={handleCreateCampaign}
                                    >
                                    Create Campaign
                                </GivingButton> 
                            </> 
                        )
                    } 
                </ButtonWrap>
    

                {
                    !checkIsMemberAStripeCustomerError && !checkIsMemberAStripeCustomerLoading && (
                        view === 'give' ? 
                        ( 
                            <GiveToChurch />
                        )
                        : view === 'campaigns' ?
                        (
                            <Campaigns />
                        )
                        : view === 'transactions' ?
                        (
                            <Transactions />
                        )
                        : view === 'payment-methods' &&
                        (
                            <PaymentMethods />
                        ) 
                    )
                } 

                <Modal
                    open={viewCreateGivingCampaignModal}
                    onClose={() => dispatch(setViewCreateGivingCampaignModal(false))}
                    >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '99vw',
                            height: '99vh', 
                        
                        }}
                        >
                            <CreateCampaignModal />

                    </div>
                </Modal> 
            </MediaPageContainer>
        )
    }
}

const DotsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100%;
`


const ViewBtns = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%; 
`

const GivingTop = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; 
`

const MediaPageContainer = styled.div`
    height: 100%;
    padding: 10px;
`
const ButtonWrap = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;  

`