import React from 'react'
import { NoneContainer } from './postComponents.styled'
import { useNavigate } from 'react-router-dom'
import PeopleIcon from '@mui/icons-material/People';
import SendIcon from '@mui/icons-material/Send';
import styled from 'styled-components'
import { Tooltip } from '@mui/material';

export default function NoMembers() {

    const navigate = useNavigate()

    return (
        <NoneContainer>
            < PeopleIcon />
            <span>You don't have any members</span>

            <Tooltip title="Invite multiple members by email" placement="top">
            <InviteButton
                onClick={() => navigate('/settings?section=invite')}
                >
                    <SendIcon />
                    <p>Invite</p>
            </InviteButton>
            </Tooltip>
                

                
        </NoneContainer>
    )
}

const InviteButton = styled.div`

    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 35px; 
    bottom: 10px;
    right: 10px;
    padding: 5px 14px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #ffffff;
    font-size: 11px; 
    color: #65676b;
    box-shadow: 0px 0px 4px #ccc; 
    transition: all 0.3s ease-in-out;

    p{
        height: fit-content;
        padding: 0px;
        margin: 0px; 
    }

    &:hover {
        box-shadow: 0px 0px 4px #949494; 
    }
    svg{ 
        
        font-size: 16px;
        height: fit-content;
        padding: 0px;
        margin: 0px; 
        margin-right: 6px;
    }

`