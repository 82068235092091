import { useEffect } from 'react'
import '../css/pages/settings.css'
import { useNavigate } from 'react-router-dom'
import { useSelector, } from 'react-redux'
import styled from 'styled-components'
import UserExtras from '../components/userProfile/UserExtras'
import ChurchExtras from '../components/userProfile/ChurchExtras'
import NotifFeed from 'components/notifications/NotifFeed'

export default function Notifications() {
  const navigate = useNavigate()
  const { user} = useSelector((state) => state.auth)
  
  useEffect(() => {
    if(!user){navigate('/login')}
    
  }, [user, navigate])
  
  return (

    <NotificationsPageContainer>
      <SideBar>
        { user.isOrganization || user.isChurch ? 
            (
                <ChurchExtras/>
            )
            :
            (
                <UserExtras/>
            )        
        }
      </SideBar>

      <NotificationsElementsContainer>
        <NotificationsHeading>Notifications</NotificationsHeading>

        <NotifFeed />

      </NotificationsElementsContainer>
</NotificationsPageContainer>
)
}


const NotificationsPageContainer = styled.div`
    display: flex;
    width: 1300px;
    flex-direction: row;
    justify-content: flex-start; 
    margin: 0 auto;
    @media (max-width: 868px) {
      width: 100%;
    }
`

const SideBar = styled.div`
  word-wrap: normal;
  display: flex;
  flex-direction: column;
  width: fit-content;
`

const NotificationsElementsContainer = styled.div`
  width: 600px;
  display: flex;
  border-right: 1px solid var(--borderColor);
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
    border-right: none
  }
`

const NotificationsHeading = styled.div`
  width: 100%;
  /* border-bottom: 1px solid var(--borderColor); */
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px; 
  padding-left: 20px;
  font-weight: 400;
  color: var(--lightThemeFontColor);
  font-family: 'Roboto', sans-serif;
`