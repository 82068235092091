import axios from 'axios'

import { baseUrl } from '../constants'
const URL = baseUrl

const API_URL = '/api/posts/'
const API_URL_SETPOST = '/api/posts/setPost'
const API_URL_LIKE = '/api/posts/like'
const API_URL_UNLIKE = '/api/posts/unlike'
const API_URL_DELETE = '/api/posts/deletePost'
const API_URL_GETPROFILEPOSTS = '/api/posts/getProfilePosts'
const API_URL_SAVE = '/api/posts/save'
const TEST = '/api/posts/testPostOpenAi'

//for sending the token
const config = (token) => { 
  return { 
    withCredentials: true,
    headers: { 
      Authorization: `Bearer ${token}`, 
    }, 
  } 
}
const setPostOrganization = async (postData, token) => {
  const response = await axios.post(`${URL+API_URL}organization`, postData, config(token))
  return response.data
}

const setPost = async (postData, token) => {
  const response = await axios.post(URL+API_URL_SETPOST, postData, config(token))
  return response.data
}

const setComment = async (commentData, token) => {
  const response = await axios.post(`${URL+API_URL}comments`, commentData, config(token))
  return response.data
}


const getOrganizationPosts = async (organization, token) => {
  const response = await axios.post(`${URL+API_URL}getOrganizationPosts`, organization, config(token))
  return response.data
}

const getProfilePosts = async (profileData, token) => {
  const response = await axios.post(URL+API_URL_GETPROFILEPOSTS, profileData, config(token))
  return response.data
}

const getMyPosts = async (postType, token) => {
  const response = await axios.post(`${URL+API_URL}getMyPosts`, postType, config(token))
  return response.data
}

const deletePost = async (postData, token) => {
  const response = await axios.post(URL+API_URL_DELETE, postData, config(token))
  return response.data
}

const likePost = async (data, token) => {
  const response = await axios.post(URL+API_URL_LIKE, data, config(token))
  return response.data
}

const unlikePost = async (data, token) => {
  const response = await axios.post(URL+API_URL_UNLIKE, data, config(token))
  return response.data
}
const savePost = async (data, token) => {
  const response = await axios.post(URL+API_URL_SAVE, data, config(token))
  return response.data
}

const testPostOpenAi = async (data, token) => {
  const response = await axios.post(URL+TEST, data, config(token))
  return response.data
}

const seeWhoIsPraying = async (data, token) => {
  const response = await axios.post(URL+'/api/posts/seeWhoIsPraying', data, config(token))
  return response.data
}

const sendQuickMessage = async (data, token) => {
  const response = await axios.post(URL+'/api/conversations/sendQuickMessage', data, config(token))
  return response.data
}


const postService = {
  setPostOrganization,
  setPost,
  setComment,
  getOrganizationPosts,
  deletePost,
  likePost,
  unlikePost,
  getMyPosts,
  getProfilePosts,
  savePost,
  testPostOpenAi,
  seeWhoIsPraying,
  sendQuickMessage
}
export default postService
