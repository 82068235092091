import React, {useState } from 'react'
import styled from 'styled-components';
import { TopHeaderSettings } from '../settings.styled';
import PublicIcon from '@mui/icons-material/Public';
import ProfilePublicSettings from './ProfilePublicSettings';    
import ProfileFeatureSettings from './ProfileFeatureSettings';
import GroupsIcon from '@mui/icons-material/Groups';
import { settingsLanguage } from '../settingsLanguage';
import { useSelector } from 'react-redux';

export default function ProfileSettingsContainer() {

    const [view, setView] = useState('public')
    const { user } = useSelector((state) => state.auth)

    const handleView = (value) => {
        setView(value)
    }

    return (
        <ChurchProfileSettingsContainer>

            <TopHeaderSettings>
                {
                    settingsLanguage.profileSettingsControl[user.preferredLanguage]
                }
            </TopHeaderSettings> 

            <MainSettingsContainer>
                <TopButtonSectionS>
                    <SettingsButton 
                        onClick={()=>handleView('public')}
                        active={view === 'public'}
                        btnSide={'left'}
                        >
                        <PublicIcon />
                        <span>
                            {
                                settingsLanguage.publicView[user.preferredLanguage]
                            }
                        </span>
                    </SettingsButton>

                    <SettingsButton 
                        onClick={()=>handleView('member')}
                        active={view === 'member'}
                        btnSide={'right'}
                        >
                        <GroupsIcon />
                        <span>
                            {
                                settingsLanguage.memberView[user.preferredLanguage]
                            }
                        </span>
                    </SettingsButton>
                </TopButtonSectionS>

            </MainSettingsContainer>


            {
                view === 'public' ? 
                (
                    <ProfilePublicSettings />
                )
                : view === 'member' &&
                (
                    <ProfileFeatureSettings />
                )
            }

        </ChurchProfileSettingsContainer>
    )
}

const ChurchProfileSettingsContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: 'Roboto', sans-serif;
    color: var(--lightThemeFontColor);
    font-weight: 300; 

    p{
        margin-left: 40px;
        text-align: left;
        font-size: 13px;
        color: var(--dFontColor);
        font-weight: 300;
        font-family: 'Roboto', sans-serif;
    }
    @media (max-width: 768px) {
        p{ margin-left: 5px; }
    } 
`
const MainSettingsContainer = styled.div`
    width: 100%;
    height: fit-content;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const TopButtonSectionS = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
`

const SettingsButton = styled.div`
    background-color: white; 
    box-shadow: 0 0 10px #ccc;
    border-radius: 5px;
    border: ${props => props.active ? '1px solid var(--themeColor)' : '1px solid white'};
    margin-left: ${props => props.btnSide === 'right' ? '10px' : '0px'};
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    span{
        margin-left: 5px;
        font-size: 13px;    
        font-weight: 300;
        font-family: 'Roboto', sans-serif;
    }
`