import React, { useEffect } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import styled from 'styled-components' 
import Modal from '@mui/material/Modal';  
import { useSelector, useDispatch } from 'react-redux'
import Tooltip from '@mui/material/Tooltip';
import { getEvents, setViewEventModal } from 'features/events/eventSlice'
import LoaderSmall from 'loaders/LoaderSmall'

const localizer = momentLocalizer(moment)

export default function CalendarComponent() {

    const dispatch = useDispatch()

    const { user, admin } = useSelector(state => state.auth)
    const { eventsList, isEventLoading } = useSelector(state => state.events)
    const { profileChurch } = useSelector(state => state.profileChurch)
    const [list, setDates] = React.useState([])
    
    const handleSelectEvent = (event) => {
        console.log(event)
    }

    const handleSelectSlot = (event) => {

        if(!admin[profileChurch._id] && !user.isOrganization){
            return
        }else{
            dispatch(setViewEventModal({  
                // startDate: event.start,  
            }))         
        }
    }

    useEffect(() => {
        if(eventsList.length === 0){ 

            dispatch(getEvents({
                ids: profileChurch._id
            }))
        }
    }, [])

    let setDatesFunction = () => {
        let dates = []

        eventsList.map((event, index) => {
            const start = event.startDate.split('-')
            const end = event.endDate ? event.endDate.split('-') : null

            dates.push({
                id: index,
                title: event.eventName,
                allDay: false,
                // start: new Date (Number(start[0]), Number(start[1])-1, Number(start[2].slice(0,2))),
                // end: end ? new Date (Number(end[0]), Number(end[1])-1, Number(end[2].slice(0,2))) : new Date (Number(start[0]), Number(start[1])-1, Number(start[2].slice(0,2))),
                start: new Date (event.startDate),
                end: end ? new Date (event.endDate) : new Date (event.startDate),
            
            })
        })

        setDates(dates)
    }
    
    useEffect(() => {
        if(eventsList.length > 0){
            setDatesFunction()
        }
        
    }, [eventsList])

    return (
        <CalendarContainer>
            <Calendar
                localizer={localizer}
                events={list}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                selectable
                />
{/* 
            <Tooltip title="Sync Calendar"> 
                <SyncCalendar> 
                    < SyncIcon /> 
                </SyncCalendar>
            </Tooltip> */}

            <Modal
                open={isEventLoading}
                onClose={() => {}}
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                
                <LoaderSmall />
                
            </Modal>

        </CalendarContainer>
    )
}

const CalendarContainer = styled.div`  



`

const LoadingModal  = styled.div`
    background-color: white;
    padding: 20px 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const SyncCalendar = styled.div` 
    display: flex;
    justify-self: flex-end;
    align-self: flex-end;
    margin-top: 10px;
    border: 1px solid var(--lightThemeFontColor);
    width: 80px;
    padding: 10px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg{
        
        font-size: 20px;
        color: var(--lightThemeFontColor);
    }
`