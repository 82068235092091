
import { useState, useEffect } from 'react'

const defaultFallback = 'https://api.praysely.com/static/img/profile/2024-01-01T16-37-34.594Z-profileImage.png'

const useImageLoader = (smallImage, largeImage, fallback = defaultFallback ) => {

    const [imageSource, setImageSource] = useState(smallImage)
    const [err, setError] = useState(false)

    useEffect(() => {
        setImageSource(smallImage) 

        const image = new Image()
        image.src = largeImage

        image.onload = () => { 
            setImageSource(largeImage)
        }
        
        image.onerror = () => { 
            setError(true)
            setImageSource(fallback)
        }

    }, [smallImage, largeImage, fallback])

    return [imageSource, { blur: imageSource === smallImage || err === true }]

}

export default useImageLoader