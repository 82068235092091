import { useEffect, useState, useCallback } from 'react'
 
import React from 'react'
import { setUpdatedImage } from '../../features/auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import { updateImage, setImagePreview, chooseImage, removeImage, setCroppedImage } from '../../features/profile/profileSlice' 
import HorizontalLoader from '../../loaders/HorizontalLoader'
import { imgUrl } from 'features/constants'
import AutorenewIcon from '@mui/icons-material/Autorenew';
import styled from 'styled-components'
import './styles.css'
import { toast } from 'react-toastify'
import { TopHeaderSettings, UploadImageButton } from './settings.styled'
import AddImageCropper from './AddImageCropper'
import { settingsLanguage } from './settingsLanguage'


function AddImage() {
    const dispatch = useDispatch()
    const { profileImage, croppedImage, profileImagePreview, chosenImage, isImageUpdateSuccess, isImageUpdateLoading, isImageUpdateError, hasUpdated} = useSelector((state) => state.profile)
    const [imageValid, setImageValid] = useState(false)
    const { user } = useSelector((state) => state.auth)
    const [isEdited, setIsEdited] = useState(false)

    useEffect(() => {
    }, [dispatch, profileImage, isImageUpdateLoading, isImageUpdateError, isImageUpdateSuccess, profileImagePreview])

    const handleImageChange = async (e) => { 

        setIsEdited(true)

        let fileName = e.target.files[0].name
        let fileType = fileName.slice(fileName.lastIndexOf('.')+1).toLowerCase()

        if(fileType !== 'jpeg' && fileType !== 'png' && fileType !== 'jpg'){
            
            return toast.error('Unsupported image type')
        }else{
            setImageValid(true)
            let url = URL.createObjectURL(e.target.files[0])
            dispatch(setImagePreview({ url: url}))
            dispatch(chooseImage(e.target.files[0]))
        }

      }

    const handleSaveImage = async () => {   

        const formData = new FormData();
        let imageData;

        if(croppedImage){
            let localFile = await fetch(croppedImage);
            let fileBlob = await localFile.blob();
            let file = new File([fileBlob], "profileImage.png",{ type:"image/png", lastModified:new Date().getTime()})
            imageData = file

        }else{
            imageData = chosenImage
        }

        formData.append("file", imageData);
        dispatch(updateImage(formData))
    }
    const cancelImage = () => {
        dispatch(removeImage())
        dispatch(setCroppedImage(null))
        setImageValid(false)
        setIsEdited(false)
    }
    

    useEffect(() => {
        if(isImageUpdateSuccess){

            const localStorageUser = localStorage.getItem('user')
            let myData = JSON.parse(localStorageUser)

            myData.imagePath = profileImage
            localStorage.setItem('user', JSON.stringify(myData))

            // create a dispatch function to update the user state with the new image path
            dispatch(setUpdatedImage(profileImage))

            dispatch(removeImage())
            dispatch(setCroppedImage(null))
            setIsEdited(false)

        }
    }, [profileImage, isImageUpdateSuccess])


    let imageToShow;
    try{ imageToShow =  `${imgUrl}/profile/${user.imagePath}`}
    catch(e){  imageToShow =  require(`../../images/directoryImages/default/addImage.png`)  }




    return (
        <AddImageContainer>
            <TopHeaderSettings>
                {
                    settingsLanguage.updateImage[user.preferredLanguage]
                }
            </TopHeaderSettings>


            { profileImagePreview && !croppedImage ? 
                ( 
                    <div>
                        <img className="profileImgSettings" src={profileImagePreview.url} alt="profile-a" />  
                        <AddImageCropper image={profileImagePreview.url} />
                    </div>
                )
                : croppedImage ? 
                ( <div> 
                    <img className="profileImgSettings" src={croppedImage} alt="profile-b" /> 
                    <AddImageCropper image={profileImagePreview ? profileImagePreview.url : ''} />
                </div> 
                )
                : profileImage && !hasUpdated ? 
                ( <div><img className="profileImgSettings" src={imageToShow} alt="profile-c" /></div> )
                :
                ( <div><img className="profileImgSettings" src={imageToShow} alt="profile-d" /></div> )
            }

            {
                !isImageUpdateLoading && !imageValid && (
                    <div>
                        <UploadImageButton 
                            htmlFor="postFileUpload"
                            > 
                            <AutorenewIcon />
                            <span>
                                {settingsLanguage.uploadImage[user.preferredLanguage]}
                            </span> 
                        </UploadImageButton>
                        <input hidden  id="postFileUpload"  type="file" accept=".png, .jpg, .jpeg" name="file" onChange={handleImageChange}/>
                    </div>
                )
                    
            }

            {
                isEdited &&

                <ProfileImagePreviewSave>
                    <div className='cancelImageBtn' onClick={cancelImage}>
                        {
                            settingsLanguage.cancel[user.preferredLanguage]
                        }
                    </div>
                    <div className='saveImageBtn' onClick={handleSaveImage} >
                        {
                            settingsLanguage.save[user.preferredLanguage]
                        }
                    </div>
                </ProfileImagePreviewSave>
            }
                

        </AddImageContainer>
  )
}

const ProfileImagePreviewSave = styled.div`

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

`


const AddImageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

export default AddImage
