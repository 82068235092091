import styled from "styled-components";

export const ReqReceivedItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 10px 10px;
    border-radius: 5px;
    &:hover{
        background-color: rgb(228, 228, 228);
    }
    // for mobile view
    @media (max-width: 1260px){ 
        width: 93%;
    }

    @media (max-width: 768px){
        margin-left: 15px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 93%;
    }
`

export const RequestsItemInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;  

    @media (max-width: 768px){ 
        margin-bottom: 10px;
        align-items: center;
        justify-content: flex-start;
    }
`


export const RequstItemName = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 9px;  
    height: 100%; 
    span{
        color: var(--dFontColor);
    }

    @media (max-width: 768px){  
        justify-content: center;
        align-items: flex-start; 
    }
`

export const ReqItemImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
`
export const ReqButton = styled.div`
    padding: 10px 20px; 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: ${props => props.reqType === 'reject' ? '10px' : '0px'};
    background-color: ${props => props.reqType === 'accept' ? 'green' : 'rgb(118, 118, 118)'};
    color: white;
    border-radius: 5px;
    font-size: smaller;
    cursor: pointer;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    width: 90px;
    &:hover{
        background-color: ${props => props.reqType === 'accept' ? 'rgb(4, 165, 4)' : 'rgb(78, 78, 78)'};
        /* background-color: rgb(4, 165, 4); */
        transition: .4s;
    }
    @media (max-width: 768px){
        padding: 13px 11px;
    }
`
