import './startConversation.css'
import { useEffect, useRef, useState } from 'react'
import {  useSelector, useDispatch } from 'react-redux'
import useToggleDropdown from '../../../hooks/useToggleDropdown'
import Spinner from '../../Spinner'
import ViewAllItem from './ViewAllItem'
import { getMembers, resetChurchMembers, setMemberFilter } from '../../../features/memberships/membershipSlice'
import styled from 'styled-components'
import { imgUrl } from 'features/constants'
import MyMembersItem from 'components/settings/memberships/MyMembersItem'
import MemberSearch from 'components/settings/memberships/MemberSearch' 
function ViewAllMembers() {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const ref1 = useRef(null)
    const [loadingMore, loadingMoreSet] = useState() 
    const [isDropped, setIsDropped] = useToggleDropdown(ref1)
    const [icon, setIcon] = useState()
    const {  
        members, 
        isMemberSearchActive, 
        isMbsLoading, 
        isMbsSuccess, 
        isMbsError,
        totalMembers,
        filter,
        hasNextPage,
        nextPage,
        totalPages,
        currentPage,
        searchResults,
        isMbsSearchSuccess
    
      } = useSelector((state) => state.memberships2)

    const handleDropDown = () => { setIsDropped.toggle(ref1) }


    useEffect(() => {
        if(user && (user.isOrganization || user.isChurch)){
            dispatch(getMembers())
        }
        return () => { dispatch(resetChurchMembers()) }
    }, [dispatch, user])


    const loadMoreUsers = () => {
        if(hasNextPage){
            loadingMoreSet(true)
            dispatch(getMembers({
                page: nextPage,
                filter: filter
            }))
        }
    }


  return (
    <ViewAllContainer>
        <ViewAllHeading> 
            <MemberSearch /> 
        </ViewAllHeading>
        <MembersFeed> 
        
            {
                isMbsLoading && !loadingMore ? 
                (
                <Spinner />
                )
                : isMbsSuccess ?
                ( members.map((ele) => {
                let min = 1
                const max = 100
                const rand = min + Math.random() * (max - min)
                return (
                    <ViewAllItem 
                        key={ ele._id +  rand } 
                        userId={ele._id}
                        fullName={ele.fullName} 
                        username={ele.username} 
                        image={ele.profileImg.imagePath2}  
                    />
                )
                })
                )
                :
                (
                <div> Something went wrong </div>
                )
            }

            {!isMbsLoading && isMbsSuccess && hasNextPage ? 
            (
                <LoadMoreUsers onClick={loadMoreUsers}>Load More Users</LoadMoreUsers>
            )
            : !isMbsLoading &&
            (
                null
            )
            }
        </MembersFeed> 
    </ViewAllContainer>
  )
}

export default ViewAllMembers

const ViewAllContainer = styled.div`
    font-size: 20px;
    color: rgb(70,70,70);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 55px);
`

const ViewAllHeading = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; 
    margin-top: 10px;
`

const LoadMoreUsers = styled.button`
    margin-top: 50px;
    padding: 15px 40px;
    background-color: #005900;
    color: rgb(200,200,200);
    outline: none;
    width: fit-content;
    border-radius: 7px;
    cursor:  pointer;
    border: none;
    &:hover{
        background-color: green;
        color: #e7e7e7;
    }
`
const MembersFeed = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    padding: 10px;
    /* hide the scrollbar */
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }
    
`