import React, {useState, useEffect } from 'react'
import styled from 'styled-components'   
import { useSelector, useDispatch } from 'react-redux'
import { setFormData, setGoal, setErrorField } from 'features/givingCampaigns/givingCampaignSlice'
import { GiveForm, StyledInput, InputIconWrap } from '../../giving.styled' 
import { TbReportMoney } from "react-icons/tb";
import { BiCalendarAlt, BiCalendarCheck  } from "react-icons/bi";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; 

export default function Step2Details() {
    const dispatch = useDispatch()

    const { hasEndDate, goal, hasGoal, errorField, endDateInputStyle } = useSelector((state) => state.givingCampaign)

    const [endDate, setEndDate] = useState( dayjs( `${dayjs().year()}-${dayjs().month() + 1}-${dayjs().date() + 1}`));
    const [openEnd, setOpenEnd] = useState(false)
    const [border, setBorder] = useState('')
    const [iconSize, setIconSize] = useState(40) 

    const handleDateChange = (newValue) => {
        let date0 = `${newValue.get('year')}-${newValue.get('month') + 1}-${newValue.get('date')}`
        dispatch(setFormData({ key: 'endDate', value: date0 }))
    }

    const handleFormData = (key, value) => {
        // if the key is hasGoal, then only numbers are allowed
        if(key === 'goal'){
            dispatch(setGoal({ value: value }))
        }else{
            dispatch(setFormData({ key: key, value: value }))
        }
    }

    const handelDateClick = () => {
        setOpenEnd(true)
        setBorder('date')
    }

    const handleGoalChange = (e) => {
        if(errorField['goal']){
            dispatch(setErrorField({
                key: 'goal',
                value: false
            }))
        }
        dispatch(setGoal({ value: e.target.value }))
    }


    return (
        <GiveForm>
            <Wrap2>
                <DetailsItem>
                    <DI>
                        <Ctb
                            enabled={hasGoal}
                            active={border === 'goal'}
                            onClick={() => handleFormData('hasGoal', !hasGoal)}
                            >
                                {
                                    hasGoal ?
                                    (
                                        <>
                                            <TbReportMoney size={iconSize} />
                                            Set a goal
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <TbReportMoney size={iconSize} />
                                            Click to set a goal
                                        </>
                                    )
                                }

                        </Ctb>
                    </DI>
                    <DI>
                        {
                            hasGoal && 
                            (
                                <StyledInput
                                    style={{marginLeft: '10px', width: '100%'}}
                                    > 
                                    <InputIconWrap
                                        isError={errorField['goal']}
                                        onFocus={() => setBorder('goal')}
                                        onBlur={() => setBorder('')} 
                                        >
                                        <input 
                                            placeholder='Goal amount'
                                            maxLength={10}
                                            type="text" 
                                            value={goal}
                                            onChange={handleGoalChange}
                                            />
                                    </InputIconWrap>
                                </StyledInput>
                            )
                        } 
                    </DI> 
                </DetailsItem>

                <DetailsItem>
                    <DI>
                        <Ctb
                            enabled={hasEndDate}
                            active={border === 'date'}
                            onClick={() => handleFormData('hasEndDate', !hasEndDate)}
                            >
                            {
                                hasEndDate ?
                                (
                                    <>
                                        <BiCalendarCheck size={iconSize} />
                                        Set an end date
                                    </> 
                                )
                                :
                                (
                                    <>
                                        <BiCalendarAlt size={iconSize} />
                                        No end date
                                    </>
                                )
                            }
                        </Ctb>
                    </DI> 
                    <DI>
                        {
                            hasEndDate && 
                            (
                                <StyledInput
                                    onFocus={() => setBorder('date')}
                                    onBlur={() => setBorder('')}
                                    style={{marginLeft: '10px', width: '100%'}}
                                    > 
                                    <LocalizationProvider dateAdapter={AdapterDayjs}> 
                                    <DesktopDatePicker
                                        value={endDate}
                                        onChange={(newValue) => handleDateChange(newValue)}
                                        sx={endDateInputStyle}
                                        open={openEnd}
                                        onClose={() => setOpenEnd(false)}
                                        slotProps={{
                                            textField: {
                                                onClick: handelDateClick,
                                            },
                                        }} 
                                        // defaultValue={dayjs( `${dayjs().year()}-${dayjs().month() + 1}-${dayjs().date() + 1}`)} 
                                        /> 
                                    </LocalizationProvider>   
                                </StyledInput>
                            )
                        }
                    </DI> 
                </DetailsItem>            
            </Wrap2>      
        </GiveForm> 
    )
}

const Ctb = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%; 
    transition: all 0.6s ease-in-out; 
    border-radius: 10px;
    cursor: pointer;
    color: ${props => props.enabled ? 'var(--themeColor)' : 'var(--lightThemeFontColor)'};
    background-color: #f5f5f5;
        box-shadow: 0 0 5px #ccc;
        border: 2px solid ${props => props.active ? 'var(--themeColor)' : 'transparent'};
    &:hover { 
        border: 2px solid var(--themeColor);
    }
`

const Wrap2 = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    transition: all 0.6s ease-in-out; 

`

const DI = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%; 
    height: 100%;
    transition: all 0.6s ease-in-out; 

`

const DetailsItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%; 
    height: 50%;
    transition: all 0.6s ease-in-out; 
    padding: 10px;
`