
import React, { useEffect, useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, useMap } from 'react-leaflet'

function LocationMarker({myPosition}) {
    const [position, setPosition] = useState(null)

    useEffect(() => {
        console.log(myPosition)
    }, [myPosition])

    const map = useMapEvents({
        
        // update() {
        //     console.log('loading')
        // },   

        // tileload() {
        //     console.log('tile loaded')
        // },

        click() {
            setPosition(myPosition)
            map.flyTo(myPosition, map.getZoom())
        },

        // locationfound(e) {
        //     console.log('location found')
        //     setPosition(e.latlng)
        //     map.flyTo(e.latlng, map.getZoom())
        // },
    })


    return position === null ? null : (
      <Marker position={position}>
        <Popup>You are here</Popup>
      </Marker>
    )
  }
  

export default function ListingLocation({markerTitle, markerPosition}) {

    useEffect(() => {
        console.log(markerPosition)
    }, [markerPosition])

    return (
        <MapContainer 
            center={markerPosition} 
            scrollWheelZoom={true}
            zoom={13}  
            > 
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
            
            <LocationMarker myPosition={markerPosition} />

        </MapContainer>
    )
}
