import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { submitVerify, setChurchState, resetCvSlice } from 'features/churchVerification/cvSlice'
import ChurchExtras from 'components/userProfile/ChurchExtras'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ChurchVerifySucess from './ChurchVerifySucess'
import { toast } from 'react-toastify'


export default function ChurchVerify() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { isVerifySubmitSuccess, isVerifySubmitLoading, churchLocationState, errorMessage,isVerifySubmitError  } = useSelector((state) => state.churchVerification)

    const { user } = useSelector((state) => state.auth)    
    const [street, setStreet ] = useState()
    const [city, setCity ] = useState()
    const [state, setState ] = useState()
    const [zip, setZip ] = useState()
    const [country, setCountry ] = useState('USA')

    const handleSubmit = () => {
        dispatch(submitVerify({
            street: street,
            city: city,
            state: churchLocationState,
            zip: zip,
            country: country
        }))
        setStreet('')
        setCity('')
        setState('');
        setStreet('')
        setZip('')
    }
    useEffect(() => {
        if(isVerifySubmitError){
            toast.error(errorMessage)
        }
        
        return () => {
            dispatch(resetCvSlice())
        }
    }, [errorMessage, isVerifySubmitError, dispatch])
    useEffect(() => {}, [state])
    useEffect(()=> { 
        if(!user.isOrganization && !user.isChurch ){ navigate(`/`) } 
        if(user.accountVerified){ navigate(`/${user.name}`) } 
    }, [])

if(isVerifySubmitSuccess){

    return(
        <ChurchVerifyCont>
            <ChurchExtras />
            <CVWrap>
                <ChurchVerifySucess />
            </CVWrap>
        </ChurchVerifyCont>
    )
}

  return (
    <ChurchVerifyCont>
        <ChurchExtras />
        <CVWrap>
            <CVTitle>Get your verified badge!</CVTitle>
            <VerifiedUserIcon
                style={{
                    fontSize: '50px',
                    marginBottom: '10px',
                    color: 'green'
                }} />
            <CVText>
                Please fill the form with you church's physical address, so we can verify you are real, 
                and your name will include a verified badge.
            </CVText>
            <CVinputWrap>
                <CVinput placeholder='Street' value={street} onChange={(e)=> setStreet(e.target.value)}/>
            </CVinputWrap>
            <CVinputWrap>
                <CVinput placeholder='City' value={city} onChange={(e)=> setCity(e.target.value)}/>
            </CVinputWrap>
            <CVinputWrap>
                <StateOptions setState={setState}  />
            </CVinputWrap>
            <CVinputWrap>
                <CVinput placeholder='Zip Code' value={zip} onChange={(e)=> setZip(e.target.value)}/>
            </CVinputWrap>
            <CVinputWrap style={{flexDirection: 'column'}}>
                <CVinput readonly placeholder='country' value={'USA'}/>
                <span>Currently only avaliable in USA</span>
            </CVinputWrap>
            {isVerifySubmitLoading ? (
                <CVSubmitBtn>Loading...</CVSubmitBtn>
            ):
            (
                <CVSubmitBtn onClick={handleSubmit}>Submit</CVSubmitBtn>
            )

            }
            
            <CVText>Address used for verification only</CVText>
        </CVWrap>
    </ChurchVerifyCont>
  )
}

const ChurchVerifyCont = styled.div`
        height: 100vh;
        display: flex;
        flex-direction: row;
        width: fit-content;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: lighter;
        color: #4d4d4d;
`
const CVWrap =styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const CVTitle = styled.h1`
    font-size: x-large;
    font-weight: lighter;
 

`
const CVText = styled.div`
    width: 60%;
    
`

const CVinputWrap = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40%;
`
const CVinput = styled.input`
    padding: 10px;
    width: 100%;
`

const CVSubmitBtn = styled.div`
    cursor: pointer;
    background-color: green;
    border-radius: 5px;
    color: white;
    padding: 10px 20px;
    margin: 5px;
    &:hover{
        background-color: #11a511;
    }

`


const StateOptions = (props) => {
    const dispatch = useDispatch()

    return (
        <select 
            onChange={(e) => dispatch(setChurchState(e.target.value))}
            style={{
                padding: '10px',
                width: '100%'
            }}
        
        >
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
        </select>
    )
}