import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import settingService from './settingService'


const errorMessage = (error) => {
    return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}


const initialState = { 

    settingSection: null,
    viewReportIssueModal: false,
    viewResetItemModal: false,

    isVerifyPasswordLoading: false,
    isVerifyPasswordSuccess: false,
    isVerifyPasswordError: false,
    verifyPasswordErrorMessage: null,

    isResetEmailLoading: false,
    isResetEmailSuccess: false,
    isResetEmailError: false,
    resetEmailErrorMessage: null,
    newEmail: '',

    isUpdateUsernameLoading: false,
    isUpdateUsernameSuccess: false,
    isUpdateUsernameError: false,
    updateUsernameErrorMessage: null,
    newUsername: '',

    isUpdateFullNameLoading: false,
    isUpdateFullNameSuccess: false,
    isUpdateFullNameError: false,
    updateFullNameErrorMessage: null,
    newFullName: '',
}

// search
export const verifyPassword = createAsyncThunk(
    'settings/verify',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await settingService.verifyPassword(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const resetEmail = createAsyncThunk(
    'settings/resetEmail',
    async (data, thunkAPI) => {
        try { 
            const token = thunkAPI.getState().auth.user.token
            return await settingService.resetEmail(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const updateUsername = createAsyncThunk(
    'settings/updateUsername',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await settingService.updateUsername(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const updateFullName = createAsyncThunk(
    'settings/updateFullName',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await settingService.updateFullName(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)



export const settingSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        resetSettingSection: (state) => initialState,
        setSettingSection: (state, action) => { state.settingSection = action.payload },
        setViewReportIssueModal: (state, action) => {
            state.viewReportIssueModal = action.payload
        },
        setViewResetItemModal: (state, action) => {
            state.viewResetItemModal = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(verifyPassword.pending, (state) => {
                state.isVerifyPasswordLoading = true
            })
            .addCase(verifyPassword.fulfilled, (state, action) => {
                state.isVerifyPasswordLoading = false
                state.isVerifyPasswordSuccess = true
                state.isVerifyPasswordError = false
                state.verifyPasswordErrorMessage = null
            })
            .addCase(verifyPassword.rejected, (state, action) => {
                state.isVerifyPasswordLoading = false
                state.isVerifyPasswordSuccess = false
                state.isVerifyPasswordError = true
                state.verifyPasswordErrorMessage = action.payload
            })
            // ========================================================
            .addCase(resetEmail.pending, (state) => {
                state.isResetEmailLoading = true
            })
            .addCase(resetEmail.fulfilled, (state, action) => {
                state.newEmail = action.payload.data.email
                state.isResetEmailLoading = false
                state.isResetEmailSuccess = true
                state.isResetEmailError = false
                state.resetEmailErrorMessage = null
                
            })  
            .addCase(resetEmail.rejected, (state, action) => {
                state.isResetEmailLoading = false
                state.isResetEmailSuccess = false
                state.isResetEmailError = true
                state.resetEmailErrorMessage = action.payload
            })
            // ========================================================
            .addCase(updateUsername.pending, (state) => {
                state.isUpdateUsernameLoading = true
            })
            .addCase(updateUsername.fulfilled, (state, action) => {

                state.newUsername = action.payload.data.username
                
                let user = JSON.parse(localStorage.getItem('user'))

                user.name = action.payload.data.username

                localStorage.setItem('user', JSON.stringify(user))
                
                state.isUpdateUsernameLoading = false
                state.isUpdateUsernameSuccess = true
                state.isUpdateUsernameError = false
                state.updateUsernameErrorMessage = null 


            })
            .addCase(updateUsername.rejected, (state, action) => {
                state.isUpdateUsernameLoading = false
                state.isUpdateUsernameSuccess = false
                state.isUpdateUsernameError = true
                state.updateUsernameErrorMessage = action.payload
            })
            // ========================================================
            .addCase(updateFullName.pending, (state) => {
                state.isUpdateFullNameLoading = true
            })
            .addCase(updateFullName.fulfilled, (state, action) => {

                state.newFullName = action.payload.data.fullName

                let user = JSON.parse(localStorage.getItem('user'))
                user.fullName = action.payload.data.fullName
                localStorage.setItem('user', JSON.stringify(user))

                state.isUpdateFullNameLoading = false
                state.isUpdateFullNameSuccess = true
                state.isUpdateFullNameError = false
                state.updateFullNameErrorMessage = null
            })
            .addCase(updateFullName.rejected, (state, action) => {
                state.isUpdateFullNameLoading = false
                state.isUpdateFullNameSuccess = false
                state.isUpdateFullNameError = true
                state.updateFullNameErrorMessage = action.payload
            })
    }

})


export const { resetSettingSection, setSettingSection, setViewReportIssueModal, setViewResetItemModal } = settingSlice.actions

export default settingSlice.reducer