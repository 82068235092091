import React from 'react'
import styled from 'styled-components'


export default function PreviewListing() {
    return (
        <PreviewView>
            <PreviewListingContainer>


            </PreviewListingContainer>

        </PreviewView>
    )
}

const PreviewView = styled.div`
    width: 65%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ebebeb;
    border-radius: 0px 10px 10px 0px;
    overflow-y: scroll;
    padding: 30px;
    /* hide scroll bar */
    ::-webkit-scrollbar {
        display: none;
    }
    text-align: center;
    
`

const PreviewListingContainer = styled.div`
    background-color: white;
    height: 100%;
    width: 100%;
    border-radius: 20px;
`