import {React } from 'react'
import { useSelector } from 'react-redux'
import CreateDirectoryEntry from './CreateDirectoryEntry'
import DirectoryAddedSuccess from './DirectoryAddedSuccess'
import ViewDirectoryItem from './ViewDirectoryItem'
import { ModalContainerMain } from 'components/modal/modal.styled'
import AddPersonInfoToDirectory from './AddPersonInfoToDirectory'


function DirectoryModal(props) {
    const {  isDirectoryCreatedSuccess } = useSelector((state) => state.directory)
    const { createDirectory, editDirectory, viewCard } = useSelector((state) => state.directoryModal)

    if(isDirectoryCreatedSuccess){
      return(
        <>
          <ModalContainerMain>
            <DirectoryAddedSuccess/>
          </ModalContainerMain>
        </>
      )
    }


    if(createDirectory){
        return(
          <>
            <CreateDirectoryEntry />
            <AddPersonInfoToDirectory/>
          </>
        )
      }else if( viewCard ){
        return(

          <ViewDirectoryItem/>
   
        )
        }else if( editDirectory ){
          return(

            <>
              <CreateDirectoryEntry 
                edit={true}
                />        
              <AddPersonInfoToDirectory/>    
            </>


          )
    }


}


export default DirectoryModal