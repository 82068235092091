import { Link } from 'react-router-dom';
import TimeAgo from 'timeago-react'; 
import { useDispatch, useSelector } from 'react-redux'
import { acceptRequest, deleteRequest, setCurrentLoadingRedId } from '../../../features/requests/requestSlice'
import { imgUrl } from 'features/constants';
import styled from 'styled-components';
import SpinnerSmall from 'components/SpinnerSmall';
import { RequestsItemInfo, RequstItemName, ReqReceivedItemContainer, ReqButton, ReqItemImage } from './reqests.styled';
import { settingsLanguage } from '../settingsLanguage';

import * as timeago from 'timeago.js';
// import it first.
import es from 'timeago.js/lib/lang/es';

// register it.
timeago.register('es', es);

function ReqReceivedItem({reqId, userId, fullName, username, image, about, createdAt}) {
    const dispatch = useDispatch()
    let date = new Date(createdAt)
    const { user } = useSelector((state) => state.auth)

    const { isAcceptLoading, isDeleteLoading, currentReqId } = useSelector((state) => state.requests)

    const handleAccept = () => {
        dispatch(setCurrentLoadingRedId(reqId))
        dispatch(acceptRequest({
            userId: userId.toString(),
            reqId: reqId.toString()
        }))
    }

    const handleReject = () => {
        dispatch(setCurrentLoadingRedId(reqId))
        dispatch(deleteRequest({
            type: 'delete',
            reqId: reqId,
            userId: userId
        }))
    }

    let imageToShow;
    try{ imageToShow =   `${imgUrl}/profile/${image}` }
    catch(e){  imageToShow =  require(`../../../profileImage/generic.png`)}
  


    return (
        <ReqReceivedItemContainer>
            <RequestsItemInfo>
                <ReqItemImage src={imageToShow} alt='Request' />

                <RequstItemName>
                    <span>{fullName}</span>
                    <ReqTimeAgo> 
                        <TimeAgo 
                            datetime={date}
                            locale={user.preferredLanguage}
                            />
                    </ReqTimeAgo>
                </RequstItemName>
            </RequestsItemInfo>


            <ReqBtnsWrapper>
                <ReqButton
                    reqType={'accept'}
                    onClick={handleAccept}
                    disabled={isAcceptLoading || isDeleteLoading}
                    > 
                    {
                        isAcceptLoading && currentReqId === reqId.toString() ? 
                        (
                            <SpinnerSmall />    

                        )
                        :
                        (
                            settingsLanguage.accept[user.preferredLanguage]
                        )
                    }
                </ReqButton>
                <ReqButton 
                    reqType={'reject'}
                    onClick={handleReject} 
                    disabled={isAcceptLoading || isDeleteLoading}
                    > 
                    {
                        isDeleteLoading && currentReqId === reqId.toString()?
                        (
                            <SpinnerSmall />    
                        )
                        :
                        (
                            settingsLanguage.reject[user.preferredLanguage]
                        )
                    } 
                </ReqButton>
            </ReqBtnsWrapper>
        </ReqReceivedItemContainer>
    )
}

export default ReqReceivedItem

const ReqTimeAgo = styled.div`
    font-size: 12px;
    color: gray;
    margin-top: 2px; 
    @media (max-width: 768px){
        margin-left: 0px;
        font-size: 10px;
    }
`

const ReqBtnsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 768px){
        margin-top: 10px;

    }
`