import React, {useEffect} from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getChurchBanks, getTransactions, setView } from 'features/giving/givingSlice'  
import { ViewContainer } from 'components/giving/giving.styled'
import BankItem from './BankItem'

export default function Banks() {
    
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { 
        churchBanks,
        getTransactionsLoading,
        getTransactionsSuccess,
        getTransactionsError
    } = useSelector((state) => state.giving)

    useEffect(() => { 
        dispatch(getChurchBanks())
    }, [])
    

    return (
        <ViewContainer>
            {
                churchBanks.map((bank) => 
                    (
                        <BankItem  
                            key={bank.id} 
                            data={bank} 
                            />
                    ) 
                )
            }
        </ViewContainer>
    )
}
