import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from "react-redux"
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { getMemberOf } from 'features/memberships/membershipSlice';
import { selectTo } from 'features/createPost/selectPostingToSlice';
import useToggleDropdown from 'hooks/useToggleDropdown';
import { useRef,useEffect } from 'react'
import Spinner from 'loaders/Spinner';
import { setErrorField, setCreatePostType, setCreatePostPostingTo } from "features/posts/postSlice";
import { imgUrl } from 'features/constants';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import { TbBuildingChurch } from "react-icons/tb";
import { language } from 'app/language';

export default function FormTop() {
    const dispatch = useDispatch()
    const ref1 = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref1)
    const { user } = useSelector((state) => state.auth)
    const { postType, isAnonymous } = useSelector((state) => state.postType )
    const { memberOf, isMbsLoading, isMbsSuccess } = useSelector((state) => state.memberships2)
    const { errorField, createPostType, createPostPostingTo } = useSelector((state) => state.posts)

    const handleGetMembers = () => {
        if(!isMbsSuccess){ dispatch(getMemberOf()) }
    }

    useEffect(() => {
        if(memberOf && memberOf.length === 0){
            dispatch(getMemberOf())
        }
    }, [])

    useEffect(() => { 
        if(user.isOrganization){
            dispatch(setCreatePostPostingTo({
                fullName: user.fullName,
                _id: user._id
            }))
        }else if(isMbsSuccess && memberOf && memberOf.length === 1){
            dispatch(setCreatePostPostingTo(memberOf[0]))
        }
    }, [isMbsSuccess, memberOf])
    
    const handlePostingToClick = () => {
        if(errorField === 'postingTo'){
            dispatch(setErrorField(''))
        }
        setIsDropped.toggle(ref1) 
    }

    const handleGroupClick = (value) => {

        dispatch(setCreatePostPostingTo(value)) //sending object data so other components can use, namely,  //when we submit it we have the ID of where its going
    
    }
    
    let userImage;
    try { userImage = user.imagePath } 
    catch (error) {  userImage = 'generic/2024-01-01T14-13-49.160Z-person.png' }

  return (
    <FormTopContainer>
        <FTImage>
            {
                isAnonymous ? 
                (
                    <NoAccountsIcon style={{width: '50px', height: '50px', borderRadius: '50%'}} alt='create-post' src={`${imgUrl}/profile/${userImage}`} />

                )
                :
                (
                    <img style={{width: '50px', height: '50px', borderRadius: '50%'}} alt='create-post' src={`${imgUrl}/profile/${userImage}`} />
                )
            }
            
            <FTUsername>
                <NameText>
                {
                    isAnonymous ? (
                        <AText>
                            <i>
                                {language.anonymous[user.preferredLanguage]}
                            </i>
                        </AText>
                    ) : (
                        <span>{user.fullName}</span>
                    )
                }
                </NameText>
                {
                    !user.isOrganization && (
                        <PostingToBtn 
                            ref={ref1} 
                            onClick={handlePostingToClick} 
                            onMouseEnter={handleGetMembers}
                            isError={errorField === 'postingTo'}
                            >
                            <PtbEle>
                                <TbBuildingChurch style={{fontSize: '15px', marginRight: '5px'}}/>
                                { createPostPostingTo && createPostPostingTo.fullName ? 
                                    (
                                        <span>{language.postingTo[user.preferredLanguage]} {createPostPostingTo.fullName}</span>
                                    )
                                    :
                                    (
                                        <span>
                                            {language.selectWhereToPost[user.preferredLanguage]}
                                        </span>
                                    )
                                }
                            </PtbEle>
                            {
                                isDropped && (
                                    <MyChurches>
                                        { !isMbsLoading && memberOf && memberOf.length > 0 ? (   
                                                memberOf.map((ele) => (
                                                        <MyChurchItem
                                                            onClick={() => handleGroupClick(ele)} 
                                                            >  
                                                            {ele.fullName}  
                                                        </MyChurchItem>
                                                    )
                                                )
                                            ):
                                            (   isMbsLoading ? (<Spinner />) : (<p>No Memberships</p>))
                                        }
        
                                    </MyChurches>
                                )
                            }
                        </PostingToBtn>
                    )  
                }
  
            </FTUsername>
        </FTImage>

        { postType && (
            <PostType>
                {language[postType][user.preferredLanguage]}
            </PostType>
        )}

    </FormTopContainer>
  )
}

const MyChurchItem = styled.div`
        width: 100%;
        /* do not break text */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
        cursor: pointer;
        padding: 10px;
        border-radius: 5px;

        &:hover{
            background-color: #eaeaea;
        }
`

const MyChurches = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 35px;
    left: 0px;
    background-color: white;
    z-index: 11;
    border-radius: 0px  9px 9px 9px;
    box-shadow: 0px 0px 5px #a9a9a9;
    width: fit-content;
    color: var(--lightThemeFontColor);
    padding: 5px;
    min-width: 170px;
    max-width: 210px;
`

const FormTopContainer = styled.div`
    padding-top: 10px;
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
`
const NameText = styled.div`
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: var(--dFontColor);
`
const AText = styled.div`
    font-size: 15px; 
    
`

const FTImage = styled.div`
    padding-left: 20px;
    display: flex;
    width: fit-content;
    width: fit-content;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; 

`
const FTUsername = styled.div`
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    height:50px;
`

const PostingToBtn = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: small;
    padding: 8px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid yellow;
    cursor: pointer;
    z-index: 10;
    max-width: 280px;
    position: absolute;
    border: ${props => props.isError ? '1px solid red' : '1px solid #6e6e6e'};

    span{
        width: 100%;
        max-width: 280px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
    }

    &:hover{
        background-color: #ececec; 
        border: ${props => props.isError ? '1px solid red' : '1px solid #c1c1c1'};
    }

`
const PtbEle = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    
    span{
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;        
    }


`
const PostType = styled.div`
    padding: 7px 12px;
    background-color: #278327;
    color: white;
    margin-right: 20px;
    font-size: smaller;
    height: fit-content;
    border-radius: 5px;
`