import styled from 'styled-components'
import Loader from "loaders/Loader";
import { React, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { setDirectoryImagePreview, removeDirectoryImage, chooseDirectoryImage } from '../../features/modal/directoryModalSlice'
import { updateDirectoryItem,addFamilyMemberToSettingsUpdate, discardChanges, editFamilyMemberSettingsUpdate, removePersonFromEditingDirectory } from '../../features/directory/directorySlice'
import LoaderSmall from '../../loaders/HorizontalLoader'
import { imgUrl } from 'features/constants'
import PeopleIcon from '@mui/icons-material/People';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import DirToolTip from './directory/DirToolTip'; 
import { TopHeaderSettings, UploadImageButton } from './settings.styled'
import RestartAltIcon from '@mui/icons-material/RestartAlt';

function MyDirectoryItem(props) {
    const dispatch = useDispatch()
    const members = useRef() 
    const [ viewAddUserForm, setViewAddUserForm ] = useState(false)
    const [isChanged, setIsChanged] = useState(false) 
    const { myDirectory, isDirectorySuccess, isDirectoryLoading } = useSelector((state) => state.directory)
    const { directoryImagePreview, selectedImage } = useSelector((state) => state.directoryModal)
    const [ removePersons, setRemovePersons ] = useState(false)
    const [ directoryHeading, setDirectoryHeading ] = useState(myDirectory.name)
    const { isDirectoryUpdatedLoading } = useSelector((state) => state.directory)
    const [ isEditing, setIsEditing ] = useState(false)
    const [emailValid, setEmailValid] = useState(true)
    const [ editingIndex, setEditingIndex ] = useState(-1)

    console.log(emailValid)

    const handleDiscardChanges = () => {
      dispatch(discardChanges())
      setDirectoryHeading(myDirectory.name)
      dispatch(removeDirectoryImage())
      setIsChanged(false)
    }

    const handleAddDirectoryHeading = (e) => { 
      setDirectoryHeading(e.target.value) 
      if(!isChanged){setIsChanged(true)}
    }

    const handleImageChange = (e) => {

      // ensure that the file is an image and not a webm or mp4
      let fileName = e.target.files[0].name
      let fileType =  fileName.slice(fileName.lastIndexOf('.')+1).toLowerCase()
      if(fileType !== 'jpeg' && fileType !== 'jpg' && fileType !== 'png'){

        return toast.error('Select a JPG or PNG files only')
      }

      if(!isChanged){setIsChanged(true)}
      let url = URL.createObjectURL(e.target.files[0])
      dispatch(setDirectoryImagePreview({ url: url }))
      dispatch(chooseDirectoryImage(e.target.files[0]))
    }

    const addMember = (action) => {
      if(action === 'cancel'){
        setUserInfo(I_STATE)
        setViewAddUserForm(false)
      }else{
        if(!addName){ return toast.error('Add a name for this person') }
        if(myDirectory.familyList.length < 13){
  
        dispatch(addFamilyMemberToSettingsUpdate({ 
          familyData: JSON.stringify({
            name: addName,
            email: addEmail,
            phone: addPhone,
            about: addAbout,
          })  
        }))

        // setFamilyMembersArray( array => [...array, JSON.stringify(userInfo) ] )
        setUserInfo(I_STATE)
        setViewAddUserForm(false)
        if(!isChanged){setIsChanged(true)}
              
        }else{toast.error('Limit 12 family members')}
      }
    }

    const handleEdit = (action) => {
      if(action === 'cancel'){
        setIsEditing(false)
        setEditingIndex(-1)
        setUserInfo(I_STATE)
        return;
      }
      if(!memberData.name){  return toast.error('Add a name for this person')}

      dispatch(editFamilyMemberSettingsUpdate({ 
        index: editingIndex,
        familyData: JSON.stringify({
          name: memberData.name,
          email: memberData.email,
          phone: memberData.phone,
          about: memberData.about,
        })  
      }))
      if(!isChanged){setIsChanged(true)}
      setIsEditing(false)
      setEditingIndex(-1)
      setUserInfo(I_STATE)
    }

    //const handleMemberKeyPress = (e) => {if(members.current && (e.key === 'Enter' || e.key === ' ') && familyMember.length > 1) {addMember()}} 
    // const handleEmailChange = (e) => {
    //     if(!isChanged){setIsChanged(true)}
    //     let emailSanitize = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     setEmail(e.target.value)
    //     if (emailSanitize.test(e.target.value)) { setEmailValid(true) }
    //     else { setEmailValid(false) }
    // }
    // const handlePhoneKey = (e) => { if (e.key === 'Backspace' && phone.length === 1) { setPhone('') } }

    const [ memberData, setMemberData ] = useState(null)

    const editMember = (memberData, index) => {  
      if(removePersons){
        dispatch(removePersonFromEditingDirectory({ index: index }))
        setRemovePersons(false)
        if(!isChanged){setIsChanged(true)}
        return;
      }else{
        setIsEditing(true)
        setMemberData(memberData) 
        setEditingIndex(index)        
      }
    }

    const I_STATE = {
      addName: '',
      addEmail: '',
      addPhone: '',
      addAbout: '',
    }

    const [userInfo, setUserInfo] = useState(I_STATE) 
    const { addName, addEmail, addPhone, addAbout } = userInfo

    const handleName = (e) => {
      //if(!isChanged){setIsChanged(true)} 
      if(isEditing){  setMemberData( (prevState) => ( {  ...prevState, name: e.target.value } ) )  }
      else{  setUserInfo((prevState) => ( { ...prevState, [e.target.name]: e.target.value } ) )    } 
    }

    const handleEmail = (e) => {
      let emailSanitize = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(isEditing){  setMemberData(  (prevState) => ( { ...prevState, email: e.target.value } ) )   }
      else{ setUserInfo((prevState) => (  { ...prevState, [e.target.name]: e.target.value  } ) ) }
      if ( emailSanitize.test(e.target.value) ) {setEmailValid(true)}
      else {setEmailValid(false)}
    }

    const handlePhone = (e) => {
      if(isEditing){ setMemberData((prevState) => ({ ...prevState, phone: e.target.value }))  }
      else{ setUserInfo((prevState) => ( { ...prevState, [e.target.name]: e.target.value } ) ) }
    }
    
    const handleAbout = (e) => {
      if(isEditing){ setMemberData( (prevState) => (  {  ...prevState,  about: e.target.value } )  )  }
      else{ setUserInfo((prevState) => ( {  ...prevState,  [e.target.name]: e.target.value  } ) ) }
    }

    const handleSubmit = () => {
      if(!isChanged){return toast.error('No changes have been made')}
      if (!directoryHeading) { return toast.error('Please add a name!') }
      const formData = new FormData();

      formData.append("file", selectedImage)
      formData.append("isImageUpdated", isImgPreview)
      formData.append("familyName", directoryHeading);
      formData.append("oldImage", myDirectory.image ? myDirectory.image.imagePath :  'defaul.jpg')
      // formData.append("churchId", profile._id)

      myDirectory.familyList.map(
          ele => formData.append("familyList", ele)
      )

      dispatch(updateDirectoryItem(formData))
    }

    const isImgPreview = Object.keys(directoryImagePreview).length > 0 
    let imageToShow;
    try { imageToShow = `${imgUrl}/directory/${myDirectory.image.imagePath2}` } 
    catch (error) {  imageToShow = `${imgUrl}/directory/default.jpg` }

    return (
        <MyDirectoryItemWrap>
            <TopHeaderSettings> Manage Directory Entry </TopHeaderSettings>

            { isDirectoryLoading && ( <Loader /> )  }
 
            { isDirectorySuccess ? (
                    <UpdateForm>
                        <ImageSection>

                            <InputWrapHeading> 
                                <span>Directory Heading</span>
                                <InputWrap hasInput={directoryHeading}>  
                                    <input ref={members} name='name' value={directoryHeading} onChange={handleAddDirectoryHeading}  maxLength = "25"  placeholder='Directory Name'/>
                                </InputWrap>  
                            </InputWrapHeading>

                            <AddImage>
                                <ImagePlaceholder>
                                    {isImgPreview ?
                                        (
                                            <ImgPreview src={directoryImagePreview.url} alt="Add Image" />
                                        ) :
                                        (
                                            <ImgPreview src={imageToShow} alt="Add Image" />
                                        )
                                    }
                                </ImagePlaceholder >
                                <AddImageBtnsWrap>
                                    <UploadImageButton 
                                      width ='150px'
                                      htmlFor="imageUpload" 
                                      > 
                                      <RestartAltIcon />
                                        {isImgPreview ? (<span>Replace Image</span>) : (<span>Upload Image</span>)}
                                    </UploadImageButton>
                                    <input hidden id="imageUpload" type="file" accept=".png, .jpg, .jpeg" name="file" onChange={handleImageChange} />


                                    {isImgPreview && (
                                        <RemoveImage onClick={() => dispatch(removeDirectoryImage())}>
                                            Remove image
                                        </RemoveImage>
                                    )}
                                </AddImageBtnsWrap>
                            </AddImage>
                        </ImageSection>

                        
                        <FormSection>  
                            <MembersList> 
                                <Info>
                                    <span>
                                      <PeopleIcon style={{fontSize: "15px", color: "rgb(130,130,130)"}}/>
                                      <span style={{marginLeft: '5px'}}>Family Members </span>
                                    </span>
                                    <InfoItem>
                                    { myDirectory.familyList.map((ele, index) => {

                                            let dirData = JSON.parse(ele) 
                                            let name = dirData.name.slice(0, dirData.name.indexOf(' '))

                                            return( 
                                                <FamilyList removing={removePersons} key={dirData.phone} onClick={() => editMember(dirData, index)}> {name} </FamilyList>
                                            ) 
                                        })
                                    }
                                    </InfoItem> 
                                </Info>      
                            </MembersList> 
                            {removePersons && ( <span style={{ color: 'grey' }}>
                              <i>Click on name to remove</i>
                              <u style={{marginLeft: '10px', cursor: 'pointer'}} onClick={() => setRemovePersons(false)}>Cancel</u>
                            </span> )}

                            { !isEditing && !viewAddUserForm && !removePersons && (
                              <span style={{ color: 'grey' }}>
                              <i>Click on name to edit</i>
                              </span>
                            )}

                            { !isEditing && !viewAddUserForm && !removePersons &&
                              <PersonsButtonWrap>

                                <AddDirectoryMember remove={false} onClick={() => setViewAddUserForm(true)}>
                                    <PersonAddAlt1Icon />
                                    <span className='viewToolTip'>
                                      <DirToolTip   value={'Add person'} /> 
                                    </span> 
                                </AddDirectoryMember>

                                <AddDirectoryMember remove={true} onClick={() => setRemovePersons(true)}>
                                  <PersonRemoveIcon />
                                  <span className='viewToolTip'>
                                      <DirToolTip   value={'Remove person'} /> 
                                    </span> 
                                </AddDirectoryMember>

                              </PersonsButtonWrap>
                            }
                            { viewAddUserForm && !isEditing ?
                                (
                                    <AddUserInputWrap>
                                      <AddUserInputHeading> Add Person to Directory </AddUserInputHeading>
                                      <InputWrap hasInput={addName}>  
                                          {addName.length > 0 && (<IconInput>Name</IconInput>)}
                                          <input  ref={members} name='addName' value={addName} onChange={handleName}  maxLength = "25"  placeholder='Full Name'/>
                                      </InputWrap>
                                      <InputWrap hasInput={addEmail}>  
                                          {addEmail.length > 0 && (<IconInput>Email</IconInput>)}
                                          <input ref={members} name='addEmail' value={addEmail} onChange={handleEmail}  maxLength = "25"  placeholder='Email Address'/>
                                      </InputWrap>
                                      <InputWrap hasInput={addPhone}>  
                                          {addPhone.length > 0 && (<IconInput>Phone</IconInput>)}
                                          <input ref={members} name='addPhone' value={addPhone} onChange={handlePhone}  maxLength = "10"  placeholder='Phone Number'/>
                                      </InputWrap>
                                      <InputWrap hasInput={addAbout}>  
                                          {addAbout.length > 0 && (<IconInput>About</IconInput>)}
                                          <textarea ref={members} name='addAbout' value={addAbout} onChange={handleAbout}  maxLength = "1200"  placeholder='About (optional). Use this section to add anything you would like others to know about this person.'/>
                                      </InputWrap>
                                      <AddUserBtnWrap>
                                          <AddUserBtn onClick={addMember}>Add Person</AddUserBtn>  
                                          <span onClick={() => addMember('cancel')} style={{cursor: 'pointer', marginLeft: '10px', color: 'rgb(77,77,77)'}}><u>cancel</u></span>
                                      </AddUserBtnWrap> 
                                    </AddUserInputWrap>
                                )
                                : isEditing && (
                                    <AddUserInputWrap>
                                        <AddUserInputHeading> Editing Directory Person Info </AddUserInputHeading>
                                      <InputWrap hasInput={memberData.name}>  
                                          {memberData.name.length > 0 && (<IconInput>Name</IconInput>)}
                                          <input  ref={members} name='addName' value={memberData.name} onChange={handleName}  maxLength = "25"  placeholder='Full Name'/>
                                      </InputWrap>
                                      <InputWrap hasInput={memberData.email}>  
                                          {memberData.email.length > 0 && (<IconInput>Email</IconInput>)}
                                          <input ref={members} name='addEmail' value={memberData.email} onChange={handleEmail}  maxLength = "25"  placeholder='Email Address'/>
                                      </InputWrap>
                                      <InputWrap hasInput={memberData.phone}>  
                                          {memberData.phone.length > 0 && (<IconInput>Phone</IconInput>)}
                                          <input ref={members} name='addPhone' value={memberData.phone} onChange={handlePhone}  maxLength = "10"  placeholder='Phone Number'/>
                                      </InputWrap>
                                      <InputWrap hasInput={memberData.about}>  
                                          {memberData.about.length > 0 && (<IconInput>About</IconInput>)}
                                          <textarea 
                                            ref={members}  
                                            name='addAbout' 
                                            value={memberData.about} 
                                            onChange={handleAbout}  
                                            maxLength = "1200"  
                                            placeholder='About (optional). Use this section to add anything you would like others to know about this person.'/>
                                      </InputWrap>
                                      <AddUserBtnWrap>
                                          <AddUserBtn onClick={handleEdit}>Save Edit</AddUserBtn>  
                                          <span onClick={() => handleEdit('cancel')} style={{cursor: 'pointer', marginLeft: '10px', color: 'rgb(77,77,77)'}}><u>cancel</u></span>
                                      </AddUserBtnWrap> 
                                    </AddUserInputWrap>
                                )

                            }
                        </FormSection> 
                    </UpdateForm>
                )
                :
                ( <div>loading </div> )
            } 
            
            { isChanged && (
              <SubmitButtonWrap> 
                  { isDirectoryUpdatedLoading ? (
                      <SubmitWrap >
                          <SaveBtn
                            disabled={isDirectoryUpdatedLoading}
                            >Saving... </SaveBtn>
                          <LoaderSmall />
                      </SubmitWrap>
                      ) 
                      :
                      ( 
                        <SubmitWrap > 
                          <SaveBtn onClick={handleSubmit}> Save Changes </SaveBtn> 
                          <DiscardBtn  onClick={handleDiscardChanges}> Discard Changes </DiscardBtn>
                        
                        </SubmitWrap> 
                        
                      )
                  } 
              </SubmitButtonWrap>
            )

            }

        </MyDirectoryItemWrap>
    )
}

const MyDirectoryItemWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;  
    @media (max-width: 768px) {
      flex-direction: column; 
    }
`

const ImageSection = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;

    @media (max-width: 768px) {
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 5px;
    }

`

const FormSection = styled.div`
    width: 50%; 
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-left: 10px; 
    @media (max-width: 768px) {

      margin-left: 0px;
      width: 100%;
    }
`

const UpdateForm = styled.div`
  display: flex; 
  flex-direction: row;
  align-items: space-between;
  justify-self: center;
  align-self: center;
  margin-top: 0px; 
  width: 100%;
    color: var(--lightThemeFontColor);

  textarea{
    resize: none;
    padding: 10px; 
    height: 131px;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
    color: var(--lightThemeFontColor);
  }
  textarea:focus{
    outline: none;
    border: 1px solid var(--themeColor);
  }
  textarea::placeholder{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
    }

    @media (max-width: 768px) {
      
      flex-direction: column;
      
    }


`


const InputWrapHeading = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
  font-size: 14px;
  color: var(--lightThemeFontColor);
  span{
    margin-bottom: 5px;
  }
`

const InputWrap = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    justify-self: center;
    align-self: flex-start;
    margin-bottom: 5px;
    font-size: 12px;
    transition: all 0.7s;
    border-radius: 4px;

    input{ 
      border: 1px solid #dfdfdf;
      padding: ${props => props.hasInput ? '15px 2px 8px 9px':'10px'};
      width: 100%;
      outline: none;
      border-radius: 5px;
      color: var(--lightThemeFontColor);
    }
    input:focus{
      border: 2px solid var(--themeColor);
      outline: none;
    }
    textarea{
      width: 100%;
      font-size: 12px;
      min-height: fit-content;
    }
    textarea::placeholder{
     
      font-size: 12px;
    }
`

const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
  font-size: 14px;
  color: rgb(100,100,100);
  height: fit-content;
  align-items: flex-start;

  span{
    display: flex;
    flex-direction: row;
    align-items: center; 
    font-size: 14px;
    color: var(--lightThemeFontColor);
  
  }

`

const FamilyList = styled.div`
  padding: 4px 8px;
  background-color: rgb(220,210,210);
  border: ${props => props.removing ? '1px solid red':'1px solid rgb(220,210,210);'};
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  &:hover{
    background-color: #f1f1f1;
    border: ${props => props.removing ? '1px solid rgb(173, 39, 39)':'1px solid rgb(220,210,210);'};
  }
`

const PersonsButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  width: 100%;
  height: fit-content; 
  flex-wrap: wrap;

  & span{
    /* padding-left: 10px; */
  }
`


const AddImageBtnsWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
const RemoveImage = styled.div`
    padding-left: 5px;
    cursor: pointer;
    font-size: 11px;
    color: rgb(80,80,80);
    &:hover{
      text-decoration: underline;
    }
`

const ImagePlaceholder = styled.div`
    width: 100%;
    height: fit-content;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const AddImage = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;  

    @media (max-width: 768px) {
      width: 98%;
      height: fit-content;
    } 
    
`

const ImgPreview = styled.img`
    width: 100%;
    height: auto;
    border-radius: 10px;
`

const MembersList = styled.div`
    max-width: 250px;
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-content: flex-start;
    height: fit-content;

`

const AddDirectoryMember = styled.div`
    position: relative;
    border: ${ props => props.remove ? '1px solid red': '1px solid grey'};
    cursor: pointer;
    width: 100%;
    height: fit-content;
    margin: 30px 10px;
    padding: 5px;
    border-radius: 8px;
    align-self: center;
    justify-self: center;
    color: #5a5a5a;
    display: flex;
    font-size: smaller;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.4s;
    .viewToolTip{
        position: absolute;
        margin-top: 20px;
        display: none;

      }
    &:hover{
      .viewToolTip{
        margin-top: 20px;
        display: flex;
        
      }
      background-color: ${ props => props.remove ? 'red': 'grey'};
      color: ${ props => props.remove ? 'white': 'white'};
    }
`
const AddUserInputWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: fit-content; 
    width: 100%;
    margin-top: 20px; 
    padding: 10px;
    justify-self: center;
    align-self: center;
    border-radius: 10px;

    @media (max-width: 768px) {


    }
`
const AddUserInputHeading = styled.div`
  border-radius: 10px 10px 0px 0px;   
  width: 100%; 
  padding: 5px;
  margin-bottom: 10px;
  color: var(--lightThemeFontColor);
`

const AddUserBtnWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px
`

const AddUserBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90px;
  justify-content: center;
  background-color: #dfdfdf;
  font-size: smaller;
  padding: 10px 5px;
  border-radius: 5px;
  color: #4e4e4e;
  cursor: pointer;
  transition: all 0.5s;
  &:hover{
    color: white;
    background-color: green;
  }
`

const IconInput = styled.div`
    position: absolute;
    padding: 0px;
    color: #a3a3a3;
    top: 1px;
    left: 5px;
    font-size: 11px;
`

const SaveBtn = styled.button`
  pointer-events: ${props => props.disabled ? 'none':'auto'};
  width: fit-content;
  border: none;
  background-color: green;
  padding: 8px 25px;
  color: white;
  border-radius: 5px;
  &:hover{
    cursor:pointer;
    background-color: rgb(0, 156, 0);
  }
`
const SubmitButtonWrap = styled.div`
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    
`

const SubmitWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  margin-top: 20px;

`


const DiscardBtn = styled.div`
  padding: 5px;
  color: #912121;
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
  font-size: smaller;

`

export default MyDirectoryItem