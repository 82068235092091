import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    imagePreview: {},
    image: {}
}

export const imageSlice = createSlice({
    name: 'image',
    initialState,
    reducers: {
        setImagePreview: (state, action) => {
            state.imagePreview = action.payload
        },
        chooseImage: (state, action) => {
            state.image = action.payload
        },
        removeImage: (state) => initialState,
    },
})

export const { setImagePreview, chooseImage, removeImage } = imageSlice.actions

export default imageSlice.reducer