import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RefreshIcon from '@mui/icons-material/Refresh';
import { FaLock } from "react-icons/fa";
import { InputWrap } from 'components/forms/forms.styled';
import { useSelector } from 'react-redux';

export default function ChurchUsernameChange() {

    const [ name, setName ] = useState('')
    const { user } = useSelector((state) => state.auth)


    return (
        <ChurchUsernameChangeCont>
            <TabsSection> 
                <SelectedTab>
                    <TabText> 
                        <img 
                            style={{width: '15px', height: '15px'}}
                            src={require('./favicon.ico')}
                            alt='logo'
                            />
                        <span>Praysely</span>

                    </TabText>
                </SelectedTab>
            </TabsSection>
            <SearchBarSection>

                <IconsWrap>
                    <ArrowBackIcon />
                    <ArrowForwardIcon  />
                    <RefreshIcon /> 
                </IconsWrap>


                <SearchBarCont>
                    <SearchBarInputContainer>
                        <FaLock style={{fontSize: '11px'}} /> 
                        <input  
                            value={`praysely.com/${user.name} `}
                            />
                    </SearchBarInputContainer>
                    
                </SearchBarCont>
            </SearchBarSection>


            <ChangeUsernameSection>


            </ChangeUsernameSection>

        </ChurchUsernameChangeCont>
    )
}


const ChurchUsernameChangeCont = styled.div`
    border-radius: 10px;
    width: 600px;
    height: 400px;
    background-color: #f1f1f1;
    box-shadow: 0px 0px 10px #ccc;
`
const SearchBarSection = styled.div`
    width: 100%;
    height: 50px;
    background-color: white;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 20px;
    font-weight: 400; 
    padding: 5px;
`

const IconsWrap = styled.div`
    width: 100px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    svg{
        margin: 5px;
        color: var(--lightThemeFontColor);
    }
    `




const SearchBarCont = styled.div`
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 20px;
    font-weight: 600; 
`

const SearchBarInputContainer = styled.div`
    padding: 5px 20px;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    color: var(--lightThemeFontColor);
    font-weight: 400;
    border-radius: 20px;
    background-color: #f1f3f4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    input{
        margin-left: 10px;
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        font-size: 15px;
        font-family: 'Roboto', sans-serif;
        color: var(--lightThemeFontColor);
        font-weight: 400;
        background-color: #f1f3f4;
    }
`



const SelectedTab = styled.div`
    width: 150px;
    height: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start; 
    border-radius: 10px 10px 0px 0px;
`

const TabText = styled.div`
    font-size: 13px;
    font-weight: 400;
    color: var(--lightThemeFontColor);
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; 
    padding-left: 10px;
    span{
        margin-left: 10px;
    }
`

const TabsSection = styled.div`
    width: 100%;
    height: 40px;
    background-color: #e8eaed;
    display: flex;
    align-items: center;
    padding: 5px 5px 0px 10px;
    font-size: 20px;
    font-weight: 600; 
    border-radius: 10px 10px 0px 0px;
`

const ChangeUsernameSection = styled.div`
    width: 100%;
    height: calc(100% - 90px); 
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    font-size: 20px;
    font-weight: 600; 
    padding: 20px; 
    border-radius: 0px 0px 10px 10px;
`