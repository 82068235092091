import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import announcementService from './announcementService'

const errorMessage = (error) => {
    return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
  }

const initialState = {
  announcements: [],


  isAnnouncementCreatedError: false,
  isAnnouncementCreatedSuccess: false,
  isAnnouncementCreatedLoading: false,
  announcementsErrorMessage: '',

  isAnnouncementDeletedError: false,
  isAnnouncementDeletedSuccess: false,
  isAnnouncementDeletedLoading: false,
  announcementsDeleteErrorMessage: '',

  isAnouncementsSuccess: false,
  isAnouncementsLoading: false,
  isAnouncementsError: false,

  totalAnouncements: 0,
  totalPages: 0,
  hasPrevPage: false,
  nextPage:null,
  currentPage: 0,
  prevPage: null,  
  hasNextPage: false,
  hasFilter: false,
  filterValue: null,
  hasSortValue: null,
  sortValue: null,

  showCreateAnnouncementModal: false,
  ancmtImagePreview: {},
  ancmtImage: {}
}

export const createAnnouncement = createAsyncThunk(
    'announcement/create',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await announcementService.createAnnouncement(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const getAnnouncements = createAsyncThunk(
    'announcement/get',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await announcementService.getAnnouncements(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )
  export const deleteAnnouncement = createAsyncThunk(
    'announcement/delete',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await announcementService.deleteAnnouncement(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const announcementSlice = createSlice({
    name: 'announcement',
    initialState,
    reducers: {
        announcementReset: (state) => initialState,
        setViewCreateAncmtModal: (state) => {
          state.showCreateAnnouncementModal = true
        },
        closeCreateAncmtModal: (state) => {
          if(state.isAnnouncementCreatedSuccess){
            state.isAnnouncementCreatedSuccess = false
          }
          state.showCreateAnnouncementModal = false
        },
        setAncmntImagePreview: (state, action) => {
          state.ancmtImagePreview = action.payload
        },
        chooseAncmntImage: (state, action) => {
          state.ancmtImage = action.payload
        },
        removeAncmntImage: (state, action) => {
          state.ancmtImage = {}
          state.ancmtImagePreview = {}
        },
    },
    extraReducers: (builder) => {
      builder
        .addCase(createAnnouncement.pending, (state) => {
          state.isAnnouncementCreatedLoading = true
        })
        .addCase(createAnnouncement.fulfilled, (state, action) => {
          state.isAnnouncementCreatedLoading = false
          state.isAnnouncementCreatedSuccess = true
          state.announcements.unshift(action.payload)
        })
        .addCase(createAnnouncement.rejected, (state, action) => {
            state.isAnnouncementCreatedLoading = false
            state.isAnnouncementCreatedError = true
            state.announcementsErrorMessage = action.payload
        })
        //------------------
        .addCase(getAnnouncements.pending, (state) => {
          state.isAnouncementsLoading = true
        })
        .addCase(getAnnouncements.fulfilled, (state, action) => {
          state.isAnouncementsLoading = false
          state.isAnouncementsSuccess = true
          
          state.announcements.push(...action.payload.ancmtsArray)
          state.totalAnouncements = action.payload.totalAnouncements
          state.totalPages = action.payload.totalPages
          state.currentPage = action.payload.currentPage
          state.hasNextPage = action.payload.hasNextPage
          state.prevPage = action.payload.previousPage
          state.nextPage = action.payload.nextPage
          state.filterValue = action.payload.filter
          state.sortValue = action.payload.sortValue

        })
        .addCase(getAnnouncements.rejected, (state, action) => {
            state.isAnouncementsLoading = false
            state.isAnouncementsError = true
            state.announcementsErrorMessage = action.payload
        })
        //------------------
        .addCase(deleteAnnouncement.pending, (state) => {
          state.isAnnouncementDeletedLoading = true
        })
        .addCase(deleteAnnouncement.fulfilled, (state, action) => {
          state.isAnnouncementDeletedLoading = false
          state.isAnnouncementDeletedSuccess = true
          state.announcements = state.announcements.filter(
            (post) => post._id !== action.payload.id
          )
        })
        .addCase(deleteAnnouncement.rejected, (state, action) => {
            state.isAnnouncementDeletedLoading = false
            state.isAnnouncementDeletedError = true
            state.announcementsErrorMessage = action.payload
        })
        //------------------
    },
})

export const { 
  announcementReset, 
  closeCreateAncmtModal, 
  setViewCreateAncmtModal,
  chooseAncmntImage,
  setAncmntImagePreview

} = announcementSlice.actions
export default announcementSlice.reducer