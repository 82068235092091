import axios from 'axios' 
import { baseUrl } from '../constants'
const URL = baseUrl  

 
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }

 
const getListings = async (data, token) => {
  const response = await axios.post(URL+'/api/marketplace/getListings', data, config(token))
  return response.data
}

const addListing = async (data, token) => {
  const response = await axios.post(URL+'/api/marketplace/addListing' , data, config(token))
  return response.data
}

const searchListings = async (data, token) => {
  const response = await axios.post(URL+'/api/marketplace/searchListings' , data, config(token))
  return response.data
}

const marketService = {
    getListings, addListing, searchListings
}
export default marketService