import { create } from "zustand";
import produce from "immer";


const iState = {

    isEdited: false,
    areFeaturesEdited: false,

    settingsInfo: { 
        location: 'Location info',
        profileImg: 'Profile image, this is ususally enabled. If disabled your church name will still be displayed',
        description: 'Allow non members to view your church description',
        stats: 'Members count and date of account creation',
        people: 'Display cards of church leaders as displayed in the about section of your profile',
        bulletin: 'Allow non members to view and download your church bulletin. Only the latest bulletin will be displayed',
    },      
}

export const settingsStore = create((set) => ({

    ...iState,

    setItem: (payload) => set(produce((state) => {
        state[payload.item] = payload.value
    })),

    setIsEdited: (payload) => set(produce((state) => {
        state.isEdited = payload
    })),
 
 

}));