import React, { useEffect, useMemo } from 'react'
import { ViewContainer } from './giving.styled'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom' 
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Initialize from './Give/Initialize'
import ConfirmPayment from './Give/ConfirmPayment'
import PaymentSuccess from './Give/PaymentSuccess'
import { pkStripe } from 'features/constants';

// const stripePromise = loadStripe( pkStripe, {  stripeAccount: sessionStorage.getItem('cpk') } );
// pk_test_51MiUs9FN6wKpuMYyWcBgz0RQhp2CPlkedozNx1ry9KePdrAPH4UNy1uOG8wG6TA8Jat6B8uodfoxVlEMBO4Bv5tQ00LZRgV979

export default function GiveToChurch() {

    const navigate = useNavigate() 
    const location = useLocation();
    const status =  new URLSearchParams(location.search).get('status') ? new URLSearchParams(location.search).get('status') : 'none'
 
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { clientSecret } = useSelector((state) => state.giving)

    // const stripePromise = loadStripe( pkStripe,  { stripeAccount: profileChurch.stripeId } );

    // wrap the stripePromise in a useMemo to prevent it from being recreated on every render
    const stripePromise = useMemo(
        () => loadStripe( pkStripe,  { stripeAccount: profileChurch.stripeId } ), 
        [profileChurch.stripeId]
    )

    useEffect(() => {        
        if(!status || status === 'none'){
            navigate('?section=giving&v=give&status=initialize')
        } 
    }, [status, navigate])

    return ( 
        <ViewContainer> 
            { status === 'initialize' ?
                (
                    <Initialize />
                )
                : status === 'confirm' ?
                (
                    (stripePromise && clientSecret) ?
                    (
                        <Elements 
                            stripe={stripePromise} 
                            options={{clientSecret}}
                            > 
                            <ConfirmPayment />
                        </Elements>
                    )
                    :
                    (
                        // this is what can happen if a user refreshes the page
                        // while trying to confirm payment
                        // they payment intent will be lost
                        // so we need to redirect them to the initialize page
                        
                        <Initialize />

                    )
                )
                : status === 'success'&&
                (
                    <PaymentSuccess />
                ) 
            }
        </ViewContainer>
    )
}
