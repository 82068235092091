import React, {useState, useEffect } from 'react'
import styled from 'styled-components'  
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import { useSelector, useDispatch } from 'react-redux'
import { setViewCreateGivingCampaignModal, setFormStep, createGivingCampaign, setErrorField, resetInitialState, resetCreateCampaign } from 'features/givingCampaigns/givingCampaignSlice'
import Step1Description from './form/Step1Description'
import Step2Details from './form/Step2Details'
import Step3Images from './form/Step3Images'
import { GoArrowRight, GoArrowLeft  } from "react-icons/go";
import Step4Review from './form/Step4Review'
import SpinnerSmall from 'components/SpinnerSmall'


export default function CreateCampaignModal() {

    const dispatch = useDispatch()

    const { 
        formStep, images, title, description, goal,
        hasGoal, hasEndDate, endDate,
        createGivingCampaignLoading, 
     } = useSelector((state) => state.givingCampaign)

    const handleClose = () => {
        
        dispatch(setViewCreateGivingCampaignModal(false))
        dispatch(resetCreateCampaign())
    }
  
    const handleFormSubmit = () => {

 
        let fileSrcArray = []
        images.forEach(image => {
            fileSrcArray.push(image.fileSrc)
        })

        let formData = new FormData()
        formData.append('title', title)
        formData.append('description', description)
        formData.append('goal', goal) 
        formData.append('endDate', endDate)
        fileSrcArray.forEach((fileSrc, index) => {
            formData.append('files', fileSrc)
        })
        dispatch(createGivingCampaign(formData)) 
    }

    const handleNext = () => {

        if(formStep === 1){

            if(!title || !description){
                if(!title){
                    dispatch(setErrorField({ key: 'title', value: true }))
                }
                if(!description){
                    dispatch(setErrorField({ key: 'description', value: true }))
                }
                return
            }
            

        }else if(formStep === 2){

            if((!goal && hasGoal)){
                if(!goal && hasGoal){
                    dispatch(setErrorField({ key: 'goal', value: true }))
                } 
                return
            }

        }else if(formStep === 3){
            if(images.length === 0){
                return
            }
        }

        dispatch(setFormStep(formStep + 1))
    }

    return (
        <CreateCampaignModalContainer>
            <ModalCloseButton handler= {() => handleClose()} top='20px' right='20px' size='35px' /> 
            <SCCT>
                <CCT>
                    Create Campaign
                </CCT>
                <CCTStep>
                   Step {formStep} of 4
                </CCTStep>
            </SCCT>

            {
                formStep === 1 ? (
                    <Step1Description />
                ) : formStep === 2 ?
                (
                    <Step2Details />
                ) :   formStep === 3 ? (
                    <Step3Images />
                ) : formStep === 4 ? (
                    <Step4Review />
                ) : null
            }
            <StepsButtons>
                <StepButton 
                    display={'flex'}
                    disabled={formStep === 1}
                    onClick={() => dispatch(setFormStep(formStep - 1))}
                    >
                    <GoArrowLeft /> Back 
                </StepButton>
                <StepButton 
                    display={'flex'}
                    disabled={formStep === 4}
                    onClick={handleNext}
                    >
                    Next <GoArrowRight />
                </StepButton>
                <StepButton
                    display={formStep === 4 ? 'flex' : 'none'}
                    disabled={formStep !== 4}
                    loading={createGivingCampaignLoading}
                    onClick={handleFormSubmit}
                    >
                    {
                        createGivingCampaignLoading ? 
                        (
                            <SpinnerSmall />
                        )
                        :
                        (
                            'Submit'
                        )
                    }
                </StepButton>
            </StepsButtons> 
        </CreateCampaignModalContainer>
    )
}

const StepButton = styled.div`
    padding: 10px 20px;
    border: none;
    border-radius: 5px;;
    background-color: ${props => props.disabled ? 'lightgray' : 'var(--themeColor)'};
    pointer-events: ${props => props.disabled || props.loading ? 'none' : 'auto'};
    color: white;
    cursor: pointer;
    display: ${props => props.display};   
    visibility: ${props => props.disabled ? 'hidden' : 'visible'};
    align-items: center;
    justify-content: center;
    svg {
        margin-right: 5px;
        margin-left: 5px;
    }
    &:hover {
        background-color: var(--themeColorLight);
    }
`

const StepsButtons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
`

 

const CreateCampaignModalContainer = styled.div`
    background-color: white;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 700px;
    height: 500px; 
    overflow-y: scroll;

    /* hide scroll bar */
    /* width */ 
    ::-webkit-scrollbar {
        width: 0;
    } 
    border-radius: 10px;

    @media (max-width: 768px){ 
        width: 100%;
        height: 80vh;
        /* display scroll bar */

        /* width */
        ::-webkit-scrollbar {
            width: 5px;
        }
    }

`

const SCCT = styled.div`
    font-size: 20px; 
    color: var(--lightThemeFontColor);
    margin: 20px;
`

const CCT = styled.div`
    font-size: 20px;
    color: var(--lightThemeFontColor);
    font-weight: bold;
`
const CCTStep = styled.div`
    font-size: 14px;
    color: var(--lightThemeFontColor);

`