import PostItemFilter from "../postComponents/PostItemFilter"
import CreatePostBtn from './CreatePostBtn'
import SendMessageBtn from './SendMessageBtn'
import styled from "styled-components"

function UserProfileTop() {
  return (
    <PostFeedTop>
        <PostItemFilter />
    </PostFeedTop>
  )
}

export default UserProfileTop

const PostFeedTop = styled.div`
    margin-right: 20px;
    margin-top: 40px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

`