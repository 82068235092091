import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getMemberOf, getMembers } from '../../../features/memberships/membershipSlice'
import MemberOf from './MemberOf'
import MyMembers from './MyMembers'
function MembersWrap() {
    const dispatch = useDispatch()
    const { profile, isError, message, isLoading, hasUpdated } = useSelector((state) => state.profile)
    const { user } = useSelector((state) => state.auth)
  

    return (
        <>
                { user && (user.isOrganization || user.isChurch) ?
                    (
                        < MyMembers />
                    )
                    :
                    (
                        < MemberOf />
                    ) 
                }
                
        </>
    )
}

export default MembersWrap