import React, { useEffect, Suspense, lazy } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { getSubscription } from 'features/billing/billingSlice'
import Spinner from 'components/Spinner'
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import SubItem from './SubItem' 
import SubscribeModal from './SubscribeModal'
import SubSignuUpItem from './SubSignuUpItem'
import { ViewHeading } from '../settings.styled';

export default function Subscriptions() {
  const SubsciptionSignUp = lazy(() => import('./SubsciptionSignUp'))


  const dispatch = useDispatch()
  const { 
    subscription, 
    subscriptionInterval,
    viewSubscribeModal,
    isGetSubscriptionLoading,
    isCreateSubscriptionSuccess,
    isGetSubscriptionSuccess,
  } = useSelector((state) => state.billing)
  
  useEffect(() => {
    if(!isCreateSubscriptionSuccess){
      dispatch(getSubscription())
    }
    
  }, [isCreateSubscriptionSuccess, dispatch])


  return (
    <SubscriptionsContainer>
      <ViewHeading> <CardMembershipIcon/><span style={{marginLeft: '10px'}}>Subscriptions</span>   </ViewHeading>

    { isGetSubscriptionLoading ? 
      (
          <SpinnerWrap> <Spinner  /> </SpinnerWrap>
      ) 
      : 
      ( isGetSubscriptionSuccess &&
        (
          <>


            { subscription ? 
              ( 
                <SubItem data={subscription} />    
              )
              :
              (
                <Suspense fallback={<div>Loading...</div>}> 
                  <SubsciptionSignUp />
                </Suspense>
              )
            }


            { subscription && subscriptionInterval === 'month' &&
              (
                <SubSignuUpItem 
                  price={subscription.plan.amount === 999 ? ('$99.00') : ('$150.00')}
                  interval='yearly' 
                  changeToYearly={true}
                  />
              )
            }  
          </>    
        )
      )
    }

    { viewSubscribeModal && (
        <SubscribeModal />
      )
    }

    </SubscriptionsContainer>
  )
}

const SpinnerWrap = styled.div`margin-top: 10px;`

const SubscriptionsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
 