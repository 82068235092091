import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux' 
import { useNavigate, useLocation } from 'react-router-dom'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'; 
import { imgUrl } from 'features/constants' 
import useGetWindowSize from 'hooks/useGetWindowSize'
import ChurchProfileTopNavDesktopView from './ChurchProfileTopNavDesktopView' 
import useToggleDropdown from 'hooks/useToggleDropdown'
import ChurchProfileNavMenu from './ChurchProfileNavMenu' 
import {
    PCContainer,
    PCTopInfo,
    PCName,
    PCTNameWrap,
    PCNav,  
    NavWrap,
    MenuWrap,
    PCNavMenuIcon, 
} from './extras.styled' 
import { IoMenu } from "react-icons/io5"; 

function ChurchProfileTopInfo() {
    const { user } = useSelector((state) => state.auth)
    const { profileChurch, churchFeatures, activeSection } = useSelector((state) => state.profileChurch)

    const location = useLocation(); 
    const section =  new URLSearchParams(location.search).get('section') ? new URLSearchParams(location.search).get('section') : 'none'
    const [width] = useGetWindowSize()
    const [features, setFeatures] = useState(null) 
    const [dropdownMenuItems, setDropdownMenuItems] = useState([])


    const ref = useRef(null)
    const [showMenu, setShowMenu] = useToggleDropdown(ref)

    const toggleDropdown = () => {
        setShowMenu.toggle(!showMenu)
    }


    useEffect(() => {

        if(width < 768){

            // here we are setting the dropdown menu items to all the features

            setDropdownMenuItems([...churchFeatures.main, ...churchFeatures.extra])
        }
        else{ 
            // here we get the remaining items from churchFeatures.main and all the items from churchFeatures.extra 
            // exclude the first 4 items from churchFeatures.main
            const main = churchFeatures.main.slice(4)  
            setDropdownMenuItems([...main, ...churchFeatures.extra])
        }

    }, [width, churchFeatures])

    
  return (
    <PCContainer>
                    {/* {!user.accountVerified && (user.isChurch || user.isOrganization) && (
                            <AccountVerified onClick={() => navigate('/settings/verify-account')}> <span>Account not yet verified. <LearnMore>Learn More</LearnMore></span> </AccountVerified>
                        )  } */}
        <PCTopInfo>
            <PCName>
                <PCTNameWrap>
                    <img 
                        src={`${imgUrl}/profile/${profileChurch.profileImg.imagePath2}`} 
                        alt="" 
                        style={{width: '35px', height: '35px', borderRadius: '50%', objectFit: 'cover', marginRight: '15px'}}
                        />
                    <span>{profileChurch.fullName}</span>
                    {
                        (
                            (user.accountVerified && (user.isChurch || user.isOrganization)) 
                            || 
                            profileChurch.accountVerified 
                        ) &&
                        (
                            <VerifiedUserIcon style={{color: 'green', marginLeft: '5px', fontSize: 'smaller'}}/>
                        )
                    }
                </PCTNameWrap>

                <PCNav>
                    {
                        width > 768 ? (
                            <NavWrap>  
                                <ChurchProfileTopNavDesktopView /> 

                                <PCNavMenuIcon
                                    ref={ref}
                                    active={false}  
                                    onClick={toggleDropdown}
                                > 
                                    <IoMenu  style={{ fontSize: '20px', }} />    
                                </PCNavMenuIcon>

    

                                { showMenu && (  
                                    <ChurchProfileNavMenu 
                                        items= {dropdownMenuItems}
                                        /> 
                                )}

                            </NavWrap>
                            
                        ):(
                            <MenuWrap >
                                <PCNavMenuIcon
                                    ref={ref}
                                    active={false}  
                                    onClick={toggleDropdown}
                                > 
                                    <IoMenu  style={{ fontSize: '20px', }} />     
                                </PCNavMenuIcon>
                                { showMenu && (  
                                    <ChurchProfileNavMenu 
                                        items= {dropdownMenuItems}
                                        /> 
                                )}
                            </MenuWrap> 
                        )
                        
                    }

                    </PCNav> 
            </PCName>
        </PCTopInfo>
    </PCContainer>
  )
}

export default ChurchProfileTopInfo
