import ModalCloseButton from 'components/buttons/ModalCloseButton'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { setViewEditVideoModal, updateVideo } from 'features/videos/videoSlice'
import { FileUploadButton } from 'components/files/files.styled'
import SpinnerSmall from 'components/SpinnerSmall'
import { LuListVideo } from "react-icons/lu";
import { MediaUpdateModal } from '../media.styled'

export default function EditVideoModal() {

    useEffect(() => { if(!videoData){ dispatch(setViewEditVideoModal(false))}}, [])
    
    const dispatch = useDispatch()

    const { videoData, videoUpdateLoading, videoUpdateSuccess, videoUpdateError } = useSelector(state => state.video)
    const { profileChurch } = useSelector(state => state.profileChurch)

    const [title, setTitle] = useState(videoData.title ? videoData.title : 'Video Title')

    const handleEditVideo = () => {
        dispatch(updateVideo({
            videoId: videoData._id, 
            title: title,
            churchId: profileChurch._id
        }))
    }

    return (
        <MediaUpdateModal> 
            <ModalCloseButton
                handler={() => dispatch(setViewEditVideoModal(false))}
                top={'20px'} right={'20px'} size='30px'
                />

            <h1> 
                <LuListVideo style={{marginRight: '10px', fontSize: '20px'}}  />
                Edit Video
            </h1>

            <EVFC>
                <label>Title</label>
                <input type='text' 
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />

                {
                    videoData.origin !== 'youtube' &&
                    (
                        <>
                            <label>Thumbnail</label> 
                            <input type='text' defaultValue={videoData.thumbnail} />
                        </>
                    )
                }

            </EVFC>

            <div
                style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '40px'}}
                >
                <FileUploadButton
                    disabled={videoUpdateLoading}
                    onClick={handleEditVideo}
                    >
                    {
                        videoUpdateLoading ? 
                        (
                            <SpinnerSmall />
                        )
                        :
                        (
                            'Save'
                        )
                    }
                </FileUploadButton>
            </div> 
        </MediaUpdateModal>
    )
}

const EVFC = styled.div`    
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    transition: all 0.6s ease-in-out;

    label{
        font-size: 13px;
        color: var(--lightThemeFontColor);
        margin-bottom: 5px;
    }

    input{
        padding: 10px;
        border: 1px solid var(--lightThemeBorderColor);
        color: var(--lightThemeFontColor);
        border-radius: 5px;
        background-color: aliceblue;
        border: 2px solid transparent;
    } 

    /* on input focus */
    input:focus{
        outline: 2px solid var(--themeColor);
    }

` 