import {React, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { closeModal } from '../../features/modal/modalSlice'
import { reportPost } from '../../features/reportIssue/reportIssueSlice'

function ReportPostModal2() {
  const dispatch = useDispatch()
  const {user} = useSelector((state) => state.auth)

  const { isIssueSuccess, isCreateIssueSuccess, isIssueError, isIssueLoading } = useSelector((state) => state.issues)
  const { singlePost } = useSelector((state) => state.singlePost)
  const [messageText, setMessageText] = useState('')
  const [option, setOption] = useState('')

  const { 
    postId,
    creatorId, 
    fromPostItem,
    fromPostComment, 
    postContent,
    postText,
    commentId, 
    commentText,
    postedToId,
    postType,
    residingId,
    residingName
    } = useSelector((state) => state.modal)


  const handleMessage = (e) => {
    if(messageText.length > 150){return toast.error('Not allowed')}
    setMessageText(e.target.value.replace(/[\|&;\$%@"<>\(\)\+#{}]/g, ""));
    console.log(messageText)
  }

  const handleOption = (e) => {
    setOption(e.target.value)
    console.log(`setting option ${option} ...`)
  }

  const handleSubmit = () => {
    if(messageText.length < 150 && option){
      //include postID
      if(fromPostItem || fromPostComment){
        dispatch(reportPost({
          // reporterId: user._id, accessible in the backend
          creatorId: creatorId,
          residingId: residingId,
          residingName: residingName,
          postType: postType,
          postId: postId,
          commentId: commentId,
          reportReason: option,
          message: messageText
        }))
      }else{
          dispatch(reportPost({
            // reporterId: user._id, accessible in the backend
            creatorId: creatorId,
            residingId: residingId,
            residingName: residingName,
            postType: 'post',
            postId: postId,
            commentId: commentId,
            reportReason: option,
            message: messageText
          }))
      }
    }
  }

  useEffect(() => {
      if(isCreateIssueSuccess){
        toast(`Report submitted. Thank you ${user.name}`)
        dispatch(closeModal())
      }
  }, [isCreateIssueSuccess, dispatch])

  return (
    <>
     <IssueModalContainer>
      <IssueModalWrap>
        <CloseButton onClick={() => dispatch(closeModal())}>Close</CloseButton>
        <ModalHeading>Report {fromPostComment ? ('Comment'):('Post')}</ModalHeading>
        {
          fromPostItem ?
          (
            <ReportedText>{postText.slice(0, 30)} {postText.length > 30 && ('...')}</ReportedText>
          )
          : fromPostComment && 
          (
            <ReportedText>{commentText.slice(0,30)}  {commentText.length > 30 && ('...')}</ReportedText>
          )

        }
          <IssueModalForm>
            <Select onChange={handleOption}>
              <option value="" hidden>Select Type</option>
              <option value="Innapropriate">Innapropriate</option>
              <option value="Hateful">Hateful</option>
              <option value="Misrepresentation">Misrepresentation</option>
            </Select>
            <textarea value={messageText} onChange={handleMessage} rows = "5" cols = "30" maxLength = "150"  placeholder='tell us more (optional)...'/>
            <IssueSubmit onClick={handleSubmit}> Submit </IssueSubmit>
          </IssueModalForm>
        </IssueModalWrap>
      </IssueModalContainer>

    </>
    
  )
}

const ReportedText = styled.div`
  padding: 15px;
  background-color: grey;
  border-radius: 10px;

`

const IssueModalContainer = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
`

const IssueModalWrap = styled.div`
  z-index: 2;
  width: 450px;
  min-height: 320px;
  max-height: fit-content;
  background: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  border-radius: 8px;
  position: relative;
  animation: animate 0.3s;
  padding: 5px 5px 20px 5px;
`

const ModalHeading = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
`
const IssueModalForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  margin-top: 10px;
  textarea{
    resize: none;
    padding: 10px;
    border: 1px solid green;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
  }
  textarea:focus{
    outline: none;
  }
  textarea::placeholder{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
    }

`
const CloseButton = styled.button`
  border: none;
  background-color: white;
  align-self: end;
  padding: 10px;
  &: hover{
    cursor:pointer;
    

  }
`
const IssueSubmit = styled.button`
  border: none;
  background-color: green;
  margin-top: 15px;
  padding: 15px;
  color: white;
  border-radius: 5px;
  &: hover{
    cursor:pointer;
    background-color: rgb(0, 156, 0);
  }
`

const Select = styled.select`
  width: 100%;
  height:35px;
  background: white;
  color: gray;
  font-size: 14px;
  border:none;
  border: green;
  margin-bottom: 15px;
       option {
         color: black;
         background: white;
         font-weight: small;
         display: flex;
         white-space: pre;
         min-height: 20px;
         padding: 30px;
         border-radius: 0px;
       }
`;
export default ReportPostModal2