import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import messageService from './messengerService'

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

const initialState = {
  messages: [],
  conversationType: '', // m2m or m2c
  currentConversation: '',

  isMessagesError: false,
  isMessagesSuccess: false,
  isMessagesLoading: false,
  messagesMessage: '',
  messagesErrorMessage: '',

  isCreateError: false,
  isCreateSuccess: false,
  isCreateLoading: false,
  createMessageError: '',

  isDeleteError: false,
  isDeleteSuccess: false,
  isDeleteLoading: false,
  deleteMessageError: '',

  lstMsgCnvId: null, //last message conversation id
  lstMsgUsrId: null,
  lstMsgUsrTyp: '',

  totalMessages: 0,
  limit: 0,
  totalPages: 0,
  page: 0,
  pagingCounter: 0,
  hasPrevPage: false,
  hasNextPage: false,
  prevPage: null,
  nextPage:null,
  isLoadingMore: false,

  hiddenUsers: null,

}

export const createMessage = createAsyncThunk(
  'messages/createMessage',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await messageService.createMessage(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getMessages = createAsyncThunk(
  'messages/getMessages',
  async (conversationId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await messageService.getMessages(conversationId, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getMoreMessages = createAsyncThunk(
  'messages/getMoreMessages',
  async (conversationId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await messageService.getMessages(conversationId, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const deleteMessage = createAsyncThunk(
  'messages/deleteMessage',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await messageService.deleteMessage(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const addNewMessageNotification = createAsyncThunk(
  'messages/addNewMessageNotification',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await messageService.addNewMessageNotification(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const messageSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    messageReset: (state) => initialState,

  },
  extraReducers: (builder) => {
    builder
      .addCase(getMessages.pending, (state) => {
        state.isMessagesLoading = true
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        state.isMessagesError = false
        state.isMessagesSuccess = true
        state.isMessagesLoading = false

        if(action.payload.isMember2member){

          state.messages.push(...action.payload.messages)

        }else{
          state.messages.push(...action.payload.messages)
        }

        state.conversationType = action.payload.conversationType
        state.totalMessages = action.payload.totalDocs
        state.limit = action.payload.limit
        state.totalPages = action.payload.totalPages
        state.page = action.payload.page
        state.pagingCounter = action.payload.pagingCounter
        state.hasPrevPage = action.payload.hasPrevPage
        state.hasNextPage = action.payload.hasNextPage
        state.prevPage = action.payload.prevPage
        state.nextPage = action.payload.nextPage
        state.hiddenUsers = action.payload.hiddenUsers
        
      })
      .addCase(getMessages.rejected, (state, action) => {
        state.isMessagesError = true
        state.isMessagesSuccess = false
        state.isMessagesLoading = false
        state.messagesMessage = action.payload
        state.messagesErrorMessage = action.payload
      })
      // -----------------------------------------------------------------------
     
      .addCase(getMoreMessages.pending, (state) => {
        state.isLoadingMore = true
      })
      .addCase(getMoreMessages.fulfilled, (state, action) => {
        state.isMessagesError = false
        state.isMessagesSuccess = true
        state.isLoadingMore = false

        if(action.payload.isMember2member){
          state.messages.push(...action.payload.messages)
        }else{
          state.messages.push(...action.payload.messages)
        }

        state.conversationType = action.payload.conversationType
        state.totalMessages = action.payload.totalDocs
        state.limit = action.payload.limit
        state.totalPages = action.payload.totalPages
        state.page = action.payload.page
        state.pagingCounter = action.payload.pagingCounter
        state.hasPrevPage = action.payload.hasPrevPage
        state.hasNextPage = action.payload.hasNextPage
        state.prevPage = action.payload.prevPage
        state.nextPage = action.payload.nextPage

      })
      .addCase(getMoreMessages.rejected, (state, action) => {
        state.isMessagesError = true
        state.isMessagesSuccess = false
        state.isMessagesLoading = false
        state.isLoadingMore = false
        state.messagesMessage = action.payload
      })
      // --------------------------------------------------------------------
     
     
     
     
      .addCase(createMessage.pending, (state) => {
        state.isCreateLoading = true
      })
      .addCase(createMessage.fulfilled, (state, action) => {
        
        state.isCreateError = false
        state.isCreateSuccess = true
        state.isCreateLoading = false
        state.messages.unshift(action.payload.message) //places the last created conversation to the top //can also be acheived with sorting query
        
        if(action.payload.convId !== state.lstMsgCnvId && !state.lstMsgCnvId){
          state.lstMsgUsrId = action.payload.otherUserId
          state.lstMsgUsrTyp = action.payload.otherUserType
        }else if(action.payload.convId === state.lstMsgCnvId && state.lstMsgCnvId){
          state.lstMsgUsrId = null
          state.lstMsgUsrTyp = null
        }

        state.lstMsgCnvId = action.payload.convId
      })
      .addCase(createMessage.rejected, (state, action) => {
        state.isCreateError = true
        state.isCreateSuccess = false
        state.isCreateLoading = false
        state.createMessageError = action.payload
      })
      // -----------------------------------------------------------------------
      .addCase(deleteMessage.pending, (state) => {
        state.isDeleteLoading = true
      })
      .addCase(deleteMessage.fulfilled, (state, action) => {
        state.isDeleteError = false
        state.isDeleteSuccess = true
        state.isCreateLoading = false
        state.messages = state.messages.filter(
          (ele) => ele._id !== action.payload.deletedMsgId
        )
      })
      .addCase(deleteMessage.rejected, (state, action) => {
        state.isDeleteError = true
        state.isDeleteSuccess = false
        state.isDeleteLoading = false
        state.deleteMessageError = action.payload
      })
  },
})

export const { messageReset } = messageSlice.actions
export default messageSlice.reducer