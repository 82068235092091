import React, { useEffect, useState } from 'react'
import styled, {keyframes} from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { convertTime } from '../billing/stripeUtilityFuncs'
import LoopIcon from '@mui/icons-material/Loop';
import { setViewAddConnectBankModal, refreshConnectAccountData, getConnectOnboardingLink, resetOnboardingLink } from 'features/giving/givingSlice'
import { requirementsData } from './RequirementData/requirementsData';
import AddConnectBankModal from './RequirementData/AddConnectBankModal';
import { Modal } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { FaEdit } from "react-icons/fa";



export default function RequirementsDue() {

    const dispatch = useDispatch()
    const [upcomingDeadline, setUpcomingDeadline] = useState(false)
    const [deadlineDateString, setDeadlineDateString] = useState('')

    const { user } = useSelector(state => state.auth)
    const { profileChurch } = useSelector(state => state.profileChurch)
    const { 
        viewAddConnectBankModal,
        connectAccount,
        requirementsForm,
        refreshConnectAccountDataLoading,
        churchConnectAccountStatusLoading,
        churchConnectAccountStatusSuccess,
        churchConnectAccountStatusError,
        churchConnectAccountStatusMessage,
        getConnectOnboardingLinkSuccess,
        getConnectOnboardingLinkError,
        getConnectOnboardingLinkMessage,
        getConnectOnboardingLinkLoading,
        onboardingLink
    } = useSelector(state => state.giving)

 
    useEffect(() => {

        let date = convertTime(connectAccount.requirements.current_deadline)

        if(connectAccount.requirements.current_deadline < Number((Date.now()/1000).toFixed(0))){
            setUpcomingDeadline(false)
        }else{
            setUpcomingDeadline(true) 
            setDeadlineDateString(`${date.month} ${date.day}, ${date.year}`)
        }

        // console.log(`${date.month} ${date.day}, ${date.year}`)
        // console.log(Number((Date.now()/1000).toFixed(0)))
        // console.log(connectAccount.requirements.current_deadline)
        // console.log(new Date(connectAccount.requirements.current_deadline).getTime())

    }, [connectAccount.requirements.current_deadline])
    

    const handleSubmitForm = () => {
        dispatch(getConnectOnboardingLink( ))
    }

    useEffect(() => {
        
        if(getConnectOnboardingLinkSuccess){
            window.open(onboardingLink, '_blank')
        }    
    

    }, [getConnectOnboardingLinkSuccess, onboardingLink])

    return (
        <RequirementsDueContainer>
           
            <TopSection> 

                <RefreshButton
                    loading={refreshConnectAccountDataLoading}
                    onClick={() => {
                        dispatch(resetOnboardingLink())
                        dispatch(refreshConnectAccountData())
                    }}
                    >
                    <LoopIcon />
                    {
                        refreshConnectAccountDataLoading ? ('Refreshing...') : ('Refresh')}
                </RefreshButton>
            </TopSection>

            <RequirementsHeading>
                To start receiving payments from your users, <br /> the following list of requirements are due
                {
                    upcomingDeadline && 
                    (
                        <span> by {deadlineDateString}</span>
                    ) 
                }
            </RequirementsHeading>
                
            <RequirementItemsContainer>
                {
                    connectAccount.requirements.currently_due.map((item, index) => {
                        return (
                            requirementsData[item] ? 
                            (
                                <DueItem key={index}>
                                    <CircleIcon /> 
                                    {requirementsData[item].label}
                                </DueItem>
                            )
                            :
                            (
                                null
                            ) 
                        )
                    })
                }
            </RequirementItemsContainer>


            <SubmitForm
                onClick={handleSubmitForm}
                >
                <FaEdit />
                Complete Requirements
            </SubmitForm>               
            

            <Modal
                open={viewAddConnectBankModal}
                onClose={() => dispatch(setViewAddConnectBankModal(false))}
                >
                    <div
                        style={{
                            width: '100vw',
                            height: '100vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        >
                        <AddConnectBankModal />
                    </div> 
            </Modal>

        </RequirementsDueContainer>
    )
}

const SpinAction = keyframes`
    100%   {transform: rotate(360deg)}
`

const DueItem = styled.div`

    display: flex;  
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
    font-size: 15px;

    svg{
        font-size: 5px;
        margin-right: 10px;
    }

`

const SubmitForm = styled.div`
    cursor: pointer;
    margin-top: 40px;
    width: fit-content;
    height: 40px;
    border-radius: 5px;
    background-color: #979797;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    transition : all 0.3s ease-in-out;
    svg{
        margin-right: 10px;  
    }

    &:hover{
        background-color: green;
    }

`

const RequirementItemsContainer = styled.div`   

    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start

`

const RequirementsHeading = styled.div`
    font-size: 18px; 
    margin-bottom: 20px; 
    text-align: left;
`
const TopSection = styled.div`
    display: flex;  
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
`
const RefreshButton = styled.button`
    cursor: pointer; 
    outline: none;
    border: none;
    box-shadow: 0px 0px 5px #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: #ffffff;
    color: grey; 
    border-radius: 5px;
    font-size: 12px;
    transition: all 0.3s ease-in-out;
    margin-right: 20px;

    svg{
        margin-right: 11px; 
    
        animation-name: ${props => props.loading ? SpinAction : 'none'};
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

    
    }


    &:hover{
        color: #4d4d4d;
        box-shadow: 0px 0px 5px #a2a2a2;
    }

`

const PayoutsStatus = styled.div`
    background-color: ${props => props.bg};
    color: #dcdcdc;
    padding: 10px 20px;
    border-radius: 5px; 
    font-size: 12px;
`

const RequirementsDueContainer = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

`