
import { useDispatch,  } from 'react-redux'
import { setViewCreatePostModal } from '../../features/posts/postSlice'
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import styled from 'styled-components';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ExtrasList, ExtrasIcon, ExtrasItem } from './extras.styled';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { language } from 'app/language';
import { useSelector } from 'react-redux';


function CreatePostBtn() {

  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const handleNav = () => {

    dispatch(setViewCreatePostModal(true))


    // if(user._id === profile._id){
    //   navigate(
    //     '/create-post', {
    //       replace: true,
    //     }
    //   )
    // }else{
    //   navigate(
    //     '/create-post', {
    //       replace: true,
    //       state: { 
    //         id: profile._id ?? null, 
    //         fullName: profile.fullName ?? null,
    //         userName: profile.userName ?? null
    //       }
    //     }
    //   )      
    // } commented out 12.25.2022


  }



  return (
    <CreatePostBtnContainer onClick={handleNav}>
        <PostAddIcon style={{fontSize:'25px'}} /> 
        <span>
          {language.createPost[user.preferredLanguage]}
        </span>
    </CreatePostBtnContainer>
  )
}

export default CreatePostBtn

const CreatePostBtnContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center; 
    align-items: center;
    justify-content: center;
    /* background-color: white; */
    margin-top: 18px;
    padding: 12px 5px;
    border-radius: 40px;
    /* color: white; */
    /* background-color: var(--themeColor); */
    border: 4px solid var(--themeColor);
    font-weight: 600;
    transition: all 0.3s ease-in-out;
 
    background-color: white;
    color: var(--themeColor);

    svg{
      margin-right: 11px;
    }

    &:hover{
      cursor: pointer;
      background-color: var(--themeColor);
      color: white; */

      svg{
        color: white;
      }

    }

    @media (max-width: 768px) {
      width: 100%;
      display: flex;
      justify-content: center;
      align-self: center; 
      align-items: center;
      justify-content: center;
      background-color: white;
      margin-top: 11px;
      margin-bottom: 10px;
      padding: 10px 15px;
      border-radius: 10px;
      color: white;
      background-color: var(--themeColor);
    }

`