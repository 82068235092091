import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const URL_UPLOAD = '/api/bulletins/upload'
const URL_GET = '/api/bulletins/get'
const URL_DEL = '/api/bulletins/delete'

const config = (token) => { return { 
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}`, }, 
  } }
  

const uploadBulletin = async (data, token) => {
    const response = await axios.post(URL+URL_UPLOAD, data, config(token))
    return response.data
}
const getBulletins = async (data, token) => {
    const response = await axios.post(URL+URL_GET, data, config(token))
    return response.data
}

const deleteBulletin = async (data, token) => {
    const response = await axios.post(URL+URL_DEL, data, config(token))
    return response.data
}

const bulletinService = {
    uploadBulletin, getBulletins, deleteBulletin
}

export default bulletinService