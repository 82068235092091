import React, { useEffect } from 'react'
import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { getPrayerWallItems, setViewPrayerWall } from 'features/prayerWall/prayerWallSlice';
import Loader from 'loaders/Loader';
import PrayerWallItem from './PrayerWallItem';
import { language } from 'app/language';


export default function PrayerWall() {

    const dispatch = useDispatch()
    const { 
        isGetPrayerWallItemsSuccess,
        isGetPrayerWallItemsLoading,
        isGetPrayerWallItemsError, 
        viewPrayerWall, 
        prayerWallItems 
    } = useSelector((state) => state.prayerWall)
    const { user } = useSelector((state) => state.auth)
    const { profileChurch } = useSelector((state) => state.profileChurch)

    const handleClickClose = () => {
        dispatch(setViewPrayerWall(false))
    }


    useEffect(() => {
        if(viewPrayerWall){
            dispatch(getPrayerWallItems({
                churchId: profileChurch._id
            }))            
        }


    }, [viewPrayerWall])


    return (
        <PrayerWallContainer>
            
            { viewPrayerWall && 
                
                (
                    <PrayerWallWrapper>
                        <PrayerWallHeader>
                            <h2>
                                {language.prayerWall[user.preferredLanguage]}
                            </h2>
                            <CloseButton onClick={handleClickClose} > <CloseIcon style={{fontSize: '16px'}}/> </CloseButton>
                        </PrayerWallHeader>       

                        <PrayerWallFeed>

                            { isGetPrayerWallItemsLoading ?  
                                (
                                    <Loader />
                                )
                                : isGetPrayerWallItemsSuccess && prayerWallItems.length > 0 && 
                                (
                                    prayerWallItems.map((item, index) => {

                                        return (
                                            <PrayerWallItem key={index} item={item} />
                                        )
                                    
                                    })
                                )
                            } 
                        </PrayerWallFeed>
                    </PrayerWallWrapper>

                )

            }

        </PrayerWallContainer>
    )
}

const PrayerWallContainer = styled.div`
    width: 100%;
    height: 100%; 
    padding: 10px;
    @media (max-width: 768px) {
        display: none;
    }
`

const PrayerWallWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: var(--lightThemeBackground);
`

const PrayerWallFeed = styled.div`
    width: 100%;
    height: 100%;
    background-color: var(--lightThemeBackground);
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`
const PrayerWallHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h2{
        font-weight: 300;
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        color: var(--lightThemeFontColor);        
    }
`

const CloseButton = styled.div`
    cursor: pointer;
    display: flex;
    color: var(--lightThemeFontColor);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    transition: all 0.5s ease-in-out;
    &:hover{
        background-color: #c4c4c4;
        color: white;
    }
`