import React, { useEffect, useState } from 'react'
import { SettingsMainContainer, SettingsViewContainer } from '../settings.styled'
import styled from 'styled-components'
import Toggle from 'react-toggle'
import "react-toggle/style.css" // for ES6 modules
import { getSettings, setSettings, updateSettings, resetSettings, setTestSettings } from 'features/profileChurch/profileChurchSlice'
import { settingsStore } from 'app/settingsStore'
import { useSelector, useDispatch } from 'react-redux'
import { SettingsMainBtn, SettingsMainBtnCancel } from '../settings.styled'
import { SDesc, SettingsBtnWrap, SettingsInfo, SettingsItem, ProfileSettingsContainer, SettingsItemWrap, SettingsNameWrap, SettingsName, ToggleButtonWrap } from './profileSettings.styled'
import Loader from 'loaders/Loader' 
import SettingsMenuItem from './SettingMenuItem'
import { toast } from 'react-toastify'

export default function ProfileFeatureSettings() {
    const dispatch = useDispatch()  
    const { 
        features,
        arePublicSettingsSaved,
        areFeaturesEdited,
        areFeaturesSaved,
        isChurchSettingsLoading,
        isChurchSettingUpdating, 
        isChurchSettingsSuccess 
    } = useSelector((state) => state.profileChurch)


    const handleToggleChange = (event) => { 
        // dispatch(setSettings({
        //     settingType: 'features',
        //     name: event,
        //     value: !features[event]
        // }))
    }
 
    const { isEdited, settingsInfo, setIsEdited } = settingsStore() 



    useEffect(() => { 
        dispatch(getSettings({
            settingType: 'features' 
        }))  
    }, [])

    useEffect(() => {
        if(areFeaturesSaved){

            toast.success('Settings updated')
        }
    }, [areFeaturesSaved])
         

    const handleSubmit = () => { 
      dispatch(updateSettings({
            settingType: 'features', 
            settings: features
      })) 
    }

    const handleCancel = () => { 
      setIsEdited(false) 
      dispatch(resetSettings())
    }


    return (
        <SettingsViewContainer>
            <SDesc>Choose the order of how these feautures should appear on your church profile and select which items should be in a dropdown menu.</SDesc> 
            <ProfileSettingsContainer> 
                {
                    (isChurchSettingsLoading || isChurchSettingUpdating) && 
                    (  
                        <div style={{ 
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}> 
                            <Loader /> 
                        </div>
                    )
                }
                <SettingsItemWrap> 
                { 
                    isChurchSettingsSuccess &&
                    (
                        features.main.map((setting, index) => {
                            return (
                                <SettingsMenuItem 
                                    key={setting.value}
                                    index={index}
                                    setting={setting}
                                    location={'main'}
                                    />
                            )
                        })
                    ) 
                }

                <ExtraItems>
                    <span>These items below will appear in a drop down menu in desktop view.  </span>

                    { 
                        isChurchSettingsSuccess &&
                        (
                            features.extra.map((setting, index) => {
                                return (
                                    <SettingsMenuItem 
                                        key={setting.value}
                                        index={index}
                                        setting={setting}
                                        location={'extra'}
                                        /> 
                                )
                            }) 
                        )
                    }
 
                </ExtraItems>

                </SettingsItemWrap>  
                { areFeaturesEdited && (
                    <SettingsBtnWrap>
                        <SettingsMainBtnCancel
                            disabled={isChurchSettingUpdating}
                            onClick={handleCancel}
                            >Cancel
                        </SettingsMainBtnCancel> 
                        <SettingsMainBtn
                            disabled={isChurchSettingUpdating}
                            onClick={handleSubmit}
                            >Save
                        </SettingsMainBtn>
                    </SettingsBtnWrap>
                )}
            </ProfileSettingsContainer>   
        </SettingsViewContainer>
    )
}


const ExtraItems = styled.div`
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

`