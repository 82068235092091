import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { FileInputWrap, FileModal, FileFormWrapper, FileUploadInput, FileUploadButton, ButttonWrap } from 'components/files/files.styled'
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import { setViewCreateFolderModal, createFolder, resetUploadState } from 'features/files/fileSlice'
import { useDispatch, useSelector } from 'react-redux'
import { BsFolderPlus } from "react-icons/bs";
import SpinnerSmall from 'components/SpinnerSmall'
import { CgRename } from "react-icons/cg";

export default function NewFolderModal() {

    const dispatch = useDispatch()
    const { isCreateFolderLoading, isCreateFolderSuccess } = useSelector(state => state.file)
    const { profileChurch } = useSelector(state => state.profileChurch)

    const folderName = useRef(null)
    const handleClose = () => {
        dispatch(setViewCreateFolderModal(false))
    }

    const handleCreateFolder = () => { 

        if(folderName.current.value === ''){
            return
        }

        dispatch(createFolder({
            churchId: profileChurch._id,
            title: folderName.current.value
        }))
    }

    useEffect(() => {
        if(isCreateFolderSuccess){
            dispatch(resetUploadState()) 
            dispatch(setViewCreateFolderModal(false))
        }
    }, [isCreateFolderSuccess])


    return (
        <FileModal>
            <ModalCloseButton
                top='10px'
                right='10px'
                size='30px'
                handler={handleClose}
            />

            <FileFormWrapper>
                <FIH>Create a new folder</FIH>

                <FileInputWrap>
                    <CgRename style={{ color: 'var(--lightThemeFontColor)'}} />

                    <FileUploadInput 
                        ref={folderName}
                        type="text" 
                        placeholder='Folder name' 
                        />
                </FileInputWrap>
            <ButttonWrap>
                <FileUploadButton
                    width={'fit-content'}
                    onClick={handleCreateFolder}
                    >
                    {
                        isCreateFolderLoading ? (
                            <SpinnerSmall />
                        )
                        :
                        (
                            <>
                                <BsFolderPlus style={{ marginRight: '10px'}} />
                                <span>Create</span>
                            </>
                        )
                    }
                </FileUploadButton>                 
            </ButttonWrap>

            </FileFormWrapper> 
        </FileModal>
    )
}


const FIH = styled.h3`
    color: var(--lightThemeFontColor);
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 500;
    width: 100%;
    text-align: left;

`