import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const API_URL = '/api/messages/createMessage'
const API_URL2 = '/api/messages/getMessages'
const API_URL3 = '/api/messages/deleteMessage'


//for sending the token
const config = (token) => { return { 
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}`, }, 
  } }

const createMessage = async (data, token) => {
    const response = await axios.post(URL+API_URL, data, config(token))
    return response.data
}

const getMessages = async (conversationId, token) => {
    const response = await axios.post(URL+API_URL2, conversationId, config(token))
    return response.data
}

const deleteMessage = async (data, token) => {
    const response = await axios.post(URL+API_URL3, data, config(token))
    return response.data
}//if I delete a converation, it sould only be deleted from my side, the other user should still see 

const addNewMessageNotification = async (data, token) => {
    const response = await axios.post(URL+'/api/messages/addNewMessageNotification', data, config(token))
    return response.data
}

const messageService = { 
    createMessage,
    getMessages,
    deleteMessage,
    addNewMessageNotification
}
export default messageService
