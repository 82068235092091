 
import '../../css/pages/directory.css' 
import React, {useRef, useEffect} from 'react' 
import DirectorySearch from '../../components/directory/DirectorySearch' 
import DirectorySettings from '../../components/directory/DirectorySettings'
import DirectoryCards from '../../components/directory/DirectoryCards'
import styled from 'styled-components' 
import DirectorySort from 'components/directory/DirectorySort'
import { MobilePageTitle } from 'components/main.styled'
import { BiCake } from "react-icons/bi";
import { useDispatch, useSelector } from 'react-redux' 
import Tooltip from '@mui/material/Tooltip';
import useToggleDropdown from 'hooks/useToggleDropdown'
import BirthdaysDropdown from 'components/directory/BirthdaysDropdown'
import useGetWindowSize from 'hooks/useGetWindowSize'
import { addAc } from 'features/auth/authSlice'
import { addAcFunc } from 'funcs/ac'
import { directoryLanguage } from 'components/directory/directoryLanguage'

function Directory() {
    const dispatch = useDispatch()
    const ref = useRef(null) 
    const [isDropped, setIsDropped] = useToggleDropdown(ref)
    const [width, height] = useGetWindowSize()
    const { user } = useSelector(state => state.auth)

    useEffect(() => {

        const dis = () => dispatch(addAc({
          type: 'directory', 
        }))
    
        addAcFunc('directory', dis)
    
      }, [])


    return (
        <ChurchDirectoryContainer> 
            <MobilePageTitle>
                {
                    directoryLanguage.directory[user.preferredLanguage]
                }
            </MobilePageTitle>

            <DirectoryTop>
                <DirectorySort />
                
                <SearchSort>
                    
                    <DirectorySearch /> 

                    {
                        width > 768 && (
                            <DirectorySettings />
                        )
                    }     
                </SearchSort>
{/* 
                <Tooltip title="View Birthdays" placement="top">
                <ViewBdays
                    ref={ref}
                    onClick={() => setIsDropped.toggle(ref)}
                    > 
                    <BiCake 
                        style={{
                            fontSize: '20px', 
                            color: '#727575'
                        }}
                        />  
                </ViewBdays></Tooltip> 

                {
                    isDropped && (
                        <BirthdaysDropdown />
                    )
                }

*/}

            </DirectoryTop> 
            <DirectoryCards />
        </ChurchDirectoryContainer>
    )
}

const ViewBdays = styled.div`

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 50%;
    width: 40px;  
    
   
    color: #696969;
    cursor: pointer;
    font-size: 14px;
     
     &:hover{
        background-color: white;
        box-shadow: 0px 0px 11px #ccc;
     }
`

const ChurchDirectoryContainer = styled.div`
    width: 100%; 
    display: flex;
    flex-direction: column;
    padding: 10px; 
    -ms-overflow-style: none;  
    scrollbar-width: none;  

    &::-webkit-scrollbar {
        display: none;
    }
    overflow-y: scroll;

    @media (max-width: 768px) {
        padding: 10px;
        padding-top: 5px;
    }


`

const SearchSort = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center; 
    height: 100%;
    justify-content: flex-start;
    width: fit-content;
    @media (max-width: 768px) {
        width: 100%;
    }
`


const DirectoryTop = styled.div`
    padding: 0px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    position: relative;
`


export default Directory