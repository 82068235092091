import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

function AlreadyHaveAccount() {
    const navigate = useNavigate()
    const handleClick = () => { navigate('/login') }

  return (
    <AHAC>
        <AHACText>Already have an account? </AHACText>
        <AHACText2 onClick={handleClick}>Log In</AHACText2>
    </AHAC>
  )
}

export default AlreadyHaveAccount

const AHAC = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    color: #343434;
    font-size: smaller;
`
const AHACText = styled.div`
    padding-right: 5px;
`

const AHACText2 = styled.div`
    text-decoration: underline;
    cursor: pointer;
    &:hover{
        color: black;
    }

`