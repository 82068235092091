import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import singlePostService from './singlePostService'

const initialState = {
  singlePost: null,
  spComments: [],

  isPostError: false,
  isPostSuccess: false,
  isPostLoading: false,

  isPostLoadingLikes: false,
  isPostLikesSuccess: false,

  postMessage: '',
  singlePostLikes: null,
}

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

// authRequired
export const getPost = createAsyncThunk(
  'singlePost/getPost',
  async (postData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await singlePostService.getPost(postData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const likePost = createAsyncThunk(
  'singlePost/like',
  async (postData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await singlePostService.likePost(postData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// // authRequired
// export const loadMoreComments = createAsyncThunk(
//   'post/loadMoreComments',
//   async (postData, thunkAPI) => {
//     try {
//       const token = thunkAPI.getState().auth.user.token
//       return await singlePostService.getPost(postData, token)
//     } catch (error) {
//       const message = errorMessage(error)
//       return thunkAPI.rejectWithValue(message)
//     }
//   }
// )

// authRequired
export const getPublicPost = createAsyncThunk(
  'post/getPublicPost',
  async (postData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await singlePostService.getPublicPost(postData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const singlePostSlice = createSlice({
  name: 'singlePost',
  initialState,
  reducers: {
    singlePostReset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPost.pending, (state) => {
        state.isPostLoading = true
      })
      .addCase(getPost.fulfilled, (state, action) => {
        state.isPostLoading = false
        state.isPostSuccess = true
        state.singlePost = action.payload
        state.singlePostLikes = action.payload.likes.length
      })
      .addCase(getPost.rejected, (state, action) => {
        state.isPostLoading = false
        state.isPostError = true
        state.postMessage = action.payload
      })
      //-----------------------


      .addCase(likePost.pending, (state) => {
        state.isPostLoadingLikes = true
      })
      .addCase(likePost.fulfilled, (state, action) => {
        state.isPostLoadingLikes = false
        state.isPostLikesSuccess = true
        state.singlePostLikes = action.payload.count
      })
      .addCase(likePost.rejected, (state, action) => {
        state.isPostLoading = false
        state.isPostError = true
        state.postMessage = action.payload
      })
      //-------------------------------
      .addCase(getPublicPost.pending, (state) => {
        state.isPostLoading = true
      })
      .addCase(getPublicPost.fulfilled, (state, action) => {
        state.isPostLoading = false
        state.isPostSuccess = true
        state.singlePost = action.payload
        //state.posts = action.payload =-> this gives another variable in the state as 'posts'
      })
      .addCase(getPublicPost.rejected, (state, action) => {
        state.isPostLoading = false
        state.isPostError = true
        state.postMessage = action.payload
      })
  },
})

export const { singlePostReset } = singlePostSlice.actions
export default singlePostSlice.reducer
  //==========================================//