import React from 'react'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { convertTime, formatPrice } from 'components/settings/billing/stripeUtilityFuncs'
import styled from 'styled-components';

export default function ChurchTransactionItem(props) {

    let date = convertTime(props.data.created)
    let price = formatPrice(props.data.amount)

    return (
        <tr>
            <td>{`${date.month}  ${date.day}  ${date.year}`}</td>
            <td>{price}</td>
            <td>{props.data.description} </td>
            <td>  {props.data.receipt_email}  </td>
            {/* <td>  {props.data.status}  </td> */}
           
            {/* <RecTd>
            <a style={{color: 'var(--themeColor)'}} href={`${props.data.receipt_url}`} target="_blank" rel="noreferrer">
                <ReceiptLongIcon />
            </a>
            <span className='recT'> View Receipt </span>
            </RecTd> */}
        </tr>
    )
}

const RecTd = styled.td`
  .recT{
    position: absolute;
    padding: 5px;
    display: none;
    width: fit-content;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #00000063;
    color: white;
    margin-bottom: 5px;
    margin-left: 5px;
    border-radius: 5px 5px 5px 0px;
    font-size: x-small;
    white-space: nowrap;
  }

  &:hover{
    .recT{
      display: unset;
    }
  }

`

const Rfnd = styled.div`
  display: inline;
  color: white;
  font-size: 10px;
  padding: 3px;
  border-radius: 2px;
  background-color: #8d0202b0;
`