import {useState, useEffect, useRef } from 'react' 
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify' 
import { login, reset, loginOrganization } from '../../features/auth/authSlice'
import HaveAnAccount from './HaveAnAccount'
import ForgotPasswordLink from './ForgotPasswordLink'
import { FormWrap, FormElement, FormHeading, SubmitButton } from './login.styled'
import SpinnerSmall from 'components/SpinnerSmall'
import useKeypress from 'hooks/useKeypress'


function ChurchLoginForm() {
    const formRef = useRef(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [errorField, setErrorField] = useState('')
    const [email, setEmail] = useState('') 
    const { user, isLoginLoading, isError, isloginSuccess, message } = useSelector( (state) => state.auth )
    const [password, setPassword] = useState('')
    const [passwordValid, setPasswordValid] = useState()

    const handleEmail = (e) => {
        let emailType = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setEmail(e.target.value)
    }

    const handleSubmit = () => { 
        const userData = { 
            email: email, 
            password: password 
        }
        dispatch(loginOrganization(userData))
    }
      
    const handlePassword = (e) => {
        setPassword(e.target.value)
        if(password.length > 5){ setPasswordValid(true) }
    }

      useEffect(() => {
        if (!user) {  navigate('/login') }
        if (isError) { toast.error(message) }
         if( isloginSuccess || user){
            navigate(`/${user.name}`)
        }
    // dispatch(reset()) 
    }, [user, isError, isloginSuccess, message, navigate, dispatch])

    const handleKeyPressSubmit = () => {
        if(document.activeElement === formRef.current){
            handleSubmit()
        }
    }
    
    useKeypress('Enter', handleKeyPressSubmit)

  return (
    <FormWrap>
        <FormElement isError={errorField === 'email' ? ('red'):(null) }>
            <FormHeading>Email</FormHeading>
                <input 
                    type={'text'} 
                    id='email'
                    name='email'
                    value={email}
                    placeholder=''
                    onChange={handleEmail}
                    autoComplete='off'
                /> 
        </FormElement>
        <FormElement isError={errorField === 'password' ? ('red'):(null) }>
            <FormHeading>Password</FormHeading>
                <input 
                    ref={formRef}
                    type={'password'} 
                    id='password'
                    name='password'
                    value={password}
                    placeholder=''
                    onChange={handlePassword}
                    autoComplete='off'
                /> 
        </FormElement>

        <ForgotPasswordLink  />

        <SubmitButton
            disabled={isLoginLoading || !passwordValid || !email}
            onClick={handleSubmit}
            >
            {isLoginLoading ? <SpinnerSmall /> : 'Login'}
        </SubmitButton>

        <HaveAnAccount />

    </FormWrap>
  )
}

export default ChurchLoginForm
