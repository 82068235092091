import axios from 'axios' 
import { baseUrl } from '../constants'
const URL = baseUrl  

 
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
}}

 
const startCollection = async (data, token) => {
  const response = await axios.post(URL+'/api/files/startCollection', data, config(token))
  return response.data
}

const createFolder = async (data, token) => {
    const response = await axios.post(URL+'/api/files/createFolder', data, config(token))
    return response.data
}

const getFolders = async (data, token) => {
    const response = await axios.post(URL+'/api/files/getFolders', data, config(token))
    return response.data
}

const getFolderFiles = async (data, token) => {
    const response = await axios.post(URL+'/api/files/getFolderFiles', data, config(token))
    return response.data
}

const getCollectionFiles = async (data, token) => {
    const response = await axios.post(URL+'/api/files/getCollectionFiles', data, config(token))
    return response.data
}

const renameFolder = async (data, token) => {
    const response = await axios.post(URL+'/api/files/renameFolder', data, config(token))
    return response.data
}

const deleteFile = async (data, token) => {
    const response = await axios.post(URL+'/api/files/deleteFile', data, config(token))
    return response.data
}

const deleteFolder = async (data, token) => {
    const response = await axios.post(URL+'/api/files/deleteFolder', data, config(token))
    return response.data
}

const uploadFile = async (data, token) => {
    const response = await axios.post(URL+'/api/files/uploadFile', data, config(token))
    return response.data
}

const moveFile = async (data, token) => {
    const response = await axios.post(URL+'/api/files/moveFile', data, config(token))
    return response.data
}

const renameFile = async (data, token) => {
    const response = await axios.post(URL+'/api/files/renameFile', data, config(token))
    return response.data
}


const marketService = {
    startCollection, 
    createFolder,
    getFolders,
    getFolderFiles,
    getCollectionFiles,
    renameFolder,
    renameFile,
    deleteFile,
    deleteFolder,
    uploadFile,
    moveFile,
}
export default marketService