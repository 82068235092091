import {useState, useEffect, useRef} from 'react' 
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { FormWrap, VerifyLocationButton, FormElement, FormHeading, Available, NotAvailable, InputWrap, SubmitButton, NonSubmittableButton,  FormEleWrap1, RegisterButton } from './forms.styled'
import { 
  registerChurch, 
  verifyUsernameAvailable, 
  verifyEmailAvailable, 
  sendVerificationEmailCode,
  resetEverythingButUser, 
} from '../../features/auth/authSlice'
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import { setViewEmailConfirmModal } from '../../features/auth/authSlice'
import AlreadyHaveAccount from 'components/register/AlreadyHaveAccount'
import AcceptTerms from './AcceptTerms'
import { getCoordinates, resetMap } from 'features/maps/mapSlice';
import SpinnerSmall from 'components/SpinnerSmall';


export default function ChurchRegister() {
    const checkMark = <CheckCircle style={{color: 'green'}} />
    const notValid = <Cancel style={{color: 'red'}} />
    const emailInputRef = useRef();
    const usernameInputRef = useRef();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [errorField, setErrorField] = useState('')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [isEmailEdited, setIsEmailEdited] = useState(false)
    const [isEmailVerifyDispatced, setEVD] = useState(false)
    const [isUsernameVerifyDispatced, setUVD] = useState(false) 
    const [isUsernameEdited, setIsUsernameEdited] = useState(false)
    const [emailValid, setEmailValid] = useState()
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [passwordValid, setPasswordValid] = useState()
    const [passwordValid2, setPasswordValid2] = useState()  
    const [locationInput, setLocationInput] = useState('')
    const [inputFocus, setInputFocus] = useState('')
    const locationRef = useRef()
    const [fullName, setFullName] = useState('') 
    const handleLocation = (e) => {  setLocationInput(e.target.value) }
    const [locationVerified, setLocationVerified] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const { 
      termsAccepted, isError, message, isUsernameAvailable, isEmailAvailable, codeVerified, isRegisterSuccessChurch,
      isAvailableLoadingType, isAvailableError, isEmailCodeVerificationSuccess
    } = useSelector( (state) => state.auth )

    const { getCoordinatesSuccess, locationCoordinates, location, searchTerm } = useSelector( (state) => state.maps )
  
    useEffect(() => { }, [password, password2, passwordValid, passwordValid2])

    const handleEmailChange = (e) => {
      if(!isEmailEdited){
        setIsEmailEdited(true)
      }

      let emailType = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      setEmail(e.target.value)

      if ( emailType.test(e.target.value) ) {

        setEmailValid(true)

        dispatch(verifyEmailAvailable({
          userType: 'church',
          email: e.target.value,
        }))

        setEVD(true)

      }
      else {
        setEmailValid(false)
      }
    }

    const handlePassword = (e) => {
      setPassword(e.target.value)
      if(password.length > 5){ setPasswordValid(true)}
    }

    const handlePassword2 = (e) => {
      setPassword2(e.target.value)
      if(password === e.target.value){setPasswordValid2(true)
      }else if(e.target.value.length !== password.length){
        setPasswordValid2(false)
      }
    }

    useEffect(() => {
      if (isError) { toast.error(message)}
      if (isRegisterSuccessChurch) { 
        setIsLoading(false)
        navigate('/login')
        toast.success('Account Created, Login to Continue!')
      }

    }, [ isError, message, navigate, dispatch, isRegisterSuccessChurch])


    useEffect(() => {
      if(isEmailEdited && ( email.length > 5 ) && (document.activeElement !== emailInputRef.current)){
        setIsEmailEdited(false)
        setEVD(true)
      }
    }, [dispatch, email, emailValid, isEmailEdited]) 


      useEffect(() => {
        if(getCoordinatesSuccess && location.bestMatch){
            setLocationVerified(true)
            setLocationInput(location.bestMatch)
        }
      }, [getCoordinatesSuccess, location.bestMatch])

      const handleUsernameChange = (e) => {
        // check if latest keycode is a space
        if(e.target.value.charCodeAt(e.target.value.length - 1) === 32){ return }

        if(!isUsernameEdited){
          setIsUsernameEdited(true)
        }
        setUsername(e.target.value)
      }

      useEffect(() => {
        if(isUsernameEdited && ( username.length > 3 )){
          dispatch(verifyUsernameAvailable({
            userType: 'church',
            username: username,
          }))
          setIsUsernameEdited(false)
          setUVD(true)
        }
      }, [isUsernameAvailable, isUsernameEdited, dispatch, username])


      const handleSubmit = () => { 

        if(fullName.length < 3){
          setErrorField('fullName')
          return(toast.error('Enter a Valid Name'))
        }

        if(email.length < 1 || !emailValid){
          setErrorField('email')
          return(toast.error('Enter a Valid Email'))
        }

        // if(!locationVerified){
        //   setErrorField('location')
        //   return(toast.error('Enter a valid Location'))
        // }

        if(password.length < 8 || password2.length < 8){
          setErrorField('password')
          return(toast.error('Passwords must be at least 8 characters'))
        }

        if (password !== password2) {
          setErrorField('password')
          return(toast.error('Passwords do not match')) 
        }

        let data = {
          fullName: fullName,
          username: username,
          email: email,
          // location: locationInput,
          // coordinatesLng: locationCoordinates[1],
          // coordinatesLat: locationCoordinates[0],
          password: password, 
        }

        setErrorField('')
        dispatch(registerChurch(data))

      }

      const handleSendEmailVerificationCode = () => {
        if(fullName.length < 3){
          setErrorField('fullName')
          return(toast.error('Enter a Valid Name'))
        }

        if(email.length < 1 || !emailValid || !isEmailAvailable){
          setErrorField('email')
          return(toast.error('Enter a Valid Email address'))
        }

        if(password.length < 8 || password2.length < 8){
          setErrorField('password')
          return(toast.error('Passwords must be at least 8 characters'))
        }
        if (password !== password2) {
          setErrorField('password')
          return(toast.error('Passwords do not match')) 
        }
        dispatch(setViewEmailConfirmModal({
          email: email
        }))

        dispatch(sendVerificationEmailCode({
          fullName: fullName, email: email,
        }))

        setIsLoading(true)
 
      }

      useEffect(() => {
        // if the email verification is a success, then call handleSubmit
        if(isEmailCodeVerificationSuccess && codeVerified){
          handleSubmit()
        }
      }, [isEmailCodeVerificationSuccess, codeVerified])

      // unmount useffect function
      useEffect(( ) => { return () => { dispatch(resetEverythingButUser())  }}, [])

  return (
    <FormWrap>
        <FormElement isError={errorField === 'fullName' ? ('red'):(null) }>
            <FormHeading>Chuch Name</FormHeading>
            <input  
                type={'text'} 
                id='fullName'
                name='fullName'
                value={fullName}
                placeholder='Example: Grace Community Church'
                onChange={(e) => setFullName(e.target.value)}
                autoComplete='off'
            /> 
        </FormElement>
        <FormElement  isError={errorField === 'username' ? ('red'):(null) }> 

            <FormHeading>
              <span>User name</span>   

              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center', 
                }}
                >
                {   isUsernameVerifyDispatced && ( username.length > 2 ) && (
                  
                    isAvailableError ? (
                      <NotAvailable>Not Available</NotAvailable>
                    ) 
                    : isAvailableLoadingType === 'username' ? (
                    'verifying...' 
                    ): isUsernameAvailable &&
                    (
                      <Available>Available</Available>
                    )
                  )
                }                   
                <Tooltip
                  title="This will be your identifier on Praysely. User names are used for church URLs such as www.praysely.com/gracecommunitychurch"
                  >
                  <HelpIcon />
                </Tooltip> 


           
              </span>       

            </FormHeading>
            <FormEleWrap1>
              { codeVerified ? (
                  <input 
                    value={username} 
                    readOnly 
                    />
                )
                :
                (
                  <input 
                    ref={usernameInputRef} 
                    type='text' 
                    className='form-control' 
                    id='username'
                    name='username' value={username} 
                    placeholder='Choose a Unique User Name'
                    onChange={handleUsernameChange} 
                    autoComplete='off'
                />
                )
              }      

            </FormEleWrap1>


        </FormElement>
        <FormElement  isError={errorField === 'email' ? ('red'):(null) }> 
            <FormHeading>
              <span>Email</span>  
              {  ( emailValid && !isEmailAvailable && isEmailVerifyDispatced) || isAvailableError ? 
                (
                  <NotAvailable>Not Available</NotAvailable>
                )
                :emailValid && isAvailableLoadingType === 'email' ? (
                  <span>verifying...</span>
                )
                :emailValid && isEmailAvailable &&
                (
                  <Available>Available</Available>
                )
              } 
            </FormHeading>
            <InputWrap>
            { codeVerified ? (
              <input value={email} readOnly/>
            )
            :
            (
              <input
                    ref={emailInputRef}
                    type='text'
                    id='email'
                    name='email'
                    value={email}
                    placeholder='Enter your email'
                    onChange={handleEmailChange}
                    autoComplete="new-password"
                  />
            )

            }
            <span> 
              { 
                emailValid && !isEmailVerifyDispatced ? 
                (
                  checkMark
                )
                : (emailValid && isEmailVerifyDispatced) && isEmailAvailable ? 
                (
                  checkMark
                )
                : (emailValid && isEmailVerifyDispatced) && !isEmailAvailable ?
                (
                  notValid
                )
                :
                ('')
              }   
            </span>
          </InputWrap>
        </FormElement>

        {/* <FormElement 
          isError={errorField === 'location' ? ('red'):(null) }
          > 
          <FormHeading> 
            <span>Location / Address</span>
            <span>
                <Tooltip
                  title="You may enter your church's address, or your city and region. Then, click 'Set Location' to verify your location is entered properly and exists on Google Maps."
                  >
                  <HelpIcon />
                </Tooltip> 
            </span>
          </FormHeading>

            <FormEleWrap1>
              <input
                onFocus={() => setInputFocus('location')}
                ref={locationRef}
                type="text"
                value={locationInput}
                onChange={handleLocation}
                placeholder={`${inputFocus === 'location' ? 'Enter your best address': 'Location'}`}
                />
                {
                    searchTerm === locationInput || locationInput === location.bestMatch ? 
                    (
                      <VerifyLocationButton
                        onClick={() => {
                          setLocationInput('')
                          dispatch(resetMap())
                        }}
                        >
                          Clear
                      </VerifyLocationButton>    
                    )
                    :
                    (
                      <VerifyLocationButton
                          onClick={() => dispatch(getCoordinates({address: locationInput}))}
                          >
                          Set Location 
                      </VerifyLocationButton>
                    )
                }            
            </FormEleWrap1>
        </FormElement> */}


        <FormElement  isError={errorField === 'password' ? ('red'):(null) } >
            <FormHeading>Password</FormHeading>
            <InputWrap>
              <input
                    type='password'
                    className='form-control'
                    id='password'
                    name='password'
                    value={password}
                    placeholder='Enter password'
                    onChange={handlePassword}
                    autoComplete='off'
                  />
              <span>  { passwordValid ? (checkMark): password.length > 4 ? (notValid):('')} </span>
            </InputWrap>
        </FormElement>
        <FormElement  isError={errorField === 'password' ? ('red'):(null) }  >
            <FormHeading>Confirm Password</FormHeading>
            
            <InputWrap>
              <input
                    type='password'
                    className='form-control'
                    id='password2'
                    name='password2'
                    value={password2}
                    placeholder='Confirm password'
                    onChange={handlePassword2}
                    autoComplete='off'
                  />
              <span>  { passwordValid2 ? (checkMark): password2.length > 4 ? (notValid):('')} </span>
            </InputWrap>
        </FormElement>

        <AcceptTerms /> 

        { termsAccepted && isEmailAvailable && emailValid && !codeVerified ? 
          (
            <SubmitButton onClick={handleSendEmailVerificationCode}>
              Continue
            </SubmitButton>
          )
          : isLoading ?
          (
            <RegisterButton onClick={handleSubmit}>
              <SpinnerSmall />
            </RegisterButton>
          )
          :
          (
            <NonSubmittableButton>
              Continue
            </NonSubmittableButton>
          )
        }
        
        <AlreadyHaveAccount />
    </FormWrap>
  )
}
