let imgBaseUrl;
let apiUrl;
let staticBase;
let stripePK;

let dateObjEx = { 
    monthLong: ['January', 'Febuary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October','November', 'December'],
    monthShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept','Oct','Nov', 'Dec'],
    dayLong: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
}



if(process.env.NODE_ENV === 'development'){
    // imgBaseUrl = 'frontend/src'
    imgBaseUrl = 'https://api.praysely.com/static/img'
    staticBase = 'https://api.praysely.com/static'
    apiUrl = 'http://localhost:8000'
    // apiUrl = 'https://api.praysely.com';
    stripePK = 'pk_test_51MiUs9FN6wKpuMYyWcBgz0RQhp2CPlkedozNx1ry9KePdrAPH4UNy1uOG8wG6TA8Jat6B8uodfoxVlEMBO4Bv5tQ00LZRgV979'

}else{
    apiUrl =  'https://api.praysely.com';
    staticBase = 'https://api.praysely.com/static'
    imgBaseUrl = 'https://api.praysely.com/static/img'
    stripePK = 'pk_live_51MiUs9FN6wKpuMYyU94tH5fzMqjaNiUKmibLrkeXxWbDKoR4ruWLxQKqKg5LLabgwOi5nJcXcnmhOq7zZqPKfKPg00fckOQEUM'
}

export const baseUrl = apiUrl;
export const staticUrl = staticBase;
export const imgUrl = imgBaseUrl;
export const dateObj = dateObjEx;
export const genericAvatar = 'generic/2024-01-01T14-13-49.160Z-person.png'
export const pkStripe = stripePK;