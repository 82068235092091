import React from 'react'
import styled from 'styled-components'  
import CampaignFeed from './CampaignFeed'

export default function Campaigns() {
    return (
        <CampaignsContainer>
            <CampaignFeed />
        </CampaignsContainer>
    )
}

const CampaignsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%; 
`