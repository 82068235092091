import React, {useState} from 'react'
import { sendInviteEmail } from 'features/email/emailSlice'
import { useDispatch, useSelector } from 'react-redux'  
import styled from 'styled-components'
import { SettingsMainContainer, TopHeaderSettings } from '../settings.styled'
import SendIcon from '@mui/icons-material/Send';
import LoaderSmall from 'loaders/LoaderSmall'
import Spinner from 'loaders/Spinner'
import SpinnerSmall from 'components/SpinnerSmall'

export default function InviteMembers() {

    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { isInviteEmailLoading, isInviteEmailSuccess, isInviteEmailError, inviteEmailErrorMessage } = useSelector(state => state.email)

    const [ emailAddresses, setEmailAddresses ] = useState('')

    const handleSendInvites = () => { 
        dispatch(sendInviteEmail({
            churchName: user.fullName,
            emailList: emailAddresses
        }))
    }

    return (
        <SettingsMainContainer> 
            <TopHeaderSettings> Invite Members </TopHeaderSettings>

            <MainInviteContainer> 
                <HeadingH2><span>Invite your church members to register an account on Praysely and join your church. </span> <span>Enter up to 100 emails, each separated by commas</span> </HeadingH2>
                <InviteTextArea
                    onChange={(e) => setEmailAddresses(e.target.value)}
                    placeholder='Example:   susan@mail.com,   jim@mail.com, ...'
                    />
                <InviteButton
                    disabled={isInviteEmailLoading}
                    onClick={handleSendInvites}
                    > 
                        {
                            isInviteEmailLoading ? (   
                                <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> <SpinnerSmall/> <span style={{marginLeft: '10px'}}>Sending Emails...</span> </span> 
                            )
                            :
                            (
                                <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> <SendIcon /> <span>Send Invite</span></span> 
                            )
                        }
                        
                </InviteButton>      
            </MainInviteContainer> 
        </SettingsMainContainer> 
    )
}

const MainInviteContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px; 
`
const HeadingH2 = styled.h2`
    color: var(--LightThemeFontColor);
    font-size: 13px;
    width: 75%;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
    text-align: left;
`

const InviteTextArea = styled.textarea`
    width: 100%;
    outline: none;
    border: none;
    min-height: 250px; 

    // set max width limits for textarea 
    resize: vertical; 


    border-radius: 5px;
    padding: 20px;
    box-shadow: 0px 0px 10px #ccc;
    // modify placeholder text
    color: var(--LightThemeFontColor);
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    &::placeholder {
        color: var(--LightThemeFontColor);
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
    }
`

const InviteButton = styled.div`
    display: flex;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    width: fit-content;
    background-color: var(--ltGreenButtonSolid);
    border: 3px solid var(--ltGreenButtonSolid);
    color: white;
    font-weight: 400;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    outline: none;
    transition: all 0.5s;
    
    /* icon is hidden and unhide it on hover */
    svg { 
        margin-right: 9px; 
        font-size: 19px;
    }

    &:hover {
        transition: all 0.5s;
        background-color: white;
        color: var(--ltGreenButtonSolid); 
    }
`