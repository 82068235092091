import axios from 'axios'

import { baseUrl } from '../constants'
const URL = baseUrl

const URL_CREATE = '/api/reportIssue/createIssue'
const URL_GET = '/api/reportIssue/getIssues'
const URL_COMMENT_ISSUE = '/api/reportIssue/createCommentIssue'
const URL_POST_ISSUE = '/api/reportIssue/reportPost'

//for sending the token
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }

//---------------------------------------------------------------
//---------------------------------------------------------------

const createIssue = async (issueData, token) => {
  const response = await axios.post(URL+URL_CREATE, issueData, config(token))
  return response.data
}
const getIssues = async (token) => {
    const response = await axios.get(URL+URL_GET, config(token))
    return response.data
  }
  
const reportPost = async (issueData, token) => {
  const response = await axios.post(URL+URL_POST_ISSUE, issueData, config(token))
  return response.data
}

const createCommentIssue = async (issueData, token) => {
  const response = await axios.post(URL+URL_COMMENT_ISSUE, issueData, config(token))
  return response.data
}

const reportIssueService = {
    createIssue,
    getIssues,
    reportPost,
    createCommentIssue
  }
  
  export default reportIssueService