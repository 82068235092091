import axios from 'axios'

import { baseUrl } from '../constants'
const URL = baseUrl


const API_URL = '/api/profile/'
const API_URL_GETMYDATA = '/api/profile/getMyData'
const URL_GETMYPROFILE = '/api/profile/getMyProfile'
const API_URL_UDATEPROFILE = '/api/profile/updateProfile'
const API_URL_UDATEPASS = '/api/profile/updatePassword'
const API_URL_ADDIMAGE = '/api/users/addImage'
const API_URL_UPDATEIMAGE = '/api/users/updateImage'
const API_URL_JOIN = '/api/profile/sendJoinRequest'

//for sending the token
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }


const getProfileData = async (profileData, token) => {
  const response = await axios.post(URL+API_URL, profileData, config(token))
  return response.data
}

const updateProfile = async (updateData, token) => {
  const response = await axios.put(URL+API_URL_UDATEPROFILE, updateData, config(token))
  return response.data
}

const updatePassword = async (updateData, token) => {
  const response = await axios.put(URL+API_URL_UDATEPASS, updateData, config(token))
  return response.data
}
//this below runs in settings
const getMyData = async (token) => {
  const response = await axios.get(URL+API_URL_GETMYDATA, config(token))
  return response.data
}

//this below runs in settings
const getMyProfile = async (data, token) => {
  const response = await axios.post(URL+URL_GETMYPROFILE, data, config(token))
  return response.data
}

const addImage = async (formData, token) => {
  const response = await axios.post(URL+API_URL_ADDIMAGE, formData, config(token))
  return response.data
}

const updateImage = async (formData, token) => {
  const response = await axios.post(URL+API_URL_UPDATEIMAGE, formData, config(token))
  return response.data
}

const sendJoinRequest = async (profileData, token) => {
  const response = await axios.post(URL+API_URL_JOIN, profileData, config(token))
  return response.data
}


const profileService = {
  getProfileData,
  getMyData,
  getMyProfile,
  updateProfile,
  updatePassword,
  addImage,
  updateImage,
  sendJoinRequest,
}

export default profileService