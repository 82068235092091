import React, {useEffect, useState, useRef}from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setViewUploadVideoModal, resetSearchVideos, searchVideos, setViewEditVideoModal } from 'features/videos/videoSlice'
import styled from 'styled-components'
import { MediaSectionContainer, MediaTitle, StyledSearchInput, MediaControls,SearchBtn, SearchWrap,MediaFeed } from 'components/media/media.styled'
import { SearchResultsTitle, ClearSearchBtn } from 'components/search/search.styled'
import VideoUploadSelect from './VideoUploadSelect'
import CreateButton from 'components/buttons/CreateButton' 
import { Modal } from '@mui/material'
import UploadVideoModal from './UploadVideoModal'
import VideoFeed from './VideoFeed' 
import VideoPlayerModal from './VideoPlayerModal'
import { setViewVideoPlayerModal, resetAllLoadingSuccessError } from 'features/videos/videoSlice' 
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom'
import { MUIModalWrap } from 'components/modal/modal.styled'
import { setViewConfirmModal } from 'features/modal/modalSlice'
import ConfirmModal from 'components/modals/ConfirmModal'
import { addAc } from 'features/auth/authSlice'
import { addAcFunc } from 'funcs/ac' 
import MediaSort from '../MediaSort' 
import SearchIcon from '@mui/icons-material/Search';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

// check for url params
import { useLocation } from 'react-router-dom'
import EditVideoModal from './EditVideoModal'

export default function Videos() {

    const location = useLocation()
    const viewVideo = new URLSearchParams(location.search).get('vid') ? new URLSearchParams(location.search).get('vid') : ''
    const navigate = useNavigate() 
    const searchInputRef = useRef(null)
    const dispatch = useDispatch()
    const [extendSearchBar, setExtendSearchBar] = useState(false) 
    const { 
        viewUploadVideoModal, isDeleteVideoLoading, 
        isDeleteVideoSuccess, viewVideoPlayerModal, 
        currentVideo, isSearchActive, viewEditVideoModal, videoData
    } = useSelector((state) => state.video)
    const [upload, setUpload] = useState(false)
    const { viewConfirmModal, confirmModalData } = useSelector((state) => state.modal)

    const handleResetAfterClose = () => {
        dispatch(resetAllLoadingSuccessError())
    }

    const handleClearSearch = () => {
        searchInputRef.current.value = ''
        dispatch(resetSearchVideos())
        setExtendSearchBar(false)
    }

    const handleSearch = () => { 
        if(searchInputRef.current.value === ''){
            return
        } 
        dispatch(searchVideos({ searchTerm: searchInputRef.current.value })) 
    }

    useEffect(() => {
        console.log('videos mounted')
        const dis = () => dispatch(addAc({
            type: 'media', 
        }))
        addAcFunc('vd', dis)
    }, [])

    useEffect(() => {
        if(viewVideo){ 
            if(!viewVideoPlayerModal){ 
                dispatch(setViewVideoPlayerModal({
                    view: true,
                    videoUrl: null,
                    videoId: viewVideo
                }))
                navigate(`?section=media&view=videos&vid=${viewVideo}`)

                // console.log('view video')
                // console.log(viewVideo)

            }
        }
    }, [viewVideo])

    return (
        <MediaSectionContainer>

            <MediaControls>
                <MediaSort section={'video'} />                
                <SearchWrap 
                    extendWidth={extendSearchBar}
                    onClick={() => {if(!extendSearchBar){setExtendSearchBar(!extendSearchBar)}}}
                    > 
                    {
                        !extendSearchBar && 
                        <SearchIcon 
                            style={{cursor: 'pointer', marginLeft: '5px'}}
                            onClick={() => setExtendSearchBar(!extendSearchBar)}
                            />
                    }
                    
                    <StyledSearchInput
                        ref={searchInputRef}
                        extendWidth={extendSearchBar}
                        placeholder='Search Videos...'
                        />

                    {
                        extendSearchBar &&
                        <SearchBtn
                            onClick={handleSearch}
                            >
                            <ManageSearchIcon />
                        </SearchBtn>
                    }
                    
                </SearchWrap>      
            </MediaControls>
            {
                isSearchActive &&
                <SearchResultsTitle>
                    <MediaTitle>Search Results</MediaTitle>
                    <ClearSearchBtn
                        onClick={handleClearSearch}
                        >Clear Search</ClearSearchBtn>
                </SearchResultsTitle>
            }


            <VideoFeed />
            
            
            <Modal
                open={viewUploadVideoModal}
                onClose={() => dispatch(setViewUploadVideoModal(false))}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <MUIModalWrap>
                        <UploadVideoModal  />
                    </MUIModalWrap>
            </Modal>
            <Modal
                open={viewVideoPlayerModal}
                onClose={() => dispatch(setViewVideoPlayerModal(false))}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <MUIModalWrap backgroundColor={'#242424'} >
                    <BackButton
                        onClick={() => {
                            dispatch(setViewVideoPlayerModal({
                                view: false,
                                videoUrl: null
                            }))
                            navigate(`?section=media&view=videos`)
                        }}
                        >
                        <ArrowBackIcon />
                        <span> Back to Videos </span>
                    </BackButton>
                    <VideoPlayerModal url={currentVideo} />
                </MUIModalWrap>
            </Modal> 
            <Modal
                open={viewConfirmModal}
                onClose={() => dispatch(setViewConfirmModal(false))}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <MUIModalWrap>
                    <ConfirmModal
                        loadingState={isDeleteVideoLoading}
                        conditionToCloseModal={isDeleteVideoSuccess}
                        text={'Are you sure you want to delete this video?'}
                        doSomethingAfterClose={true}
                        resetAfterClose={handleResetAfterClose} 
                        handler={confirmModalData.handler} 
                        confirmBtnBackgroundHover={'red'}
                        />
                </MUIModalWrap>
            </Modal> 
            <Modal
                open={viewEditVideoModal}
                onClose={() => dispatch(setViewEditVideoModal(false))}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <MUIModalWrap>
                    <EditVideoModal  />
                </MUIModalWrap>
            </Modal> 
        </MediaSectionContainer>

    )
}


const BackButton = styled.div`
    position: absolute;
    top: 20px;
    left: 20px;
    width: fit-content;
    height: 50px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer; 

    span{
        width: fit-content;
        text-align: left;
        word-wrap: normal;
    }

    svg{ 
        font-size: 60px;
        height: 100%; 
        margin-right: 10px;
    }

    &:hover{
        color: #dac7c7;
    }

`
