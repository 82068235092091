import axios from 'axios'

import { baseUrl } from '../constants'
const URL = baseUrl

const API_URL = '/api/profile/updateImage'

//for sending the token
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }

const updateImage = async (imageData, token) => {
  const response = await axios.post(URL+API_URL, imageData, config(token))
  return response.data
}

const imageService = { 
    updateImage
  }
  export default imageService
  