
export const bulletinLanugage = {

    uploadBulletin: {
        en: 'Upload Bulletin',
        es: 'Subir Boletín'
    },
    bulletinTitle: {
        en: 'Bulletin Title',
        es: 'Título del Boletín'
    },
    noFileSelected: {
        en: 'No file selected',
        es: 'Ningún archivo seleccionado'
    },
    selectPdfFile: {
        en: 'Select PDF File',
        es: 'Seleccionar Archivo PDF'
    }, 
    bulletins: {
        en: 'Bulletins',
        es: 'Boletines'
    },
}