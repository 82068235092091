import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import prayerWallService from './prayerWallService'

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

const initialState = {

    prayerWallItems: [],

    isGetPrayerWallItemsSuccess: false,
    isGetPrayerWallItemsLoading: false,
    isGetPrayerWallItemsError: false,

    isAddingItemToPrayerWallLoading: false,
    isAddingItemToPrayerWallSuccess: false,
    isAddingItemToPrayerWallError: false,

    prayerWallItemsmessage: '',

    viewPrayerWall: localStorage.getItem('viewPrayerWall') === 'true' ? true : false,

}

 
export const getPrayerWallItems = createAsyncThunk(
    'prayerWall/getPrayerWallItems',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await prayerWallService.getPrayerWallItems(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const addItemToPrayerWall = createAsyncThunk(
    'prayerWall/addItemToPrayerWall',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await prayerWallService.addItemToPrayerWall(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)




export const prayerWallSlice = createSlice({
    name: 'prayerWall',
    initialState,
    reducers: {
        setViewPrayerWall: (state, action) => {
            state.viewPrayerWall = action.payload
            localStorage.setItem('viewPrayerWall', action.payload)
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(getPrayerWallItems.pending, (state) => {
                state.isGetPrayerWallItemsLoading = true
                state.isGetPrayerWallItemsSuccess = false
            })
            .addCase(getPrayerWallItems.fulfilled, (state, action) => {
                state.isGetPrayerWallItemsLoading = false
                state.isGetPrayerWallItemsSuccess = true

                state.prayerWallItems = action.payload

            })
            .addCase(getPrayerWallItems.rejected, (state, action) => {
                state.isRequestGetLoading = false
                state.isRequestGetError = true
                state.requestGetmessage = action.payload
            })
            //----------------------------------------------------------------
            .addCase(addItemToPrayerWall.pending, (state) => {
                state.isAddingItemToPrayerWallLoading = true
            })
            .addCase(addItemToPrayerWall.fulfilled, (state, action) => {
                state.isAddingItemToPrayerWallLoading = false
                state.isAddingItemToPrayerWallSuccess = true

                state.prayerWallItems.unshift(action.payload)
            })
            .addCase(addItemToPrayerWall.rejected, (state, action) => {
                state.isAddingItemToPrayerWallLoading = false
                state.isAddingItemToPrayerWallError = true
                state.prayerWallItemsmessage = action.payload
            })

    }
})

export const { setViewPrayerWall } = prayerWallSlice.actions
export default prayerWallSlice.reducer
