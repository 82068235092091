import {React } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { closeDirectoryModal } from '../../../features/modal/directoryModalSlice'

import { BsFillCheckCircleFill } from 'react-icons/bs'
import ModalCloseButton from 'components/buttons/ModalCloseButton'

function DirectoryAddedSuccess() {

  const dispatch = useDispatch()

  return (
    <ModalWrap> 
      <ModalCloseButton
          handler={() => dispatch(closeDirectoryModal())}
          top='10px'
          right='10px'
          size='35px'
          backgroundColor='white'
          hoverBackgroundColor='#ccc'
        />

      <ModalHeading>Your directory record is submitted!</ModalHeading>
      <Icon> <BsFillCheckCircleFill/> </Icon>
    </ModalWrap>
  )
}


const ModalWrap = styled.div`
  z-index: 2;
  width: 450px;
  min-height: 320px;
  max-height: fit-content;
  background: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  position: relative;
  animation: animate 0.3s;
  padding: 5px 5px 20px 5px;
`
const Icon = styled.div`
    margin-top: 3vw;
    font-size: 7vw;
    color: green;
    @media (max-width: 768px) {
      font-size: 80px;
    }
`

const ModalHeading = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 20px;
  color: var(--lightThemeFontColor);
`


export default DirectoryAddedSuccess
