import React, {useState, useEffect, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setNewFileType, setViewAddFileModal, resetUploadState, setSelectedFileToUpload, uploadFile, setFolderToAddFile } from 'features/files/fileSlice'
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import styled from 'styled-components'
import { toast } from 'react-toastify';
import { HiOutlineCloudUpload } from "react-icons/hi";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import LoaderSmall from 'loaders/LoaderSmall'
import SpinnerSmall from 'components/SpinnerSmall'
import { 
    FileInputWrap, FIH, GoBackButton, FileModal, FileUploadInput, FileUploadButton, ButttonWrap,
    FormWrap, FolderSelect, BulletinFile, BulletinFileChild1, BulletinFileName, ChooseFileBtn
} from 'components/files/files.styled'
import { IoArrowBack } from "react-icons/io5";
import { IoIosLink } from "react-icons/io";
import { CgRename } from "react-icons/cg";

export default function AddFileModal({selectedFileType}) {

    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(setViewAddFileModal(false))
    }
    const [fileName, setFileName] = useState('')
    const [pdfSelected, setPdfSelected] = useState(null)
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { user } = useSelector((state) => state.auth)
    const { fileToUpload, collectionFiles, fileUploadLoading, fileUploadSuccess, folderToAddFile} = useSelector((state) => state.file)

    const fileNameRef = useRef(null)
    const urlRef = useRef(null)

    const handleBack = () => {
        dispatch(setNewFileType(null))
    }

    const handleBulletinName = (e) => {
        setFileName(e.target.value)
    }

    const handleSelectFolder = (e) => {
        dispatch(setFolderToAddFile(e.target.value))
    }


    const handlePdfChange = (e) => {
        setPdfSelected(e.target.files[0].name)

        let fileName = e.target.files[0].name
        let fileSize = Math.round((e.target.files[0].size / 1024))
        let fileType = fileName.slice(fileName.lastIndexOf('.')+1)

        
        if(fileType !== 'pdf'){
            setPdfSelected(null)
            return toast.error('Select a PDF file only')
        }
        if(fileSize >= 12000){
            setPdfSelected(null)
            return toast.error('Max file size is 12Mb')
        }

        dispatch(setSelectedFileToUpload(e.target.files[0]))
    }

    const handleUpload = () => {

        if(fileName.length > 100){
            return toast.error('File name is too long')
        }
        if(selectedFileType === 'url'){
            // ensure url is valid
            let url = urlRef.current.value
            if(url.length === 0){
                return toast.error('Enter a URL')
            }
            if(!url.includes('https')){
                return toast.error('Enter a valid URL')
            }
            if(!url.includes('.')){
                return toast.error('Enter a valid URL')
            }
        }else{

            if(!fileToUpload){
                return toast.error('Select a PDF file')
            }

        }


        if(fileName.length === 0){
            return toast.error('Enter a name for your file')
        }

        let folderIndex = collectionFiles.folders.findIndex(folder => folder._id === folderToAddFile)
        const formData = new FormData();
        formData.append("title", fileNameRef.current.value)  

        if(selectedFileType === 'url'){
            
            formData.append("fileType", 'url')
            formData.append("url", urlRef.current.value)

        }else{

            formData.append("fileType", 'pdf')
            formData.append("file", fileToUpload)
        }
        
        formData.append("folder", folderToAddFile === '' ? '' : folderToAddFile)
        formData.append("folderIndex", folderIndex)
        formData.append("churchId", profileChurch._id)
        formData.append("creatorType", user.isOrganization ? 'Church' : 'User')
        formData.append("creatorName", user.fullName)

        dispatch(uploadFile(formData))
 
    }

    useEffect(() => {
        if(fileUploadSuccess){ 
            dispatch(resetUploadState())
            dispatch(setViewAddFileModal(false))
        }
    }, [fileUploadSuccess])

    return (
        <FileModal>
            <ModalCloseButton 
                handler={handleClose} 
                size='30px'
                top='10px'
                right='10px'
                />
            <FormWrap>

                <GoBackButton
                    onClick={handleBack}
                    ><IoArrowBack /> Go back</GoBackButton>
                <FIH>Add or upload a file</FIH>

                <FileInputWrap> 
                    <CgRename style={{fontSize: '20px', color: 'var(--lightThemeFontColor)'}} />

                    <FileUploadInput 
                        placeholder={'Give this file a name'} 
                        onChange={handleBulletinName} 
                        ref={fileNameRef}
                        />
                </FileInputWrap>
                <BulletinFile>

                    {
                        selectedFileType === 'url' ? 
                        (

                            <FileInputWrap>
                                <IoIosLink style={{fontSize: '20px', color: 'var(--lightThemeFontColor)'}} />

                                <FileUploadInput
                                    ref={urlRef}
                                    placeholder={'Enter URL'}
                                    />                                    
                            </FileInputWrap>

                        )
                        : selectedFileType === 'file' &&
                        (
                            <>
                                <BulletinFileChild1 > 
                                    <BulletinFileName>
                                        { pdfSelected ? (pdfSelected) :  ('No file Selected') }
                                    </BulletinFileName>
                                    <FileUploadInput  
                                        hidden id="bulletinUpload"  
                                        type="file"  accept=".pdf"  name="file" placeholder={'Ehl'}
                                        onChange={handlePdfChange}
                                        />                     
                                </BulletinFileChild1>
                                <ChooseFileBtn htmlFor="bulletinUpload"> 
                                    <span>Select  PDF File</span>
                                </ChooseFileBtn>                            
                            </>

                        )
                    }


                </BulletinFile>


                <FolderSelect>
                    <InputLabel id="demo-simple-select-label">Select Folder</InputLabel>

                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        style={{width: '100%'}}
                        value={folderToAddFile}
                        onChange={handleSelectFolder} 
                        >
                            <MenuItem key={'000'} value={''}>None (Root Folder)</MenuItem>

                            {
                                collectionFiles.folders.map((folder, index) => (
                                    <MenuItem 
                                        key={folder._id} 
                                        selected={folderToAddFile === folder._id}
                                        value={folder._id}
                                        >
                                            {folder.title}
                                    </MenuItem>
                                ))
                            }
                    </Select>
                </FolderSelect>


                <ButttonWrap>
                    <FileUploadButton 
                        disabled={fileUploadLoading}
                        onClick={handleUpload}
                        >
                            {
                                fileUploadLoading ? (
                                    <SpinnerSmall />
                                )
                                :
                                (
                                    <>
                                        <HiOutlineCloudUpload style={{ marginRight: '10px'}} />
                                        <span>Submit</span>
                                    </>
                                )
                            }


                    </FileUploadButton>
                </ButttonWrap>
            </FormWrap>
        </FileModal>
    )
}


