

export const addAcFunc = async (type, callBackFunction) => {
    
    let ac = JSON.parse(sessionStorage.getItem('ac'))

    if(!ac){
        ac = {} 
    }
    
    if(!ac[type]){ 

        ac[type] = true
        sessionStorage.setItem('ac', JSON.stringify(ac))
        callBackFunction()
    }

    return;

}