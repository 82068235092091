import { useParams } from 'react-router-dom'
import { useState, useEffect}  from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { MdArrowBackIosNew } from 'react-icons/md'
import { getSingleEventData, getEventDiscussionComments, getSingleEventDataLoggedIn, sendResponse, setViewRsvpModal, setRsvpData } from 'features/events/eventSlice'
import { imgUrl, dateObj } from 'features/constants'
import styled from 'styled-components'
import Loader from 'loaders/Loader'
import Details from '../../components/events/singlePageEventComponents/Details'
import Discussion from '../../components/events/singlePageEventComponents/Discussion'
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import useImageLoader from 'hooks/useImageLoader'
import { eventLanguage } from 'components/events/eventsLanguage'
import EventMap from 'components/events/singlePageEventComponents/EventMap'
import { BiMailSend } from "react-icons/bi";
import SpinnerSmall from 'components/SpinnerSmall'
import { Modal, Tooltip } from '@mui/material'
import { LuMailCheck } from "react-icons/lu";
import RsvpModal from 'components/events/singlePageEventComponents/RsvpModal'
import ModalContainer from 'components/modal/ModalContainer'

export default function SingleEventPage(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { eventId } = useParams()
    const { 
        singlePageEventData, 
        isSingleEventLoading, 
        singleEventPrivacy,
        isResponseLoading,
        isResponseSuccess,
        viewRsvpModal,
    } = useSelector((state) => state.events)
    const [date, setDate] = useState(null) // ['detail', 'discussion'
    const [privacy, setPrivacy] = useState('')
    const [attending, setAttending] = useState(false)

    const [imageSource, { blur }] = useImageLoader(`${imgUrl}/events/${singlePageEventData && singlePageEventData.image.imagePath1}`, `${imgUrl}/events/${singlePageEventData && singlePageEventData.image.imagePath2}`)

    const [ contentView, setContentView ] = useState('details') // ['detail', 'discussion' 

    const [totalAttending, setTotalAttending] = useState(0)

    const [myEventStatus, setMyEventStatus] = useState({
        statusLoading: false,
        isAttending: false,
        isInterested: false,
        isNotAttending: false,
        label: '',
    })


    const handleResponse = (response) => {
        dispatch(sendResponse({
            eventId: singlePageEventData._id,
            response: response
        }))
    }
    
    useEffect(() => {

        if(user === null){

            if(singlePageEventData){
                setDate(new Date(singlePageEventData.startDate))
            }

            return
        }else{
 

            if(singlePageEventData && singlePageEventData.attending){
                // check if user is in the attenging array or the interested array
                if(singlePageEventData.attending.find((item) => item._id === user._id)){
                    setAttending(true)
                } else  {
                    setAttending(false)
                }     
            }
        }
    }, [singlePageEventData, isResponseSuccess])


    useEffect(() => {
        if(singlePageEventData === null){

            if(user){ 
                dispatch(getSingleEventDataLoggedIn({
                    id: eventId
                }))

                dispatch(getEventDiscussionComments({
                    id: eventId,
                    eventDiscussionCommentsArray: null
                }))

            }else{ 
                dispatch(getSingleEventData({
                    id: eventId
                }))
            }
        } else{ 
            dispatch(getEventDiscussionComments({
                id: eventId,
                eventDiscussionCommentsArray: singlePageEventData.eventDiscussionComments
            }))
        }        
    }, [])

    useEffect(() => {
        if(singleEventPrivacy === 'members'){
            setPrivacy('members')
        }else{
            setPrivacy('public')
        }
    }, [singleEventPrivacy])

    const handleClick = () => {
        navigate(-1)
    }

    const [coordinates, setCoordinates] = useState(null)

    useEffect(() => {
        if(singlePageEventData){

            if(singlePageEventData.coordinates){

                setCoordinates([singlePageEventData.coordinates.lat.toString(), singlePageEventData.coordinates.lng.toString()])
                
            }
        }

    }, [singlePageEventData])
  

    let profileImageToShow;
    try{ profileImageToShow =  `${imgUrl}/events/${singlePageEventData.image.imagePath2}`}
    catch(e){  profileImageToShow =  require(`../../profileImage/generic.png`)  }

    const handleRsvp = (response) => {

        dispatch(setRsvpData({
            eventId: singlePageEventData._id,
            status: attending ? 'attending' : 'notAttending'
        }))        
        
        dispatch(setViewRsvpModal(true))
    }



  return (
        <SinglePageContainer>

            {   user  && 
                (
                    <div className='singlePostPostedToWrap'>
                        <div className='singlePostGoBack' onClick={handleClick} > 
                            <MdArrowBackIosNew style={{marginRight: '10px'}} /> Go Back
                        </div>
                    </div>
                ) 
            }

                            
            {
                singlePageEventData && (

                    isSingleEventLoading ? (
                        <Loader />
                    )
                    :
                    (
                        <SinglePageWrap  addTopMargin={!user}>
                            <SinglePageLeft>
                                <ItemContainer>
                                    <img 
                                        onContextMenu={(e) => e.preventDefault()}
                                        src={imageSource} 
                                        alt='event' 
                                        style={{width: '100%', height: '100%', objectFit: 'cover', borderTopLeftRadius: '15px', borderTopRightRadius: '15px'}} 
                                        />
                                </ItemContainer>
        
                                <EventInfo> 
                                    <ChurchInfo>
                                        <ChurchImage>
                                            <img 
                                                onContextMenu={(e) => e.preventDefault()}
                                                src={`${imgUrl}/profile/${singlePageEventData.churchId.profileImg.imagePath2}`} 
                                                alt='event' 
                                                style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%'}} 
                                                />
                                        </ChurchImage>
                                        <ChurchName>{singlePageEventData.churchId.fullName}</ChurchName>
                                    </ChurchInfo>

                                    <EventInfoWrap>
                                        <EventTitle>
                                            {/* <CalendarIconWithDate date={(date && !isSingleEventLoading) && date.getDate().toString()} size={20} borderColor={'grey'} /> */}
                                            <p style={{marginLeft: "10px"}}>{singlePageEventData.eventName}</p>
                                        </EventTitle>
                                        <EventDate>
                                            
                                            { date && !isSingleEventLoading && ` 
                                                ${dateObj.dayLong[date.getDay()]}
                                                
                                                ${dateObj.monthLong[date.getMonth()]}

                                                ${date.getDate()}
                                                
                                                ${date.getFullYear()}
                                            `}

                                        </EventDate>
                                    </EventInfoWrap> 
                                </EventInfo>

                                <MapWrap>
                                    {
                                        coordinates && (
                                            <MapContainer style={{height: '300px', position: "relative"}}>
                                                <OpenInGoogle 
                                                    onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${singlePageEventData.locationAddress}`)}
                                                    >
                                                    {
                                                        eventLanguage.openInGoogle[user ? user.preferredLanguage : 'en']
                                                    }
                                                </OpenInGoogle>              
                                                <EventMap 
                                                    markerTitle={singlePageEventData.eventName} 
                                                    markerPosition={[singlePageEventData.coordinates.lng, singlePageEventData.coordinates.lat]}
                                                    /> 
                                            </MapContainer> 
                                        ) 
                                    } 
                                </MapWrap>
                            </SinglePageLeft>
                            <SinglePageRight>
                                <TopRightItemContainer>
                                    <SectionButton>
                                        <SectionButtonTitle
                                            active={contentView === 'details'} 
                                            onClick={() => setContentView('details')}
                                            >
                                            {
                                                eventLanguage.details[user ? user.preferredLanguage : 'en']
                                            }
                                        </SectionButtonTitle>
                                        <SectionButtonTitle
                                            disabled={privacy === 'private'}
                                            active={contentView === 'discussion'} 
                                            onClick={() => setContentView('discussion')}
                                            >
                                            {
                                                eventLanguage.discussion[user ? user.preferredLanguage : 'en']
                                            }
                                        </SectionButtonTitle>
                                    </SectionButton>

                                    {
                                        user && (
                                            <MyEventStatus>
                                                {
                                                    (user._id !== singlePageEventData.creator && singlePageEventData.allowRsvp) &&
                                                    (
                                                        attending ? 
                                                        (
                                                            <Tooltip 
                                                                title='You are attending this event, click to undo RSVP'
                                                                placement='top'
                                                                > 
                                                                <RSVPBtn
                                                                    spinIcon={'none'}
                                                                    bg={'green'}
                                                                    disabled={isResponseLoading}
                                                                    onClick={() => handleRsvp('attending')}
                                                                    >
                                                                    {
                                                                        isResponseLoading ? (
                                                                            <SpinnerSmall  />
                                                                        ) : (
                                                                            <>
                                                                                <LuMailCheck 
                                                                                    style={{marginRight: '10px'}}
                                                                                    />
                                                                                Attending        
                                                                            </>  
                                                                        ) 
                                                                    }
                                                                </RSVPBtn>
                                                            </Tooltip>
                                                        )
                                                        :
                                                        (
                                                            <RSVPBtn
                                                                spinIcon={'spin 0.5s'}
                                                                disabled={isResponseLoading}
                                                                bg={'var(--themeColor)'}
                                                                onClick={() => handleRsvp('attending')}
                                                                >
                                                                {
                                                                    isResponseLoading ? (
                                                                        <SpinnerSmall  />
                                                                    ) : (
                                                                        <>
                                                                            <BiMailSend 
                                                                                style={{marginRight: '10px'}}
                                                                                />
                                                                            RSVP        
                                                                        </>  
                                                                    ) 
                                                                }
                                                            </RSVPBtn>
                                                        )
                                                    )
                                                } 
                                            </MyEventStatus>
                                        )
                                    }

                                </TopRightItemContainer>

                                <RightMainContent>
                                    {
                                        contentView === 'details' ? (
                                            <Details 
                                                attending={attending}
                                                />
                                        ) : (
                                            <Discussion />
                                        )
                                    }
                                </RightMainContent>
                            </SinglePageRight>
                        </SinglePageWrap>
                    )
                )
            }
            <Modal
                open={viewRsvpModal}
                onClose={() => dispatch(setViewRsvpModal(false))}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <ModalContainer
                    modalBody={
                        <RsvpModal 
                            attending={attending}
                            attendingArray={singlePageEventData && singlePageEventData.attending}
                            />
                    }
                    />
            </Modal>
        </SinglePageContainer>
    )
}

const RSVPBtn = styled.div`
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    width: 130px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    cursor: pointer;
    color: var(--lightThemeFontColor);
    border-radius: 6px;
    background-color: ${props => props.bg};
    color: white;
    font-size: 12px;
    svg{
        color: white;
        font-size: 23px;
    }

    &:hover{
        background-color: var(--themeColorDark);
        /* spin the svg icon only one time */

        svg{
            animation: ${props => props.spinIcon};

        }
    }
`

const MapWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 300px;
    margin-top: 20px;
    background-color: white ;
    border-radius: 10px;
    box-shadow: 0 0 10px #ccc;

    @media (max-width: 768px) {
        margin-top: 10px;
    }


`

const MapContainer = styled.div`
  position: relative;
  min-height: 200px;
  height: 100%;
  width: 100%; 
  border-radius: 10px; 
  border: 1px solid #d6d6d6; 
`

const OpenInGoogle = styled.button` 
  position: absolute;
  top: 5px;
  border: 1px solid #b0b0b0;
  outline: none;
  border-radius: 3px;
  background-color: white;
  right: 5px;
  z-index: 999;
  color: #666666;
  padding: 5px;
  cursor: pointer;
  font-size: 11px;
`

const SinglePageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
    margin-top: 20px;
    height: fit-content;
    margin: 0px auto;

    @media (max-width: 1468px) {
        width: 80%;
    }

    @media (max-width: 1068px) {
        width: 100%;
    }

    @media (max-width: 968px) {
        width: 100%;
    }

`
const SinglePageWrap = styled.div`
    display: flex;
    height: calc(100vh - 100px);
    flex-direction: row;
    width: 100%;  
    margin-top: ${props => props.addTopMargin ? '20px' : '0px'};

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 0px;
    }
`
const SinglePageLeft = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: fit-content ;

    @media (max-width: 768px) {
        width: 100%;  
        padding: 10px; 
        height: fit-content;    
    }
`
const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    width: 100%;
    height: 50%;
    background-color: white;
    @media (max-width: 768px) {
        height: 100%;
    }
`
const EventInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 10px;
    background-color: white;
    border-radius: 0px 0px 15px 15px;
    box-shadow: 0 0 10px #ccc;
    @media (max-width: 768px) {
        padding: 10px;
        height: 100%;
    }
`
const EventInfoWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    height: fit-content;
    padding: 10px;

    @media (max-width: 768px) {
        padding: 0px;
    }
`

const ChurchInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 10px;
    border-bottom: 1px solid #ececec;
    @media (max-width: 768px) {
        padding: 10px; 
    }
`
const ChurchImage = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
`
const ChurchName = styled.div`
    font-size: 15px;
    color: var(--lightThemeSecondaryFontColor);
    font-weight: 500;
    padding: 10px;
    @media (max-width: 768px) {
        padding-left: 10px;
    }
`

const EventTitle = styled.div`
    font-size: 17px;
    color: var(--lightThemeFontColor);
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 768px) {
        margin-top: 10px;
    }
`

const EventDate = styled.div`
    font-size: 12px;
    color: var(--lightThemeSecondaryFontColor);
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    @media (max-width: 768px) {
        padding: 5px;
    }
`

const SinglePageRight = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 45%;
    padding: 0px 20px 10px 20px;
    /* border: 1px solid red; */
    @media (max-width: 768px) { 
        padding: 5px;
        width: 100%;
    }
`

const TopRightItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    box-shadow: 0 0 10px #ccc;
    width: 100%;
    height: fit-content;
    background-color: white; 
`


const SectionButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;

`
const SectionButtonTitle = styled.div`
    padding: 10px 20px;
    cursor: pointer; 
    
    pointer-events: ${props => props.active || props.disabled ? 'none' : 'auto'};
    border-bottom: ${props => props.active ? '3px solid var(--themeColor)' : '3px solid transparent'};
    color: var(--lightThemeFontColor);
    transition: all 0.4s;
    &:hover{
        border-radius: ${props => props.active ? 'none' : '10px'};
        background-color: #ececec;
        border-bottom: 3px solid transparent;
    };

`
const MyEventStatus = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    cursor: pointer;
    color: var(--lightThemeFontColor);
`

const RightMainContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content ; 
    margin-top: 20px; 
    @media (max-width: 768px) {
        margin-top: 10px;
        height: fit-content;
    }
`