import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { addEventDiscussionReply, getEventDiscussionCommentReplies, setLoadingCommentIdReplies } from 'features/events/eventSlice'
import { imgUrl } from 'features/constants'
import TimeAgo from 'timeago-react'
import SendIcon from '@mui/icons-material/Send';
import { FaReply } from "react-icons/fa";
import { LiaCommentsSolid } from "react-icons/lia";


export default function DiscussionCommentItem(props) {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const [viewReplies, setViewReplies] = useState(false) 
    const [createReply, setCreateReply] = useState(false)
    const [reply, setReply] = useState('')
    const [isHovered, setIsHovered] = useState(false)

    const { 
        eventDiscussionComments, 
        commentIndexLoading,
        latestReply,
        commentRepliesSuccess,
        commentRepliesLoading,
        commentIdReplies,
        commentIdsOfRepliesLoaded
    } = useSelector((state) => state.events)


    const handleViewReplies = () => { 
        //check if replies are loaded
        // if not loaded, dispatch getEventDiscussionCommentReplies

        dispatch(setLoadingCommentIdReplies({
            commentId: props.data._id,
            index: props.index,
            loading: true,
            status: 'loading',
        }))

        if(!viewReplies){
            // if the replies are not loaded, dispatch getEventDiscussionCommentReplies

            if(!commentIdsOfRepliesLoaded.includes(props.data._id)){
                dispatch(getEventDiscussionCommentReplies({
                    commentId: props.data._id,
                    repliesArrayList: props.data.replies,
                    index: props.index,
                })) 
            }else if(commentIdsOfRepliesLoaded.includes(props.data._id)){
                // in redux, we store the commentIds for which replies have been loaded
                setViewReplies(true)
            
                dispatch(setLoadingCommentIdReplies({
                    commentId: props.data._id,
                    index: props.index,
                    loading: false,
                    status: 'success',
                }))
            }            
        }else{
            setViewReplies(false)
        }
        console.log(commentIndexLoading)
    }

    useEffect(() => {
        if(commentRepliesSuccess && commentIdReplies === props.data._id){
            if(eventDiscussionComments[props.index].repliesLoaded === props.data._id){
                setViewReplies(true)
            }           
            else{
                setViewReplies(false)
            } 
        }
    }, [eventDiscussionComments, commentRepliesSuccess, commentIdReplies, props.data._id, props.index])

    const handleCreateReply = () => {
        // setViewReplies(false)
        dispatch(addEventDiscussionReply({
            discussionCommentId : props.data._id,
            authorId: user._id,
            replyingTo: props.data.authorName,
            displayName: user.fullName,
            reply: reply,
            index: props.index,
        }))
        setReply('')
        setCreateReply(false)
    }

    let newDate = new Date(props.data.createdAt)

    return (
        <ComItemCont
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            >
            <TG>
                <TopCommentSection>
                    <TopLeftSection>
                        <TGImage> <img src={`${imgUrl}/profile/${props.data.authorId.profileImg.imagePath2}`} alt="user" /></TGImage>       
                        <HeadingText>{props.data.authorName}</HeadingText>
                    </TopLeftSection>

                    <TopRightSection>
                        <CommentDate><TimeAgo datetime={newDate}/></CommentDate>
                    </TopRightSection>
                </TopCommentSection>

                <CommentText>{props.data.comment}</CommentText>
                <Btns>
                    {props.data.replies.length > 0 && (
                        <CommentButton
                            visible={true}
                            disabled={commentRepliesLoading}
                            onClick={handleViewReplies}
                            >
                                <LiaCommentsSolid style={{ marginRight: '5px', fontSize: '14px'}} />

                                {
                                    // ((commentIndexLoading.commentId === props.data._id)
                                    // && 
                                    // (commentIndexLoading.status === 'loading') ) ? ('loading') :
                                    (
                                        viewReplies ? ('Hide replies') : ('View replies')
                                    )
                                }
                        </CommentButton>  
                    )}                      
                    <CommentButton 
                        visible={true}
                        onClick={() => setCreateReply(!createReply)}>
                            <FaReply style={{ marginRight: '5px', fontSize: '14px'}} /> Reply
                    </CommentButton>
                </Btns>
            </TG>
            {
                createReply && (
                    <ReplyInputWrap>
                        <ReplyInput 
                            type="text" 
                            placeholder="Add a reply" 
                            value={reply}
                            onChange={(e) => setReply(e.target.value)}
                            /> 
                        <ReplyButton
                            disabled={reply.length < 3}
                            onClick={handleCreateReply}
                            >
                                <SendIcon />
                        </ReplyButton>
                    </ReplyInputWrap>
                )
            }   
            {

                (
                    viewReplies &&
                    commentIndexLoading.commentId === props.data._id && 
                    commentIndexLoading.status === 'success'
                ) 
                && 
                (
                    <RepliesContainer>
                        <h2>Replies</h2>
                        {
                            props.data.replies.map((ele) => (
                                <TGWrap>
                                    <TGImage> <img src={`${imgUrl}/profile/${ele.authorId.profileImg.imagePath2}`} alt="user" /></TGImage>       
                                    <TG
                                        isReply={true}
                                        key={ele._id}
                                        >
                                        <TopCommentSection>
                                            <TopLeftSection> 
                                                <HeadingText>{ele.authorName}</HeadingText>
                                            </TopLeftSection>
                                            <TopRightSection>
                                                <CommentDate><TimeAgo datetime={newDate}/></CommentDate>
                                            </TopRightSection>
                                        </TopCommentSection>
                                        <CommentText>{ele.reply}</CommentText>
                                    </TG>
                                </TGWrap>
                            ))
                        }
                    </RepliesContainer>
                )
            }
   
        </ComItemCont>
    )
}

const TGWrap = styled.div` 
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;

`
const TGImage = styled.div`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 6px;
    margin-top: 3px; 
    img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
`

const ComItemCont = styled.div`
    width: 100%;  
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    padding : 5px; 
`

const TG = styled.div` 
    width: ${props => props.isReply ? 'fit-content' : '100%'};
    margin-bottom: ${props => props.isReply ? '5px' : '0px'};
    max-width: ${props => props.isReply ? '80%' : '100%'};
    padding: 10px 10px 10px 10px; 
    height: fit-content;
    border-radius: ${props => props.isReply ? '15px 0px 15px 15px':'none'  };
    border-bottom: ${props => props.isReply ? 'none' : '1px solid #ececec'};
    display: flex;
    flex-direction: column;
    text-align: left; 
    background-color: ${props => props.isReply ? 'white' : 'none'};
`

const TopCommentSection = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

`

const TopLeftSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 10px;
`

const TopRightSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
const CommentDate = styled.div`
    font-size: 12px;
    color: var(--lightThemeSecondaryFontColor);
`

const CommentText = styled.div`
    padding-top: 10px;
    font-size: 13px;
    color: var(--lightThemeFontColor);
`

const HeadingText = styled.div` 
    font-weight: 500;
    font-size: 13px;
    color: var(--lightThemeFontColor);
`

const Btns = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`

const CommentButton = styled.div` 
    font-size: 11px;
    padding: 8px 13px;
    color: #909090;
    cursor: pointer;
    transition: all 0.5s; 
    margin-right: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &:hover{
        transition: all 0.5s;
        background-color: #e6e6e6;
        color: #474747;
    }
`

const RepliesContainer = styled.div`
    width: 100%;
    height: fit-content;  
    display: flex;
    flex-direction: column;
    align-items: flex-end;  
    margin-top: 1px;
    /* background-color: #e9e9e9; */
    background-color: aliceblue;
    border: 1px solid #ececec;
    padding: 2px 15px 10px 0px;
    border-radius: 0px 0px 15px 45px;
    max-height: 300px;
    overflow-y: scroll; 

    &::-webkit-scrollbar {
        width: 0px;
        background: transparent; 
    }
    -ms-overflow-style: none; 
    scrollbar-width: none; 

    h2{
        width: 100%;
        text-align: left;
        font-size: 14px;
        font-weight: 300;
        padding: 5px 0px 5px 10px;
        color: var(--lightThemeFontColor);
    }
`

const ReplyInputWrap = styled.div` 
    margin-top: 5px;
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 5px ;
    background-color: aliceblue;
`

const ReplyButton = styled.button`
    padding: 2px 10px;
    font-size: 10px;
    border: none;
    outline: none;
    color: ${props => props.disabled ? 'grey' : 'white'};
    border-radius: 5px;
    cursor: pointer;
    background-color: ${props => props.disabled ? 'none' : 'green'};

    &:hover{
        transition: all 0.4s;  
    } 
`
 
const ReplyInput = styled.input`
    background-color: aliceblue;
    border: none;
    outline: none;
    width: 100%;
    padding: 5px; 
`