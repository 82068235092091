import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react';
import { likePost } from '../../features/posts/singlePostSlice'
import { FaPray } from 'react-icons/fa'
import { MdEmojiPeople } from 'react-icons/md'
import styled from 'styled-components'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { setViewWhoIsPrayingModal, setSelectPostToViewPrayers } from 'features/posts/postSlice'

function PostActions() {
  const dispatch = useDispatch()
  const { singlePost, singlePostLikes, isPostLikesSuccess } = useSelector((state) => state.singlePost)
  const { 
    sPComments, 
    totalComments 
  } = useSelector((state) => state.sPComments)
  const { user } = useSelector((state) => state.auth)
  const [isLiked, setIsLiked] = useState()


  useEffect(() => {
    let obj = singlePost.likes.find( ele => ele.toString() === user._id)//if user already likes post setlike'
    if(obj){setIsLiked(true)
    }else{ setIsLiked(false)}
  }, [singlePost.likes , user._id, singlePostLikes])

  const handleLikePost = () => {

    if(singlePost.user._id === user._id){

      console.log('view who is praying')

      dispatch(setSelectPostToViewPrayers(singlePost.likes))
      dispatch(setViewWhoIsPrayingModal(true))

      return
    }

      dispatch(likePost({
        postId: singlePost._id,
      }
    ))
  }

  useEffect(() => {
    if(isPostLikesSuccess){
      setIsLiked(true)
    }
  }, [isPostLikesSuccess ])


  return (
    <SinglePostActions>
      { singlePost.postType === 'Prayer Request' ? 
        ( 
          <SpActionsStyle 
            style={{pointerEvents: `${isLiked ? 'none' : 'auto'}`}}
            onClick={handleLikePost}
            >
            <FaPray 
              style={{ marginRight: '7px'}}  
              /> 
              {
                singlePost.user._id === user._id ? 'See who is praying' : isLiked ? 'You are praying' : 'Express your desire to pray'
              }
          </SpActionsStyle> 
        )
        :
        (
          <SpActionsStyle 
            onClick={handleLikePost} 
            style={{cursor: 'pointer'}} 
            >
            <MdEmojiPeople style={{ marginRight: '7px'}} />
              Praise the Lord!
          </SpActionsStyle>
        )
      } 
      <SpActionsStyle style={{pointerEvents: 'none'}}  > 
        <QuestionAnswerIcon style={{ marginRight: '7px'}}/>
          <span style={{marginRight: '5px'}}>
            { 'Comments ' } 
            {( sPComments && totalComments > 0 ) &&  ( `(${totalComments})` ) }
          </span>
      </SpActionsStyle>
    </SinglePostActions>
  )
}

const SpActionsStyle = styled.div`
  padding: 10px 15px;
  font-size: 15px;
  color: #75797a; 
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.4s ;
  svg{
    font-size: 18px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 7px 14px;
  }

  &:hover{
    background-color: #f0f2f5; 
  
  }

`

const SinglePostActions = styled.div`
    width: 100%;
    min-height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top: 1px solid rgb(185, 185, 185);
    color: #75797a; 
    font-size: 20px;
  `

export default PostActions