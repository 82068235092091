// import { useState, useEffect } from 'react'
// import '../../css/components/settings/requests.css'
import { SettingsMainContainer, TopHeaderSettings } from './settings.styled'
import { useSelector } from 'react-redux'
import Loader from "loaders/Loader";
import ReqReceivedItem from './requests/ReqReceivedItem'  
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import { settingsLanguage } from './settingsLanguage'

function RequestsRecieved() {

    const { requestsReceived, isRequestGetSuccess, isRequestGetLoading, isRequestGetError, requestGetmessage } = useSelector((state) => state.requests)
    const { user } = useSelector((state) => state.auth)
    
    return (
        <SettingsMainContainer>
            <TopHeaderSettings>
                {
                    settingsLanguage.requestsRecieved[user.preferredLanguage]
                }
            </TopHeaderSettings>

            { isRequestGetLoading ? 
                (
                    <Loader />
                )
                : isRequestGetSuccess && !isRequestGetLoading && requestsReceived.length > 0 ?
                (
                    requestsReceived.map( ele => (
                        <ReqReceivedItem 
                            key={ele.senderId._id}
                            reqId={ele._id}
                            userId={ele.senderId._id}
                            fullName={ele.senderId.fullName}
                            username={ele.senderId.username}
                            image={ele.senderId.profileImg.imagePath2} 
                            createdAt={ele.createdAt}
                        />
                    )) 
                )
                :
                (
                    <div className='noRequestsWrap'>
                        <div> <PersonAddDisabledIcon className="noRequestsIcon" /></div>
                        <div className='noRequests'>
                            {
                                settingsLanguage.noRequests[user.preferredLanguage]
                            }
                        </div>
                    </div>
                    
                )}
        </SettingsMainContainer>
    )
}

export default RequestsRecieved 
