import React from 'react'
import styled from 'styled-components'
import { useEffect } from 'react'

export default function DirToolTip(props) {
  return (
    <DTTWrap> 
        <Sq></Sq>
        <DTTText>
          {props.value}  
        </DTTText> 
    </DTTWrap>
  )
}

const DTTWrap = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    transition: all 0.4s;
`
const Sq = styled.div`
    transform: rotate(45deg);
    background-color: rgb(92, 92, 92);
    width: fit-content;
    padding: 5px;
    border-radius: 
    margin-left: 0px;
`

const DTTText = styled.div`
    color: white;
    background-color: rgb(92, 92, 92);
    width: fit-content;
    padding: 5px;
    font-size: smaller;
    margin-top: -5px;
    border-radius: 5px;
    overflow-wrap: normal;
`