export const mediaLanguage = {
    noVideos: {
        en: 'No videos found',
        es: 'No hay videos disponibles',
    },
    videos: {
        en: 'Videos',
        es: 'Videos',
    },
    addVideo: {
        en: 'Add Video',
        es: 'Añadir Video',
    },
    toastSuccess: {
        en: 'Video added',
        es: 'Video añadido',
    },
    toastErrorTitle: {
        en: 'Title is required',
        es: 'Se requiere un título',
    },
    toastErrorAddSomething: {
        en: 'Add something meaningful',
        es: 'Agrega algo aquí',
    },
    add: {
        en: 'Add',
        es: 'Añadir',
    },
    createButton: {
        en: 'Create',
        es: 'Crear',
    },
    videoTitle: {
        en: 'Video Title',
        es: 'Título del Video',
    },
    videoDescription: {
        en: 'Video Description',
        es: 'Descripción del Video',
    },
    videoUrl: {
        en: 'Video URL',
        es: 'URL del Video',
    },
    delete: {
        en: 'Delete',
        es: 'Eliminar',
    },
    totalVideos: {
        en: 'Total Videos',
        es: 'Videos Totales',
    },
    images: {
        en: 'Images',
        es: 'Imágenes',
    },
    noImages: {
        en: 'No images available',
        es: 'No hay imágenes disponibles',
    },
    addImage: {
        en: 'Add Image',
        es: 'Añadir Imagen',
    },
    toastSuccessImage: {
        en: 'Image added',
        es: 'Imagen añadida',
    },
    imageTitle: {
        en: 'Image Title',
        es: 'Título de la Imagen',
    },
    audio: {
        en: 'Audio',
        es: 'Audio',
    },
    noAudio: {
        en: 'No audio files available',
        es: 'No hay audio disponible',
    },
    addAudio: {
        en: 'Add Audio',
        es: 'Añadir Audio',
    },
    toastSuccessAudio: {
        en: 'Audio added',
        es: 'Audio añadido',
    },
    audioTitle: {
        en: 'Audio Title',
        es: 'Título del Audio',
    },
    audioDescription: {
        en: 'Audio Description',
        es: 'Descripción del Audio',
    },
    audioUrl: {
        en: 'Audio URL',
        es: 'URL del Audio',
    },
    totalAudio: {
        en: 'Total Audio',
        es: 'Audio Total',
    },
    totalImages: {
        en: 'Total Images',
        es: 'Imágenes Totales',
    },
    addAudioButton: {
        en: 'Add Audio',
        es: 'Añadir Audio',
    },
    addImageButton: {
        en: 'Add Image',
        es: 'Añadir Imagen',
    },
    addVideoButton: {
        en: 'Add Video',
        es: 'Añadir Video',
    },
    addMediaButton: {
        en: 'Add Media',
        es: 'Añadir Multimedia',
    },
    media: {
        en: 'Media',
        es: 'Multimedia',
    },
    mediaTitle: {
        en: 'Media Title',
        es: 'Título del Multimedia',
    },
    mediaDescription: {
        en: 'Media Description',
        es: 'Descripción del Multimedia',
    },
    mediaUrl: {
        en: 'Media URL',
        es: 'URL del Multimedia',
    },
    mediaType: {
        en: 'Media Type',
        es: 'Tipo de Multimedia',
    },
}