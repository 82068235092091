import axios from 'axios'

import { baseUrl } from '../constants'
const URL = baseUrl

const API_URL = '/api/profileChurch/'
const API_URL_REQUEST = '/api/profileChurch/sendJoinRequest'
const API_URL_ADDABOUT = '/api/profileChurch/addPersonAboutSection'
const API_URL_EDITPERSON = '/api/profileChurch/editChurchPerson'
const API_URL_EDITDESCRIPTION = '/api/profileChurch/editChurchDescription'
const API_URL_NOTLOGGEDIN = '/api/profileChurch/getProfileChurchDataNotLoggedIn'
const API_URL_SETTINGS = '/api/profileChurch/getSettings'
const API_URL_SETTINGS_UPDATE = '/api/profileChurch/updateSettings'

//for sending the token
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }


const getProfileChurchData = async (profileChurchData, token) => {
  const response = await axios.post(URL+API_URL, profileChurchData, config(token))
  return response.data
}

const sendJoinRequest = async (profileChurchData, token) => {
  const response = await axios.post(URL+API_URL_REQUEST, profileChurchData, config(token))
  return response.data
}

const addPersonAboutSection = async (profileChurchData, token) => {
  const response = await axios.post(URL+API_URL_ADDABOUT, profileChurchData, config(token)) 
  return response.data
}

const editChurchPerson = async (profileChurchData, token) => {
  const response = await axios.post(URL+API_URL_EDITPERSON, profileChurchData, config(token))
  return response.data
}

const deleteChurchPerson = async (profileChurchData, token) => {
  const response = await axios.post(URL+'/api/profileChurch/deleteChurchPerson', profileChurchData, config(token))
  return response.data
}

const editChurchDescription = async (profileChurchData, token) => {
  const response = await axios.post(URL+API_URL_EDITDESCRIPTION, profileChurchData, config(token))
  return response.data
}

const getProfileChurchDataNotLoggedIn = async (profileChurchData) => {
  const response = await axios.post(URL+API_URL_NOTLOGGEDIN, profileChurchData)
  return response.data
}

const getSettings = async (data, token) => {
  const response = await axios.post(URL+API_URL_SETTINGS, data, config(token))
  return response.data
}

const updateSettings = async (settingsData, token) => {
  const response = await axios.post(URL+API_URL_SETTINGS_UPDATE, settingsData, config(token))
  return response.data
}

const getMyChurches = async (data, token) => {
  const response = await axios.post(URL+'/api/profileChurch/getMyChurches', data, config(token))
  return response.data
}

const createChurchFeedback = async (data, token) => {
  const response = await axios.post(URL+'/api/profileChurch/createChurchFeedback', data, config(token))
  return response.data
}

const getChurchFeedback = async (data, token) => {
  const response = await axios.post(URL+'/api/profileChurch/getChurchFeedback', data, config(token))
  return response.data
}

const profileChurchService = {
    getProfileChurchData, 
    sendJoinRequest, 
    addPersonAboutSection, 
    editChurchPerson,
    editChurchDescription,
    getProfileChurchDataNotLoggedIn,
    getSettings,
    updateSettings,
    deleteChurchPerson,
    getMyChurches,
    createChurchFeedback,
    getChurchFeedback
  }
  
  export default profileChurchService