import { useEffect } from 'react'
import { useState } from 'react'
import { BiCommentAdd } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createConversation, chooseConversation, viewNoConversation  } from '../../../features/messenger/conversationSlice'
import styled from 'styled-components'
import { imgUrl } from 'features/constants'
import { MImageSmall } from '../zStyling.styled'
import AddIcon from '@mui/icons-material/Add';


function ViewAllItem( { userId, fullName, username, image, isOtherMember }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const { conversations, newConversation, conversationType, isCreateConversationError, isCreateConversationSuccess, alreadyExists, alreadyExistsId } = useSelector(state => state.conversations)

  const handleClick = async () => {
    //in backend, if convo does not exist it will create a new one, and return that
    dispatch( createConversation( { 
      receiver: userId,
      conversationType: isOtherMember ? 'user' : 'church',
      isOtherMember: isOtherMember
    }))
  }

  useEffect(() => {
    if(isCreateConversationSuccess && !alreadyExists){

      if(conversationType === 'm2m'){
        navigate(`/messages/${newConversation}/mm`, {replace: true})
      }else{
         navigate(`/messages/${newConversation}`, {replace: true})
      }
    }else if (alreadyExists){
      if(conversationType === 'm2m'){
        navigate(`/messages/${alreadyExistsId}/mm`, {replace: true})
      }else{
        navigate(`/messages/${alreadyExistsId}`, {replace: true})
      }
    }

  }, [isCreateConversationSuccess, navigate, alreadyExists, newConversation, alreadyExistsId, conversationType])



  let imageToShow;
  try{ imageToShow =  `${imgUrl}/profile/${image}`}
  catch(e){  imageToShow =  null}

  return (
    <ViewAllItemWrap  onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} >

      <div className="vaiTop">
        <div> { imageToShow ?
              (
                  <MImageSmall src={imageToShow} alt='Conversation' />      
              )
              :
              (
                  <MImageSmall src={`${imgUrl}/profile/generic.jpg`} alt='Default' />
              )
          }
        </div>
              <ItemName>{fullName}</ItemName>
      </div>

      {
         show && 
         (
             <VaiSelect onClick={handleClick} > 
              <AddIcon />  
                <span>
                  New Message  
                </span>
             </VaiSelect> 
         )
      }
       
    </ViewAllItemWrap>
  )
}

export default ViewAllItem

const ViewAllItemWrap = styled.div`
    padding: 4px 6px;
    margin-top: 11px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: 0.4s;
    cursor: pointer;
    width: 100%;
    font-size: smaller;

    &:hover{
      box-shadow: none;
      background-color: #e4e1e1;
    }

    @media (max-width: 768px) {  
      font-size: 10px;
    }
`

const ItemName = styled.div`
    margin-left: 8px;
    font-size: 13px;
    font-weight: 500;
    color: var(--lightThemeFontColor);
    @media (max-width: 768px) {
      font-size: 10px;
    }
`

const VaiSelect = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0px 10px;
    background-color: var(--ltGreenButton);
    margin-left: 8px;
    border-radius: 8px;
    color: white;
    font-size: x-small;

    svg{
      font-size: .8rem;
      margin-right: 4px;
    }

    &:hover{
      transition: all 0.4s;
      cursor: pointer;
      background-color: green;
    }

`