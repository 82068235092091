import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './authService'


const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

// Get user from localStorage
const user = JSON.parse(localStorage.getItem('user'))
const pr = JSON.parse(sessionStorage.getItem('pr')) //privileges
const loginType = localStorage.getItem('loginType')
const reqisterType = localStorage.getItem('registerType')
 

const initialState = {

  user: user ? user : null,
  isRegisterUserLoading: false,
  isRegisterOrganizationLoading: false,
  isRegisterSuccessUser: false,
  isRegisterSuccessChurch: false,

  termsAccepted: false,
  errorField: '',

  isLoginLoading: false, 
  isLoginError: false,
  loginMessage: '',

  isError: false,
  isSuccess: false,
  isLoading: false,
  isloginSuccess: false,

  admin: pr ? pr.admin : {}, // object of church ids
  moderator: pr ? pr.moderator : {}, // object of church ids
  lastUpdatedPr: pr ? pr.lastUpdated: null,

  message: '',

  viewHelpSidePanel: false,

  registrationType: null,

  userType: loginType ? loginType : 'church',
  registerType: reqisterType ? reqisterType : 'church',

  registrantEmail: '',

  isEmailResendLoading: false,
  isEmailResendSuccess: false,
  isEmailResendError: false,
  emailResendMessage: '',

  isEmailVerificationLoading: false,
  isEmailVerificationSent: false,
  isEmailVerificationSuccess: false,
  isEmailVerificationError: false,
  emailVerificationMessage: '',
  errorCount: 0,

  isAvailableLoading: false,
  isAvailableLoadingType: '',
  isAvailableSuccess: false,
  isAvailableMessage: '',
  isAvailableError: false,
  isEmailAvailable: false,
  isUsernameAvailable: false,

  isEmailCodeVerificationLoading: false,
  isEmailCodeVerificationSuccess: false,
  isEmailCodeVerificationError: false,
  emailCodeMessage: '',

  codeVerified: false,
  emailVerified: false,
  viewEmailConfirmModal: false,

  zipCodeData: null,
  isZipCodeLoading: false,
  isZipCodeSuccess: false,
  isZipCodeError: false,
  zipCodeMessage: '',

  isForgotPasswordLoading: false,
  viewForgotPasswordModal: false,
  forgotPasswordUserType: null,
  isResetPasswordSuccess: false,

  registerModalData: null,

  imageUpdated: false,

  marketLocationLoading: false,
  marketLocationSuccess: false,
  marketLocationError: false,
  marketLocationMessage: '',

  viewPrefferedLanguageModal: false, 
  updatePreferredLanguageLoading: false,
  updatePreferredLanguageSuccess: false,
  updatePreferredLanguageError: false,
  updatePreferredLanguageMessage: '',

}

// Register user
export const register = createAsyncThunk(
  'auth/register',
  async (user, thunkAPI) => {
    try {
      return await authService.register(user)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


// Register Organization
export const registerChurch = createAsyncThunk(
  'auth/registerChurch',
  async (data, thunkAPI) => {
    try {
      return await authService.registerChurch(data)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


// Login user
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
  try {
    return await authService.login(user)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Login organization
export const loginOrganization = createAsyncThunk('auth/loginOrganization', async (user, thunkAPI) => {
  try {
    return await authService.loginOrganization(user)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Logout test 
export const logoutUser = createAsyncThunk('auth/logoutUser', async (user, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.logoutUser(user, token)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})


export const logout = createAsyncThunk('auth/logout', async () => {
  await authService.logout()
})

export const logoutUserFaraSesiune = createAsyncThunk('auth/logoutUserFaraSesiune', async () => {
  await authService.logoutUserFaraSesiune()
})

export const verifyUsernameAvailable = createAsyncThunk(
  'auth/verifyUserNameAvailable',
  async (data, thunkAPI) => {
    try {
      return await authService.verifyUsernameAvailable(data)
    } catch (error) {
    const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const verifyEmailAvailable = createAsyncThunk(
  'auth/verifyEmailAvailable',
  async (data, thunkAPI) => {
    try {
      return await authService.verifyEmailAvailable(data)
    } catch (error) {
    const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const sendVerificationEmailCode = createAsyncThunk(
  'auth/sendVerificationEmailCode',
  async (data, thunkAPI) => {
    try {
      return await authService.sendVerificationEmailCode(data)
    } catch (error) {
    const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const verifyEmailCode = createAsyncThunk(
  'auth/verifyEmailCode',
  async (data, thunkAPI) => {
    try {
      return await authService.verifyEmailCode(data)
    } catch (error) {
    const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (data, thunkAPI) => {
    try {
      return await authService.forgotPassword(data)
    } catch (error) {
    const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getUsZipCodeData = createAsyncThunk(
  'auth/getUsZipCodeData',
  async (data, thunkAPI) => {
    try {
      return await authService.getUsZipCodeData(data)
    } catch (error) {
    const message = errorMessage(error)
      
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const addAc = createAsyncThunk(
  'auth/addac',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await authService.addAc(data, token)
    } catch (error) {
    const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateMyMarketLocation = createAsyncThunk(
  'auth/updateMyMarketLocation',
  async (data, thunkAPI) => {
    try {
      return await authService.updateMyMarketLocation(data)
    } catch (error) {
    const message = errorMessage(error)
      
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updatePreferredLanguage = createAsyncThunk(
  'auth/updatePreferredLanguage',
  async (data, thunkAPI) => {
    try {
      return await authService.updatePreferredLanguage(data)
    } catch (error) {
    const message = errorMessage(error)
    return thunkAPI.rejectWithValue(message)
    }
  }
)
      
export const pastravzta = createAsyncThunk(
  'auth/pastravzta',
  async (data, thunkAPI) => {
    try {
      return await authService.pastravzta(data)
    } catch (error) {
      // const message = errorMessage(error)
      console.log('error', error)
      // return thunkAPI.rejectWithValue(message)
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {


    resetAuth: (state) => initialState,

    resetEverythingButUser: (state) => {
      state.isError = false
      state.isSuccess = false
      state.isLoading = false
      state.isloginSuccess = false
      state.message = ''
      state.isRegisterUserLoading = false
      state.isRegisterOrganizationLoading = false
      state.isRegisterSuccessUser = false
      state.isRegisterSuccessChurch = false
      state.isAvailableLoading = false
      state.isAvailableLoadingType = ''
      state.isAvailableSuccess = false
      state.isAvailableMessage = ''
      state.isAvailableError = false
      state.isEmailAvailable = false
      state.isUsernameAvailable = false

      state.isEmailCodeVerificationLoading = false
      state.isEmailCodeVerificationSuccess = false
      state.isEmailCodeVerificationError = false
      state.emailCodeMessage = ''

      state.codeVerified = false
      state.emailVerified = false
      state.viewEmailConfirmModal = false

      state.zipCodeData = null
      state.isZipCodeLoading = false
      state.isZipCodeSuccess = false
      state.isZipCodeError = false
      state.zipCodeMessage = ''

      state.isForgotPasswordLoading = false
      state.viewForgotPasswordModal = false
      state.forgotPasswordUserType = null
      state.isResetPasswordSuccess = false

      state.registerModalData = null

    },

    resetUserForLogout: (state) => {
      state.user = null
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },

    toggleAcceptTerms: (state) => {
      state.termsAccepted = !state.termsAccepted
    },

    setErrorField: (state, action) => {
      state.errorField = action.payload
    },

    resetUser: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
    setUserType: (state, action)  => {
      state.userType = action.payload
    },
    setViewEmailConfirmModal: (state, action) => {
      state.registrantEmail = action.payload.email
      state.viewEmailConfirmModal = true
    },
    setCloseEmailConfirmModal: (state, action) => {
      state.viewEmailConfirmModal = false
    },
    setEmailVerified: (state, action) => {
      state.emailVerified = true
    },
    resetRegisterSuccess: (state) => {
      state.isRegisterSuccessUser = false
      state.isRegisterSuccessChurch = false
    },
    closeForgotPassword: (state) => {
      state.viewForgotPasswordModal = false
    },
    setViewForgotPassword: (state) => {
      state.viewForgotPasswordModal = true
    },
    setForgotPasswordUserType: (state, action) => {
      state.forgotPasswordUserType = action.payload
    },

    setRegisterModalData: (state, action) => {
      state.registerModalData = action.payload
    },

    setUpdatedImage: (state, action) => {
      state.user.imagePath = action.payload
      state.imageUpdated = true
    },

    setPrivilege: (state, action) => {
      state[`${action.payload.type}`] = action.payload.value
    },

    setAdmin: (state, action) => {

      let pr = JSON.parse(sessionStorage.getItem('pr'))
      
      if(!pr){
        pr = {
          admin: {},
          moderator: {}
        }
      }

      state.admin = {
        ...state.admin,
        [action.payload.id]: action.payload.value
      }
      pr.admin = state.admin

      localStorage.setItem('pr', JSON.stringify(pr))

    },

    setModerator: (state, action) => {
      
      let pr = JSON.parse(sessionStorage.getItem('pr'))
      
      if(!pr){
        pr = {
          admin: {},
          moderator: {}
        }
      }

      state.moderator = {
        ...state.moderator,
        [action.payload.id]: action.payload.value
      }

      pr.moderator = state.moderator

      sessionStorage.setItem('pr', JSON.stringify(pr))

    },

    updateUser: (state, action) => {
      state.user[action.payload.type] = action.payload.value
    },

    setRegistrationType: (state, action) => {
      state.registrationType = action.payload
      state.registerType = action.payload
    },

    setViewHelpSidePanel: (state, action) => {
      state.viewHelpSidePanel = action.payload
    },
    setViewPrefferedLanguageModal: (state, action) => {
      state.viewPrefferedLanguageModal = action.payload
    },

   },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isRegisterUserLoading = true
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isSuccess = true
        state.isRegisterUserLoading = false
        state.isRegisterSuccessUser = true

        localStorage.setItem('loginType', 'user')

      })
      .addCase(register.rejected, (state, action) => {
        state.isRegisterUserLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      //-----------------------------------------------------      
      .addCase(login.pending, (state) => {
        state.isLoginLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoginLoading = false
        state.isloginSuccess = true
        
        state.user = action.payload
        state.isError = false
      
        state.message = ''

        state.isAdmin = action.payload.isAdmin
        state.isModerator = action.payload.isModerator

      })
      .addCase(login.rejected, (state, action) => {
        state.isLoginLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      //-----------------------------------------------------
      .addCase(loginOrganization.pending, (state) => {
        state.isLoginLoading = true
      })
      .addCase(loginOrganization.fulfilled, (state, action) => {
        state.isLoginLoading = false
        state.isloginSuccess = true
        state.user = action.payload
        state.isError = false
        state.message = ''
      })
      .addCase(loginOrganization.rejected, (state, action) => {
        state.isLoginLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      //-----------------------------------------------------
      .addCase(logout.fulfilled, (state) => {
        state.user = null
      })
            //-----------------------------------------------------
      .addCase(logoutUser.fulfilled, (state) => {
        state.user = null
      })
      //-----------------------------------------------------
      .addCase(registerChurch.pending, (state) => {
        state.isLoading = true
      })
      .addCase(registerChurch.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isRegisterSuccessChurch = true
        
        localStorage.setItem('loginType', 'church')


      })
      .addCase(registerChurch.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      //-----------------------------------------------------
      .addCase(verifyUsernameAvailable.pending, (state) => {
        state.isAvailableLoading = true
        state.isAvailableLoadingType = 'username'
      })
      .addCase(verifyUsernameAvailable.fulfilled, (state, action) => {
        state.isAvailableLoading = false
        state.isAvailableSuccess = true
        state.isAvailableLoadingType = ''
        state.isAvailableError = false
        state.isUsernameAvailable = action.payload.isAvailable
      })
      .addCase(verifyUsernameAvailable.rejected, (state, action) => {
        state.isAvailableLoading = false
        state.isAvailableError = true
        state.isAvailableMessage = action.payload
        state.isUsernameAvailable = false
      })
      //-----------------------------------------------------
      .addCase(verifyEmailAvailable.pending, (state) => {
        state.isAvailableLoading = true
        state.isAvailableLoadingType = 'email'
      })  
      .addCase(verifyEmailAvailable.fulfilled, (state, action) => {
        state.isAvailableLoading = false
        state.isAvailableLoadingType = ''
        state.isAvailableSuccess = true
        state.isAvailableError = false
        state.isEmailAvailable = action.payload.isAvailable
      })
      .addCase(verifyEmailAvailable.rejected, (state, action) => {
        state.isAvailableLoading = false
        state.isAvailableError = true 
        state.isAvailableMessage = action.payload
        state.isEmailAvailable = false
      })
      //-----------------------------------------------------
      .addCase(sendVerificationEmailCode.pending, (state) => {
        state.isEmailVerificationLoading = true
      })
      .addCase(sendVerificationEmailCode.fulfilled, (state, action) => {
        state.isEmailVerificationLoading = false
        state.isEmailVerificationSent = true
      })
      .addCase(sendVerificationEmailCode.rejected, (state, action) => {
        state.isEmailVerificationLoading = false
        state.isAvailableMessage = action.payload
        state.emailVerified = false
      })
      //-----------------------------------------------------
      .addCase(verifyEmailCode.pending, (state) => {
        state.isEmailCodeVerificationLoading = true
        state.isEmailCodeVerificationError = false
        state.isEmailCodeVerificationSuccess = false

      })
      .addCase(verifyEmailCode.fulfilled, (state, action) => {
        state.isEmailCodeVerificationLoading = false
        state.isEmailCodeVerificationSuccess = true
        state.isEmailCodeVerificationError = false
        state.codeVerified = true
      })
      .addCase(verifyEmailCode.rejected, (state, action) => {
        state.isEmailCodeVerificationLoading = false
        state.emailCodeMessage = action.payload
        state.isEmailCodeVerificationError = true
        state.codeVerified = false
        state.errorCount = state.errorCount + 1
      })   
      //-----------------------------------------------------
      .addCase(forgotPassword.pending, (state) => {
        state.isForgotPasswordLoading = true
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isForgotPasswordLoading = false
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isForgotPasswordLoading = false
      })   
      //-----------------------------------------------------
      .addCase(getUsZipCodeData.pending, (state) => {
        state.isZipCodeLoading = true
        // state.zipCodeData = null
      })
      .addCase(getUsZipCodeData.fulfilled, (state, action) => {
        state.isZipCodeLoading = false
        state.isZipCodeSuccess = true 
        state.zipCodeData = action.payload.zip
      })
      .addCase(getUsZipCodeData.rejected, (state, action) => {
        state.isZipCodeLoading = false
        state.isZipCodeError = true
        state.zipCodeMessage = action.payload
      })
      //-----------------------------------------------------
      .addCase(updateMyMarketLocation.pending, (state) => {
        state.marketLocationLoading = true
      })
      .addCase(updateMyMarketLocation.fulfilled, (state, action) => {
        state.marketLocationLoading = false
        state.marketLocationSuccess = true
        state.user.marketLocation = action.payload.marketLocation

        let user = JSON.parse(localStorage.getItem('user'))
        user.marketLocation = action.payload.marketLocation

        localStorage.setItem('user', JSON.stringify(user))

      })
      .addCase(updateMyMarketLocation.rejected, (state, action) => {
        state.marketLocationLoading = false
        state.marketLocationError = true
        state.marketLocationMessage = action.payload 
      })
      //-----------------------------------------------------
      .addCase(updatePreferredLanguage.pending, (state) => {
        state.updatePreferredLanguageLoading = true
      })
      .addCase(updatePreferredLanguage.fulfilled, (state, action) => {
        state.updatePreferredLanguageLoading = false
        state.updatePreferredLanguageSuccess = true

        state.user.preferredLanguage = action.payload.preferredLanguage
        let user = JSON.parse(localStorage.getItem('user'))
        user.preferredLanguage = action.payload.preferredLanguage
        localStorage.setItem('user', JSON.stringify(user))
        // refresh window
        // window.location.reload()
      })
      .addCase(updatePreferredLanguage.rejected, (state, action) => {
        state.updatePreferredLanguageLoading = false
        state.updatePreferredLanguageError = true
        state.updatePreferredLanguageMessage = action.payload 
      })
  },
})

export const { 
  resetUser, 
  resetEverythingButUser,
  resetUserForLogout,
  setUserType, 
  setViewEmailConfirmModal, 
  setCloseEmailConfirmModal, 
  resetRegisterSuccess,
  setViewForgotPassword,
  closeForgotPassword,
  setForgotPasswordUserType,
  setRegisterModalData,
  resetAuth,
  toggleAcceptTerms,
  setErrorField,
  setUpdatedImage,
  setPrivilege,
  updateUser,
  setRegistrationType,
  setViewHelpSidePanel,
  setAdmin,
  setModerator,
  setViewPrefferedLanguageModal
} = authSlice.actions
export default authSlice.reducer
