import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  postingTo: '',
}

export const selectPostingToSlice = createSlice({
  name: 'postingTo',
  initialState,
  reducers: {
    selectTo: (state, action) => {
      state.postingTo = action.payload
    },
    selectToReset: (state) => initialState,
  },
})

export const { selectTo } = selectPostingToSlice.actions

export default selectPostingToSlice.reducer