import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import CreateAncmtModal from 'components/announcements/CreateAncmtModal'
import { setViewCreateAncmtModal } from 'features/anouncements/announcementSlice'
import AnnouncementFeed from 'components/announcements/AnnouncementFeed'
import CreateButton from 'components/buttons/CreateButton'
import { ComponentPageWrapper } from 'pages/pages.styled'
import { MobilePageTitle } from 'components/main.styled'
import { language } from 'app/language'

export default function Anouncements() {
    const { user, admin } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const { showCreateAnnouncementModal } = useSelector((state) => state.announcement)
    const { profileChurch } = useSelector((state) => state.profileChurch) 

    const  handleCreateAnouncement = () => {
        dispatch(setViewCreateAncmtModal())
    }


  return (
    <ComponentPageWrapper>

        <MobilePageTitle>
            {language.announcements[user.preferredLanguage]}
        </MobilePageTitle>
        { (user.isOrganization || admin[profileChurch._id]) &&  ( 
            
                <TopWrap>
                    <CreateButton 
                        handler={handleCreateAnouncement} 
                        buttonText={language.createAnnouncement[user.preferredLanguage]}
                        />
                </TopWrap>

            )
        }
        { showCreateAnnouncementModal && 
            (
                <CreateAncmtModal />
            )
        }
        <AnnouncementFeed />
    </ComponentPageWrapper>
  )
}


const TopWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    svg{
        font-size: 25px;
        color: var(--lightThemeFontColor);
    }
`   