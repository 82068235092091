import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const API_URL = '/api/singlePost/getComments'
const API_URL2 = '/api/singlePost/setComment'
const API_URL3 = '/api/singlePost/deleteComment'

//for sending the token
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }

//auth required
const getComments = async (postData, token) => {
  const response = await axios.post(URL+API_URL, postData, config(token))
  return response.data
}

//auth required
const setComment = async (commentData, token) => {
  const response = await axios.post(URL+API_URL2, commentData, config(token))
  return response.data
}

//auth required
const deleteComment = async (postData, token) => {
  const response = await axios.post(URL+API_URL3, postData, config(token))
  return response.data
}

const setCommentReply = async (data, token) => {
  const response = await axios.post(URL+'/api/singlePost/setCommentReply', data, config(token))
  return response.data
}

const deleteCommentReply = async (data, token) => {
  const response = await axios.post(URL+'/api/singlePost/deleteCommentReply', data, config(token))
  return response.data
}

const getCommentReplies = async (data, token) => {
  const response = await axios.post(URL+'/api/singlePost/getCommentReplies', data, config(token))
  return response.data
}

const singlePostCommentService = { 
  getComments, 
  setComment, 
  deleteComment, 
  setCommentReply,
  deleteCommentReply,
  getCommentReplies,
}
export default singlePostCommentService
