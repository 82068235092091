import YouTubeIcon from '@mui/icons-material/YouTube'; 
// import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import ComputerIcon from '@mui/icons-material/Computer';
import LanguageIcon from '@mui/icons-material/Language';

export const ICONS = {
    'youtube': <YouTubeIcon/>,
    'facebook': <FacebookIcon/>,
    // 'twitter': <XIcon/>,
    'computer': <ComputerIcon/>,
    'url': <LanguageIcon/>,
}