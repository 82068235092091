import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux' 
import styled from 'styled-components'
import useGetWindowSize from 'hooks/useGetWindowSize'
import { baseUrl } from 'features/constants'
import HomeComponentSmall from './HomeComponentSmall'
import { HomeContainer, Wrap3, LpButton  } from './landingPage.styled'
import { landingPageStore } from 'app/landingPageStore' 
import Loader from 'loaders/Loader'
import { pastravzta } from 'features/auth/authSlice'

function HomeComponent() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth)
    const {  isLoading, isError, message } = useSelector((state) => state.posts) 
    const [imagePath, setImagePath] = useState('prys/lp-church.jpg') 
    const [screenWidth, screenHeight] = useGetWindowSize()
    const { userType } = landingPageStore() 

    let userName;
    if(user){
      userName = user.name
    }
    const [currentDetail, setCurrentDetail] = useState()
  
    const change = () => { 
      const randomIndex = shareDetailsArray[(Math.floor(Math.random() * shareDetailsArray.length))]
      setCurrentDetail(randomIndex)
    }
  
    useEffect(() => {

      console.log('pastravzta')

      dispatch(pastravzta())

    }, [])

    useEffect(() => { 
      const interval = setInterval(change, 1050)
      return () => clearInterval(interval)
    }, [])
   
    useEffect(() => {
      if (isError) {
        console.log(message)
      }
      if (user) {
        navigate('/home')
      }

      if(screenWidth > 1850){
        setImagePath('lp-church-big.jpg')
      }
      else if(screenWidth < 1850 && screenWidth > 1268){
        setImagePath('lp-church.jpg')
      } 
      else if(screenWidth < 1268 && screenWidth > 768){
        setImagePath('lp-church-small.jpg')
      }

    }, [user, navigate, isError, message, screenWidth])
  

    if (isLoading) {
      return <Loader />
    }
  
    const shareDetailsArray = [
      'Prayer Requests', 'Monetary Giving', 'Testimonies', 'Praises', 'Events & Anouncements', 'Church Directory'
    ]
  
    if(screenWidth < 768){
      return (
        <HomeComponentSmall />
      ) 
    }

  return (
    <HomeContainer>
      <DesktopLeft>
        <LeftWrap> 
          <h1>  <LeftHeader>Connect with your church family!</LeftHeader>  </h1>
          
          <Details> 
            A digital platform designed to enhance church community, connection and fellowship.
          </Details>

          <LeftDetails>{currentDetail}</LeftDetails>

          <Wrap3>  
            <LpButton
              btnType='join'
              onClick={() => navigate('/register')}
              >
                Join Praysely
            </LpButton> 

            { userType === 'church' && ( 
              <LpButton 
                btnType='cal'
                onClick={() => window.open("https://calendly.com/praysely")}
                >
                  Book a demo
              </LpButton>
            )} 

          </Wrap3> 
        </LeftWrap> 

      </DesktopLeft> 
      <DesktopRight>
          <img style={{width: "100%", height: "100%"}} src={`${baseUrl}/prys/${imagePath}`} alt={'Praysely'} />  
      </DesktopRight>
  </HomeContainer>
  )
}

export default HomeComponent

const DesktopLeft = styled.div`
  height: 100%;
  width: 45%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  @media (max-width: 768px) {
    width: 90%;
  }
`

const DesktopRight = styled.div`
  width: 55%;
  height: 100%;
  @media (max-width: 768px) {
    display: none;
    width: 90%;
  }
`

const Details = styled.div`
    color: #535353;
    font-size: 19px;
    margin-top: 30px;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 87%;
    line-height: 1.5; 
  //media queries
  @media (max-width: 1750px) { 
    font-size: 12px; 
  }
  @media (max-width: 1208px) { 
    width: 95%;
  }
  @media (max-width: 768px) {

  }

`

const LeftHeader = styled.div`

  font-size: 30px;
  font-weight: 600; 
  margin-left: 0px;
  color: #535353;
  text-align: left;


  @media (max-width: 1750px) {
    font-size: 25px;
    margin-left: 0px;
    margin-top: 10px;
  }


`
const LeftWrap = styled.div` 

  padding: 100px;

  h1{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    width: 100%;
    text-align: justify;
    font-size: 40px;
    font-weight: 900;
  }
  //media queries
  @media (max-width: 1750px) {

    padding: 50px;

    h1{ 
      flex-direction: column;
    }
  
  }
  @media (max-width: 1208px) {
    padding: 20px;
  }

`

const LeftDetails = styled.div`
  margin-top: 40px;
  font-size: 15px;
  padding: 20px;
  text-align: left;
  border-radius: 10px;
  background-color: white;
  box-shadow: 3px 3px 7px rgb(179, 179, 179);
`

