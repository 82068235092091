import React, {useState, useEffect, useRef} from "react";

export default function useKeypress(targetKey, handler) {

    const handlerRef = useRef(handler);


    useEffect(() => {
        handlerRef.current = handler;
    });

    useEffect(() => {
        function eventHandler(event) {
            if (event.key === targetKey) {
                handlerRef.current(event);
            }
        }

        document.addEventListener("keydown", eventHandler);

        return () => {
            window.removeEventListener("keydown", eventHandler);
        };
    }, [targetKey]);



}