import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Conversations from '../../components/messenger/Conversations'
import MessagePanel from '../../components/messenger/MessagePanel'
import styled from 'styled-components'
import UserExtras from 'components/userProfile/UserExtras'
import ChurchExtras from 'components/userProfile/ChurchExtras'

function SelectedConversation() {
  const navigate = useNavigate()
  const { user } = useSelector( state => state.auth)

  useEffect(() => {
    if(!user){ navigate('/login') }

 
  }, [navigate, user])



  return (
    <MessageContainer>
        {
            user.isChurch || user.isOrganization ? (
                <ChurchExtras />
            )
            :
            (
                <UserExtras />
            )
        }
      <MessagesWrapSC>
        <Conversations /> {/*  this is on left side of page */}
      </MessagesWrapSC>

      {/* Mobile view */}
      

      <MessagePanel />
       

    </MessageContainer>
  )
}

export default SelectedConversation

const MessageContainer = styled.div`
    display: flex;
    width: 1300px;
    flex-direction: row;
    justify-content: flex-start; 
    margin: 0 auto;
    @media (max-width: 868px) {
      width: 100%;
    }

`

const MessagesWrapSC = styled.div`
  
  @media (max-width: 768px) {
    display: none;

    }
`





//  learn about useMatch on react-router-dom to be able to highlight 
//background of conversationlistItmes when url param matches the conversation ID