import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import passResetService from './passResetService'

const initialState = {
  isPassResetSuccess: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  isTokenValid: false,
  passwordResetSuccess: false,
  message: '',

  isTokenCheckSuccess: false, 
  isTokenCheckError: false,
  isTokenCheckLoading: false,
  tokenCheckMessage: '',

  isSubmitLoading: false,
  isSubmitSuccess: false,
  isSubmitError: false,
  submitmessage: '',

}


// Check if the password reset token is valid
export const tokenCheck = createAsyncThunk(
  'password/tokenCheck',
  async (urlData, thunkAPI) => {
    try {
      return await passResetService.tokenCheck(urlData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Check if the password reset token is valid
export const passwordUpdate = createAsyncThunk(
  'password/update',
  async (passwordData, thunkAPI) => {
    try {
      return await passResetService.passwordUpdate(passwordData)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const passResetSlice = createSlice({
  name: 'ispasswordResetSuccess',
  initialState,
  reducers: {
    passwordResetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(tokenCheck.pending, (state) => {
        state.isTokenCheckLoading = true
      })
      .addCase(tokenCheck.fulfilled, (state, action) => {
        state.isTokenCheckLoading = false
        state.isTokenCheckSuccess = true
      })
      .addCase(tokenCheck.rejected, (state, action) => {
        state.isTokenCheckLoading = false
        state.isTokenCheckError = true
        state.tokenCheckMessage = action.payload
        state.isTokenValid = false
      })
      //------------------------------------------------------------
      .addCase(passwordUpdate.pending, (state) => {
        state.isSubmitLoading = true
      })
      .addCase(passwordUpdate.fulfilled, (state, action) => {
        state.isSubmitLoading = false
        state.isSubmitSuccess = true
        state.passwordResetSuccess = true
        state.submitmessage = 'Password successfully updated'
      })
      .addCase(passwordUpdate.rejected, (state, action) => {
        state.isSubmitLoading = false
        state.isSubmitError = true
        state.submitmessage = action.payload
      })
    //------------------------------------------------------------
  },
})

export const { passwordResetState } = passResetSlice.actions
export default passResetSlice.reducer