import { useState, useEffect } from 'react'
import { FaRegUserCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { forgotPasswordMail } from '../features/mail/mailSlice' 
import Loader from 'loaders/Loader'
//   -->>  https://blog.logrocket.com/implementing-a-secure-password-reset-in-node-js/

function ForgotPassword() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({email: '',})
    const { email } = formData
    const { user, isLoading, isError, message } = useSelector((state) => state.auth)
    const { mail, mailError,  mailSuccess, mailMessage } = useSelector((state) => state.mail)

    useEffect(() => {
        if (isError) {toast.error(message)}
        if (user) {navigate('/')}
        if(mailError){ toast.error(mailMessage)}
        if(mailSuccess){ toast.success(mailMessage) }
        // dispatch(reset())
    }, [user, isError, message, navigate, dispatch, mailError, mailSuccess, mailMessage, mail])

    const onChange = (e) => {
        setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
        }))
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const userData = { email }
        dispatch(forgotPasswordMail(userData))
        }

    if (isLoading) {return <Loader />}

    return (
        <>
            <section className='heading'>
                <h1> <FaRegUserCircle /> Forgot Password </h1>
            </section>


            <section className='form loginForm'>
                <form onSubmit={onSubmit}>
                    <div className='form-group'>
                        <input
                        type='email'
                        className='form-control'
                        id='email'
                        name='email'
                        value={email}
                        placeholder='Enter your email'
                        onChange={onChange}
                        />
                    </div>

                    <div className='form-group'>
                        <button type='submit' className='btn btn-block'> Submit </button>
                    </div>
                </form>
            </section>
        </>
    )
    }

export default ForgotPassword
