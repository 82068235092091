import React, { useEffect, useRef, useState, Suspense } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { setCollectionFilesFromLocalStorage, getCollectionFiles, resetUploadState, setViewFileModal } from 'features/files/fileSlice'
import FolderItem from 'components/files/FolderItem'
import Folder from './Folder'
import CreateButton from 'components/buttons/CreateButton' 
import { FilesFeedContainer, FolderFeedContainer } from './files.styled'
import { Modal } from '@mui/material' 
import { BsFolderPlus } from "react-icons/bs";
import { VscNewFile } from "react-icons/vsc";
import FileItem from './fileItem/FileItem'
import NewFolderModal from './modal/NewFolderModal' 
import useToggleDropdown from 'hooks/useToggleDropdown'
import DropDownContainer from 'components/dropdown/DropDownContainer' 
import MoveFileModal from './modal/MoveFileModal'
import { setViewConfirmModal } from 'features/modal/modalSlice'
import ConfirmModal from 'components/modals/ConfirmModal'
import NewFileModal from './modal/NewFileModal' 
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';  
import { SortContainerButtons } from './files.styled'
import { IoIosArrowRoundDown, IoIosArrowRoundUp  } from "react-icons/io";
import useGetWindowSize from 'hooks/useGetWindowSize';
import RenameFileModal from './modal/RenameFileModal'
import { sorted } from './sortUtility'
import { addAc } from 'features/auth/authSlice'
import { addAcFunc } from 'funcs/ac'
import { DotEle, IconStyled } from 'components/dots/dots.styled'

export default function Files() {

    const dispatch = useDispatch() 
    const location = useLocation()
    const { 
        viewMoveFileModal, widths, fileUploadLoading, viewCreateFolderModal, 
        isDataLoadingSuccess, collectionFiles, viewAddFileModal,
        isFileDeletedSuccess, isFileDeleting, viewRenameFileModal
    } = useSelector(state => state.file)
    const { profileChurch } = useSelector(state => state.profileChurch)
    const { viewConfirmModal, confirmModalData } = useSelector((state) => state.modal)

    const handleSort = (field) => {
        setSortState(sortState === 'ascending' ? 'descending' : 'ascending')
        setSField(field)
    }

    const [width, height] = useGetWindowSize()

    const [sortState, setSortState] = useState("ascending");
    const [sField, setSField] = useState("title")
    const folder = new URLSearchParams(location.search).get('folder') ? new URLSearchParams(location.search).get('folder') : 'none'
    const ref = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref)
    const handleDropDown = () => { setIsDropped.toggle(ref) }

    // const [fArray, setFArray] = useState([...collectionFiles.files])

    useEffect(() => {  

        // instead of a random number, we are going to use the church file collection id
        let ls = localStorage.getItem(`${profileChurch.fileCollection}`)
        //  here we are checking the "hash", its not a hash, its a query string
        //  if the query string is empty, we are going to fetch the files from the root folder

        // if the query string is not empty, we are going to fetch the files, in the backend 
        // we will check if the "hash" is the same. If it is we will not fetch the files again
        // if it is not the same, we will fetch the files again
        let pls = JSON.parse(ls)


        if(!ls){
            dispatch(getCollectionFiles({
                fileCollection: profileChurch.fileCollection,
                churchId: profileChurch._id,
            }))
        }else{

            // if the last update was less than 15 minutes ago we will not fetch the files again
            dispatch(setCollectionFilesFromLocalStorage(pls))
            // if(pls.lastUpdate < Date.now() - 1000 * 60 * 1){
                dispatch(getCollectionFiles({
                    hs: pls.hs,
                    hsi: pls.hsi,
                    churchId: profileChurch._id,
                    fileCollection: profileChurch.fileCollection
                }))
            // } else{
            //     console.log('Too soon, junior')
            // }
        }
    }, [])

    const handleCreateFolder = () => {
        dispatch(setViewFileModal({
            modal: 'viewCreateFolderModal',
            view: true
        }))
    }

    const handleUploadFile = () => {
        dispatch(setViewFileModal({
            modal: 'viewAddFileModal',
            view: true
        }))
    }   
    const handleResetAfterClose = () => {
        dispatch(resetUploadState())
    }
    
    useEffect(() => {

        const dis = () => dispatch(addAc({
          type: 'files', 
        }))
    
        addAcFunc('files', dis)
    
    }, [])


    if(folder !== 'none'){
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <Folder 
                    id={folder} 
                    />
            </Suspense>
        )
    }
    return (
        <TEMPCONTAINER>
            <div
                ref={ref}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    width: '100%',
                    marginBottom: '10px', 
                    position: 'relative', 
                    backgroundColor: 'transparent'
                }}
                >
                <MediaMenu
                    ref={ref}
                    onClick={handleDropDown}
                    >
                    <MoreHorizIcon />
                </MediaMenu>                       

                {
                    isDropped && (
                        <DropDownContainer
                            top='55px'
                            right='15px'
                            >
                            <DotEle>
                                <IconStyled>
                                    <VscNewFile style={{ marginRight: '15px'}} />
                                </IconStyled>
                                <span onClick={handleUploadFile}>Upload File</span>
                            </DotEle>                    
                            <DotEle>
                                <IconStyled>
                                    <BsFolderPlus style={{ marginRight: '15px'}} />
                                </IconStyled>
                                <span onClick={handleCreateFolder}>Create Folder</span>
                            </DotEle>
                        </DropDownContainer>
                    )
                }
            </div>


            <FeedContainerXZ>
                <FolderFeedContainer>
                    
                    { isDataLoadingSuccess &&  collectionFiles.folders.map((folder, index) => (
                        <FolderItem 
                            key={folder._id} 
                            id={folder._id}
                            index={index}
                            title={folder.title} 
                            />   
                    ))}
                </FolderFeedContainer>

                <FilesFeedContainer style={{ marginTop: '20px' }} >

                <SortContainerButtons>
                    <button
                        style={{ width: `${widths.title}px` }}
                        onClick={() => handleSort('title')}
                        >
                        Name 
                        {
                            sField === 'title' && (
                                sortState === 'ascending' ? <IoIosArrowRoundDown/> : <IoIosArrowRoundUp/>
                            )
                        }

                    </button>
                    <button
                        style={{ width: `${widths.date}px`, display: width < 768 ? 'none' : 'flex'}}
                        onClick={() =>  handleSort('createdAt')}
                        >
                        Date
                        {
                            sField === 'createdAt' && (
                                sortState === 'ascending' ? <IoIosArrowRoundDown/> : <IoIosArrowRoundUp/>
                            )
                        }
                    </button> 
                    <button
                        style={{ width: `${widths.type}px`,display: width < 768 ? 'none' : 'flex' }}
                        onClick={() =>  handleSort('fileType')}
                        >
                        Type
                        {
                            sField === 'fileType' && (
                                sortState === 'ascending' ? <IoIosArrowRoundDown/> : <IoIosArrowRoundUp/>
                            )
                        }
                    </button>
                </SortContainerButtons>
                    
                    { 
                    
                        isDataLoadingSuccess &&  
                            
                            [...collectionFiles.files]
                                .sort(sorted(sField, sortState))
                                .map((file, index) => ( 
                                    <FileItem 
                                        key={file._id}
                                        index={index}
                                        id={file._id}
                                        file={file} 
                                        />   
                                ))
                    }
                </FilesFeedContainer>
            </FeedContainerXZ>



            <Modal
                open={viewAddFileModal}
                onClose={() => dispatch(setViewFileModal({
                    modal: 'viewAddFileModal',
                    view: false
                }))}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '100%', 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column', 
                            position: 'relative',
                            animation: 'animate 0.3s',
                            padding: '10px 20px'
                        }}
                        >
                          <NewFileModal />  
                    </div> 
            </Modal>
            <Modal
                open={viewCreateFolderModal}
                onClose={() => dispatch(setViewFileModal({
                    modal: 'viewCreateFolderModal',
                    view: false
                }))}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '100%', 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column', 
                            position: 'relative',
                            animation: 'animate 0.3s',
                            padding: '10px 20px'
                        }}
                        >
                          <NewFolderModal />  
                    </div> 
            </Modal>
            <Modal
                open={viewRenameFileModal}
                onClose={() => dispatch(setViewFileModal({
                    modal: 'viewRenameFileModal',
                    view: false
                }))}
                >
                <div
                    style={{
                        width: '100%',
                        height: '100%', 
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column', 
                        position: 'relative',
                        animation: 'animate 0.3s',
                        padding: '10px 20px'
                    }}
                    >
                        <RenameFileModal />
                </div>
            </Modal>
            <Modal
                open={viewMoveFileModal}
                onClose={() => dispatch(setViewFileModal({
                    modal: 'viewMoveFileModal',
                    view: false
                }))}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '100%', 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column', 
                            position: 'relative',
                            animation: 'animate 0.3s',
                            padding: '10px 20px'
                        }}
                        >
                          <MoveFileModal />  
                    </div> 
            </Modal>
            <Modal
                open={viewConfirmModal}
                onClose={() => dispatch(setViewConfirmModal(false))}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh',
                        width: '100vw',
                    }}
                    >
                    <ConfirmModal 
                        text={confirmModalData.text}
                        extraInfo={confirmModalData.extraInfo}
                        handler={confirmModalData.handler}
                        confirmBtnBackgroundHover={'#ff0000'}
                        conditionToCloseModal={isFileDeletedSuccess}
                        doSomethingAfterClose={true}
                        resetAfterClose={handleResetAfterClose}
                        loadingState={isFileDeleting}
                        />
                </div>
            </Modal>
        </TEMPCONTAINER>
    )
}

const MediaMenu = styled.div`
    position: absolute; 
    top: 9px;
    right: 10px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
        background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    svg{ 
        color: var(--lightThemeFontColor); 
    }

    &:hover{
        transition: all 0.3s; 
        background-color: #dfdfdf;
    }

`

const FeedContainerXZ = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column  ;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: scroll;

    /* remove scrollbar */
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 20px;
        border: 3px solid #f5f5f5;
    }
`



const TEMPCONTAINER = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 15px;
    border: 1px solid #e0e0e0; 
    /* overflow-x: hidden; */
`