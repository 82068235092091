import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUser } from '../../features/auth/authSlice' 
import SpinnerSmall from '../SpinnerSmall'; 
import { TopHeaderSettings, CenterSettingsContainer } from './settings.styled';
import ChurchUsernameChange from './profileSettings/ChurchUsernameChange';
import styled from 'styled-components';
import { SettingsInputContainer, SettingsSubmitButton, SettingsBody } from './settings.styled';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { toast } from 'react-toastify';
import { updateUsername } from 'features/settings/settingSlice';
import { useNavigate } from 'react-router-dom';


function UpdateUserName(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const { isUpdateUsernameLoading, isUpdateUsernameSuccess, newUsername} = useSelector((state) => state.settings)
  const [ viewExample, setViewExample ] = useState(false)
  const [ viewHelp, setViewHelp ] = useState(false) 
  const [inputValue, setInputValue] = useState('')

  const handleChange = (e) => {
    // remove any spaces
    if(e.target.value.includes(' ')){
      return setInputValue(e.target.value.replace(/\s/g, ''))
    }else{
      return setInputValue(e.target.value)
    }
  }

  useEffect(() => {
    if(!user.isOrganization){
      navigate(`/settings`)
    }
  }, [user])

  const  handleSubmit = async () => {
    if(!inputValue){ return toast.error('Please enter a username') }
    if(inputValue.length < 3){ return toast.error('Username must be at least 3 characters long') }
    if(inputValue.length >= 19){ return toast.error('Username must be less than 20 characters long') }

      dispatch(updateUsername({
        username: inputValue
      }))
      setInputValue('')
  
  }

  useEffect(() => {
    if(isUpdateUsernameSuccess && newUsername){
      dispatch(updateUser({
        type: 'user',
        value: newUsername
      }))
    }
  }, [isUpdateUsernameSuccess, newUsername])


  return (

    <CenterSettingsContainer>
        <TopHeaderSettings> Update User Name </TopHeaderSettings>

        <SettingsBody>


          <SettingsInputContainer 
            > 
            <input
                maxLength={20}
                value={inputValue}
                onChange={handleChange}
                placeholder='Enter a new user name'
                />
            <SettingsSubmitButton

              disabled={isUpdateUsernameLoading}
              onClick={handleSubmit}
              >
                {isUpdateUsernameLoading ? <SpinnerSmall /> : 'Update'}
            </SettingsSubmitButton>
          </SettingsInputContainer>          


          <ViewExample>
            <HelpOutlineIcon 
              onClick={() => setViewHelp(!viewHelp)}
              />

            { viewHelp && 
              (
                <>
                  <h5>Why does your church need a user name ?</h5>
                  <p>
                    Your unique user name is how you are found inside and outside of Praysely. When your members login, 
                    knowledge of this username ({user.name}) is not necessary to them, but it will be visible URL section of the browser.
                    If non members (logged in users to Praysely but outside your organization) enter your username in the search bar, they will be able to find your church and see what you have allowed non members to see. 
                    They will also be able to send you a membership request.
                  </p>             
                </>
              )
            }

            <span onClick={() => setViewExample(!viewExample)}> View Example </span>
          </ViewExample>

          {
            viewExample && (
              <ChurchUsernameChange />
            )
          }
        
        </SettingsBody>

    </CenterSettingsContainer>

  )
}

export default UpdateUserName

const ViewExample = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  color: var(--lightThemeFontColor);

  p{
    text-align: left;
    line-height: normal;
    font-size: 11px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  svg{
    cursor: pointer;
    margin-bottom: 11px;
    font-size: 20px;  
    &:hover{
    
      color: var(--themeColor);
    
    }
  }

  span{
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    color: var(--themeColor);
    &:hover{
      text-decoration: underline;
    }

    margin-bottom: 20px;
  }


`