import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import TimeAgo from 'timeago-react'; 
import { BsThreeDots, BsArrowLeftShort } from 'react-icons/bs'
import { removeMemberOf } from '../../../features/memberships/membershipSlice'
import { imgUrl } from 'features/constants';
import { setViewConfirmModal, setConfirmModalData } from 'features/modal/modalSlice';

function MemberOfItem( { userId, fullName, username, image } ) {
//should be paginated since churches will potentially have a lot of members
//alos, allow to search your members
    const navigate = useNavigate()
    const ref = useRef()
    const ref2 = useRef()
    const dispatch = useDispatch()
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [confirm, setConfirm] = useState(false);
    const ref3 = useRef()

    const handleRemove = () => { 
        dispatch(removeMemberOf({ churchId: userId } ))
    }
    
    const goToProfile = () => {
        navigate( `/${username}`)
    }

    const handleCancelRemove = () => {
        setConfirm(false);
        setIsDropdownOpen(false)
    }

    // useEffect(() => {
    //         const checkIfClicked = e => {if(ref3.current.contains(e.target)) {setConfirm(true)}}
    //         document.addEventListener("mousedown", checkIfClicked)
    //     return () => { document.removeEventListener("mousedown", checkIfClicked)} // Cleanup the event listener
    //   }, [confirm])


    // useEffect(() => {
    //     const checkIfClickedOutside = e => {
    //         if(isDropdownOpen && ref.current  && ( (!ref.current.contains(e.target) || !ref2.current.contains(e.target)) && !ref3.current.contains(e.target))) {
    //             setIsDropdownOpen(false)
    //         }
    //     }
    //     document.addEventListener("mousedown", checkIfClickedOutside)
    //     return () => { document.removeEventListener("mousedown", checkIfClickedOutside)} // Cleanup the event listener
    //   }, [isDropdownOpen])

      let imageToShow;
      try{ imageToShow =  `${imgUrl}/profile/${image}`}
      catch(e){  imageToShow =  `${imgUrl}/profile/${image}`}
  

    const handleLeaveChurch = () => {
        dispatch(setConfirmModalData({
            text: `Are you sure you want to leave ${fullName} ?`,
            handler: handleRemove,
            confirmBtnBackgroundHover: 'red',
        }))
    
        dispatch(setViewConfirmModal(true))
    }

    return (
        <div className='myMembersItemWrap'>
                <div className='mMIInfo'>
                    <img className='reqItemImage' src={imageToShow} alt='none' />

                    <div className='mMIName'>
                            <Link to={`/${username}`}> <div className='mMIFullName'>{fullName}</div></Link> 
                    </div>
                </div>


                <div  className='mMIDots'>
                    <div ref={ref} onClick={() => setIsDropdownOpen(prevState => !prevState)}>< BsThreeDots />   </div>
                    { isDropdownOpen && (
                            <div className='memberDotsWrap'> 
                                
                                <li ref={ref3} onClick={handleLeaveChurch} className='leaveChurch'> Leave Church  </li> 
                            </div>
                        )
                    }
                </div> 
        </div>
    )
}

export default MemberOfItem