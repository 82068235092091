import React, {useEffect} from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import CreatePollModal from 'components/polls/CreatePollModal'
import { setShowCreatePollModal, setCloseCreatePollModal } from 'features/polls/pollSlice'
import PollFeed from 'components/polls/PollFeed'
import CreateButton from 'components/buttons/CreateButton'
import { MobilePageTitle } from 'components/main.styled'
import { Modal } from '@mui/material'
import { MUIModalWrap } from 'components/modal/modal.styled'
import { addAc } from 'features/auth/authSlice'
import { addAcFunc } from 'funcs/ac'
import { pollLanguage } from 'components/polls/pollLanguage'

export default function Polls() {
    const { user, admin} = useSelector((state) => state.auth)
    const { showCreatePollModal } = useSelector((state) => state.poll)
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const dispatch = useDispatch()

    const handleCreatePoll = () => {
        dispatch(setShowCreatePollModal())
    }

    const handleClose = () => {
        dispatch(setShowCreatePollModal())
    }


    useEffect(() => {

        const dis = () => dispatch(addAc({
          type: 'polls', 
        }))
    
        addAcFunc('polls', dis)
    
      }, [])

  return (
    <PollsContainer>
        <MobilePageTitle>
            {
                pollLanguage.polls[user.preferredLanguage]
            }
        </MobilePageTitle>

        { (user.isOrganization || admin[profileChurch._id] ) && (
            <CreateButton 
                handler={handleCreatePoll}
                buttonText={pollLanguage.createPoll[user.preferredLanguage]}
                />
        )} 
        
        <PollFeed />

        <Modal  
            open={showCreatePollModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <MUIModalWrap> 
                <CreatePollModal />
            </MUIModalWrap>
        </Modal>
    </PollsContainer>
  )
}

const PollsContainer = styled.div`
    padding: 10px;
    overflow-y: scroll;
    @media (max-width: 768px) {
        padding: 10px;
    }
`
