import React, { useEffect, useState } from 'react'
import { SettingsMainContainer, SettingsViewContainer } from '../settings.styled'
import styled from 'styled-components'
import Toggle from 'react-toggle'
import "react-toggle/style.css" // for ES6 modules
import { getSettings, setSettings, updateSettings, setTestSettings } from 'features/profileChurch/profileChurchSlice'
import { settingsStore } from 'app/settingsStore'
import { useSelector, useDispatch } from 'react-redux'
import { SettingsMainBtn, SettingsMainBtnCancel } from '../settings.styled'
import { SDesc, SettingsBtnWrap, SettingsInfo, SettingsItem, ProfileSettingsContainer, SettingsItemWrap, SettingsNameWrap, SettingsName, ToggleButtonWrap } from './profileSettings.styled'
import Loader from 'loaders/Loader'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Tooltip } from '@mui/material'
import { toast } from 'react-toastify'

export default function SettingsMenuItem({index, setting, location}) {
    const dispatch = useDispatch()  
    const { 
        features, 
    } = useSelector((state) => state.profileChurch)


    const [hovered, setHovered] = useState(null);
    const { isEdited, settingsInfo, setIsEdited } = settingsStore()
    const [ viewDetails, setViewDetails ] = useState() 
    const [ originalFeatures, setOriginalFeatures ] = useState(features)

    const handleToggleChange = (event) => {
        // dispatch(setSettings({
        //     settingType: 'features',
        //     name: event,
        //     value: !features[event]
        // }))
    }

    const handleOrderChange = (position, direction, currentLocation ) => {

        // in the main array, a maximum of 4 items are allowed, so if a user tries to move an item from the main to the extra array, it will be removed
        // from the main array and added to the extra array

        // if a user tries to move an item from the extra array to the main array and it already has 4 items, the last item in the main array will be 
        // removed and added to the extra array


        // Array.prototype.move = function(from,to){
        //     this.splice(to,0,this.splice(from,1)[0]);
        //     return this;
        //   };
          
        //   var arr = [ 'a', 'b', 'c', 'd', 'e'];
        //   arr.move(3,1);//["a", "d", "b", "c", "e"]
          
          
        //   var arr = [ 'a', 'b', 'c', 'd', 'e'];
        //   arr.move(0,2);//["b", "c", "a", "d", "e"]

        let tempChurchFeatures = {
            main: [...features.main],
            extra: [...features.extra]
        }

        console.log(tempChurchFeatures)

        if(currentLocation === 'main'){ 

            if(direction === 'up'){
                if(position === 0){
                    return
                }else{
 
                    let temp = tempChurchFeatures.main[position]
                    tempChurchFeatures.main[position] = tempChurchFeatures.main[position - 1]
                    tempChurchFeatures.main[position - 1] = temp

                }
            }else if(direction === 'down'){
 
                if(position === tempChurchFeatures.main.length - 1){

                    // remove the last item in the main array and add it to the extra array
                    let temp = tempChurchFeatures.main[tempChurchFeatures.main.length - 1]
                    tempChurchFeatures.main.splice(tempChurchFeatures.main.length - 1, 1)
                    tempChurchFeatures.extra.push(temp)

                    // move the first item in the extra array to the main array
                    let temp2 = tempChurchFeatures.extra[0]
                    tempChurchFeatures.extra.splice(0, 1)
                    tempChurchFeatures.main.push(temp2)



                }else{ 
                    let temp = tempChurchFeatures.main[position]
                    tempChurchFeatures.main[position] = tempChurchFeatures.main[position + 1]
                    tempChurchFeatures.main[position + 1] = temp
                }
            }

        }else if(currentLocation === 'extra'){
            if(direction === 'up'){
                if(position === 0){
                    
                    // remove the last item in the main array and add it to the extra array
                    let temp = tempChurchFeatures.main[tempChurchFeatures.main.length - 1] // last item in main

                    let temp2 = tempChurchFeatures.extra[position] // first item in extra

                    tempChurchFeatures.main[tempChurchFeatures.main.length - 1] = temp2
                    tempChurchFeatures.extra[position] = temp
                    
                    // tempChurchFeatures.extra[position] = tempChurchFeatures.extra[position - 1]
                    // tempChurchFeatures.extra[position - 1] = temp2


                }else{
                    let temp = tempChurchFeatures.extra[position]
                    tempChurchFeatures.extra[position] = tempChurchFeatures.extra[position - 1]
                    tempChurchFeatures.extra[position - 1] = temp
                }
            }else if(direction === 'down'){
                if(position === tempChurchFeatures.extra.length - 1){
                    return
                }else{
                    let temp = tempChurchFeatures.extra[position]
                    tempChurchFeatures.extra[position] = tempChurchFeatures.extra[position + 1]
                    tempChurchFeatures.extra[position + 1] = temp
                }
            }
        }

        dispatch(setTestSettings(tempChurchFeatures))

    }

    return (
        <SettingsItem
            onMouseEnter={() => setHovered(setting.value)}
            onMouseLeave={() => setHovered(null)}
            >
            <SettingsName>
                <SettingsNameWrap>
                    {setting.label === "profileImg" ?  "Profile Image" : setting.label.charAt(0).toUpperCase() + setting.label.slice(1)} 
                    { hovered === setting.value && (
                        <HelpOutlineIcon 
                            onClick={() => { 
                                if(viewDetails !== setting.value ){ 
                                    setViewDetails(setting.value) 
                                }else{ 
                                    setViewDetails(null) 
                                }
                            }}
                            style={{ fontSize: '16px', marginLeft: '15px' }} />
                    )}                                                
                </SettingsNameWrap>

                {   viewDetails === setting.value &&
                    <SettingsInfo>
                        {setting.label}. Current Status: {setting.enabled ? "Enabled" : "Disabled"}
                    </SettingsInfo> 
                }
                
        </SettingsName>

            <RightSide>
                    { hovered === setting.value && (
                        <ArrowBtns>
                            <Tooltip title="Move Up" placement="top">
                            <ArrowIcon
                                onClick={() => handleOrderChange(index, 'up', location)}
                                >
                                <ArrowUpwardIcon />
                            </ArrowIcon> 
                            </Tooltip>
                            <Tooltip title="Move Down" placement="top">
                            <ArrowIcon
                                onClick={() => handleOrderChange(index, 'down', location)}
                                > 
                                <ArrowDownwardIcon />
                            </ArrowIcon>
                            </Tooltip>
                        </ArrowBtns>
                    )}

                {/* <ToggleButtonWrap> 
                    <Toggle
                        defaultChecked={setting.enabled}
                        icons={{
                            checked: null,
                            unchecked: null,
                        }}
                        onChange={() => handleToggleChange(setting.label)} />  
                </ToggleButtonWrap>                                      */}
            </RightSide>
        </SettingsItem> 
    )
}

const ArrowBtns = styled.div`

    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;


`

const RightSide = styled.div`

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%; 
`

const ArrowIcon = styled.div`

    cursor: pointer;
    font-size: 17px;
    color: var(--lightThemeFontColor);
    margin-right: 5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
        background-color: white;
    }

`