import React, {useState, useEffect } from 'react'
import styled from 'styled-components'   
import { useSelector, useDispatch } from 'react-redux'
import { setFormData, setErrorField } from 'features/givingCampaigns/givingCampaignSlice'
import { GiveForm, StyledInput, InputIconWrap } from '../../giving.styled'

export default function Step1Description() {

    const dispatch = useDispatch()

    const { title, description, errorField } = useSelector((state) => state.givingCampaign)

    const handleDescriptionChange = (e) => {
        if(errorField['description']) {
            dispatch(setErrorField({
                key: 'description',
                value: false
            }))
        }
        dispatch(setFormData({ key: 'description', value: e.target.value }))
    }

    const handleTitleChange = (e) => { 

        if(errorField['title']){
            dispatch(setErrorField({
                key: 'title',
                value: false
            }))
        }
        dispatch(setFormData({ key: 'title', value: e.target.value }))
    }


    return (
        <GiveForm>
            <StyledInput>
                <label>Title</label>
                <InputIconWrap
                    isError={errorField['title']}
                    >
                    <input 
                        placeholder='Title of the campaign'
                        type="text" 
                        value={title}
                        onChange={handleTitleChange}
                        />
                </InputIconWrap>
            </StyledInput>

            <StyledInput>
                <label>Description</label> 
                <InputIconWrap
                    isError={errorField['description']}
                    >
                    <StyledTextArea
                        style={{resize: 'none', fontFamily: 'inherit' }}
                        placeholder='Description of the campaign'
                        type="text" 
                        value={description}
                        onChange={handleDescriptionChange}
                        />
                </InputIconWrap>
            </StyledInput>
        </GiveForm> 
    )
}

const StyledTextArea = styled.textarea`
    width: 100%;
    height: 200px;
    padding: 10px;
    border: 2px solid #e5e5e5;
    border-radius: 5px;
    font-size: 14px;
    color: var(--lightThemeFontColor); 
    &:focus {  
        outline: none;
        border: 2px solid var(--themeColor);
    }
`