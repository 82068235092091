import styled from "styled-components"

export const SettingsBtnWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 60px; 
    padding-bottom: 50px;
`
export const SDesc = styled.div`
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    align-items: center;
    font-size: 12px;
    font-weight: 300;
    color: var(--dFontColor);
    font-family: 'Roboto', sans-serif;
`

export const SettingsInfo = styled.div`
    width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 300;
    color: var(--dFontColor);
    font-family: 'Roboto', sans-serif;
`

export const SettingsItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    align-items: center;
    padding : 20px;  
    height: fit-content;

    &:hover{
        background-color: #dfdfdf;
        border-radius: 10px;
    }
    @media (max-width: 768px) {
        padding: 15px;

    }
`


export const ProfileSettingsContainer = styled.div`
    width: 100%;
    padding: 0px; 
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    color: var(--fontColor);
    @media (max-width: 768px) {
        padding: 5px;
    }
`

export const SettingsItemWrap = styled.div`
    width: 100%;
    display: flex; 
    border-radius: 5px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
    padding-bottom: 20px;
    transition: all 0.7s ;
    padding: 20px;
    @media (max-width: 768px) {
        padding: 5px;
    }
`
export const SettingsNameWrap = styled.div`
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: var(--dFontColor);
    margin-right: 10px;
    @media (max-width: 768px) {
        font-size: 13px;
        margin-right: 5px;
    }
`

export const SettingsName = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

`
export const ToggleButtonWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`