
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetAlreadyExists, setViewStartConversationModal } from '../../../features/messenger/conversationSlice'
import styled from 'styled-components'
import AddIcon from '@mui/icons-material/Add';

function NewConversationBtn() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { alreadyExists } = useSelector(state => state.conversations)

    const handleClick = async () => {

        if(alreadyExists){
            dispatch(resetAlreadyExists())
        }

        // navigate('/messages/new', {replace: true}  )
        dispatch(setViewStartConversationModal(true))

    }

    return ( 
        <StartNewContainer onClick={handleClick}>

            <AddIcon /> 
            <p style={{marginLeft: '6px'}}>New Message</p>
        
        </StartNewContainer>
    )
}

export default NewConversationBtn

const StartNewContainer = styled.div`
    display: flex; 
    padding: 0px;
    border-radius: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: center;  
    color: rgb(70, 70, 70);
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 10px;
    padding: 10px;
    box-shadow: 0 0 10px #ccc;
    &:hover{
        transition: all 0.5s ease-in-out;
        background-color: var(--ltGreenButton);
        color: white;
        /* box-shadow: none; */
    }
    @media (max-width: 768px) {
        width: fit-content;
        font-size: smaller;
        padding: 10px 10px;
        margin-top: 0px; 
    }
`