import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  allowComments: true,
}

export const allowCommentsSlice = createSlice({
  name: 'allowComments',
  initialState,
  reducers: {
    setAllowComments: (state, action) => {
      state.allowComments = action.payload
    },
    resetAllowComments: (state) => initialState,
  },
})

export const { setAllowComments, resetAllowComments } = allowCommentsSlice.actions

export default allowCommentsSlice.reducer