import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import EditAboutModal from './EditAboutModal'
import PersonCard from './PersonCard'
import { aboutChurchStore } from 'app/aboutChurchStore'
import PersonCardModal from './PersonCardModal' 
import EditIcon from '@mui/icons-material/Edit';
import AddPersonModal from './AddPersonModal'
import TimeAgo from 'timeago-react'
import CreateButton from 'components/buttons/CreateButton'
import { MobilePageTitle } from 'components/main.styled' 
import ConfirmModal from 'components/modals/ConfirmModal'
import Modal from '@mui/material/Modal';
import { setViewConfirmModal } from 'features/modal/modalSlice' 
import { addAc } from 'features/auth/authSlice'
import { addAcFunc } from 'funcs/ac'
import { language } from 'app/language'

export default function About() {
    const dispatch = useDispatch()
    const { user, admin, moderator } = useSelector((state) => state.auth)
    const { profileChurch, editChurchPersonSuccess, viewConfirmModaliInAbout } = useSelector((state) => state.profileChurch)
    const setViewAddPersonModal = aboutChurchStore(state => state.setViewAddPersonModal)
    const viewAddPersonModal = aboutChurchStore(state => state.viewAddPersonModal)
    const setViewEditDescriptionModal = aboutChurchStore(state => state.setViewEditDescriptionModal)
    const viewEditDescriptionModal = aboutChurchStore(state => state.viewEditDescriptionModal)
    const viewPersonCardModal = aboutChurchStore(state => state.viewPersonCardModal)
    const handleAddPeople = () => { setViewAddPersonModal(true) }
    const { viewConfirmModal, confirmModalData }  = useSelector((state) => state.modal)

    const [showingMoreDescription, setShowingMoreDescription] = useState(false)

    const handleEditInfo = () => { 
        setViewEditDescriptionModal(true) 
    }
    
    useEffect(() => {  // check if person is admin
        
        if(editChurchPersonSuccess){
            setViewAddPersonModal(false)
            setViewEditDescriptionModal(false)
        }
        

    }, [editChurchPersonSuccess])

    useEffect(() => {

        const dis = () => dispatch(addAc({
          type: 'about', 
        }))
    
        addAcFunc('about', dis)
    
      }, [])

    const dateJoined = new Date(profileChurch.createdAt)

    return (
        <AboutContainer>   

            <MobilePageTitle >
                {language.about[user.preferredLanguage]}
            </MobilePageTitle>
            { (admin[profileChurch._id] || user.isOrganization) &&  
                <EditSection> 
                    <CreateButton
                        handler={handleAddPeople} 
                        buttonText={language.addPeople[user.preferredLanguage]} 
                        />
                    <CreateButton 
                        handler={handleEditInfo} 
                        buttonText={language.editDescription[user.preferredLanguage]}
                        customIcon={<EditIcon />} 
                        />
                </EditSection>
            }

            { 
                profileChurch.organization.description && profileChurch.aboutChurchSettings.description && 
                ( 
                    <AboutItem> 
                        <h2 style={{marginTop: '10px'}} >
                            {language.description[user.preferredLanguage]}
                        </h2> 

                        <DescriptionContainer
                            onClick={() => setShowingMoreDescription(!showingMoreDescription)} 
                            blurBottom={!showingMoreDescription}
                            >
                            {
                                profileChurch.organization.description.split('\n').map((item, i) => {
                                    return <section key={i}>{item}</section>;
                                })
                            }

                        </DescriptionContainer> 
                        {
                            showingMoreDescription ? (
                                <p onClick={() => setShowingMoreDescription(false)}>
                                    {language.showLess[user.preferredLanguage]}
                                </p>
                            )
                            :
                            (
                                profileChurch.organization.description.length > 500 && (
                                    <p onClick={() => setShowingMoreDescription(true)}>
                                        {language.showMore[user.preferredLanguage]}
                                    </p>
                                )
                            )
                        }

                    </AboutItem> 
                )
            }
            { 
                profileChurch.organization.details && profileChurch.aboutChurchSettings.details &&
                ( 
                    <AboutItem> 
                        <h2>
                            {language.details[user.preferredLanguage]}
                        </h2> 
                        <span>
                            {language.details[user.preferredLanguage]}: 
                            : {profileChurch.organization.locationCity}</span> 
                        <span>
                            {language.dateJoined[user.preferredLanguage]}:
                            :  <p style={{marginLeft: '5px'}}><TimeAgo datetime={dateJoined} /></p>  </span>
                        <span>
                            {language.members[user.preferredLanguage]}:
                            : <p style={{marginLeft: '5px'}}>{profileChurch.members.length}</p></span>
                    </AboutItem> 
                )
            }
            { 
                // profileChurch.aboutChurchSettings.stats &&
                // ( <AboutItem> <h2>Stats</h2> <span>here are some important stats </span> </AboutItem> )
            }
            { 
                profileChurch.aboutChurchSettings.people &&
                (
                    <AboutItem>
                        <h2>
                            {language.people[user.preferredLanguage]}
                        </h2>
                        <PeopleWrap>
                        {
                            profileChurch.organization.people && profileChurch.organization.people.length > 0 ? ( 
                                profileChurch.organization.people.map((person, index) => (
                                        <PersonCard 
                                            index={index}
                                            key={index} 
                                            data={person} 
                                            />
                                    )
                                )
                            )
                            :
                            (
                                <span>...</span>
                            )
                        }
                        </PeopleWrap>
                    </AboutItem>
                )
            }  
            {
                viewAddPersonModal && <AddPersonModal />
            }
            {
                viewPersonCardModal && <PersonCardModal />
            }
            {
                viewEditDescriptionModal && <EditAboutModal />
            }

        </AboutContainer>
    )
}

const AboutContainer = styled.div`
    width: 100%;
    height: 100%; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    color: var(--fontColorDarkGrey);
    overflow-y: scroll;
    @media (max-width: 768px) {
        padding: 10px;
    }
`
const AboutItem = styled.div`
    margin-top: 20px;
    width: 100%;
    height: fit-content; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
    color: var(--lightThemeFontColor); 


    span{
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: center;
        text-align: left;
        color: var(--lightThemeFontColor);
        cursor: default;
    }
    h2{
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 15px;
        color: var(--lightThemeFontColor);
    }
    @media (max-width: 768px) {
        padding: 0px;
        margin-top: 0px;;
    }

    p{
        color: var(--lightThemeFontColor);
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
        width: 100%;
        margin-bottom: 0px;
        text-align: right;
        &:hover{
            text-decoration: underline;
        
        }
    }

`


const EditSection = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0px;
`
const PeopleWrap = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-left: 0px;
    margin-bottom: 10px;
    @media (max-width: 768px) {
        padding: 10px 50px;
        align-items: center;
        justify-content: center;
    }

`

const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
    text-align: left;
    color: var(--lightThemeFontColor);
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0px 0px 5px #ccc;
    height: ${props => !props.blurBottom ? 'fit-content' : '250px'};
    overflow-y: hidden;
    /* mask-image: linear-gradient(to bottom, black 50%, transparent 100%); */
    mask-image: ${props => props.blurBottom ? 'linear-gradient(to bottom, black 50%, transparent 100%)' : 'none'};
    transition: height 1s ease-in-out;

    section{
        margin-bottom: 6px;
    }

`