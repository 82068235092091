import React from 'react'
import styled from 'styled-components'
import { setForgotPasswordUserType, setViewForgotPassword } from '../../features/auth/authSlice'
import { useDispatch } from 'react-redux'

function ForgotPasswordLink() {
    const dispatch = useDispatch()

  return (
    <FPLink onClick={() => {
        dispatch(setForgotPasswordUserType('church'))
        dispatch(setViewForgotPassword())
    }}>
        <LText>Forgot Password</LText>
    </FPLink>
  )
}

const FPLink = styled.div`
    display: flex;
    flex-direction: row;
    justify-self: center;
    width: 80%;
`
const LText = styled.div`
    display: flex;
    width: fit-content;
    align-self: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    text-decoration: underline;
    align-self: center;
    font-size: smaller;
    color: #343434;
    &:hover{
        color: black
    }
`


export default ForgotPasswordLink