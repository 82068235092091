import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getChurchBanks, getTransactions, setView } from 'features/giving/givingSlice'  
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import useToggleDropdown from 'hooks/useToggleDropdown';
import { DotsWrap, DotEle } from 'components/dots/dots.styled';
import DeleteIcon from '@mui/icons-material/Delete';
import { CiBank } from "react-icons/ci";
import { MdOutlineDelete } from "react-icons/md";


export default function BankItem(props) {

    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { profileChurch } = useSelector((state) => state.profileChurch)

    const ref = useRef(null)

    const [isDropped, setIsDropped] = useToggleDropdown(ref)

    const handleDropDown = () => { setIsDropped.toggle(ref) }

    return (
        <BankItemContainer>

            <DotsIcon
                ref={ref}
                onClick={handleDropDown}
                >
                <MoreHorizIcon  />
            </DotsIcon>

            {
                isDropped && (
                    <DotsWrap>
                        <DotEle>
                            <CiBank style={{
                                marginRight: '10px',
                                fontSize: '20px',
                            }}/>
                            Edit Bank Info
                        </DotEle>
                        <DotEle >
                            <MdOutlineDelete style={{
                                marginRight: '10px',
                                fontSize: '20px',
                            }} />
                            Delete Bank
                        </DotEle>
                    </DotsWrap>            
                )    
            } 

            <BankName>
                {props.data.bank_name}
            </BankName>
            <AccountData>
                account number:  ***{props.data.last4}
            </AccountData> 
        </BankItemContainer>
    )
}

const DotsIcon = styled.div`

    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: var(--lightThemeFontColor);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1;
`

const BankItemContainer = styled.div`
    position: relative;
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    aspect-ratio: 5 / 1;
    background-color: white;
    box-shadow: 0px 0px 5px #ccc;
    margin-bottom: 20px; 

    @media (max-width: 760px){
        width: 95%;
    } 
`

const AccountData = styled.div`
    font-size: 15px; 
    color: var(--lightThemeFontColor);
    display: flex;
`

const BankName = styled.div`

    font-size: 15px;
    font-weight: 600; 
    color: var(--lightThemeFontColor);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
` 