import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useToggleDropdown from '../../hooks/useToggleDropdown'
import '../../css/components/header/headerProfile.css'
import { useDispatch, useSelector } from 'react-redux';
import { postReset } from '../../features/posts/postSlice';
import { logout, logoutUser, resetUserForLogout } from 'features/auth/authSlice'


import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';

function HeaderProfile({ name }) {
    const { profileName } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ref1 = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref1)
    const handleDropDown = () => { setIsDropped.toggle(ref1) }

    

    const handleLogout = () => {
        dispatch(logoutUser())
        dispatch(resetUserForLogout())
        navigate('/login')
        window.location.reload()
    }

    return (
    <div className='headerProfileContainer' >

        <div ref={ref1} onClick={handleDropDown}  className='headerProfile'>
            <div className='hpIconWrap'>       
                <PermIdentityIcon className='headerProfileIcon' /> 
                <KeyboardArrowDownIcon style={{color: 'grey' , fontSize: 'smaller'}}/> 
            </div> 

        </div>
      
                {isDropped && (
                                  <div  className='headerProfileDrop'>
                                                    <div className='headerProfileDropItems'  onClick={handleLogout}>
                                                        <LogoutIcon/> 
                                                        <span style={{paddingLeft:'15px'}} >Logout</span>
                                                    </div>
                                    {/* for now we will just allow users to logout */}
                                                 {/* <div onClick={handleNav} >
                                                    <div className='headerProfileDropItems' onClick={handleDropDown}>
                                                        <div><PermIdentityIcon/> <span style={{paddingLeft:'5px'}} >My Profile </span></div>
                                                        <RiArrowRightSLine  style={{marginLeft:'10px'}}/>
                                                    </div>
                                                </div>

                                                <Link to={'/messages'}>
                                                    <div className='headerProfileDropItems'  onClick={handleDropDown}>
                                                        <div> <FiMail/> <span style={{paddingLeft:'5px'}} >Messages</span></div>
                                                        <RiArrowRightSLine  style={{marginLeft:'10px'}}/>
                                                    </div>
                                                </Link>

                                                <Link  to={'/create-post'}>
                                                    <div className='headerProfileDropItems' onClick={handleDropDown} >
                                                        <div> <MdPostAdd/> <span style={{paddingLeft:'5px'}} >Create Post</span></div>
                                                        <RiArrowRightSLine  style={{marginLeft:'10px'}}/>
                                                    </div>
                                                </Link>

                                                <Link to={'/settings'}>
                                                    <div className='headerProfileDropItems'  onClick={handleDropDown}>
                                                        <div> <FiSettings/> <span style={{paddingLeft:'5px'}} >Settings</span></div>
                                                        <RiArrowRightSLine  style={{marginLeft:'10px'}}/>
                                                    </div>
                                                </Link> */}
                                  </div>  
                              )}

        
    </div>
  )
}

export default HeaderProfile