import GlobalBtnWrapper from 'components/buttons/GlobalBtnWrapper'
import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux' 
import { setViewAddListingModal } from 'features/marketplace/marketSlice'
import { btnHeight, btnWidth,  } from 'app/globalSettings'
import AddIcon from '@mui/icons-material/Add';

export default function CreateListingButton() {
     
    const dispatch = useDispatch()

    const handleCreateListing = () => {

        console.log('asdf')
        dispatch(setViewAddListingModal(true))

    }

    return ( 
        <GlobalBtnWrapper
            height={btnHeight}
            width={btnWidth}
            boxShadow={true}
            theme={'light'}
            >
            <BTN
                onClick={handleCreateListing}
                >
                <AddIcon />
                <span>Create Listing</span>
            </BTN>
        </GlobalBtnWrapper>
    )
}

const BTN = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 150px;
    border-radius: 10px;
    background-color: white;
    color: var(--lightThemeBtnColor);
    transition: all 0.5s ease-in-out;
    svg{
        margin-right: 5px;
    }
    &:hover{ 
        color: white;
        background-color: #00800096;
    }

`