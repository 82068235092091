import { castVote, deletePoll } from 'features/polls/pollSlice'
import HorizontalLoader from 'loaders/HorizontalLoader'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useToggleDropdown from 'hooks/useToggleDropdown'
import styled from 'styled-components'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { toast } from 'react-toastify'
import { DotEle, DotsWrap, Dots, DotsButton2 } from 'components/dots/dots.styled'
import { pollLanguage } from './pollLanguage'

export default function PollItem(props) {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { poll, isVoteLoading, isVoteSuccess, voteErrorMessage, isVoteError } = useSelector((state) => state.auth)
    const [ canViewResults, setCanViewResults ] = useState()
    const [ totalVotes, setTotalVotes ] = useState()
    const { profileChurch } = useSelector((state) => state.profileChurch)

    const ref1 = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref1)
    const handleDropDown = () => { setIsDropped.toggle(ref1) }

    useEffect(() => {
        if(user.isChurch || user.isOrganization){
            setCanViewResults(true)

            // can set here users with priviledges
            // setCanViewResults

            setTotalVotes(props.data.castVotes.length)
        }else{
            setTotalVotes(props.data.castVotes.length)

            let check = props.data.castVotes.find((vote) => vote.toString() === user._id)
            if(check){
                setCanViewResults(true)
            }
        }
    },[ props.data.castVotes, user._id, user.isChurch,user.isOrganization ])

    const handleVote = (choice) => {
        dispatch(castVote({
            index: props.index,
            pollId: props.data._id,
            choice: choice,
        }))
    } 

    const handleDelete = () => {
        if(totalVotes > 2){
            toast.error('Poll with votes can not be deleted.')
            return;
        }
        else{

            dispatch(deletePoll({ 
                churchId: profileChurch._id,
                pollId: props.data._id 
            }))
        }
    }

  return (
    <PollItemContainer>
        <PollItemQuestion>{props.data.title}</PollItemQuestion>
            
            <DotsButton2 
                onClick={handleDropDown}
                ref={ref1}
                >
                <MoreHorizIcon style={{fontSize: '18px'}}/>
            </DotsButton2>
              {isDropped && (
                <DotsWrap>
                    <DotEle onClick={handleDelete}>   
                        <DeleteOutlineIcon className='postItemDotsIcon' />  
                        {
                            pollLanguage.delete[user.preferredLanguage]
                        }
                    </DotEle>                    
                </DotsWrap>

              )} 
        
         

        {/* if I I have voted for a particular poll, I will see the results */}
        { !isVoteLoading && (canViewResults || (user.isChurch || user.isOrganization)) ? 
            (
                <ViewResultsContainer>
                    <span>
                        {
                            ` ${pollLanguage.totalVotes[user.preferredLanguage]} ${totalVotes}   `
                        } 
                    </span>
                    {
                        props.data && props.data.choices.map((choice) => (
                            <ChoiceItemWrap> 
                                <ChoiceVotedItem  >{choice.choice} </ChoiceVotedItem> 
                                <PercentTotal>
                                    <ChoiceVotedPercent votesVal={choice.votes.length}  resWidth={`${choice.votes.length/totalVotes*100}%`}>
                                        
                                        {  totalVotes ? (choice.votes.length/totalVotes*100).toFixed(1) : 0 }%
                                    
                                    </ChoiceVotedPercent>

                                    {choice.votes.find((id) => id === user._id) && (
                                        <AccountCircleIcon style={{position: 'absolute', right: '3px', color: 'white'}} />
                                    )}
                                </PercentTotal> 
                            </ChoiceItemWrap> 
                        ))
                    }
                </ViewResultsContainer> 
            ) 
            : isVoteLoading ? 
            (   
                <LoadingContainer>
                        <HorizontalLoader />
                </LoadingContainer>
            )
            :
            (
                <ChoicesContainer>
                    {
                        props.data && props.data.choices.map((choice) => ( 
                            <ChoiceItem onClick={() => handleVote(choice)}>{choice.choice}</ChoiceItem> 
                        ))
                    }
                </ChoicesContainer>
            ) 
        }
    </PollItemContainer>
  )
}

const PollItemContainer = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 20px 45px 45px 45px;
    box-shadow: 0 0 10px #ccc;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 100%;
    min-width: 400px;
    position: relative;
    color: var(--dFontColor);
    @media (max-width: 768px) {
        margin-bottom: 10px;
        min-width: unset;
        width: 100%;
    }
`

const DeleteDots = styled.div`
    
    position: absolute;
    right: 0px;
    top: 0px;

`

const PollItemQuestion = styled.div`
    margin-bottom: 30px;
    margin-top: 10px;
    
`

const ChoicesContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
`
const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
`

const ChoiceItem = styled.div`
    padding: 10px;
    background-color: #e1e1e1;
    width: 80%;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    border: 1px solid #e1e1e1;
    &:hover{
        transition: 0.6s;
        border: 1px solid green;
    }
`
const ChoiceItemWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

`

const ChoiceVotedPercent = styled.div`
    width: ${props => props.resWidth };
    background-color: ${props => props.votesVal ? ('#297bd8f7;'):('none')};
    padding: 10px;
    border-radius: ${props => props.resWidth === '100%' ? ('8px'):('8px 0px 0px 8px') };
    color: white;
`

const ChoiceVotedItem = styled.div`
    margin-top: 10px;

`

const PercentTotal = styled.div`
    width: 100%;
    background-color: #c5c5c5;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
`

const ViewResultsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--dFontColor);
`