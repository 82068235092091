
import styled from 'styled-components'
import { PiChatsFill } from "react-icons/pi";


function NoConvSelected() {
  return (
    <NoConvSelectedWrap>

        <PiChatsFill />
        <span>Select a conversation to view messages</span>

    </NoConvSelectedWrap>
  )
}

export default NoConvSelected

const NoConvSelectedWrap = styled.div`
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--lightThemeFontColor);
    svg{
        font-size: 3rem;
        margin-bottom: 20px;
    }
`