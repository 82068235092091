import { React, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getMyDirectory } from '../../features/directory/directorySlice'
import Loader from "loaders/Loader";
import MyDirectoryItem from './MyDirectoryItem'
import SettingsIcon from '@mui/icons-material/Settings'

function UpdateDirectory() { 
    const dispatch = useDispatch()
    const { myDirectory, isDirectorySuccess, isDirectoryUpdatedSuccess, isDirectoryLoading } = useSelector((state) => state.directory)
    
    // useEffect(() => { dispatch(getMyDirectory()) }, [])

    const { profile, isSuccess } = useSelector((state) => state.profile)
 
    useEffect(() => {
        // if(profile && isSuccess){ 
            dispatch(getMyDirectory())
        // }
    }, [
        // profile, isSuccess, dispatch
    ])

    useEffect(() => {
        if(isDirectoryUpdatedSuccess){
            toast.success('Directory updated!')
          }
    }, [isDirectoryUpdatedSuccess])
     
    return (
        <>
            { 
                isDirectoryLoading ?
                (
                    <Loader />
                )
                :  myDirectory ? 
                    
                     <MyDirectoryItem />
                    
                :
                (
                    <div 
                        style={{
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            height: '100%', 
                            width: '100%',
                            fontSize: '10px'
                        }}
                       >
                        <span>Directory entry not found</span>
                        <span>To create a directory entry, go to your church's directory section and click on <SettingsIcon /> </span>
                    </div>
                )
            }
        </>
    )
}

export default UpdateDirectory