import './startConversation.css'
import {  useDispatch, useSelector } from 'react-redux'
import Spinner from '../../Spinner'
import ViewAllItem from './ViewAllItem'
import { useEffect } from 'react'
import { getMemberOf } from '../../../features/memberships/membershipSlice'
import styled from 'styled-components'
import Loader from 'loaders/Loader'

function ViewAllMemberOF() {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { memberOf, isMbsLoading, isMbsSuccess, isMbsError } = useSelector(state => state.memberships2)

    useEffect(() => {
        if(user && !user.isOrganization){
            dispatch(getMemberOf())
        }
    }, [dispatch, user])


  return (
    <ViewAllContainer>

        <ViewAllHeading>My Memberships</ViewAllHeading>

        {
            isMbsLoading ?
            (
                <Loader />
            )
            : isMbsSuccess && memberOf ?
            (
                memberOf.map((ele) => {
                    let min = 1
                    const max = 100
                    const rand = min + Math.random() * (max - min)
                    return (
                        <ViewAllItem 
                            key={ ele._id +  rand } 
                            userId={ele._id}
                            fullName={ele.fullName} 
                            username={ele.username} 
                            image={ele.profileImg.imagePath2}  
                        />
                    )
                })
            )
            :
            (
                <div>Nothing to here</div>
            )
        }
    </ViewAllContainer>
  )
}

export default ViewAllMemberOF

const ViewAllHeading = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; 
    height: 100%;
    font-size: 11px;
    color: rgb(100,100,100);
`
const ViewAllContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
`