import axios from 'axios'

import { baseUrl } from '../constants'
const URL = baseUrl


const API_URL = '/api/comments/'
// Create new post
const getComments = async (postId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(`${URL + API_URL}get/${postId}`, config)
  return response.data
}

// // Create new post
const getCommentsTest = async (token) => {
//   const config = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   }
//   const response = await axios.get(API_URL_TEST, config)
//   return response.data.[0]
 }

// Create new post
const setComment = async (commentData, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await axios.post(`${URL + API_URL}setComment`, commentData, config)
    return response.data
}

const commentService = { getComments, setComment, getCommentsTest }
export default commentService
