import {useEffect, useRef, useState} from 'react'
import SettingsIcon from '@mui/icons-material/Settings';
import { useDispatch, useSelector } from 'react-redux'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useToggleDropdown from '../../hooks/useToggleDropdown'
import styled from 'styled-components'
import { addMyDirectory, getDirectoryStatus } from '../../features/directory/directorySlice'
import { setViewDirectoryModal } from '../../features/modal/directoryModalSlice'
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import Loader from 'loaders/Loader';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { directoryLanguage } from './directoryLanguage';

function DirectorySettings() {
    const dispatch = useDispatch()
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { directoryStatus, isDirectoryStatusLoading, isDirectoryStatusSuccess } = useSelector((state) => state.directory)
    const { user } = useSelector((state) => state.auth)

    const handleAddMyDirectory = () => { dispatch(addMyDirectory({ churchId: profileChurch._id })) } 
    const ref1 = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref1)

    const handleDropDown = () => { 

        if(!user.isOrganization){
 
            dispatch(getDirectoryStatus({churchId: profileChurch._id}))
            
        }
        
        setIsDropped.toggle(ref1) 
    }

    const handleAddToDirectory = () => {
      dispatch(setViewDirectoryModal({createDirectory: true}))
    }

  return (
    <CONT>
        <MoreHorizIcon 
            ref={ref1} 
            style={{color: '#727575', cursor: 'pointer'}} 
            onClick={handleDropDown} 
            />
            { isDropped && (
                <DropWrap onClick={handleDropDown}>
                    {
                        isDirectoryStatusLoading ?
                        (
                            <AYHWrap>
                                <Loader />
                            </AYHWrap>
                        )      
                        : (user.isOrganization || directoryStatus === null) ?                 
                        (
                            <AYHWrap onClick={handleAddToDirectory}>
                                <ICON><FolderSharedIcon/></ICON>
                                <Qustion>
                                    {
                                        directoryLanguage.createDirectoryItem[user.preferredLanguage]
                                    }
                                </Qustion>
                            </AYHWrap>                                   
                        )                 
                        : isDirectoryStatusSuccess && directoryStatus === 'present' ?
                        (
                            <AYHWrap>
                                <ICON> 
                                    <CheckCircleIcon style={{color: 'green'}}/> 
                                    <span style={{paddingLeft: '5px'}}>
                                        {
                                            directoryLanguage.itemPresent[user.preferredLanguage]
                                        }
                                    </span> 
                                </ICON>
                            </AYHWrap>
                        )
                        : isDirectoryStatusSuccess && directoryStatus ===  'not-present' && 
                        (   
                            <AddMine onClick={handleAddMyDirectory}> 
                                {
                                    directoryLanguage.addMyDirectory[user.preferredLanguage]
                                }
                            </AddMine>
                        )
                    }
                </ DropWrap >
            )}
    </CONT>
  )
}

const CONT = styled.div`
    display: flex;
    z-index: 8;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #ccc;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 10px;
`

const DropWrap = styled.div`
    padding: 0;
    border-radius: 10px;
    position: absolute;
    display: flex;
    width: fit-content;
    flex-direction: column;
    top: 10px;
    right: 0px;
    border: 1px solid rgb(212, 212, 212);
    background-color: white;
    z-index: 11;
    padding: 5px;
    border-radius: 5px;
`

const AddMine = styled.div`
    padding: 10px;
    cursor: pointer;
    &:hover{
        background-color: green;
        color: white;
        border-radius: 5px;
    }
`
const ICON = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
`

const AYHWrap = styled.div`
    color: rgb(75,75,75);
    display: flex;
    flex-direction: row;
    padding: 10px;
    cursor: pointer;
    align-items: center;
    transition: all 0.4s;
    &:hover{
        background-color: rgb(231, 231, 231);
    }
`

const Qustion = styled.span`
  
  padding-left: 5px;
`
export default DirectorySettings