import { useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addMyDirectory, getBirthdays, getDirectory, getDirectoryStatus, setNewPageLoading } from '../../features/directory/directorySlice' 
import DirectoryCardItem from './DirectoryCardItem'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Loader from 'loaders/Loader'
import DirectoryModal from './DirectoryModals/DirectoryModal'
import Modal from '@mui/material/Modal';
import ConfirmModal from 'components/modals/ConfirmModal' 
import { setViewConfirmModal } from 'features/modal/modalSlice'
import { setPrivilege } from 'features/auth/authSlice' 
import { setViewDirectoryModal } from 'features/modal/directoryModalSlice'
import GroupAddIcon from '@mui/icons-material/GroupAdd';

function DirectoryCards() {
 
    const dispatch = useDispatch()  
    const { viewDirectoryModal } = useSelector((state) => state.directoryModal)
    const { viewConfirmModal, confirmModalData } = useSelector((state) => state.modal)
    const { user } = useSelector((state) => state.auth)
    const { profileChurch  } = useSelector((state) => state.profileChurch)
    const { 
        directoryList, isDirectoryLoading, isDirectorySuccess, 
        isDirectoryError, directoryMessage, pagesArray, hasPrevPage, 
        currentPage, hasNextPage, isNewPageLoading, sortValue, directoryStatus,
        isDirectoryStatusLoading, isDirectoryStatusSuccess
    } = useSelector((state) => state.directory)

    useEffect(() => {
        if(isDirectoryError){
            toast.error(directoryMessage)
        }
    },[dispatch, directoryMessage, isDirectoryError])

    useEffect(() => {
        dispatch(getDirectory({
            ids: profileChurch.churchDirectoryList,
            currentPage: currentPage
            
        }))
    }, [])


    const handleGetBdays = () => {
        dispatch(getBirthdays({
            churchId: profileChurch._id,
        }))
    }

    const handleAddMyDirectoryItem = () => {  
        dispatch(addMyDirectory({
            churchId: profileChurch._id
        })) 
    }

    useEffect(() => { 
        dispatch(getDirectoryStatus({
            churchId: profileChurch._id
        })) 
    }, [dispatch, profileChurch._id])

    const handleAddToDirectory = () => {
        dispatch(setViewDirectoryModal({createDirectory: true}))
    }

  return (
    <DirectoryCardsContainer> 
        { isNewPageLoading && ( <LoaderContainer> <Loader /> </LoaderContainer> )  }
        <CardsContainer>
            { isDirectorySuccess ? 
                (
                    directoryList.map(ele => (
                        <DirectoryCardItem 
                            key={ele._id} 
                            data={ele}  
                            />
                    ))
                )
                :  isDirectoryError && 
                (
                    <div>Error loading directory</div>
                )
            }

            { isDirectoryStatusSuccess &&
                (user.isOrganization || directoryStatus === null) ?              
                    (
                        <CreateDirectoryButton onClick={handleAddToDirectory}> 
                            <GroupAddIcon />
                            <span> Create a Directory Entry </span> 
                        </CreateDirectoryButton>                                   
                    )    
                    : isDirectoryStatusSuccess && directoryStatus === 'not-present' &&
                    (
                        <CreateDirectoryButton
                            onClick={handleAddMyDirectoryItem}
                            > 
                            <GroupAddIcon />
                            <span> Add your directory here </span>  
                        </CreateDirectoryButton>
                    )
            }

        </CardsContainer>
        <PaginationContainer>
            <PaginationNumber
                disabled={!hasPrevPage || isDirectoryLoading}
                onClick={() => {
                    dispatch(setNewPageLoading(true))
                    dispatch(getDirectory({
                        ids: profileChurch.churchDirectoryList, 
                        churchId: profileChurch._id,
                        currentPage: currentPage - 1,
                        sort: sortValue
                    }))
                }}
            >
                <PaginationIcon> <ArrowBackIosIcon fontSize='12px' /> </PaginationIcon>
            </PaginationNumber>
            {
                isDirectorySuccess && (
                    pagesArray.map(ele => (
                        <PaginationNumber
                            active={ele === currentPage}
                            key={ele} 
                            disabled={ele === currentPage || isDirectoryLoading}
                            onClick={() => {
                                dispatch(setNewPageLoading(true))
                                dispatch(getDirectory({
                                    ids: profileChurch.churchDirectoryList, 
                                    churchId: profileChurch._id,
                                    currentPage: ele,
                                    sort: sortValue
                                }))
                            }}
                            >
                            {ele.toString()}
                        </PaginationNumber>
                    ))
                )
            }
            <PaginationNumber
                disabled={!hasNextPage || isDirectoryLoading}
                onClick={() => {
                    dispatch(setNewPageLoading(true))
                    dispatch(getDirectory({
                        ids: profileChurch.churchDirectoryList, 
                        churchId: profileChurch._id,
                        currentPage: currentPage + 1,
                        sort: sortValue
                    }))
                }}
            >
                <PaginationIcon>
                    <ArrowForwardIosIcon fontSize='12px' /> 
                </PaginationIcon>
            </PaginationNumber>
        </PaginationContainer>
        {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}

        <Modal
            open={viewDirectoryModal}
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            // onClose={() => dispatch(closeDirectoryModal())}
            onClose={() => {}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <DirectoryModal />
            
        </Modal>

        {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
        <Modal
            open={viewConfirmModal}
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            onClose={() => dispatch(setViewConfirmModal(false))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <ConfirmModal 
                text={confirmModalData.text} 
                handler={confirmModalData.handler}  
                confirmBtnBackgroundHover={confirmModalData.confirmBtnBackgroundHover}
                extraInfo={confirmModalData.extraInfo}
                /> 
        </Modal>
        
        {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
    </DirectoryCardsContainer>
  )
}

export default DirectoryCards

const CreateDirectoryButton = styled.div`
    padding: 10px;
    width: 120px;
    height: 140px;
    color: rgb(94, 94, 94); 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 2px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.5s;
    position: relative;
    border: 3px dashed #ccc;
    color: var(--lightThemeFontColor);
    span{
        font-size: 12px;
        font-weight: 100;
    }

    @media (max-width: 768px) {
        padding: 8px;
        width: 110px;
        margin: 5px; 
        height: 130px; 
    }

    &:hover{
        transition: all 0.5s;
        background-color: #e1e1e1; 
    } 
`

const DirectoryCardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: calc(100vh - 200px);
    padding-top: 10px;    

    @media (max-width: 768px) {
        width: 100%;
        justify-content: center; 
        height: calc(100vh - 195px); 
    }
`
const LoaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
`

const CardsContainer = styled.div`
    display: flex; 
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    /* flex-basis: 100%; */
    width: 100%;
    height: 100%;
    padding-top: 10px; 
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    } 

    @media (max-width: 768px) {

        width: 100%;
        flex-direction: row;
        div {
            flex: 50%;
        }
    }
`

const PaginationContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    padding: 5px 0px 0px 0px; 
    background-color: none; 

    @media (max-width: 768px) {
        width: 100%;
        justify-content: center;
    }
`

const PaginationNumber = styled.button`
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${props => props.active ? '#38577d': props.disabled && !props.active ? '#ccc' : 'grey'};
    font-size: 11px;
    width: 45px;
    height: 45px;
    margin-right: 5px;
    border-radius: 5px;
    border: ${props => props.active ? '2px solid #6283ab' : '2px solid #dbdbdb'};
    background-color: ${props => props.active ? 'white' : props.disabled && !props.active ? '#dbdbdb' : 'white'};
    /* on hover give it a box shadow of #ccc and transition for .5 seconds */
    &:hover {
        box-shadow: ${props => props.disabled ? 'none' : '0px 0px 5px 0px #aeadad'};
        transition: 0.5s;
    }


`

const PaginationIcon = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    width: 100%;
    height: 100%; 
    background-color: ${props => props.disabled ? '#dbdbdb' : 'white'};
`