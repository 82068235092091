import React, {useEffect, useState } from 'react' 
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { setViewStartConversationModal } from 'features/messenger/conversationSlice'

function NoConversations() {
    const [showBtn, setShowBtn] = useState(false)
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const handleClick = () => {
        dispatch(setViewStartConversationModal(true))
    }

    useEffect(() => {
            if(window.location.pathname.includes('new')){
                setShowBtn(false)
            }else{setShowBtn(true)}
    }, [showBtn])

  return (
    <NoConversationsContainer>
        <NCmain>
            You don't have any conversations
        </NCmain> 
        { showBtn && (

                <StartConvBtn onClick={handleClick}>
                    Start a Conversation
                </StartConvBtn> 
            )
        }
    </NoConversationsContainer>
  )
}

export default NoConversations

const NoConversationsContainer = styled.div`
    height: 60vh;
    width: 100%;
    /* border: 1px solid rgb(180, 180, 180); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
`
const NCmain = styled.div`
    padding: 10px;
    overflow-x: hidden;
    max-width: 30vw;
    word-wrap: break-word;
    color: var(--lightThemeFontColor);

`

const StartConvBtn = styled.div`
    padding: 10px;
    background-color: green;
    border-radius: 5px;
    color: white; 
    cursor: pointer;

    &:hover{
        background-color: rgb(0, 150, 0);
    }

`