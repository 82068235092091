import { useState, useRef } from 'react'
import styled from 'styled-components' 
import { resetSearchTerm, searchDirectory, setSearchTerm } from 'features/directory/directorySlice';
import { useSelector, useDispatch } from 'react-redux' 
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { toast } from 'react-toastify';
import { SearchWrap, SearchBtn, StyledSearchInput } from 'components/media/media.styled';
import SearchIcon from '@mui/icons-material/Search';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { directoryLanguage } from './directoryLanguage';

function DirectorySearch() {
  const searchInputRef = useRef(null)
  const dispatch = useDispatch()
  const [searchVal, setSearchVal] = useState('')
  const { searchTerm, isSearchActive, isSearchLoading } = useSelector((state) => state.directory)
  const [isFocused, setIsFocused] = useState(false)
  const [extendSearchBar, setExtendSearchBar] = useState(false)
  const { user } = useSelector((state) => state.auth)

  const handleSearch = (e) => {
    e.preventDefault()
    if(
      searchInputRef.current.value === ''
    ){
      return toast.error(directoryLanguage.toastSearchError[user.preferredLanguage], {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      })
    } 

    dispatch(setSearchTerm(
      searchInputRef.current.value
    ))
    dispatch(searchDirectory({
      searchVal: searchInputRef.current.value
    })) 
  }

  const handleClearSearch = () => { 
    dispatch(resetSearchTerm()) 
    setExtendSearchBar(false)
  } 

// in redux create a copy of the current directoryItems array so that
// when the user is done searching ( the original search will create a new array of directoryItems to be displayed )
// the original array of directory items will be returned

  return (
    <DSWrap>
      <SearchWrap 
        isFocused={isFocused}
        extendWidth={extendSearchBar} 
        onClick={() => {if(!extendSearchBar){setExtendSearchBar(!extendSearchBar)}}}
        > 
        {
          !extendSearchBar && 
            <SearchIcon 
              style={{cursor: 'pointer', marginLeft: '5px'}}
              onClick={() => setExtendSearchBar(!extendSearchBar)}
              />
        }

        <StyledSearchInput
          ref={searchInputRef}
          extendWidth={extendSearchBar}
          placeholder={directoryLanguage.searchDirectory[user.preferredLanguage]}
          />

        {
          extendSearchBar &&
          <SearchBtn
            onClick={handleSearch}
            >
            <ManageSearchIcon />
          </SearchBtn>
        } 
      </SearchWrap>
      {
        isSearchActive && (
          <SearchTermVal onClick={handleClearSearch}>
            <ST>{searchTerm}</ST>
            <SearchOffIcon style={{
              marginLeft: '0px',
              marginTop: '0px',
              display: 'flex', 
              alignSelf: 'flexStart',
              justifySelf: 'start',
              // fontSize: 'smaller', 
              cursor: 'pointer', 
              color: '#ff0000bf'}}/>
          </SearchTermVal>                  
        )
      }
    </DSWrap>
  )
}
const DSWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -10px;  
  @media (max-width: 768px) {
    width: 100%; 
    margin-right: 5px;
  }
`

const ST = styled.span`
  padding: 5px 8px;
  background-color: #00800091;
  color: white;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  border-radius: 4px;
  box-shadow:  0 0 10px #ccc;

`
const SearchTermVal = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  align-self: center;
  justify-self: center;
`

export default DirectorySearch