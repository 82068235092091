
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { ModalContainerMain } from 'components/modal/modal.styled'
import { useSelector, useDispatch } from 'react-redux';
import { resetListing, addListing, setListingType, setViewAddListingModal } from 'features/marketplace/marketSlice';
import ListingForm from './ListingForm';
import Tooltip from '@mui/material/Tooltip';
import PreviewListing from './PreviewListing'; 
import ModalCloseButton from 'components/buttons/ModalCloseButton';


export default function AddListingModal() {

    const dispatch = useDispatch()
    const { listingTypes, listingType } = useSelector(state => state.market) 


    return (
        <ModalContainerMain> 
            <ListingModalContainer>                 
                <ModalCloseButton 
                    handler={() => dispatch(setViewAddListingModal(false))}
                    />
                

                { 
                    !listingType ? 
                    (              
                        <BIW>

                            <h2>What are you listing?</h2>

                            <BoxItemsWrapper>
                                {
                                    Object.keys(listingTypes).map((item, index) => {

                                    return(
                                        <Tooltip title={listingTypes[item].tooltip} placement={listingTypes[item].tooltipPlacement}>
                                            <BoxListingItem
                                                onClick={() => dispatch(setListingType(listingTypes[item].type))}
                                                key={listingTypes[item].type}
                                                >
                                                {listingTypes[item].icon}
                                                <span>{listingTypes[item].type}</span>
                                            </BoxListingItem>
                                        </Tooltip>
                                    )
                                    })
                                }                            
                            </BoxItemsWrapper>                                
                        </BIW>

                    )
                    :
                    (
                        <ListingForm />
                    )
                } 

                {/* here we can add what the listing will look like */}
                {/* <PreviewListing /> */}
            </ListingModalContainer>
        </ModalContainerMain>
    )
}

const ListingModalContainer = styled.div`
    width: 750px;
    height: 85vh;
    border-radius: 10px;
    background-color: white;

    display: flex;
    flex-direction: row;
    justify-content: flex-start ;
    align-items: center;
`

const BIW = styled.div` 
    /* width: 35%; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2{
        margin-bottom: 30px;
        color: var(--lightThemeFontColor);
    }
`

const BoxListingItem = styled.div`
    width: 110px;
    height: 110px;
    box-shadow: 0px 0px 5px #ccc;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer; 
    color: var(--lightThemeFontColor);
    &:hover {
        background-color: #f0f0f0;
    }
    svg{
        margin-bottom: 10px;
    }
    span{
        font-size: 12px;
    }


`

const BoxItemsWrapper = styled.div`
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    flex-wrap: wrap ;
    gap: 10px;
    column-gap: 10px;
    row-gap: 10px;
    justify-content: center;
    overflow-y: scroll;
    /* hide scroll bar */
    ::-webkit-scrollbar {
        display: none;
    }

`