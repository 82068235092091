import React from 'react'
import styled from 'styled-components'
import { landingPageStore } from 'app/landingPageStore' 
import { TbBuildingChurch } from "react-icons/tb";
import { MdOutlinePerson2 } from "react-icons/md";

export default function UserType() {

    const style = {
        toggleColor: "var(--themeColor)",
        buttonheight: "70px",
        buttonwidth: "200px", 
        time: ".5s"
    }

    const { userType, setUserType, setIsOptionHovered } = landingPageStore()

    const handleToggle = (value) => { 
        setUserType(value)
        if(value === 'member'){
            localStorage.setItem('loginType', 'user')
        }else if(value === 'church'){
            localStorage.setItem('loginType', 'church')
        } 
    }

    return (
        <UserTypeContainer>
            <SectionWrapper>
                <SectionTitle>
                    <span>I am a: </span>
                    <span
                        style={{
                            marginLeft: '20px'
                        }}
                        >
                    {userType === 'church' ? <TbBuildingChurch /> : <MdOutlinePerson2 />}
                    {userType}
                    </span>
                </SectionTitle> 
                <ToggleWrapper>
                    <Option
                        onMouseEnter={() => setIsOptionHovered("church")}
                        onMouseLeave={() => setIsOptionHovered("")}
                        myStyle={style}
                        side="left"
                        selected={userType==='church'}
                        onClick={() => handleToggle('church')}
                        >
                            <BGText selected={userType==='church'}>Church Leader</BGText>
                            <BG myStyle={style}></BG> 
                    </Option>
                    <Option2
                        onMouseEnter={() => setIsOptionHovered("member")}
                        onMouseLeave={() => setIsOptionHovered("")}
                        myStyle={style}
                        onClick={() => handleToggle('member')}
                        selected={userType==='member'}
                        >   
                            <BGText selected={userType==='member'}>Church Member</BGText>
                            <BG2 myStyle={style}></BG2>
                    </Option2>
                </ToggleWrapper>
            </SectionWrapper>
            {/* <Hovered
                display={isOptionHovered}
                >
                {
                    isOptionHovered === 'church' ? 
                    (
                        <h1>church</h1>
                    )
                    : isOptionHovered === 'member' &&
                    (
                        <h1>member</h1>
                    ) 
                }
            </Hovered> */}
        </UserTypeContainer>
    )
}

const Hovered = styled.div`
    position: absolute;
    right: 0px;
    display: ${props => props.display ? 'flex' : 'none'};
`

const BG = styled.div`
    background: ${props => props.myStyle.toggleColor};
    position: absolute;
    right: -${props => props.myStyle.buttonwidth};
    width: ${props => props.myStyle.buttonwidth};
    height: ${props => props.myStyle.buttonheight}; 
    transition: ${props => props.myStyle.time};
`

const BG2 = styled.div`
    background: ${props => props.myStyle.toggleColor};
    position: absolute;
    left: -${props => props.myStyle.buttonwidth};
    width: ${props => props.myStyle.buttonwidth};
    height: 100px; 
    transition: ${props => props.myStyle.time};
`

const BGText = styled.span`
    position: absolute;
    color: ${props => props.selected ? 'white' : '#cdcdcd'};
    font-family: 'Roboto', sans-serif;
    font-weight: ${props => props.selected ? '500' : '200'};
    top: 25px;
    width: fit-content;
    left: 40px;
    z-index: 3;
    @media (max-width: 768px) {
        font-size: 12px;
    } 
`

const Option = styled.div`
    height: ${props => props.myStyle.buttonheight};
    width: ${props => props.myStyle.buttonwidth};
    position: relative;
    border: 2px solid ${props => props.selected ? props.myStyle.toggleColor : props.myStyle.toggleColor};
    overflow: hidden;
    border-radius: ${props => props.side === 'left' ? '15px 0px 0px 15px' : '0px 15px 15px 0px'};
    cursor: pointer;
    ${props => props.selected && ` 
            ${BG} {
                right: 0px;
                transition: ${props => props.myStyle.time};
            }
        `
    }
`
const Option2 = styled.div`
    height: ${props => props.myStyle.buttonheight};
    width: ${props => props.myStyle.buttonwidth};
    position: relative;
    overflow: hidden;
    border: 2px solid ${props => props.selected ? props.myStyle.toggleColor : props.myStyle.toggleColor};
    border-radius: ${props => props.side === 'left' ? '15px 0px 0px 15px' : '0px 15px 15px 0px'};
    cursor: pointer;
    ${props => props.selected && ` 
            ${BG2} {
                left: 0px;
                transition: ${props => props.myStyle.time};
            }
        `
    }
`

const ToggleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius: 18px;
    padding: 5px;
`

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: fit-content;
`
const SectionTitle = styled.h1`
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 25px;
    color: white;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    span{
        display: flex;
        flex-direction: row;
        align-items: center;  
    }

    svg{
        margin-right: 4px;
    }

    `
    






const UserTypeContainer = styled.div`
    height: 250px;
    width: 100%;
    background-color: #6f952e;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
`