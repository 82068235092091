
import {React, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { searchChurch, closeSearchChurchModal, resetChurchSearchTerm, setChurchSearchTerm, resetSearchResults } from 'features/memberships/membershipSlice'
import { useNavigate } from 'react-router-dom'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ChurchSearchResultsItem from './ChurchSearchResultsItem'
import SearchOffIcon from '@mui/icons-material/SearchOff';
import SearchIcon from '@mui/icons-material/Search';
import { ModalContainerMain } from 'components/modal/modal.styled';
import Loader from 'loaders/Loader'
import ModalCloseButton from 'components/buttons/ModalCloseButton'


export default function SearchForChurchModal() {
    const dispatch = useDispatch()
    const [searchVal, setSearchVal] = useState('')
    const [ searchTermChanged, setSearchTermChanged ] = useState(false)
  const navigate = useNavigate()
    const {user} = useSelector((state) => state.auth)
    const [showClearResults, setShowClearResults] = useState(false)

    const { 
        churchesSearchResult,
        churchSearchTerm, 
        firstSearchTerm,
        isChurchSearchActive, 
        isChurchSearchSuccess, 
        isChurchSearchLoading,
        searchChurchCount,
        noMoreResults,

    } = useSelector((state) => state.memberships2)

    const handleChurchNav = (value) => {
        dispatch(closeSearchChurchModal())
        navigate(`/${value}`)
    }

    const handleClearSearch = () => {
        setSearchVal('')
        dispatch(resetChurchSearchTerm())
      }

      const handleSearch = (e) => {
        e.preventDefault()        
        
        if(searchVal === churchSearchTerm){
            return toast.success('Showing results')
        }


        if(searchVal.length <= 2){
          return toast.error('Enter a name to search', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          })
        }
        
        if(searchTermChanged){ dispatch(resetSearchResults()) }
        
        dispatch(setChurchSearchTerm(searchVal))



        dispatch(searchChurch({
            searchVal: searchVal,
        }))
      }

    const handleLoadMore = () => {
        //check if search term has not changed
        //dispatch(setChurchSearchTerm(searchVal))
        dispatch(searchChurch({
            searchVal: searchVal,
            searchChurchCount: searchChurchCount
        }))
    }

    const handleInputChange = (e) => {
        if(churchSearchTerm && (churchSearchTerm !== e.target.value)){ setSearchTermChanged(true) }
        // if(searchTermChanged){ dispatch(resetChurchSearchTerm()) }
        setSearchVal(e.target.value)
    }

    useEffect(() => { }, [churchSearchTerm, searchTermChanged])

  return (
    
     <ModalContainerMain>
      <MModalWrap>

        <ModalCloseButton 
          handler={() => dispatch(closeSearchChurchModal())}
          top= '10px'
          right='10px'
          size='35px'
          />
        
        <ModalHeading>Find your church</ModalHeading>

        <ChurchSearchForm>
          <ChurchSearchInput value={searchVal} onChange={handleInputChange} placeholder="Search by church name..." /> 
          { isChurchSearchLoading ? 
            (
                <SearchBtnC> <HourglassEmptyIcon style={{animation: 'spin 1s infinite linear'}}  /> </SearchBtnC> 
            )
            :
            (
                <SearchBtnC onClick={handleSearch} onSubmit={handleSearch}> 
                    <SearchIcon/>

                </SearchBtnC> 
            )
          }

        </ChurchSearchForm>

        <ChurchSearchContent>
            {   
                isChurchSearchActive && 
                (
                    <SearchTermVal 
                        onClick={handleClearSearch} 

                        >
                    <ST 
                        onMouseEnter={() => setShowClearResults(true)}
                        onMouseLeave={() => setShowClearResults(false)}
                        >{churchSearchTerm}</ST>
                    <SearchOffIcon 
                        onMouseEnter={() => setShowClearResults(true)}
                        onMouseLeave={() => setShowClearResults(false)}
                        
                        style={{
                        marginLeft: '0px',
                        marginTop: '0px',
                        display: 'flex', 
                        alignSelf: 'flexStart',
                        justifySelf: 'flexStart',
                        // fontSize: 'smaller', 
                        cursor: 'pointer', 
                        color: '#ff0000bf'}}
                        />
                        <span style={{color: 'grey', marginLeft: '12px'}}>{showClearResults?('Clear Results'):('Search results')}</span>  
                    </SearchTermVal>
                    
                
                )
            }

            { isChurchSearchLoading ? 
                (
                    <Loading><Loader /></Loading>
                )
                : !isChurchSearchLoading && isChurchSearchSuccess && churchesSearchResult &&
                (
                    <SearchResults>
                        <BorderBottom></BorderBottom>
                        {
                            churchesSearchResult.length === 0 ?(
                                <NoResults>No results</NoResults>
                            )
                            :
                            (
                                churchesSearchResult.map((church) => (
                                    <ChurchSearchResultsItem data={church}  />
                                ))
                            )
                        }
                    </SearchResults>
                )
            }
        </ChurchSearchContent>

        </MModalWrap>
    </ModalContainerMain>
    
  )
}
const BorderBottom = styled.div`
    border-bottom: 1px solid #ccc;
    width: 100%;
    margin-bottom: 10px;
`


const MModalWrap = styled.div`
  z-index: 0;
  width: 450px;
  min-height: 320px;
  max-height: fit-content;
  background: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 8px;
  position: relative;
  animation: animate 0.3s;
  padding: 5px 5px 20px 5px;
  @media (max-width: 768px) {
    width: 90vw;
    min-height: 200px;
    height: fit-content;
  }

`

const ModalHeading = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 30px;
  font-weight: 400;
  color: var(--lightThemeFontColor);
`


const ChurchSearchForm = styled.form`
    margin-bottom: 10px;
    padding: 0px;
    display: flex;
    justify-content: row;
    align-self: flex-start; 
    justify-self: flex-start;
    border: 1px solid #ccc;
    width: 80%;
    border-radius: 5px 5px 5px 5px;
    background-color: white;
    padding: 3px;
    align-self: center;
    justify-self: center;
`
const ChurchSearchContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
`
const ST = styled.span`
  padding: 5px 8px;
  background-color: #00800091;
  color: white;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 0 10px #ccc;

`
const SearchTermVal = styled.div`
  margin-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  align-self: flex-start;
  justify-self: flex-start;
  
`
const ChurchSearchInput = styled.input`
    width: 100%;
    padding: 10px;
    outline: none;
    border: none;
    border-radius: 5px 0px 0px 5px;
`
const SearchBtnC = styled.button`
  cursor: pointer;
  color: #ffffff;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
  border: none;
  background-color: #898989;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover{
     background-color: green;
     color: white;
  }
`



const Loading = styled.div`
    

`

const SearchResults = styled.div`
    margin-top: 10px;
    width: 100%;

    max-height: 250px;
    overflow-y: scroll;

    ::-webkit-scrollbar{
        display: none;
    }
`

const NoResults = styled.div`
   color: #333333;
`