import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { searchListings, setListingTypeFilter } from 'features/marketplace/marketSlice'
import LocationFilter from './LocationFilter'
import SearchIcon from '@mui/icons-material/Search';

export default function ListingSearchContainer() {

    const dispatch = useDispatch()
    const { searchRadius, listingTypes, listingTypeFilter } = useSelector((state) => state.market)
    const [ input, setInput] = useState()

    const handleSearch = () => {
        dispatch(searchListings({
            searchQuery: input,
            searchRadius: searchRadius
        })) 
    }

    const handleInput = (e) => {
        setInput(e.target.value)
    }

    return (
        <ListingSearchContainerMain> 
            <SearchInputContainer>
                <input onChange={handleInput} type="text" placeholder={listingTypes[`${listingTypeFilter}`].searchPlaceholder ? listingTypes[`${listingTypeFilter}`].searchPlaceholder : 'Search'}/>
                <button
                    onClick={handleSearch}
                    >
                    <SearchIcon />
                </button>
            </SearchInputContainer> 
        </ListingSearchContainerMain>
    )
}

const ListingSearchContainerMain = styled.div` 
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row ;
    justify-content: flex-start ;
    align-items: center;
    background-color: white;
    border-radius: 10px;
`

const SearchInputContainer = styled.div`
    width: 300px;
    height: 35px;
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
    /* if in focus */
    &:focus-within{
        box-shadow: 0px 0px 5px #a1a1a1;
    }

    svg{
        font-size: 17px;
    }

    input {
        width: 80%;
        height: 100%;
        border-radius: 5px; 
        padding: 5px;
        font-size: 16px;
        outline: none;
        border: none;
        /* adjust placeholder */
        ::placeholder{
            font-size: 14px;
        }
    }



    button{
        width: 37px;
        height: 100%;
        border-radius: 7px;
        background-color: #4e854e9f;
        color: white;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        border: none;
        cursor: pointer;
        border-left: 1px solid #cccccc;
    }

`