import React, {useEffect, useState}from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setViewUploadVideoModal } from 'features/videos/videoSlice'
import styled from 'styled-components'
import VideoUploadSelect from './VideoUploadSelect'
import CreateButton from 'components/buttons/CreateButton' 
import { Modal } from '@mui/material'
import UploadVideoModal from './UploadVideoModal'
import VideoFeed from './VideoFeed' 
import VideoPlayerModal from './VideoPlayerModal'
import { setViewVideoPlayerModal } from 'features/videos/videoSlice' 
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom'
import { MUIModalWrap } from 'components/modal/modal.styled'
import { setViewConfirmModal } from 'features/modal/modalSlice'
import ConfirmModal from 'components/modals/ConfirmModal'
import { addAc } from 'features/auth/authSlice'
import { addAcFunc } from 'funcs/ac'

export default function Videos() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { viewUploadVideoModal, isDeleteVideoLoading, isDeleteVideoSuccess, viewVideoPlayerModal, currentVideo } = useSelector((state) => state.video)
    const [upload, setUpload] = useState(false)
    const { viewConfirmModal, confirmModalData } = useSelector((state) => state.modal)

    const handleResetAfterClose = () => {
        console.log('reset after close')
    }

    useEffect(() => {
        console.log('videos mounted')
        const dis = () => dispatch(addAc({
            type: 'videos', 
        }))
        addAcFunc('vd', dis)
    }, [])

    return (
        <VideosPageContainer>
            <CreateButton  
                handler={() => setUpload(!upload)}
                buttonText={'Upload Video'}
                 />
                { upload && ( <VideoUploadSelect /> )}
            <VideoFeed />

            <Modal
                open={viewUploadVideoModal}
                onClose={() => dispatch(setViewUploadVideoModal(false))}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <MUIModalWrap>
                        <UploadVideoModal  />
                    </MUIModalWrap>
            </Modal>
            <Modal
                open={viewVideoPlayerModal}
                onClose={() => dispatch(setViewVideoPlayerModal(false))}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <MUIModalWrap backgroundColor={'#242424'} >
                    <BackButton
                        onClick={() => {
                            dispatch(setViewVideoPlayerModal({
                                view: false,
                                videoUrl: null
                            }))
                            navigate(`?section=videos`)
                        }}
                        >
                        <ArrowBackIcon />
                        <span> Back to Videos </span>
                    </BackButton>
                    <VideoPlayerModal url={currentVideo} />
                </MUIModalWrap>
            </Modal> 
            <Modal
                open={viewConfirmModal}
                onClose={() => dispatch(setViewConfirmModal(false))}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <MUIModalWrap>
                    <ConfirmModal
                        loadingState={isDeleteVideoLoading}
                        conditionToCloseModal={isDeleteVideoSuccess}
                        text={'Are you sure you want to delete this video?'}
                        doSomethingAfterClose={true}
                        resetAfterClose={handleResetAfterClose} 
                        handler={confirmModalData.handler} 
                        confirmBtnBackgroundHover={'red'}
                        />
                </MUIModalWrap>
            </Modal> 
        </VideosPageContainer>
    )
}

const VideosPageContainer = styled.div`

    padding: 10px;
`
const BackButton = styled.div`
    position: absolute;
    top: 20px;
    left: 20px;
    width: fit-content;
    height: 50px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer; 

    span{
        width: fit-content;
        text-align: left;
        word-wrap: normal;
    }

    svg{ 
        font-size: 60px;
        height: 100%; 
        margin-right: 10px;
    }

    &:hover{
        color: #dac7c7;
    }

`
