import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const API_URL = '/api/feedback/sendFeedback'


  const sendFeedback = async (data, {}) => {
    const response = await axios.post(URL+API_URL, data, {})
    return response.data
  }


const feedbackService = { sendFeedback }
export default feedbackService